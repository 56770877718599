import React from 'react';

import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

import store from 'root/site/store';
import { Loader } from '@smartplatform/ui';

import './styles.scss';
import {Records} from "root/site/components";

@observer
export default class Disconnections extends React.Component {
    @observable record;

    async componentWillMount() {
        const record = await store.model.Disconnection.find({
            where: {
                or: [{ hide: null }, { hide: false }],
            },
            limit: 1
        });
        this.records = await store.model.CategoryRecord.find({
            where: {
                disconnectionId: record.id,
                showOnMain: true
            },
        });

        this.record = record;
    }

    render() {
        if (this.records?.totalCount === 0) {
            return null;
        }
        if (!this.record) {
            return <Loader />;
        }

        return (
            <div className="newsClass">
                <div className="container">
                    {this.record && this.record.map(record => (
                        <React.Fragment key={record.id}>
                            <Records
                                page="disconnection"
                                categoryId={record.id}
                                title={'Плановые отключения'}
                                model="Disconnection"
                                // limit={1}
                                showOnMain={true}
                                // order='createdAt desc'
                        />
                        </React.Fragment>
                    ))}
                </div>
            </div>
        );
    }
}
