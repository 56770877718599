export const processText = (html) => {
	if (html) {
		// нельзя использовать lookbehind - (?<= ) и (?<! ) - https://caniuse.com/js-regexp-lookbehind
		// временно заменяем готовые гиперссылки (тэги <a>) на "--anchor[N]--" и сохраняем их в массиве
		const anchorMatch = html.match(/<a[^<]+<\/a>/gi);
		const anchors = [];
		if (anchorMatch) {
			anchorMatch.forEach((anchor, i) => {
				anchors.push(anchor);
				html = html.replace(anchor, `--anchor${i}--`);
			});
		}

		// возвращаем сохраненные ранее готовые гиперссылки
		anchors.forEach((anchor, i) => (html = html.replace(`--anchor${i}--`, anchor)));

		html = html.replace(/([\?\[\]])/g, '$1');
		const match = html.match(/<img[^>]+>/gi);

		if (match) {
			
			// функция экранирует все спец символы для RegExp , которые есть в строке
			const escapeString = (str) => str.replace(/([()[{*+.$^\\|?])/g, '\\$1');

			match.forEach((imgTag, i) => {
				const srcMatch = imgTag.match(/src="([^"]+)"/i);
				if (srcMatch && srcMatch[1]) {
					const src = srcMatch[1];
					try {
						const re = new RegExp(`(${escapeString(imgTag)})`, 'g');

						html = html.replace(re, `<a class="lightbox" target="_blank" href="${src}">$1</a>`);
					} catch (e) {
						console.warn(e);
					}
				}
			});
		}
	}
	return html;
};
