import React from 'react';
import './styles.scss';
import store from 'root/site/store';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { HtmlValue } from '@smartplatform/ui';
import { processText } from 'root/tools/processText';

@observer
export default class PaymentDetails extends React.Component {
	@observable paymentDetails;

	componentDidMount() {
		this.init();
	}

	init = async () => {
		let paymentDetails = await store.model.CompanyInformation.find({ where: { name: 'paymentDetails' } });
		this.paymentDetails = paymentDetails[0];
	};

	render() {
		return (
			<div className='MainBackGroundClient'>
				<div className='container'>
					{this.paymentDetails && (
						<React.Fragment>
							<div className='slate-editor-content__wrapper'>
								<HtmlValue value={processText(this.paymentDetails.mainText)} />
							</div>
						</React.Fragment>
					)}
				</div>
			</div>
		);
	}
}
