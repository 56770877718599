import React from 'react';
import { Popup, Button } from '@smartplatform/ui';

import { faInfoCircle } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SmartCity from './инструкция Кварплата+.docx';
import RussiaPost from './Инструкция оплаты онлайн Почта России.docx';

export default class PaySelectionModal extends React.Component {
	render() {
		return (
			<Popup onClose={this.props.onClose} className="pay-buttons-popup">
				<div className="pay-button">
					<a
						href="https://finance.pochta.ru/payment/fsg_91674_Elektroenergija_Sahaenergo__Respublika_Saha_Jakutija"
						target="_blank"
						rel="noopener noreferrer"
						className="pay-button-link"
					>
						<Button
							type="button"
							className="float-right btn-outline-light"
							label="Оплата через Почту России"
							style={{ marginBottom: '20px' }}
						/>
					</a>

					<a className="pay-button-info" href={RussiaPost} download>
						<FontAwesomeIcon icon={faInfoCircle} color="#0066a6" size="2x" />
					</a>
				</div>

				<div className="pay-button">
					<a
						href="https://pay.kvartplata.ru/pk/login.action?id=3"
						target="_blank"
						rel="noopener noreferrer"
						className="pay-button-link"
					>
						<Button
							type="button"
							className="float-right btn-outline-light"
							label="Оплата через систему «Город»"
							style={{ marginBottom: '20px' }}
						/>
					</a>

					<a className="pay-button-info" href={SmartCity} download>
						<FontAwesomeIcon icon={faInfoCircle} color="#0066a6" size="2x" />
					</a>
				</div>
			</Popup>
		);
	}
}
