import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

@observer
export default class Attachment extends React.Component {
	render() {
		const { attachment, property } = this.props;

		return (
			<div className='attachment'>
				<div className='info'>
					<a className='file-name'>{attachment.filename}</a>
				</div>
			</div>
		);
	}
}
