import React from 'react';
import './styles.scss';
import { Route, Link } from 'react-router-dom';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import store from 'root/site/store';

import { Loader } from '@smartplatform/ui';
import { Records } from '../../components/record';

@observer
export default class DisconnectionPage extends React.Component {
	@observable records;

	async componentWillMount() {
		const records = await store.model.Disconnection.find({
			where: {
				or: [{ hide: null }, { hide: false }],
			},
		});

		this.records = records;
	}

	render() {
		if (!this.records) {
			return (
				<div className='MainBackGroundClient'>
					<Loader />
				</div>
			);
		}
		return (
			<div className='MainBackGroundClient'>
				<div className='container'>
					{this.records &&
						this.records.map((record) => (
							<React.Fragment key={`route-${record.id}`}>
								<Records
									page='disconnection'
									categoryId={record.id}
									title={record.category}
									model='Disconnection'
								/>
							</React.Fragment>
						))}
				</div>
			</div>
		);
	}
}
