import React from 'react';
import { Field } from 'formik';

export const FormField = ({ name, placeholder, errors, touched, ...rest }) => {
	return (
		<div>
			<Field placeholder={placeholder} name={name} {...rest} />
			{errors[name] && touched[name] ? (
				<div className="customer-field-error">{errors[name]}</div>
			) : null}
		</div>
	);
};
