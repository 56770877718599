import React from 'react';
import './styles.scss';
import { Route, Link } from 'react-router-dom';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import store from 'root/site/store';

import { Loader } from '@smartplatform/ui';
import { Records } from '../../components/record';

@observer
export default class OtherPage extends React.Component {
	@observable reporting;

	async componentWillMount() {
		const reporting = await store.model.Reporting.find({
			where: { or: [{ hide: null }, { hide: false }] },
		});
		this.reporting = reporting;
	}

	render() {
		if (!this.reporting) {
			return (
				<div className="MainBackGroundClient">
					<Loader />
				</div>
			);
		}
		return (
			<div className="MainBackGroundClient">
				<div className="container">
					<div className="row">
						<div className="col-4 ">
							<div className="BlockButton">
								<ul className="purchases">
									{this.reporting.map((reporting) => (
										<li key={`reporting/${reporting.id}`}>
											<Link to={`/reporting/${reporting.id}`}> {reporting.category} </Link>
										</li>
									))}
								</ul>
							</div>
						</div>
						<div className="col-8">
							<div className="container">
								{this.reporting.map((reporting) => (
									<React.Fragment key={`route-${reporting.id}`}>
										<Route
											path={`/reporting/${reporting.id}`}
											exact
											render={() => (
												<Records
													page="reporting"
													categoryId={reporting.id}
													title={reporting.category}
													model="Reporting"
												/>
											)}
										/>
									</React.Fragment>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
