import React from "react";

export const Fax = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 23 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M21.8718 3.51217C20.9739 3.51217 20.0759 3.51217 19.1779 3.51217C19.1779 2.70399 19.1779 1.89585 19.1779 1.08767C19.1779 0.591737 18.7759 0.189697 18.28 0.189697C13.9398 0.189697 9.5997 0.189697 5.25954 0.189697C4.76361 0.189697 4.36157 0.591701 4.36157 1.08767C4.36157 1.89585 4.36157 2.70399 4.36157 3.51217C3.46359 3.51217 2.56565 3.51217 1.66768 3.51217C1.17178 3.51217 0.769775 3.91421 0.769775 4.41015C0.769775 7.34348 0.769775 10.2768 0.769775 13.2102C0.769775 13.7061 1.17178 14.1081 1.66775 14.1081C2.56573 14.1081 3.46367 14.1081 4.36164 14.1081C4.36164 15.1685 4.36164 16.2288 4.36164 17.2891C4.36164 17.8063 4.36164 18.3234 4.36164 18.8405C4.36164 19.1399 4.42218 19.3632 4.65357 19.5749C4.76134 19.6734 4.87641 19.7373 5.01697 19.7768C5.14714 19.8133 5.27579 19.8102 5.40736 19.8102C7.52817 19.8102 9.64898 19.8102 11.7698 19.8102C13.8905 19.8102 16.0114 19.8102 18.1322 19.8102C18.2638 19.8102 18.3926 19.8133 18.5228 19.7768C18.6635 19.7373 18.7786 19.6732 18.8864 19.5745C19.1178 19.3626 19.1779 19.1389 19.1779 18.8395C19.1779 18.3221 19.1779 17.8046 19.1779 17.2872C19.1779 16.2275 19.1779 15.1678 19.1779 14.1082C20.0759 14.1082 20.9738 14.1082 21.8718 14.1082C22.3677 14.1082 22.7698 13.7062 22.7698 13.2102C22.7698 10.2769 22.7698 7.34352 22.7698 4.41018C22.7698 3.91425 22.3678 3.51217 21.8718 3.51217ZM3.01468 6.67748C2.58075 6.67748 2.22897 6.32569 2.22897 5.89177C2.22897 5.45784 2.58075 5.10606 3.01468 5.10606C3.4486 5.10606 3.80039 5.45784 3.80039 5.89177C3.80039 6.32569 3.4486 6.67748 3.01468 6.67748ZM18.28 18.9122H5.25958V11.8183H18.28V18.9122ZM18.28 3.51217C13.9399 3.51217 9.59973 3.51217 5.25958 3.51217C5.25958 3.00333 5.25958 2.49445 5.25958 1.98565C5.25958 1.48971 5.66158 1.08767 6.15756 1.08767C9.89904 1.08767 13.6406 1.08767 17.3821 1.08767C17.878 1.08767 18.28 1.48968 18.28 1.98565C18.28 2.49452 18.28 3.00337 18.28 3.51217Z" />
  </svg>
);

export const Phone = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.3265 15.8672L16.2734 12.8068C15.6652 12.1998 14.6582 12.2182 14.0289 12.8492L12.4907 14.3905C12.3935 14.3368 12.2929 14.2807 12.1872 14.2212C11.2158 13.6818 9.88638 12.9425 8.48742 11.5393C7.08431 10.1332 6.34595 8.79868 5.80609 7.82451C5.74912 7.7213 5.69454 7.6218 5.64064 7.5273L6.67298 6.49416L7.18052 5.98484C7.8108 5.35297 7.82818 4.3439 7.22147 3.73508L4.16835 0.67436C3.56164 0.0663935 2.55412 0.0848375 1.92385 0.716712L1.06337 1.58409L1.08689 1.60749C0.798358 1.97648 0.557255 2.40205 0.377833 2.86099C0.21244 3.29784 0.109468 3.7147 0.0623828 4.13242C-0.340762 7.48216 1.18651 10.5436 5.33132 14.6978C11.0607 20.4398 15.6779 20.006 15.877 19.9849C16.3109 19.9329 16.7266 19.829 17.1491 19.6645C17.603 19.4868 18.0274 19.2455 18.3953 18.9569L18.4141 18.9737L19.2858 18.1182C19.9148 17.4864 19.9329 16.477 19.3265 15.8672Z"
      fill="white"
    />
  </svg>
);

export const Email = props => (
  <svg
    width="23"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
<path d="M10.8232 10.8075L8.49219 8.63376L1.82764 14.7205C2.06989 14.96 2.39692 15.1091 2.7576 15.1091H18.8887C19.2481 15.1091 19.5737 14.96 19.8146 14.7205L13.1541 8.63376L10.8232 10.8075Z" fill="white"/>
<path d="M19.8146 0.357462C19.5724 0.135862 19.2468 0 18.8848 0H2.75484C2.39553 0 2.06987 0.137181 1.82764 0.3601L10.8198 7.91429L19.8146 0.357462Z" fill="white"/>
<path d="M0.388672 1.43893V12.9506L6.864 7.2599L0.388672 1.43893Z" fill="white"/>
<path d="M14.7783 7.26196L21.2536 12.9506V1.43893L14.7783 7.26196Z" fill="white"/>
  </svg>
  

);

export const switchHeaderIconStyle = (item) => {
  switch (item.name) {
      case 'phone':
      case 'helpline':
          return <Phone className='topbar-icon' />;

      case 'fax':
          return <Fax className='topbar-icon fax' />;

      case 'email':
          return <Email className='topbar-icon email' />;

      default:
          break;
  }
};
