import React from 'react';
import './styles.scss';
import store from 'root/site/store';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { HtmlValue } from '@smartplatform/ui';
import { processText } from 'root/tools/processText';

@observer
export default class AboutPage extends React.Component {
	@observable aboutCompany;
	@observable trends;

	componentDidMount() {
		this.init();
	}

	init = async () => {
		this.trends = await store.model.Trend.find();
		const aboutCompany = await store.model.CompanyInformation.find({ where: { name: 'aboutCompany' } });
		this.aboutCompany = aboutCompany[0];
	};

	render() {
		return (
			<div className='MainBackGroundClient'>
				<div className='container'>
					{this.aboutCompany && (
						<>
							<div className='slate-editor-content__wrapper'>
								<HtmlValue value={processText(this.aboutCompany.mainText)} />
							</div>

							<div className='row'>
								<div className='col-6 about'>
									{this.trends &&
										this.trends.map((trend, i) => {
											if (i % 2 === 0) {
												return (
													<div key={trend.id} className={Math.ceil(i / 2) % 2 === 0 ? 'blue' : 'white'}>
														<strong>Направление {i + 1} - </strong>
														{trend.text}
													</div>
												);
											}
										})}
								</div>
								<div className='col-6 about1'>
									{this.trends &&
										this.trends.map((trend, i) => {
											if (i % 2 !== 0) {
												return (
													<div key={trend.id} className={Math.ceil(i / 2) % 2 === 0 ? 'blue' : 'white'}>
														<strong>Направление {i + 1} - </strong>
														{trend.text}
													</div>
												);
											}
										})}
								</div>
							</div>
						</>
					)}
				</div>
			</div>
		);
	}
}
