import React from "react";

export const Point = props => (
  <svg
    width="23"
    height="20"
    viewBox="0 0 23 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
  <path d="M6.86638 0C3.08044 0 0 3.19607 0 7.12452C0 8.14922 0.202143 9.12757 0.600512 10.0326C2.3173 13.9304 5.60885 18.0456 6.577 19.2139C6.64954 19.3012 6.75529 19.3515 6.86657 19.3515C6.97786 19.3515 7.0836 19.3012 7.15614 19.2139C8.12391 18.0458 11.4155 13.9308 13.1328 10.0326C13.5314 9.12757 13.7333 8.14922 13.7333 7.12452C13.733 3.19607 10.6525 0 6.86638 0ZM6.86638 10.825C4.89973 10.825 3.29957 9.1648 3.29957 7.12433C3.29957 5.08365 4.89973 3.42343 6.86638 3.42343C8.83303 3.42343 10.4332 5.08365 10.4332 7.12433C10.4334 9.1648 8.83322 10.825 6.86638 10.825Z" fill="white"/>

  </svg>
);




export const Phone = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.3265 15.8672L16.2734 12.8068C15.6652 12.1998 14.6582 12.2182 14.0289 12.8492L12.4907 14.3905C12.3935 14.3368 12.2929 14.2807 12.1872 14.2212C11.2158 13.6818 9.88638 12.9425 8.48742 11.5393C7.08431 10.1332 6.34595 8.79868 5.80609 7.82451C5.74912 7.7213 5.69454 7.6218 5.64064 7.5273L6.67298 6.49416L7.18052 5.98484C7.8108 5.35297 7.82818 4.3439 7.22147 3.73508L4.16835 0.67436C3.56164 0.0663935 2.55412 0.0848375 1.92385 0.716712L1.06337 1.58409L1.08689 1.60749C0.798358 1.97648 0.557255 2.40205 0.377833 2.86099C0.21244 3.29784 0.109468 3.7147 0.0623828 4.13242C-0.340762 7.48216 1.18651 10.5436 5.33132 14.6978C11.0607 20.4398 15.6779 20.006 15.877 19.9849C16.3109 19.9329 16.7266 19.829 17.1491 19.6645C17.603 19.4868 18.0274 19.2455 18.3953 18.9569L18.4141 18.9737L19.2858 18.1182C19.9148 17.4864 19.9329 16.477 19.3265 15.8672Z"
      fill="white"
    />
  </svg>
);

export const Email = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
<path d="M10.8232 10.8075L8.49219 8.63376L1.82764 14.7205C2.06989 14.96 2.39692 15.1091 2.7576 15.1091H18.8887C19.2481 15.1091 19.5737 14.96 19.8146 14.7205L13.1541 8.63376L10.8232 10.8075Z" fill="white"/>
<path d="M19.8146 0.357462C19.5724 0.135862 19.2468 0 18.8848 0H2.75484C2.39553 0 2.06987 0.137181 1.82764 0.3601L10.8198 7.91429L19.8146 0.357462Z" fill="white"/>
<path d="M0.388672 1.43893V12.9506L6.864 7.2599L0.388672 1.43893Z" fill="white"/>
<path d="M14.7783 7.26196L21.2536 12.9506V1.43893L14.7783 7.26196Z" fill="white"/>
  </svg>
  

);

export const Search = props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
<path d="M30.5287 29.1469L22.8866 21.2259C24.8515 18.898 25.9281 15.9691 25.9281 12.92C25.9281 5.79602 20.1123 0 12.9641 0C5.81579 0 0 5.79602 0 12.92C0 20.044 5.81579 25.84 12.9641 25.84C15.6476 25.84 18.2049 25.0333 20.3913 23.502L28.0914 31.4832C28.4133 31.8163 28.8462 32 29.31 32C29.7491 32 30.1657 31.8332 30.4819 31.5298C31.1538 30.8855 31.1752 29.8171 30.5287 29.1469ZM12.9641 3.37043C18.2478 3.37043 22.5462 7.65425 22.5462 12.92C22.5462 18.1857 18.2478 22.4695 12.9641 22.4695C7.68036 22.4695 3.38193 18.1857 3.38193 12.92C3.38193 7.65425 7.68036 3.37043 12.9641 3.37043Z" fill="white"/>

  </svg>
  

);

export const switchFooterIconStyle = (item) => {
  switch (item.name) {
      case 'phone':
      case 'helpline':
          return <Phone className='topbar-icon' />;

      case 'fax':
          return <Fax className='topbar-icon fax' />;

      case 'email':
          return <Email className='topbar-icon email' />;

      case 'companyLocated':
          return <Point className="topbar-icon" />;

      default:
          break;
  }
};

