import React from 'react';

import './styles.scss';

import { Header, Footer } from '../../components';
import { withRouter } from 'react-router-dom';

@withRouter
export default class MainLayout extends React.Component {
	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			console.log('scroll');
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'instant',
			});
		}
	}
	render() {
		return (
			<div>
				<Header />

				{this.props.children}
				<Footer />
			</div>
		);
	}
}
