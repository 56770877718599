import React from 'react';
import { Popup } from '@smartplatform/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from '../../store';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormField } from './FormField';
import { Attachments } from './attachments/Attachments';

const validationSchema = Yup.object().shape({
	name: Yup.string().required('Заполните поле'),
	adress: Yup.string().required('Заполните поле'),
	email: Yup.string().required('Заполните поле'),
	phone: Yup.string().required('Заполните поле'),
	text: Yup.string()
		.max(300, 'Превышено количество знаков для текста обращения')
		.required('Заполните поле'),
	requestFieldId: Yup.string().required('Выберите тему'),
});

@observer
export default class PaySelectionModal extends React.Component {
	@observable requestFields = [];
	@observable record;
	@observable attachments = [];
	@observable fieldValue = null;
	@observable sended = false;

	componentDidMount() {
		this.init();
	}

	init = async () => {
		this.requestFields = await store.model.RequestField.find();
		this.record = new store.model.CustomerRequest();
	};

	onSubmit = async (values, actions) => {
		this.sended = true;
		Object.keys(values).forEach((key) => (this.record[key] = values[key]));
		this.record.requestFieldId = parseInt(values.requestFieldId);
		let field = this.requestFields.find((field) => field.id === this.record.requestFieldId);
		this.record.requestField = field;

		await this.record.save();
		for (const attach of this.attachments) {
			this.record.attachments.add(attach.id);
		}
		await this.record.sendMessage();

		setTimeout(() => this.props.onClose(), 1000);
	};

	render() {
		return (
			<Popup onClose={this.onClose} className='pay-buttons-popup' canClose={false} showCloseButton={true}>
				<FontAwesomeIcon icon={faTimes} className='customer-request-close-btn ui-popup-close-btn' onClick={this.props.onClose} />

				{this.requestFields && this.record && (
					<div style={{ width: '50vw', paddingTop: '10px' }}>
						<Formik
							initialValues={{
								name: '',
								adress: '',
								email: '',
								phone: '',
								text: '',
								requestFieldId: '',
								agree: false,
								sended: false,
							}}
							validationSchema={validationSchema}
							onSubmit={this.onSubmit}
							render={({ handleBlur, handleChange, values, handleSubmit, errors, touched }) => (
								// <form onSubmit={handleSubmit}>
								<Form>
									<div className='customer-field'>
										<FormField name='name' placeholder='Как к вам обращаться' errors={errors} touched={touched} />

										<FormField
											name='email'
											placeholder='Ваш эл. адрес для обратной связи'
											errors={errors}
											touched={touched}
										/>
										<FormField name='phone' placeholder='Контактный телеофн' errors={errors} touched={touched} />
									</div>
									<div className='customer-field'>
										<FormField name='adress' placeholder='Адресс объекта' errors={errors} touched={touched} />
									</div>
									<div className='customer-field'>
										<div>
											<select
												style={{ width: '50%', color: !values.requestFieldId ? 'gray' : 'inherit' }}
												placeholder='Тема обращения'
												name='requestFieldId'
												value={values.requestFieldId}
												onChange={handleChange}
												onBlur={handleBlur}
											>
												{!values.requestFieldId && <option value='' label='Выберите тему обращения' />}
												{this.requestFields.map((field) => (
													<option
														key={`field-${field.id}`}
														style={{ color: 'black' }}
														value={field.id}
														label={field.name}
													/>
												))}
											</select>
											{errors.requestFieldId && touched.requestFieldId ? (
												<div className='customer-field-error'>{errors.requestFieldId}</div>
											) : null}
										</div>
									</div>
									<div className='customer-field'>
										<div>
											<textarea
												style={{ height: '200px', width: '100%' }}
												placeholder='Тема обращения'
												name='text'
												value={values.text}
												onChange={handleChange}
												onBlur={handleBlur}
											/>

											{errors.text && touched.text ? <div className='customer-field-error'>{errors.text}</div> : null}
										</div>
									</div>
									<Attachments
										record={this.record}
										relation='attachments'
										canUpload
										withContext={!this.record.id}
										attachments={this.attachments}
									/>
									{!values.sended && (
										<p className={'col-8 check'}>
											<input
												type='checkbox'
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.agree}
												name='agree'
											/>
											Даю согласие на обработку&nbsp;
											<a
												href='https://ru.wikipedia.org/wiki/%D0%9E%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B0_%D0%BF%D0%B5%D1%80%D1%81%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85_%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85'
												target='_blank'
												rel='noopener noreferrer'
											>
												персональных данных
											</a>
											&nbsp;в соответствии Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных»
										</p>
									)}
									<p className={'col-4 send'}>
										<button type='submit' className='submit-button' disabled={!values.agree}>
											Отправить
										</button>
									</p>
								</Form>
								// </form>
							)}
						></Formik>
						{this.sended && <h3 style={{ textAlign: 'center' }}>Сообщение отправлено</h3>}
					</div>
				)}
			</Popup>
		);
	}
}
