import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import store from 'root/site/store';
import { List } from './List';
import './styles.scss';

@observer
export class Vacancies extends React.Component {
	@observable records = null;
	@observable isLoading = true;
	@observable employer = null;

	async componentDidMount() {
		this.records = await store.model.ViewEmployer.find({
			where: { publishedVacanciesCount: { gt: 0 } },
			include: [{ relation: 'vacancies', scope: { where: { published: true } } }],
			order: 'publishedVacanciesCount desc',
		});
		if (this.records.length) this.employer = this.records[0];
		this.isLoading = false;
	}

	onEmployerChange = (employer) => () => (this.employer = employer);

	render() {
		return (
			<div className='MainBackGroundClient'>
				{!this.isLoading && (
					<div className='container vacancies'>
						<ul className='employers-list'>
							{this.records.totalCount > 0 ? (
								this.records.map((record) => (
									<li
										key={`record/${record.id}`}
										className={this.employer.id === record.id ? 'active' : ''}
										onClick={this.onEmployerChange(record)}
									>
										<div className='d-flex justify-content-between'>
											<div>{record.name} </div>
											<div>{record.publishedVacanciesCount} </div>
										</div>
									</li>
								))
							) : (
								<div>Нет вакансий</div>
							)}
						</ul>
						<div className='vacancies-list'>
							{this.employer && (
								<div className='container'>
									<List records={this.employer.vacancies()} />
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		);
	}
}
