import React from 'react';
import './styles.scss';
import * as Components from '../../components';
import store from 'root/site/store';
import { Map as LeafletMap, TileLayer, Marker, Popup } from 'react-leaflet';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { HtmlValue } from '@smartplatform/ui';
import { processText } from 'root/tools/processText';

@observer
export default class ContactsPage extends React.Component {
	@observable management;
	@observable contacts;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.management = await store.model.Management.find();
		const contacts = await store.model.CompanyInformation.find({ where: { name: 'contacts' } });
		this.contacts = contacts[0];
	};

	render() {
		return (
			<div className='fon'>
				<div className='row'>
					<div className='col-xl-12'>
						{/*<img className="map" src={karta} />*/}
						<LeafletMap
							center={[62.045691, 129.763154]}
							zoom={16}
							maxZoom={17}
							attributionControl={true}
							zoomControl={true}
							doubleClickZoom={true}
							scrollWheelZoom={true}
							dragging={true}
							animate={true}
							easeLinearity={0.35}
						>
							<TileLayer url='http://{s}.tile.osm.org/{z}/{x}/{y}.png' />
							<Marker position={[62.045691, 129.763154]}>
								<Popup>
									<p>
										АО «Сахаэнерго» <br />
										Адрес: 677000 г. Якутск, пер. Энергетиков,2
									</p>
								</Popup>
							</Marker>
						</LeafletMap>
					</div>
					<div className='col-xl-6'>
						<div className='area'>
							<h4 className='ClassNameSection'>Руководство</h4>
							<ol className='push'>
								{this.management &&
									this.management.map((person) => {
										return (
											<li key={person.id}>
												<p>
													{person.description} – <span className='medium'>{person.fullName}</span>
												</p>
											</li>
										);
									})}
							</ol>
						</div>
					</div>
					<div className='col-xl-6 contact'>
						<div className='ContainerSubmitApp'>
							<h4 className='ClassNameSection bgwhite'>Контакты</h4>
							{this.contacts && <HtmlValue value={processText(this.contacts.mainText)} />}
						</div>
						<div className='grey'>
							<div>
								<h4 className='ClassNameSection'>Напишите нам</h4>
								<Components.FormRequest />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
