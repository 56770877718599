import { Popup, Input, Attachments, Button, Row, Checkbox } from '@smartplatform/ui';
import React from 'react';
import store from 'root/site/store';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

@observer
export class ResponsePopup extends React.Component {
	@observable record = null;
	@observable agreed = false;

	async componentDidMount() {
		this.record = await store.model.VacancyResponse.create();
	}

	onChange = (prop) => (e) => (this.record[prop] = e.target.value);
	onSave = async () => {
		this.record.vacancyId = this.props.vacancy.id;
		await this.record.save();
		this.props.onClose();
	};

	render() {
		if (!this.record) return null;
		const input = (prop, label) => (
			<div className='form-field'>
				<label className='is-required'>{label}</label>
				<Input value={this.record[prop]} onChange={this.onChange(prop)} />
			</div>
		);
		const disabled = !['lastName', 'middleName', 'city', 'phone', 'email'].every((key) => this.record[key]) || !this.agreed;
		return (
			<Popup width={600} onClose={this.props.onClose} className='container response-popup'>
				<div>
					<div className='form-field'>
						<label>Вакансия</label>
						<Input value={this.props.vacancy.title} disabled />
					</div>
					{input('lastName', 'Фамилия')}
					{input('firstName', 'Имя')}
					{input('middleName', 'Отчество')}
					{input('city', 'Город')}
					<Row>
						{input('phone', 'Телефон')}
						{input('email', 'E-mail')}
					</Row>

					<div className='form-field'>
						<label>Резюме, сопроводительное письмо</label>

						<Attachments store={store.model} record={this.record} noAttachmentName accept={['.doc', '.docx', '.rtf', '.pdf']} />
						<div className='mt-2'>
							Тип файла: <b>doc, docx, rtf, pdf</b>
						</div>
					</div>
					<div className='form-field mt-3'>
						<Checkbox value={this.agreed} onChange={(value) => (this.agreed = value)} />
						<label className='is-required'>Даю согласие на обработку персональных данных</label>
					</div>

					<Button className='btn-primary mt-3' onClick={this.onSave} disabled={disabled}>
						Отправить
					</Button>
				</div>
			</Popup>
		);
	}
}
