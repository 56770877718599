import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { HtmlValue, Loader } from '@smartplatform/ui';
import store from 'root/site/store';
import './styles.scss';
import { processText } from 'root/tools/processText';

@observer
export default class ClientsPage extends React.Component {
	@observable clientInfo;

	componentDidMount() {
		this.init();
	}

	init = async () => {
		const clientInfo = await store.model.CompanyInformation.find({ where: { name: 'forCustomers' } });
		this.clientInfo = clientInfo[0];
	};
	render() {
		console.log(this.clientInfo);
		if (!this.clientInfo) {
			return (
				<div className='MainBackGroundClient loader'>
					<Loader />
				</div>
			);
		}
		return (
			<div className='MainBackGroundClient'>
				<div className='container'>
					{this.clientInfo && (
						<div className='slate-editor-content__wrapper'>
							<HtmlValue value={processText(this.clientInfo.mainText)} />
						</div>
					)}
				</div>
			</div>
		);
	}
}
