import React from 'react';

import { observer } from 'mobx-react';

import { Button, Popup, Popover, Loader } from '@smartplatform/ui';

import { Fax, Phone, Email } from './icons';
import './styles.scss';
import { observable, action } from 'mobx';
import PaySelectionModal from './PaySelectionModal';
import userIcon from './userIcon.svg';
import CustomerFormModal from './CustomerFormModal';
import ReactSVG from 'react-svg';
import store from '../../store';
import { withRouter } from 'react-router-dom';
import { headerAdditionalInfoLeft, headerAdditionalInfoRight } from '../../../tools/additionalInfo';
import { switchHeaderIconStyle } from './icons';
@withRouter
@observer
export default class TopBar extends React.Component {
	@observable showPaymenu = false;
	@observable showCustomerForm = false;
	@observable additionalInfo = false;
	@observable isLoading = true;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		this.additionalInfo = await store.model.AdditionalInfo.find({
			where: { name: { inq: [...headerAdditionalInfoLeft, ...headerAdditionalInfoRight] } },
		});
		this.isLoading = false;
	};

	@action togglePayMenu = () => {
		this.showPaymenu = !this.showPaymenu;
	};

	render() {
		const additionalInfoLoader = (infoArray) =>
			!this.isLoading
				? this.additionalInfo.map((item) => {
						if (!infoArray.includes(item.name)) return null;
						return (
							<div key={item.id} className='topbar__contacts-item'>
								{switchHeaderIconStyle(item)}
								<a href={item.additionalText}>
									{item.label}: {item.mainText}
								</a>
							</div>
						);
				  })
				: null;

		return (
			<div className='topbar'>
				<div className='container'>
					{this.showPaymenu && <PaySelectionModal onClose={this.togglePayMenu} />}
					{this.showCustomerForm && <CustomerFormModal onClose={() => (this.showCustomerForm = false)} />}
					<div className='row align-items-center'>
						<div className='topbar'>
							<div className='topbar__contacts'>
								<div className='left'>{additionalInfoLoader(headerAdditionalInfoLeft)}</div>
								<div className='right'>{additionalInfoLoader(headerAdditionalInfoRight)}</div>
							</div>
							<div className='topbar__feedback'>
								{/* <Button
									type="button"
									className="float-right btn-outline-light"
									label="Задать вопрос"
									rel="noopener noreferrer"
									onClick={() => (this.showCustomerForm = true)}
								/> */}
							</div>
							{/* <div className="col-lg-4 col-md-6 col-sm-6 align-self-center topbar-buttons"> */}
							<div className='topbar__buttons'>
								<a
									href='http://www.rushydro.ru/form/'
									target='_blank'
									rel='noopener noreferrer'
									className='btn btn-outline-light mr-2 d-flex align-items-center justify-content-center'
								>
									{' '}
									Линия доверия
								</a>
								<Button
									style={{ marginRight: '10px' }}
									type='button'
									className='float-right btn-outline-light'
									label='Обращение граждан'
									rel='noopener noreferrer'
									onClick={() => (this.showCustomerForm = true)}
								/>
								<a
									href='http://tp.sakhaenergo.ru/'
									target='_blank'
									rel='noopener noreferrer'
									className='btn btn-outline-light mr-2 d-flex align-items-center justify-content-center'
								>
									Оставить заявку техприсоединения
								</a>
								<Button
									style={{ marginRight: '10px' }}
									type='button'
									className='float-right btn-outline-light'
									label='Оплата онлайн'
									onClick={this.togglePayMenu}
								/>
								<div className='user-cabinet_wrapper'>
									<Popover content='Вход в личный кабинет'>
										<button className='user-cabinet' label='Оплата онлайн'>
											<ReactSVG src={userIcon} className='user-cabinet__icon' />
										</button>
									</Popover>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
