import React from 'react';
// import PropTypes from 'prop-types';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import Attachment from './Attachment';
import store from '../../../store';
import t from '../../../../i18n';

import './attachments.scss';

const bytesToSize = (bytes) => {
	var sizes = [t('bytes'), t('Kb'), t('Mb'), t('Gb'), t('Tb')];
	if (bytes === 0) return '0 ' + sizes[0];
	const i = Math.floor(Math.log(bytes) / Math.log(1024));
	return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i];
};

@observer
export class Attachments extends React.Component {
	// static propTypes = {
	// 	record: PropTypes.object.isRequired,
	// 	relation: PropTypes.string,
	// 	property: PropTypes.string,
	// 	canUpload: PropTypes.bool,
	// 	uploadIsImage: PropTypes.bool,
	// 	onChange: PropTypes.func,
	// 	onDelete: PropTypes.func,
	// 	softDelete: PropTypes.bool,
	// 	withFormContext: PropTypes.bool,
	// };

	// static defaultProps = {
	// 	relation: 'attachments',
	// 	property: 'filename',
	// 	canUpload: false,
	// 	softDelete: false,
	// 	withFormContext: false,
	// };

	@observable attachments = [];
	@observable newFiles = [];
	@observable errors = [];
	@observable isUploading = false;

	constructor(props) {
		super(props);
		this.record = this.props.record;
		this.id = this.props.record.MODEL.name + '-' + this.props.record.id;
		this.model = store.model.Attachment;
	}

	onAttach = async (e) => {
		const newFiles = [];
		this.errors = [];
		for (let file of e.target.files) {
			const attachment = new this.model();
			attachment.filename = file.name;
			newFiles.push({
				attachment,
				file,
				uploadProgress: 0,
				done: false,
				error: null,
			});
		}
		this.newFiles = newFiles;
		this.isUploading = true;
		const promises = this.newFiles.map(this.upload);
		await Promise.all(promises);

		this.isUploading = false;
		this.errors = this.newFiles.filter((uploadObj) => !!uploadObj.error);
		this.newFiles = [];
	};

	handleFileProgress = (uploadObj, event) => {
		uploadObj.uploadProgress = event.percent;
	};

	upload = async (uploadObj) => {
		console.log('upload obj is ', uploadObj);
		await uploadObj.attachment.save();
		console.log('saved attach', uploadObj.attachment);
		try {
			const res = await this.uploadFile(uploadObj);
			if (this.props.attachments) {
				this.props.attachments.push(uploadObj.attachment);
			} else {
				await this.props.record[this.props.relation].add(uploadObj.attachment.id);
			}

			uploadObj.done = true;
			this.attachments.push(uploadObj.attachment);
		} catch (error) {
			if (error.status === 413) {
				uploadObj.error = t('file.fileTooBig');
			} else {
				uploadObj.error = t('file.uploadError');
			}
			await uploadObj.attachment.delete();
		}
	};

	uploadFile = (uploadObj) =>
		new Promise((resolve, reject) => {
			console.log('obj in promise', uploadObj);
			uploadObj.attachment
				.uploadFile('filename', uploadObj.file)
				.on('progress', (event) => this.handleFileProgress(uploadObj, event))
				.end((error, result) => {
					if (!error) {
						resolve(result);
					} else {
						reject(error);
					}
				});
		});

	render() {
		return (
			<>
				<div className={'attachments'} style={{ height: 50 }}>
					{this.props.canUpload && (
						<div className='upload'>
							<input type='file' id={this.id} onChange={this.onAttach} multiple />
							<label htmlFor={this.id}>
								<FontAwesomeIcon icon={faPaperclip} />
								Прикрепить файлы
							</label>
						</div>
					)}
				</div>
				<div className='errors-and-list'>
					{this.isUploading && (
						<div className='new-files'>
							{this.newFiles.map((uploadObj, i) => {
								return uploadObj.done && !uploadObj.error ? (
									<Attachment key={i} attachment={uploadObj.attachment} isNew />
								) : (
									<div key={i} className='new-file'>
										<div className='icon'></div>
										<div className='info'>
											<div className='file-name'>{uploadObj.file.name}</div>
											{uploadObj.uploadProgress !== undefined && (
												<div className='progress'>
													{t('file.uploading')}: <em>{Math.round(uploadObj.uploadProgress) + '%'}</em> из
													<em>{bytesToSize(uploadObj.file.size)}</em>
												</div>
											)}
										</div>
									</div>
								);
							})}
						</div>
					)}
					{this.errors.length > 0 && (
						<div className='upload-errors'>
							{this.errors.map((uploadObj, i) => {
								return (
									<div key={i} className='new-file upload-error'>
										<div className='icon'>{/* <FontAwesomeIcon icon={faExclamationTriangle} /> */}</div>
										<div className='info'>
											<div className='file-name'>{uploadObj.file.name}</div>
											<div className='error-msg'>{uploadObj.error}</div>
										</div>
									</div>
								);
							})}
						</div>
					)}
					{this.attachments.length > 0 && (
						<div className='list'>
							{this.attachments.map((attachment, i) => (
								<Attachment key={i} attachment={attachment} />
							))}
						</div>
					)}
				</div>
			</>
		);
	}
}
