import React from 'react';
import './styles.scss';
import { Route, Link } from 'react-router-dom';

import { observable } from 'mobx';
import { observer } from 'mobx-react';

import store from 'root/site/store';
import { defaultCompanyStats } from '../../../tools/additionalInfo';
@observer
export default class AboutCompany extends React.Component {
	@observable companyStats;

	async componentDidMount() {
		this.companyStats = await store.model.AdditionalInfo.find({
			where: { name: { inq: defaultCompanyStats } },
		});
	}

	render() {
		if (!this.companyStats) {
			return <div />;
		}
		return (
			<div className='container'>
				<div className='row align-items-center'>
					<div className='col ClassNameSection'> О компании в цифрах </div>
					<div className='col-auto center-block ClassFurtherSection'>
						<a href='./about'> Подробнее о компании </a>
					</div>
				</div>
				<div className='row justify-content-center'>
					{this.companyStats.map((item) => (
						<div key={item.id} className='col-3'>
							<div className='ClassNameNumbers'>{item.mainText}</div>
							<div className='ClassNameDescription'>{item.additionalText}</div>
						</div>
					))}
				</div>
			</div>
		);
	}
}
