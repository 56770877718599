import React from 'react';

import './styles.scss';
import img from './img/karta.png';

export default class InteractiveMap extends React.Component {
	render() {
		return (<div className="InteractiveMapImg"> 
		 <div className="mapdiv"> 



    
<svg version="1.1" id="svg2" xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 614.9 575.3">

<g id="Background">
	<g id="Background-2">
		<path d="M248.9,110.2c0-1,1-2,3-2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1C249.9,113.2,247.9,112.2,248.9,110.2z M314,130.2
			c1,0,1,0,1,0c2,0,3-1,4-2c0-1,0-2,0-2c0-1,0-2,0-3c-2-1-4-2-6,0h-1c-1,1-1,3-1,4C312,129.2,313,130.2,314,130.2L314,130.2z
			 M205.9,91.2c1-3,3-3,3-3c1,0,1,0,2,0c1,1,2,3,1,4c0,2-1,3-3,3S205.9,93.2,205.9,91.2z M207.9,92.2L207.9,92.2c0-1,0-1,0-1
			S207.9,91.2,207.9,92.2L207.9,92.2z M545,237.2l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-2,1-3,0-3,0c-1-1-3-1-4-2c-2,3-11,4-14,4
			c-1,3-4,2-5,2l0,0l0,0c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0l0,0c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4
			c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5l0,0c0,1,0,1,0,1c1,3,1,5-1,6c0,0-2,1-4-2c0,1-1,1-2,1
			c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3c0,1-1,1-2,1c-2,0-6-3-6-6l0,0c-1,0-1,0-2,0l0,0c0,3-8,5-9,5s-2,0-3-1l0,0
			c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6
			c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2
			c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1l0,0c-1,0-2,0-3-1c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-1,1-1,4-5,3l0,0c-1,1-2,1-2,1
			c-2,0-3-1-3-2c-1-1-2-1-2-1c-1,1-2,2-3,1c-2,0-4-2-5-4c0-2-3-4-6-4l0,0c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2c0,4-3,8-5,10
			c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3l0,0c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3c-2,0-3,1-3,2
			s0,4-4,5c-1,0-2,1-3,1c-3,2-5,2-10,1s-5-1-6,0c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2l0,0
			c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4
			c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0l0,0c1,1,2,3,2,4s1,1,1,1c2,3,3,6,1,9c-1,3-2,4-5,3c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3
			s-2,1-3,1c-2,0-3,0-5,0s-5,0-7-1c-1,0-1,0-2,0c-2,2-4,1-5,1h-1c1,1,0,3-1,4c-2,1-4,0-6-1c-1,0-3-1-4-3c-1,0-1,0-2,0c-1,1-3,0-5,0
			c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2c-1,0-1,0-2,0c0,1-2,2-5,0l0,0c0,1-1,1-1,1c-1,0-1,1-2,0l0,0c0,0-1,0-2,0
			c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2c-3-1-5-2-7-3
			c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3c-5-1-6-1-7,0c-4,1-6,1-7,0
			c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2c-1,0-1,0-1,0c-1-2-1-5-1-7l0,0c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7
			c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3l0,0c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4
			c-1,0-1-1-1-1l0,0c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6
			c-1,1-3,3-6,3c-1,0-5,1-5,2c-1,1-2,3-4,2c-1-1-1-2-1-4c1-2,1-4,3-5v-1l0,0c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3
			c-1,0-1-1-1-1c-1,0-1,1-1,1c-3,3-6,4-10,2c-3-2-6-5-5-8l0,0c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
			l0,0l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
			s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3l0,0c0-1,1-2,1-2c1-1,3,0,4,0v-2c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6
			c0-2-1-3-2-3c-3,0-4-2-5-3l0,0c-1-1-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1l-1-3c0-2,0-7,1-11c1-6,1-7,4-9
			c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3
			c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6s4,0,7,1c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3
			c0-1,0-2,0-3s0-2,0-3c2-2,5-4,8-3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2
			s1-10,3-12c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5
			c1-1,2-1,3-2c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3
			c-1,0-2-2-2-4s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1c1-1,1-1,1-1c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2
			c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1l0,0c0,0,0,1,0,2c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0l0,0c1-1,4,0,4,0c1,0,1,0,2,0
			c3-2,8-1,13,2c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
			c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
			c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c1,1,3,2,6,1c5-2,9-1,11,3c1,0,1,1,2,1
			c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5l0,0v1l0,0c2,1,4,2,5,5c0-1,1-1,2-1c1-1,1,0,2,0c1,2,1,3,0,4c0,1-1,1-2,1
			s-1,0-1,0l-1-1c0,0-1,0-1,1l0,0c-1,1-1,2-2,3l0,0c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1
			c-1,0-1,0-1,0v1c0,1,0,2,1,3c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c1-1,2-1,4,0c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5l0,0
			c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c3-2,4-3,8-2s11,4,12,8
			c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5s4-3,6-1c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2
			c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2l0,0c-1,3-3,3-3,3c-2-1-3-3-2-6c1-4,4-9,7-8c1,0,2,0,2,1l0,0c1-1,1-1,1-2
			c0,0,0,0-1,0c-2-2-1-4-1-5c1-1,3-2,5-1c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l0,0l8,1c3,1,6,1,8,1c4-2,7-2,13,0c7,1,9,2,9,5
			c0,1-1,3-2,4l0,0l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2l0,0c1,0,3-1,4-2l0,0c4-2,4-2,10-7l0,0c3-3,5-4,8-4c1,0,2,0,2-1
			c1-1,4-2,8-3c6-2,7-2,13-1c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3c0,2,0,2,1,2c3,1,4,1,7,0c2-1,3-2,5-2c1,0,2-1,3-2
			l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4c2,1,3,3,2,5l0,0c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5
			c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3
			c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10c0,1,1,2,1,3l0,0l2,3C546,235.2,546,236.2,545,237.2z M161.9,431.2L161.9,431.2L161.9,431.2
			z M519,137.2h1c2,0,3-1,3-2l0,0c1,1,1,2,3,3l0,0c2,0,3-2,3-3l0,0c1-2,1-3,0-5c-1-1-2-2-3-2h-1c1-2,0-3-2-3c-1-1-3,0-3,2
			c0,1,1,3,2,3c0,1,0,1,0,2c-1-1-1-1-2-2c0,0-1-1-2-1s-2,0-3,1c-2,1-2,3-1,5C515,136.2,517,137.2,519,137.2z M348,153.2
			c-1,2,0,4,3,5c2,0,4-1,4-3c1-3-1-5-3-6C350,149.2,348,150.2,348,153.2z M330,80.2h1l0,0c3,0,3-3,4-5c0-1,1-4-1-5v-1c-1-1-2-3-3-3
			s-1,0-1,0c-3,1-4,9-3,11C327,79.2,328,80.2,330,80.2z M364,112.2c0,2-1,3-2,3c-1,1-3,2-3,4c-1,1,0,2,1,3c0,0,0,0,1,0s3-1,4-2
			c2,1,5,1,7,1c10,2,14,2,15-1c0-1-1-2-1-3c-1-1-1-3-4-6c-3-5-3-5-7-6C369,104.2,366,106.2,364,112.2L364,112.2z M329,100.2
			c-1,0-1,0-2,0c-1,1-1,2-1,4c0,0,1,3,3,4c1,1,1,2,2,2c1,2,2,3,3,3l0,0c1,0,2,0,3-1c2-3,0-5-1-7C334,103.2,331,100.2,329,100.2z
			 M390,67.2c0,6,2,8,8,9c2,1,4,2,6,3s3,2,5,2c0,0,0,0,1,0c4,0,7-2,10-5l2-3c1-1,1-2,0-3l-1-1c0-1-1-1-1-1c0-1,0-1,0-2c-1-1-2-1-3-1
			c-2,0-6,0-8,2h-1c0-1,0-2-1-3c-2-2-4-1-8,1c-1,1-1,1-2,2c-1-1-1-1-1-2l-1-2c-1-1-2-1-3,0c-1,0-1,1-1,1L390,67.2z M363,108.2v1
			c1,0,2-1,3-1l0,0c2-2,2-4,2-6c-1-2-2-4-4-4h-1c-2,0-3,1-4,3c0,1,0,2,1,4C360,107.2,361,108.2,363,108.2L363,108.2z M338,68.2v-1
			c0-4,2-7,6-9c5-2,7,0,10,5c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5c1-2,3-2,5-2c2,1,5,3,7,5c0,1,3,1,4,1c2-1,4,0,8,1
			c4,2,6,3,6,5c0,1,0,2-1,2c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-5-1-11,1-13,4
			c-2,2-4,3-6,3l0,0c-1,0-2-1-3-2l0,0c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4S337,69.2,338,68.2L338,68.2z M369,69.2
			c0,1,1,3,2,5c-1-1-1-3-1-5c0-1,0-1,0-2c0,0,0,0,0,1C369,68.2,369,69.2,369,69.2L369,69.2z"/>
		<g id="Shadow">
			<g id="st0">
				<path d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,30.2c-5.1,0-9.4,0.9-12.9,2.4c-0.9-0.1-1.8-0.1-2.7-0.1c-4.2,0-8.3,0.8-12.6,2.6
					c-0.6,0.3-1.3,0.5-1.9,0.9c-3.3,1.7-6.3,3.7-8.8,6.1c-0.6,0.2-1.3,0.3-1.9,0.5c-7.8,2.6-14,8.6-17.5,16.8
					c-2.4,5.7-4,14.2-2.3,22.3c0.4,2.6,1.1,5,2.1,7.3c-2.3,4.2-3.1,8.3-3.3,11.4c-4.9-4.5-11.1-7.3-18-8c-0.7-1-1.6-2-2.5-2.9
					c-5.8-5.8-14.3-9.3-22.7-9.3c-3.3,0-11.3,0-18.9,5.9c-0.7,0.3-1.4,0.7-2,1.1c-0.2-1.6-0.6-3.3-1.2-4.9c-1.5-4.4-4-8.5-7.3-11.8
					c-3.4-3.4-7.7-5.8-12.4-6.8c-1-1.5-2.2-2.9-3.5-4.2c-5.3-5.3-12.5-8.3-19.7-8.3c-3.9,0-7.8,0.9-11.2,2.7c-0.5,0.1-1,0.2-1.5,0.3
					c-0.1,0-0.2,0-0.2,0c-6.3,0-12.5,2.2-17.5,6.2c-6.2,5-10,12.2-10.5,20c-4.8,4.3-7.2,9.1-8.4,11.6c-1.7,3.5-2.6,7.3-2.6,11.2
					c0,1.3,0,3.4,0.4,5.9c-0.8,3.2-1.3,6.6-1.4,9.8c0,0.1,0,0.1,0,0.2c-0.4,5,0.2,9.3,1.4,12.9c-2.7,1.2-5.5,2.8-8.2,5
					c-4.3,1.7-8.4,4.3-12.1,7.7c-0.4,0.1-0.7,0.1-1.1,0.2c-2.3-0.6-5.1-1.1-8.2-1.1c-4.5,0-8.9,1-13,3.1c-10.9,5.5-14,15.4-15,19
					c-1.2,3-1.8,6.1-1.8,9.3c0,3,0,6.8,1.1,11.6c-0.1,0.3-0.2,0.8-0.4,1.2c-0.7,1.6-1.5,3.7-2.2,6c-0.2,0.3-0.4,0.6-0.6,0.8
					c-2.8,1.1-5.4,2.7-7.7,4.7c-5.4,4.4-8.3,9.2-9.8,13.7c-1.4,3.4-2.2,7.1-2.2,10.9c0,0.3,0,0.7,0,1c-2.4,4.7-3.2,9.3-3.8,12.9
					l0,0.2c-0.3,1.2-0.5,2.3-0.6,3.4c-2.2,1.8-4.3,4.2-6.1,7.1c-6.1,5.5-9.7,13.6-9.7,21.3c0,8.6,4.3,16.3,8.6,21
					c2.1,2.5,4.3,4.4,6.5,5.9c-0.1,1-0.2,2.1-0.2,3.1c0,5.4,1.6,10.5,4.7,14.9c-1.6,3.4-2.7,7.4-2.7,12.1c0,0.8,0,1.9,0.2,3.3
					c-0.7,1.4-1.3,3-1.9,4.8c-0.8,2.5-1.3,5.2-1.3,7.9c0,1.3,0,3.2,0.3,5.5c-0.3,2.2-0.4,4.5-0.1,6.8c-2.7,3.3-4.8,6.5-6.1,8.9
					c-1.1,1.5-2,3-2.7,4.4c-1.2,1.4-2.5,2.9-3.6,4.4c-3.6,4.8-7.8,11.4-7.8,20c0,3,0.4,5.8,1.1,8.5c1.2,8,5.8,16.5,16,23.3
					c0.9,0.6,1.8,1.1,2.7,1.6c4.9,2.4,10,3.7,15.1,3.7c0.6,0,1.3,0,2-0.1c0.7,0.9,1.5,1.8,2.4,2.7c1.9,1.9,4.1,3.5,6.5,4.7
					c3.9,1.9,8,2.9,12.2,2.9c4.4,0,10.2-1,16-5.3c5.7-1.5,10.9-4.6,15.7-9.3c0.2-0.2,0.3-0.3,0.5-0.5c2.6,4.7,6.5,8.5,11.2,11.3
					c0.1,2.9,0.6,5.5,1.3,7.9c0,0.2-0.1,0.5-0.1,0.7c-1.1,3.4-2.9,9.6-1.5,16.9c0.2,1.9,0.6,4.2,1.4,6.7c0.2,0.5,0.3,0.9,0.5,1.3
					c0,0.4,0,0.7,0,1c0,5.5,1.4,10.5,3.9,14.8c2.6,6.6,7.4,11.2,13.1,13.8c4,2.1,8.6,3.2,13.3,3.2c1.2,0,2.3-0.1,3.6-0.2
					c2,1.6,4.3,3.1,7.1,4.4c6,7.2,13.3,9.3,18,9.8c0,0,0,0.1,0.1,0.1c2.7,5.1,6.9,9.3,12.2,12c4.2,2.1,8.1,2.8,11,3.3
					c0,0,0.1,0,0.1,0c1.1,0.6,2.3,1.2,3.5,1.7c2,1.7,4.3,3.2,6.9,4.5c2.7,1.3,5.5,2.2,8.4,2.5c1.8,0.4,3.6,0.6,5.5,0.6
					c1.4,0,2.7-0.1,4.1-0.3c0.2,0,0.5,0,0.7,0c2.1,0.6,4.3,0.9,6.5,0.9h3c0.7,0,1.5,0,2.4-0.1c0.8,0.3,1.5,0.6,2.3,0.8
					c3,1.3,7.3,2.7,12.6,2.7c4.5,0,8.9-1,12.9-3.1c0.8-0.4,1.6-0.9,2.4-1.4c0.9,0,1.7,0,2.4,0h5c8.2,0,15.2-2.8,20.7-8.3
					c4.4-4.4,7-9.6,8-15.7c2.8-2.6,5.2-6,7-10.2c3.5-6.7,4.3-14.3,2.5-21.7c2.5-4.4,3.9-9.5,3.9-15.1c0-0.9,0-1.9-0.2-3
					c0.7-1,1.3-2,1.9-3.1l1.2,0.3c2,0.5,4,0.7,6.1,0.7c7.6,0,14.3-2.8,19.1-7.6c3.8-0.5,7.4-1.6,10.9-3.3c0.2-0.1,0.4-0.1,0.5-0.2
					c2.7-0.8,5.3-2,7.6-3.4c8.6-1.7,14.7-7,18-13.4c3.1-5.2,5.7-13.2,2.9-23.8v-3.5c0.5-2.2,1-5,1-8.3c1.5-1.5,3.7-4,5.8-7.2
					c2.3,0.6,4.7,0.9,7.3,0.9c2.3,0,4.5-0.3,6.7-0.9c3.2,1.2,6.6,1.9,10.3,1.9c5.6,0,11-1.9,15.3-5.3c1.9-1.1,3.7-2.5,5.3-4.1
					c3.2-3.2,5.5-7,6.9-11.1c0.4-0.5,0.8-1.1,1.2-1.7c3.9-5.8,5.8-11.5,5.9-17.5c0.8-2.3,1.3-5.1,1.3-8.4c0-3.1-0.6-6.3-1.7-9.2
					c6-1.8,11.1-5.6,14.6-10.8c8.9-0.2,15.6-4,20-9.2c2.5-0.4,5-1.2,7.2-2.4c8.2-4.1,15-12.7,15.4-24.1c0.9-3,1.4-6.3,1.4-10.2
					c0-1.6-0.1-3.1-0.4-4.7c0.9-1.9,1.6-4.1,2.1-6.6c0.1-0.1,0.1-0.2,0.2-0.3c0.5,0,1,0,1.5,0c4.4,0,8.8-1,12.8-3.1
					c2.4-1.2,4.6-2.8,6.5-4.7c1.6-1.6,2.8-3.2,3.7-4.6l0.5-0.3c1.4-0.9,2.6-2,3.8-3.1c5.5-5.5,8.3-12.5,8.3-20.7c0-4-1-8-2.8-11.5
					c-0.6-4.9-2.1-9.1-3.5-12.5c-0.9-2.8-2-5.3-3.2-7.4c0.4-0.2,0.8-0.3,1.1-0.5c8.7-3.4,13.2-9.2,15.4-13.7c3-6,3-11.5,3-14.4
					c0-6.9-1.5-12.3-3.6-16.5c-0.9-4-2.8-7.8-5.6-11c-1.6-3.4-3.8-6.5-6.7-9.1c-2.5-4.8-6.4-8.5-11-10.8c-0.6-2.6-1.5-5.2-2.8-7.8
					c-1.2-2.4-2.8-4.6-4.7-6.5c-0.4-0.4-0.8-0.8-1.4-1.3c-3.7-5-9-8.5-15-10.1c-2.6-0.9-5.4-1.3-8.2-1.3l0,0c0,0,0,0,0,0
					c0,0,0,0,0,0c-7.4,0-14.5,3.1-19.6,8.6c-0.1,0.1-0.1,0.1-0.2,0.2c-0.5,0.3-1,0.6-1.4,1c-0.1,0-0.3,0-0.4,0
					c-5.6,0-10.1,1.2-14.4,2.6c-4.1,1.1-7.8,2.3-11.2,4c-5,1.2-9.1,3.4-12.3,5.9c-2.1-1.3-4.4-2.3-6.9-2.9c-2.3-2.8-5.2-5.2-8.5-7.1
					c-4.4-2.4-9-3.5-13.4-4.2c-3.6-1.1-8-2.1-13-2.1c-2.9,0-5.8,0.3-8.6,1c-0.7-0.1-1.4-0.3-2-0.3l-1.6-0.2c0.2,0,0.3,0,0.5,0h1
					c10.2,0,19.5-4.1,27.7-12.3c1.2-1.2,2.2-2.4,3.1-3.8l1.3-1.9c7.2-9.3,7.5-21.9,1-31.5c-1.2-3-3.1-5.8-5.4-8.2
					c-5.5-5.5-12.5-8.3-20.7-8.3c-0.5,0-1.1,0-1.7,0c-3.4-1.4-7-2.1-10.7-2.1c-1.4,0-2.8,0.1-4.1,0.3c-2.3-0.6-4.6-0.9-7-0.9
					c-1.8,0-3.6,0.2-5.3,0.5c0,0-0.1,0-0.1,0c-0.5-0.1-1.1-0.3-1.7-0.5c-1.8-0.5-4-1.1-6.8-1.5c-1.8-1.2-3.7-2.3-5.5-3.2
					C370.7,31.1,366.9,30.2,363,30.2L363,30.2z M192.1,117.2C192.1,117.2,192.1,117.2,192.1,117.2L192.1,117.2L192.1,117.2z"/>
			</g>
			<g id="st0">
				<path d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,32.2c-1.8,0-5.2,0-9.2,1.1c-1.2,0.3-2.4,0.8-3.5,1.2c-1-0.1-2-0.1-3-0.1
					c-3.9,0-7.8,0.8-11.9,2.4c-0.6,0.2-1.2,0.5-1.7,0.8c-3.3,1.7-6.2,3.7-8.8,6.1c-0.8,0.2-1.5,0.4-2.2,0.6
					c-7.3,2.4-13.1,8-16.2,15.7c-2.3,5.5-3.8,13.6-2.2,21.3c0.4,2.7,1.2,5.3,2.4,7.7c-2.9,4.9-3.6,9.8-3.7,12.9
					c-0.7,0.3-1.4,0.6-2.1,1c-5-5.1-11.6-8.2-18.9-8.7c-0.8-1.2-1.7-2.3-2.8-3.4c-5.5-5.5-13.4-8.7-21.3-8.7c-3.1,0-10.8,0-17.9,5.6
					c-1.6,0.7-3,1.6-4.4,2.7c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2-6.5-3-13.1-8.2-18.3c-3.3-3.3-7.6-5.6-12.2-6.4c-1-1.7-2.3-3.2-3.7-4.6
					c-4.9-4.9-11.6-7.7-18.3-7.7c-3.7,0-7.2,0.9-10.4,2.5c-0.7,0.1-1.5,0.3-2.3,0.5c-0.1,0-0.2,0-0.3,0c-5.8,0-11.6,2.1-16.2,5.8
					c-6,4.8-9.5,11.8-9.7,19.4c-4.9,4.2-7.4,9.1-8.6,11.5c-1.6,3.2-2.4,6.7-2.4,10.3c0,1.3,0,3.4,0.4,6c-0.9,3.4-1.4,6.8-1.4,9.8
					c0,0.1,0,0.2,0,0.3c-0.2,2-0.5,6.1,0.9,10.9c0.3,0.9,0.6,1.9,1,2.9c-3.2,1.2-6.5,3-9.7,5.6c-4.4,1.6-8.6,4.3-12.2,7.9
					c-0.7,0.1-1.4,0.2-2.2,0.3c-2.2-0.6-5-1.2-8.1-1.2c-4.2,0-8.3,1-12.1,2.8c-10.2,5.1-13.1,14.6-14,17.9c-1.1,2.8-1.7,5.7-1.7,8.7
					c0,3.1,0,6.9,1.1,11.6c0,0.1,0,0.2,0,0.3c-0.1,0.4-0.3,1-0.5,1.6c-0.7,1.6-1.5,3.7-2.2,6.2c-0.4,0.5-0.8,1.1-1.2,1.7
					c-2.8,1-5.4,2.6-7.6,4.6c-5,4.1-7.8,8.6-9.2,12.8c-1.4,3.2-2.1,6.7-2.1,10.3c0,0.5,0,1,0,1.4c-2.5,4.5-3.2,9.1-3.8,12.8l0,0.2
					c-0.3,1.4-0.6,2.8-0.8,4.2c-2.3,1.8-4.5,4.2-6.4,7.3c-5.8,5.1-9.3,12.7-9.3,20.1c0,8.1,4.1,15.3,8.1,19.6c2.2,2.6,4.5,4.5,6.8,6
					c0.1,0.1,0.2,0.3,0.3,0.4c-0.2,1.3-0.3,2.6-0.3,4c0,2.9,0.6,8.9,5,14.7c-1.7,3.3-3,7.5-3,12.3c0,0.9,0.1,2.1,0.3,3.7
					c-0.8,1.5-1.5,3.2-2.1,5.1c-0.8,2.3-1.2,4.8-1.2,7.3c0,1.3,0,3.3,0.3,5.6c-0.4,2.4-0.4,4.9-0.1,7.3c-2.9,3.4-5.1,6.9-6.5,9.4
					c-1.1,1.5-2,3-2.8,4.5c-1.2,1.4-2.5,3-3.6,4.5c-3.4,4.6-7.3,10.8-7.3,18.8c0,2.9,0.4,5.6,1.1,8.1c1.1,7.5,5.5,15.6,15.1,22
					c0.8,0.5,1.6,1,2.5,1.4c4.6,2.3,9.4,3.5,14.2,3.5c0.9,0,1.9,0,3-0.1c0.8,1.1,1.8,2.3,2.9,3.4c1.7,1.7,3.8,3.2,6,4.3
					c3.6,1.8,7.4,2.7,11.3,2.7c4.1,0,9.7-1,15.1-5.1c5.5-1.4,10.6-4.3,15.1-8.9c0.5-0.5,0.9-1,1.3-1.5c0.4-0.2,0.9-0.4,1.3-0.6
					c0.2,0.3,0.3,0.7,0.5,1c2.6,5.1,6.7,9.2,11.9,12c0,3.3,0.5,6.2,1.4,8.8c0,0.5-0.1,1-0.2,1.4c-1.1,3.3-2.9,9.3-1.4,16.3
					c0.2,1.8,0.5,3.9,1.3,6.2c0.2,0.6,0.4,1.1,0.6,1.6c0,0.5,0,0.9,0,1.3c0,5.2,1.4,9.9,3.8,13.9c2.4,6.1,6.8,10.4,12,12.8
					c3.8,2,8.1,3,12.6,3c1.3,0,2.7-0.1,4.1-0.3c3.1,2.6,6.1,4.1,7.8,4.9c6,7.4,13.4,9.2,17.8,9.5c0.2,0.3,0.4,0.6,0.7,0.9
					c2.5,4.8,6.4,8.8,11.5,11.3c3.9,2,7.6,2.6,10.5,3.1c0.1,0,0.2,0,0.3,0.1c1.2,0.7,2.5,1.3,4,1.9c2,1.7,4.2,3.1,6.6,4.4
					c2.5,1.2,5.1,2,7.9,2.3c1.7,0.4,3.4,0.6,5.1,0.6c1.3,0,2.7-0.1,4-0.3c0.4,0,0.7,0,1.1,0c2,0.6,4.1,0.8,6.2,0.8h3
					c0.7,0,1.7,0,2.8-0.2c0.7,0.3,1.5,0.6,2.3,0.9c2.8,1.3,7,2.7,12.2,2.7c4.2,0,8.3-1,12-2.8c1-0.5,1.9-1,2.7-1.6c1.2,0,2.2,0,3,0
					h5c7.6,0,14.1-2.6,19.3-7.7c4.2-4.2,6.7-9.3,7.5-15.2c2.7-2.4,5.3-5.8,7.2-10.4c2.3-4.6,4.6-12,2.1-21c2.6-4.2,4-9.3,4-14.7
					c0-1-0.1-2.2-0.2-3.5c1.1-1.6,2.1-3.2,2.9-4.9l2.7,0.7c1.8,0.5,3.7,0.7,5.6,0.7c7.3,0,13.7-2.8,18.2-7.5
					c3.8-0.4,7.4-1.4,10.9-3.2c0.3-0.1,0.5-0.2,0.8-0.3c2.8-0.8,5.3-2,7.6-3.5c8-1.5,13.7-6.4,16.8-12.4c2.9-4.9,5.4-12.5,2.7-22.7
					v-4c0.5-2.4,1.1-5.3,1-8.9l0.3-0.3c1.6-1.6,4.2-4.5,6.6-8.5c2.6,0.8,5.3,1.2,8.2,1.2c2.3,0,4.6-0.3,6.9-1c3.1,1.3,6.5,2,10.1,2
					c5.2,0,10.2-1.7,14.2-4.9c1.8-1,3.5-2.3,5-3.8c3-3,5.3-6.7,6.5-10.7c0.5-0.6,0.9-1.2,1.3-1.8c3.7-5.6,5.5-11,5.5-16.7
					c0.7-2.1,1.3-4.8,1.3-8.1c0-3.6-0.8-7.3-2.4-10.6c6.8-1.5,12.6-5.5,16.2-11.4c0,0,0.1,0,0.1,0c9,0,15.7-3.8,19.8-9.1
					c2.6-0.4,5.1-1.2,7.4-2.3c7.8-3.9,13.1-11.4,14.2-20c0.1-0.9,0.2-1.8,0.2-2.7c0.1-0.2,0.1-0.5,0.2-0.7c1.1-4,1.1-7.4,1.1-9.2
					c0-1.7-0.2-3.3-0.5-4.9c1-2,1.8-4.4,2.2-7.1c0.4-0.5,0.7-1.1,1-1.7c0.9,0.1,1.7,0.1,2.6,0.1c4.1,0,8.1-1,12-2.9
					c2.2-1.1,4.2-2.6,6-4.3c1.6-1.6,2.8-3.2,3.7-4.6l0.8-0.5c1.3-0.8,2.4-1.8,3.5-2.9c5.1-5.1,7.7-11.6,7.7-19.3c0-3.8-1-7.6-2.8-11
					c-0.5-4.7-2-9-3.3-12.3c-0.6-2.2-1.8-5.4-4.1-8.8c0.3-0.3,0.7-0.6,1-0.9c0.7-0.3,1.4-0.5,2.2-0.9c8.1-3.2,12.3-8.6,14.3-12.7
					c2.8-5.6,2.8-10.8,2.8-13.5c0-1.8,0-5.6-1.2-9.9c-0.6-2.1-1.4-4.1-2.3-6c-0.9-3.9-2.7-7.6-5.4-10.6c-1.5-3.3-3.7-6.3-6.5-8.8
					l0,0c-2.4-4.9-6.4-8.6-11.2-10.7c-0.5-2.8-1.4-5.5-2.8-8.3c-1.1-2.2-2.6-4.2-4.3-6c-0.4-0.4-0.9-0.9-1.5-1.4
					c-3.4-4.7-8.4-8-14.1-9.5c-2.4-0.8-5-1.2-7.7-1.2c-7.3,0-13.9,3.2-18.5,8.3c-0.7,0.4-1.4,0.9-2.1,1.4c-0.3,0-0.7,0-1,0
					c-5.3,0-9.6,1.2-13.9,2.6c-4.1,1.1-7.8,2.3-11,3.9c-5.4,1.3-9.6,3.8-12.9,6.4c-2.4-1.7-5.2-2.9-8.1-3.6c-2.2-2.8-5-5.3-8.4-7.1
					c-4-2.2-8.1-3.2-12.9-4c-3.5-1-7.7-2-12.5-2c-2.9,0-5.8,0.4-8.6,1.1c-0.8-0.2-1.5-0.3-2.3-0.4l-2.8-0.3c-0.5-1.1-1-2.1-1.6-3.1
					c-0.2-0.3-0.3-0.7-0.5-1c1.3,0.2,2.6,0.3,4.1,0.3h1c14.5,0,23.4-8.8,26.3-11.7c1.1-1.1,2-2.2,2.9-3.5l1.3-2
					c6.7-8.7,7-20.4,0.9-29.3c-1.1-2.9-2.9-5.5-5.1-7.7c-5.1-5.1-11.6-7.7-19.3-7.7c-0.6,0-1.3,0-2.1,0c-3.2-1.4-6.7-2.1-10.3-2.1
					c-1.5,0-2.9,0.1-4.2,0.3c-2.2-0.7-4.5-1-6.9-1c-1.8,0-3.6,0.2-5.3,0.6c-0.2-0.1-0.4-0.1-0.6-0.2c-0.6-0.1-1.1-0.3-1.7-0.5
					c-1.8-0.5-4.1-1.2-6.9-1.5c-1.9-1.3-3.8-2.4-5.6-3.3C370.1,33,366.6,32.2,363,32.2L363,32.2z"/>
			</g>
			<g id="st0">
				<path d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,34.2c-1.7,0-4.9,0-8.6,1.1c-1.3,0.4-2.5,0.8-3.7,1.4c-1.1-0.1-2.2-0.2-3.3-0.2
					c-3.7,0-7.3,0.7-11.1,2.3c-0.5,0.2-1.1,0.5-1.6,0.7c-3.4,1.7-6.3,3.8-8.7,6.2c-0.8,0.2-1.7,0.4-2.5,0.7
					c-6.7,2.2-12.1,7.4-15,14.6c-2.1,5.2-3.6,12.9-2.1,20.2c0.4,3,1.4,5.7,2.7,8.2c-3.7,5.5-4,11.1-4,14.2c-1.5,0.5-2.9,1.2-4.3,2.1
					c-0.2-0.1-0.4-0.3-0.5-0.4c-4.9-5.4-11.7-8.5-19.3-8.9c-0.9-1.4-1.9-2.7-3.1-3.9c-5.1-5.1-12.5-8.2-19.8-8.2
					c-3.2,0-10.2,0-16.9,5.4c-1.9,0.9-3.7,2.1-5.3,3.4c-0.8-0.6-1.7-1.1-2.5-1.6c0-2.6-0.4-5.2-1.2-7.8c-1.3-3.8-3.5-7.3-6.3-10.2
					c-3.3-3.3-7.5-5.3-12-6c-1.1-1.8-2.4-3.5-3.9-5c-2.2-2.1-8.1-7.2-16.8-7.2c-3.4,0-6.8,0.8-9.8,2.4c-0.9,0.1-1.8,0.3-2.7,0.6
					c-0.2,0-0.4,0-0.5,0c-5.4,0-10.7,1.9-15,5.4c-5.7,4.6-9,11.4-9,18.6c0,0,0,0.1,0,0.1c-5,4-7.5,8.8-8.8,11.5
					c-1.5,2.9-2.2,6.1-2.2,9.4c0,1.4,0,3.6,0.5,6.1c-0.9,3.3-1.4,6.8-1.5,9.7c0,0.1,0,0.2,0,0.4c-0.2,2-0.4,5.7,0.9,10.2
					c0.4,1.3,1,2.9,1.8,4.5c0,0,0,0.1,0,0.1c-3.6,1.2-7.7,3-11.4,6.3c-4.4,1.6-8.5,4.2-12.1,7.8c-0.1,0.1-0.1,0.1-0.2,0.2
					c-1,0.1-2.1,0.3-3.2,0.5c-2.2-0.6-4.9-1.2-8-1.2c-3.9,0-7.7,0.9-11.2,2.6c-9.5,4.8-12.1,13.7-13,16.7c-1.1,2.5-1.6,5.3-1.6,8
					c0,3.1,0,6.8,1.2,11.5c0,0.3-0.1,0.5-0.1,0.8c-0.1,0.5-0.4,1.3-0.6,2c-0.7,1.6-1.6,3.8-2.3,6.4c-0.5,0.7-1.1,1.5-1.7,2.4
					c-2.8,0.9-5.4,2.4-7.6,4.4c-4.4,3.6-7.3,7.7-8.8,12.3c-1.2,2.9-1.8,6.1-1.8,9.3c0,0.6,0,1.3,0.1,1.9c-2.5,4.5-3.3,9-3.9,12.6
					l0,0.3c-0.4,1.6-0.6,3.3-0.9,4.9c-2.4,1.8-4.8,4.2-6.7,7.4c-5.6,4.7-8.9,11.9-8.9,18.8c0,7.5,3.8,14.3,7.6,18.3
					c2.1,2.4,4.3,4.4,6.7,5.9c0.3,0.4,0.7,0.8,1,1.2c-0.3,1.5-0.4,3-0.4,4.6c0,2.8,0.6,8.9,5.4,14.4c-1.9,3.3-3.4,7.5-3.4,12.6
					c0,1.4,0.1,2.7,0.3,4c-0.8,1.5-1.6,3.3-2.2,5.3c-0.7,2.1-1.1,4.4-1.1,6.6c0,1.3,0,3.3,0.3,5.6c-0.4,2.6-0.4,5.3,0,7.9
					c-3.2,3.6-5.5,7.3-6.9,9.9c-1.1,1.5-2,3-2.8,4.5c-1.2,1.4-2.5,3-3.7,4.5c-3.4,4.6-7,10.3-7,17.6c0,2.7,0.4,5.3,1.1,7.7
					c1,7.1,5.1,14.6,14.3,20.8c0.7,0.5,1.5,0.9,2.3,1.3c4.3,2.2,8.8,3.3,13.3,3.3c1.2,0,2.5-0.1,3.9-0.3c0.9,1.4,2,2.8,3.3,4.1
					c1.6,1.6,3.4,2.9,5.5,3.9c3.3,1.7,6.8,2.5,10.4,2.5c3.9,0,9.1-1,14.2-5c5.3-1.2,10.2-4.1,14.6-8.4c0.5-0.5,1-1.1,1.5-1.6
					c1.2-0.5,2.4-1,3.7-1.7c0.3,0.9,0.7,1.9,1.2,2.9c2.6,5.1,6.9,9.1,12.2,11.7c-0.2,3.7,0.4,7,1.4,9.8c-0.1,0.7-0.1,1.5-0.2,2.2
					c-1.1,3.1-2.8,8.8-1.4,15.5c0.1,1.7,0.5,3.8,1.2,6c0.2,0.7,0.5,1.3,0.7,1.9c-0.1,0.6-0.1,1.2-0.1,1.7c0,4.9,1.3,9.3,3.6,13.1
					c2.1,5.6,6.2,9.6,11,11.8c3.5,1.9,7.5,2.9,11.8,2.9c1.5,0,3.1-0.1,4.8-0.4c0.1,0.1,0.3,0.3,0.4,0.4c3,2.6,6.1,4.1,7.7,4.8
					c0.1,0.1,0.2,0.1,0.3,0.2c6,7.7,13.7,9.1,17.6,9.2c0.3,0.5,0.7,1,1.1,1.5c2.2,4.6,5.9,8.4,10.8,10.8c3.7,1.8,7.2,2.5,9.9,3
					c0.2,0,0.5,0.1,0.7,0.1c1.2,0.7,2.6,1.4,4.2,2c1.9,1.7,4.1,3.1,6.6,4.4c2.3,1.1,4.8,1.9,7.3,2.1c1.6,0.4,3.2,0.5,4.8,0.5
					c1.3,0,2.6-0.1,3.8-0.3c0.5,0,1,0.1,1.5,0.1c1.9,0.6,3.9,0.8,5.9,0.8h3c0.8,0,1.9,0,3.1-0.2c0.9,0.4,1.8,0.8,2.7,1.1
					c2.7,1.2,6.6,2.6,11.4,2.6c3.9,0,7.7-0.9,11.2-2.6c1.1-0.5,2.1-1.2,3-1.9c1.4,0.1,2.6,0.1,3.6,0.1h5c7.1,0,13.1-2.4,17.8-7.2
					c4.1-4.1,6.4-9,7-14.8c2.8-2.3,5.3-5.5,7.2-10.2c2.3-4.4,4.5-11.7,1.8-20.5c2.3-3.5,4.1-8.2,4.1-14.4c0-1.1-0.1-2.5-0.3-4
					c1.6-2.1,2.9-4.3,3.8-6.6c0.4,0.1,0.7,0.1,1.1,0.2l3.3,0.8c1.7,0.4,3.4,0.6,5.1,0.6c7,0,13.1-2.8,17.2-7.4
					c3.9-0.3,7.6-1.3,11.1-3.1c0.3-0.1,0.6-0.3,0.9-0.4c2.8-0.8,5.3-2,7.5-3.5c7.6-1.3,12.9-5.9,15.8-11.4
					c2.8-4.6,5.1-11.8,2.5-21.5v-4.5c0.5-2.5,1.2-5.7,0.9-9.5l0.9-0.9c1.7-1.7,4.7-5,7.1-9.5c2.8,1.1,5.8,1.6,9.1,1.6
					c2.3,0,4.5-0.4,6.7-1c0.1,0,0.3,0,0.4-0.1c3,1.4,6.3,2.1,9.9,2.1c4.8,0,9.4-1.6,13.1-4.6c1.7-0.9,3.3-2.1,4.7-3.6
					c2.9-2.9,5-6.5,6.2-10.3c0.5-0.6,1-1.3,1.5-1.9c3.5-5.3,5.2-10.4,5.2-15.9c0.7-2,1.4-4.6,1.4-7.8c0-5-1.7-9.1-3.5-12.1
					c7.7-0.8,14.5-5.2,18.2-11.9c0.4,0,0.8,0,1.3,0c8.6,0,14.9-3.8,18.7-9c3.3-0.4,5.9-1.4,7.7-2.2c6.7-3.4,13.2-10.6,13.3-21.2
					c0.1-0.3,0.2-0.6,0.3-0.9c1.1-3.7,1.1-6.9,1.1-8.6c0-1.8-0.2-3.5-0.6-5.2c1.2-2.1,2.1-4.6,2.4-7.6c0.7-1,1.3-2,1.8-3.1
					c1.2,0.2,2.5,0.3,3.7,0.3c3.8,0,7.5-0.9,11.1-2.7c2-1,3.9-2.3,5.5-3.9c1.6-1.6,2.8-3.2,3.6-4.6l1.2-0.8c1.2-0.8,2.2-1.6,3.2-2.6
					c4.7-4.7,7.2-10.8,7.2-17.8c0-3.6-0.9-7.2-2.7-10.3c-0.4-4.7-2-9-3.4-12.4c-1.2-4-3-7.2-5-9.6c0.9-0.7,1.8-1.4,2.7-2.2
					c0.8-0.3,1.6-0.6,2.5-1c6.1-2.4,10.6-6.3,13.3-11.8c2.6-5.2,2.6-9.9,2.6-12.6c0-6.5-1.4-11.4-3.4-15.2
					c-0.8-3.8-2.6-7.3-5.2-10.1c-1.5-3.3-3.6-6.3-6.4-8.7l-0.2-0.4c-2.3-4.6-6.2-8.2-10.9-10.1c-0.1-0.1-0.2-0.1-0.4-0.2
					c-0.4-2.9-1.3-5.8-2.8-8.8c-1-2-2.3-3.9-3.9-5.5c-0.4-0.4-0.9-0.9-1.6-1.5c-3.1-4.4-7.8-7.5-13.1-8.9c-2.3-0.8-4.7-1.1-7.1-1.1
					c-6.8,0-13,3-17.2,7.9c-0.7,0.4-1.4,0.9-2.1,1.4c-0.2,0.2-0.5,0.3-0.7,0.5c-0.5,0-1.1,0-1.6,0c-5.1,0-9.1,1.1-13.3,2.5
					c-4.2,1.1-7.8,2.3-11,3.9c-5.8,1.3-10.2,4.2-13.5,7c-2.7-2.1-5.9-3.7-9.3-4.4c-2.1-2.9-4.9-5.3-8.2-7.1
					c-3.8-2.1-7.7-3.1-12.4-3.8c-3.3-1-7.5-2-12-2c-2.9,0-5.7,0.4-8.5,1.2c-0.9-0.2-1.7-0.4-2.6-0.5l-3.9-0.5c-0.5-1.4-1.2-2.7-2-4
					c-0.6-1.2-1.5-3-3-5.1c2.3,0.8,5.1,1.4,8.3,1.4h1c13.7,0,22.1-8.4,24.8-11.2c1-1,1.9-2,2.6-3.2l1.4-2.1c6.3-8,6.5-18.9,0.7-27.2
					c-1-2.7-2.6-5.2-4.7-7.3c-4.7-4.7-10.8-7.2-17.8-7.2c-0.7,0-1.5,0-2.5,0c-3.1-1.4-6.4-2.1-9.9-2.1c-1.5,0-3,0.1-4.3,0.4
					c-2.2-0.7-4.4-1-6.8-1c-1.8,0-3.6,0.2-5.4,0.7c-0.3-0.1-0.7-0.2-1-0.3c-0.6-0.1-1.2-0.3-1.8-0.5c-1.8-0.5-4.2-1.2-7.1-1.4
					c-2.1-1.5-4.2-2.7-5.8-3.5C369.5,35,366.3,34.2,363,34.2L363,34.2z M512.1,230.2L512.1,230.2L512.1,230.2L512.1,230.2z"/>
			</g>
			<g id="st0">
				<path d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,36.2c-4.9,0-8.9,1-12,2.5c-1.2-0.2-2.4-0.3-3.6-0.3c-3.4,0-6.8,0.7-10.4,2.1
					c-0.5,0.2-1,0.4-1.4,0.6c-3.4,1.7-6.3,3.8-8.6,6.3c-1,0.2-1.9,0.4-2.9,0.7c-6.2,2.1-11.1,6.8-13.8,13.4
					c-0.5,1.2-4,10.2-1.9,19.3c0.5,3.1,1.6,5.9,3.1,8.4c-4.4,5.9-4.4,12.1-4.4,14.9c0,0.2,0,0.4,0,0.6c-0.3,0.1-0.5,0.2-0.8,0.4
					c-2,0.6-3.9,1.6-5.6,2.9c-0.6-0.5-1.2-1-1.8-1.4c-4.8-5.3-11.6-8.4-19.1-8.4c-0.9-1.6-2-3.1-3.3-4.4c-4.7-4.7-11.6-7.6-18.4-7.6
					c-3,0-9.6,0-15.8,5.1c-2.3,1.1-4.4,2.5-6.2,4.3c-1.4-1.1-3-2.1-4.7-3c0.4-6.2-1.9-12.8-6.9-17.8c-3.2-3.2-7.4-5.1-11.8-5.5
					c-1.1-2-2.4-3.9-4.1-5.5c-2-2-7.4-6.6-15.4-6.6c-3.3,0-6.5,0.8-9.3,2.4c-1,0.2-1.9,0.4-2.8,0.6c-0.3,0-0.6,0-0.9,0
					c-4.9,0-9.8,1.7-13.7,4.9c-5.2,4.2-8.3,10.4-8.3,17.1c0,0.4,0,0.7,0,1.1c-5.2,3.8-7.7,8.8-9,11.4c-1.3,2.6-2,5.5-2,8.5
					c0,1.4,0,3.6,0.5,6.2c-0.9,3.2-1.5,6.6-1.5,9.7c0,0.2,0,0.3,0,0.4c-0.4,5.5,0.5,10.3,2.8,14.4c-0.2,0.7-0.3,1.3-0.4,2
					c-3.8,1.1-8.1,2.9-11.9,6.4c-4.2,1.4-8.2,3.9-11.8,7.5c-0.2,0.2-0.4,0.4-0.6,0.7c-1.4,0.1-2.8,0.3-4.2,0.6
					c-2.1-0.7-4.8-1.3-8-1.3c-3.6,0-7.1,0.8-10.3,2.4c-8.8,4.4-11.2,12.8-12,15.6c0,0,0,0,0,0.1c-1,2.3-1.5,4.8-1.5,7.4
					c0,3,0,6.7,1.2,11.3c-0.1,0.4-0.1,0.8-0.1,1.3c-0.2,0.6-0.5,1.5-0.7,2.3c-0.7,1.6-1.6,3.9-2.3,6.5c-0.7,0.9-1.5,2-2.2,3.2
					c-2.8,0.8-5.4,2.3-7.6,4.3c-4.1,3.3-6.8,7.1-8.2,11.4c-1.4,3.4-2,7.2-1.6,10.9c-2.6,4.3-3.4,8.7-4,12.6l-0.1,0.3
					c-0.4,1.9-0.7,3.8-0.9,5.6c-0.6,0.4-1.2,0.9-1.8,1.4c-2.4,2-4,4.2-5.2,6.3c-5.3,4.3-8.4,11-8.4,17.5c0,7,3.6,13.2,7.1,17
					c2,2.4,4.2,4.3,6.6,5.7c0.6,0.8,1.2,1.5,1.9,2.2c-0.4,1.7-0.6,3.4-0.6,5.2c0,2.7,0.7,8.8,5.9,14.2c-2.1,3.2-3.9,7.5-3.9,12.8
					c0,1.1,0.1,2.7,0.4,4.4c-0.9,1.6-1.7,3.4-2.4,5.6c-0.6,1.9-1,4-1,6c0,1.3,0,3.3,0.3,5.6c-0.5,2.8-0.5,5.7,0.2,8.5
					c-3.4,3.7-5.9,7.7-7.4,10.4c-1.1,1.5-2,3.1-2.8,4.5c-1.3,1.4-2.6,3.1-3.7,4.6c-3.2,4.3-6.5,9.6-6.5,16.4c0,2.6,0.3,5.1,1,7.3
					c0.9,6.6,4.8,13.7,13.4,19.5c0.7,0.4,1.3,0.8,2,1.2c4,2,8.2,3,12.4,3c1.5,0,3.1-0.1,4.9-0.5c0.9,1.6,2.1,3.3,3.7,4.9
					c1.4,1.4,3.1,2.6,4.9,3.6c3,1.5,6.2,2.3,9.5,2.3c5,0,9.4-1.6,13.4-4.8c5.1-1.1,9.8-3.8,14-8c0.6-0.6,1.2-1.3,1.7-2
					c2.1-0.8,4.1-1.8,6-2.9c0.4,1.6,1,3.3,1.8,5c2.6,5.1,7,9,12.5,11.3c-0.4,4.2,0.2,7.7,1.3,10.7c-0.1,1-0.2,2-0.3,2.9
					c-1,2.9-2.7,8.3-1.4,14.7c0.1,1.6,0.4,3.6,1.1,5.7c0.3,0.8,0.6,1.5,0.9,2.2c-0.1,0.8-0.1,1.5-0.1,2c0,4.5,1.3,8.7,3.4,12.2
					c1.9,5,5.5,8.6,9.7,10.7c2.8,1.6,6.6,2.9,11.3,2.9c1.7,0,3.5-0.2,5.4-0.5c0.4,0.3,0.7,0.7,1.1,1c2.8,2.5,5.7,3.8,7.2,4.5
					c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0,0,0,0,0.1,0.1c5.8,7.6,13.3,8.7,16.8,8.8c0.3,0.1,0.6,0.3,0.9,0.4
					c0.4,0.7,0.9,1.3,1.5,2c2,4.3,5.5,7.9,10,10.1c3.4,1.7,6.7,2.3,9.4,2.8c0.3,0.1,0.7,0.1,1.1,0.2c1.3,0.8,2.8,1.6,4.5,2.2
					c1.9,1.7,4,3.1,6.4,4.3c2.1,1.1,4.4,1.7,6.8,1.9c1.5,0.3,3,0.5,4.5,0.5c1.3,0,2.5-0.1,3.7-0.4c0.6,0,1.2,0.1,1.7,0.1
					c0.1,0,0.1,0,0.2,0c1.8,0.6,3.7,0.8,5.6,0.8h3c0.8,0,2-0.1,3.4-0.3c1,0.5,2.1,0.9,3.2,1.3c2.5,1.1,6.2,2.4,10.7,2.4
					c3.6,0,7-0.8,10.3-2.4c1.2-0.6,2.3-1.3,3.3-2.1c1.6,0.1,3.1,0.1,4.2,0.1h5c6.5,0,12.1-2.2,16.4-6.6c3.9-3.9,6.1-8.8,6.5-14.5
					c2.8-2.1,5.4-5.3,7.3-9.9c2.2-4.2,4.3-11.3,1.4-19.9c2.4-3.3,4.3-7.9,4.3-14.1c0-1.3-0.1-2.8-0.4-4.3c0.1-0.2,0.2-0.4,0.2-0.6
					c2-2.4,3.4-5.2,4.4-8c0.9,0.1,1.8,0.3,2.8,0.5l3.4,0.8c1.5,0.4,3.1,0.6,4.6,0.6c6.7,0,12.4-2.8,16.2-7.4c4-0.2,7.7-1.2,11.3-3
					c0.4-0.1,0.7-0.3,1-0.4c2.7-0.8,5.2-2,7.4-3.5c7.1-1.1,12.1-5.3,14.8-10.5c2.6-4.3,4.8-11,2.3-20.3v-5c0.6-2.7,1.3-5.9,0.9-9.9
					c0.1-0.2,0.3-0.4,0.4-0.6l1.1-1.1c1.8-1.8,5-5.4,7.4-10.3c0.5,0.1,1,0.1,1.5,0.2c2.7,1.1,5.6,1.6,8.7,1.6c2.1,0,4.2-0.3,6.3-1
					c0.4,0,0.7-0.1,1.1-0.2c2.9,1.4,6.1,2.2,9.6,2.2c4.4,0,8.6-1.5,12-4.3c1.6-0.9,3.1-2,4.4-3.3c2.8-2.8,4.8-6.2,5.8-9.8
					c0.6-0.7,1.1-1.3,1.6-2.1c3.4-5.1,5-9.9,4.8-15.1c0.7-1.8,1.4-4.4,1.4-7.4c0-5-1.8-9-3.6-11.8c-0.2-0.6-0.3-1.4-0.6-2.2
					c0.1,0,0.1,0,0.2,0c8.5,0,16-4.7,19.6-12.1c0.8,0.1,1.6,0.1,2.4,0.1c8.2,0,14.2-3.8,17.6-8.9c3.4-0.2,6.1-1.2,7.9-2.1
					c6.2-3.1,12.2-9.9,12.1-19.8c0.9-2.6,1.4-5.6,1.4-9.2c0-1.9-0.3-3.7-0.8-5.4c1.3-2.2,2.3-4.8,2.7-8.1c1-1.4,1.9-2.9,2.6-4.5
					c0,0,0.1,0,0.1,0c1.5,0.4,3.1,0.5,4.8,0.5c3.5,0,6.9-0.8,10.2-2.5c1.8-0.9,3.5-2.1,4.9-3.6c1.7-1.7,2.8-3.3,3.6-4.6l1.5-1
					c1-0.7,2-1.5,2.9-2.4c4.4-4.4,6.6-9.9,6.6-16.4c0-3.4-0.9-6.8-2.7-9.7c-0.4-4.7-1.9-8.9-3.3-12.3c-1.4-4.8-3.6-8.2-6.1-10.6
					c1.7-1.1,3.2-2.3,4.6-3.7c0.9-0.3,1.8-0.7,2.8-1.1c5.7-2.2,9.8-5.8,12.3-10.8c2.4-4.8,2.4-9.1,2.4-11.7c0-6.2-1.4-10.9-3.3-14.5
					c-0.7-3.6-2.4-7-5-9.7c0,0,0-0.1-0.1-0.1c-1.4-3.2-3.5-6.1-6.2-8.4l-0.4-0.7c-2.1-4.3-5.7-7.5-10-9.2c-0.4-0.2-0.9-0.4-1.3-0.6
					c-0.3-3.1-1.2-6.1-2.7-9.2c-0.9-1.8-2.1-3.5-3.6-4.9c-0.4-0.4-1-1-1.7-1.6c-2.9-4.2-7.2-7.1-12.2-8.3c-2.1-0.7-4.3-1.1-6.5-1.1
					c-6.4,0-12.1,2.9-15.9,7.4c-0.8,0.5-1.5,1-2.3,1.5c-0.4,0.3-0.8,0.6-1.2,0.9c-0.7,0-1.5-0.1-2.3-0.1c-4.8,0-8.7,1.1-12.7,2.4
					c-4.2,1.1-7.8,2.2-10.8,3.9c-6.4,1.4-10.9,4.8-14.1,7.8c-2.9-2.7-6.5-4.6-10.5-5.2c-2-2.9-4.7-5.4-7.9-7.2
					c-3.6-2-7.4-2.9-11.9-3.6c-3.2-1-7.2-1.9-11.6-1.9c-2.9,0-5.7,0.4-8.5,1.2c-0.9-0.3-1.9-0.5-2.9-0.6l-5.2-0.6c0,0,0-0.1,0-0.1
					c-0.5-1.7-1.3-3.2-2.2-4.7c-0.8-1.6-2.2-4.2-4.7-7.2c-0.9-1.5-1.9-2.8-2.8-4.1l1.1,0.5c0.1,0.1,0.3,0.2,0.4,0.2
					c2.1,1.2,6.5,3.8,13.1,3.8h1c12.9,0,20.8-8,23.4-10.6c0.9-0.9,1.7-1.9,2.4-2.9l1.4-2.1c5.8-7.4,6-17.5,0.6-25.1
					c-0.9-2.5-2.4-4.8-4.4-6.8c-4.4-4.4-9.9-6.6-16.4-6.6c-0.7,0-1.7,0-2.9,0.1c-2.9-1.4-6.2-2.1-9.5-2.1c-1.6,0-3.1,0.2-4.5,0.4
					c-2.1-0.7-4.4-1.1-6.6-1.1c-1.9,0-3.7,0.3-5.5,0.7c-0.5-0.2-1-0.3-1.4-0.4c-0.6-0.2-1.2-0.3-1.8-0.5c-2-0.6-4.4-1.2-7.3-1.4
					c-2.2-1.5-4.3-2.8-6-3.6C368.9,36.9,365.9,36.2,363,36.2L363,36.2z M337.6,132c1.7-0.2,3.4-0.7,5-1.2c0.2,0.3,0.4,0.6,0.5,0.8
					c-0.1,0.1-0.1,0.3-0.2,0.4c-2.1,0.9-4.1,2-5.8,3.5c-0.3,0.2-0.6,0.5-0.9,0.8C336.8,134.9,337.3,133.4,337.6,132L337.6,132z"/>
			</g>
			<g id="st0">
				<path d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,38.2c-1.6,0-4.4,0-7.5,0.9c-1.5,0.4-2.8,1-4.1,1.7c-1.2-0.2-2.6-0.3-4-0.3
					c-3.2,0-6.3,0.6-9.7,2c-0.4,0.2-0.9,0.4-1.3,0.6c-3.4,1.7-6.2,3.8-8.5,6.3c-1.1,0.1-2.2,0.4-3.2,0.7c-3.2,1.1-9.2,4.1-12.6,12.3
					c-1.6,3.8-3.3,11.1-1.8,17.9c0.5,3.4,1.8,6.5,3.7,9c-4.9,5.7-4.9,12-4.9,14.8c0,0.6,0,1.3,0.1,1.9c-0.7,0.2-1.5,0.5-2.2,0.9
					c-2.3,0.7-4.5,2-6.3,3.6c-0.3-0.3-0.7-0.6-1.1-0.9l0,0c-0.6-0.6-1.3-1.1-2-1.6c-4.5-5-10.8-7.9-17.9-7.9c-0.3,0-0.7,0-1,0
					c-0.2-0.3-0.3-0.5-0.5-0.8c-0.8-1.6-1.8-3-3.1-4.3c-4.4-4.4-10.7-7-17-7c-2.8,0-9.1,0-14.7,4.8c-2.8,1.2-5.2,3.1-7.1,5.4
					c-2.1-1.9-4.3-3.4-7-4.5c0.9-6.1-1.2-12.6-6.2-17.6c-3.1-3.1-7.3-4.9-11.7-5c-1-2.2-2.4-4.3-4.2-6c-1.8-1.8-6.8-6-14-6
					c-3,0-5.9,0.8-8.5,2.3c-1,0.1-2,0.3-3.1,0.6c-0.1,0-0.3,0.1-0.4,0.1c-0.3,0-0.7,0-1,0c-4.4,0-8.9,1.6-12.5,4.5
					c-4.8,3.8-7.5,9.5-7.5,15.5c0,0.6,0,1.2,0.1,1.8c-0.1,0.2-0.3,0.4-0.4,0.6c-5.2,3.6-7.6,8.5-8.9,11c-1.2,2.4-1.8,5-1.8,7.6
					c0,1.4,0,3.6,0.6,6.2c-0.9,3.1-1.6,6.7-1.6,9.7c0,0.2,0,0.4,0,0.5c-0.1,1.8-0.4,5,0.7,8.8c0.4,1.5,1.1,3.2,2.2,5
					c-0.3,1.2-0.6,2.5-0.9,3.8c0,0,0,0,0,0.1c-4,1-8.6,2.8-12.7,6.6c-4.1,1.3-7.9,3.7-11.4,7.2c-0.4,0.4-0.7,0.8-1.1,1.2
					c-1.7,0.1-3.5,0.4-5.2,0.8c-2.1-0.7-4.7-1.4-7.9-1.4c-3.3,0-6.5,0.7-9.4,2.2c-8,4-10.2,11.5-11,14.3c0,0.1,0,0.1-0.1,0.2
					c-0.9,2.1-1.4,4.4-1.4,6.7c0,3.2,0,6.7,1.2,11.2c-0.1,0.6-0.2,1.1-0.2,1.7c-0.2,0.6-0.5,1.7-0.8,2.7c-0.7,1.6-1.7,4-2.3,6.7
					c-0.8,1.1-1.8,2.4-2.7,4c-0.1,0-0.3,0.1-0.4,0.1c-2.7,0.7-5.2,2-7.3,4l0,0c-3.8,3.1-6.3,6.6-7.6,10.5c-1.1,2.5-1.7,5.2-1.7,8
					c0,1,0.1,1.9,0.2,2.8c-2.7,4.1-3.4,8.4-4.1,12.4l-0.1,0.4c-0.5,2.1-0.8,4.3-1,6.4c-2.7,1.6-5.3,4.1-7.3,7.7
					c-5,3.9-8,10.2-8,16.2c0,6.4,3.5,12.3,6.6,15.6c1.9,2.3,4.1,4.1,6.4,5.5c0.9,1.1,1.8,2.2,2.8,3.1c-0.5,1.8-0.8,3.8-0.8,5.8
					c0,2.6,0.7,8.8,6.5,13.9c-2.2,3-4.5,7.5-4.5,13.1c0,1.2,0.1,2.8,0.5,4.7c-1,1.6-1.9,3.6-2.7,5.9c-0.6,1.7-0.9,3.5-0.9,5.4
					c0,1.4,0,3.4,0.4,5.8c-0.5,2.7-0.5,5.6,0.2,8.3c0.1,0.2,0.1,0.5,0.2,0.7c-3.8,3.9-6.5,8.2-7.9,10.8c-1.1,1.5-2.1,3.1-2.9,4.7
					c-1.3,1.4-2.6,3.1-3.8,4.6c-3,4.1-6.2,9-6.2,15.2c0,2.5,0.3,4.8,1,6.9c0.8,6.1,4.4,12.8,12.6,18.2c0.6,0.4,1.2,0.7,1.8,1.1
					c3.8,1.9,7.6,2.8,11.5,2.8c2.1,0,4.1-0.3,6-0.8c0.9,1.9,2.2,3.9,4,5.8c1.3,1.3,2.8,2.4,4.4,3.2c2.7,1.4,5.7,2.1,8.6,2.1
					c4.6,0,8.8-1.6,12.5-4.7c4.9-0.9,9.4-3.5,13.5-7.6c0.7-0.7,1.3-1.4,1.8-2.2c2.9-1,5.8-2.6,8.8-4.8c0.2,2.3,0.8,4.9,2.1,7.5
					c2.6,5.2,7.1,9,12.9,10.9c-0.7,4.6-0.1,8.6,1.2,11.8c-0.1,1.2-0.2,2.4-0.4,3.6c-1,2.9-2.7,8-1.3,13.9c0.1,1.5,0.4,3.3,1,5.3
					c0.3,0.9,0.6,1.7,1,2.5c-0.1,0.9-0.2,1.7-0.2,2.4c0,4.2,1.2,8.1,3.2,11.3c1.7,4.6,4.9,7.9,8.7,9.8c2.6,1.5,6,2.7,10.4,2.7
					c1.9,0,3.8-0.2,6.1-0.7c0.5,0.6,1.1,1.1,1.7,1.7c2.6,2.3,5.2,3.5,6.8,4.2c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.1,0.3,0.1,0.5,0.2
					c0.2,0.1,0.3,0.2,0.5,0.3c5.5,7.4,12.6,8.3,15.7,8.3c0,0,0.1,0,0.1,0c0.5,0.2,1.1,0.5,1.7,0.7c0.5,0.9,1.2,1.8,1.9,2.7
					c1.8,4,5,7.2,9.1,9.3c3.2,1.6,6.2,2.1,8.9,2.6c0.5,0.1,1,0.2,1.5,0.3c1.3,0.9,2.9,1.7,4.8,2.3c1.8,1.7,3.9,3.1,6.3,4.3
					c1.9,1,4.1,1.6,6.2,1.7c1.6,0.4,3.1,0.5,4.1,0.5c1.2,0,2.4-0.1,3.6-0.4c0.6,0.1,1.2,0.1,1.8,0.1c0.2,0,0.3,0,0.5,0
					c1.7,0.6,3.5,0.9,5.3,0.9h3c0.9,0,2.2-0.1,3.6-0.4c1.2,0.6,2.4,1.1,3.7,1.5c2.4,1.1,5.8,2.3,9.9,2.3c3.3,0,6.4-0.7,9.4-2.2
					c1.3-0.6,2.5-1.4,3.6-2.4c1.9,0.2,3.6,0.2,4.8,0.2h5c6,0,11.1-2,15-6c3.8-3.8,5.8-8.5,6-14.1c2.8-2,5.5-5,7.5-9.7
					c2.1-4,4.2-11,0.9-19.4c2.5-3.1,4.6-7.5,4.6-13.8c0-1.3-0.1-2.9-0.5-4.4c0.2-0.6,0.4-1.1,0.5-1.5c2.3-2.8,3.8-6.1,4.7-9.1
					c1.4,0.2,2.9,0.4,4.6,0.7l3.4,0.9c1.3,0.3,2.7,0.5,4.1,0.5c6.5,0,12-2.8,15.3-7.3c4-0.1,7.8-1,11.4-2.9c0.4-0.2,0.8-0.3,1.2-0.5
					c2.7-0.8,5.2-2,7.3-3.5c6.5-0.9,11-4.5,13.6-9.1c2.7-4.1,4.9-10.5,2.3-19.5V409c0-0.1,0-0.1,0-0.2c0.6-2.7,1.3-6.1,0.8-10.1
					c0,0,0,0,0,0c0.3-0.4,0.7-0.8,1-1.3l1.3-1.3c1.9-1.9,5.3-5.8,7.6-11.1c1,0.2,2.1,0.4,3.1,0.5c2.5,1,5.3,1.6,8.2,1.6
					c2,0,4-0.3,5.9-0.9c0.6-0.1,1.2-0.2,1.8-0.3c2.7,1.5,5.9,2.2,9.3,2.2c4,0,7.8-1.4,10.9-3.9c1.5-0.8,2.9-1.8,4.1-3
					c2.6-2.6,4.5-5.9,5.4-9.4c0.6-0.7,1.2-1.4,1.7-2.2c3.2-4.8,4.7-9.4,4.5-14.3c0.7-1.7,1.4-4.1,1.4-7.1c0-4.7-1.7-8.4-3.5-11
					c0-0.1,0-0.1,0-0.2c-0.2-0.8-0.5-2-1-3.3c0.1-0.6,0.2-1.1,0.2-1.7c0.7,0.1,1.5,0.2,2.3,0.2c8.2,0,15.4-4.8,18.4-12.3
					c1.2,0.2,2.4,0.3,3.6,0.3c7.9,0,13.5-3.8,16.5-8.8c3.6-0.1,6.4-1.1,8.1-2c5.7-2.8,11.1-9,11-18.2c0.2-0.5,0.3-1,0.5-1.5
					c0.9-3.2,0.9-5.9,0.9-7.5c0-2-0.3-3.9-1-5.7c1.5-2.3,2.6-5,2.9-8.6c1.3-1.6,2.3-3.5,3-5.5c0.5-0.1,1-0.3,1.6-0.4
					c1.6,0.4,3.2,0.6,4.8,0.6c3.2,0,6.3-0.8,9.3-2.2c1.6-0.8,3.1-1.9,4.4-3.2c1.7-1.7,2.8-3.4,3.5-4.6l1.9-1.3
					c0.9-0.6,1.8-1.3,2.6-2.1c4-4,6-9,6-15c0-3.2-0.9-6.4-2.6-9.1c-0.3-4.6-1.8-8.8-3.2-12.4c-1.7-5.6-4.5-9.2-7.6-11.5
					c2.7-1.4,5-3.2,7-5.2c1-0.3,2-0.7,3-1.2c5.2-2,9-5.3,11.3-9.9c2.2-4.3,2.2-8.3,2.2-10.9c0-1.6,0-4.8-1-8.3
					c-0.5-2-1.3-3.8-2.3-5.5c-0.6-3.5-2.2-6.7-4.7-9.2c0,0,0,0-0.1-0.1c-0.1-0.2-0.1-0.3-0.2-0.5c-1.3-3.1-3.4-5.8-6-7.9l-0.5-1.1
					c-1.9-3.8-5.2-6.8-9.1-8.3c-0.8-0.4-1.6-0.7-2.4-1c-0.1-3.2-0.9-6.5-2.5-9.7c-0.8-1.6-1.9-3.1-3.2-4.4c-0.4-0.4-1-1-1.7-1.7
					c-2.6-3.9-6.6-6.6-11.3-7.7c-1.9-0.7-3.9-1-6-1c-5.9,0-11.1,2.7-14.6,7c-0.8,0.5-1.6,1-2.4,1.6c-0.6,0.4-1.2,0.8-1.7,1.3
					c-1-0.1-1.9-0.1-2.9-0.1c-4.6,0-8.3,1-12.2,2.3c-4.2,1.1-7.7,2.2-10.6,3.9c-6.3,1.3-10.7,4.7-14,7.9c-0.3,0.3-0.7,0.6-1,0.8
					c-2.9-3.3-7-5.6-11.6-6.1c-1.8-3-4.5-5.5-7.8-7.3c-3.4-1.8-7-2.7-11.4-3.4c-3.1-1-7-1.9-11.1-1.9c-2.9,0-5.7,0.4-8.5,1.3
					c-1-0.3-2-0.6-3.1-0.7l-6.3-0.8c-0.2-0.5-0.3-0.9-0.5-1.2c-0.5-1.6-1.2-3.1-2.1-4.4c-1-2-2.3-4.4-4.6-7c-1.3-2-2.5-3.8-3.8-5.3
					c0.8-0.8,1.4-1.6,2-2.5l2.6,1.3c0.1,0,0.3,0.2,0.5,0.3c2,1.1,6,3.5,12.1,3.5h1c12,0,19.6-7.5,22-10c0.8-0.8,1.5-1.7,2.1-2.6
					l1.5-2.2c5.3-6.8,5.5-16,0.4-22.9c-0.8-2.4-2.2-4.5-4-6.3c-4-4-9-6-15-6c-0.8,0-2,0-3.3,0.1c-2.8-1.4-5.9-2.2-9.1-2.2
					c-1.7,0-3.2,0.2-4.6,0.5c-2.1-0.8-4.2-1.2-6.5-1.2c-1.9,0-3.7,0.3-5.5,0.8c-0.6-0.2-1.2-0.4-1.9-0.6c-0.6-0.2-1.2-0.3-1.9-0.5
					c-2-0.6-4.5-1.3-7.4-1.4c-2.3-1.6-4.5-2.9-6.2-3.8C368.2,38.8,365.6,38.2,363,38.2L363,38.2z M335.9,130.1
					c2.8-0.2,5.3-0.9,7.6-2c0,0,0,0.1,0.1,0.1c0.6,1.2,1.3,2.2,1.9,3.1c-0.4,0.7-0.7,1.4-1,2.1c-4.5,1.6-8.2,4.7-10.5,8.7
					c-1.4,0.3-2.6,0.7-3.8,1.1c-0.4-0.4-0.7-0.9-1.1-1.3c0.9-0.8,1.6-1.4,2-1.9C333.8,137.5,335.5,133.9,335.9,130.1L335.9,130.1z"
					/>
			</g>
			<g id="st0">
				<path d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,40.2c-1.5,0-4.1,0-7,0.8c-1.6,0.4-3,1.1-4.4,1.9c-1.3-0.3-2.8-0.5-4.3-0.5
					c-2.9,0-5.8,0.6-8.9,1.8c-0.4,0.2-0.8,0.3-1.1,0.5c-3.4,1.7-6.2,3.9-8.5,6.5c-1.2,0.1-2.4,0.3-3.6,0.7c-2.9,1-8.3,3.7-11.4,11.2
					c-1.5,3.6-3.2,10.6-1.7,16.9c0.5,3.6,2.1,6.8,4.4,9.4c-0.1,0.1-0.1,0.1-0.2,0.2c-5.4,5.4-5.4,11.6-5.4,14.6
					c0,1.1,0.1,2.2,0.4,3.3c-1.3,0.3-2.5,0.7-3.7,1.3c-2.3,0.7-4.5,2-6.3,3.8c-0.3,0.3-0.5,0.5-0.8,0.8c-0.7-0.7-1.5-1.5-2.4-2.2
					l-0.1-0.1c-0.6-0.6-1.3-1.1-2-1.6c-4.1-4.7-10-7.4-16.5-7.4c-0.7,0-1.4,0-2.1,0.1c-0.3-0.6-0.7-1.2-1.1-1.8
					c-0.7-1.4-1.7-2.7-2.8-3.9c-4-4-9.8-6.4-15.6-6.4c-2.8,0-8.5,0-13.7,4.5c-3.3,1.4-6.1,3.8-7.9,6.7c-0.3-0.3-0.5-0.6-0.8-0.8
					c-2.5-2.5-5.2-4.3-8.8-5.5c0.7-3.1,0.6-6.4-0.5-9.6c-1-2.9-2.7-5.7-4.9-7.9c-2.8-2.8-6.6-4.4-10.6-4.4h-1
					c-0.9-2.4-2.4-4.7-4.3-6.6c-1.6-1.6-6.1-5.4-12.6-5.4c-2.8,0-5.5,0.8-7.8,2.2c-1,0.1-2.1,0.3-3.2,0.6c-0.2,0.1-0.5,0.1-0.7,0.2
					c-0.4,0-0.8,0-1.3,0c-8.7,0-18,7.2-18,18c0,0.8,0,1.6,0.1,2.4c-0.4,0.5-0.7,1-1,1.4c-4.9,3.3-7.2,7.8-8.5,10.4
					c-1,2.1-1.6,4.4-1.6,6.7c0,1.5,0,3.7,0.6,6.3c-1,3.3-1.6,6.9-1.6,9.7c0,0.1,0,0.4,0,0.6c-0.1,1.7-0.4,4.6,0.6,8.1
					c0.4,1.5,1.2,3.4,2.5,5.3c-0.4,1.4-0.8,2.9-1.1,4.6c-0.1,0.4-0.2,0.9-0.2,1.3c-4.1,0.9-9.1,2.6-13.3,6.7
					c-3.9,1.1-7.7,3.4-11.1,6.8c-0.5,0.5-1,1.1-1.5,1.7c-2.2,0.1-4.3,0.4-6.3,0.9h-0.1c-2-0.7-4.6-1.4-7.7-1.4c-3,0-5.8,0.7-8.5,2
					c-7.2,3.6-9.2,10.5-9.9,13.1c0,0.1-0.1,0.2-0.1,0.3c-0.8,1.9-1.3,4-1.3,6c0,3.1,0,6.6,1.3,11.1c-0.1,0.7-0.2,1.4-0.3,2.1
					c-0.2,0.7-0.6,2-1,3.2c-0.7,1.5-1.7,4-2.3,6.9c-1,1.2-2.1,2.8-3.2,4.7c-0.4,0.1-0.8,0.3-1.1,0.4c-2.4,0.6-4.7,1.8-6.6,3.6
					l-0.1,0.1c-3.4,2.8-5.8,6-6.9,9.5c-1.5,3.4-1.9,7.1-1.3,10.7c-2.8,4-3.5,8.2-4.2,12.3l-0.1,0.5c-0.6,2.4-0.9,4.9-1.1,7.3
					c-2.8,1.5-5.6,3.9-7.7,7.8c-4.7,3.5-7.6,9.3-7.6,14.9c0,5.9,3.2,11.3,6.1,14.3l0,0c1.9,2.2,4,4,6.2,5.3c1.1,1.5,2.4,2.9,3.7,4.1
					c-0.7,2-1.1,4.1-1.1,6.4c0,3.1,1,8.9,7.2,13.5c0,0.1,0,0.2-0.1,0.3c-2.3,2.8-5.1,7.2-5.1,13.2c0,1.2,0.1,3,0.7,5
					c-1.1,1.7-2.1,3.7-2.9,6.2c-0.5,1.5-0.8,3.1-0.8,4.7c0,1.4,0,3.4,0.4,5.7c-0.5,2.6-0.5,5.3,0.1,7.8c0.1,0.6,0.3,1.2,0.5,1.7
					c-4.1,4-7,8.7-8.5,11.3c-1.1,1.5-2.1,3.1-2.9,4.7c-1.3,1.4-2.7,3.1-3.8,4.7c-3,4-5.8,8.4-5.8,14c0,1.1,0,3.6,1,6.5
					c0.6,5,3.3,11.4,11.7,17c0.5,0.3,1.1,0.7,1.6,0.9c3.5,1.7,7.1,2.6,10.6,2.6c2.5,0,4.9-0.4,7.2-1.2c0.8,2.2,2.1,4.6,4.3,6.8
					c1.1,1.1,2.5,2.1,3.9,2.8c2.5,1.2,5.1,1.9,7.8,1.9c4.3,0,8.2-1.5,11.6-4.6c4.7-0.8,9.1-3.2,13-7.1c0.7-0.7,1.3-1.4,1.8-2.2
					c0.1-0.1,0.2-0.2,0.3-0.3c4.6-1.4,8.8-4.3,12.1-7.4c-0.4,3-0.3,6.8,1.8,11c2.6,5.1,7.3,8.7,13.2,10.4c0,0.2,0.1,0.4,0.1,0.6
					c-1,4.9-0.4,9,1,12.3c-0.1,1.5-0.3,3-0.5,4.4c-0.9,2.6-2.6,7.5-1.3,13.1c0.1,1.4,0.3,3.2,1,5c0.3,1,0.7,1.9,1.2,2.8
					c-0.2,1.1-0.2,2-0.2,2.7c0,3.9,1.1,7.4,3,10.3c1.6,4.6,4.8,7.7,8.6,9.3c2.3,1.2,5.2,2.1,8.8,2.1c2,0,4.2-0.3,6.9-0.9
					c0.7,0.8,1.5,1.6,2.3,2.4c2.4,2.1,4.8,3.2,6.3,3.9c0.2,0.1,0.3,0.1,0.5,0.2c0.2,0.1,0.4,0.2,0.6,0.3c0.3,0.2,0.6,0.4,0.8,0.6
					c4.8,6.7,10.8,7.7,14.3,7.7c0.2,0,0.4,0,0.6,0c0.8,0.4,1.7,0.8,2.7,1.2c0.6,1.1,1.3,2.1,2.2,3.2c1.6,3.7,4.5,6.7,8.3,8.6
					c2.9,1.5,5.8,2,8.3,2.4c0.6,0.1,1.2,0.2,1.9,0.3c0,0,0,0,0,0c1.4,0.9,3.1,1.8,5.1,2.5c1.7,1.7,3.7,3.1,6.1,4.3
					c1.8,0.9,3.7,1.4,5.7,1.5c1.5,0.4,2.8,0.5,3.8,0.5c1.2,0,2.3-0.1,3.5-0.4c0.7,0.1,1.3,0.1,2,0.1c0.3,0,0.5,0,0.8,0
					c1.6,0.6,3.3,0.9,5,0.9h3c0.9,0,2.3-0.1,3.9-0.5c1.3,0.7,2.7,1.3,4.1,1.8c2.3,1,5.4,2.1,9.2,2.1c3,0,5.8-0.7,8.5-2
					c1.4-0.7,2.7-1.6,3.8-2.7c2.2,0.3,4.1,0.3,5.5,0.3h5c2,0,8.2,0,13.6-5.4c5.4-5.4,5.4-11.6,5.4-13.6c0,0,0-0.1,0-0.1
					c2.9-1.7,5.7-4.7,7.6-9.5c2-3.9,4.1-10.6,0.4-19c2.6-2.8,4.9-7.1,4.9-13.4c0-1.4-0.1-2.9-0.6-4.6c0.4-0.9,0.6-1.6,0.8-2.2
					c0-0.1,0-0.1,0.1-0.2c2.7-3.1,4.2-7,5-10.4c1.9,0.2,4,0.6,6.5,1l3.5,0.9c1.2,0.3,2.4,0.4,3.6,0.4c6.2,0,11.3-2.8,14.3-7.4
					c0.2,0,0.5,0,0.7,0c3.9,0,7.5-0.9,10.9-2.7c0.5-0.2,0.9-0.4,1.3-0.5c2.7-0.7,5.1-2,7.2-3.5c6.4-0.7,10.8-4.3,13-8.8
					c2.3-3.8,4-9.6,1.6-17.6v-5.9c0-0.1,0.1-0.3,0.1-0.4c0.6-2.7,1.3-5.7,0.7-9.3c0-0.3-0.1-0.6-0.2-0.9c0.6-0.6,1.1-1.2,1.5-1.9
					l1.4-1.4c2-2,5.7-6.3,7.9-12.1c1.5,0.5,3.1,0.9,4.8,0.9c2.3,1,5,1.6,7.7,1.6c1.8,0,3.7-0.3,5.4-0.9c0.8-0.1,1.6-0.2,2.6-0.5
					c2.6,1.6,5.7,2.4,9,2.4c3.6,0,7.1-1.3,9.8-3.6c1.4-0.7,2.7-1.6,3.8-2.8c2.5-2.5,4.2-5.5,4.9-8.8c0.1-0.2,0.2-0.3,0.3-0.5
					c0.6-0.6,1.2-1.3,1.6-2c3.1-4.7,4.4-8.9,4.1-13.6c0.7-1.5,1.4-3.9,1.4-6.8c0-4.4-1.7-7.8-3.3-10.1c0,0,0-0.1,0-0.1
					c0-0.1-0.1-0.3-0.1-0.5c-0.2-0.9-0.5-2.2-1.1-3.6c0.3-1.6,0.4-3,0.5-4.3c1.3,0.4,2.7,0.6,4.1,0.6c7.6,0,14-4.4,16.7-11.5
					c0.2-0.4,0.3-0.8,0.4-1.2c1.6,0.5,3.3,0.7,4.9,0.7c7.7,0,12.9-3.9,15.4-8.9c0.3,0,0.5,0,0.8,0c3.3,0,5.9-0.9,7.5-1.7
					c2-1,10.3-5.8,9.9-16.8c0.2-0.5,0.4-1,0.5-1.6c0.8-2.9,0.8-5.5,0.8-7c0-2.1-0.4-4.1-1.2-6c1.8-2.4,3.1-5.2,3.2-9.2
					c1.5-1.8,2.7-3.9,3.4-6.2c1.1-0.3,2.1-0.5,3.1-0.9c1.6,0.5,3.2,0.7,4.9,0.7c2.9,0,5.7-0.7,8.4-2c1.4-0.7,2.8-1.7,3.9-2.8
					c1.7-1.7,2.8-3.4,3.4-4.6l2.3-1.6c0.8-0.5,1.6-1.2,2.3-1.9c5.4-5.4,5.4-11.6,5.4-13.6c0-3-0.9-5.9-2.5-8.3l-0.1-0.2
					c-0.2-4.5-1.7-8.8-3.1-12.3c-2.1-7-6-10.5-9.9-12.5c0.1-0.1,0.1-0.1,0.2-0.2c3.8-1.4,7.3-3.8,10-6.5c1-0.3,2.1-0.7,3.3-1.2
					c12.2-4.6,12.2-15.3,12.2-18.9c0-5.8-1.3-10-3.2-13.2c-0.5-3.2-1.9-6.1-4.2-8.4c-0.1-0.1-0.2-0.2-0.4-0.4
					c-0.1-0.3-0.2-0.5-0.3-0.8c-1.2-2.9-3.2-5.5-5.8-7.4l-0.7-1.5c-1.7-3.4-4.7-6-8.1-7.3c-1.1-0.6-2.3-1-3.6-1.4
					c0.2-3.4-0.6-6.8-2.3-10.3c-0.7-1.4-1.7-2.8-2.8-3.9c-0.5-0.5-1.1-1.1-1.9-1.8c-2.4-3.7-6.1-6.2-10.4-7.2
					c-1.7-0.6-3.4-0.9-5.3-0.9c-5.4,0-10.2,2.6-13.3,6.6c-0.8,0.5-1.7,1-2.5,1.7c-0.8,0.5-1.5,1.1-2.2,1.7c-1.2-0.1-2.4-0.2-3.6-0.2
					c-4.3,0-7.7,1-11.6,2.2c-4.2,1.1-7.6,2.2-10.5,3.8c-6,1.1-10.2,4.4-13.4,7.4c-1.1,1-2,1.7-2.6,2.2l0,0
					c-2.6-3.8-6.8-6.4-11.6-6.9l-0.9-0.1c-1.7-3.1-4.2-5.6-7.5-7.4c-3.2-1.7-6.7-2.6-10.9-3.2c-3-0.9-6.7-1.8-10.7-1.8
					c-2.9,0-5.6,0.5-8.4,1.4c-0.2,0-0.4-0.1-0.6-0.1c-0.9-0.3-1.9-0.5-2.9-0.7l-7.6-0.9c-0.3-0.9-0.6-1.7-0.9-2.4
					c-0.4-1.5-1.1-2.9-1.9-4.1c-0.7-1.5-2-4-4.4-6.8c-1.7-2.7-3.2-4.9-5.1-6.6c1.4-1.1,2.5-2.4,3.4-3.8c0.4-0.3,0.9-0.6,1.2-1
					l3.8,1.9c0.1,0.1,0.4,0.2,0.6,0.4c1.8,1,5.6,3.2,11.1,3.2h1c11.2,0,18.3-7.1,20.6-9.4c0.7-0.7,1.3-1.5,1.9-2.3l1.5-2.2
					c5-6.2,5.1-14.6,0.3-20.9c-0.7-2.2-2-4.2-3.6-5.8c-5.4-5.4-11.6-5.4-13.6-5.4c-0.9,0-2.2,0-3.7,0.1c-2.7-1.4-5.6-2.2-8.7-2.2
					c-1.7,0-3.3,0.2-4.8,0.6c-2-0.8-4.1-1.3-6.3-1.3c-1.9,0-3.8,0.3-5.6,1c-0.7-0.3-1.5-0.6-2.3-0.8c-0.7-0.2-1.3-0.3-1.9-0.5
					c-2.1-0.6-4.6-1.3-7.5-1.3c-2.3-1.7-4.6-3.1-6.4-4C367.6,40.7,365.3,40.2,363,40.2L363,40.2z M334,128.2c1.6,0,6,0,10.5-2.9
					c0.2,0.7,0.5,1.4,0.9,2.1c0.8,1.6,1.7,2.8,2.5,3.8c-0.7,1.3-1.4,2.6-2,3.9c-4.7,1.5-8.5,4.7-10.7,8.9c-2.2,0.3-4.1,1-5.7,1.8
					c-1-1.5-2.3-2.8-3.6-3.9c1.8-1.2,3-2.4,3.7-3.1C332.4,136,334,132.2,334,128.2L334,128.2z M464,351.2L464,351.2L464,351.2
					L464,351.2z M110,414.3C110,414.3,110,414.3,110,414.3L110,414.3L110,414.3z M312.7,501.6C312.7,501.6,312.7,501.6,312.7,501.6
					L312.7,501.6L312.7,501.6z"/>
			</g>
			<g id="st0">
				<path d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,42.2c-1.5,0-3.8,0-6.4,0.8c-1.6,0.5-3.2,1.2-4.6,2.1c-1.4-0.4-3-0.6-4.7-0.6
					c-2.6,0-5.3,0.6-8.2,1.7c-0.3,0.1-0.7,0.3-1,0.4c-3.4,1.7-6.2,4-8.4,6.6c-1.3,0-2.7,0.2-3.9,0.7c-3.2,1.1-7.5,3.7-10.2,10
					c-1.3,3.1-3,9.8-1.6,15.7c0.6,4,2.5,7.5,5.4,9.9c-0.6,0.4-1.2,0.9-1.8,1.5c-4.8,4.8-4.8,10.3-4.8,13.2c0,1.4,0.2,2.8,0.7,4.1
					c0.1,0.2,0.2,0.5,0.3,0.8c-1.9,0.2-3.8,0.7-5.6,1.6c-2.1,0.6-4,1.7-5.6,3.3c-0.8,0.8-1.5,1.7-2.1,2.6c-1.4-1.9-2.9-3.1-3.7-3.8
					c0,0-0.1-0.1-0.1-0.1c-0.6-0.6-1.3-1.1-2.1-1.6c-3.8-4.4-9.2-6.9-15.2-6.9c-1.1,0-2.2,0.1-3.4,0.3c-0.4-1-0.9-2-1.6-3
					c-0.6-1.3-1.5-2.4-2.5-3.5c-3.6-3.6-9-5.8-14.2-5.8c-2.8,0-7.9,0-12.6,4.3c-4.1,1.7-7.3,4.9-8.7,8.9c-0.7-1.4-1.6-2.5-2.5-3.4
					c-2.7-2.7-5.6-4.4-9.8-5.6c0-0.1,0-0.2,0.1-0.3c2-5.6,0.5-12.2-4.4-17.1c-2.4-2.4-5.7-3.8-9.2-3.8h-2c-0.2,0-0.3,0-0.5,0
					c-0.7-2.6-2.2-5.2-4.2-7.2c-1.4-1.4-5.4-4.8-11.2-4.8c-2.5,0-5,0.7-7.1,2.1c-1,0.1-2.1,0.3-3.3,0.6c-0.3,0.1-0.7,0.2-1,0.3
					c-0.5-0.1-1-0.1-1.5-0.1c-7.7,0-16,6.4-16,16c0,1,0.1,2,0.2,3c-0.6,0.7-1.2,1.5-1.7,2.3c0,0,0,0-0.1,0c-4.7,3-6.9,7.4-8.1,9.8
					c-0.9,1.8-1.4,3.8-1.4,5.8c0,1.6,0,3.8,0.7,6.3c-1,3.3-1.7,6.9-1.7,9.7c0,0.1,0,0.5,0,0.8c-0.2,2.2-0.6,7.8,3.3,12.9
					c-0.5,1.6-0.9,3.3-1.3,5.3c-0.2,0.8-0.3,1.7-0.4,2.6c-4.2,0.8-9.7,2.3-14.1,6.8c-3.8,1-7.4,3.2-10.7,6.4
					c-0.7,0.7-1.3,1.4-1.8,2.3c-2.5,0.1-4.9,0.3-7.1,0.9h-0.7c-2-0.7-4.4-1.4-7.4-1.4c-2.7,0-5.2,0.6-7.6,1.8
					c-6.4,3.2-8.2,9.3-8.9,11.8c0,0.1-0.1,0.3-0.1,0.4c-0.8,1.7-1.2,3.5-1.2,5.3c0,3.2,0,6.6,1.3,11.1c0,0.1-0.1,0.3-0.1,0.4
					c-0.1,0.7-0.2,1.4-0.2,2.1c-0.2,0.8-0.7,2.3-1.1,3.6c-0.7,1.5-1.7,4.1-2.4,7.1c-1,1.2-2.5,3.2-3.6,5.5c-0.8,0.2-1.4,0.5-1.9,0.6
					c-2.2,0.5-4.2,1.6-5.9,3.2c0,0-0.1,0.1-0.1,0.1c-1.3,1.1-4.8,3.9-6.3,8.7c-1.5,3.3-1.8,7-1,10.5c-3,3.8-3.7,7.9-4.4,12.1
					l-0.1,0.5c-0.6,2.7-1,5.5-1.1,8.1c-3,1.4-5.9,3.8-8,7.9c-4.4,3-7.1,8.4-7.1,13.6c0,5.3,3,10.2,5.6,13l0.1,0.1
					c0.9,1,2.8,3.4,6.1,5c1.3,1.9,3,3.6,4.7,5c-0.9,2.1-1.4,4.4-1.4,6.9c0,2.8,0.9,8.2,7,12.3c0.1,0.1,0.3,0.2,0.4,0.3
					c0,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.2-0.1,0.3c-0.1,0.4-0.2,0.9-0.3,1.3c-2.2,2.5-5,6.7-5,12.3c0,1.5,0.2,3.4,0.8,5.4
					c-1.2,1.7-2.2,3.7-3.2,6.5c-0.4,1.3-0.7,2.7-0.7,4.1c0,1.5,0,3.5,0.4,5.8c-0.6,2.4-0.6,4.9,0,7.3c0.2,0.9,0.5,1.8,0.9,2.7
					c-4.5,4.1-7.7,9.2-9.2,11.9c-1.2,1.5-2.1,3.2-2.9,4.7c-1.3,1.4-2.7,3.1-3.9,4.7c-2.8,3.7-5.3,7.8-5.3,12.8c0,1.1,0,3.4,0.9,6
					c0.5,4.6,3,10.5,10.9,15.8c0.4,0.3,0.9,0.6,1.4,0.8c3.2,1.6,6.5,2.4,9.7,2.4c2.7,0,5.4-0.6,7.9-1.6c0.3,0.1,0.5,0.2,0.8,0.2
					c0.6,2.4,1.8,5.1,4.3,7.6c1,1,2.1,1.8,3.4,2.4c2.2,1.1,4.5,1.6,6.9,1.6c5.2,0,8.7-2.5,10.7-4.5c6.8-0.9,11-5.1,12.5-6.6
					c0.6-0.6,1.1-1.3,1.6-2c0.2-0.2,0.5-0.6,0.9-0.9c4.7-1.3,9.2-4.5,12.4-7.7c1.2-0.2,2.3-0.5,3.4-0.9c0,0.1,0,0.2,0.1,0.2
					c-0.9,2.9-1.8,7.5,0.9,12.9c2.4,4.8,7,8.2,12.8,9.5c0.2,0.1,0.3,0.2,0.5,0.4c0.1,0.7,0.2,1.4,0.3,2c-1,4.8-0.4,8.7,1,11.8
					c-0.1,1.8-0.3,3.6-0.6,5.3c0,0,0,0.1,0,0.1c-0.9,2.6-2.5,7.1-1.2,12.2c0.1,1.4,0.3,2.9,0.9,4.6c0.4,1.1,0.8,2.1,1.4,3.1
					c0,0,0,0,0,0c-0.2,1.2-0.3,2.2-0.3,3c0,3.5,1,6.8,2.8,9.4c1.3,4.1,4.1,6.7,7.1,8.1c2.1,1.2,4.9,2.2,8.5,2.2
					c2.2,0,4.6-0.3,7.7-1.1c0.8,1.1,1.7,2.2,2.8,3.1c2.2,1.9,4.5,3,5.8,3.6c0.2,0.1,0.3,0.2,0.5,0.2c0.2,0.1,0.4,0.2,0.7,0.3
					c0.4,0.3,0.9,0.6,1.3,0.8c4.3,6.2,9.8,7.2,12.9,7.2c0.3,0,0.6,0,0.9,0c1.1,0.5,2.4,1.1,3.8,1.6c0.6,1.2,1.4,2.4,2.5,3.6
					c1.4,3.4,4,6.2,7.5,7.9c2.7,1.3,5.3,1.8,7.8,2.3c0.6,0.1,1.2,0.2,1.9,0.4c0.1,0,0.3,0.1,0.4,0.1c1.4,1,3.2,1.9,5.4,2.5
					c1.6,1.7,3.6,3.1,6,4.3c1.6,0.8,3.3,1.2,5,1.3c1.3,0.4,2.5,0.5,3.5,0.5c1.1,0,2.3-0.1,3.4-0.4c0.7,0.1,1.4,0.1,2.1,0.1
					c0.4,0,0.7,0,1.1,0c1.5,0.6,3.1,0.9,4.7,0.9h3c1.1,0,2.6-0.1,4.2-0.6c0,0,0.1,0,0.1,0c1.4,0.9,2.9,1.6,4.5,2c2.1,1,5,2,8.5,2
					c2.7,0,5.2-0.6,7.6-1.8c1.3-0.6,2.4-1.4,3.4-2.4c0.2-0.2,0.4-0.4,0.5-0.6c2.4,0.4,4.6,0.4,6.3,0.4h5c1.8,0,7.4,0,12.2-4.8
					c4.8-4.8,4.8-10.4,4.8-12.2c0-0.4,0-0.9-0.1-1.3c2.9-1.5,5.9-4.2,7.8-9.1c2.2-4,4-10.5-0.1-18.6c0,0,0-0.1,0-0.1
					c2.6-2.4,5.3-6.5,5.3-12.9c0-1.6-0.2-3.2-0.6-4.6c0-0.2,0.1-0.3,0.1-0.5c0.4-0.9,0.6-1.7,0.8-2.3c0.1-0.3,0.2-0.5,0.2-0.6
					c2.9-3.2,4.4-7.3,5-10.7c0.2-0.3,0.3-0.5,0.5-0.8c2.2,0.3,5,0.6,8.2,1.2l3.6,0.9c1,0.3,2.1,0.4,3.2,0.4c5.9,0,10.6-2.9,13.1-7.4
					c0.6,0,1.2,0.1,1.8,0.1c3.6,0,6.9-0.8,10.1-2.6c0.5-0.2,1-0.4,1.4-0.6c2.8-0.7,5.2-2,7.2-3.6c6.1-0.5,9.8-3.7,11.7-7.4
					c2.3-3.4,4-8.9,1.7-16.7v-6.5c0-0.2,0.1-0.4,0.1-0.6c0.6-2.5,1.2-5.4,0.7-8.6c-0.1-0.6-0.2-1.2-0.4-1.8c0.8-0.8,1.5-1.6,2.2-2.5
					l1.6-1.6c2.1-2.1,6.2-6.8,8-13.2c0.5,0.2,1.1,0.4,1.6,0.5c1.6,0.6,3.2,0.9,4.9,1c2.2,1,4.6,1.6,7.3,1.6c1.7,0,3.4-0.3,5-0.9
					c1-0.1,2.1-0.3,3.3-0.7c3.1,2.1,6.4,2.5,8.7,2.5c3.2,0,6.3-1.2,8.6-3.3c1.3-0.6,2.5-1.5,3.6-2.5c2.3-2.3,3.8-5.1,4.5-8.1
					c0.2-0.4,0.5-0.8,0.6-1c0.6-0.6,1.1-1.2,1.5-1.9c3-4.5,4.2-8.4,3.8-12.8c0.6-1.4,1.4-3.6,1.4-6.4c0-4-1.6-7.1-3-9.1
					c-0.1-0.1-0.1-0.3-0.2-0.4c0-0.2-0.1-0.4-0.2-0.7c-0.2-1-0.6-2.4-1.2-3.9c0.5-2.6,0.6-5,0.6-6.7c0.1-0.2,0.3-0.3,0.4-0.5
					c1.7,0.8,3.7,1.3,5.6,1.3c6.8,0,12.4-3.9,14.8-10.2c0.4-1.1,0.7-2.1,0.9-3.1c2.1,0.9,4.3,1.3,6.3,1.3c7.1,0,11.3-3.5,13.3-7.4
					c0.3-0.5,0.6-1,0.9-1.5c0,0,0,0,0.1,0c0.6,0.1,1.3,0.1,1.9,0.1c3,0,5.2-0.8,6.6-1.5c1.8-0.9,9.3-5.3,8.8-15.3
					c0.2-0.6,0.5-1.2,0.6-1.8c0.8-2.6,0.8-4.9,0.8-6.4c0-2.2-0.6-4.3-1.6-6.2c2.1-2.5,3.6-5.4,3.6-9.7c1.8-2,3.1-4.5,3.7-7.2
					c0.1,0,0.3-0.1,0.4-0.1c1.5-0.3,3-0.7,4.3-1.2c1.6,0.5,3.2,0.8,4.9,0.8c2.6,0,5.1-0.6,7.5-1.8c1.2-0.6,2.4-1.4,3.4-2.4
					c1.7-1.6,2.6-3.3,3.1-4.3c0.1-0.1,0.1-0.2,0.2-0.3l2.7-1.8c0.7-0.5,1.4-1,2-1.6c4.8-4.8,4.8-10.4,4.8-12.2
					c0-2.6-0.8-5.1-2.2-7.2l-0.4-0.6c0-4.4-1.6-8.6-3.1-12.3c-2.1-7.2-6.3-10.4-10.4-11.9c0-0.6-0.1-1.1-0.2-1.6
					c0.5-0.4,1-0.9,1.6-1.4c3.9-1.4,7.4-3.8,10-6.6c1.1-0.3,2.2-0.7,3.5-1.3c2.3-0.8,6.8-2.8,9.3-8c1.7-3.5,1.7-6.7,1.7-9.1
					c0-1.5,0-4.2-0.8-7.3c-0.5-1.8-1.3-3.6-2.2-5.1c0-0.1,0-0.2-0.1-0.2c-0.4-2.9-1.7-5.5-3.7-7.6c-0.2-0.2-0.4-0.4-0.7-0.7
					c-0.1-0.4-0.3-0.8-0.4-1.1c-1.2-2.7-3.1-5.1-5.5-6.9l-0.9-1.9c-1.5-2.9-3.9-5.1-6.8-6.3c-1.6-0.9-3.4-1.5-5.3-1.7
					c0.6-3.6,0-7.2-1.9-11c-0.6-1.2-1.4-2.4-2.4-3.4c-0.5-0.5-1.1-1.1-2-1.8c-2.1-3.4-5.5-5.7-9.5-6.6c-1.5-0.5-3.1-0.8-4.7-0.8
					c-4.9,0-9.3,2.4-12,6.1c-0.9,0.4-1.8,1-2.7,1.7c-1,0.7-1.9,1.4-2.7,2.2c-1.5-0.2-2.9-0.3-4.3-0.3c-4,0-7.3,0.9-11,2.2
					c-4.2,1.1-7.6,2.2-10.3,3.8c-5.7,1-9.6,4-12.8,7c-2.2,1.8-3.3,2.8-4.2,3.4c-0.3-0.3-0.6-0.6-0.9-0.9l-0.5-0.5
					c-2.3-3.4-6-5.8-10.3-6.2l-2-0.3c-1.4-3.1-3.9-5.7-7.1-7.5c-3-1.6-6.3-2.4-10.4-3c-4-1.2-7.2-1.8-10.2-1.8
					c-2.8,0-5.5,0.5-8.2,1.4c-0.5,0-1-0.1-1.4-0.3c-0.8-0.3-1.6-0.5-2.5-0.6l-8-1c-0.3,0-0.6-0.1-0.9-0.1c-0.3-1.5-0.8-2.7-1.2-3.5
					c-0.4-1.4-1-2.7-1.8-3.8c-0.7-1.4-1.9-3.8-4.3-6.6c-2.2-3.6-4.1-6-6.8-7.8c2.5-1.5,4.2-3.3,5.3-5.2c0.9-0.6,1.6-1.3,2.2-1.8
					c0.2,0.1,0.4,0.1,0.6,0.2l4.5,2.2c0.2,0.1,0.4,0.2,0.7,0.4c1.8,1,5.1,3,10.1,3h1c9.3,0,15.4-5,19.2-8.8c0.6-0.6,1.1-1.3,1.6-2
					l1.5-2.3c4.5-5.6,4.5-13.1,0.1-18.7c-0.6-2-1.7-3.9-3.2-5.4c-4.8-4.8-10.4-4.8-12.2-4.8c-1,0-2.5,0-4.2,0.2
					c-2.5-1.5-5.3-2.3-8.2-2.3c-1.8,0-3.4,0.3-4.9,0.7c-1.9-0.9-4-1.4-6.2-1.4c-1.9,0-3.9,0.4-5.6,1.1c0,0,0,0,0,0
					c-0.8-0.4-1.7-0.8-2.7-1c-0.7-0.2-1.4-0.4-2-0.5c-2.1-0.6-4.5-1.3-7.3-1.3c-0.1,0-0.2,0-0.3,0c-2.4-1.8-4.9-3.3-6.7-4.2
					C367,42.7,365,42.2,363,42.2L363,42.2z M334,126.2c1.8,0,7,0,11.7-4.3c0.2,1.6,0.7,3.1,1.4,4.6c1,2,2.3,3.5,3.2,4.4
					c-1.2,1.8-2.2,3.8-3,5.8c-4.9,1.3-8.8,4.6-10.9,9.1c-3.3,0.3-5.8,1.4-7.8,2.6c-1.6-2.9-4-5.2-6.9-6.6c3.2-1.4,5.3-3.4,6.3-4.4
					c2.4-2.4,3.8-5.7,3.8-9.2v-2v-0.1C332.7,126.2,333.4,126.2,334,126.2L334,126.2z M559,182.2L559,182.2L559,182.2L559,182.2z"/>
			</g>
			<g id="st0">
				<path d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,44.2c-1.9,0-6.7,0-10.7,3c-1.5-0.5-3.1-0.8-5-0.8c-2.4,0-4.8,0.5-7.4,1.6
					c-0.3,0.1-0.6,0.2-0.8,0.4c-3.4,1.7-6.2,4.1-8.3,6.8H330c-1.2,0-2.4,0.2-3.5,0.6c-2.8,0.9-6.6,3.2-9,8.9
					c-1.2,2.9-2.8,9.2-1.4,14.6c0.6,4.7,3.4,8.5,7.4,10.5c-1.6,0.5-3.1,1.4-4.3,2.7c-4.2,4.2-4.2,9-4.2,11.8c0,1.2,0.2,2.4,0.6,3.5
					c0.2,0.7,0.7,2,1.6,3.5c-0.5-0.1-1-0.1-1.6-0.1c-2.2,0-4.4,0.5-6.4,1.5c-1.8,0.5-3.5,1.5-4.9,2.8c-1.7,1.7-2.7,3.6-3.3,5.4
					c-1.5-3.5-4.1-5.6-5.2-6.5c-0.1,0-0.1-0.1-0.2-0.1c-0.6-0.6-1.3-1.1-2.1-1.6c-3.4-4.1-8.3-6.5-13.9-6.5c-1.5,0-3,0.2-4.5,0.5
					c-0.1-0.1-0.2-0.3-0.4-0.4c-0.4-1.3-1-2.6-1.9-3.8c-0.5-1.1-1.3-2.2-2.2-3.1c-3.2-3.2-8.1-5.2-12.8-5.2c-2.8,0-7.4,0-11.5,4
					c-4.2,1.6-7.4,5.1-8.3,9.4c-0.3,0.9-0.5,2-0.5,3.2c-0.5-0.3-0.9-0.5-1.4-0.8c-0.4-2.5-1.6-4.7-3.4-6.5
					c-2.5-2.5-5.2-4.3-10.9-5.6c0.3-0.8,0.6-1.6,0.7-2.5c0.9-2.7,1-5.7,0-8.6c-0.8-2.3-2.2-4.6-3.9-6.3c-2.1-2.1-4.9-3.2-7.8-3.2h-2
					c-0.7,0-1.3,0.1-2,0.2c-0.3-2.1-1.3-5.2-4.1-8c-1.3-1.3-4.8-4.2-9.8-4.2c-2.3,0-4.6,0.7-6.4,2.1c-1.4,0.1-3.1,0.4-4.8,1
					c-0.6-0.1-1.2-0.1-1.8-0.1c-6.8,0-14,5.6-14,14c0,1.3,0.1,2.5,0.4,3.6c-0.9,0.9-1.7,2-2.4,3.1c-0.1,0.1-0.2,0.2-0.4,0.3
					c-4.3,2.7-6.2,6.6-7.5,9.1c-0.8,1.5-1.2,3.2-1.2,4.9c0,1.6,0,3.9,0.8,6.4c-1.1,3.2-1.8,6.9-1.8,9.6c0,0.2,0,0.6-0.1,0.9
					c-0.2,2.3-0.6,7.6,3.6,12.3c-0.6,1.7-1.1,3.7-1.6,6.1c-0.2,1.2-0.5,2.6-0.6,4c-4.4,0.8-10.3,2-14.8,6.9c-3.6,0.8-7.1,2.9-10.3,6
					c-0.8,0.8-1.5,1.8-2,2.8c-2.9,0.1-5.6,0.3-8.1,1h-1.3c-1.9-0.7-4.3-1.4-7.1-1.4c-2.4,0-4.6,0.5-6.7,1.6
					c-5.7,2.8-7.2,8.3-7.9,10.6c-0.1,0.2-0.1,0.5-0.2,0.6c-0.7,1.4-1,3-1,4.6c0,3.2,0,6.5,1.4,10.9c-0.1,0.3-0.1,0.6-0.2,0.9
					c-0.1,0.6-0.2,1.2-0.2,1.9c-0.1,0.8-0.7,2.7-1.2,4.2c-0.7,1.5-1.8,4.2-2.4,7.2c-1,1.2-2.9,3.5-4.1,6.3c-1.1,0.3-2.1,0.6-2.7,0.9
					c-1.9,0.4-3.7,1.4-5.2,2.8c-0.1,0-0.1,0.1-0.2,0.1c-1.2,1-4.3,3.5-5.6,7.8c-1.5,3.2-1.8,7-0.7,10.3c-3.2,3.6-3.9,7.6-4.6,12
					l-0.1,0.6c-0.7,3-1,6.2-1.1,9.1c-3.7,1.3-6.5,3.9-8.4,7.9c-4.1,2.6-6.7,7.5-6.7,12.2c0,4.4,2.5,9,5.1,11.6l0.1,0.1
					c0.8,1,2.7,3.2,5.9,4.7c1.6,2.4,3.8,4.5,5.9,6c-1.2,2.2-2,4.7-2,7.5c0,6.2,4.3,9.6,7.6,11.6c-0.1,0.6-0.2,1.2-0.4,2.1
					c0,0.1,0,0.2,0,0.2c-0.1,0.6-0.3,1.3-0.4,1.8c-2,2.3-4.8,6.2-4.8,11.3c0,1.8,0.3,3.7,1.1,5.7c-1.3,1.7-2.5,3.8-3.5,6.8
					c-0.4,1.1-0.6,2.3-0.6,3.5c0,1.6,0,3.6,0.5,5.8c-0.6,2.2-0.6,4.6-0.1,6.8c0.3,1.3,0.8,2.5,1.5,3.7c-5,4.1-8.5,9.8-10,12.5
					c-1.2,1.5-2.1,3.2-2.9,4.8c-1.3,1.4-2.7,3.2-4,4.8c-3.5,4.7-5,8-5,11.6c0,1,0,3.1,0.9,5.6c0.4,4.2,2.6,9.6,10,14.5
					c0.4,0.3,0.8,0.5,1.2,0.7c2.9,1.5,5.9,2.2,8.8,2.2c2.7,0,5.3-0.6,7.7-1.8c0.9,0.3,1.7,0.6,2.6,0.8c0.4,2.4,1.4,5.2,4,7.7
					c0.8,0.8,1.8,1.5,2.9,2.1c1.9,1,3.9,1.4,6,1.4c4.8,0,8-2.6,9.8-4.4c6.5-0.7,10.6-4.7,12-6.1c0.5-0.5,1-1.1,1.4-1.7
					c0.3-0.4,1-1.1,1.6-1.6c4.6-1.2,9.2-4.5,12.4-7.7c2.2-0.3,4-1,5.6-1.9c0.1,0.1,0.2,0.3,0.3,0.4c0.1,1,0.3,1.9,0.5,2.8
					c-0.9,2.6-2,6.8,0.6,11.9c2.2,4.4,6.5,7.4,11.8,8.6c0.5,0.4,1,0.8,1.6,1.1c0,1.2,0.2,2.3,0.4,3.2c-1,4.3-0.7,8.1,1,11.5
					c-0.1,2-0.3,4.1-0.6,6.1c0,0.1-0.1,0.2-0.1,0.3c-0.9,2.6-2.3,6.7-1.1,11.3c0.1,1.3,0.3,2.7,0.8,4.3c0.4,1.1,0.8,2.1,1.4,3
					c0,0.3,0.1,0.5,0.1,0.8c-0.3,1.1-0.4,2.2-0.4,3c0,3.2,1,6.2,2.6,8.5c1.1,3.6,3.5,5.9,6.1,7.2c1.9,1.1,4.4,2,7.6,2
					c2.1,0,4.3-0.3,7.3-1.1c0.2-0.1,0.4-0.1,0.6-0.2c0.2,0,0.5,0.1,0.8,0.2c0.8,1.3,1.8,2.5,3,3.6c2,1.7,4,2.7,5.4,3.3
					c0.2,0.1,0.4,0.2,0.5,0.3c0.3,0.1,0.5,0.2,0.8,0.3c0.6,0.5,1.2,0.8,1.7,1.1c3.8,5.8,8.7,6.7,11.5,6.7c0.5,0,0.9,0,1.3-0.1
					c1.4,0.7,3,1.3,4.9,2c0.6,1.4,1.5,2.8,2.8,4.2c1.2,3.1,3.5,5.6,6.7,7.2c2.4,1.2,4.9,1.6,7.3,2.1c0.6,0.1,1.3,0.2,1.9,0.4
					c0.3,0.1,0.5,0.1,0.8,0.1c1.4,1,3.3,2.1,5.6,2.7c1.6,1.7,3.5,3.1,5.8,4.3c1.4,0.7,2.9,1.1,4.4,1.2c1.2,0.3,2.3,0.5,3.2,0.5
					c1.1,0,2.2-0.2,3.2-0.5c0.7,0.1,1.5,0.2,2.2,0.2c0.5,0,1,0,1.4-0.1c1.4,0.6,2.9,0.9,4.4,0.9h3c1,0,2.4-0.1,3.9-0.7
					c0.3,0,0.6,0.1,1,0.1c1.4,1,3,1.7,4.6,2.1c2,0.9,4.6,1.9,7.8,1.9c2.3,0,4.6-0.5,6.7-1.6c1.1-0.5,2-1.2,2.9-2.1
					c0.4-0.4,0.8-0.9,1.2-1.3c2.7,0.5,5.3,0.5,7,0.5h5c1.6,0,6.6,0,10.8-4.2c4.2-4.2,4.2-9.2,4.2-10.8c0-0.5,0-1-0.1-1.6
					c0.1-0.3,0.2-0.7,0.3-1.1c2.9-1.2,5.9-3.7,7.8-8.6c2-3.7,3.7-9.9-0.5-17.7c0.2-0.4,0.4-0.9,0.6-1.4c2.4-2.1,4.9-5.8,4.9-11.7
					c0-1.6-0.2-3.2-0.7-4.6c0.1-0.4,0.2-0.8,0.3-1.2c0.4-0.9,0.6-1.7,0.8-2.2c0.2-0.6,0.3-0.8,0.4-1.1c2.9-3,4.3-7.1,4.9-10.4
					c0.5-0.7,0.9-1.4,1.2-2.1c2.5,0.3,5.8,0.7,9.8,1.4l3.6,0.9c0.9,0.2,1.8,0.3,2.7,0.3c5.7,0,10.1-2.9,12-7.5c1,0.1,2,0.2,3,0.2
					c3.3,0,6.3-0.8,9.3-2.4c0.6-0.2,1.1-0.4,1.5-0.6c2.8-0.7,5.1-2,7-3.7c5.6-0.3,8.9-3.1,10.7-6.3c2.3-3.3,3.8-8.3,1.5-15.6v-7
					c0.1-0.3,0.1-0.5,0.2-0.8c0.5-2.5,1.1-5,0.7-7.9c-0.1-1-0.4-1.9-0.7-2.8c1.1-0.9,2.1-2,2.9-3.1l1.7-1.7
					c2.1-2.1,6.5-7.1,7.8-13.8c0.2-0.2,0.4-0.5,0.6-0.7c1,0.5,2,0.9,3,1.2c1.5,0.6,3.1,0.9,4.7,0.9c0.1,0,0.2,0,0.3,0
					c2,1,4.3,1.6,6.8,1.6c0.9,0,2.6-0.1,4.5-0.8c1.2-0.1,2.6-0.3,4.1-0.9c2.9,2.3,6.1,2.8,8.3,2.8c2.8,0,5.5-1.1,7.5-3
					c1.2-0.5,2.3-1.3,3.3-2.3c2.5-2.5,3.6-5.3,4-7.3c0.3-0.6,0.8-1.4,1.1-1.7c0.5-0.5,0.9-1.1,1.3-1.6c2.8-4.2,3.9-7.8,3.4-11.9
					c0-0.1,0.1-0.2,0.1-0.4c0.6-1.2,1.4-3.2,1.4-5.8c0-3.6-1.5-6.4-2.8-8.1c-0.1-0.2-0.2-0.4-0.3-0.7c-0.1-0.2-0.2-0.6-0.2-0.9
					c-0.3-1-0.6-2.5-1.3-4.2c0.6-3.1,0.7-5.8,0.7-7.7c0.7-0.8,1.3-1.6,1.8-2.4c1.8,1.2,4,1.9,6.2,1.9c5.9,0,10.9-3.4,12.9-8.9
					c0.8-2,1-3.9,1-5.5c0,0,0-0.1,0-0.1c2.6,1.5,5.4,2.4,8,2.4c6.2,0,9.8-3,11.6-6.4c0.4-0.6,0.7-1.2,1-1.8c0.3-0.2,0.6-0.5,0.9-0.8
					c0,0,0.1-0.1,0.1-0.1c0.8,0.2,1.7,0.2,2.5,0.2c2.6,0,4.5-0.7,5.8-1.3c2.2-1.1,8.4-5,7.7-13.9c1.4-3.2,1.4-6.4,1.4-7.9
					c0-2.3-0.7-4.6-2.1-6.4c2.5-2.7,4.1-5.2,4.1-9.6c0-0.2,0-0.5,0-0.7c2.2-2.1,3.6-4.9,4-8c2.1-0.4,4.3-0.9,6.5-1.8
					c1.5,0.6,3.2,0.9,4.9,0.9c2.2,0,4.5-0.5,6.6-1.6c1.1-0.5,2-1.2,2.9-2.1c1.5-1.5,2.4-3.1,2.9-4c0.2-0.2,0.3-0.4,0.5-0.7l3-2
					c0.6-0.4,1.2-0.9,1.7-1.4c4.2-4.2,4.2-9.2,4.2-10.8c0-2.2-0.6-4.3-1.8-6.1l-0.8-1.1c0.1-4.3-1.4-8.4-2.9-12.3
					c-2.1-7.2-6.4-9.9-10.5-11.2c0-0.1,0-0.2,0-0.3c0-1.3-0.1-2.4-0.4-3.5c0.8-0.7,1.8-1.5,2.7-2.4c3.9-1.3,7.5-3.8,10-6.6
					c1.2-0.3,2.5-0.7,3.9-1.4c9.8-3.7,9.8-12,9.8-15.2c0-5.3-1.2-9-3-11.7c0-0.2-0.1-0.4-0.1-0.6c-0.3-2.4-1.4-4.7-3.1-6.5
					c-0.3-0.3-0.6-0.6-1-1c-0.1-0.5-0.3-1-0.5-1.4c-1.1-2.5-2.9-4.8-5.2-6.4c0-0.1-0.1-0.2-0.2-0.3l-1-2c-1.3-2.5-3.4-4.4-6-5.4
					c-2-1.1-4.3-1.7-6.8-1.7c1.3-4,0.9-7.8-1.2-11.9c-0.5-1.1-1.2-2-2.1-2.9c-0.5-0.5-1.2-1.2-2.1-1.9c0-0.1-0.1-0.2-0.1-0.2
					c-1.8-3-4.8-5-8.3-5.7c-1.3-0.5-2.7-0.7-4.2-0.7c-4.4,0-8.3,2.3-10.7,5.7c-0.9,0.4-1.8,1-2.8,1.7c-1.2,0.8-2.3,1.7-3.1,2.8
					c-1.8-0.3-3.4-0.4-5-0.4c-3.8,0-6.9,0.9-10.5,2.1c-4.8,1.2-7.9,2.4-10.2,3.8c-5.4,0.8-9.2,3.7-12.1,6.6
					c-3.7,3.1-4.5,3.7-6.1,4.6c-0.1-0.1-0.3-0.3-0.4-0.5c-0.4-0.6-0.9-1.2-1.4-1.7l-0.6-0.6c-1.9-3-5.2-5.1-8.9-5.5l-3.2-0.5
					c-1.2-3.2-3.5-5.8-6.7-7.5c-2.7-1.5-6-2.2-9.9-2.8c-3.8-1.2-6.9-1.8-9.8-1.8c-2.7,0-5.3,0.5-7.9,1.5c-0.8-0.1-1.7-0.2-2.4-0.4
					c-0.7-0.2-1.4-0.4-2.1-0.5l-8-1c-0.5-0.1-0.9-0.1-1.4-0.1h-1h0c-0.2-2.1-0.8-3.8-1.3-4.8c-0.3-1.3-0.9-2.5-1.6-3.5
					c-0.6-1.4-1.8-3.7-4.2-6.4c-2.8-4.6-4.8-7-9.2-8.8c0.6-0.4,1.1-0.8,1.6-1.2c3.1-1.5,5-3.5,6.1-5.6c1.4-0.9,2.5-1.9,3.2-2.7
					c0.6,0.2,1.2,0.3,1.9,0.5l4.7,2.3c0.2,0.1,0.5,0.3,0.9,0.5c1.7,1,4.7,2.7,9.1,2.7h1c8.6,0,14.2-4.7,17.8-8.2
					c0.5-0.5,1-1.1,1.4-1.7l1.6-2.4c1.9-2.4,3-5.2,3-8.2c0-3-1.1-6-3.1-8.4c-0.5-1.8-1.5-3.5-2.8-4.9c-4.2-4.2-9.2-4.2-10.8-4.2
					c-1.1,0-2.8,0-4.7,0.2c-2.8-1.9-5.7-2.3-7.8-2.3c-1.8,0-3.5,0.3-5.1,0.9c-1.9-1-3.9-1.5-6-1.5c-2,0-3.9,0.5-5.7,1.4
					c-0.3-0.1-0.6-0.3-0.9-0.4c-0.7-0.4-1.5-0.6-2.3-0.8c-0.7-0.2-1.4-0.4-2.1-0.6c-2.1-0.6-4.2-1.2-6.8-1.2c-0.3,0-0.7,0-1,0
					c-2.5-2-5.1-3.5-6.9-4.5C366.4,44.6,364.7,44.2,363,44.2L363,44.2z M334,124.2c1.6,0,6.6,0,10.8-4.2c0.5-0.5,1-1.1,1.4-1.7
					c2.8-4.2,3.4-8.7,1.9-13.5c0.1,0,0.2,0.1,0.3,0.1c0.2,1,0.5,2,0.9,3.2c0.2,1.2,0.6,2.3,1.1,3.4c-0.7,1.1-1.4,2.4-1.8,3.9
					c-1.4,3.2-1.3,6.9,0.4,10.2c1,2.1,2.5,3.5,3.3,4.4c0.2,0.2,0.5,0.5,0.8,0.7c-1.8,2.3-3.2,5-4.1,7.8c-5.2,1-9.3,4.5-11,9.3
					c-4.6,0.1-7.7,2-10,3.5c-2.1-5.4-7-9.2-12.9-9.2c-1.3,0-3.2,0.2-5.3,1.2c-0.2-0.7-0.5-1.4-0.9-2.1c-0.3-0.6-0.7-1.2-1.1-1.7
					c2.4,1.4,4.7,1.7,6.2,1.7h1c6.6,0,10.4-3.8,11.8-5.2c2.1-2.1,3.2-4.9,3.2-7.8v-2v-2.7C331.5,124.1,332.9,124.2,334,124.2
					L334,124.2z M303.4,136.1c0-0.1,0.1-0.2,0.1-0.3c0.1,0.2,0.3,0.4,0.4,0.5C303.8,136.3,303.6,136.2,303.4,136.1L303.4,136.1z"/>
			</g>
			<g id="st0">
				<path d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,45.2c-1.9,0-6.6,0-10.5,3.1c-1.5-0.6-3.2-0.9-5.2-0.9c-2.3,0-4.6,0.5-7.1,1.5
					c-0.3,0.1-0.5,0.2-0.8,0.3c-3.5,1.8-6.3,4.1-8.2,6.9c-0.1,0-0.2,0-0.3,0h-1c-1.1,0-2.1,0.2-3.2,0.5c-2.6,0.9-6.1,3-8.3,8.3
					c-1.3,3.2-2.6,9.1-1.4,14c0.8,6.6,6,11.1,12.9,11.1h1c0.2,0,0.3,0,0.5,0c0.1,0.1,0.2,0.2,0.2,0.3c-0.9-0.2-1.8-0.3-2.7-0.3h-2
					c-2.7,0-5.2,1.1-7.1,2.9c-3.9,3.9-3.9,8.4-3.9,11.1c0,1.1,0.2,2.1,0.5,3.2c0.3,0.9,1.1,3,2.7,5.2c-1.1-0.3-2.3-0.5-3.7-0.5
					c-2.2,0-4.2,0.5-6.1,1.5c-1.7,0.4-3.2,1.3-4.5,2.6c-2.7,2.7-3.6,6.1-3.8,8.5c-0.1-0.1-0.2-0.3-0.3-0.4c-0.8-5.1-4.3-7.9-5.7-9.1
					c-0.1-0.1-0.1-0.1-0.2-0.2c-0.6-0.6-1.3-1.1-2.1-1.5c-3.2-4-7.9-6.3-13.2-6.3c-1.6,0-3.2,0.2-4.8,0.6c-0.3-0.3-0.6-0.7-0.9-1
					c-0.3-1.2-0.9-2.5-1.9-3.9c-0.5-1-1.2-2-2-2.8c-3-3-7.7-4.9-12.1-4.9c-2.7,0-7.1,0-11,3.8c-4,1.5-7,4.7-7.9,8.7
					c-0.4,1.3-0.7,3-0.4,4.8c-1.1-0.8-2.2-1.5-3.4-2c-0.3-2.5-1.4-4.7-3.2-6.5c-2.4-2.4-5-4.1-10.7-5.4c-0.3-0.1-0.5-0.3-0.8-0.4
					c0.5-1,0.8-2,1.1-3.1c0.9-2.5,1-5.4,0-8.2c-0.7-2.2-2-4.3-3.7-5.9c-1.9-1.9-4.4-2.9-7.1-2.9h-2c-0.9,0-1.9,0.1-2.9,0.4
					c-0.1-1.8-0.8-5.3-3.9-8.5c-1.2-1.2-4.4-3.9-9.1-3.9c-2.2,0-4.3,0.7-6.1,2.1c-1.4,0.1-3.2,0.4-5,1.1c-0.6-0.1-1.2-0.2-1.9-0.2
					c-6.3,0-13,5.2-13,13c0,1.4,0.2,2.7,0.5,4c-1,1-1.9,2.1-2.6,3.3c-0.2,0.1-0.4,0.3-0.6,0.5c-4.1,2.5-6,6.4-7.2,8.7
					c-0.7,1.4-1.1,2.9-1.1,4.5c0,1.6,0,3.9,0.9,6.3c-1.1,3.2-1.9,7-1.9,9.7c0,0.3,0,0.6-0.1,1c-0.2,2.2-0.6,7.3,3.5,11.8
					c0,0.2,0.1,0.3,0.1,0.5c-0.7,1.7-1.2,3.8-1.7,6.2c-0.3,1.5-0.5,3.2-0.7,4.7c-4.4,0.7-10.6,1.8-15.2,6.9c-3.6,0.8-7,2.7-10.1,5.8
					c-0.9,0.9-1.6,1.9-2.1,3.1c-3.1,0-6,0.2-8.6,1h-1.6c-2-0.7-4.2-1.4-6.9-1.4c-2.2,0-4.3,0.5-6.3,1.5c-5.3,2.6-6.7,7.6-7.4,10
					c-0.1,0.2-0.2,0.5-0.2,0.7c-0.6,1.3-1,2.8-1,4.3c0,3.3,0,6.5,1.4,10.9c-0.1,0.4-0.2,0.8-0.2,1.1c-0.1,0.6-0.2,1.2-0.2,1.8
					c-0.1,0.8-0.8,2.9-1.3,4.4c-0.7,1.5-1.8,4.2-2.4,7.3c-1,1.1-3.1,3.7-4.3,6.7c-1.4,0.3-2.4,0.7-3.1,0.9c-1.8,0.4-3.5,1.3-4.8,2.6
					c-0.1,0.1-0.1,0.1-0.2,0.2c-1.3,1-4.2,3.4-5.4,7.4c-0.8,1.7-1.3,3.6-1.3,5.6c0,1.5,0.3,3.1,0.8,4.5c-3.4,3.5-4,7.3-4.7,11.9
					l-0.1,0.6c-0.8,3.2-1,6.6-1.1,9.7c-3.8,1.2-6.7,3.8-8.6,7.8c-0.1,0.1-0.1,0.1-0.2,0.2c-3.8,2.4-6.2,7-6.2,11.3
					c0,4.2,2.4,8.5,4.8,10.9c0,0,0.1,0.1,0.1,0.1c0.8,1,2.7,3.2,5.8,4.6c1.7,2.6,4.2,4.9,6.5,6.6c-1.4,2.1-2.3,4.8-2.3,7.7
					c0,6,4.4,9.2,7.7,11c-0.1,0.7-0.2,1.6-0.5,2.8c0,0.1,0,0.1,0,0.2c-0.2,0.8-0.3,1.5-0.4,2.1c-1.9,2.1-4.8,5.9-4.8,10.9
					c0,1.8,0.3,3.8,1.2,5.8c-1.4,1.7-2.7,3.8-3.7,7c-0.3,1-0.5,2.1-0.5,3.2c0,1.5,0,3.6,0.5,5.8c-0.6,2.2-0.7,4.4-0.2,6.6
					c0.4,1.5,1,2.9,1.8,4.1c-5.2,4-9,10.1-10.5,12.8c-1.2,1.5-2.1,3.2-3,4.8c-1.3,1.4-2.8,3.2-4,4.8c-3.4,4.5-4.8,7.7-4.8,11
					c0,1,0,3.1,0.9,5.5c0.4,3.9,2.5,9.1,9.6,13.9c0.3,0.2,0.7,0.4,1.1,0.6c2.8,1.4,5.6,2.1,8.4,2.1c2.7,0,5.2-0.6,7.7-1.9
					c1.2,0.5,2.4,0.8,3.6,1.1c0.3,2.4,1.2,5.2,3.8,7.8c0.8,0.8,1.6,1.4,2.6,1.9c1.8,0.9,3.6,1.3,5.5,1.3c4.7,0,7.8-2.7,9.3-4.3
					c6.4-0.5,10.4-4.5,11.7-5.9c0.5-0.5,0.9-1,1.2-1.5c0.4-0.6,1.2-1.4,1.9-2c4.7-1.2,9.3-4.5,12.4-7.8c2.5-0.3,4.6-1.2,6.3-2.3
					c0.3,0.5,0.7,0.9,1,1.3c0.1,1.2,0.4,2.3,0.6,3.2c-0.9,2.4-2,6.5,0.4,11.4c2.1,4.2,6.2,7,11.4,8.1c0,0,0,0,0,0
					c0.6,0.6,1.3,1,2.1,1.4c0,1.5,0.2,2.7,0.4,3.8c-1,4.2-0.7,7.9,1,11.2c-0.1,2.2-0.3,4.4-0.7,6.6c0,0.1-0.1,0.3-0.1,0.4
					c-0.9,2.5-2.2,6.4-1.1,10.8c0.1,1.2,0.2,2.6,0.7,4.2c0.4,1.1,0.8,2.1,1.5,3c0,0.4,0.1,0.8,0.2,1.2c-0.3,1.1-0.4,2.1-0.4,2.9
					c0,3.1,0.9,5.9,2.5,8.1c1,3.7,3.4,5.8,5.9,6.9c1.7,1,4,1.8,7,1.8c2,0,4.1-0.3,7.1-1c0.3-0.1,0.5-0.1,0.8-0.2
					c0.3,0.1,0.7,0.1,1.2,0.2c0.1,0,0.2,0.1,0.3,0.1c2.4,4.1,6.5,6.1,8.2,6.9c0.2,0.1,0.4,0.2,0.6,0.3c0.3,0.1,0.5,0.3,0.8,0.4
					c0.7,0.5,1.3,0.9,1.9,1.2c3.6,5.6,8.2,6.5,10.8,6.5c0.5,0,1,0,1.5-0.1c1.5,0.7,3.3,1.5,5.5,2.3c0.6,1.5,1.5,3,2.9,4.4
					c1.1,2.9,3.3,5.4,6.3,6.9c2.3,1.1,4.6,1.5,7,2c0.6,0.1,1.3,0.2,2,0.4c0.3,0.1,0.7,0.1,1,0.1c1.4,1.1,3.3,2.2,5.8,2.7
					c1.5,1.7,3.4,3.1,5.7,4.3c1.3,0.6,2.7,1,4.2,1.1c1.1,0.3,2.2,0.4,3,0.4c1.1,0,2.1-0.2,3.1-0.5c0.8,0.1,1.5,0.2,2.3,0.2
					c0.5,0,1.1,0,1.6-0.1c1.3,0.6,2.8,0.9,4.2,0.9h3c1,0,2.4-0.1,3.8-0.7c0.4,0.1,0.9,0.1,1.5,0.1c1.4,1,2.9,1.7,4.5,2.1
					c1.9,0.9,4.4,1.9,7.5,1.9c2.2,0,4.3-0.5,6.2-1.5c1-0.5,1.8-1.1,2.6-1.9c0.5-0.5,0.9-1,1.3-1.5c0.2-0.1,0.3-0.1,0.5-0.2
					c2.7,0.6,5.3,0.6,7.1,0.6h5c1.7,0,6.1,0,10.1-3.9c3.9-3.9,3.9-8.4,3.9-10.1c0-0.5,0-1.1-0.1-1.6c0.1-0.5,0.3-1.2,0.4-1.8
					c2.8-1,5.8-3.3,7.8-8.3c2-3.5,3.6-9.6-0.7-17.2c0.3-0.6,0.6-1.3,0.8-1.9c2.4-2,4.8-5.5,4.8-11.2c0-1.7-0.3-3.2-0.8-4.6
					c0.2-0.5,0.3-0.9,0.4-1.4c0.4-0.9,0.7-1.7,0.9-2.3c0.2-0.6,0.3-0.8,0.5-1.2c0,0,0-0.1,0.1-0.1c2.9-3,4.2-7,4.7-10.3
					c0.6-0.9,1.1-1.8,1.5-2.8c2.6,0.2,6.2,0.7,10.7,1.5l3.7,0.9c0.8,0.2,1.6,0.3,2.4,0.3c6.2,0,9.8-3.5,11.3-7.6
					c1.3,0.2,2.5,0.3,3.7,0.3c3.2,0,6-0.7,8.9-2.3c0.7-0.2,1.2-0.5,1.6-0.7c2.8-0.7,5.1-2,7-3.7c5.7-0.1,8.8-3,10.3-6
					c2.1-3.1,3.5-7.9,1.2-14.8v-7.2c0.1-0.3,0.1-0.6,0.2-0.9c0.5-2.5,1.1-4.8,0.7-7.6c-0.2-1.2-0.5-2.2-0.9-3.2
					c1.3-0.9,2.5-2.1,3.3-3.4l1.8-1.8c2-2,6.3-7,7.6-13.5c0.4-0.5,0.9-1,1.3-1.6c1.1,0.7,2.4,1.2,3.7,1.6c1.3,0.6,2.8,0.9,4.2,0.9
					c0.2,0,0.4,0,0.5,0c1.9,1,4.2,1.6,6.6,1.6c0.9,0,2.5-0.1,4.3-0.8c1.4-0.1,3-0.4,4.5-1.1c2.6,2.3,5.6,2.9,8.2,2.9
					c2.6,0,5.1-1,7-2.8c1.2-0.5,2.2-1.2,3.1-2.1c2.4-2.4,3.4-5.1,3.7-7c0.3-0.7,0.9-1.7,1.3-2.1c0.5-0.5,0.9-1,1.2-1.5
					c2.7-4,3.7-7.4,3.2-11.4c0.1-0.2,0.1-0.4,0.2-0.6c0.5-1.1,1.3-3,1.3-5.5c0-3.4-1.4-6-2.7-7.6c-0.1-0.3-0.2-0.5-0.3-0.8
					c-0.1-0.2-0.2-0.7-0.3-1c-0.3-1.1-0.7-2.6-1.4-4.3c0.7-3.3,0.7-6.2,0.7-8.1c1-1.1,1.8-2.2,2.5-3.4c0,0,0.1,0,0.1,0
					c1.8,1.5,4.1,2.3,6.4,2.3c5.5,0,10.1-3.2,12-8.2c0.7-1.9,0.9-3.8,1-5.3c0.1-0.5,0.2-1,0.3-1.5c0,0,0,0,0,0c2.7,1.9,5.8,3,8.6,3
					c6,0,9.3-2.9,10.8-6c0.4-0.6,0.7-1.2,1-1.8c0.4-0.3,0.7-0.6,1.1-0.9c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0,0.1-0.1,0.2-0.1
					c0.9,0.2,1.8,0.3,2.8,0.3c2.4,0,4.2-0.7,5.3-1.2c2.1-1,7.9-4.7,7.1-13.2c1.4-3.1,1.4-6.3,1.4-7.7c0-2.4-0.9-4.7-2.4-6.5
					c2.7-2.8,4.4-5.2,4.4-9.5c0-0.4,0-0.7-0.1-1.1c2.4-2.1,3.9-5.2,4-8.5c0.8-0.1,1.7-0.3,2.5-0.5c1.8-0.4,3.5-0.9,5-1.5
					c1.5,0.6,3.2,1,4.9,1c2.1,0,4.1-0.5,6.1-1.5c1-0.5,1.8-1.1,2.6-1.9c1.5-1.5,2.3-2.9,2.7-3.8c0.2-0.3,0.4-0.6,0.6-0.8
					c0,0,0.1-0.1,0.1-0.1l3-2c0.5-0.4,1.1-0.8,1.5-1.2c3.9-3.9,3.9-8.4,3.9-10.1c0-2-0.6-3.9-1.7-5.5l-0.9-1.4
					c0.2-4.2-1.3-8.4-2.9-12.2c-2.1-7.3-6.5-9.6-10.5-10.8c0-0.4,0-0.7,0-1.1c0-1.4-0.2-2.7-0.5-3.9c1-0.8,2.1-1.8,3.3-2.9
					c4-1.2,7.6-3.8,10-6.6c1.2-0.3,2.5-0.7,4-1.4c9.2-3.4,9.2-11,9.2-14.2c0-5.1-1.2-8.7-2.9-11.3c-0.1-0.3-0.1-0.5-0.2-0.8
					c-0.3-2.2-1.3-4.3-2.9-6c-0.3-0.3-0.7-0.7-1.2-1.1c-0.2-0.5-0.3-1.1-0.6-1.6c-1.1-2.5-2.8-4.6-5.1-6.1c-0.1-0.2-0.2-0.4-0.2-0.5
					l-1-2c-1.2-2.3-3.2-4.1-5.5-4.9c-1.8-1-4-1.6-6.4-1.6c-0.5,0-0.9,0-1.4,0.1c0,0,0-0.1,0-0.1c1.7-4.2,1.5-8.1-0.7-12.5
					c-0.5-1-1.1-1.8-1.9-2.6c-0.5-0.5-1.2-1.2-2.2-2c-0.1-0.1-0.1-0.2-0.2-0.4c-1.7-2.8-4.4-4.6-7.7-5.3c-1.2-0.5-2.6-0.7-3.9-0.7
					c-4.2,0-7.8,2.2-10,5.5c-0.9,0.4-1.9,1-2.9,1.7c-1.3,0.8-2.4,1.9-3.3,3c-2-0.3-3.7-0.5-5.5-0.5c-3.6,0-6.6,0.8-10.2,2.1
					c-4.8,1.2-7.9,2.4-10.1,3.8c-5.3,0.8-8.9,3.6-11.8,6.4c-4.3,3.6-4.8,4-6.9,5c0,0,0,0,0,0c-0.4-0.3-0.7-0.7-1-1.2
					c-0.4-0.5-0.8-1.1-1.2-1.5l-0.7-0.7c-1.8-2.8-4.8-4.8-8.3-5.2l-3.8-0.5c-1.1-3.2-3.3-5.8-6.4-7.5c-2.6-1.4-5.8-2.2-9.6-2.7
					c-3.8-1.2-6.7-1.7-9.5-1.7c-2.7,0-5.2,0.5-7.8,1.5c-1,0-2-0.2-2.8-0.5c-0.6-0.2-1.3-0.4-1.9-0.4l-8-1c-0.4-0.1-0.8-0.1-1.2-0.1
					h-1h-1c0-2.5-0.8-4.3-1.3-5.5c-0.3-1.2-0.8-2.3-1.6-3.4c-0.6-1.3-1.7-3.6-4.1-6.3c-3.2-5.3-5.2-7.5-11-9.2
					c1.3-0.5,2.5-1.2,3.6-2.2c3-1.5,4.9-3.4,6-5.5c1.6-1,2.7-2.2,3.5-2.9c0.1-0.1,0.1-0.1,0.2-0.2c0.8,0.3,1.6,0.5,2.5,0.7l4.8,2.4
					c0.3,0.1,0.6,0.3,0.9,0.5c1.7,1,4.4,2.6,8.6,2.6h1c8.2,0,13.7-4.5,17.1-7.9c0.5-0.5,0.9-1,1.2-1.5l1.6-2.4
					c3.8-4.6,3.7-10.9-0.2-15.5c-0.4-1.8-1.3-3.4-2.6-4.7c-3.9-3.9-8.4-3.9-10.1-3.9c-1.2,0-2.9,0-4.9,0.3c-2.7-1.9-5.5-2.4-7.5-2.4
					c-1.9,0-3.6,0.4-5.2,1c-1.8-1.1-3.8-1.6-5.9-1.6c-2,0-3.9,0.5-5.7,1.5c-0.5-0.2-0.9-0.5-1.4-0.7c-0.7-0.3-1.3-0.6-2-0.8
					c-0.7-0.2-1.4-0.4-2.1-0.6c-2-0.6-4.1-1.1-6.5-1.1c-0.4,0-0.9,0-1.3,0.1c-2.5-2-5.2-3.7-7-4.6C366.1,45.6,364.6,45.2,363,45.2
					L363,45.2z M380,60C380,60,380,60,380,60L380,60L380,60z M334,123.2c1.7,0,6.1,0,10.1-3.9c0.5-0.5,0.9-1,1.2-1.5
					c3-4.5,3.3-9.4,0.9-14.6c0.9,0.4,1.9,0.7,2.9,0.9c0.2,1.1,0.5,2.4,1,3.8c0.2,1.3,0.7,2.6,1.3,3.7c-0.8,1.1-1.6,2.6-2.1,4.2
					c-1.2,2.9-1.2,6.3,0.4,9.4c1,2,2.3,3.3,3.1,4.1c0.5,0.5,1,0.9,1.5,1.2c-2.1,2.5-3.8,5.7-4.7,8.9c-5.4,0.8-9.6,4.4-11.1,9.4
					c-0.1,0-0.2,0-0.3,0c-4.9,0-8,2.1-10.2,3.6l-0.6,0.4c0,0,0,0-0.1,0c-1.6-5.7-6.5-9.7-12.4-9.7c-1.4,0-3.6,0.3-5.8,1.6
					c-0.3-1-0.7-2-1.2-3.1c-1.2-2.4-3.3-4.2-5.7-5c0.2-0.8,0.6-2,0.8-3.4c3.6,5.9,8.4,6.8,11,6.8h1c6.1,0,9.6-3.5,11.1-4.9
					c1.9-1.9,2.9-4.4,2.9-7.1v-2v-3c0-0.4,0-0.8-0.1-1.2C330.9,123,332.7,123.2,334,123.2L334,123.2z"/>
			</g>
			<g id="st1">
				<path id="st2" d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,45.7c-1.9,0-6.6,0-10.4,3.2c-1.5-0.6-3.3-1-5.3-1c-2.2,0-4.4,0.5-6.9,1.4
					c-0.2,0.1-0.5,0.2-0.7,0.3c-3.5,1.8-6.3,4.1-8.2,7c-0.2,0-0.4,0-0.6,0h-1c-1,0-2,0.2-3,0.5c-2.5,0.8-5.9,2.9-8,8.1
					c-1.3,3.2-2.5,9-1.3,13.7c0.8,6.3,5.8,10.7,12.4,10.7h1c0.2,0,0.5,0,0.7,0c0.3,0.5,0.7,0.9,1,1.4l0.2,0.3
					c-1.3-0.4-2.6-0.7-4-0.7h-2c-2.5,0-4.9,1-6.7,2.8c-3.8,3.8-3.8,8.1-3.8,10.7c0,1,0.2,2,0.5,3c0.4,1.1,1.4,3.8,3.7,6.3
					c-1.4-0.5-3.1-1-5.1-1c-2.1,0-4.1,0.5-5.9,1.5c-1.6,0.4-3.1,1.3-4.3,2.5c-3.3,3.3-3.7,7.6-3.8,9.9c-0.3-0.6-0.7-1.3-1.1-1.9
					c-0.7-5-4.2-7.8-5.6-8.9c-0.1-0.1-0.2-0.1-0.2-0.2c-0.6-0.6-1.3-1.1-2-1.5c-3.1-4-7.7-6.2-12.9-6.2c-1.6,0-3.3,0.2-5,0.6
					c-0.4-0.4-0.8-0.9-1.2-1.3c-0.3-1.2-0.9-2.5-1.8-3.9c-0.5-1-1.1-1.9-1.9-2.7c-2.9-3-7.4-4.8-11.7-4.8c-2.6,0-6.9,0-10.7,3.8
					c-3.9,1.4-6.8,4.5-7.6,8.4c-0.5,1.5-0.8,3.6-0.2,6c-1.4-1.3-2.9-2.2-4.6-2.9c-0.3-2.5-1.3-4.7-3.1-6.5c-2.4-2.4-4.9-4-10.6-5.3
					c-0.4-0.2-0.9-0.4-1.3-0.6c0.6-1,1-2.2,1.3-3.4c0.9-2.4,1-5.2,0.1-7.9c-0.7-2.1-2-4.1-3.5-5.7c-1.8-1.8-4.2-2.8-6.7-2.8h-2
					c-1.1,0-2.2,0.2-3.4,0.6c0,0,0,0,0-0.1c0-1.5-0.4-5.3-3.8-8.7c-1.1-1.1-4.3-3.8-8.7-3.8c-2.2,0-4.2,0.7-5.9,2.1
					c-1.4,0.1-3.3,0.4-5.1,1.1c-0.6-0.1-1.3-0.2-2-0.2c-6,0-12.5,5-12.5,12.5c0,1.5,0.2,2.9,0.5,4.2c-1.1,1-2.1,2.2-2.8,3.5
					c-0.2,0.2-0.5,0.3-0.7,0.5c-3.9,2.5-5.8,6.2-7,8.5c-0.7,1.3-1,2.8-1,4.2c0,1.6,0,3.9,0.9,6.3c-1.1,3.1-1.9,7-1.9,9.7
					c0,0.3,0,0.6-0.1,1.1c-0.2,2.1-0.5,7.2,3.5,11.5c0.1,0.3,0.1,0.5,0.2,0.7c-0.7,1.7-1.2,3.8-1.7,6.3c-0.3,1.6-0.6,3.5-0.7,5
					c-0.1,0-0.2,0-0.2,0c-4.4,0.7-10.6,1.8-15.1,6.9c-3.5,0.7-7,2.7-10,5.7c-0.9,0.9-1.6,2-2.1,3.2c-3.2,0-6.2,0.2-8.8,1h-1.8
					c-1.9-0.7-4.2-1.4-6.8-1.4c-2.1,0-4.2,0.5-6,1.4c-5.1,2.5-6.4,7.4-7.1,9.7c-0.1,0.3-0.2,0.6-0.2,0.8c-0.6,1.3-0.9,2.7-0.9,4.1
					c0,3.3,0,6.5,1.4,10.9c-0.1,0.4-0.2,0.8-0.3,1.3c-0.1,0.6-0.2,1.1-0.2,1.7c-0.1,0.8-0.8,3-1.3,4.6c-1,2.1-1.9,4.7-2.4,7.4
					c-1,1.1-3.2,3.8-4.4,6.9c-1.5,0.3-2.6,0.7-3.4,1c-1.7,0.4-3.3,1.2-4.6,2.4c-0.1,0.1-0.1,0.1-0.2,0.2c-1.2,1-4.1,3.3-5.2,7.2
					c-0.8,1.7-1.2,3.5-1.2,5.4c0,1.6,0.3,3.1,0.9,4.6c0,0,0,0,0,0.1c-3.4,3.5-4.1,7.3-4.8,11.8l-0.1,0.6c-0.8,3.3-1.1,6.9-1.1,10
					c-3.9,1.1-6.8,3.7-8.7,7.7c-0.1,0.1-0.2,0.2-0.3,0.3c-3.7,2.3-6,6.8-6,10.9c0,4,2.3,8.2,4.7,10.6c0,0.1,0.1,0.1,0.1,0.2
					c0.8,0.9,2.6,3.1,5.8,4.5c1.7,2.7,4.5,5.2,6.9,6.9c-1.6,2.1-2.5,4.8-2.5,7.8c0,5.9,4.5,9,7.8,10.7c-0.1,0.7-0.2,1.8-0.5,3.2
					c0,0.1,0,0.1,0,0.2c-0.2,0.8-0.3,1.6-0.4,2.2c-1.9,2.1-4.7,5.7-4.7,10.6c0,1.8,0.3,3.9,1.3,5.9c-1.4,1.7-2.7,3.8-3.8,7.1
					c-0.3,1-0.5,2-0.5,3c0,1.5,0,3.6,0.5,5.8c-0.6,2.1-0.7,4.4-0.2,6.5c0.4,1.6,1.1,3.1,2,4.3c-5.4,3.9-9.3,10.3-10.7,13
					c-1.2,1.5-2.1,3.2-3,4.9c-1.3,1.4-2.8,3.2-4,4.8c-3.3,4.4-4.7,7.5-4.7,10.7c0,1,0,2.9,0.8,5.2c0.3,3.9,2.3,9,9.4,13.7
					c0.3,0.2,0.7,0.4,1,0.6c2.7,1.4,5.5,2,8.2,2c2.7,0,5.2-0.6,7.6-1.9c1.4,0.5,2.8,0.9,4.1,1.2c0.2,2.4,1.1,5.3,3.7,7.9
					c0.7,0.7,1.6,1.3,2.5,1.8c1.7,0.8,3.5,1.3,5.3,1.3c4.6,0,7.6-2.7,9-4.3c6.3-0.4,10.3-4.4,11.6-5.7c0.4-0.4,0.8-0.9,1.2-1.4
					c0.4-0.7,1.4-1.6,2.1-2.2c4.6-1.1,9.3-4.5,12.3-7.7c2.8-0.3,5-1.2,6.8-2.5c0.4,0.6,0.8,1.2,1.4,1.8c0.1,1.3,0.4,2.4,0.7,3.4
					c-0.9,2.3-2.1,6.4,0.3,11.2c2,4.1,6.1,6.8,11.1,7.8c0,0,0.1,0.1,0.1,0.1c0.7,0.6,1.5,1.1,2.3,1.5c0,1.6,0.1,3,0.4,4.1
					c-1,4.2-0.7,7.9,1,11.1c-0.1,2.3-0.3,4.6-0.7,6.8c-0.1,0.1-0.1,0.3-0.2,0.4c-0.9,2.6-2.2,6.3-1.1,10.6c0.1,1.2,0.2,2.6,0.7,4
					c0.4,1.1,0.9,2.1,1.5,2.9c0.1,0.5,0.1,0.9,0.2,1.4c-0.3,1.1-0.4,2.1-0.4,2.9c0,3,0.9,5.7,2.5,7.8c1,3.6,3.3,5.6,5.6,6.7
					c1.6,1,3.9,1.7,6.8,1.7c1.9,0,4.1-0.3,6.9-1c0.3-0.1,0.6-0.2,0.9-0.3c0.4,0.1,0.8,0.1,1.4,0.3c0.2,0.1,0.4,0.1,0.5,0.2
					c2.3,4.1,6.4,6,8.1,6.8c0.2,0.1,0.4,0.2,0.6,0.3c0.3,0.1,0.6,0.3,0.8,0.4c0.7,0.6,1.4,1,2,1.3c3.3,5.2,7.4,6.4,10.4,6.4
					c0.5,0,1.1,0,1.6-0.1c1.6,0.8,3.4,1.6,5.8,2.4c0.6,1.5,1.5,3.1,2.9,4.5c1,2.9,3.2,5.2,6.1,6.7c2.2,1.1,4.5,1.5,6.9,1.9
					c0.6,0.1,1.3,0.2,2,0.4c0.4,0.1,0.7,0.1,1.1,0.2c1.4,1.1,3.3,2.2,5.9,2.7c1.5,1.8,3.3,3.1,5.7,4.3c1.3,0.6,2.6,1,4,1
					c1.1,0.3,2.1,0.4,3,0.4c1.1,0,2.1-0.2,3.1-0.5c0.8,0.1,1.6,0.2,2.3,0.2c0.6,0,1.1,0,1.7-0.1c1.3,0.6,2.7,1,4.1,1h3
					c1,0,2.3-0.1,3.8-0.7c0.5,0.1,1.1,0.1,1.7,0.1c1.4,1,2.9,1.7,4.5,2.1c1.9,0.9,4.3,1.8,7.3,1.8c2.1,0,4.1-0.5,6-1.4
					c0.9-0.5,1.7-1.1,2.5-1.8c0.5-0.5,0.9-1,1.3-1.5c0.3-0.1,0.5-0.2,0.8-0.3c2.7,0.6,5.4,0.6,7.2,0.6h5c1.6,0,5.9,0,9.7-3.8
					c3.8-3.8,3.8-8.1,3.8-9.7c0-0.5,0-1.1-0.1-1.6c0.1-0.6,0.3-1.4,0.5-2.2c2.7-1,5.8-3.2,7.7-8.1c1.9-3.5,3.6-9.4-0.8-17
					c0.4-0.7,0.7-1.5,1-2.3c2.3-1.9,4.7-5.3,4.7-10.9c0-1.7-0.3-3.2-0.8-4.6c0.2-0.5,0.4-1,0.5-1.6c0.4-0.9,0.6-1.7,0.9-2.3
					c0.2-0.6,0.3-0.9,0.5-1.3c0-0.1,0.1-0.2,0.1-0.2c2.8-2.9,4.2-7,4.6-10.1c0.7-1,1.2-2,1.6-3.1c2.7,0.2,6.4,0.7,11.1,1.5l3.7,0.9
					c0.8,0.2,1.5,0.3,2.3,0.3c6.1,0,9.6-3.5,10.9-7.7c1.4,0.2,2.7,0.3,4,0.3c3.1,0,5.9-0.7,8.7-2.3c0.7-0.2,1.2-0.5,1.7-0.7
					c0,0,0,0,0,0c2.8-0.7,5.1-2,6.9-3.7c5.6-0.1,8.6-2.8,10.1-5.7c2.1-3,3.4-7.7,1.2-14.4v-7.4c0.1-0.3,0.1-0.7,0.2-1
					c0.5-2.4,1-4.7,0.7-7.4c-0.2-1.3-0.5-2.4-1.1-3.5c1.5-1,2.7-2.2,3.6-3.6l1.9-1.9c2-2,6.3-6.9,7.5-13.3c0.5-0.6,1.1-1.3,1.6-2.1
					c1.2,0.8,2.6,1.4,4,1.8c1.3,0.5,2.7,0.8,4.1,0.8c0.2,0,0.4,0,0.7,0c1.9,1,4.1,1.6,6.5,1.6c0.8,0,2.4-0.1,4.2-0.8
					c1.5-0.1,3.1-0.4,4.7-1.2c2.6,2.4,5.6,3,8.1,3c2.5,0,4.9-1,6.7-2.7c1.1-0.5,2.2-1.2,3-2c2.4-2.4,3.3-4.9,3.6-6.8
					c0.3-0.7,1-1.8,1.4-2.2c0.4-0.4,0.8-0.9,1.2-1.4c2.7-4,3.7-7.3,3.1-11.2c0.1-0.2,0.2-0.5,0.2-0.7c0.5-1.1,1.3-2.9,1.3-5.3
					c0-3.3-1.4-5.8-2.6-7.4c-0.1-0.3-0.2-0.6-0.4-0.9c0-0.1-0.2-0.7-0.3-1.1c-0.3-1.2-0.7-2.7-1.5-4.4c0.7-3.4,0.7-6.4,0.7-8.3
					c0,0,0-0.1,0-0.1c1.1-1.2,1.9-2.4,2.7-3.6c0.2,0,0.4-0.1,0.6-0.1c1.7,1.5,4,2.4,6.3,2.4c5.3,0,9.7-3,11.5-7.9
					c0.7-1.9,0.9-3.7,1-5.2c0.2-0.6,0.3-1.2,0.4-1.8c0.2-0.1,0.4-0.1,0.6-0.2c2.7,1.9,5.7,3,8.6,3c5.8,0,8.9-2.8,10.4-5.8
					c0.4-0.6,0.8-1.3,1.1-1.9c0.4-0.3,0.8-0.6,1.1-0.9c0.1-0.1,0.2-0.2,0.4-0.3c0.1-0.1,0.2-0.2,0.4-0.3c0.9,0.2,1.9,0.3,2.9,0.3
					c2.4,0,4.2-0.7,5.1-1.2c2-1,7.6-4.6,6.8-12.8c1.5-3.1,1.5-6.2,1.5-7.7c0-2.4-0.9-4.7-2.6-6.5c2.8-2.9,4.6-5.2,4.6-9.5
					c0-0.4,0-0.9-0.1-1.3c2.5-2.1,4.1-5.3,4.1-8.7c0,0,0,0,0-0.1c0.9-0.1,1.9-0.3,2.9-0.5c1.9-0.4,3.6-0.9,5.1-1.5
					c1.5,0.7,3.2,1,4.9,1c2,0,4-0.5,5.9-1.4c0.9-0.5,1.7-1.1,2.5-1.8c1.5-1.5,2.2-2.9,2.7-3.7c0.2-0.3,0.5-0.6,0.7-0.9
					c0.1,0,0.1-0.1,0.2-0.2l3-2c0.5-0.3,1-0.7,1.4-1.2c3.8-3.8,3.8-8.1,3.8-9.7c0-1.9-0.6-3.7-1.6-5.3l-1-1.6
					c0.3-4.2-1.3-8.3-2.8-12.2c-2.1-7.3-6.4-9.5-10.6-10.6c0-0.5,0.1-1,0.1-1.4c0-1.5-0.2-2.9-0.6-4c1.1-0.9,2.3-1.9,3.7-3.2
					c3.9-1.2,7.5-3.8,9.9-6.6c1.2-0.2,2.6-0.7,4.1-1.4c8.8-3.3,8.8-10.6,8.8-13.8c0-5-1.2-8.6-2.9-11.1c-0.1-0.3-0.1-0.6-0.2-1
					c0,0,0,0,0,0c-0.2-2.1-1.2-4.2-2.7-5.7c-0.4-0.4-0.8-0.8-1.3-1.3c-0.8-3-2.8-5.8-5.6-7.6c-0.1-0.2-0.2-0.4-0.3-0.6l-1-2
					c-1.1-2.2-3-3.9-5.3-4.7c-1.8-1-3.9-1.6-6.2-1.6c-0.7,0-1.4,0.1-2.1,0.2c0.1-0.3,0.2-0.6,0.3-0.9c1.7-4.1,1.4-7.9-0.7-12.1
					c-0.5-0.9-1.1-1.7-1.8-2.5c-0.6-0.6-1.3-1.3-2.2-2c-0.1-0.1-0.2-0.3-0.2-0.4c-1.6-2.6-4.3-4.4-7.4-5.1c-1.2-0.4-2.5-0.7-3.8-0.7
					c-4,0-7.6,2.2-9.7,5.4c-1,0.4-2,1-2.9,1.7c-1.3,0.9-2.4,1.9-3.3,3.1c-2.1-0.3-3.9-0.5-5.7-0.5c-3.6,0-6.5,0.8-10.1,2
					c-4.8,1.2-7.9,2.4-10.1,3.8c-5.2,0.7-8.8,3.5-11.6,6.3c-4.5,3.7-4.9,4-7.2,5.2c-0.1,0-0.2-0.1-0.3-0.1c-0.4-0.4-0.8-0.7-1.2-1.4
					c-0.3-0.5-0.7-1-1.2-1.4l-0.6-0.6c-1.7-2.8-4.6-4.8-8.1-5.1l-4-0.6c-1-3.2-3.1-5.8-6.3-7.6c-2.6-1.4-5.7-2.1-9.5-2.7
					c-3.8-1.2-6.6-1.7-9.4-1.7c-2.6,0-5.1,0.5-7.7,1.5c-1,0-2.1-0.2-3.1-0.5c-0.6-0.2-1.2-0.3-1.8-0.4l-8-1c-0.4,0-0.8-0.1-1.2-0.1
					h-1h-1.5c0-0.2,0-0.3,0-0.5c0-2.4-0.8-4.2-1.3-5.3c-0.3-1.2-0.8-2.3-1.5-3.3c-0.6-1.3-1.7-3.6-4.1-6.2
					c-3.4-5.6-5.4-7.7-12.3-9.4c0,0,0,0,0,0c1.8-0.5,3.5-1.3,5.1-2.7c3-1.4,4.8-3.4,5.8-5.4c1.5-1,2.7-2.1,3.5-2.9
					c0.1-0.1,0.3-0.3,0.4-0.4c0.9,0.3,1.8,0.5,2.8,0.8l4.8,2.4c0.3,0.1,0.6,0.3,0.9,0.5c1.6,0.9,4.3,2.5,8.3,2.5h1
					c8,0,13.4-4.4,16.7-7.8c0.4-0.4,0.8-0.9,1.2-1.4l1.6-2.5c3.7-4.5,3.6-10.6-0.3-14.9c-0.4-1.7-1.3-3.3-2.5-4.6
					c-3.8-3.8-8.1-3.8-9.7-3.8c-1.2,0-3,0-5,0.3c-2.7-1.9-5.4-2.4-7.4-2.4c-1.9,0-3.6,0.4-5.3,1c-1.8-1.1-3.8-1.7-5.8-1.7
					c-2,0-3.9,0.5-5.7,1.6c-0.5-0.3-1-0.5-1.6-0.8c-0.6-0.3-1.3-0.6-1.9-0.7c-0.7-0.2-1.4-0.4-2.1-0.6c-2-0.6-4-1.1-6.4-1.1
					c-0.5,0-1,0-1.4,0.1c-2.5-2.1-5.2-3.7-7.1-4.7C365.9,46,364.5,45.7,363,45.7L363,45.7z M334,122.7c1.6,0,5.9,0,9.7-3.8
					c0.4-0.4,0.8-0.9,1.2-1.4c4.5-6.8,1.7-12.8,0.2-15.4c1.3,0.7,2.7,1.3,4.5,1.5c0.2,1.2,0.5,2.5,1.1,4.1c0.3,1.4,0.7,2.7,1.4,3.9
					c-0.9,1.2-1.7,2.6-2.2,4.4c-1.2,2.8-1.1,6.1,0.3,9c0.9,1.9,2.2,3.1,3,4c0.6,0.6,1.2,1.1,1.9,1.5c-2.4,2.6-4.1,6-5.1,9.4
					c-5.5,0.7-9.7,4.3-11.1,9.5c-0.2,0-0.4,0-0.7,0c-4.7,0-7.7,2.1-10,3.6l-0.6,0.4c-0.2,0.1-0.4,0.3-0.6,0.5
					c-1.4-5.8-6.2-10.1-12.1-10.1c-1.5,0-3.8,0.3-6.1,1.9c-0.3-1.1-0.7-2.4-1.4-3.6c-1.2-2.4-3.3-4.1-5.7-4.8c-0.1,0-0.1,0-0.2-0.1
					c0-0.1,0.1-0.2,0.1-0.3c0.4-1.2,0.9-3,0.9-5c3.4,6.6,8,8,11.4,8h1c5.9,0,9.3-3.4,10.7-4.8c1.8-1.8,2.8-4.2,2.8-6.7v-2v-3
					c0-0.7-0.1-1.4-0.2-2.1C330.3,122.3,332.3,122.7,334,122.7L334,122.7z M555.5,182.2C555.5,182.2,555.5,182.2,555.5,182.2
					L555.5,182.2L555.5,182.2z"/>
			</g>
			<g id="st0">
				<path d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,46.2c-1.8,0-6.6,0-10.3,3.3c-1.6-0.7-3.3-1.1-5.4-1.1c-2.1,0-4.3,0.5-6.7,1.4
					c-0.2,0.1-0.5,0.2-0.7,0.3c-3.5,1.8-6.3,4.2-8.2,7.1c-0.3,0-0.5,0-0.8,0h-1c-1,0-1.9,0.2-2.8,0.5c-2.4,0.8-5.7,2.8-7.7,7.8
					c-1.3,3.1-2.5,8.8-1.3,13.5c0.8,6,5.5,10.3,11.9,10.3h1c0.3,0,0.6,0,1,0c0.4,0.5,0.8,1.1,1.2,1.6l0.6,0.8
					c0.1,0.1,0.1,0.1,0.2,0.2c0.2,0.3,0.4,0.5,0.6,0.8c-1.7-0.9-3.6-1.4-5.6-1.4h-2c-2.4,0-4.7,0.9-6.4,2.6
					c-3.6,3.6-3.6,7.7-3.6,10.4c0,1,0.2,1.9,0.5,2.8c0.4,1.3,1.9,5,5.2,7.7c0.1,0.1,0.2,0.2,0.3,0.3c-1.7-0.8-4.2-2-7.4-2
					c-2,0-4,0.5-5.7,1.4c-1.6,0.4-3,1.2-4.2,2.4c-3.6,3.6-3.6,8.5-3.6,10.4c0,1.4,0.3,2.8,1,4c3.3,6.6,7.8,8,11,8h1
					c5.7,0,9-3.2,10.4-4.6c1.7-1.7,2.6-4,2.6-6.4v-2v-3c0-1.1-0.2-2.3-0.6-3.3c2.3,1.8,4.7,2.3,6.6,2.3c1.6,0,5.7,0,9.4-3.6
					c0.4-0.4,0.8-0.9,1.1-1.4c4.8-7.2,1.1-13.4-0.1-15.5c-0.1-0.2-0.3-0.4-0.3-0.5c-0.1-0.2-0.2-0.5-0.4-0.7c0,0,0.1,0,0.1,0
					c1.6,1.2,3.6,2.4,6.3,2.6c0.1,1.3,0.4,2.7,1.1,4.4c0.3,1.5,0.8,2.9,1.5,4.1c-0.9,1.2-1.8,2.6-2.3,4.5c-1.2,2.7-1.1,5.8,0.3,8.6
					c0.9,1.8,2.1,3,2.9,3.8c0.7,0.7,1.5,1.3,2.4,1.7c-2.6,2.7-4.5,6.3-5.5,10c-5.6,0.6-9.8,4.3-11.1,9.6c-0.4,0-0.7,0-1.1,0
					c-4.5,0-7.5,2-9.7,3.5l-0.6,0.4c-0.4,0.3-0.8,0.6-1.1,0.9c-0.1-0.1-0.1-0.2-0.2-0.3c-1.1-5.9-5.8-10.1-11.7-10.1
					c-2.6,0-4.7,0.8-6.5,2.2c-0.2-1.3-0.7-2.7-1.5-4.3c-1.1-2.2-3.1-3.9-5.4-4.6c-0.2-0.1-0.4-0.2-0.7-0.3c0.1-0.3,0.1-0.5,0.2-0.8
					c0.7-2.4,2.1-7.2-1.3-12c-0.6-4.8-4-7.6-5.4-8.7c-0.1-0.1-0.2-0.1-0.2-0.2c-0.6-0.6-1.3-1.1-2-1.5c-3-3.9-7.4-6-12.6-6
					c-1.7,0-3.4,0.2-5.2,0.7c-0.5-0.6-1-1.1-1.5-1.6c-0.3-1.2-0.9-2.5-1.8-3.9c-0.4-1-1.1-1.9-1.8-2.6c-2.9-2.9-7.2-4.6-11.4-4.6
					c-2.7,0-6.7,0-10.4,3.6c0,0,0,0,0,0c-3.9,1.3-6.5,4.4-7.4,7.8c-0.7,2-1.1,4.9,0.4,8.1c-1.8-2.1-3.9-3.5-6.3-4.5
					c-0.2-2.5-1.2-4.7-3-6.5c-2.3-2.3-4.8-4-10.4-5.2c-0.6-0.3-1.2-0.6-1.9-0.9c0.7-1.2,1.2-2.5,1.5-3.9c1.5-4.2,0.4-9.3-3.4-13
					c-1.7-1.7-4-2.6-6.4-2.6h-2c-1.3,0-2.6,0.3-3.9,0.8c0-0.3,0-0.5,0-0.8c0-1.4-0.4-5.1-3.6-8.4c-1.1-1.1-4.1-3.6-8.4-3.6
					c-2.1,0-4.1,0.7-5.7,2c-1.4,0.1-3.3,0.3-5.2,1.2c-0.6-0.1-1.3-0.2-2-0.2c-5.8,0-12,4.8-12,12c0,1.5,0.2,3,0.6,4.4
					c-1.2,1-2.2,2.3-3,3.7c-0.3,0.2-0.5,0.4-0.8,0.6c-3.8,2.4-5.6,5.9-6.8,8.4c-0.6,1.2-1,2.6-1,4c0,1.7,0,4,0.9,6.3
					c-1.1,3.1-1.9,7-1.9,9.7c0,0.3,0,0.7-0.1,1.1c-0.2,2.1-0.5,7,3.4,11.2c0.1,0.4,0.2,0.7,0.3,1.1c-1.7,4.1-2.3,9-2.6,11.7
					c-0.2,0-0.4,0-0.6,0.1c-4.3,0.7-10.5,1.7-14.9,6.9c-3.5,0.7-6.9,2.6-9.9,5.6c-1,1-1.7,2.1-2.1,3.4c-3.3,0-6.4,0.2-9.1,1h-1.9
					c-1.9-0.7-4.1-1.4-6.7-1.4c-2,0-4,0.5-5.8,1.4c-4.9,2.4-6.2,7.1-6.9,9.4c-0.1,0.3-0.2,0.6-0.2,0.8c-0.6,1.2-0.9,2.5-0.9,3.9
					c0,3.3,0,6.5,1.5,10.8c-0.1,0.4-0.2,0.9-0.3,1.4c-0.1,0.5-0.2,1.1-0.2,1.7c-0.1,0.9-0.8,3.1-1.4,4.7c-0.6,1.4-1.8,4.2-2.4,7.4
					c-0.9,1-3.3,3.8-4.5,7.1c-1.6,0.2-2.8,0.7-3.6,1c-1.7,0.3-3.2,1.1-4.4,2.3c-0.1,0.1-0.2,0.1-0.2,0.2c-1.2,1-3.9,3.2-5,6.9
					c-1.5,3.1-1.6,6.7-0.4,9.9c0,0.1,0,0.2,0,0.3c-3.4,3.4-4,7-4.8,11.7l-0.1,0.7c-0.8,3.4-1.1,7.1-1.1,10.2c-5.3,1.3-7.7,5-8.8,7.7
					c-0.1,0.1-0.2,0.2-0.4,0.3c-3.6,2.2-5.8,6.5-5.8,10.5c0,3.9,2.3,8,4.5,10.3c0,0.1,0.1,0.1,0.1,0.2c0.9,1,2.7,3.2,5.8,4.5
					c1.8,2.9,4.8,5.4,7.2,7.1c-1.6,2-2.7,4.7-2.7,8c0,5.8,4.4,8.7,7.8,10.4c-0.1,0.8-0.2,1.9-0.6,3.6c0,0.1,0,0.1,0,0.2
					c-0.2,0.9-0.3,1.7-0.5,2.4c-1.9,2-4.7,5.6-4.7,10.4c0,1.8,0.3,3.9,1.4,6c-1.5,1.7-2.8,3.8-4,7.2c-0.3,0.9-0.5,1.9-0.5,2.8
					c0,1.5,0,3.6,0.6,5.8c-0.6,2.1-0.7,4.3-0.2,6.4c0.4,1.7,1.1,3.2,2.2,4.5c0,0,0,0,0,0.1c-5.5,3.8-9.5,10.4-11,13.1
					c-1.2,1.5-2.1,3.2-3,4.9c-1.3,1.4-2.8,3.2-4,4.8c-3.2,4.3-4.5,7.3-4.5,10.4c0,1,0,2.9,0.8,5.1c0.2,3.8,2.2,8.8,9.2,13.4
					c0.3,0.2,0.6,0.4,1,0.6c2.6,1.3,5.3,2,7.9,2c2.6,0,5.2-0.7,7.6-2c1.6,0.6,3.1,1.1,4.6,1.4c0.2,2.4,0.9,5.3,3.6,7.9
					c0.7,0.7,1.5,1.3,2.3,1.7c1.6,0.8,3.3,1.2,5.1,1.2c4.6,0,7.5-2.7,8.8-4.3c6.2-0.3,10.2-4.3,11.5-5.6c0.4-0.4,0.8-0.9,1.1-1.4
					c0.5-0.8,1.6-1.7,2.3-2.3c4.6-1.1,9.3-4.6,12.4-7.8c3-0.3,5.3-1.3,7.2-2.8c0.5,0.9,1,1.6,1.7,2.3c0.1,1.4,0.4,2.6,0.7,3.6
					c-0.9,2.3-2.1,6.2,0.2,10.9c2,3.9,5.9,6.6,10.9,7.6c0.1,0,0.2,0.1,0.2,0.1c0.7,0.7,1.6,1.2,2.5,1.6c-0.1,1.8,0.1,3.2,0.4,4.5
					c-1.1,4.1-0.7,7.8,1,11c-0.1,2.3-0.3,4.8-0.7,7.1c-0.1,0.2-0.1,0.3-0.2,0.5c-0.9,2.5-2.2,6.2-1,10.3c0.1,1.2,0.2,2.5,0.7,4
					c0.4,1.1,0.9,2,1.5,2.9c0.1,0.5,0.1,1.1,0.2,1.6c-0.3,1-0.4,2-0.4,2.9c0,3.1,1,5.7,2.4,7.6c0.9,3.4,3.1,5.4,5.4,6.4
					c1.6,0.9,3.7,1.7,6.6,1.7c1.9,0,4-0.3,6.8-1c0.3-0.1,0.7-0.2,1-0.3c0.4,0.1,0.9,0.2,1.5,0.3c0.3,0.1,0.5,0.2,0.8,0.3
					c2.2,4.1,6.3,6,7.9,6.8c0.2,0.1,0.4,0.2,0.6,0.3c0.3,0.1,0.6,0.3,0.9,0.4c0.8,0.6,1.5,1,2.1,1.3c3.2,5.1,7.2,6.2,10.1,6.2
					c0.6,0,1.1,0,1.6-0.1c1.7,0.8,3.6,1.7,6.1,2.5c0.5,1.6,1.5,3.1,2.9,4.6c1,2.8,3,5.1,5.9,6.6c2.2,1.1,4.4,1.5,6.7,1.9
					c0.6,0.1,1.3,0.2,2,0.4c0.4,0.1,0.8,0.1,1.2,0.2c1.4,1.1,3.3,2.3,5.9,2.8c1.5,1.8,3.3,3.1,5.6,4.3c1.2,0.6,2.5,0.9,3.9,0.9
					c1.1,0.3,2.1,0.4,2.9,0.4c1.2,0,2.3-0.2,3.1-0.5c0.8,0.1,1.6,0.2,2.4,0.2c0.6,0,1.2,0,1.7-0.1c1.2,0.6,2.6,1,4.1,1h3
					c1.1,0,2.4-0.2,3.7-0.7c0.6,0.1,1.2,0.1,1.9,0.1c1.4,1,2.9,1.8,4.5,2.2c1.8,0.9,4.2,1.8,7.1,1.8c2,0,4-0.5,5.8-1.4
					c0.9-0.4,1.7-1,2.3-1.7c0.5-0.5,1-1.1,1.4-1.6c0.3-0.1,0.7-0.2,1-0.4c2.7,0.6,5.4,0.6,7.2,0.6h5c1.6,0,5.7,0,9.4-3.6
					c3.6-3.6,3.6-7.8,3.6-9.4c0-0.5,0-1.1-0.1-1.6c0.1-0.7,0.4-1.6,0.6-2.5c2.8-0.9,5.8-3.1,7.7-7.9c2.2-3.8,3.4-9.6-1-16.8
					c0.4-0.8,0.8-1.7,1.2-2.6c2.3-1.8,4.7-5.1,4.7-10.6c0-1.8-0.3-3.4-0.8-4.6c0.2-0.6,0.4-1.1,0.5-1.7c0.4-0.9,0.6-1.7,0.9-2.3
					c0.2-0.6,0.3-0.9,0.5-1.3c0-0.1,0.1-0.2,0.1-0.3c2.8-2.9,4.1-6.9,4.6-10.1c0.8-1,1.4-2.2,1.7-3.4c2.7,0.2,6.6,0.7,11.6,1.6
					l3.7,0.9c0.7,0.2,1.4,0.3,2.2,0.3c6,0,9.4-3.6,10.6-7.7c1.5,0.3,3,0.4,4.4,0.4c3.1,0,5.8-0.7,8.5-2.2c0.7-0.2,1.2-0.5,1.7-0.7
					c0,0,0,0,0.1,0c2.7-0.7,5.1-2,6.8-3.7c5.5,0,8.5-2.6,9.9-5.5c2-2.9,3.3-7.5,1.1-14.1v-7.5c0.1-0.4,0.2-0.7,0.2-1
					c0.5-2.4,1-4.6,0.7-7.2c-0.2-1.4-0.6-2.6-1.2-3.7c1.6-1,2.9-2.2,3.8-3.7l1.9-1.9c2-2,6.2-6.9,7.4-13.2c0.7-0.7,1.3-1.6,1.9-2.6
					c1.3,0.9,2.8,1.6,4.4,2c1.2,0.5,2.6,0.8,3.9,0.8c0.3,0,0.5,0,0.7,0c1.8,1,3.9,1.6,6.4,1.6c0.8,0,2.3-0.1,4.1-0.8
					c1.5-0.1,3.2-0.4,4.9-1.3c2.5,2.5,5.5,3.1,8,3.1c2.4,0,4.7-0.9,6.4-2.6c0,0,0,0,0,0c1.1-0.4,2.1-1.1,3-2
					c2.3-2.3,3.2-4.8,3.5-6.6c0.4-0.8,1.1-2,1.5-2.4c0.4-0.4,0.8-0.9,1.1-1.4c2.6-3.9,3.6-7.1,3-11c0.1-0.3,0.2-0.6,0.3-0.9
					c0.5-1,1.3-2.8,1.3-5.1c0-3.2-1.4-5.6-2.6-7.1c-0.1-0.3-0.2-0.6-0.4-0.9c-0.1-0.2-0.2-0.7-0.3-1.2c-0.3-1.2-0.7-2.7-1.5-4.5
					c0.8-3.4,0.8-6.4,0.8-8.4c0-0.1,0-0.2,0-0.3c1.1-1.2,2.1-2.5,2.8-3.9c0.4-0.1,0.7-0.1,1.1-0.2c1.7,1.5,3.8,2.4,6.1,2.4
					c5.1,0,9.3-2.9,11.1-7.6c0.7-1.9,0.9-3.6,0.9-5.1c0.2-0.7,0.3-1.4,0.4-2c0.4-0.1,0.8-0.3,1.2-0.5c2.6,1.9,5.6,3.1,8.4,3.1
					c5.6,0,8.5-2.7,9.9-5.5c0.5-0.7,0.8-1.4,1.1-2c0.4-0.3,0.8-0.6,1.2-1c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.1,0.4-0.3,0.6-0.4
					c1,0.3,2,0.4,3,0.4c2.3,0,4-0.7,4.9-1.1c1.9-1,7.4-4.4,6.5-12.5c1.5-3,1.5-6.2,1.5-7.6c0-2.4-0.9-4.7-2.6-6.4
					c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0-0.1c3-3,4.7-5.1,4.7-9.5c0-0.5,0-1-0.1-1.5c2.5-2,4.1-5.1,4.1-8.5c0-0.2,0-0.3,0-0.5
					c1.1-0.2,2.2-0.3,3.3-0.6c2-0.4,3.7-0.9,5.2-1.6c1.5,0.7,3.1,1.1,4.9,1.1c1.9,0,3.8-0.5,5.7-1.4c0.9-0.4,1.7-1,2.3-1.7
					c1.4-1.4,2.2-2.8,2.6-3.7c0.3-0.3,0.5-0.7,0.7-1c0.1-0.1,0.2-0.1,0.3-0.2l3-2c0.5-0.3,1-0.7,1.4-1.1c3.6-3.6,3.6-7.8,3.6-9.4
					c0-1.8-0.5-3.5-1.5-5l-1.1-1.7c0.3-4.2-1.3-8.3-2.8-12.1c-2-7.3-6.5-9.3-10.7-10.3c0.1-0.6,0.1-1.2,0.1-1.8c0-1.6-0.3-3-0.6-4.2
					c1.2-0.9,2.5-2,4-3.5c3.9-1.2,7.6-3.8,9.9-6.6c1.2-0.2,2.6-0.6,4.2-1.4c8.5-3.2,8.5-10,8.5-13.3c0-1.3,0-3.7-0.7-6.2
					c-0.5-1.7-1.2-3.2-2.1-4.6c-0.1-0.4-0.1-0.7-0.2-1.1c0,0,0,0,0,0c-0.2-2-1.1-4-2.6-5.4c-0.4-0.4-0.8-0.8-1.4-1.3
					c-0.2-0.6-0.4-1.2-0.6-1.7c-1-2.4-2.8-4.4-5-5.9c-0.1-0.3-0.2-0.5-0.3-0.7l-1-2c-1.1-2.1-2.9-3.7-5.1-4.5c-1.7-1-3.7-1.5-6-1.5
					c-1,0-2,0.2-2.9,0.5c0.3-0.6,0.5-1.2,0.6-1.8c1.6-4,1.4-7.6-0.6-11.7c-0.4-0.9-1-1.7-1.7-2.3c-0.6-0.6-1.3-1.3-2.2-2
					c-0.1-0.2-0.2-0.3-0.3-0.5c-1.6-2.5-4.1-4.2-7.2-4.9c-1.2-0.4-2.4-0.7-3.6-0.7c-3.9,0-7.4,2.1-9.3,5.3c-1,0.4-2,1-3,1.7
					c-1.4,0.9-2.5,2-3.4,3.3c-2.2-0.4-4-0.6-5.9-0.6c-3.5,0-6.4,0.8-9.9,2c-4.9,1.2-7.9,2.4-10,3.8c-5.1,0.7-8.6,3.3-11.5,6.2
					c-4.6,3.9-4.9,4.1-7.5,5.4c-0.2-0.1-0.4-0.1-0.6-0.2c-0.5-0.4-0.9-0.8-1.4-1.5c-0.3-0.5-0.7-1-1.1-1.4l-0.7-0.7
					c-1.6-2.7-4.4-4.6-7.7-4.9l-4.4-0.6c-0.9-3.2-3-5.9-6.1-7.6c-2.5-1.4-5.6-2.1-9.4-2.6c-3.7-1.2-6.6-1.7-9.3-1.7
					c-2.6,0-5.1,0.5-7.6,1.5c-1.1,0-2.3-0.2-3.3-0.5c-0.6-0.2-1.1-0.3-1.7-0.4l-8-1c-0.4,0-0.7-0.1-1.1-0.1h-1h-2.1
					c0-0.3,0.1-0.7,0.1-1c0-2.3-0.8-4.1-1.3-5.1c-0.3-1.2-0.8-2.2-1.5-3.2c-0.6-1.3-1.7-3.5-4.1-6.2c-3.6-5.8-5.5-7.6-12-9.2
					c-0.2-0.1-0.5-0.1-0.7-0.1c-0.5-0.1-1-0.2-1.5-0.2c0,0-0.1-0.1-0.1-0.1c2.5-0.3,5.1-1.2,7.2-3.1c3-1.4,4.7-3.3,5.7-5.3
					c1.6-1,2.7-2.1,3.6-2.9c0.2-0.2,0.4-0.4,0.6-0.6c1,0.3,2,0.6,3.1,0.9l4.9,2.4c0.3,0.1,0.6,0.3,1,0.5c1.6,0.9,4.2,2.4,8.1,2.4h1
					c7.8,0,13.1-4.4,16.4-7.6c0.4-0.4,0.8-0.9,1.1-1.4l1.7-2.5c3.6-4.3,3.4-10.2-0.4-14.4c-0.4-1.7-1.2-3.2-2.4-4.5
					c-3.6-3.6-7.8-3.6-9.4-3.6c-1.2,0-3.1,0-5.2,0.3c-2.6-2-5.3-2.4-7.3-2.4c-1.9,0-3.6,0.4-5.3,1.1c-1.8-1.1-3.7-1.7-5.8-1.7
					c-2,0-3.9,0.6-5.6,1.6c0,0,0,0-0.1,0c-0.6-0.3-1.2-0.6-1.8-0.9c-0.6-0.3-1.2-0.5-1.8-0.7c-0.8-0.2-1.4-0.4-2.1-0.6
					c-1.9-0.5-4-1.1-6.3-1.1c-0.5,0-1.1,0-1.6,0.1c-2.5-2.1-5.3-3.8-7.2-4.7C365.8,46.5,364.4,46.2,363,46.2L363,46.2z M310,478.2
					L310,478.2L310,478.2L310,478.2z"/>
			</g>
			<g id="st1">
				<path id="st2" d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,46.7c-2,0-6.7,0-10.2,3.4c-1.6-0.7-3.4-1.1-5.5-1.1c-2.1,0-4.2,0.4-6.5,1.4
					c-0.2,0.1-0.4,0.2-0.6,0.3c-3.6,1.8-6.3,4.2-8.1,7.2c-0.3,0-0.7-0.1-1.1-0.1h-1c-0.9,0-1.8,0.1-2.7,0.4
					c-2.3,0.8-5.4,2.7-7.4,7.5c-1.3,3.1-2.5,8.7-1.3,13.2c0.7,5.8,5.3,9.9,11.4,9.9h1c0.4,0,0.8,0,1.2-0.1c0.4,0.6,0.9,1.2,1.4,1.8
					l0.6,0.8c0.1,0.1,0.1,0.1,0.2,0.2c0.9,1.1,1.7,2.2,2.6,3.1c-2.3-1.7-4.9-2.9-7.9-2.9h-2c-2.3,0-4.4,0.9-6,2.5
					c-3.5,3.5-3.5,7.4-3.5,10c0,0.9,0.1,1.8,0.4,2.7c0.4,1.2,1.9,4.9,5.1,7.5c0.6,0.8,1.2,1.5,1.8,2c3,4.3,6.5,5.3,9.2,5.3
					c1.5,0,5.5,0,9-3.5c0.4-0.4,0.8-0.8,1.1-1.3c4.6-6.9,1-13-0.1-15c-0.1-0.2-0.3-0.5-0.3-0.6c-0.4-0.8-0.9-1.6-1.6-2.2
					c0,0,0,0,0,0c0.7,0.4,1.3,0.7,2.1,0.9c1.6,1.3,3.7,2.5,6.5,2.6c0.1,1.3,0.4,2.9,1.1,4.8c0.3,1.6,0.8,3,1.6,4.2
					c-1,1.2-1.9,2.7-2.5,4.6c-1.1,2.6-1.1,5.5,0.3,8.2c0.9,1.7,2.1,2.9,2.8,3.7c0.8,0.8,1.8,1.5,2.9,1.9c-2.9,2.7-5,6.7-6,10.6
					c-5.7,0.4-10,4.2-11.1,9.6c-0.5,0-1-0.1-1.4-0.1c-4.4,0-7.2,1.9-9.4,3.4c-0.2,0.1-0.4,0.3-0.6,0.4c-0.5,0.3-0.9,0.7-1.3,1.1
					l-0.2,0.2c-0.1-0.1-0.2-0.3-0.2-0.4c-0.1-0.2-0.2-0.3-0.3-0.5c-1.1-5.7-5.6-9.8-11.3-9.8c-2.8,0-5,1-6.8,2.7
					c-0.2-1.5-0.6-3.2-1.6-5c-1.1-2.1-2.9-3.7-5.1-4.3c-0.3-0.1-0.7-0.3-1.1-0.4c0,0,0-0.1,0-0.1c0.1-0.4,0.2-0.7,0.3-1.1
					c0.7-2.4,2.1-7-1.3-11.6c-0.6-4.7-3.9-7.4-5.2-8.5c-0.1-0.1-0.2-0.1-0.3-0.2c-0.6-0.6-1.3-1.1-2-1.5c-2.9-3.8-7.2-5.9-12.3-5.9
					c-1.7,0-3.5,0.3-5.3,0.8c-0.6-0.7-1.1-1.3-1.7-1.9c-0.3-1.2-0.8-2.5-1.8-3.9c-0.4-0.9-1-1.8-1.7-2.5c-2.8-2.8-7-4.5-11-4.5
					c-2.6,0-6.5,0-10,3.5c0,0-0.1,0.1-0.1,0.1c-3.8,1.2-6.5,4.1-7.3,7.8c-0.7,2.1-1,5.2,1,8.4c0,0,0,0.1,0,0.1
					c-0.2,0.1-0.4,0.2-0.7,0.3c-2-2.5-4.3-4.2-7-5.2c-0.1-2-0.8-4.4-2.9-6.5c-2.3-2.3-4.7-3.9-10.2-5.1c-0.8-0.4-1.6-0.8-2.4-1.1
					c0.8-1.2,1.5-2.7,1.8-4.2c1.5-4,0.4-8.9-3.2-12.5c-1.6-1.6-3.8-2.5-6-2.5h-2c-1.4,0-3,0.3-4.5,1.1c0.1-0.5,0.1-1,0.1-1.6
					c0-1.4-0.3-4.9-3.5-8c-1-1-3.9-3.5-8-3.5c-2,0-4,0.7-5.5,2c-1.5,0.1-3.5,0.3-5.4,1.2c-0.6-0.1-1.3-0.2-2.1-0.2
					c-5.6,0-11.5,4.6-11.5,11.5c0,1.6,0.2,3.1,0.7,4.5c-1.3,1-2.4,2.4-3.2,3.8c-0.3,0.2-0.6,0.4-0.9,0.7c-3.7,2.3-5.5,5.8-6.7,8.2
					c-0.6,1.2-0.9,2.5-0.9,3.8c0,1.7,0,4,1,6.3c-1.2,3.1-2,7-2,9.7c0,0.3,0,0.7-0.1,1.1c-0.2,2.2-0.5,6.9,3.4,10.9
					c0.1,0.5,0.2,0.9,0.4,1.3c-1.8,4.3-2.4,9.6-2.6,12.2c-0.3,0-0.7,0.1-1,0.1c-5.6,0.9-10.9,2.2-14.8,6.8c-3.4,0.6-6.8,2.5-9.8,5.5
					c-1,1-1.7,2.2-2.1,3.5c-3.5,0-6.6,0.2-9.4,1h-2.1c-1.9-0.7-4.1-1.4-6.6-1.4c-2,0-3.8,0.4-5.6,1.3c-4.7,2.3-6,6.9-6.6,9
					c-0.1,0.3-0.2,0.7-0.3,0.9c-0.6,1.2-0.8,2.4-0.8,3.7c0,3.3,0,6.4,1.4,10.7c0,0,0,0.1,0,0.1c-0.1,0.5-0.2,1-0.3,1.5
					c-0.1,0.5-0.2,1.1-0.2,1.6c-0.1,0.9-0.8,3.2-1.4,4.9c-0.6,1.3-1.9,4.3-2.4,7.4c-0.8,0.9-3.4,3.9-4.6,7.4c-1.7,0.2-3,0.7-3.8,1
					c-1.6,0.3-3.1,1.1-4.2,2.2c-0.1,0.1-0.2,0.1-0.3,0.2c-1.2,0.9-3.8,3.1-4.9,6.7c-1.5,3-1.6,6.6-0.3,9.7c0,0.2-0.1,0.4-0.1,0.6
					c-3.4,3.3-4,6.9-4.8,11.5l-0.1,0.7c-0.8,3.5-1.1,7.3-1.1,10.6c-5.4,1.2-7.8,5-8.9,7.6c-0.1,0.1-0.3,0.2-0.4,0.3
					c-3.7,2.2-5.7,6.8-5.7,10.2c0,3.7,2.3,7.8,4.4,9.9c0.1,0.1,0.1,0.1,0.2,0.2c0.8,1,2.6,3.1,5.7,4.4c1.8,3,5.1,5.7,7.6,7.4
					c-1.8,2-2.9,4.9-2.9,8.1c0,5.7,4.5,8.5,7.8,10.1c0,0.8-0.2,2.1-0.6,4c0,0.1,0,0.1,0,0.2c-0.2,0.9-0.4,1.8-0.5,2.5
					c-1.8,1.9-4.7,5.4-4.7,10.2c0,2.3,0.5,4.3,1.5,6c-1.5,1.7-2.9,3.8-4.1,7.3c-0.3,0.9-0.4,1.8-0.4,2.7c0,1.6,0,3.7,0.6,5.9
					c-0.6,2-0.8,4.2-0.3,6.2c0.4,1.6,1.1,3.1,2.1,4.4c0.1,0.2,0.1,0.4,0.2,0.6c-5.6,3.8-9.7,10.6-11.1,13.2c-1.2,1.5-2.1,3.2-3,4.9
					c-1.2,1.3-2.7,3-4,4.8c-3.2,4.2-4.5,7.1-4.5,10.1c0,0.9,0,2.8,0.8,5c0.2,3.7,2.1,8.5,9,13.1c0.3,0.2,0.6,0.4,0.9,0.5
					c2.6,1.3,5.2,1.9,7.7,1.9c2.6,0,5.2-0.7,7.6-2c1.7,0.7,3.5,1.2,5,1.5c0.1,2.4,0.8,5.3,3.4,8c0.6,0.6,1.4,1.2,2.2,1.6
					c1.6,0.8,3.2,1.2,4.8,1.2c4.6,0,7.3-2.8,8.6-4.3c6.2-0.2,10.1-4.2,11.4-5.5c0.4-0.4,0.8-0.8,1.1-1.3c0.6-0.8,1.7-1.9,2.5-2.5
					c4.6-1,9.4-4.6,12.4-7.8c3.1-0.3,5.6-1.4,7.4-3l0.2,0.2c0.5,1,1.1,1.9,1.9,2.6c0,1.5,0.4,2.8,0.7,3.8c-0.9,2.2-2.2,6,0.1,10.7
					c1.9,3.8,5.8,6.4,10.6,7.3c0.1,0.1,0.2,0.1,0.3,0.2c0.8,0.7,1.7,1.3,2.7,1.6c-0.1,2,0.1,3.5,0.4,4.8c-0.7,2.5-1.4,6.7,1,10.9
					c-0.1,2.4-0.4,4.9-0.7,7.3c-0.1,0.2-0.1,0.4-0.2,0.5c-0.9,2.5-2.1,6.1-1,10.1c0,1.2,0.2,2.5,0.7,3.9c0.4,1.1,0.9,2,1.5,2.9
					c0.1,0.6,0.1,1.2,0.3,1.8c-0.3,1-0.4,2-0.4,2.8c0,2.9,0.9,5.5,2.4,7.5c0.9,3.2,3,5.1,5.1,6.1c1.5,0.9,3.6,1.6,6.3,1.6
					c1.8,0,3.9-0.3,6.7-1c0.4-0.1,0.7-0.2,1-0.3c0.4,0.1,1,0.2,1.7,0.3c0.3,0.1,0.7,0.2,1,0.3c2.1,4.1,6.2,6,7.8,6.8
					c0.2,0.1,0.4,0.2,0.6,0.3c0.3,0.1,0.6,0.3,0.9,0.4c0.8,0.7,1.6,1.1,2.2,1.4c3.1,5,6.9,6.1,9.7,6.1c0.6,0,1.2-0.1,1.7-0.1
					c1.7,0.8,3.7,1.7,6.4,2.6c0.6,1.8,1.6,3.4,3,4.8c0.9,2.7,2.9,5,5.7,6.3c2.1,1,4.3,1.4,6.6,1.9c0.6,0.1,1.3,0.2,2,0.4
					c0.4,0.1,0.8,0.1,1.3,0.2c1.4,1.1,3.3,2.3,6,2.8c1.5,1.8,3.3,3.1,5.6,4.3c1.2,0.6,2.4,0.9,3.7,0.9c1,0.3,2,0.4,2.8,0.4
					c1.2,0,2.2-0.2,3-0.5c0.8,0.2,1.6,0.2,2.4,0.2c0.6,0,1.2,0,1.8-0.1c1.2,0.6,2.6,1,4,1h3c1.1,0,2.4-0.2,3.7-0.7
					c0.6,0.1,1.3,0.2,2,0.2c0,0,0,0,0.1,0c1.4,1,3,1.8,4.6,2.2c1.8,0.9,4.1,1.8,6.9,1.8c1.9,0,3.8-0.4,5.6-1.3
					c0.8-0.4,1.6-0.9,2.2-1.6c0.5-0.5,1-1.1,1.5-1.7c0.4-0.1,0.9-0.3,1.3-0.4c2.7,0.6,5.4,0.6,7.2,0.6h5c1.5,0,5.5,0,9-3.5
					c3.5-3.5,3.5-7.5,3.5-9c0-0.5-0.1-1.1-0.2-1.6c0.2-0.8,0.4-1.9,0.7-2.9c2.8-0.8,5.8-2.9,7.6-7.8c2.1-3.8,3.3-9.4-1.1-16.6
					c0.5-0.9,1-1.9,1.3-2.9c2.2-1.8,4.6-4.9,4.6-10.3c0-1.9-0.4-3.4-0.9-4.6c0.2-0.6,0.4-1.2,0.6-1.8c0.4-1,0.7-1.8,0.9-2.4
					c0.2-0.6,0.3-0.9,0.5-1.4c0.1-0.1,0.1-0.2,0.2-0.4c2.8-2.8,4.1-6.9,4.5-10c0.9-1.1,1.5-2.4,1.8-3.8c2.8,0.2,6.8,0.7,12.1,1.6
					l3.7,0.9c0.7,0.2,1.4,0.3,2.1,0.3c6,0,9.3-3.6,10.2-7.8c1.7,0.3,3.2,0.5,4.7,0.5c3,0,5.6-0.7,8.3-2.2c0.7-0.2,1.3-0.5,1.7-0.7
					c0,0,0.1,0,0.1,0c2.7-0.7,5-2,6.7-3.7c0.1,0,0.1,0,0.2,0c5.3,0,8.1-2.5,9.4-5.2c2-2.9,3.2-7.3,1.1-13.8v-7.6
					c0.1-0.4,0.2-0.7,0.2-1.1c0.5-2.3,1-4.6,0.6-7c-0.2-1.5-0.7-2.8-1.4-4c1.7-1,3.1-2.3,4.1-3.8l1.9-1.9c2-2,6.2-6.8,7.2-13
					c0,0,0-0.1,0.1-0.1c0.8-0.8,1.5-1.8,2.1-3c1.4,1,3,1.9,4.7,2.3c1.2,0.5,2.5,0.8,3.8,0.8c0.3,0,0.5,0,0.8,0
					c1.8,1,3.9,1.6,6.3,1.6c0.8,0,2.3-0.1,4-0.8c1.6-0.1,3.4-0.4,5.1-1.4c2.4,2.6,5.4,3.2,7.9,3.2c2.3,0,4.4-0.9,6-2.5
					c0,0,0.1-0.1,0.1-0.1c1.1-0.4,2.1-1.1,2.9-1.9c2.2-2.2,3.1-4.7,3.3-6.4c0.4-0.8,1.2-2.2,1.7-2.6c0.4-0.4,0.8-0.8,1.1-1.3
					c2.6-3.9,3.5-7,2.9-10.8c0.1-0.3,0.2-0.6,0.3-1c0.5-1,1.2-2.7,1.2-5c0-3.1-1.4-5.5-2.5-6.9c-0.1-0.3-0.2-0.6-0.4-0.9
					c-0.1-0.2-0.2-0.8-0.4-1.3c-0.3-1.2-0.7-2.8-1.5-4.5c0.8-3.4,0.8-6.5,0.8-8.4c0-0.2,0-0.3,0-0.5c1.2-1.3,2.2-2.7,3-4.2
					c0.5-0.1,1-0.2,1.5-0.3c0,0,0,0,0,0c1.6,1.6,3.7,2.4,5.9,2.4c4.8,0,8.9-2.8,10.6-7.3c0.7-1.8,0.8-3.5,0.9-4.9
					c0.2-0.7,0.4-1.5,0.5-2.5c0.5-0.2,1-0.4,1.6-0.6c2.6,2,5.6,3.2,8.4,3.2c5.3,0,8.1-2.5,9.5-5.2c0.5-0.7,0.9-1.4,1.1-2.1
					c0.5-0.3,0.9-0.6,1.3-1c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.2,0.5-0.4,0.8-0.6c1,0.3,2,0.4,3.1,0.4c2.2,0,3.8-0.7,4.6-1.1
					c2.4-1.2,7.1-4.5,6.2-12.1c1.5-2.9,1.5-6,1.5-7.5c0-2.3-0.9-4.4-2.5-6c-0.1-0.1-0.3-0.3-0.4-0.4c0.1-0.1,0.2-0.3,0.3-0.4
					c3-3,4.6-5,4.6-9.2c0-0.6,0-1.1-0.1-1.7c2.5-1.9,4.1-5,4.1-8.3c0-0.3,0-0.6,0-0.9c2.7-0.4,6-1,9-2.3c1.5,0.7,3.1,1.2,4.9,1.2
					c1.8,0,3.7-0.5,5.5-1.3c0.8-0.4,1.6-0.9,2.2-1.6c1.4-1.4,2.1-2.8,2.5-3.6c0.3-0.3,0.5-0.7,0.8-1.1c0.1-0.1,0.3-0.2,0.4-0.3l3-2
					c0.5-0.3,0.9-0.7,1.3-1.1c3.5-3.5,3.5-7.5,3.5-9c0-1.7-0.5-3.3-1.4-4.7l-1.2-1.8c0,0,0-0.1,0-0.1c0.4-4-1.2-8.1-2.8-12.1
					c-2-7.3-6.6-9.2-10.8-10.1c0.1-0.7,0.2-1.5,0.2-2.2c0-1.7-0.3-3.1-0.7-4.4c1.2-1,2.7-2.2,4.3-3.7c3.9-1.2,7.6-3.8,9.9-6.6
					c1.3-0.2,2.6-0.6,4.3-1.4c8.2-3,8.2-9.6,8.2-12.9c0-1.3,0-3.6-0.7-6.1c-0.5-1.7-1.2-3.2-2.1-4.5c-0.1-0.5-0.2-0.9-0.2-1.2
					c0,0,0,0,0-0.1c-0.2-1.9-1.1-3.8-2.4-5.2c-0.4-0.4-0.9-0.9-1.5-1.4c-0.2-0.6-0.4-1.2-0.6-1.8c-1-2.3-2.7-4.3-4.9-5.7
					c0,0,0,0,0,0c-0.1-0.3-0.2-0.6-0.4-0.8l-1-2c-1-2-2.8-3.5-4.9-4.2c-1.6-0.9-3.6-1.5-5.7-1.5c-1.4,0-2.8,0.3-4,1
					c0.5-0.9,0.9-2,1.2-3c1.6-3.9,1.4-7.3-0.6-11.3c-0.4-0.8-0.9-1.6-1.6-2.2c-0.6-0.6-1.3-1.3-2.3-2c-0.1-0.2-0.2-0.4-0.3-0.6
					c-1.5-2.4-3.9-4-6.9-4.6c-1.1-0.4-2.3-0.6-3.5-0.6c-3.8,0-7.1,2.1-9,5.2c-1,0.4-2,0.9-3,1.8c-1.4,0.9-2.5,2.1-3.4,3.4
					c-2.3-0.4-4.2-0.6-6.2-0.6c-3.4,0-6.3,0.8-9.8,2c-4.9,1.2-7.9,2.4-10,3.8c-5.1,0.6-8.5,3.2-11.3,6.1c-4.8,4-5,4.2-7.8,5.6
					c-0.3-0.1-0.5-0.2-0.8-0.3c-0.6-0.5-1-0.9-1.6-1.7c-0.3-0.5-0.7-0.9-1.1-1.3l-0.7-0.7c-1.5-2.6-4.2-4.4-7.4-4.7l-4.7-0.7
					c-0.8-3.2-2.8-5.9-5.9-7.6c-2.5-1.3-5.5-2-9.2-2.6c-3.7-1.2-6.5-1.7-9.2-1.7c-2.6,0-5,0.5-7.5,1.5c-1.2,0-2.4-0.2-3.6-0.5
					c-0.5-0.2-1.1-0.3-1.6-0.4l-8-1c-0.3,0-0.7-0.1-1.1-0.1h-1h-2.6c0.1-0.5,0.1-1,0.1-1.5c0-2.2-0.7-4-1.2-5
					c-0.3-1.1-0.8-2.2-1.5-3.1c0,0,0,0,0,0c-0.6-1.3-1.6-3.5-4-6.1c-3.6-5.8-5.3-7.4-11.7-9.1c-0.2-0.1-0.4-0.1-0.7-0.1
					c-0.6-0.1-1.2-0.2-1.8-0.2c-0.2-0.3-0.4-0.7-0.6-1c2.7-0.3,5.5-1,7.8-3.1c3-1.4,4.7-3.3,5.6-5.3c1.6-0.9,2.8-2.1,3.6-3
					c0.3-0.3,0.5-0.6,0.8-0.9c1.1,0.4,2.2,0.7,3.5,1l4.9,2.5c0.3,0.2,0.6,0.3,1,0.5c1.5,0.9,4.1,2.4,7.8,2.4h1
					c7.6,0,12.8-4.3,16-7.5c0.4-0.4,0.8-0.8,1.1-1.3l1.7-2.5c3.5-4.2,3.4-9.8-0.4-13.9c-0.3-1.6-1.1-3.1-2.3-4.3
					c-3.5-3.5-7.5-3.5-9-3.5c-1.2,0-3.1,0-5.3,0.4c-2.6-2-5.2-2.4-7.1-2.4c-1.9,0-3.7,0.4-5.4,1.1c-1.7-1.2-3.7-1.8-5.7-1.8
					c-1.9,0-3.7,0.5-5.4,1.5c-0.1,0-0.2,0.1-0.3,0.1c-0.7-0.4-1.4-0.7-2-1c-0.6-0.3-1.1-0.5-1.7-0.6c-0.8-0.2-1.5-0.4-2.1-0.6
					c-2-0.6-3.9-1.1-6.1-1.1c-0.6,0-1.2,0-1.8,0.1c-2.5-2.1-5.3-3.9-7.3-4.8C365.6,47,364.3,46.7,363,46.7L363,46.7z M451.5,367.2
					L451.5,367.2L451.5,367.2L451.5,367.2z M310,478.7C310,478.7,310,478.7,310,478.7L310,478.7L310,478.7z M315.5,113.5
					c-2,0-3.8,0.5-5.6,1.4c-1.5,0.4-2.9,1.1-4,2.2c-3.5,3.5-3.5,8.2-3.5,10c0,1.3,0.3,2.6,0.9,3.8c3.2,6.4,7.5,7.7,10.6,7.7h1
					c5.5,0,8.7-3.1,10-4.5c1.6-1.6,2.5-3.8,2.5-6v-2v-3c0-3.2-1.8-6.2-4.7-7.6C321.2,114.8,318.7,113.5,315.5,113.5L315.5,113.5z"/>
			</g>
			<g id="st0">
				<path d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,47.2c-2,0-6.7,0-10.1,3.5c-1.6-0.8-3.4-1.3-5.6-1.3c-2,0-4.1,0.4-6.3,1.3
					c-0.2,0.1-0.4,0.2-0.6,0.3c-3.6,1.8-6.3,4.3-8.1,7.3c-0.4-0.1-0.9-0.1-1.3-0.1h-1c-0.9,0-1.7,0.1-2.5,0.4
					c-2.2,0.7-5.2,2.6-7.1,7.2c-1.2,2.9-2.4,8.5-1.3,12.9c0.7,5.6,5.1,9.5,10.9,9.5h1c0.5,0,1,0,1.5-0.1c0.5,0.7,1,1.3,1.5,2
					l0.6,0.8c0,0.1,0.1,0.1,0.2,0.2c3.2,4,5.8,7.2,9.5,8.4c1.6,1.3,3.8,2.6,6.7,2.6c0,0,0,0,0,0c0.1,1.4,0.3,3,1.2,5.1
					c0.3,1.6,0.9,3.1,1.8,4.4c-1,1.2-2.1,2.7-2.6,4.7c-1.1,2.5-1,5.3,0.2,7.8c0.8,1.7,2,2.8,2.7,3.6c1,1,2.2,1.7,3.5,2
					c-3.2,2.7-5.4,6.9-6.5,11.1c0,0.1,0,0.2-0.1,0.2c-5.8,0.3-10.1,4.2-11,9.7c-0.6-0.1-1.2-0.1-1.9-0.1c-4.2,0-7,1.8-9.1,3.3
					c-0.2,0.1-0.4,0.3-0.6,0.4c-0.4,0.3-0.8,0.6-1.2,1l-0.6,0.6c-0.2-0.3-0.4-0.6-0.6-0.9c-0.1-0.2-0.3-0.4-0.4-0.6
					c-1-5.6-5.3-9.5-10.8-9.5c-2.1,0-4.8,0.6-7.3,3.3c0-1.7-0.5-3.7-1.6-5.9c-1-2-2.8-3.5-4.8-4.1c-0.4-0.2-0.9-0.4-1.4-0.5
					c0-0.2,0-0.4,0.1-0.6c0.1-0.4,0.2-0.8,0.3-1.1c0.7-2.5,2-6.8-1.3-11.3c-0.5-4.6-3.8-7.3-5.1-8.3c-0.1-0.1-0.2-0.2-0.3-0.2
					c-0.6-0.6-1.3-1.1-2-1.4c-2.8-3.7-7-5.8-11.9-5.8c-1.8,0-3.6,0.3-5.5,0.8c-0.6-0.8-1.3-1.6-2-2.2c-0.3-1.2-0.8-2.5-1.8-3.9
					c-0.4-0.9-1-1.7-1.7-2.4c-2.7-2.7-6.8-4.3-10.7-4.3c-2.5,0-6.3,0-9.7,3.3c-0.1,0.1-0.1,0.1-0.2,0.2c-3.7,1.1-6.3,3.9-7,7.5
					c-0.6,2-0.9,5,1,8.1c0,0.2,0,0.4,0,0.6c-0.4,0.2-0.9,0.3-1.3,0.5c-2.1-2.7-4.5-4.5-7.3-5.5c-0.1-2-0.7-4.4-2.8-6.5
					c-2.2-2.2-4.6-3.8-10-5c-0.9-0.5-1.9-0.9-3-1.4c1-1.3,1.7-2.8,2-4.5c0.8-2.1,0.9-4.6,0.1-7c-0.6-1.9-1.8-3.7-3.2-5.1
					c-1.5-1.5-3.5-2.3-5.7-2.3h-2c-1.6,0-3.3,0.4-4.9,1.3c-0.1-0.2-0.1-0.4-0.2-0.6c0.1-0.5,0.1-1.1,0.1-1.7c0-2.7-1.2-5.6-3.3-7.7
					c-1-1-3.8-3.3-7.7-3.3c-2,0-3.9,0.7-5.3,2c-1.5,0.1-3.5,0.3-5.6,1.2c-0.7-0.1-1.4-0.2-2.1-0.2c-5.3,0-11,4.4-11,11
					c0,1.6,0.3,3.2,0.8,4.7c-1.5,1-2.6,2.5-3.4,4c-0.3,0.2-0.7,0.4-1,0.7c-3.6,2.2-5.3,5.7-6.5,8c-0.6,1.1-0.8,2.3-0.8,3.6
					c0,1.7,0,3.9,1,6.2v0.1c-1.2,3.1-2,7-2,9.6c0,0.3,0,0.7-0.1,1.2c-0.2,2.1-0.5,6.7,3.4,10.6c0.1,0.6,0.3,1.1,0.5,1.6
					c-1.9,4.5-2.5,10.3-2.7,12.6c0,0,0,0-0.1,0c-0.4,0-0.9,0-1.3,0.1c-5.5,0.9-10.8,2.2-14.6,6.8c-3.4,0.6-6.7,2.4-9.7,5.4
					c-1,1-1.7,2.3-2.1,3.7c-3.6,0-6.9,0.1-9.7,1h-2.2c-1.9-0.7-4-1.4-6.6-1.4c-1.9,0-3.7,0.4-5.4,1.3c-4.5,2.2-5.7,6.6-6.3,8.7
					c-0.1,0.3-0.2,0.7-0.3,0.9c-0.5,1.1-0.8,2.3-0.8,3.5c0,3.2,0,6.3,1.4,10.5c0,0.1,0.1,0.1,0.1,0.2c-0.1,0.5-0.2,1.1-0.3,1.7
					c-0.1,0.5-0.2,1-0.2,1.5c-0.1,0.9-0.9,3.3-1.4,5c-0.6,1.3-1.9,4.3-2.4,7.5c-0.7,0.7-3.5,3.9-4.7,7.6c0,0,0,0-0.1,0
					c-1.8,0.2-3.2,0.7-4,1.1c-1.5,0.3-2.9,1-4,2.1c-0.1,0.1-0.2,0.2-0.3,0.2c-1.1,0.9-3.7,3-4.7,6.6c-0.7,1.5-1.2,3.2-1.2,4.9
					c0,1.5,0.3,3,0.9,4.4c-0.1,0.3-0.1,0.6-0.1,0.9c-3.4,3.2-4,6.8-4.8,11.4l-0.1,0.7c-0.9,3.9-1.1,8.1-1.1,10.9
					c-5.5,1-7.9,4.9-9,7.5c-0.2,0.1-0.3,0.3-0.5,0.4c-3.6,2.1-5.6,6.5-5.6,9.8c0,3.6,2.2,7.5,4.3,9.6c0.1,0.1,0.1,0.1,0.2,0.2
					c0.8,1,2.6,3.1,5.7,4.3c1.8,3.2,5.5,6.1,8,7.7c-2,2-3.2,4.9-3.2,8.2c0,5.6,4.6,8.2,7.8,9.8c0,0.8-0.1,2.2-0.6,4.4
					c0,0.1,0,0.1,0,0.2c-0.2,1-0.4,2-0.5,2.7c-1.8,1.9-4.6,5.3-4.6,9.9c0,2.3,0.6,4.4,1.6,6.1c-1.6,1.7-3.1,3.8-4.2,7.4
					c-0.3,0.8-0.4,1.7-0.4,2.5c0,1.6,0,3.7,0.6,5.9c-0.6,2-0.8,4.1-0.3,6.1c0.4,1.6,1.1,3,2.1,4.2c0.1,0.3,0.2,0.7,0.3,1
					C68,364,63.8,371,62.4,373.6c-1.2,1.5-2.1,3.2-3,4.9c-1.2,1.3-2.7,3-4,4.9c-3.1,4.1-4.3,7-4.3,9.8c0,0.9,0,2.8,0.8,4.9
					c0.2,3.6,2,8.3,8.7,12.8c0.3,0.2,0.6,0.4,0.9,0.5c2.5,1.3,5,1.9,7.5,1.9c2.6,0,5.1-0.7,7.5-2.1c1.9,0.8,3.9,1.3,5.6,1.6
					c0.1,2.4,0.6,5.4,3.3,8.1c0.6,0.6,1.3,1.1,2.1,1.5c1.5,0.7,3,1.1,4.6,1.1c4.5,0,7.2-2.9,8.3-4.3c0,0,0.1,0,0.1,0
					c6-0.2,9.9-4,11.2-5.3c0.4-0.4,0.7-0.8,1-1.2c0.6-0.9,1.9-2.1,2.7-2.7c4.5-1,9.3-4.6,12.3-7.8c3.3-0.3,5.9-1.5,7.7-3.2l0.6,0.6
					c0.5,1,1.2,1.9,2,2.7c0,1.6,0.4,2.9,0.8,4.1c-0.8,2.1-2.2,5.9,0.1,10.4c1.9,3.7,5.6,6.2,10.4,7.1c0.1,0.1,0.3,0.2,0.4,0.3
					c0.9,0.8,1.9,1.3,3,1.7c-0.2,2.1,0.1,3.8,0.4,5.1v0c-0.7,2.4-1.4,6.6,0.9,10.7c-0.1,2.5-0.4,5.1-0.7,7.5
					c-0.1,0.2-0.1,0.4-0.2,0.6c-0.9,2.4-2.1,5.9-1,9.8c0,1.1,0.2,2.5,0.6,3.8c0.4,1.1,0.9,2,1.5,2.9c0.1,0.7,0.2,1.4,0.3,2
					c-0.3,1-0.4,2-0.4,2.8c0,2.9,0.9,5.4,2.4,7.3c0.8,3.1,2.8,4.9,4.8,5.8c1.5,0.9,3.5,1.6,6.1,1.6c1.8,0,3.8-0.3,6.6-1
					c0.4-0.1,0.7-0.2,1.1-0.4c0.4,0.1,1.1,0.2,1.9,0.4c0.4,0.1,0.8,0.3,1.2,0.4c2,4,5.9,5.9,7.7,6.7c0.2,0.1,0.4,0.2,0.6,0.3
					c0.3,0.1,0.6,0.3,0.9,0.4c0.9,0.7,1.7,1.2,2.3,1.5c3,4.9,6.7,6,9.4,6c0.6,0,1.2-0.1,1.8-0.2c1.8,0.9,3.9,1.8,6.7,2.8
					c0,0,0,0,0,0c0.6,1.9,1.6,3.5,3,4.9c0.9,2.7,2.7,4.8,5.4,6.2c2,1,4.2,1.4,6.5,1.8c0.6,0.1,1.3,0.2,2,0.4
					c0.4,0.1,0.9,0.1,1.3,0.2c1.4,1.1,3.3,2.4,6.1,2.8c1.4,1.8,3.2,3.2,5.5,4.3c1.1,0.6,2.3,0.8,3.6,0.8h0c1,0.3,1.9,0.4,2.7,0.4
					c1.2,0,2.2-0.3,3-0.5c0.8,0.2,1.6,0.3,2.4,0.3c0.7,0,1.3-0.1,1.9-0.2c1.2,0.7,2.5,1,3.9,1h3c1.1,0,2.3-0.2,3.6-0.7
					c0.6,0.1,1.3,0.2,2.1,0.2c0.1,0,0.1,0,0.2,0c1.4,1.1,3,1.8,4.6,2.2c1.8,0.8,4,1.7,6.8,1.7c1.9,0,3.7-0.4,5.3-1.3
					c0.8-0.4,1.5-0.9,2.1-1.5c0.6-0.6,1.1-1.2,1.5-1.8c0.5-0.1,1-0.3,1.5-0.5c2.7,0.7,5.5,0.7,7.3,0.7h5c1.5,0,5.3,0,8.7-3.3
					c3.3-3.3,3.3-7.2,3.3-8.7c0-0.6-0.1-1.1-0.2-1.6c0.2-0.9,0.5-2.2,0.8-3.3c2.8-0.7,5.7-2.7,7.6-7.6c2.1-3.7,3.2-9.3-1.2-16.3
					c0.6-1,1.1-2.1,1.5-3.2c2.2-1.7,4.5-4.7,4.5-10c0-1.9-0.4-3.4-0.9-4.6c0.3-0.6,0.5-1.3,0.6-2c0.4-1,0.7-1.8,0.9-2.4
					c0.2-0.6,0.3-1,0.6-1.5c0.1-0.1,0.1-0.3,0.2-0.4c2.8-2.8,4.1-6.8,4.5-9.9c0.9-1.2,1.6-2.6,1.9-4c0,0,0,0,0-0.1
					c2.8,0.2,7,0.7,12.5,1.7l3.7,0.9c0.6,0.2,1.3,0.2,1.9,0.2c5.8,0,8.9-3.5,9.8-7.6l0.1-0.2c1.8,0.3,3.4,0.5,5.1,0.5
					c2.9,0,5.5-0.7,8.1-2.1c0.7-0.2,1.3-0.5,1.7-0.7c0,0,0.1,0,0.1,0c2.8-0.7,5-2,6.7-3.8c0.1,0,0.2,0,0.4,0c5.1,0,7.7-2.4,9-5
					c1.9-2.8,3.1-7.1,1-13.5V408c0.1-0.4,0.2-0.8,0.2-1.1c0.5-2.3,1-4.5,0.6-6.8c-0.1-0.9-0.5-2.5-1.5-4.1c0-0.1,0.1-0.2,0.1-0.3
					c1.8-0.9,3.3-2.2,4.3-3.9l1.9-1.9c1.5-1.5,6-6.5,7.1-12.8c0-0.1,0.1-0.2,0.1-0.3c0.9-0.9,1.7-2.1,2.3-3.5
					c1.4,1.2,3.1,2.1,5.1,2.6c1.1,0.5,2.3,0.7,3.6,0.7c0.3,0,0.6,0,0.9,0c1.7,1,3.9,1.6,6.2,1.6c1.3,0,2.6-0.3,3.9-0.8
					c1.6-0.1,3.5-0.4,5.3-1.6c2,2.2,4.6,3.4,7.8,3.4c2.1,0,4.2-0.8,5.7-2.3c0.1-0.1,0.1-0.1,0.2-0.2c1.1-0.4,2-1,2.8-1.8
					c1.7-1.7,2.9-3.9,3.2-6.2c0.4-0.9,1.3-2.3,1.8-2.8c0.4-0.4,0.7-0.8,1-1.2c2.6-3.8,3.4-6.8,2.8-10.6c0.1-0.4,0.2-0.7,0.3-1.1
					c0.5-1,1.2-2.6,1.2-4.8c0-3-1.4-5.3-2.5-6.7c-0.1-0.3-0.2-0.6-0.4-0.9c-0.1-0.2-0.3-0.8-0.4-1.4c-0.3-1.2-0.7-2.8-1.6-4.6
					c0.8-3.4,0.8-6.5,0.8-8.5c0-0.2,0-0.5,0-0.7c1.4-1.4,2.4-2.9,3.2-4.4c0.7-0.1,1.4-0.2,2-0.4c0.1,0.1,0.2,0.1,0.2,0.2
					c1.5,1.5,3.5,2.3,5.6,2.3c4.6,0,8.5-2.7,10.1-6.9c0.7-1.7,0.8-3.4,0.9-4.8c0.2-0.7,0.4-1.7,0.6-2.7c0.7-0.2,1.4-0.5,2.1-0.8
					c2.5,2,5.6,3.3,8.4,3.3c5.1,0,7.8-2.4,9-5c0.5-0.7,0.9-1.5,1.2-2.2c0.5-0.3,0.9-0.7,1.4-1.1c0.1-0.1,0.3-0.2,0.5-0.4
					c0.3-0.2,0.6-0.4,0.9-0.7c1.2,0.4,2.4,0.5,3.2,0.5c2.1,0,3.7-0.6,4.4-1c2.3-1.1,6.9-4.4,5.9-11.8c1.5-2.9,1.5-5.9,1.5-7.4
					c0-2.1-0.8-4.2-2.3-5.7c-0.2-0.2-0.4-0.4-0.7-0.7c0.2-0.3,0.3-0.5,0.5-0.8c2.9-3,4.5-4.9,4.5-8.8c0-0.6-0.1-1.3-0.2-1.9
					c2.5-1.8,4.2-4.8,4.2-8.1c0-0.4,0-0.9,0-1.3c2.8-0.3,6.4-1,9.5-2.4c1.4,0.8,3.1,1.2,4.8,1.2c1.8,0,3.5-0.4,5.2-1.3
					c0.8-0.4,1.5-0.9,2.1-1.5c1.4-1.4,2.1-2.7,2.5-3.5c0.3-0.4,0.6-0.7,0.8-1.1c0.1-0.1,0.3-0.2,0.5-0.4l3-2c0.4-0.3,0.8-0.6,1.2-1
					c3.3-3.3,3.3-7.2,3.3-8.7c0-1.6-0.5-3.1-1.3-4.4l-1.2-1.8c0-0.1-0.1-0.2-0.1-0.3c0.4-4-1.2-8-2.7-12c-2-7.3-6.5-9-10.9-9.9
					c0.2-0.8,0.3-1.7,0.3-2.6c0-1.8-0.3-3.3-0.8-4.6c1.3-1,2.9-2.3,4.7-4c3.9-1.1,7.6-3.8,9.9-6.7c1.3-0.2,2.7-0.6,4.3-1.4
					c7.9-2.9,7.9-9.3,7.9-12.4c0-4.5-0.9-8-2.8-10.5c-0.1-0.5-0.2-0.9-0.2-1.2c0,0,0-0.1,0-0.1c-0.2-1.8-1-3.6-2.3-4.9
					c-0.4-0.4-0.9-0.9-1.6-1.5c-0.1-0.6-0.4-1.3-0.6-1.9c-1-2.2-2.6-4.1-4.8-5.5c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.3-0.2-0.6-0.4-0.9
					l-1-2c-1-1.9-2.7-3.4-4.6-4c-1.6-0.9-3.4-1.4-5.5-1.4c-1.6,0-3.1,0.5-4.4,1.3l-1.4,0.9c1.3-1.4,2.1-3.2,2.6-4.9
					c1.5-3.7,1.4-7.1-0.6-10.9c-0.4-0.8-0.9-1.5-1.5-2.1c-0.6-0.6-1.3-1.3-2.3-2.1c-0.1-0.2-0.2-0.4-0.4-0.6
					c-1.4-2.3-3.8-3.9-6.6-4.4c-1.1-0.4-2.2-0.6-3.4-0.6c-3.7,0-6.9,2.1-8.7,5.1c-1,0.4-2,0.9-3.1,1.7c-1.5,0.9-2.6,2.2-3.5,3.6
					c-2.5-0.4-4.4-0.7-6.4-0.7c-3.4,0-6.2,0.8-9.6,2c-4.9,1.2-7.9,2.4-10,3.8c-5,0.6-8.3,3.2-11.2,5.9c-5,4.1-5.1,4.2-8.1,5.7
					c-0.4-0.1-0.7-0.3-1.1-0.4c-0.6-0.5-1.2-1-1.7-1.9c-0.3-0.4-0.6-0.8-1-1.2l-0.8-0.8c-1.4-2.5-4-4.2-7-4.5l-5.1-0.7
					c-0.7-3.3-2.7-5.9-5.8-7.6c-2.4-1.3-5.4-2-9.1-2.5c-3.7-1.2-6.4-1.7-9.1-1.7c-2.5,0-4.9,0.5-7.4,1.5c-1.3,0-2.6-0.2-3.8-0.6
					c-0.5-0.2-1-0.3-1.5-0.3l-8-1c-0.3,0-0.7-0.1-1-0.1h-1h-3c-0.1,0-0.2,0-0.3,0c0.2-0.7,0.3-1.3,0.3-2c0-2.2-0.7-3.8-1.2-4.8
					c-0.3-1.1-0.7-2.1-1.4-3c0,0,0,0,0-0.1c-0.6-1.2-1.6-3.4-4-6c-3.5-5.7-5.2-7.3-11.5-8.9c-0.2-0.1-0.4-0.1-0.6-0.1
					c-0.7-0.1-1.3-0.2-2-0.3c-0.3-0.6-0.7-1.3-1.2-1.9c2.9-0.2,6-0.8,8.4-3.1c2.7-1.2,4.6-3,5.6-5.2c1.6-0.9,2.8-2.1,3.6-2.9
					c0.3-0.3,0.7-0.7,1-1.1c1.3,0.5,2.6,0.8,3.8,1.1l5,2.5c0.3,0.2,0.6,0.3,1,0.6c1.6,0.9,4,2.3,7.6,2.3h1c7.5,0,12.5-4.2,15.7-7.3
					c0.4-0.4,0.7-0.8,1-1.2l1.7-2.5c3.4-4,3.2-9.5-0.5-13.4c-0.3-1.6-1.1-3.1-2.2-4.2c-3.3-3.3-7.2-3.3-8.7-3.3
					c-1.2,0-3.2,0-5.4,0.4c-2.5-2-5.1-2.5-7-2.5c-1.9,0-3.7,0.5-5.4,1.2c-1.7-1.2-3.7-1.8-5.7-1.8c-1.8,0-3.6,0.5-5.1,1.5
					c-0.2,0.1-0.4,0.2-0.5,0.3c-0.7-0.4-1.5-0.8-2.2-1.1c-0.5-0.3-1.1-0.5-1.6-0.6c-0.8-0.2-1.5-0.4-2.1-0.6c-2-0.6-3.8-1.1-6-1.1
					c-0.6,0-1.3,0-1.9,0.1c-2.5-2.2-5.4-3.9-7.3-4.9C365.5,47.5,364.2,47.2,363,47.2L363,47.2z M329,92.2h-2c-2.1,0-4.2,0.8-5.7,2.3
					c-3.3,3.3-3.3,7.1-3.3,9.7c0,0.9,0.1,1.7,0.4,2.5c0.4,1.2,1.8,4.8,5,7.3c0.6,0.8,1.2,1.5,1.8,2c2.9,4.2,6.3,5.1,8.8,5.1
					c1.5,0,5.3,0,8.7-3.3c0.4-0.4,0.7-0.8,1-1.2c4.4-6.6,1-12.5-0.2-14.4c-0.1-0.2-0.3-0.5-0.3-0.6c-0.4-0.8-0.9-1.5-1.5-2.1
					C338.6,96.4,334.3,92.2,329,92.2L329,92.2z M315.5,114c-1.9,0-3.7,0.5-5.4,1.4c-1.4,0.3-2.8,1.1-3.8,2.1
					c-3.3,3.3-3.3,7.9-3.3,9.7c0,1.2,0.3,2.5,0.8,3.6c3.1,6.1,7.2,7.4,10.2,7.4h1c5.3,0,8.2-2.9,9.7-4.3c1.5-1.5,2.3-3.5,2.3-5.7v-2
					v-3c0-3-1.7-5.8-4.4-7.2C321,115.2,318.6,114,315.5,114L315.5,114z"/>
			</g>
			<g id="st1">
				<path id="st2" d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,47.7c-2,0-6.7,0-10,3.6c-1.6-0.8-3.5-1.4-5.7-1.4c-1.9,0-3.9,0.4-6.1,1.3
					c-0.2,0.1-0.4,0.2-0.6,0.3c-3.6,1.8-6.3,4.3-8,7.4c-0.5-0.1-1-0.1-1.6-0.1h-1c-0.8,0-1.6,0.1-2.4,0.4c-2.1,0.7-5,2.5-6.8,6.9
					c-1.2,2.8-2.3,8.3-1.2,12.6c0.6,5.4,4.7,9.1,10.4,9.1h1c0.6,0,1.2,0,1.7-0.1c0.5,0.8,1.1,1.5,1.7,2.3l0.6,0.8
					c0,0.1,0.1,0.1,0.1,0.2c3.3,4.1,5.7,7.2,9.4,8.3c1.6,1.3,3.7,2.5,6.5,2.5c0.2,0,0.3,0,0.5,0c0,1.5,0.2,3.2,1.1,5.5
					c0.3,1.8,0.9,3.3,1.9,4.6c-1.1,1.1-2.2,2.7-2.7,4.8c-1.2,2.5-1.6,6.4,2.9,10.9c1.2,1.2,2.7,1.9,4.3,2.1c-3.5,2.5-6,7-7.2,11.5
					c-0.1,0.2-0.1,0.4-0.2,0.6c-5.9,0.2-10.3,4.1-11,9.8c-0.8-0.1-1.5-0.2-2.3-0.2c-4.1,0-6.7,1.8-8.8,3.2c-0.2,0.1-0.4,0.3-0.6,0.4
					c-0.4,0.3-0.8,0.6-1.1,0.9l-1,1c-0.3-0.5-0.6-1-0.9-1.4c-0.2-0.2-0.3-0.5-0.4-0.7c-0.9-5.4-5-9.2-10.3-9.2
					c-3.4,0-5.9,1.7-7.8,4.3c0.3-2,0-4.4-1.4-7.2c-0.9-1.9-2.6-3.3-4.6-3.8c-0.5-0.2-1.1-0.4-1.8-0.6c0.1-0.4,0.1-0.7,0.1-1.1
					c0.1-0.4,0.2-0.8,0.3-1.2c0.7-2.4,2-6.6-1.3-11c-0.4-4.5-3.6-7.1-4.9-8.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6-0.6-1.3-1.1-2-1.4
					c-2.7-3.7-6.8-5.7-11.6-5.7c-1.8,0-3.8,0.3-5.7,0.9c-0.7-0.9-1.5-1.8-2.3-2.5c-0.2-1.2-0.8-2.5-1.8-3.9
					c-0.4-0.9-0.9-1.7-1.6-2.3c-2.6-2.6-6.5-4.2-10.3-4.2c-2.6,0-6.1,0-9.3,3.2c-0.1,0.1-0.2,0.2-0.3,0.3c-3.8,1.1-6.1,4-6.8,7.1
					c-0.6,1.9-1,4.9,1,8c0,0.4-0.1,0.7-0.1,1.1c-0.7,0.2-1.3,0.5-1.9,0.8c-2.1-2.9-4.7-4.8-7.7-5.7c0-2-0.5-4.4-2.6-6.5
					c-2.2-2.2-4.5-3.7-9.8-4.8c-1.1-0.5-2.3-1.1-3.7-1.7c1.2-1.3,2-3,2.4-5c1.4-3.7,0.3-8.2-3-11.5c-1.4-1.4-3.3-2.2-5.3-2.2h-2
					c-1.7,0-3.5,0.5-5.2,1.6c-0.1-0.5-0.2-0.9-0.4-1.4c0.1-0.5,0.2-1.1,0.2-1.7c0-2.6-1.2-5.3-3.2-7.3c-1-1-3.6-3.2-7.3-3.2
					c-1.9,0-3.7,0.7-5.1,2c-1.5,0-3.6,0.3-5.7,1.3c-0.7-0.2-1.4-0.3-2.2-0.3c-5.1,0-10.5,4.2-10.5,10.5c0,1.7,0.3,3.3,0.9,4.9
					c-1.6,1-2.8,2.6-3.6,4.2c-0.3,0.2-0.7,0.5-1.1,0.8c-3.5,2.2-5.2,5.5-6.4,7.8c-0.5,1-0.8,2.2-0.8,3.4c0,1.7,0,3.9,1,6.2v0.3
					c-1.2,3-2,6.9-2,9.5c0,0.4,0,0.8-0.1,1.2c-0.2,2.1-0.5,6.6,3.3,10.3c0.1,0.7,0.3,1.3,0.5,1.8c-2.1,4.8-2.7,11.3-2.8,13.1
					c-0.2,0-0.3,0-0.5,0c-0.4,0-0.8,0-1.2,0.1c-5.5,0.9-10.7,2.2-14.4,6.8c-3.4,0.5-6.7,2.3-9.7,5.3c-1,1-1.8,2.4-2,3.8
					c-3.7,0-7.1,0.1-10,1h-2.4c0,0,0,0-0.1,0c-1.9-0.7-4-1.4-6.4-1.4c-1.8,0-3.5,0.4-5.1,1.2c-4.3,2.1-5.5,6.2-6.1,8.4
					c-0.1,0.3-0.2,0.8-0.3,1c-0.5,1-0.8,2.2-0.8,3.3c0,3.2,0,6.2,1.4,10.4c0,0.1,0.1,0.2,0.1,0.3c-0.1,0.5-0.2,1.2-0.4,1.8
					c-0.1,0.5-0.1,1-0.1,1.5c0,0.9-0.9,3.4-1.5,5.2c-0.6,1.3-1.9,4.3-2.4,7.5c-0.3,0.3-3.6,3.8-4.8,7.7c-0.1,0.1-0.2,0.1-0.3,0.1
					c-1.8,0.2-3.1,0.7-4,1.1c-1.4,0.3-2.8,1-3.9,2c-0.1,0.1-0.2,0.2-0.3,0.3c-1.1,0.9-3.6,2.9-4.6,6.3c-1.5,2.9-1.6,6.3-0.2,9.2
					c-0.1,0.4-0.1,0.8-0.2,1.2c-3.4,3.1-4,6.5-4.7,11.2l-0.1,0.7c-1,4.1-1.1,8.5-1.1,11.2c-5.6,0.9-8,4.8-9,7.4
					c-0.2,0.2-0.4,0.4-0.6,0.5c-3.5,2-5.3,6.2-5.3,9.4c0,3.5,2.1,7.2,4.1,9.3c0.1,0.1,0.1,0.1,0.2,0.2c0.8,1,2.6,3.1,5.6,4.2
					c1.9,3.5,6,6.5,8.5,8.1c-2.1,1.8-3.5,4.5-3.5,8.2c0,5.4,4.6,7.9,7.8,9.5c0,0.8-0.1,2.3-0.6,4.8c0,0.1,0,0.1,0,0.2
					c-0.2,1.1-0.4,2.1-0.6,2.8c-1.7,1.8-4.6,5.2-4.6,9.7c0,2.4,0.6,4.4,1.8,6.1c-1.7,1.8-3.2,3.8-4.4,7.5c-0.3,0.8-0.4,1.6-0.4,2.4
					c0,1.6,0,3.8,0.6,5.9c-0.6,1.8-0.9,3.8-0.3,6c0.4,1.6,1.1,2.9,2.1,4.1c0.1,0.5,0.3,1,0.5,1.4c-5.8,3.5-10.1,10.7-11.5,13.3
					c-1.2,1.4-2.1,3.2-3,4.9c-1.2,1.3-2.7,3-4.1,4.9c-3,4-4.3,6.8-4.3,9.5c0,1,0,2.8,0.8,4.8c0.2,3.4,1.9,8.1,8.5,12.5
					c0.3,0.2,0.5,0.3,0.8,0.5c2.4,1.2,4.9,1.8,7.3,1.8c2.6,0,5.1-0.7,7.5-2.2c2.1,0.9,4.3,1.5,6.1,1.8c0.1,2.4,0.4,5.4,3.2,8.2
					c0.6,0.6,1.2,1,1.9,1.4c1.4,0.7,2.9,1.1,4.4,1.1c4.4,0,7-3,8-4.2c0.1,0,0.2-0.1,0.3-0.1c5.9-0.2,9.5-3.8,10.9-5.2
					c0.3-0.3,0.7-0.7,0.9-1.1c0.7-1,2-2.3,2.9-2.9c4.5-0.9,9.4-4.6,12.3-7.8c3.5-0.3,6.1-1.6,7.9-3.4l1,1c0.5,1.1,1.2,2,2.1,2.8v0.1
					c0,1.6,0.4,3,0.8,4.2c-0.9,2.1-2.3,5.7,0,10.2c1.8,3.6,5.5,6,10.1,6.8c0.2,0.1,0.4,0.2,0.6,0.4c0.9,0.8,2,1.4,3.2,1.7
					c-0.2,2.3,0,4,0.3,5.4v0.2c-0.7,2.3-1.4,6.4,1,10.5c-0.1,2.6-0.4,5.3-0.8,7.8c-0.1,0.2-0.1,0.4-0.2,0.6c-0.9,2.5-2.1,5.9-1,9.6
					c0,1.1,0.2,2.4,0.6,3.7c0.4,1.1,0.9,2,1.5,2.9c0.1,0.7,0.2,1.5,0.3,2.2c-0.3,1-0.4,1.9-0.4,2.8c0,3,0.9,5.3,2.3,7.1
					c0.8,3.2,2.8,4.9,4.9,5.8c1.4,0.8,3.2,1.4,5.7,1.4c1.8,0,3.7-0.3,6.4-1c0.4-0.1,0.8-0.2,1.2-0.4c0.5,0.1,1.1,0.2,2.1,0.4
					c0.5,0.2,1,0.3,1.4,0.5c1.9,4,5.8,5.8,7.5,6.7c0.2,0.1,0.4,0.2,0.6,0.3c0.3,0.2,0.6,0.3,0.9,0.4c0.9,0.8,1.8,1.2,2.4,1.5
					c2.9,4.8,6.4,5.9,9,5.9c0.7,0,1.3-0.1,1.9-0.2c1.8,0.9,3.9,1.9,6.8,2.8c0.1,0,0.2,0.1,0.3,0.1c0.5,1.9,1.5,3.6,3.1,5
					c0.8,2.6,2.6,4.7,5.2,6c2,1,4.1,1.4,6.3,1.8c0.6,0.1,1.3,0.2,2,0.4c0.5,0.1,1,0.1,1.4,0.1c1.3,1.1,3.3,2.5,6.2,2.9
					c1.4,1.8,3.2,3.2,5.5,4.3c1,0.5,2.2,0.8,3.4,0.8h0.1c1,0.3,1.9,0.4,2.6,0.4c1.2,0,2.2-0.3,3-0.6c0.8,0.2,1.7,0.3,2.5,0.3
					c0.7,0,1.4-0.1,2-0.2c1.1,0.7,2.5,1,3.8,1h3c1,0,2.3-0.2,3.5-0.7c0.6,0.1,1.4,0.2,2.2,0.2c0.1,0,0.2,0,0.4,0
					c1.4,1.1,3,1.9,4.6,2.3c1.7,0.8,3.9,1.7,6.6,1.7c1.8,0,3.5-0.4,5.1-1.2c0.7-0.4,1.4-0.8,1.9-1.4c0.6-0.6,1.1-1.2,1.6-1.9
					c0.6-0.2,1.2-0.4,1.8-0.6c2.7,0.7,5.5,0.7,7.3,0.7h5c1.5,0,5.1,0,8.3-3.2c3.2-3.2,3.2-6.8,3.2-8.3c0-0.5-0.1-1.1-0.2-1.6
					c0.2-1,0.6-2.5,0.9-3.8c2.7-0.6,5.7-2.5,7.5-7.4c2.1-3.6,3.2-9.1-1.3-16.1c0.6-1.1,1.2-2.3,1.6-3.5c2.1-1.6,4.4-4.5,4.4-9.6
					c0-1.9-0.4-3.4-1-4.6c0.3-0.7,0.6-1.4,0.7-2.1c0.4-1,0.7-1.8,0.9-2.4c0.2-0.6,0.3-1,0.6-1.5c0.1-0.2,0.2-0.3,0.2-0.5
					c2.8-2.7,4.1-6.8,4.4-9.8c1-1.2,1.6-2.6,1.9-4c0.1-0.1,0.2-0.2,0.3-0.4c2.8,0.2,7.1,0.7,12.9,1.7l3.8,0.9
					c0.6,0.1,1.2,0.2,1.8,0.2c5.5,0,8.5-3.4,9.3-7.3l0.3-0.6c1.9,0.4,3.7,0.6,5.3,0.6c2.9,0,5.3-0.7,7.9-2.1
					c0.7-0.2,1.3-0.5,1.8-0.7c0.1,0,0.1,0,0.2,0c2.7-0.7,5-2,6.6-3.8c0.2,0,0.4,0,0.6,0c5,0,7.6-2.4,8.7-4.9
					c1.8-2.7,2.9-6.8,0.8-12.9V408c0.1-0.4,0.2-0.8,0.3-1.2c0.5-2.3,1-4.4,0.6-6.7c-0.1-0.9-0.5-2.5-1.6-4.1
					c0.1-0.3,0.2-0.5,0.2-0.7c1.8-0.9,3.3-2.2,4.3-3.8l2-2c1.5-1.5,5.9-6.4,7-12.6c0.1-0.1,0.1-0.3,0.2-0.4c1-1,1.9-2.4,2.5-4
					c1.5,1.4,3.3,2.4,5.4,3c1.1,0.5,2.2,0.7,3.4,0.7c0.4,0,0.7,0,1-0.1c1.6,1,3.7,1.6,6.1,1.6c1.2,0,2.5-0.3,3.8-0.8
					c2.1-0.1,4-0.6,5.5-1.7c1.9,2.3,4.5,3.5,7.7,3.5c2,0,3.9-0.8,5.3-2.2c0.1-0.1,0.2-0.2,0.3-0.3c1-0.4,2-1,2.7-1.7
					c1.7-1.7,2.8-3.8,3.1-6c0.4-0.9,1.3-2.4,1.9-3c0.3-0.3,0.7-0.7,0.9-1.1c2.5-3.8,3.3-6.7,2.7-10.3c0.2-0.4,0.3-0.8,0.4-1.2
					c0.5-0.9,1.2-2.5,1.2-4.6c0-2.9-1.3-5.1-2.4-6.4c-0.1-0.3-0.2-0.6-0.4-0.9c-0.1-0.3-0.3-0.8-0.4-1.5c-0.3-1.2-0.7-2.8-1.6-4.5
					c0,0,0-0.1-0.1-0.1c0.9-3.4,0.9-6.6,0.9-8.5c0-0.3,0-0.6,0-0.9c1.5-1.5,2.6-3,3.4-4.7c0.9-0.1,1.7-0.2,2.4-0.4
					c0.2,0.2,0.4,0.3,0.5,0.4c1.4,1.4,3.3,2.2,5.3,2.2c4.4,0,8.1-2.5,9.6-6.6c0.6-1.7,0.8-3.4,0.8-4.7c0.2-0.8,0.5-1.8,0.6-3
					c0.9-0.3,1.8-0.6,2.6-1.1c2.5,2.1,5.5,3.4,8.3,3.4c5,0,7.6-2.4,8.7-4.9c0.5-0.7,0.8-1.4,1.1-2.1c0.5-0.3,1-0.7,1.4-1.1
					c0.1-0.1,0.3-0.3,0.5-0.4c0.3-0.2,0.7-0.5,1.1-0.9c1.3,0.4,2.5,0.6,3.3,0.6c2,0,3.5-0.6,4.2-1c2.2-1.1,6.6-4.2,5.6-11.4
					c1.5-2.8,1.5-5.8,1.5-7.3c0-2-0.8-3.9-2.2-5.3c-0.3-0.3-0.6-0.6-1-1c0.3-0.4,0.5-0.8,0.8-1.2c3-3,4.4-4.7,4.4-8.5
					c0-0.7-0.1-1.4-0.2-2.1c2.5-1.7,4.2-4.6,4.2-7.9c0-0.6,0-1.2,0-1.8c2.9-0.3,6.7-1,10-2.5c1.4,0.8,3.1,1.3,4.8,1.3
					c1.7,0,3.4-0.4,5-1.2c0.7-0.4,1.4-0.8,1.9-1.4c1.3-1.3,2-2.7,2.4-3.4c0.3-0.4,0.6-0.8,0.8-1.2c0.2-0.1,0.4-0.3,0.6-0.4l3-2
					c0.4-0.3,0.8-0.6,1.1-0.9c3.2-3.2,3.2-6.8,3.2-8.3c0-1.5-0.4-2.9-1.3-4.2l-1.2-1.9c-0.1-0.2-0.1-0.3-0.2-0.5
					c0.4-3.9-1.1-7.9-2.7-11.9c-2-7.4-6.5-8.7-11-9.7c0.2-0.9,0.3-1.9,0.3-3c0-1.9-0.4-3.4-0.9-4.8c1.4-1,3-2.4,5-4.3
					c4-1.1,7.7-3.8,9.9-6.7c1.3-0.2,2.7-0.6,4.4-1.4c7.6-2.8,7.6-8.7,7.6-11.9c0-4.4-0.9-7.8-2.7-10.3c-0.1-0.5-0.2-1-0.3-1.3
					c0,0,0-0.1,0-0.1c-0.2-1.7-0.9-3.4-2.2-4.6c-0.5-0.5-1-1-1.7-1.6c-0.1-0.7-0.4-1.3-0.6-2c-0.9-2.1-2.5-4-4.6-5.3
					c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1-0.3-0.3-0.7-0.4-1l-1-2c-0.9-1.8-2.5-3.2-4.4-3.8c-1.5-0.9-3.3-1.4-5.3-1.4
					c-1.5,0-2.9,0.4-4.2,1.3l-3,2c-0.4,0.3-0.8,0.6-1.1,0.9c0,0,0,0,0,0c-1.4,0.3-2.6,0.7-3.5,1.2c-0.1-0.2-0.1-0.4-0.2-0.6
					c4.3-0.8,7.4-4.4,8.3-8.1c1.5-3.6,1.3-6.8-0.5-10.5c-0.4-0.7-0.8-1.4-1.4-1.9c-0.6-0.6-1.4-1.4-2.3-2.1
					c-0.1-0.2-0.2-0.5-0.4-0.7c-1.4-2.2-3.6-3.7-6.3-4.2c-1-0.4-2.1-0.6-3.2-0.6c-3.6,0-6.7,2-8.3,5c-1,0.4-2.1,0.9-3.2,1.8
					c-1.5,1-2.7,2.2-3.5,3.7c-2.6-0.4-4.6-0.7-6.7-0.7c-3.3,0-6,0.8-9.5,2c-5,1.3-7.9,2.4-9.9,3.8c-4.9,0.5-8.2,3.1-11,5.8
					c-5.1,4.2-5.1,4.3-8.3,5.8c0,0-0.1,0-0.1,0.1c-0.4-0.2-0.9-0.3-1.4-0.5c-0.7-0.6-1.3-1.1-1.9-2.1c-0.3-0.4-0.6-0.8-0.9-1.1
					l-0.9-0.9c-1.3-2.4-3.8-4.1-6.7-4.3l-5.5-0.8c-0.6-3.3-2.5-5.9-5.6-7.6c-2.4-1.3-5.3-1.9-9-2.5c-3.6-1.2-6.3-1.7-9-1.7
					c-2.5,0-4.9,0.5-7.3,1.5c-1.3,0-2.7-0.2-4-0.6c-0.5-0.2-1-0.3-1.4-0.3l-8-1c-0.3,0-0.6-0.1-0.9-0.1h-1h-3c-0.2,0-0.5,0-0.9,0
					c0,0,0-0.1,0-0.1c0.3-0.8,0.4-1.6,0.4-2.4c0-2.1-0.7-3.7-1.2-4.6c-0.2-1.1-0.7-2.1-1.4-2.9c0,0,0-0.1,0-0.1
					c-0.6-1.2-1.6-3.4-3.9-5.9c-3.5-5.7-5-7.1-11.2-8.7c-0.2,0-0.4-0.1-0.6-0.1c-0.8-0.1-1.5-0.2-2.2-0.3c-0.5-0.9-1.1-1.9-1.9-2.8
					c3-0.1,6.5-0.6,9.1-3.1c2.7-1.2,4.5-2.9,5.4-5.1c1.6-0.9,2.8-2.1,3.6-2.9c0.4-0.4,0.8-0.8,1.1-1.3c1.4,0.6,2.8,0.9,4.1,1.2
					l5,2.5c0.3,0.2,0.7,0.4,1,0.6c1.5,0.9,3.8,2.2,7.3,2.2h1c7.3,0,12.2-4.1,15.3-7.2c0.3-0.3,0.7-0.7,0.9-1.1l1.7-2.5
					c3.3-3.8,3.1-9.1-0.5-12.8c0,0,0-0.1-0.1-0.1c-0.3-1.5-1-2.9-2.1-4c-3.2-3.2-6.8-3.2-8.3-3.2c-1.4,0-3.4,0-5.6,0.4
					c-2.5-2-5-2.5-6.8-2.5c-2,0-3.7,0.5-5.5,1.2c-2.1-1.5-4.1-1.9-5.6-1.9c-1.3,0-3.1,0.3-5,1.5c-0.2,0.1-0.5,0.2-0.7,0.4
					c-0.8-0.4-1.7-0.9-2.5-1.3c-0.5-0.2-1-0.4-1.5-0.6c-0.8-0.2-1.5-0.4-2.2-0.6c-1.9-0.5-3.8-1.1-5.9-1.1c-0.7,0-1.4,0.1-2.1,0.2
					c-2.6-2.2-5.5-4-7.4-5C365.3,48,364.2,47.7,363,47.7L363,47.7z M303.4,156.8c0.3-0.2,0.5-0.4,0.8-0.7c-0.2,0.6-0.4,1.2-0.6,1.9
					C303.5,157.6,303.4,157.2,303.4,156.8L303.4,156.8z M301.1,163.7c0.1-0.1,0.2-0.2,0.2-0.2c0.4-0.4,0.7-0.7,0.9-1.1
					c-0.2,0.6-0.3,1.2-0.5,1.8c-0.1,0.3-0.2,0.7-0.3,1c0,0-0.1-0.1-0.1-0.1C301.3,164.6,301.2,164.1,301.1,163.7L301.1,163.7z
					 M512,243.7C512,243.7,512,243.7,512,243.7L512,243.7L512,243.7z M329,92.7h-2c-2,0-3.9,0.8-5.3,2.2c-3.2,3.2-3.2,6.7-3.2,9.3
					c0,0.8,0.1,1.6,0.4,2.4c0.4,1.2,1.8,4.7,4.9,7.1c0.6,0.8,1.2,1.5,1.8,2c2.7,4,6,5,8.4,5c1.5,0,5.1,0,8.3-3.2
					c0.3-0.3,0.7-0.7,0.9-1.1c4.3-6.4,0.9-12-0.2-13.9c-0.1-0.2-0.3-0.5-0.4-0.6c-0.4-0.7-0.8-1.4-1.4-1.9
					C338.1,96.7,334.1,92.7,329,92.7L329,92.7z M315.5,114.5c-1.8,0-3.6,0.5-5.2,1.3c-1.4,0.3-2.6,1-3.6,2c-3.2,3.2-3.2,7.6-3.2,9.3
					c0,1.2,0.3,2.3,0.8,3.4c3,5.9,6.9,7.1,9.7,7.1h1c5.1,0,7.9-2.8,9.3-4.2c1.4-1.4,2.2-3.3,2.2-5.3v-2v-3c0-2.8-1.6-5.4-4.1-6.7
					C320.8,115.7,318.5,114.5,315.5,114.5L315.5,114.5z"/>
			</g>
			<g id="st0">
				<path d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,48.2c-2,0-6.7,0-9.9,3.7c-1.6-0.9-3.5-1.5-5.8-1.5c-1.9,0-3.8,0.4-5.9,1.3
					c-0.2,0.1-0.4,0.2-0.5,0.2c-3.6,1.8-6.4,4.4-8,7.5c-0.6-0.1-1.2-0.2-1.9-0.2h-1c-0.8,0-1.5,0.1-2.2,0.4c-2,0.7-4.7,2.4-6.5,6.6
					c-1.2,2.8-2.3,8.2-1.2,12.3c0.6,5.2,4.5,8.6,9.9,8.6h1c0.7,0,1.3-0.1,1.9-0.2c0.6,0.8,1.2,1.6,1.8,2.5l0.6,0.8
					c0,0.1,0.1,0.1,0.1,0.2c3.3,4.1,5.7,7.1,9.2,8.2c1.5,1.2,3.5,2.5,6.2,2.5c0.3,0,0.7,0,1,0c0,0,0,0,0,0c0,1.5,0.1,3.3,1.1,5.8
					c0.3,1.9,1,3.5,2.1,4.8c-1.1,1.1-2.4,2.8-3,5c-1,2.2-0.9,4.7,0.2,7c0.8,1.5,1.8,2.6,2.6,3.3c1.3,1.3,3,2,4.9,2
					c0,0.1,0,0.3-0.1,0.5c-3.7,2.3-6.4,7-7.6,11.6c-0.1,0.3-0.2,0.6-0.3,0.9c0,0,0,0,0,0c-5.8,0-10.2,3.9-10.9,9.5
					c0,0.1-0.1,0.3-0.1,0.4c-0.9-0.2-1.8-0.2-2.6-0.2c-3.9,0-6.4,1.7-8.6,3.1c-0.2,0.1-0.4,0.3-0.6,0.4c-0.4,0.3-0.7,0.5-1.1,0.9
					l-1.3,1.3c-0.1,0-0.2,0-0.3,0.1c-0.4-0.7-0.8-1.3-1.1-1.8c-0.2-0.3-0.3-0.5-0.5-0.8c-0.9-5.2-4.8-8.9-9.8-8.9
					c-7.5,0-10.3,8.5-12.7,18.1c-0.2,0.7-0.4,1.3-0.5,1.9c-0.2-0.3-0.5-0.6-0.8-0.9c-0.1-0.6-0.2-1.1-0.4-1.6
					c0.2-0.2,0.3-0.3,0.5-0.4c1.3-1.3,2.1-3.1,2.1-4.9c0-0.5-0.1-1.1-0.2-1.6c1.1-0.8,1.9-1.9,2.5-3.1c1.5-2.3,2.3-5.7-0.1-10.4
					c-0.9-1.8-2.5-3.1-4.3-3.6c-0.6-0.3-1.3-0.5-2.2-0.7c0.1-0.5,0.2-1,0.2-1.6c0.1-0.4,0.2-0.9,0.4-1.3c0.7-2.5,1.9-6.5-1.4-10.6
					c-0.3-4.4-3.4-6.8-4.7-7.9c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6-0.6-1.3-1-2-1.4c-2.6-3.6-6.6-5.6-11.3-5.6c-1.9,0-3.9,0.3-5.9,1
					c-0.8-1.1-1.6-2-2.5-2.9c-0.2-1.2-0.7-2.5-1.7-3.9c-0.3-0.8-0.9-1.6-1.5-2.2c-2.5-2.5-6.3-4.1-9.9-4.1c-2.5,0-5.9,0-8.9,3.1
					c-0.1,0.1-0.2,0.2-0.3,0.4c-3.7,1-5.9,3.7-6.6,6.7c-0.7,2-1,4.8,1,7.8c-0.1,0.5-0.1,1.1-0.1,1.6c-0.9,0.3-1.7,0.6-2.6,1.1
					c-2.2-3.1-4.8-5.1-8-6c0.1-2-0.4-4.4-2.5-6.5c-2.1-2.1-4.4-3.6-9.6-4.7c-1.3-0.6-2.7-1.3-4.4-2c1.4-1.3,2.4-3.1,2.7-5.2
					c1.5-3.7,0-8.2-2.9-11.1c-1.3-1.3-3.1-2.1-4.9-2.1h-2c-1.8,0-3.8,0.6-5.6,2c-0.1-0.8-0.3-1.5-0.5-2.2c0.1-0.6,0.2-1.1,0.2-1.7
					c0-0.9-0.2-4.1-3.1-6.9c-0.9-0.9-3.4-3.1-6.9-3.1c-1.8,0-3.6,0.7-4.9,2c0,0,0,0-0.1,0c-1.5,0-3.7,0.2-5.8,1.3
					c-0.7-0.2-1.4-0.3-2.3-0.3c-4.8,0-10,4-10,10c0,1.7,0.3,3.5,1,5.1c-1.7,1-3,2.6-3.9,4.3c-0.4,0.2-0.8,0.5-1.2,0.8
					c-3.4,2.1-5.1,5.4-6.2,7.6c-0.5,1-0.7,2-0.7,3.1c0,1.7,0,3.9,1,6v0.5c-1.1,3-2,6.9-2,9.4c0,0.4,0,0.8-0.1,1.3
					c-0.2,2.2-0.5,6.5,3.3,10.1c0.1,0.8,0.4,1.5,0.6,2.1c-2.5,5.4-2.8,13.3-2.8,13.6c-0.3,0-0.7,0-1,0c-0.4,0-0.8,0-1.2,0.1
					c-5.4,0.9-10.6,2.1-14.2,6.8c-3.3,0.5-6.6,2.2-9.6,5.2c-1.1,1.1-1.8,2.5-2,4c0,0,0,0-0.1,0c-3.9,0-7.4,0.1-10.3,1h-2.5
					c0,0-0.1,0-0.1,0c-1.9-0.7-3.9-1.4-6.3-1.4c-1.7,0-3.4,0.4-4.9,1.2c-4.1,2-5.2,6-5.8,8.1c-0.1,0.4-0.3,0.9-0.3,1.1
					c-0.5,1-0.7,2-0.7,3.1c0,3.3,0,6.1,1.4,10.2c0.1,0.2,0.1,0.3,0.2,0.4c-0.1,0.6-0.2,1.3-0.4,2c-0.1,0.5-0.1,0.9-0.1,1.4
					c0,0.9-0.9,3.5-1.5,5.4c-0.6,1.3-1.9,4.4-2.4,7.6c0,0-0.1,0.1-0.1,0.1c0,0-3.5,3.7-4.7,7.6c0,0.1,0,0.1-0.1,0.2
					c-0.2,0.1-0.4,0.2-0.6,0.3c-1.8,0.2-3.2,0.7-4,1c-1.4,0.2-2.6,0.9-3.7,1.9c-0.1,0.1-0.2,0.2-0.3,0.3c-1.2,0.9-3.6,2.9-4.4,6.2
					c-1.4,2.7-1.4,6.1-0.1,8.8c-0.1,0.5-0.2,1-0.2,1.5c-3.4,3.1-4,6.4-4.7,11.1l-0.1,0.7c-1,4.2-1.1,8.8-1.1,11.4
					c-0.1,0-0.2,0.1-0.2,0.1c-5.2,0.7-7.6,4.1-8.9,7.2c-0.3,0.2-0.5,0.4-0.7,0.6c-3.3,1.9-5.2,5.9-5.2,9c0,3.2,2,6.9,4,8.9
					c0.1,0.1,0.1,0.2,0.2,0.2c0.8,1,2.6,3,5.6,4.1c1.9,3.7,6.5,6.9,8.9,8.3c-0.1,0.1-0.2,0.2-0.2,0.3c-2.1,1.7-3.5,4.4-3.5,8
					c0,5.3,4.7,7.7,7.8,9.2c0.1,0.8,0,2.4-0.6,5.3c0,0,0,0.1,0,0.1c-0.2,1.2-0.4,2.2-0.6,2.9c-1.7,1.7-4.5,5-4.5,9.4
					c0,2.4,0.6,4.5,1.9,6.2c-1.8,1.8-3.3,3.8-4.6,7.6c-0.2,0.7-0.4,1.5-0.4,2.2c0,1.6,0,3.8,0.6,5.9c-0.6,1.7-0.9,3.7-0.4,5.9
					c0.4,1.5,1.1,2.9,2.1,4c0.2,0.6,0.4,1.3,0.6,1.9c-5.9,3.4-10.3,10.8-11.7,13.4c-1.2,1.4-2.1,3.1-3,4.9c-1.2,1.2-2.7,3-4.1,4.9
					c-2.9,3.9-4.2,6.6-4.2,9.2c0,0.9,0,2.7,0.8,4.7c0.1,3.3,1.8,7.8,8.3,12.2c0.2,0.2,0.5,0.3,0.8,0.4c2.4,1.2,4.7,1.8,7,1.8
					c2.6,0,5.1-0.7,7.5-2.2c2.3,1,4.7,1.6,6.6,1.9c0,2.4,0.3,5.5,3,8.2c0.5,0.5,1.1,1,1.8,1.3c1.3,0.7,2.7,1,4.2,1
					c4.3,0,6.8-3,7.7-4.1c0,0,0,0,0,0c0.2-0.1,0.4-0.1,0.6-0.1c5.7-0.1,9.2-3.7,10.6-5c0.3-0.3,0.6-0.7,0.9-1.1
					c0.8-1.1,2.2-2.5,3.1-3.1c4.5-0.9,9.4-4.6,12.3-7.8c3.8-0.3,6.5-1.7,8.2-3.7l1.4,1.4c0.5,1.1,1.3,2.1,2.2,2.9v0.4
					c0,1.6,0.5,3,0.8,4.2c0,0.1,0,0.1-0.1,0.2c-0.8,2.1-2.2,5.5,0,9.8c1.7,3.5,5.3,5.8,9.9,6.6c0.2,0.1,0.4,0.3,0.6,0.4c0,0,0,0,0,0
					c1,0.9,2.2,1.5,3.4,1.7c-0.3,2.5-0.1,4.3,0.3,5.8v0.3c-0.7,2.3-1.4,6.3,1,10.3c-0.1,2.7-0.4,5.4-0.8,8c-0.1,0.2-0.2,0.4-0.2,0.7
					c-0.9,2.5-2,5.7-0.9,9.3c0,1.1,0.2,2.4,0.6,3.7c0.4,1.1,0.9,2,1.5,2.8c0.1,0.8,0.2,1.6,0.3,2.5c-0.3,0.9-0.5,1.8-0.5,2.7
					c0,2.8,0.9,5.1,2.3,6.8c0.1,0.3,0.1,0.5,0.2,0.8c0.8,2.3,2.4,4,4.5,4.8c1.5,0.9,3.4,1.3,5.4,1.3c1.7,0,3.7-0.3,6.3-1
					c0.4-0.1,0.8-0.2,1.2-0.4c0.5,0.1,1.2,0.2,2.3,0.4c0.6,0.2,1.2,0.4,1.7,0.6c1.8,4,5.6,5.8,7.4,6.6c0.2,0.1,0.4,0.2,0.6,0.3
					c0.3,0.2,0.6,0.3,1,0.4c1,0.8,2,1.3,2.5,1.6c2.8,4.7,6.2,5.8,8.7,5.8c0.7,0,1.3-0.1,1.9-0.2c1.8,0.9,4,1.9,6.9,2.9
					c0.2,0.1,0.4,0.1,0.5,0.2c0.5,2,1.5,3.7,3.1,5.1c0.7,2.5,2.5,4.6,5,5.8c1.9,0.9,3.9,1.3,6.2,1.7c0.6,0.1,1.3,0.2,2,0.4
					c0.5,0.1,0.9,0.1,1.4,0.1c0,0,0.1,0,0.2,0c1.3,1.2,3.3,2.5,6.3,2.9c1.4,1.8,3.1,3.2,5.4,4.4c1,0.5,2,0.7,3.1,0.7h0.2
					c1,0.3,1.8,0.4,2.5,0.4c1.2,0,2.2-0.3,2.9-0.6c0.8,0.2,1.7,0.3,2.5,0.3c0.7,0,1.4-0.1,2.1-0.2c1.1,0.7,2.4,1.1,3.7,1.1h3
					c1.3,0,2.4-0.3,3.5-0.8c0.7,0.1,1.4,0.2,2.2,0.2c0.2,0,0.4,0,0.5,0c1.4,1.1,3,1.9,4.6,2.3c1.7,0.8,3.9,1.7,6.4,1.7
					c1.7,0,3.4-0.4,4.9-1.2c0.7-0.3,1.3-0.8,1.8-1.3c0.6-0.6,1.1-1.3,1.6-2c0.7-0.2,1.4-0.4,2.1-0.7c2.7,0.7,5.4,0.7,7.4,0.7h5
					c1.5,0,4.9,0,7.9-3.1c3.1-3.1,3.1-6.5,3.1-7.9c0-0.5-0.1-1.1-0.2-1.6c0.2-1.1,0.7-2.8,1-4.2c3.4-0.6,5.9-3,7.5-7.2
					c2-3.5,3.1-8.9-1.5-15.9c0.7-1.1,1.4-2.5,1.8-3.8c2.1-1.5,4.4-4.3,4.4-9.3c0-1.9-0.4-3.4-1-4.6c0.4-0.8,0.6-1.6,0.8-2.3
					c0.4-0.9,0.7-1.7,0.9-2.4c0.2-0.6,0.3-1,0.6-1.6c0.1-0.2,0.2-0.4,0.2-0.5c2.8-2.7,4-6.8,4.4-9.7c1-1.2,1.6-2.6,1.9-4
					c0.1-0.2,0.3-0.5,0.5-0.7c2.9,0.2,7.2,0.6,13.2,1.7l3.8,0.9c0.6,0.1,1.1,0.2,1.7,0.2c5.3,0,8.1-3.2,8.8-6.9l0.5-0.9
					c0,0,0-0.1,0.1-0.1c2.1,0.4,3.9,0.7,5.6,0.7c2.8,0,5.2-0.6,7.7-2.1c0.8-0.2,1.4-0.5,1.8-0.7c0.1,0,0.2,0,0.2-0.1
					c2.7-0.7,4.9-2,6.5-3.8c0.3,0,0.5,0,0.8,0c0.8,0,2.1,0,3.6-0.5c2.2-0.7,3.8-2.3,4.7-4.2c1.7-2.6,2.7-6.6,0.7-12.6v-8
					c0.1-0.4,0.2-0.9,0.3-1.2c0.5-2.3,0.9-4.3,0.6-6.5c-0.1-0.9-0.5-2.5-1.6-4c0.1-0.4,0.2-0.8,0.4-1.2c1.8-0.9,3.3-2.1,4.3-3.8l2-2
					c1.5-1.5,5.8-6.3,6.8-12.5c0.1-0.2,0.2-0.3,0.3-0.5c1.1-1.1,2.1-2.6,2.7-4.6c1.6,1.5,3.5,2.8,5.8,3.4c1,0.5,2.1,0.7,3.3,0.7
					c0.4,0,0.8,0,1.2-0.1c1.6,1,3.6,1.7,6,1.7c0.6,0,2-0.1,3.7-0.8c2.2,0,4.1-0.7,5.7-1.9c1.8,2.5,4.4,3.7,7.6,3.7
					c1.9,0,3.6-0.7,4.9-2.1c0.1-0.1,0.2-0.2,0.3-0.3c1-0.3,1.9-0.9,2.7-1.7c2.1-2.1,2.7-4.3,3-5.7c0.4-0.9,1.4-2.6,2-3.3
					c0.3-0.3,0.6-0.7,0.9-1.1c2.5-3.7,3.3-6.5,2.6-10.1c0.2-0.4,0.3-0.9,0.4-1.3c0.4-0.9,1.2-2.4,1.2-4.5c0-2.8-1.3-4.9-2.3-6.2
					c-0.1-0.3-0.2-0.7-0.4-1c-0.2-0.3-0.3-0.9-0.5-1.6c-0.3-1.2-0.7-2.7-1.5-4.4c0-0.1-0.1-0.2-0.1-0.3c0.9-3.5,0.9-6.6,0.9-8.6
					c0-0.4,0-0.8,0-1.1c1.6-1.6,2.7-3.2,3.5-5c1.1-0.1,2-0.3,2.9-0.5c0.3,0.2,0.6,0.5,0.7,0.6c1.3,1.3,3.1,2,4.9,2
					c4.2,0,7.7-2.4,9.2-6.3c0.6-1.7,0.8-3.3,0.8-4.6c0.3-0.9,0.5-2,0.6-3.3c1-0.3,2.1-0.8,3.2-1.3c2.4,2.1,5.5,3.4,8.2,3.4
					c0.8,0,2.1,0,3.6-0.5c2.2-0.7,3.8-2.3,4.7-4.2c0.5-0.7,0.8-1.5,1.1-2.2c0.5-0.3,1.1-0.7,1.5-1.1c0.1-0.1,0.4-0.3,0.5-0.4
					c0.4-0.3,0.8-0.6,1.3-1.1c1.3,0.5,2.5,0.6,3.4,0.6c1.9,0,3.3-0.6,4-0.9c2.1-1,6.3-4.1,5.3-11.1c1.5-2.7,1.5-5.7,1.5-7.2
					c0-1.9-0.7-3.6-2.1-4.9c-0.4-0.4-0.8-0.8-1.3-1.3c0.4-0.5,0.7-1,1-1.6c3-3,4.3-4.6,4.3-8.2c0-0.8-0.1-1.6-0.3-2.4
					c2.6-1.6,4.3-4.5,4.3-7.6c0-0.7,0-1.4-0.1-2.2c3-0.3,7.1-1,10.6-2.7c1.1,0.7,2.7,1.3,4.8,1.3c1.6,0,3.2-0.4,4.8-1.2
					c0.7-0.3,1.3-0.8,1.8-1.3c1.3-1.3,2-2.6,2.3-3.3c0,0,0,0,0,0c0.3-0.4,0.6-0.8,0.9-1.2c0.2-0.1,0.4-0.3,0.7-0.5l3-2
					c0.4-0.3,0.7-0.5,1.1-0.9c3.1-3.1,3.1-6.5,3.1-7.9c0-1.4-0.4-2.7-1.2-3.9l-1.3-1.9c-0.1-0.2-0.1-0.4-0.2-0.6
					c0.4-3.9-1.1-7.9-2.7-11.8c-2-7.4-6.6-8.5-11.1-9.4c0.3-1,0.5-2.1,0.5-3.4c0-2-0.4-3.6-1.1-4.9c1.5-1,3.2-2.5,5.4-4.6
					c3.9-1.1,7.7-3.8,9.9-6.7c1.3-0.2,2.8-0.6,4.5-1.4c7.3-2.7,7.3-8.4,7.3-11.5c0-4.3-0.9-7.7-2.7-10.1c-0.1-0.6-0.2-1.1-0.3-1.4
					c0-0.1,0-0.1,0-0.2c-0.1-1.6-0.9-3.2-2-4.3c-0.5-0.5-1-1-1.7-1.6c-0.6-2.9-2.3-5.4-5.1-7.2c-0.1-0.1-0.3-0.2-0.4-0.3
					c-0.1-0.4-0.3-0.7-0.4-1.1l-1-2c-0.8-1.7-2.3-2.9-4-3.5c-1.4-0.9-3.2-1.4-5.2-1.4c-1.4,0-2.7,0.4-3.9,1.2l-3,2
					c-0.4,0.3-0.7,0.5-1.1,0.9c0,0-0.1,0.1-0.1,0.1c-1.7,0.3-3.1,0.9-4.1,1.5c-0.1-0.7-0.3-1.3-0.5-1.8c4.5-0.6,7.6-4.1,8.4-7.9
					c1.4-3.5,1.3-6.6-0.5-10.1c-0.3-0.7-0.8-1.3-1.3-1.8c-0.6-0.6-1.4-1.4-2.4-2.1c-0.1-0.3-0.3-0.5-0.4-0.8c-1.3-2.1-3.4-3.5-6-4
					c-1-0.4-2-0.6-3.1-0.6c-3.4,0-6.4,2-8,4.9c-1,0.3-2.1,0.9-3.2,1.8c-1.5,1-2.7,2.3-3.5,3.8c-0.1,0-0.2,0-0.2,0
					c-2.6-0.4-4.7-0.8-6.7-0.8c-3.2,0-5.9,0.8-9.4,1.9c-5,1.3-7.9,2.4-9.9,3.8c-4.9,0.5-8.1,3-10.9,5.7c-5.1,4.3-5.2,4.3-8.4,5.9
					c-0.1,0.1-0.2,0.1-0.3,0.2c-0.5-0.2-1-0.4-1.6-0.5c-0.7-0.6-1.4-1.2-2.1-2.2c-0.3-0.4-0.5-0.7-0.9-1.1l-0.9-0.9
					c-1.2-2.3-3.6-3.9-6.3-4.1l-5.8-0.8c-1.1-7.8-9.3-9.3-14.3-10c-3.6-1.2-6.3-1.7-8.9-1.7c-2.5,0-4.8,0.5-7.2,1.5
					c-1.4,0-2.9-0.2-4.3-0.6c-0.4-0.1-0.9-0.2-1.3-0.3l-8-1c-0.3,0-0.6-0.1-0.9-0.1h-1h-3c-0.4,0-0.9,0-1.7,0
					c0.1-0.3,0.2-0.6,0.3-0.8c0.2-0.7,0.4-1.5,0.4-2.2c0-2-0.7-3.6-1.2-4.5c-0.2-1-0.7-2-1.3-2.8c0,0,0-0.1-0.1-0.2
					c-0.6-1.2-1.6-3.3-3.9-5.8c-3.4-5.6-4.9-7-10.9-8.5c-0.2,0-0.4-0.1-0.5-0.1c-0.9-0.1-1.7-0.2-2.4-0.3c-0.6-1.2-1.5-2.6-2.7-3.8
					c3.2,0,7.1-0.3,9.9-3c2.6-1.2,4.4-2.9,5.3-5.1c1.6-0.9,2.8-2.1,3.7-2.9c0.5-0.5,0.9-1,1.3-1.6c1.5,0.6,3,1.1,4.5,1.4l5.1,2.6
					c0.3,0.2,0.7,0.4,1.1,0.6c1.5,0.9,3.7,2.2,7.1,2.2h1c7.1,0,11.9-4,14.9-7.1c0.3-0.3,0.6-0.7,0.9-1.1l1.7-2.6
					c3.1-3.7,2.9-8.6-0.5-12.2c0-0.1-0.1-0.1-0.1-0.2c-0.2-1.5-0.9-2.8-2-3.9c-3.1-3.1-6.5-3.1-7.9-3.1c-1.4,0-3.5,0-5.7,0.4
					c-2.4-2.1-4.9-2.5-6.7-2.5c-2,0-3.7,0.5-5.6,1.3c-2-1.6-4.1-2-5.5-2c-1.3,0-3,0.3-4.7,1.4c-0.3,0.1-0.6,0.3-0.9,0.5
					c-0.9-0.5-1.8-1-2.7-1.4c-0.5-0.2-0.9-0.4-1.4-0.5c-0.8-0.2-1.5-0.4-2.2-0.6c-1.9-0.5-3.7-1-5.7-1c-0.8,0-1.5,0.1-2.2,0.2
					c-2.4-2.1-5.4-4-7.5-5.1C365.2,48.5,364.1,48.2,363,48.2L363,48.2z M199.3,95.4c0.1-0.1,0.1-0.1,0.2-0.2c0,0.1,0,0.1,0.1,0.2
					C199.5,95.3,199.4,95.3,199.3,95.4L199.3,95.4z M360,129.2L360,129.2L360,129.2L360,129.2z M329,93.2h-2c-1.9,0-3.6,0.7-4.9,2.1
					c-3.1,3.1-3.1,6.5-3.1,8.9c0,0.8,0.1,1.5,0.4,2.2c0.4,1.1,1.7,4.6,4.8,6.9c0.6,0.8,1.2,1.5,1.9,2.1c2.6,3.9,5.7,4.8,8,4.8
					c1.5,0,4.9,0,7.9-3.1c0.3-0.3,0.6-0.7,0.9-1.1c4.1-6.1,1-11.4-0.2-13.4c-0.1-0.3-0.3-0.5-0.4-0.7c-0.3-0.7-0.8-1.3-1.3-1.8
					C337.1,96.4,333.5,93.2,329,93.2L329,93.2z M315.5,115c-1.8,0-3.5,0.4-5,1.3c-1.3,0.3-2.5,0.9-3.5,1.9c-3.1,3.1-3.1,7.3-3.1,8.9
					c0,1.1,0.3,2.2,0.7,3.1c2.8,5.7,6.6,6.9,9.3,6.9h1c4.9,0,7.6-2.7,8.9-4.1c1.3-1.3,2.1-3.1,2.1-4.9v-2v-3c0-2.7-1.5-5.1-3.9-6.3
					C320.5,116.1,318.3,115,315.5,115L315.5,115z"/>
			</g>
			<g id="st1">
				<path id="st2" d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,48.7c-2.1,0-6.8,0-9.7,3.9c-1.6-1-3.6-1.6-5.9-1.6c-1.8,0-3.7,0.4-5.8,1.2
					c-0.2,0.1-0.3,0.1-0.5,0.2c-3.7,1.8-6.4,4.5-8,7.6c-0.6-0.2-1.4-0.3-2.1-0.3h-1c-0.7,0-1.4,0.1-2.1,0.3
					c-1.9,0.6-4.5,2.2-6.2,6.4c-1.1,2.7-2.3,8-1.2,12.1c0.5,4.9,4.3,8.2,9.4,8.2h1c0.8,0,1.5-0.1,2.2-0.2c0.6,0.9,1.3,1.8,2,2.8
					l0.6,0.8c0,0.1,0.1,0.1,0.1,0.2c3.2,4,5.6,7,9.1,8c1.5,1.2,3.4,2.4,6,2.4c0.5,0,1,0,1.5-0.1c0,0.2,0,0.4,0,0.6
					c0,1.5,0.1,3.2,1.1,5.7c0.3,2,1.1,3.6,2.3,4.9c-1.2,1.1-2.6,2.8-3.2,5.1c-0.9,2.1-0.9,4.5,0.2,6.6c0.7,1.4,1.7,2.5,2.5,3.2
					c1.2,1.2,2.9,1.9,4.6,1.9h0.5c0,0,0,0,0,0.1l0,0.1c0,0.3-0.1,0.6-0.2,1.1c-4.2,2.5-6.6,7.8-7.6,11.5c-0.1,0.4-0.3,0.9-0.4,1.3
					c-0.1,0-0.3,0-0.4,0c-5.6,0-9.8,3.7-10.4,9.1c-0.1,0.3-0.2,0.6-0.2,0.9c-1.1-0.2-2.1-0.3-3-0.3c-3.8,0-6.2,1.6-8.3,3
					c-0.2,0.1-0.4,0.3-0.7,0.4c-0.4,0.2-0.7,0.5-1,0.8L327,158c-0.2,0.1-0.5,0.1-0.8,0.2c-0.5-0.8-0.9-1.5-1.3-2.1
					c-0.2-0.3-0.4-0.6-0.6-0.9c-0.8-5-4.5-8.5-9.3-8.5c-7.2,0-9.9,8.3-12.2,17.7c-0.4,1.1-0.6,2.2-0.8,3.1l-0.1-0.2
					c-0.3-0.7-0.8-1.3-1.4-1.8c-0.1-0.7-0.3-1.4-0.6-2c0.3-0.2,0.5-0.5,0.7-0.7c1.2-1.2,1.9-2.9,1.9-4.6c0-0.6-0.1-1.2-0.2-1.9
					c1.2-0.8,2-1.8,2.6-3.1c1.4-2.2,2.2-5.5-0.1-9.9c-0.8-1.7-2.3-2.9-4-3.3c-0.6-0.3-1.5-0.6-2.6-0.7c0.2-0.7,0.3-1.4,0.3-2.1
					c0.1-0.5,0.3-0.9,0.4-1.3c0.7-2.4,1.9-6.3-1.4-10.3c-0.3-4.2-3.2-6.7-4.6-7.7c-0.1-0.1-0.3-0.2-0.4-0.3c-0.6-0.6-1.2-1-2-1.3
					c0,0,0,0,0,0c-2.5-3.5-6.4-5.5-10.9-5.5c-2,0-4,0.4-6.1,1.1c-0.8-1.2-1.8-2.3-2.8-3.2c-0.2-1.2-0.7-2.5-1.7-3.9
					c-0.3-0.8-0.8-1.5-1.4-2.1c-2.4-2.4-6.1-3.9-9.6-3.9c-2.4,0-5.7,0-8.6,2.9c-0.1,0.1-0.3,0.3-0.4,0.5c-3.6,0.9-5.7,3.6-6.4,6.4
					c-0.7,1.9-0.9,4.7,1.1,7.6c-0.1,0.7-0.1,1.4-0.1,2.1c-1.1,0.3-2.2,0.8-3.2,1.4c-2.3-3.4-5-5.4-8.4-6.3c0.1-1.9-0.2-4.4-2.3-6.6
					c-2.1-2.1-4.3-3.5-9.4-4.6c-1.5-0.7-3.1-1.5-5.2-2.3c1.7-1.3,2.8-3.2,3.2-5.5c0.7-1.8,0.8-3.9,0.1-6c-0.6-1.7-1.6-3.3-2.8-4.5
					c-1.2-1.2-2.9-1.9-4.6-1.9h-2c-1.9,0-4.1,0.8-6,2.4c0-1.1-0.3-2.2-0.6-3.2c0.1-0.6,0.2-1.1,0.2-1.7c0-0.9-0.2-3.9-2.9-6.6
					c-0.9-0.9-3.3-2.9-6.6-2.9c-1.7,0-3.4,0.7-4.6,1.9c-0.1,0-0.1,0.1-0.2,0.1c-1.5,0-3.8,0.2-5.9,1.3c-0.7-0.2-1.5-0.3-2.3-0.3
					c-4.6,0-9.5,3.8-9.5,9.5c0,1.8,0.4,3.7,1.1,5.3c0,0,0,0-0.1,0c-1.8,1-3.2,2.6-4.1,4.4c-0.4,0.2-0.8,0.5-1.3,0.9
					c-3.3,2-4.9,5.1-6,7.4c-0.5,0.9-0.7,1.9-0.7,2.9c0,1.7,0,3.8,1,5.9v0.7c-1.1,2.9-2,6.8-2,9.3c0,0.4,0,0.8-0.1,1.3
					c-0.2,2.1-0.5,6.3,3.2,9.7c0,0,0,0,0,0.1c0.2,0.9,0.4,1.6,0.7,2.3c-2.5,5.3-2.9,13.5-2.9,13.6c0,0.2,0,0.3,0,0.5
					c-0.4,0-0.9,0-1.5,0c-0.4,0-0.7,0-1.1,0.1c-5.6,0.9-10.5,2.1-14,6.7c-3.3,0.4-6.5,2.1-9.5,5.1c-1.1,1.1-1.8,2.6-1.9,4.1
					c-0.2,0-0.3,0-0.5,0c-3.9,0-7.3,0.1-10.2,1h-2.7c-0.1,0-0.1-0.1-0.2-0.1c-1.9-0.7-3.8-1.3-6.1-1.3c-1.7,0-3.2,0.4-4.7,1.1
					c-3.9,1.9-5,5.8-5.6,7.8c-0.1,0.4-0.3,0.9-0.3,1.1c-0.5,0.9-0.7,1.9-0.7,2.9c0,3.2,0,6.1,1.3,10.1c0.1,0.2,0.1,0.4,0.2,0.5
					c-0.1,0.6-0.3,1.4-0.4,2.1c-0.1,0.4-0.1,0.8-0.1,1.3c0,0.9-0.9,3.7-1.5,5.6c-0.6,1.3-1.9,4.4-2.3,7.6c-0.1,0.1-0.1,0.1-0.2,0.2
					c0,0-3.5,3.6-4.6,7.4c-0.1,0.1-0.1,0.2-0.2,0.3c-0.3,0.1-0.6,0.3-0.8,0.4c-1.8,0.2-3.2,0.7-4,1c-1.3,0.2-2.5,0.8-3.5,1.8
					c-0.1,0.1-0.2,0.2-0.4,0.3c-1.1,0.9-3.4,2.8-4.3,5.9c-1.4,2.7-1.4,6-0.1,8.6c-0.2,0.6-0.2,1.2-0.2,1.7v0c-3.4,3-4,6.1-4.7,10.9
					l-0.1,0.8c-1,4.3-1.1,9.1-1.1,11.7c-0.2,0.1-0.4,0.2-0.6,0.3c-5.1,0.7-7.4,4-8.6,7c-0.3,0.2-0.6,0.5-0.9,0.7
					c-3.2,1.7-5,5.7-5,8.6c0,3.1,1.9,6.6,3.9,8.6c0.1,0.1,0.1,0.2,0.2,0.3c0.9,1,2.6,3.1,5.6,4.1c0,0,0,0,0,0
					c1.7,3.4,6.3,6.8,8.9,8.4c0.1,0.1,0.2,0.2,0.3,0.3c-0.2,0.2-0.3,0.5-0.5,0.8c-2,1.6-3.4,4.2-3.4,7.7c0,5,4.4,7.2,7.6,8.8
					c0.1,0,0.1,0.1,0.2,0.1c0.1,0.7,0.1,2.4-0.6,5.7c0,0,0,0.1,0,0.1c-0.3,1.3-0.5,2.4-0.6,3.1c-1.6,1.6-4.5,4.9-4.5,9.2
					c0,2.7,0.9,4.7,2.1,6.2c-1.8,1.9-3.5,3.8-4.8,7.7c-0.2,0.7-0.3,1.4-0.3,2.1c0,1.7,0,3.9,0.7,5.9c-0.6,1.7-0.9,3.7-0.4,5.7
					c0.3,1.5,1,2.8,2,3.9c0.2,0.8,0.4,1.5,0.8,2.1c-0.1,0.1-0.2,0.2-0.3,0.3c-5.9,3.3-10.3,10.8-11.7,13.3c-1.1,1.4-2.1,3.1-3,4.9
					c-2.4,2.5-8.1,9-8.1,13.8c0,0.9,0,2.7,0.8,4.6c0.1,3.2,1.7,7.6,8.1,11.8c0.2,0.1,0.5,0.3,0.7,0.4c2.3,1.1,4.6,1.7,6.8,1.7
					c2.6,0,5.1-0.7,7.4-2.3c2.5,1.2,5.1,1.8,7.2,2c0,2.4,0.1,5.5,2.9,8.3c0.5,0.5,1.1,0.9,1.7,1.2c1.3,0.6,2.6,1,4,1
					c4.1,0,6.4-2.8,7.4-3.9c0-0.1,0.1-0.1,0.1-0.2c0.3-0.1,0.6-0.2,0.8-0.2c5.5-0.1,9-3.6,10.3-4.9c0.3-0.3,0.6-0.6,0.8-1
					c0.8-1.2,2.4-2.7,3.3-3.3c4.5-0.9,9.5-4.7,12.2-7.9c4-0.2,6.7-1.8,8.4-3.9c0,0,0,0,0,0l1.9,1.9c0.5,1.1,1.3,2.2,2.2,3v0.6
					c0,1.6,0.5,2.9,0.8,4.1c0,0,0,0.1,0,0.1c0,0.1-0.1,0.2-0.1,0.3c-0.8,2-2.1,5.3-0.1,9.4c1.7,3.4,5.2,5.6,9.6,6.3
					c0.2,0.2,0.4,0.3,0.7,0.5c0,0,0.1,0,0.1,0c1,1,2.3,1.5,3.7,1.7c-0.4,2.7-0.2,4.5,0.2,6.1v0.4c-0.7,2.3-1.4,6.2,1,10.2
					c-0.1,2.7-0.4,5.6-0.8,8.3c-0.1,0.2-0.2,0.5-0.2,0.7c-0.8,2.4-2,5.6-0.9,9.1c0,1.1,0.2,2.3,0.6,3.6c0.4,1.1,0.9,2,1.5,2.8
					c0.1,0.9,0.2,1.8,0.4,2.7c-0.3,0.8-0.5,1.7-0.5,2.7c0,2.8,0.9,5,2.2,6.5c0.1,0.3,0.1,0.6,0.2,0.9c0.7,2.1,2.2,3.7,4.2,4.5
					c1.5,0.9,3.2,1.3,5.2,1.3c1.7,0,3.6-0.3,6.2-0.9c0.4-0.1,0.9-0.3,1.3-0.5c0.6,0.1,1.4,0.2,2.5,0.5c0.7,0.2,1.3,0.5,1.9,0.6
					c1.6,3.9,5.5,5.8,7.2,6.6c0.2,0.1,0.4,0.2,0.6,0.3c0.3,0.2,0.6,0.3,1,0.4c1,0.8,1.9,1.3,2.5,1.6c0,0,0.1,0,0.1,0c0,0,0,0,0,0
					c2.6,4.6,5.9,5.6,8.3,5.6c0.7,0,1.4-0.1,2-0.2c1.9,0.9,4,1.9,7,2.9c0.3,0.1,0.5,0.2,0.8,0.2c0.4,2,1.5,3.8,3.1,5.3
					c0.7,2.5,2.3,4.5,4.8,5.7c1.8,0.9,3.8,1.3,6.1,1.7c0.6,0.1,1.3,0.2,2,0.4c0.4,0.1,0.8,0.1,1.3,0.1c0.1,0,0.2,0,0.3,0
					c1.3,1.2,3.3,2.6,6.4,2.9c1.4,1.9,3.1,3.2,5.4,4.4c0.9,0.5,1.9,0.7,2.9,0.7h0.3c0.9,0.3,1.8,0.4,2.4,0.4c1.2,0,2.2-0.3,2.8-0.6
					c0,0,0,0,0,0c0.9,0.2,1.7,0.3,2.6,0.3c0.8,0,1.5-0.1,2.2-0.3c1.1,0.7,2.3,1.1,3.6,1.1h3c1.2,0,2.4-0.3,3.4-0.8
					c0.7,0.1,1.5,0.2,2.3,0.2c0.2,0,0.5,0,0.7,0c1.4,1.1,3,2,4.6,2.3c1.7,0.8,3.8,1.6,6.2,1.6c1.6,0,3.2-0.4,4.7-1.1
					c0.6-0.3,1.2-0.7,1.7-1.2c0.6-0.6,1.2-1.3,1.6-2.1c0.8-0.2,1.6-0.4,2.3-0.8c2.7,0.7,5.4,0.7,7.4,0.7h5c1.4,0,4.7,0,7.6-2.9
					c2.9-2.9,2.9-6.2,2.9-7.6c0-0.5-0.1-1.1-0.2-1.6c0.2-1.2,0.8-3.2,1.2-4.6c3.4-0.5,5.9-2.9,7.4-6.9c2-3.4,3-8.7-1.5-15.4
					c0-0.1-0.1-0.1-0.2-0.2c0.8-1.2,1.5-2.7,1.9-4.1c2.2-1.5,4.3-4.2,4.3-9.1c0-1.9-0.5-3.4-1.1-4.6c0.4-0.8,0.7-1.7,0.9-2.5
					c0.4-0.9,0.7-1.7,0.9-2.4c0.2-0.6,0.4-1.1,0.6-1.6c0.1-0.2,0.2-0.4,0.3-0.6c2.9-2.6,4-6.8,4.3-9.7c1-1.1,1.6-2.5,1.9-4
					c0.2-0.3,0.4-0.7,0.7-1c2.9,0.2,7.4,0.6,13.6,1.8l3.8,0.9c0.5,0.1,1,0.2,1.6,0.2c5.3,0,7.7-3.3,8.3-6.6l0.5-1
					c0.1-0.2,0.1-0.3,0.2-0.5c2.2,0.4,4.1,0.7,5.9,0.7c2.7,0,5.1-0.6,7.5-2c0.8-0.2,1.4-0.5,1.8-0.8c0.1,0,0.2,0,0.3-0.1
					c2.7-0.7,4.9-2,6.4-3.9c0.3,0,0.7,0.1,1,0.1c0.8,0,2.1,0,3.4-0.5c2-0.7,3.6-2.1,4.4-3.9c2.2-3.2,2.4-7.3,0.7-12.3v-8.2
					c0.1-0.5,0.2-0.9,0.3-1.3c0.5-2.3,0.9-4.2,0.6-6.3c-0.2-1.1-0.6-2.6-1.7-4c0.2-0.6,0.3-1.1,0.5-1.6c1.8-0.8,3.4-2.1,4.3-3.7
					l2.1-2.1c1.4-1.4,5.8-6.2,6.7-12.3c0.1-0.2,0.2-0.4,0.3-0.6c1.2-1.2,2.3-3,2.9-5.3c1.6,1.8,3.7,3.3,6.2,3.9c1,0.4,2,0.7,3.1,0.7
					c0.4,0,0.9,0,1.3-0.1c1.5,1,3.5,1.7,5.8,1.7c0.5,0,1.9-0.1,3.5-0.8c2.3,0,4.3-0.7,5.9-2.2c1.8,2.6,4.3,4,7.5,4
					c1.7,0,3.4-0.7,4.6-1.9c0.1-0.1,0.3-0.3,0.4-0.4c1-0.3,1.8-0.9,2.6-1.6c2-2,2.6-4.2,2.8-5.5c0.4-0.9,1.4-2.7,2.2-3.5
					c0.3-0.3,0.6-0.6,0.8-1c2.4-3.6,3.2-6.3,2.5-9.9c0.2-0.5,0.4-0.9,0.5-1.4c0.4-0.9,1.1-2.3,1.1-4.3c0-2.7-1.3-4.8-2.3-5.9
					c-0.1-0.3-0.2-0.7-0.4-1c-0.2-0.4-0.3-1-0.5-1.7c-0.3-1.2-0.7-2.7-1.5-4.3c-0.1-0.1-0.2-0.3-0.2-0.4c0.9-3.5,0.9-6.7,0.9-8.7
					c0-0.4,0-0.9,0-1.3c0,0,0.1-0.1,0.1-0.1c1.6-1.6,2.8-3.3,3.6-5.1c1.3-0.1,2.3-0.3,3.3-0.6c0,0,0.1,0.1,0.1,0.1
					c0.3,0.2,0.7,0.5,0.8,0.7c1.2,1.2,2.8,1.9,4.6,1.9c4.1,0,7.3-2.2,8.7-6c0.6-1.6,0.7-3.2,0.8-4.5c0.3-0.9,0.6-2.2,0.7-3.6
					c1.2-0.4,2.5-0.9,3.7-1.5c2.4,2.2,5.4,3.6,8.1,3.6c0.8,0,2.1,0,3.4-0.5c2-0.7,3.6-2.1,4.4-4c0.5-0.8,0.9-1.5,1.1-2.3
					c0.6-0.3,1.1-0.7,1.6-1.1c0.1-0.1,0.4-0.3,0.6-0.4c0.4-0.3,1-0.7,1.5-1.2c1.4,0.6,2.6,0.7,3.5,0.7c1.8,0,3.1-0.5,3.7-0.8
					c2.5-1.2,6.1-4.2,5.1-10.7c1.5-2.6,1.5-5.5,1.5-7.1c0-1.7-0.7-3.4-1.9-4.6c-0.5-0.5-1-1-1.6-1.5c0.5-0.6,0.9-1.2,1.3-1.9
					c2.9-2.9,4.2-4.5,4.2-7.9c0-0.9-0.1-1.7-0.4-2.6c2.6-1.5,4.4-4.3,4.4-7.4c0-0.9,0-1.8-0.1-2.7c0,0,0,0,0,0c3-0.3,7.5-1,11.2-2.8
					c1,0.7,2.6,1.4,4.8,1.4c1.5,0,3.1-0.4,4.6-1.1c0.6-0.3,1.2-0.7,1.7-1.2c1.2-1.2,1.9-2.5,2.2-3.2c0,0,0-0.1,0-0.1
					c0.4-0.4,0.7-0.8,0.9-1.3c0.2-0.1,0.5-0.4,0.9-0.6l3-2c0.4-0.2,0.7-0.5,1-0.8c2.9-2.9,2.9-6.2,2.9-7.6c0-1.3-0.4-2.5-1.1-3.6
					l-1.3-2c-0.1-0.3-0.2-0.5-0.3-0.8c0.5-3.8-1.1-7.8-2.6-11.7c-2-7.4-6.5-8.3-11.2-9.2l-0.1,0c0.4-1.1,0.6-2.4,0.6-3.7
					c0-2.1-0.5-3.8-1.2-5.1c1.5-1,3.4-2.6,5.8-4.8c4-1,7.8-3.8,9.8-6.7c1.3-0.2,2.8-0.5,4.6-1.4c7-2.5,7-7.8,7-11
					c0-4.3-0.9-7.6-2.7-9.9c-0.1-0.6-0.2-1.1-0.3-1.5c0-0.1,0-0.1,0-0.2c-0.1-1.5-0.8-3-1.9-4.1c-0.5-0.5-1.1-1.1-1.8-1.7
					c-0.5-2.8-2.2-5.4-4.9-7c-0.2-0.2-0.4-0.3-0.6-0.5c-0.1-0.4-0.3-0.8-0.4-1.1l-1-2c-0.8-1.6-2.2-2.7-3.8-3.3
					c-1.3-0.8-3-1.3-5-1.3c-1.3,0-2.5,0.4-3.6,1.1l-3,2c-0.4,0.2-0.7,0.5-1,0.8c-0.1,0.1-0.2,0.2-0.2,0.2c-2.1,0.3-3.6,1.2-4.8,1.8
					c-0.2-1.2-0.5-2.2-0.8-3c4.6-0.3,7.8-3.8,8.7-7.6c1.4-3.4,1.3-6.3-0.5-9.8c-0.3-0.6-0.7-1.2-1.2-1.7c-0.6-0.6-1.4-1.4-2.4-2.1
					c-0.1-0.3-0.3-0.6-0.4-0.8c-1.2-2-3.3-3.3-5.7-3.8c-0.9-0.4-1.9-0.6-3-0.6c-3.3,0-6.2,2-7.6,4.8c-1,0.3-2.2,0.8-3.2,1.7
					c-1.6,1-2.8,2.4-3.6,4c-0.2,0-0.4-0.1-0.6-0.1c-2.6-0.4-4.6-0.7-6.7-0.7c-3.2,0-5.8,0.8-9.2,1.9c-5,1.3-7.9,2.4-9.8,3.8
					c-4.8,0.5-8,2.9-10.7,5.6c-5.2,4.3-5.2,4.4-8.5,6c-0.2,0.1-0.3,0.2-0.5,0.3c-0.6-0.3-1.2-0.5-1.9-0.6c-0.8-0.6-1.5-1.2-2.3-2.4
					c-0.2-0.4-0.5-0.7-0.8-1l-1-1c-1.2-2.2-3.4-3.7-6-3.9l-6.2-0.9c-0.4-3.3-2.2-6-5.2-7.6c-2.2-1.2-5.1-1.9-8.7-2.4
					c-3.6-1.2-6.2-1.6-8.7-1.6c-2.4,0-4.7,0.5-7.1,1.5c-1.4,0-3.1-0.2-4.6-0.7c-0.4-0.1-0.8-0.2-1.2-0.3l-8-1
					c-0.3,0-0.5-0.1-0.8-0.1h-1h-3c-0.5,0-1.4,0-2.5,0.1c0.3-0.5,0.5-1.1,0.7-1.5c0.2-0.7,0.3-1.4,0.3-2.1c0-1.9-0.7-3.4-1.1-4.3
					c-0.2-1-0.6-1.9-1.3-2.7c0-0.1-0.1-0.1-0.1-0.2c-0.6-1.3-1.5-3.3-3.8-5.7c-3.4-5.6-4.7-6.9-10.6-8.3c-0.2,0-0.3-0.1-0.5-0.1
					c-0.9-0.2-1.8-0.3-2.7-0.3c-0.8-1.4-1.9-3.4-3.7-4.8c0.1,0,0.2,0,0.3,0c3.2,0,7.7,0,10.6-2.9c0,0,0,0,0,0c2.6-1.2,4.4-2.8,5.2-5
					c1.6-0.8,2.9-2.1,3.7-2.9c0.5-0.5,1.1-1.2,1.5-1.9c1.4,0.6,3,1.1,4.8,1.5l5.2,2.6c0.4,0.2,0.7,0.4,1.1,0.6
					c1.4,0.8,3.6,2.1,6.8,2.1h1c6.8,0,11.4-3.7,14.6-6.9c0.3-0.3,0.6-0.6,0.8-1l1.7-2.6c3-3.5,2.8-8.2-0.5-11.6
					c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-1.4-0.8-2.7-1.8-3.7c-2.9-2.9-6.2-2.9-7.6-2.9c-1.4,0-3.6,0-5.9,0.5c-2.3-2.1-4.8-2.6-6.6-2.6
					c-2,0-3.8,0.6-5.6,1.4c-2-1.7-4-2.1-5.4-2.1c-1.2,0-2.8,0.3-4.5,1.4c-0.4,0.2-0.8,0.4-1.1,0.6c-1-0.5-2-1-2.9-1.5
					c-0.4-0.2-0.9-0.4-1.3-0.5c-0.8-0.2-1.5-0.4-2.2-0.6c-1.9-0.5-3.6-1-5.6-1c-0.8,0-1.6,0.1-2.3,0.3c-2.4-2.2-5.4-4.1-7.6-5.2
					C365,48.9,364,48.7,363,48.7L363,48.7z M198.3,95.6c0.5-0.4,1-0.9,1.4-1.4c0.1,0.5,0.3,1.1,0.5,1.6c-0.6,0.1-1.3,0.2-1.8,0.3
					C198.4,95.9,198.4,95.7,198.3,95.6L198.3,95.6z M329,93.7h-2c-1.7,0-3.4,0.7-4.6,1.9c-2.9,2.9-2.9,6.2-2.9,8.6
					c0,0.7,0.1,1.4,0.3,2.1c0.4,1.1,1.7,4.5,4.7,6.8c0.6,0.8,1.2,1.5,1.8,2.1c2.5,3.8,5.5,4.6,7.7,4.6c1.4,0,4.7,0,7.6-2.9
					c0.3-0.3,0.6-0.6,0.8-1c3.9-5.9,0.9-10.9-0.2-12.8c-0.2-0.3-0.3-0.5-0.4-0.7c-0.3-0.6-0.7-1.2-1.2-1.7
					C336.8,96.8,333.3,93.7,329,93.7L329,93.7z M315.5,115.5c-1.7,0-3.3,0.4-4.8,1.3c-1.2,0.3-2.4,0.9-3.3,1.8
					c-2.9,2.9-2.9,6.9-2.9,8.6c0,1,0.2,2,0.7,2.9c2.7,5.4,6.3,6.6,8.8,6.6h1c4.7,0,7.3-2.6,8.6-3.9c1.2-1.2,1.9-2.9,1.9-4.6v-2v-3
					c0-2.5-1.4-4.7-3.6-5.8C320.4,116.6,318.2,115.5,315.5,115.5L315.5,115.5z"/>
			</g>
			<g id="st0">
				<path d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,49.2c-2.1,0-6.8,0-9.6,4.1c-1.6-1.1-3.6-1.8-6-1.8c-1.7,0-3.6,0.4-5.6,1.2
					c-0.2,0.1-0.3,0.1-0.5,0.2c-3.7,1.9-6.4,4.5-7.9,7.8c-0.7-0.3-1.5-0.4-2.4-0.4h-1c-0.6,0-1.3,0.1-1.9,0.3
					c-1.8,0.6-4.3,2.1-5.9,6.1c-1.2,2.9-2.1,8-1.1,11.8c0.5,4.7,4,7.8,8.9,7.8h1c0.9,0,1.7-0.1,2.4-0.3c0.6,1,1.4,2,2.2,3l0.6,0.8
					c0,0,0.1,0.1,0.1,0.1c3.3,4.1,5.5,6.9,8.9,7.9c1.4,1.2,3.3,2.4,5.8,2.4c0.7,0,1.4-0.1,2.1-0.2c-0.1,0.4-0.1,0.8-0.1,1.2
					c0,1.5,0.1,3.2,1.1,5.6c0.3,2.1,1.2,3.8,2.6,5.1c-1.2,1-2.8,2.7-3.4,5.3c-0.9,2-0.9,4.2,0.1,6.3c0.7,1.4,1.7,2.3,2.4,3.1
					c1.1,1.1,2.7,1.8,4.2,1.8h1c0,0,0.1,0,0.1,0c0,0.2-0.1,0.4-0.1,0.6c0,0,0,0.1,0,0.1c-0.1,0.3-0.1,0.8-0.2,1.3
					c-4.2,2.3-6.6,7.7-7.6,11.3c-0.2,0.6-0.3,1.2-0.4,1.7c-0.3,0-0.5-0.1-0.8-0.1c-5.3,0-9.3,3.6-9.9,8.7c-0.1,0.4-0.3,0.9-0.3,1.3
					c-1.3-0.3-2.4-0.4-3.4-0.4c-3.6,0-6,1.6-8,3c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.2-0.6,0.5-0.9,0.7l-1.5,1.5
					c-0.4,0.1-0.8,0.2-1.2,0.3c-0.1-0.1-0.1-0.1-0.2-0.2c-0.5-0.9-1-1.6-1.4-2.2c-0.2-0.3-0.4-0.6-0.6-1c-0.7-4.8-4.3-8.2-8.9-8.2
					c-6.8,0-9.5,8.2-11.8,17.3c-0.5,1.5-0.8,2.8-0.9,3.9c-0.1,0-0.3,0-0.5,0l-0.5-0.9c-0.3-0.7-0.8-1.3-1.4-1.8
					c-0.1-0.7-0.3-1.5-0.6-2.1c0-0.1,0-0.1-0.1-0.2c0.4-0.3,0.7-0.6,0.9-0.9c1.1-1.1,1.8-2.7,1.8-4.2c0-0.7-0.1-1.4-0.3-2.1
					c1.4-0.8,2.2-1.9,2.7-3c1.8-2.7,1.8-5.9,0-9.6c-0.8-1.5-2.2-2.7-3.8-3.1c-0.7-0.3-1.6-0.7-2.9-0.7c-0.1,0-0.2,0-0.3,0
					c0.3-0.9,0.4-1.8,0.5-2.7c0.1-0.5,0.3-0.9,0.4-1.4c0.7-2.5,1.8-6.1-1.4-10c-0.2-4.1-3.1-6.5-4.4-7.5c-0.1-0.1-0.3-0.2-0.4-0.3
					c-0.6-0.6-1.2-1-1.9-1.3c0,0-0.1-0.1-0.1-0.1c-2.5-3.4-6.2-5.4-10.6-5.4c-2,0-4.1,0.4-6.3,1.2c-0.9-1.4-2-2.6-3.1-3.6
					c-0.1-1.2-0.6-2.5-1.7-3.9c-0.3-0.7-0.7-1.4-1.3-2c-2.3-2.3-5.9-3.8-9.2-3.8c-2.4,0-5.5,0-8.2,2.8c-0.2,0.2-0.3,0.4-0.5,0.6
					c-3.5,0.9-5.6,3.4-6.2,6.1c-0.6,1.8-0.9,4.5,1.1,7.3c-0.1,0.9-0.2,1.8-0.2,2.6c-1.3,0.3-2.7,0.9-3.9,1.7c0-0.1-0.1-0.2-0.1-0.2
					c-2.3-3.5-5.1-5.6-8.6-6.3c0.2-1.9,0-4.4-2.1-6.6c-2-2-4.1-3.4-9.3-4.5c-1.7-0.8-3.6-1.7-6.1-2.6c2-1.2,3.4-3.3,3.8-5.8
					c0.7-1.7,0.8-3.7,0.1-5.8c-0.5-1.6-1.5-3.1-2.7-4.3c-1.1-1.1-2.7-1.8-4.2-1.8h-2c-2.1,0-4.6,1-6.5,3.2c0.1-1.6-0.1-3.1-0.6-4.4
					c0.1-0.6,0.2-1.2,0.2-1.8c0-0.8-0.2-3.7-2.8-6.2c-0.8-0.8-3.1-2.8-6.2-2.8c-1.6,0-3.1,0.6-4.2,1.8l0,0c-0.1,0.1-0.3,0.1-0.4,0.2
					c-1.5,0-3.9,0.2-6,1.4c-0.7-0.2-1.5-0.4-2.4-0.4c-4.3,0-9,3.6-9,9c0,1.9,0.4,3.8,1.3,5.4c-0.2,0.1-0.3,0.3-0.5,0.4
					c-1.7,0.9-3,2.5-3.8,4.3c-0.4,0.2-0.9,0.6-1.4,1c-3.2,1.9-4.7,5-5.9,7.2c-0.4,0.8-0.6,1.8-0.6,2.7c0,1.7,0,3.8,1,5.8v0.9
					c-1.1,2.9-2,6.8-2,9.3c0,0.4,0,0.9-0.1,1.3c-0.2,2-0.5,6,3.1,9.4c0,0.1,0.1,0.1,0.1,0.2c0.2,1,0.4,1.8,0.8,2.5
					c-2.6,5.2-2.9,13.5-2.9,13.6c0,0.3,0,0.6,0.1,0.9c-0.5,0.1-1.2,0.1-2.1,0.1c-0.3,0-0.7,0-1,0.1c-5.5,0.9-10.4,2.1-13.8,6.7
					c-3.3,0.3-6.5,2-9.4,5c-1.1,1.1-1.8,2.7-1.8,4.2v0.1c-0.3-0.1-0.7-0.1-1-0.1c-3.9,0-7.3,0.1-10.1,1H118c-0.1,0-0.2-0.1-0.3-0.1
					c-1.8-0.6-3.7-1.3-5.9-1.3c-1.6,0-3.1,0.4-4.5,1c-3.7,1.8-4.7,5.4-5.3,7.5c-0.1,0.4-0.3,1-0.4,1.2c-0.4,0.8-0.6,1.7-0.6,2.7
					c0,3.2,0,6,1.3,9.9c0.1,0.2,0.2,0.4,0.3,0.6c-0.1,0.6-0.3,1.4-0.4,2.3c-0.1,0.4-0.1,0.8-0.1,1.2c0,1-0.9,3.8-1.6,5.7
					c-0.6,1.3-2,4.5-2.3,7.7c-0.1,0.1-0.2,0.2-0.3,0.3c0,0-3.4,3.6-4.5,7.3c-0.1,0.2-0.2,0.4-0.2,0.5c-0.4,0.2-0.8,0.3-1,0.5
					c-1.8,0.2-3.2,0.7-4,1c-1.2,0.2-2.4,0.8-3.3,1.7c-0.1,0.1-0.2,0.2-0.4,0.3c-1.1,0.9-3.3,2.7-4.1,5.7c-0.7,1.3-1,2.7-1,4.2
					c0,1.5,0.4,2.9,1,4.2c-0.2,0.6-0.3,1.2-0.3,1.8v0.3c-3.5,2.9-4,6-4.8,10.8l-0.1,0.8c-1.1,4.5-1.1,9.5-1.1,11.9
					c-0.3,0.1-0.6,0.3-0.9,0.4c-5,0.6-7.1,3.8-8.3,6.8c-0.4,0.3-0.7,0.6-1.1,0.9c-2.9,1.7-4.7,5.3-4.7,8.1c0,2.9,1.9,6.4,3.8,8.2
					c0.1,0.1,0.1,0.2,0.2,0.3c0.8,1,2.6,3,5.6,4c0,0.1,0.1,0.1,0.1,0.2c1.7,3.4,6.2,6.7,8.8,8.2c0.2,0.2,0.5,0.4,0.7,0.6
					c-0.3,0.3-0.6,0.7-0.8,1.2c-1.9,1.5-3.3,4-3.3,7.3c0,4.7,4.2,6.8,7.3,8.4c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.7,0.3,2.4-0.6,6.2
					c0,0,0,0.1,0,0.1c-0.3,1.3-0.5,2.5-0.7,3.2c-1.5,1.6-4.4,4.7-4.4,8.9c0,2.8,1,4.8,2.3,6.2c-2,2-3.6,3.8-5,7.9
					c-0.2,0.6-0.3,1.3-0.3,1.9c0,1.7,0,3.9,0.7,5.9c-0.7,1.7-0.9,3.6-0.5,5.6c0.3,1.4,1,2.7,2,3.8c0.2,0.9,0.5,1.8,0.9,2.5
					c-0.2,0.2-0.3,0.4-0.5,0.6c-5.8,3.2-10.3,10.7-11.6,13.2c-1.2,1.4-2.1,3.1-3,5c-1.1,1.2-2.7,3-4.1,4.9c-2.8,3.7-4,6.2-4,8.6
					c0,0.9,0,2.6,0.8,4.4c0.1,3.1,1.6,7.4,7.9,11.5c0.2,0.1,0.4,0.3,0.6,0.4c2.2,1.1,4.4,1.7,6.6,1.7c2.6,0,5.1-0.8,7.4-2.4
					c2.6,1.3,5.5,1.9,7.7,2.2c0,0,0,0.1,0,0.1c0,2.4,0,5.5,2.8,8.2c0.5,0.5,1,0.8,1.6,1.1c1.2,0.6,2.5,0.9,3.7,0.9
					c3.8,0,6-2.6,7-3.8c0.1-0.1,0.2-0.2,0.2-0.3c0.4-0.1,0.8-0.2,1-0.3c5.3-0.1,8.7-3.5,10-4.8c0.3-0.3,0.5-0.6,0.7-0.9
					c0.9-1.3,2.6-2.8,3.5-3.5c4.5-0.8,9.5-4.7,12.2-7.9c4.3-0.2,7-1.9,8.5-4.2c0.1,0.1,0.3,0.3,0.5,0.5l1.9,1.9
					c0.5,1.2,1.3,2.2,2.3,3v0.8c0,1.5,0.4,2.8,0.8,3.9c0,0.1,0.1,0.2,0.1,0.3c-0.1,0.2-0.1,0.3-0.2,0.5c-0.8,2-2,5.1-0.1,9
					c1.6,3.2,5,5.4,9.4,6.1c0.2,0.2,0.5,0.3,0.8,0.5c0,0,0.1,0,0.1,0.1c1.1,1,2.5,1.6,4,1.6c-0.5,2.9-0.3,4.9,0.1,6.5v0.6
					c-0.7,2.2-1.4,6.1,1,9.9c-0.1,2.8-0.4,5.8-0.8,8.5c-0.1,0.3-0.2,0.5-0.3,0.7c-0.9,2.5-1.9,5.5-0.9,8.8c0,1.1,0.1,2.3,0.5,3.5
					c0.3,1,0.9,2,1.5,2.7c0.1,1,0.2,2,0.4,3c-0.3,0.8-0.5,1.7-0.5,2.7c0,2.7,0.9,4.8,2.2,6.3c0.6,3,2.5,4.5,4.1,5.1
					c1.4,0.9,3.1,1.3,5.1,1.3c1.6,0,3.5-0.3,6.1-0.9c0.5-0.1,0.9-0.3,1.3-0.5c0.6,0.1,1.5,0.2,2.7,0.5c0.8,0.3,1.5,0.5,2.2,0.7
					c1.5,3.9,5.2,5.7,7.1,6.5c0.2,0.1,0.4,0.2,0.6,0.3c0.3,0.2,0.7,0.3,1,0.4c1,0.9,1.9,1.3,2.5,1.6c0,0,0.1,0,0.1,0.1
					c0,0,0.1,0.1,0.1,0.1c2,3.6,4.7,5.4,7.9,5.4c0.8,0,1.4-0.1,2-0.3c1.9,0.9,4.1,2,7.1,3c0.3,0.1,0.7,0.2,1.1,0.2
					c0.5,2.6,1.8,4.3,3.1,5.5c0.6,2.4,2.2,4.3,4.6,5.4c1.8,0.9,3.7,1.2,5.9,1.6c0.6,0.1,1.3,0.2,2,0.4c0.4,0.1,0.8,0.1,1.2,0.1
					c0.1,0,0.3,0,0.5,0c1.3,1.2,3.3,2.7,6.4,3c1.3,1.9,3,3.2,5.3,4.4c0.8,0.4,1.8,0.6,2.7,0.6h0.4c0.8,0.3,1.5,0.4,2.4,0.4
					c1.1,0,2-0.3,2.7-0.6c0.1,0,0.1,0,0.2,0c0.9,0.2,1.7,0.3,2.6,0.3c0.8,0,1.6-0.1,2.3-0.3c1,0.7,2.3,1.1,3.5,1.1h3
					c1.2,0,2.3-0.3,3.3-0.8c0.7,0.1,1.5,0.2,2.4,0.2c0.3,0,0.6,0,0.8,0c1.4,1.2,3,2,4.6,2.4c1.7,0.8,3.7,1.6,6.1,1.6
					c1.6,0,3.1-0.4,4.4-1c0.6-0.3,1.1-0.7,1.6-1.1c0.6-0.6,1.2-1.4,1.7-2.2c0.9-0.2,1.8-0.5,2.6-0.9c2.7,0.8,5.5,0.8,7.5,0.8h5
					c1.3,0,4.5,0,7.2-2.8c2.8-2.8,2.8-5.9,2.8-7.2c0-0.5-0.1-1.1-0.2-1.6c0.2-1.2,0.8-3.5,1.2-5c0,0,0-0.1,0-0.1
					c3.4-0.4,5.9-2.7,7.4-6.7c1.9-3.3,2.9-8.4-1.4-14.9c-0.1-0.2-0.2-0.3-0.3-0.5c0.9-1.3,1.7-2.9,2.1-4.5c2.1-1.4,4.2-4,4.2-8.7
					c0-2-0.5-3.5-1.2-4.6c0.5-0.9,0.8-1.8,1-2.7c0.4-0.9,0.7-1.7,0.9-2.4c0.2-0.7,0.4-1.1,0.7-1.7c0.1-0.2,0.2-0.4,0.3-0.7
					c2.9-2.6,4-6.9,4.3-9.6c1-1.1,1.7-2.5,2-4c0.2-0.4,0.6-0.9,0.9-1.4c2.9,0.2,7.5,0.6,13.9,1.8l3.8,1c0.5,0.1,1,0.2,1.5,0.2
					c5,0,7.3-3.1,7.8-6.3l0.5-1c0.1-0.3,0.2-0.5,0.3-0.8c0,0,0.1,0,0.1,0c2.3,0.5,4.3,0.8,6.1,0.8c2.6,0,4.9-0.6,7.3-2
					c0.8-0.2,1.4-0.6,1.9-0.8c0.1,0,0.2,0,0.3-0.1c2.7-0.7,4.9-2,6.3-3.9c0.4,0.1,0.8,0.1,1.2,0.1c4.5,0,6.5-2.1,7.4-4.1
					c2.2-3,2.4-7.1,0.6-12v-8.3c0.1-0.5,0.2-0.9,0.3-1.4c0.5-2.2,0.9-4.1,0.6-6.1c-0.2-1.1-0.6-2.6-1.7-4c0.2-0.8,0.4-1.5,0.6-2.1
					c1.8-0.8,3.4-2,4.3-3.7l2.1-2.1c1.4-1.4,5.7-6.1,6.6-12.1c0.1-0.2,0.3-0.5,0.4-0.8c1.3-1.3,2.5-3.3,3.1-6.1
					c1.6,2,3.9,3.8,6.6,4.5c0.9,0.4,1.9,0.6,2.9,0.6c0.5,0,1,0,1.4-0.1c1.5,1.1,3.4,1.7,5.7,1.7c0.5,0,1.9-0.1,3.4-0.8c0,0,0,0,0,0
					c2.4,0,4.5-0.8,6.1-2.4c1.7,2.8,4.1,4.2,7.4,4.2c1.6,0,3.1-0.6,4.2-1.8c0.1-0.1,0.3-0.3,0.5-0.5c0.9-0.3,1.8-0.8,2.5-1.5
					c1.9-1.9,2.5-4,2.7-5.3c0.4-0.9,1.5-2.9,2.3-3.7c0.3-0.3,0.5-0.6,0.7-0.9c2.4-3.6,3.1-6.2,2.4-9.7c0.2-0.5,0.4-1,0.5-1.5
					c0.4-0.8,1.1-2.3,1.1-4.1c0-2.6-1.3-4.6-2.2-5.7c-0.1-0.3-0.2-0.7-0.4-1c-0.2-0.4-0.4-1-0.5-1.8c-0.3-1.2-0.7-2.6-1.5-4.2
					c-0.1-0.2-0.2-0.4-0.3-0.6c1-3.5,1-6.7,1-8.7c0-0.5,0-1.1,0-1.5c0.1-0.1,0.2-0.2,0.2-0.2c1.6-1.6,2.8-3.4,3.6-5.3
					c1.5-0.1,2.7-0.3,3.7-0.7c0.1,0.1,0.2,0.2,0.4,0.3c0.3,0.2,0.7,0.6,0.8,0.7c1.1,1.1,2.6,1.7,4.2,1.7c3.8,0,6.9-2.1,8.2-5.6
					c0.6-1.6,0.7-3.1,0.7-4.4c0.3-1,0.6-2.3,0.7-3.9c1.4-0.4,2.9-1,4.3-1.7c2.3,2.2,5.4,3.7,8,3.7c4.6,0,6.5-2.2,7.4-4.1
					c0.6-0.8,0.9-1.7,1.2-2.5c0.6-0.3,1.2-0.7,1.7-1.2c0.1-0.1,0.4-0.3,0.6-0.4c0.5-0.4,1.1-0.8,1.7-1.4c1.4,0.6,2.7,0.8,3.6,0.8
					c1.7,0,2.9-0.5,3.5-0.8c2.4-1.2,5.8-4,4.7-10.4c1.6-2.6,1.6-5.5,1.6-7c0-1.6-0.6-3.1-1.8-4.2c-0.6-0.6-1.2-1.2-2-1.8
					c0.6-0.7,1.1-1.4,1.6-2.3c2.9-2.9,4.1-4.4,4.1-7.6c0-0.9-0.2-1.9-0.5-2.8c0.1-0.1,0.2-0.1,0.2-0.2c2.5-1.4,4.3-4,4.3-7
					c0-1,0-1.9-0.1-2.9c0.1-0.1,0.2-0.2,0.3-0.3c3.1-0.3,7.7-1,11.4-2.9c1,0.7,2.5,1.5,4.7,1.5c1.5,0,2.9-0.4,4.3-1.1
					c0.6-0.3,1.1-0.7,1.6-1.1c1.2-1.2,1.8-2.4,2.1-3.1c0,0,0-0.1,0.1-0.1c0.4-0.4,0.7-0.8,0.9-1.3c0.2-0.2,0.5-0.4,1-0.7l3-2
					c0.3-0.2,0.6-0.5,0.9-0.7c2.8-2.8,2.8-5.9,2.8-7.2c0-1.2-0.4-2.3-1-3.3l-1.4-2.1c-0.1-0.3-0.2-0.6-0.3-0.9
					c0.5-3.7-1-7.6-2.6-11.6c-1.9-7.1-6.3-8-10.8-8.9l-0.7-0.1c0.5-1.2,0.8-2.5,0.8-4.1c0-2.2-0.6-3.8-1.3-5.1c0-0.1,0-0.1,0.1-0.2
					c1.5-1,3.5-2.6,6.1-5c4-1,7.8-3.8,9.8-6.7c1.4-0.1,2.8-0.5,4.7-1.4c6.7-2.4,6.7-7.3,6.7-10.5c0-1.2,0-3.3-0.6-5.5
					c-0.4-1.6-1.1-3-2.1-4.2c-0.1-0.7-0.2-1.2-0.3-1.6c0-0.1,0-0.2-0.1-0.2c-0.1-1.4-0.7-2.8-1.7-3.8c-0.5-0.5-1.2-1.2-2-1.8
					c-0.4-2.7-2.1-5.3-4.8-6.9c-0.2-0.2-0.4-0.3-0.6-0.5c0,0-0.1-0.1-0.1-0.1c-0.1-0.4-0.3-0.8-0.4-1.2l-1-2c-0.7-1.5-2-2.6-3.6-3
					c-1.2-0.8-2.9-1.3-4.8-1.3c-1.2,0-2.3,0.4-3.3,1l-3,2c-0.3,0.2-0.6,0.5-0.9,0.7c-0.1,0.1-0.2,0.2-0.4,0.4
					c-2.2,0.3-3.8,1.2-5,1.9c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1-2-0.7-3.4-1.1-4.2c0,0,0,0,0,0c0,0,0.1,0,0.1,0c4.7,0,8-3.5,8.8-7.3
					c1.4-3.2,1.2-6-0.5-9.4c-0.3-0.6-0.7-1.1-1.1-1.6c-0.7-0.7-1.5-1.5-2.5-2.2c-0.1-0.3-0.3-0.6-0.5-0.9c-1.2-1.9-3.1-3.1-5.4-3.5
					c-0.9-0.4-1.8-0.6-2.8-0.6c-3.2,0-6,2-7.3,4.8c-1,0.3-2.2,0.8-3.3,1.7c-1.7,1.1-2.9,2.5-3.5,4.2l-0.3-0.1
					c-0.2-0.1-0.4-0.1-0.7-0.1c-2.6-0.4-4.6-0.7-6.6-0.7c-3.1,0-5.6,0.7-9.1,1.9c-5.1,1.3-7.9,2.4-9.8,3.8
					c-4.5,0.4-7.5,2.5-10.6,5.5c-5.3,4.4-5.3,4.4-8.6,6.1c-0.2,0.1-0.5,0.2-0.7,0.4c-0.6-0.3-1.3-0.6-2.1-0.8
					c-0.8-0.7-1.6-1.3-2.5-2.6c-0.2-0.3-0.5-0.6-0.7-0.9l-1-1c-1.1-2.1-3.1-3.5-5.6-3.7l-6.6-0.9c-0.6-7.8-8.5-9.2-13.6-9.9
					c-3.5-1.1-6.1-1.6-8.6-1.6c-2.4,0-4.7,0.5-7,1.5c-1.5,0-3.2-0.2-4.8-0.7c-0.4-0.1-0.8-0.2-1.2-0.3l-8-1c-0.2,0-0.5,0-0.7,0h-1
					h-3c-0.6,0-1.8,0-3.4,0.2c0.5-0.8,0.9-1.6,1.1-2.3c0.2-0.6,0.3-1.3,0.3-1.9c0-1.9-0.7-3.3-1.1-4.1c-0.2-1-0.6-1.9-1.2-2.6
					c0-0.1-0.1-0.2-0.1-0.3c-0.6-1.2-1.5-3.2-3.8-5.7c-3.4-5.5-4.6-6.7-10.3-8.1c-0.2,0-0.3-0.1-0.5-0.1c-1-0.2-2-0.3-2.9-0.3
					c-1-1.9-2.6-4.7-5.5-6c0.4,0,0.9,0.1,1.3,0.1c0.4,0.1,0.8,0.1,1.2,0.1c3.2,0,7.5,0,10.2-2.8c0,0,0.1-0.1,0.1-0.1
					c2.6-1.2,4.3-2.8,5.1-5c1.6-0.8,2.8-2,3.8-2.9c0.6-0.6,1.2-1.4,1.7-2.2c1.5,0.7,3.2,1.3,5.2,1.7l5.2,2.6
					c0.4,0.2,0.7,0.4,1.1,0.6c1.5,0.9,3.5,2,6.6,2h1c6.6,0,11.1-3.6,14.2-6.8c0.3-0.3,0.5-0.6,0.7-0.9l1.8-2.6
					c2.8-3.3,2.7-7.8-0.5-10.9l0,0c-0.1-0.2-0.2-0.3-0.3-0.5c-0.2-1.3-0.8-2.6-1.7-3.5c-2.8-2.8-5.9-2.8-7.2-2.8c-1.4,0-3.7,0-6,0.5
					c-2.3-2.2-4.7-2.6-6.4-2.6c-2,0-3.8,0.6-5.7,1.5c-2-1.8-4-2.1-5.4-2.1c-1.1,0-2.7,0.3-4.3,1.3c-0.5,0.2-0.9,0.5-1.4,0.8
					c-1-0.6-2.1-1.1-3.2-1.7c-0.4-0.2-0.8-0.3-1.2-0.5c-0.8-0.2-1.5-0.4-2.2-0.6c-1.8-0.5-3.6-1-5.5-1c-0.9,0-1.7,0.1-2.5,0.3
					c-2.4-2.2-5.5-4.2-7.6-5.3C364.9,49.4,363.9,49.2,363,49.2L363,49.2z M197.8,95.4c0.8-0.7,1.6-1.5,2.2-2.4
					c0.2,1.1,0.5,2.2,1,3.2c-1.1,0.1-2.1,0.2-3,0.5C198,96.2,197.9,95.8,197.8,95.4L197.8,95.4z M329,94.2h-2
					c-1.6,0-3.1,0.6-4.2,1.8c-2.8,2.8-2.8,5.8-2.8,8.2c0,0.6,0.1,1.3,0.3,1.9c0.4,1.1,1.7,4.4,4.5,6.6c0.5,0.8,1.2,1.5,1.8,2.1
					c2,3,4.4,4.5,7.3,4.5c1.3,0,4.5,0,7.2-2.8c0.3-0.3,0.5-0.6,0.7-0.9c3.7-5.6,0.9-10.5-0.2-12.3c-0.2-0.3-0.3-0.5-0.4-0.7
					c-0.3-0.6-0.7-1.1-1.1-1.6C336.5,97.3,333.1,94.2,329,94.2L329,94.2z M315.5,116c-1.7,0-3.2,0.4-4.6,1.3
					c-1.2,0.2-2.3,0.8-3.1,1.7c-2.8,2.8-2.8,6.6-2.8,8.2c0,0.9,0.2,1.9,0.6,2.7c2.1,4.2,4.9,6.3,8.4,6.3h1c4.5,0,6.9-2.4,8.2-3.8
					c1.1-1.1,1.8-2.7,1.8-4.2v-2v-3c0-2.3-1.3-4.4-3.3-5.4C320.2,117.1,318.1,116,315.5,116L315.5,116z"/>
			</g>
			<g id="st1">
				<path id="st2" d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,49.7c-2.1,0-6.9,0-9.5,4.3c-1.6-1.2-3.6-2-6.2-2c-1.7,0-3.4,0.4-5.4,1.2
					c-0.1,0.1-0.3,0.1-0.4,0.2c-3.8,1.9-6.5,4.6-7.9,8c-0.8-0.4-1.6-0.6-2.7-0.6h-1c-0.6,0-1.2,0.1-1.7,0.3c-1.7,0.6-4,2-5.6,5.8
					c-1.2,2.8-2.1,7.9-1.1,11.5c0.5,4.4,3.8,7.4,8.4,7.4h1c1,0,1.8-0.1,2.6-0.4c0.7,1.1,1.5,2.1,2.4,3.3l0.6,0.8
					c0,0,0.1,0.1,0.1,0.1c3.3,4.1,5.5,6.8,8.8,7.7c1.4,1.2,3.2,2.3,5.5,2.3c1,0,1.9-0.1,2.8-0.3c-0.2,0.6-0.3,1.2-0.3,1.8
					c0,1.5,0.1,3.1,1.1,5.4c0.3,2.2,1.3,4,2.9,5.2c-1.2,1-3.1,2.7-3.7,5.4c-0.9,1.8-0.9,4,0.1,5.9c0.6,1.3,1.6,2.2,2.3,2.9
					c1,1,2.4,1.6,3.9,1.6h1c0.2,0,0.5,0,0.7,0c-0.1,0.4-0.2,0.8-0.2,1.2c0,0,0,0.1,0,0.1c-0.1,0.3-0.2,0.9-0.2,1.5
					c-4.2,2.2-6.6,7.5-7.6,11.1c-0.2,0.7-0.4,1.5-0.5,2.2c-0.4-0.1-0.8-0.1-1.2-0.1c-5.2,0-8.9,3.4-9.4,8.3
					c-0.2,0.6-0.3,1.2-0.4,1.8c-1.4-0.4-2.7-0.5-3.8-0.5c-3.5,0-5.6,1.5-7.7,2.9c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.2-0.6,0.4-0.8,0.7
					l-1.6,1.6c-0.5,0.1-1,0.2-1.5,0.5c-0.1-0.1-0.3-0.3-0.5-0.5c-0.5-0.9-1-1.6-1.4-2.2c-0.3-0.4-0.5-0.7-0.7-1.1
					c-0.6-4.6-4-7.9-8.4-7.9c-5.9,0-8.6,6.2-11.3,17c-0.5,1.6-0.8,3.1-1,4.4c-0.4,0-0.7-0.1-1.1-0.1c0,0-0.1,0-0.1,0l-0.6-1.2
					c-0.3-0.7-0.8-1.3-1.4-1.8c0-0.8-0.2-1.5-0.6-2.2c0-0.1-0.1-0.3-0.2-0.6c0.5-0.4,0.8-0.8,1.1-1.1c1-1,1.6-2.4,1.6-3.9
					c0-0.8-0.1-1.6-0.4-2.4c1.5-0.8,2.3-1.9,2.8-3c1.8-2.5,1.8-5.6,0-9.1c-0.7-1.4-2-2.5-3.5-2.9c-0.6-0.3-1.5-0.6-2.7-0.6
					c-0.2,0-0.5,0-0.7,0c-0.1-0.1-0.1-0.1-0.2-0.2c0.3-1,0.6-2.1,0.6-3.1c0.1-0.5,0.3-1,0.4-1.4c0.7-2.4,1.8-6-1.4-9.6
					c-0.1-4-2.9-6.2-4.2-7.3c-0.1-0.1-0.4-0.3-0.4-0.4c-0.5-0.5-1.1-0.9-1.7-1.2c-0.1-0.1-0.2-0.2-0.3-0.2c-2.4-3.3-6-5.2-10.2-5.2
					c-2.1,0-4.2,0.4-6.5,1.3c0,0,0,0,0,0c-0.9-1.6-2.1-2.9-3.3-3.9c-0.1-1.2-0.6-2.5-1.6-3.9c-0.3-0.7-0.7-1.4-1.2-1.9
					c-2.2-2.2-5.7-3.6-8.9-3.6c-2.4,0-5.3,0-7.9,2.6c-0.2,0.2-0.4,0.4-0.6,0.6c-3.3,0.8-5.4,3.2-6,5.7c-0.7,2-0.8,4.5,1.1,7.1
					c-0.2,1.1-0.2,2.1-0.2,3.1c-1.5,0.3-3.1,1-4.4,1.8H240c0,0-0.1,0-0.1,0c-0.1-0.2-0.2-0.4-0.3-0.6c-2.3-3.5-5.2-5.5-8.8-6.2
					c0.3-1.8,0.2-4.5-1.9-6.6c-2-2-4-3.3-9.1-4.4c-1.8-0.9-3.8-1.8-6.5-2.8c-0.2-0.1-0.3-0.2-0.5-0.3c2.4-1.1,4-3.2,4.5-5.9
					c1.4-3.2,0.1-7.1-2.5-9.6c-1-1-2.4-1.6-3.9-1.6h-2c-2.6,0-5.7,1.6-7.5,5c0-0.1,0-0.2,0-0.3c0.7-2.2,0.5-4.4-0.2-6.3
					c0.1-0.5,0.2-1.1,0.2-1.8c0-0.8-0.2-3.5-2.6-5.9c-0.8-0.8-2.9-2.6-5.9-2.6c-1.5,0-2.9,0.6-3.9,1.6l-0.1,0.1
					c-0.2,0.1-0.4,0.2-0.6,0.3c-1.5,0-3.9,0.2-6.1,1.4c-0.7-0.2-1.5-0.4-2.4-0.4c-4.1,0-8.5,3.4-8.5,8.5c0,2,0.5,3.9,1.4,5.6
					c-0.3,0.2-0.6,0.4-0.8,0.6c-1.7,0.9-3,2.5-3.8,4.2c-0.4,0.2-1,0.6-1.5,1c-3.1,1.9-4.6,4.8-5.7,7.1c-0.4,0.8-0.6,1.6-0.6,2.5
					c0,1.7,0,3.7,1,5.7v1.2c-1.1,2.8-2,6.7-2,9.2c0,0.4,0,0.9-0.1,1.4c-0.2,2.1-0.4,5.8,3,9c0.1,0.1,0.2,0.3,0.2,0.4
					c0.2,1,0.5,1.9,0.9,2.7c-2.6,5-3,13.5-3,13.6c0,0.5,0.1,1,0.1,1.4c-0.7,0.1-1.5,0.1-2.6,0.1c-0.3,0-0.6,0-0.9,0.1
					c-5.5,0.9-10.3,2.1-13.7,6.7c-3.2,0.3-6.4,1.9-9.3,4.8c-1,1-1.6,2.4-1.6,3.9v0.7c-0.5-0.1-1-0.2-1.5-0.2c-3.8,0-7.3,0.1-10,1h-3
					c-0.1,0-0.3-0.1-0.4-0.1c-1.8-0.6-3.7-1.3-5.8-1.3c-1.5,0-2.9,0.3-4.2,1c-3.5,1.7-4.5,5.2-5.1,7.2c-0.1,0.4-0.3,1.1-0.4,1.3
					c-0.4,0.8-0.6,1.6-0.6,2.5c0,3.2,0,5.9,1.3,9.7c0.1,0.3,0.2,0.5,0.3,0.7c-0.1,0.7-0.3,1.5-0.5,2.5c-0.1,0.4-0.1,0.7-0.1,1.1
					c0,1-0.9,3.9-1.6,5.9c-1,2.1-2,5.1-2.3,7.8c-0.2,0.1-0.3,0.3-0.5,0.4c0,0-3.4,3.6-4.3,7.1c-0.1,0.3-0.2,0.5-0.3,0.8
					c-0.5,0.2-0.9,0.4-1.3,0.6c-1.8,0.1-3.2,0.7-3.9,1c0,0,0,0,0,0c-1.2,0.2-2.2,0.7-3.1,1.5c-0.1,0-0.3,0.2-0.4,0.3
					c-1.1,0.9-3.2,2.6-4,5.5c-0.6,1.2-1,2.6-1,4c0,1.5,0.4,2.9,1.1,4.2c0,0,0,0.1,0,0.1c-0.2,0.6-0.3,1.1-0.3,1.7v0.5
					c-3.5,2.8-4,5.8-4.8,10.6l-0.1,0.8c-1.1,4.6-1.1,9.8-1.1,12.1c0,0,0,0,0,0c-0.4,0.1-0.8,0.3-1.3,0.6c-4.8,0.6-6.9,3.7-8.1,6.7
					c-0.4,0.3-0.8,0.7-1.2,1c-2.8,1.5-4.5,5-4.5,7.7c0,2.8,1.8,6.1,3.6,7.9c0.1,0.1,0.2,0.2,0.3,0.3c0.8,1,2.5,3,5.5,3.9
					c0.1,0.1,0.1,0.3,0.2,0.4c1.7,3.3,6.1,6.5,8.6,8c0.3,0.3,0.7,0.6,1.1,1c-0.3,0.4-0.8,0.9-1.2,1.6c-1.8,1.4-3.1,3.8-3.1,7
					c0,4.4,3.9,6.4,7,7.9c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.5,0.5,2.3-0.5,6.6c0,0,0,0.1,0,0.1c-0.3,1.4-0.5,2.7-0.7,3.4
					c-1.5,1.5-4.4,4.6-4.4,8.7c0,2.9,1.1,4.9,2.5,6.2c-2.1,2.1-3.8,3.9-5.2,8c-0.2,0.6-0.3,1.1-0.3,1.7c0,1.7,0,3.9,0.7,5.9
					c-0.7,1.6-1,3.5-0.5,5.5c0.3,1.4,1,2.6,2,3.6c0.2,1.1,0.6,2,1,2.8c-0.3,0.3-0.5,0.6-0.8,1c-5.7,3.1-10.2,10.7-11.5,13.1
					c-1.1,1.4-2.1,3.1-3,5c-2.1,2.2-8,8.8-8,13.2c0,0.9,0,2.6,0.8,4.4c0.1,3,1.5,7.1,7.6,11.2c0.2,0.1,0.4,0.2,0.6,0.3
					c2.2,1.1,4.3,1.6,6.4,1.6c2.6,0,5-0.8,7.4-2.4c2.8,1.4,6,2.1,8.3,2.3c0,0.2,0,0.4,0,0.6c0,2.4,0,5.3,2.6,7.9
					c0.4,0.4,0.9,0.8,1.4,1c1.1,0.6,2.3,0.9,3.5,0.9c3.6,0,5.7-2.5,6.6-3.6c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0,0,0.1-0.1
					c0.4-0.1,0.9-0.3,1.2-0.3c5.2-0.1,8.4-3.4,9.7-4.6c0.3-0.3,0.5-0.5,0.7-0.8c1-1.5,2.8-3,3.8-3.7c4.4-0.7,9.5-4.7,12.2-7.9
					c4.5-0.1,7.2-2,8.7-4.5c0.2,0.2,0.5,0.5,0.9,0.9l2,2c0.5,1.2,1.4,2.3,2.4,3.1v1c0,1.4,0.4,2.6,0.8,3.7c0,0.1,0.1,0.3,0.2,0.5
					c-0.1,0.2-0.2,0.4-0.3,0.7c-0.8,1.9-1.9,4.9-0.1,8.6c1.6,3.1,4.9,5.2,9.1,5.8c0.3,0.2,0.5,0.4,0.8,0.6c0.1,0,0.1,0.1,0.2,0.1
					c1,1,2.4,1.5,3.8,1.5c0.2,0,0.3,0,0.5,0c-0.7,3.2-0.4,5.3,0,7v0.7c-0.6,2.1-1.4,6,1,9.7c-0.1,2.9-0.4,6-0.8,8.8
					c-0.1,0.3-0.2,0.5-0.3,0.8c-0.8,2.4-1.9,5.4-0.9,8.6c0,1.1,0.1,2.2,0.5,3.4c0.3,1,0.9,1.9,1.5,2.7c0.1,1,0.2,2.1,0.4,3.2
					c-0.3,0.8-0.5,1.7-0.5,2.6c0,2.6,0.9,4.7,2.2,6.1c0.6,2.9,2.3,4.3,3.8,4.9c1.4,0.8,3,1.3,4.9,1.3c1.6,0,3.4-0.3,6-0.9
					c0.5-0.1,0.9-0.3,1.3-0.5c0.9,0.1,2.2,0.4,2.9,0.5c0.9,0.3,1.7,0.6,2.4,0.8c1.4,3.9,5.1,5.6,6.9,6.5c0.2,0.1,0.4,0.2,0.7,0.3
					c0.3,0.2,0.7,0.3,1,0.4c1,0.9,1.9,1.3,2.5,1.6c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.1,0.2,0.2c2,3.5,4.5,5.3,7.5,5.3
					c0.8,0,1.5-0.1,2.1-0.3c1.9,0.9,4.1,2,7.2,3c0.4,0.1,0.9,0.2,1.3,0.3c0.4,2.7,1.8,4.5,3.2,5.7c0.6,2.3,2.1,4.2,4.3,5.3
					c1.7,0.8,3.6,1.2,5.8,1.6c0.6,0.1,1.3,0.2,2,0.4c0.4,0.1,0.7,0.1,1.1,0.1c0.2,0,0.5,0,0.8,0c1.3,1.2,3.3,2.7,6.5,3
					c1.3,1.9,3,3.3,5.3,4.4c0.8,0.4,1.6,0.6,2.5,0.6h0.5c0.7,0.3,1.5,0.4,2.3,0.4c1.1,0,1.9-0.3,2.5-0.6c0.1,0,0.2,0,0.4-0.1
					c0.9,0.2,1.7,0.4,2.6,0.4c0.8,0,1.6-0.1,2.4-0.3c1,0.8,2.2,1.2,3.4,1.2h3c1.2,0,2.3-0.3,3.2-0.8c0.7,0.1,1.6,0.3,2.5,0.3
					c0.4,0,0.7,0,1-0.1c1.4,1.2,3,2.1,4.6,2.4c1.6,0.8,3.6,1.6,5.9,1.6c1.5,0,2.9-0.3,4.2-1c0.5-0.3,1-0.6,1.4-1
					c0.7-0.7,1.2-1.4,1.7-2.3c1-0.2,2-0.5,2.9-1c2.7,0.8,5.5,0.8,7.5,0.8h5c1.4,0,4.3,0,6.9-2.6c2.6-2.6,2.6-5.5,2.6-6.9
					c0-0.5-0.1-1-0.2-1.5c0.2-1.2,0.9-3.7,1.3-5.2c0-0.1,0.1-0.3,0.1-0.4c3.4-0.3,5.8-2.4,7.3-6.4c2.6-4.3,2.1-9.2-1.4-14.5
					c-0.2-0.3-0.4-0.5-0.6-0.7c0.9-1.4,1.9-3.1,2.3-4.8c0,0,0.1-0.1,0.1-0.1c2-1.3,4.1-3.8,4.1-8.4c0-2-0.6-3.5-1.3-4.6
					c0.5-0.9,0.9-1.9,1.1-2.8c0.4-0.9,0.7-1.7,0.9-2.4c0.2-0.7,0.4-1.1,0.7-1.8c0.1-0.2,0.2-0.5,0.3-0.7c2.9-2.6,4-6.9,4.2-9.5
					c1-1.1,1.7-2.5,2-3.9c0.3-0.4,0.7-1.1,1.2-1.7c2.9,0.2,7.6,0.6,14.3,1.8l3.8,1c0.4,0.1,0.9,0.2,1.3,0.2c4.7,0,6.8-2.9,7.4-5.9
					l0.6-1.1c0.2-0.4,0.3-0.8,0.4-1.2c0.2,0,0.4,0.1,0.6,0.1c2.3,0.5,4.2,0.8,6,0.8c2.6,0,4.8-0.6,7.1-1.9c0.8-0.2,1.5-0.6,1.9-0.8
					c0,0,0,0,0,0c0.1,0,0.2,0,0.4-0.1c2.7-0.7,4.8-2.1,6.2-4c0.5,0.1,0.9,0.2,1.4,0.2c4.3,0,6.1-2,6.9-3.8c2.1-2.9,2.3-6.9,0.6-11.7
					v-8.4c0.1-0.5,0.2-1,0.3-1.4c0.5-2.2,0.9-4,0.6-6c-0.1-1-0.6-2.6-1.8-3.9c0.3-1,0.6-1.9,0.8-2.6c1.9-0.7,3.4-2,4.3-3.6l2.2-2.2
					c1.4-1.4,5.6-6.1,6.5-11.9c0.2-0.3,0.3-0.6,0.5-0.9c1.4-1.5,2.8-3.7,3.2-7.1c1.6,2.3,4,4.6,7.1,5.3c0.9,0.4,1.8,0.6,2.8,0.6
					c0.5,0,1-0.1,1.5-0.2c1.4,1.1,3.3,1.8,5.6,1.8c0.5,0,1.8-0.1,3.3-0.8c0,0,0.1,0,0.1,0c3,0,4.9-1.3,6.1-2.6c0.1,0,0.1,0,0.2,0.1
					c1.6,2.9,4,4.4,7.2,4.4c1.5,0,2.9-0.6,3.9-1.6c0.2-0.2,0.4-0.4,0.6-0.6c0.9-0.3,1.7-0.7,2.4-1.4c1.9-1.9,2.4-3.9,2.6-5.1
					c0.4-0.9,1.5-3,2.4-3.9c0.3-0.3,0.5-0.5,0.7-0.8c2.4-3.5,3-6,2.3-9.5c0.3-0.5,0.5-1,0.6-1.6c0,0,0,0,0,0c0.4-0.8,1.1-2.2,1.1-4
					c0-2.5-1.3-4.4-2.2-5.4c-0.1-0.4-0.2-0.7-0.4-1c-0.2-0.5-0.4-1.1-0.6-1.9c-0.3-1.2-0.6-2.6-1.4-4.1c-0.1-0.2-0.2-0.5-0.4-0.7
					c0,0,0-0.1,0-0.1c1-3.5,1-6.8,1-8.8c0-0.6,0-1.3,0-1.7c0.1-0.1,0.3-0.3,0.4-0.4c1.7-1.7,2.9-3.5,3.6-5.4
					c1.7-0.1,3.1-0.4,4.2-0.8c0.2,0.2,0.4,0.3,0.6,0.5c0.3,0.2,0.7,0.6,0.8,0.7c1,1,2.4,1.6,3.9,1.6c3.6,0,6.5-2,7.8-5.3
					c0.6-1.5,0.7-3.1,0.7-4.3c0.3-1,0.7-2.5,0.7-4.2c1.5-0.4,3.3-1.1,4.9-2c2.3,2.3,5.3,3.7,7.9,3.7c4.3,0,6.1-2,6.9-3.8
					c0.6-0.9,1-1.8,1.2-2.6c0.6-0.3,1.2-0.7,1.7-1.2c0.1-0.1,0.4-0.3,0.6-0.5c0.5-0.4,1.2-0.9,1.9-1.6c1.5,0.7,2.8,0.9,3.7,0.9
					c1.6,0,2.7-0.5,3.3-0.7c2.3-1.1,5.5-3.9,4.4-10c1.6-2.5,1.6-5.4,1.6-6.9c0-1.5-0.6-2.9-1.6-3.9c-0.6-0.6-1.3-1.3-2.2-1.9
					c0-0.1-0.1-0.1-0.1-0.2c0.7-0.7,1.3-1.6,1.9-2.6c2.9-2.9,4-4.2,4-7.3c0-1-0.2-2-0.6-3c0.2-0.1,0.3-0.2,0.5-0.4
					c2.4-1.3,4.1-3.8,4.1-6.7c0-1.1,0-2-0.2-3.1c0.2-0.2,0.4-0.3,0.6-0.5c3.1-0.3,8-1,11.7-3c0.9,0.7,2.4,1.6,4.7,1.6
					c1.4,0,2.8-0.3,4.1-1c0.5-0.3,1-0.6,1.4-1c1.1-1.1,1.7-2.3,2-2.9c0-0.1,0.1-0.1,0.1-0.2c0.4-0.4,0.7-0.9,0.9-1.4
					c0.2-0.2,0.5-0.4,1.1-0.8l3-2c0.3-0.2,0.6-0.4,0.8-0.7c2.6-2.6,2.6-5.5,2.6-6.9c0-1.1-0.3-2.1-0.9-3.1l-1.4-2.1
					c-0.1-0.4-0.3-0.7-0.4-1.1c0.6-3.6-0.9-7.5-2.5-11.5c-1.8-6.8-5.8-7.6-10.5-8.5l-0.7-0.1c-0.2,0-0.4-0.1-0.6-0.1
					c0.7-1.2,1.1-2.7,1.1-4.5c0-2.1-0.6-3.8-1.3-5.1c0.1-0.2,0.1-0.4,0.2-0.5c1.5-1,3.6-2.6,6.3-5.2c3.9-1,7.9-3.9,9.8-6.7
					c1.4-0.1,2.9-0.4,4.8-1.3c6.4-2.3,6.4-7,6.4-10.1c0-1.8,0-6.3-2.6-9.5c-0.1-0.7-0.2-1.3-0.3-1.7c0-0.1,0-0.2-0.1-0.3
					c-0.1-1.3-0.7-2.6-1.6-3.5c-0.6-0.6-1.3-1.3-2.1-1.9c-0.1-0.8-0.3-1.5-0.6-2.3c-0.8-1.8-2.2-3.4-3.9-4.4
					c-0.2-0.2-0.4-0.4-0.6-0.5c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.4-0.2-0.9-0.4-1.3l-1-2c-0.7-1.4-1.9-2.4-3.3-2.8
					c-1.2-0.8-2.7-1.2-4.6-1.2c-1.1,0-2.1,0.3-3.1,0.9l-3,2c-0.3,0.2-0.6,0.4-0.8,0.7c-0.1,0.1-0.3,0.3-0.5,0.5
					c-2.2,0.3-3.8,1.2-5,1.9c-0.3,0.2-0.5,0.3-0.8,0.5c-0.1,0-0.3,0.1-0.4,0.1v0c0-2.3-0.7-3.9-1-4.8c0,0,0,0,0,0
					c0-0.2-0.1-0.5-0.2-0.7c0.2,0,0.5,0,0.7,0c4.4,0,7.6-3.4,8.3-6.9c1.3-3.1,1.2-5.8-0.4-9c-0.3-0.5-0.6-1-1-1.4
					c-0.7-0.7-1.5-1.5-2.5-2.2c-0.2-0.4-0.3-0.7-0.5-1c-0.7-1.1-2.2-2.8-5.2-3.3c-0.8-0.3-1.7-0.5-2.6-0.5c-3.1,0-5.7,1.9-6.9,4.7
					c-1.1,0.3-2.2,0.8-3.4,1.7c-1.7,1.1-2.9,2.6-3.5,4.4c0,0,0,0,0,0l-0.7-0.2c-0.2-0.1-0.4-0.1-0.6-0.1c-2.6-0.4-4.5-0.7-6.5-0.7
					c-3,0-5.5,0.7-8.9,1.9c-5.1,1.3-7.9,2.4-9.7,3.8c-4.4,0.3-7.4,2.4-10.4,5.4c-5.3,4.4-5.4,4.5-8.8,6.2c-0.3,0.2-0.6,0.3-0.9,0.5
					c-0.7-0.4-1.5-0.7-2.4-0.9c-0.9-0.7-1.8-1.4-2.7-2.8c-0.2-0.3-0.4-0.6-0.7-0.8l-1.1-1.1c-1-2-2.9-3.3-5.3-3.5l-7-1
					c-0.3-7.8-7.9-9.1-13.2-9.9c-3.5-1.1-6.1-1.6-8.5-1.6c-2.3,0-4.6,0.5-6.9,1.5c-1.5,0-3.4-0.1-5.1-0.7c-0.3-0.1-0.7-0.2-1.1-0.2
					l-8-1c-0.2,0-0.5,0-0.7,0h-1h-3c-0.7,0-2.4,0-4.5,0.3c0.9-1,1.4-2.1,1.7-3.1c0.2-0.6,0.3-1.1,0.3-1.7c0-1.8-0.7-3.2-1.1-4
					c0,0,0,0,0,0c-0.2-0.9-0.6-1.8-1.2-2.5c0-0.1-0.1-0.2-0.1-0.3c-0.6-1.2-1.5-3.2-3.7-5.6c-3.3-5.4-4.5-6.6-10.1-8
					c-0.1,0-0.3-0.1-0.4-0.1c-1.1-0.2-2.1-0.3-3.1-0.3c-1.2-2.4-3.3-6.3-8-6.7c0.7-0.2,1.6-0.3,2.5-0.3c0.6,0,1.1,0.1,1.6,0.2
					c0.4,0.1,0.7,0.1,1.1,0.1c3.2,0,7.3,0,9.9-2.6c0.1-0.1,0.1-0.1,0.2-0.2c2.6-1.1,4.3-2.7,5-4.9c1.7-0.8,2.9-2,3.8-2.9
					c0.7-0.7,1.3-1.6,1.8-2.5c1.5,0.8,3.4,1.5,5.5,1.9l5.3,2.6c0.4,0.2,0.8,0.4,1.1,0.6c1.4,0.8,3.4,2,6.3,2h1
					c6.5,0,10.8-3.5,13.9-6.6c0.3-0.3,0.5-0.5,0.7-0.8l1.8-2.6c2.7-3.1,2.6-7.3-0.5-10.3l-0.1-0.1c-0.1-0.2-0.2-0.4-0.3-0.6
					c-0.1-1.3-0.7-2.4-1.6-3.3c-2.6-2.6-5.5-2.6-6.9-2.6c-1.5,0-3.8,0-6.1,0.6c-2.2-2.2-4.6-2.7-6.3-2.7c-2,0-3.8,0.6-5.8,1.6
					c-1.9-1.8-3.9-2.2-5.3-2.2c-1.1,0-2.5,0.2-4,1.2c-0.6,0.2-1.1,0.5-1.5,0.9c-1.1-0.6-2.3-1.2-3.5-1.8c-0.4-0.2-0.7-0.3-1.1-0.4
					c-0.8-0.2-1.5-0.4-2.2-0.6c-1.9-0.5-3.5-1-5.3-1c-0.9,0-1.8,0.1-2.6,0.4c-2.4-2.2-5.6-4.3-7.7-5.4
					C364.7,49.9,363.9,49.7,363,49.7L363,49.7z M197.2,95.2c1.3-1,2.4-2.2,3.2-3.8c0,2,0.6,3.7,1.5,5.2c0,0,0,0,0,0
					c-1.6,0-3,0.2-4.4,0.7c0-0.1,0-0.1,0-0.2C197.5,96.5,197.4,95.8,197.2,95.2L197.2,95.2z M329,94.7h-2c-1.5,0-2.9,0.6-3.9,1.6
					c-2.6,2.6-2.6,5.5-2.6,7.9c0,0.6,0.1,1.2,0.3,1.7c0.4,1.1,1.6,4.3,4.4,6.4c0.5,0.8,1.2,1.5,1.8,2.1c1.9,2.9,4.2,4.3,6.9,4.3
					c1.4,0,4.3,0,6.9-2.6c0.3-0.3,0.5-0.5,0.7-0.8c3.6-5.3,0.9-9.8-0.2-11.8c-0.2-0.3-0.3-0.6-0.4-0.7c-0.3-0.5-0.6-1-1-1.4
					C336.3,97.7,333,94.7,329,94.7L329,94.7z M315.5,116.5c-1.6,0-3.1,0.4-4.5,1.2c-1.1,0.2-2.1,0.7-3,1.5c-2.6,2.6-2.6,6.3-2.6,7.9
					c0,0.9,0.2,1.7,0.6,2.5c2,4,4.7,6,7.9,6h1c4.3,0,6.6-2.3,7.9-3.6c1-1,1.6-2.4,1.6-3.9v-2v-3c0-2.1-1.2-4-3-4.9
					C320,117.5,318,116.5,315.5,116.5L315.5,116.5z"/>
			</g>
			<g id="st0">
				<path d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,50.2c-2.2,0-6.7,0-9.1,4.1c-0.1,0.1-0.2,0.2-0.3,0.4c-1.6-1.3-3.7-2.3-6.3-2.3
					c-1.6,0-3.3,0.4-5.2,1.1c-0.1,0.1-0.3,0.1-0.4,0.2c-4.5,2.2-6.8,5.3-7.9,8.2c-0.8-0.5-1.8-0.8-2.9-0.8h-1
					c-0.5,0-1.1,0.1-1.6,0.3c-1.6,0.5-3.8,1.9-5.3,5.5c-1.1,2.7-2.1,7.7-1.1,11.2c0.4,4.2,3.5,7,8,7h1c1.1,0,2-0.2,2.8-0.5
					c0.7,1.2,1.5,2.3,2.5,3.6l0.6,0.8c0,0,0.1,0.1,0.1,0.1c3.3,4.2,5.4,6.8,8.6,7.6c1.3,1.1,3,2.3,5.3,2.3c1.3,0,2.5-0.2,3.7-0.6
					c-0.1,0.2-0.1,0.3-0.2,0.4c-0.3,0.7-0.5,1.5-0.5,2.2c0,1.4,0.1,3,1.1,5.3c0.3,2.3,1.4,4.2,3.2,5.4c-0.1,0.1-0.2,0.1-0.2,0.2
					c-1.1,0.9-3.2,2.6-3.8,5.4c-0.8,1.7-0.8,3.7,0.1,5.5c0.6,1.2,1.5,2.1,2.2,2.8c0.9,0.9,2.2,1.5,3.5,1.5h1c0.5,0,1-0.1,1.5-0.2
					c-0.3,0.6-0.5,1.2-0.5,1.9c0,0.1,0,0.1,0,0.2c-0.1,0.4-0.2,1-0.2,1.8c-4.2,2-6.6,7.4-7.6,10.9c-0.3,0.9-0.5,1.8-0.5,2.7
					c-0.5-0.2-1.1-0.3-1.7-0.3c-4.9,0-8.5,3.2-8.9,7.9c-0.3,0.8-0.4,1.6-0.4,2.4c-0.2,0-0.3-0.1-0.4-0.1c-1.5-0.4-2.7-0.5-3.9-0.5
					c-3.3,0-5.4,1.4-7.4,2.8c-0.2,0.1-0.4,0.3-0.7,0.4c-0.3,0.2-0.5,0.4-0.8,0.6l-1.7,1.7c-0.6,0.1-1.2,0.3-1.9,0.6
					c-0.2-0.2-0.5-0.4-0.8-0.8c-0.5-0.9-1-1.6-1.4-2.3c-0.3-0.4-0.5-0.8-0.8-1.2c-0.6-4.4-3.8-7.6-7.9-7.6c-5.5,0-8.2,6.1-10.8,16.6
					c-0.6,1.8-0.9,3.4-1,4.8c-0.5-0.1-1-0.2-1.5-0.2c-0.1,0-0.3,0-0.4,0l-0.7-1.5c-0.3-0.7-0.9-1.3-1.5-1.8c0-0.8-0.2-1.5-0.5-2.2
					c-0.1-0.1-0.2-0.6-0.3-1c0.6-0.5,1-0.9,1.4-1.3c0.9-0.9,1.5-2.2,1.5-3.5c0-0.8-0.2-1.7-0.5-2.6c1.7-0.7,2.6-1.9,3-3.1
					c1.6-2.4,1.6-5.2-0.1-8.6c-0.7-1.3-1.8-2.3-3.2-2.6c-0.6-0.3-1.4-0.6-2.5-0.6c-0.3,0-0.6,0-0.9,0.1c-0.2-0.2-0.4-0.4-0.6-0.6
					c0.4-1.1,0.6-2.2,0.7-3.3c0.1-0.5,0.3-1,0.4-1.5c0.7-2.5,1.7-5.8-1.4-9.3c0-3.9-2.7-6.1-4-7.1c-0.2-0.1-0.4-0.3-0.5-0.4
					c-0.5-0.5-1-0.8-1.7-1.1c-0.1-0.1-0.3-0.2-0.4-0.3c-2.3-3.2-5.8-5.1-9.8-5.1c-2,0-4.1,0.4-6.3,1.3c-0.2,0.1-0.3,0.1-0.5,0.1
					c-1-1.7-2.3-3.1-3.6-4.2c-0.1-1.1-0.5-2.5-1.6-3.9c-0.2-0.7-0.6-1.3-1.2-1.8c-2.1-2.1-5.5-3.5-8.5-3.5c-2.4,0-5.1,0-7.5,2.5
					c-0.2,0.2-0.5,0.5-0.7,0.7c-3.3,0.7-5.2,3-5.8,5.4c-0.7,1.9-0.8,4.3,1.2,6.8c-0.2,1.2-0.2,2.4-0.2,3.6c0,0,0,0.1,0,0.1
					c-1.6,0.2-3.3,1-4.7,1.9H240c-0.1,0-0.2,0-0.4,0l-0.1-0.2c-0.1-0.2-0.2-0.4-0.3-0.5c-2.4-3.6-5.2-5.5-8.9-6.1
					c0.3-1.7,0.5-4.5-1.7-6.7c-1.9-1.9-3.9-3.2-8.9-4.2c-1.8-0.9-3.8-1.9-6.6-2.8c-0.5-0.3-1-0.6-1.5-0.8c2.8-0.8,4.7-3,5.2-6
					c1.3-3,0.1-6.7-2.3-9.1c-0.9-0.9-2.2-1.5-3.5-1.5h-2c-2.8,0-6.3,2-7.7,6.4c-0.2,0.5-0.3,1-0.3,1.6c0,2.3,0.7,4.4,1.9,6
					c-0.3,0-0.7,0-1,0c-1.8,0-3.4,0.3-4.9,0.9c0-0.4,0-0.7,0-0.9c0-0.8-0.1-1.5-0.4-2.2c2.3-1.8,4.1-4,4.3-7.8
					c0.7-2.2,0.5-4.4-0.2-6.2c0.2-0.5,0.3-1.1,0.3-1.8c0-0.7-0.2-3.2-2.5-5.5c-0.6-0.6-2.7-2.5-5.5-2.5c-1.3,0-2.6,0.5-3.5,1.5
					l-0.1,0.1c-0.3,0.1-0.5,0.3-0.7,0.4c-1.6,0-4,0.1-6.2,1.5c-0.7-0.3-1.6-0.5-2.4-0.5c-3.9,0-8,3.2-8,8c0,2,0.6,4.1,1.6,5.7
					c-0.4,0.2-0.8,0.5-1.2,0.9c-1.7,0.9-3,2.5-3.7,4.2c-0.5,0.2-1,0.6-1.6,1.1c-3,1.8-4.5,4.7-5.6,6.9c-0.3,0.7-0.5,1.5-0.5,2.2
					c0,1.8,0,3.7,1,5.6v1.4c-1.1,2.8-2,6.7-2,9.1c0,0.4,0,0.9-0.1,1.4c-0.2,2-0.4,5.6,2.9,8.6c0.1,0.2,0.2,0.3,0.3,0.5
					c0.2,1.1,0.5,2.1,0.9,2.9c-2.6,4.8-3.1,13.5-3.1,13.6c0,0.6,0.1,1.2,0.2,1.7c-0.8,0.2-1.8,0.3-3.2,0.3c-0.3,0-0.6,0-0.8,0.1
					c-5.4,0.9-10.2,2.1-13.5,6.7c-4,0.2-7.2,2.7-9.3,4.7c-0.9,0.9-1.5,2.2-1.5,3.5v1.4c-0.6-0.3-1.3-0.4-2-0.4c-3.8,0-7.3,0.1-10,1
					h-3.2c-0.2-0.1-0.3-0.1-0.5-0.2c-1.8-0.6-3.6-1.3-5.6-1.3c-1.4,0-2.8,0.3-4,0.9c-3.3,1.7-4.2,4.9-4.8,6.9
					c-0.1,0.5-0.3,1.1-0.4,1.4c-0.3,0.7-0.5,1.5-0.5,2.2c0,3.1,0,5.8,1.3,9.6c0.1,0.3,0.2,0.6,0.4,0.8c-0.1,0.7-0.3,1.6-0.5,2.6
					c-0.1,0.3-0.1,0.7-0.1,1c0,1.1-0.9,3.9-1.6,6.1c-1,2.1-2.1,5.1-2.3,7.9c-0.2,0.2-0.4,0.3-0.6,0.5c0,0-3.3,3.5-4.2,6.9
					c-0.1,0.3-0.3,0.6-0.4,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.6,0.2-1.1,0.5-1.5,0.7c-1.8,0.1-3.1,0.7-3.9,1c0,0-0.1,0-0.1,0
					c-1.1,0.2-2.1,0.6-2.8,1.4c-0.1,0.1-0.3,0.2-0.4,0.4c-1.1,0.9-3.1,2.5-3.8,5.3c-1.3,2.4-1.3,5.6,0.1,8c0,0.1-0.1,0.2-0.1,0.3
					c-0.2,0.5-0.3,1-0.3,1.6v0.7c-3.5,2.8-3.9,5.5-4.8,10.5l-0.1,0.8c-1.1,4.5-1.1,9.8-1.1,12c0,0.1,0,0.3,0,0.4
					c-0.5,0.2-1.1,0.4-1.7,0.7c-4.7,0.6-6.7,3.6-7.8,6.5c-0.5,0.3-0.9,0.7-1.3,1.1c-2.7,1.4-4.3,4.8-4.3,7.3c0,2.6,1.8,5.9,3.5,7.5
					c0.1,0.1,0.2,0.2,0.3,0.3c0.8,1,2.5,3,5.5,3.8c0.1,0.2,0.2,0.4,0.2,0.6c1.6,3.2,6.2,6.5,8.5,7.8c0.4,0.4,1,0.9,1.6,1.3
					c0,0-0.1,0.1-0.1,0.1c-0.3,0.3-0.9,0.9-1.4,1.9c-1.8,1.3-3,3.6-3,6.7c0,4.1,3.8,6,6.8,7.5c0.2,0.1,0.4,0.2,0.7,0.3
					c0,0,0.1,0,0.1,0c0.1,0.3,0.7,2-0.4,7.1c0,0,0,0.1,0,0.1c-0.3,1.6-0.6,2.9-0.7,3.6c-1.5,1.5-4.4,4.5-4.4,8.4
					c0,3,1.2,4.9,2.7,6.2l-0.2,0.2c-2.1,2.1-3.8,3.8-5.2,8c-0.2,0.5-0.3,1-0.3,1.6c0,1.8,0,4,0.8,6c-0.7,1.5-1,3.4-0.6,5.3
					c0.3,1.4,1,2.5,2,3.5c0.2,1.2,0.7,2.2,1.2,3.1c-0.4,0.4-0.7,0.8-1,1.3c-5.7,3-10.2,10.7-11.5,13c-1.1,1.4-2.1,3.1-3,5
					c-1.9,2-7.9,8.6-7.9,12.9c0,0.9,0,2.5,0.8,4.2c0,2.8,1.4,6.9,7.4,10.9c0.2,0.1,0.4,0.2,0.5,0.3c2.1,1,4.2,1.6,6.2,1.6
					c2.6,0,5-0.8,7.3-2.5c3,1.6,6.6,2.3,8.9,2.4c-0.1,0.3-0.1,0.7-0.1,1c0,2.4,0,5.1,2.5,7.5c0.4,0.4,0.8,0.7,1.3,0.9
					c1.1,0.5,2.2,0.8,3.3,0.8c3.4,0,5.3-2.3,6.2-3.4c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.1,0.2-0.2c0.5-0.2,1.1-0.3,1.5-0.4
					c5-0.1,8.2-3.3,9.4-4.5c0.2-0.2,0.4-0.5,0.6-0.8c1.1-1.6,3-3.2,4-3.9c4.4-0.7,9.6-4.8,12.1-7.9c4.7-0.1,7.5-2.1,8.8-4.7
					c0.2,0.2,0.7,0.5,1.4,1.3l2.1,2.1c0.5,1.2,1.4,2.3,2.5,3v1.3c0,1.3,0.4,2.5,0.8,3.6c0.1,0.2,0.1,0.4,0.2,0.6
					c-0.1,0.3-0.2,0.6-0.3,0.8c-0.8,2-1.9,4.7-0.1,8.2c1.5,3,4.7,5,8.9,5.6c0.4,0.3,0.7,0.5,0.9,0.6c0.1,0,0.1,0.1,0.2,0.1
					c0.9,0.9,2.2,1.4,3.5,1.4c0.4,0,0.7,0,1.1-0.1c-0.9,3.6-0.6,5.7-0.1,7.5v0.8c-0.6,2.1-1.4,5.8,1,9.5c-0.1,3-0.4,6.1-0.8,9.1
					c-0.1,0.3-0.2,0.6-0.3,0.8c-0.8,2.4-1.8,5.2-0.8,8.3c0,1,0.1,2.2,0.5,3.4c0.3,1,0.9,1.9,1.6,2.6c0.1,1.1,0.2,2.3,0.5,3.5
					c-0.3,0.7-0.5,1.5-0.5,2.6c0,2.5,0.9,4.5,2.2,5.9c0.5,2.9,2.2,4.2,3.7,4.7c1.3,0.8,2.8,1.1,4.5,1.1c1.5,0,3.3-0.3,5.8-0.9
					c0.5-0.1,0.9-0.3,1.3-0.6c0.9,0.1,2.3,0.4,3.2,0.5c1,0.3,2,0.7,2.7,0.9c1.2,3.8,4.9,5.6,6.7,6.4c0.2,0.1,0.5,0.2,0.7,0.3
					c0.3,0.2,0.7,0.3,1,0.4c1,0.9,1.9,1.3,2.5,1.6c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.2,0.2,0.3,0.3c1.9,3.4,4.3,5.1,7.1,5.1
					c0.8,0,1.5-0.1,2.1-0.3c1.9,1,4.2,2,7.3,3.1c0.5,0.2,1,0.3,1.6,0.3c0,0,0,0,0.1,0.1c0.3,2.8,1.7,4.6,3.1,5.8
					c0.5,2.2,1.9,4,4.1,5.1c1.6,0.8,3.5,1.2,5.7,1.5c0.6,0.1,1.3,0.2,2.1,0.4c0.3,0.1,0.7,0.1,1,0.1c0.2,0,0.6,0,1,0
					c1.2,1.2,3.3,2.8,6.6,3c1.3,2,2.9,3.3,5.2,4.5c0.7,0.3,1.5,0.5,2.2,0.5h0.6c0.7,0.3,1.4,0.4,2.2,0.4c1,0,1.9-0.3,2.4-0.6
					c0.2,0,0.3-0.1,0.5-0.1c0.9,0.3,1.7,0.4,2.6,0.4c0.9,0,1.7-0.1,2.5-0.4c0.9,0.8,2.1,1.3,3.3,1.3h3c1.3,0,2.3-0.4,3.1-0.8
					c0,0,0.1,0,0.1,0c0.7,0.1,1.6,0.3,2.5,0.3c0.4,0,0.8,0,1.2-0.1c1.4,1.3,3.1,2.1,4.6,2.5c1.6,0.8,3.5,1.6,5.8,1.6
					c1.4,0,2.7-0.3,4-0.9c0.5-0.2,0.9-0.6,1.3-0.9c0.7-0.7,1.3-1.5,1.8-2.4c1.1-0.1,2.2-0.5,3.1-1c2.7,0.9,5.5,0.9,7.6,0.9h5
					c1.3,0,4.1,0,6.5-2.5c2.5-2.5,2.5-5.2,2.5-6.5c0-0.5-0.1-1-0.2-1.5c0.2-1.2,0.9-3.8,1.3-5.4c0.1-0.3,0.1-0.5,0.2-0.8
					c4.7-0.2,6.6-4.2,7.3-6.2c2.5-4.2,2-8.9-1.4-13.9c-0.2-0.3-0.4-0.5-0.6-0.7c0-0.1-0.1-0.2-0.1-0.3c1-1.4,2-3.2,2.4-5
					c0.1-0.1,0.1-0.2,0.2-0.2c1.9-1.3,3.9-3.6,3.9-8c0-2.1-0.6-3.5-1.3-4.5c0.6-1,1-2,1.2-3c0.4-0.9,0.7-1.7,0.9-2.4
					c0.2-0.7,0.4-1.2,0.7-1.8c0.1-0.3,0.2-0.5,0.3-0.8c3-2.5,4-7,4.2-9.5c1.1-1.1,1.7-2.5,2-3.9c0.3-0.5,0.9-1.3,1.4-2
					c2.9,0.1,7.7,0.6,14.6,1.8l3.8,1c0.4,0.1,0.8,0.1,1.2,0.1c4.5,0,6.4-2.7,6.9-5.6l0.6-1.2c0.2-0.5,0.4-1,0.5-1.5c0,0,0,0,0,0
					c0.3,0.1,0.7,0.1,1.1,0.2c2.3,0.5,4.1,0.8,5.9,0.8c2.5,0,4.6-0.6,6.9-1.9c0.8-0.2,1.4-0.5,1.9-0.8c0,0,0.1,0,0.1,0
					c0.1,0,0.3,0,0.4-0.1c1.5-0.4,4.4-1.4,6.2-4.1c0.5,0.1,1,0.2,1.6,0.2c4.2,0,5.9-1.9,6.5-3.7c2-2.8,2.1-6.6,0.5-11.3v-8.6
					c0.1-0.5,0.2-1,0.3-1.5c0.5-2.2,0.9-3.9,0.6-5.8c-0.1-1-0.6-2.5-1.8-3.9c0.3-1.3,0.7-2.3,0.9-3.1c1.9-0.7,3.5-1.9,4.3-3.5
					l2.2-2.2c1.4-1.4,5.6-6,6.3-11.7c0.2-0.3,0.3-0.6,0.5-0.9c1.8-1.7,3.2-4.4,3.2-8.5c1.4,2.7,4.1,5.7,7.7,6.4
					c0.8,0.4,1.7,0.6,2.6,0.6c0.6,0,1.1-0.1,1.6-0.2c1.3,1.1,3.2,1.8,5.5,1.8c0.5,0,1.8-0.1,3.2-0.8c0.1,0,0.2,0,0.2,0
					c3,0,4.9-1.4,6-2.7c0.2,0.1,0.5,0.2,0.7,0.2c1.5,2.9,3.7,4.3,6.9,4.3c1.3,0,2.6-0.5,3.5-1.5c0.2-0.2,0.5-0.5,0.7-0.7
					c0.9-0.2,1.7-0.7,2.3-1.3c1.8-1.8,2.3-3.7,2.4-4.8c0.3-0.9,1.6-3.2,2.6-4.2c0.2-0.2,0.4-0.5,0.6-0.8c2.3-3.5,3-5.9,2.2-9.3
					c0.3-0.5,0.5-1.1,0.6-1.7c0,0,0-0.1,0-0.1c0.4-0.8,1-2.1,1-3.7c0-2.4-1.4-4.3-2.1-5.2c-0.1-0.4-0.2-0.7-0.4-1
					c-0.3-0.5-0.4-1.2-0.6-2c-0.3-1.1-0.6-2.5-1.4-4c-0.1-0.2-0.3-0.5-0.4-0.7c0-0.1,0-0.2,0-0.3c1-3.5,1-6.7,1-8.7
					c0-0.7,0-1.5,0-1.9c0.2-0.2,0.3-0.3,0.5-0.5c1.7-1.7,2.9-3.5,3.6-5.5c1.9,0,3.4-0.3,4.6-0.8c0.3,0.2,0.5,0.4,0.8,0.7
					c0.3,0.3,0.7,0.6,0.9,0.7c0.9,0.9,2.2,1.5,3.5,1.5c3.4,0,6.1-1.9,7.3-5c0.6-1.5,0.7-3,0.7-4.2c0.3-1.1,0.7-2.7,0.6-4.5
					c1.7-0.5,3.7-1.2,5.5-2.2c2.2,2.3,5.2,3.9,7.8,3.9c4.2,0,5.9-2,6.5-3.7c0.6-0.9,1-1.8,1.2-2.6c0.7-0.2,1.3-0.6,1.8-1.2
					c0.1-0.1,0.5-0.4,0.7-0.5c0.6-0.5,1.4-1.1,2.1-1.9c1.5,0.8,2.9,1,3.8,1c1.5,0,2.6-0.4,3.1-0.7c2.6-1.3,5.2-4,4.1-9.7
					c1.6-2.4,1.6-5.2,1.6-6.8c0-1.3-0.5-2.6-1.5-3.5c-0.6-0.6-1.3-1.3-2.2-2c-0.1-0.2-0.2-0.3-0.3-0.5V276c0.7-0.7,1.4-1.7,2.1-2.8
					c2.9-2.9,3.9-4.1,3.9-7c0-1.1-0.3-2.2-0.7-3.1c0.4-0.2,0.7-0.5,1-0.7c2.2-1.2,3.7-3.6,3.7-6.1c0-1.1,0-2.2-0.2-3.3
					c0.3-0.2,0.7-0.5,1-0.8c3.1-0.3,8.2-1,11.9-3c0.1,0,0.1,0.1,0.2,0.1c1.2,1,2.8,1.6,4.5,1.6c1.3,0,2.6-0.3,3.9-1
					c0.5-0.2,0.9-0.6,1.3-0.9c1.1-1.1,1.6-2.2,1.9-2.8c0-0.1,0.1-0.2,0.1-0.3c0.4-0.4,0.7-0.9,0.9-1.4c0.2-0.2,0.6-0.5,1.2-0.9l3-2
					c0.3-0.2,0.5-0.4,0.8-0.6c2.5-2.5,2.5-5.2,2.5-6.5c0-1-0.3-2-0.8-2.8l-1.4-2.2c-0.1-0.5-0.3-0.9-0.5-1.2
					c0.6-3.5-0.9-7.4-2.5-11.4c-1.7-6.2-4.9-7.2-10.1-8.2l-0.7-0.1c-0.3-0.1-0.7-0.1-1-0.1c-0.1,0-0.2,0-0.3,0c0,0-0.1-0.1-0.1-0.1
					c0.8-1.3,1.4-2.9,1.4-4.9c0-2.1-0.6-3.8-1.4-5c0.1-0.3,0.2-0.6,0.3-0.9c1.5-1,3.6-2.7,6.4-5.3c3.9-0.9,7.9-3.9,9.7-6.8
					c1.4-0.1,2.9-0.4,4.9-1.3c6.1-2.2,6.1-6.5,6.1-9.6c0-1.8,0-6.2-2.6-9.2c-0.1-0.7-0.2-1.4-0.3-1.8c0-0.1-0.1-0.2-0.1-0.3
					c-0.1-1.2-0.6-2.4-1.5-3.2c-0.6-0.6-1.3-1.3-2.2-1.9c-0.3-2.6-1.8-5.1-4.4-6.6c-0.2-0.2-0.4-0.4-0.6-0.5
					c-0.1-0.1-0.3-0.2-0.4-0.3c-0.1-0.5-0.2-0.9-0.4-1.3l-1-2c-0.6-1.3-1.8-2.2-3.1-2.6c-1.1-0.7-2.6-1.2-4.3-1.2
					c-1,0-2,0.3-2.8,0.8l-3,2c-0.3,0.2-0.5,0.4-0.8,0.6c-0.2,0.2-0.4,0.4-0.6,0.6c-2.2,0.2-3.7,1.1-4.9,1.8
					c-0.3,0.2-0.6,0.3-0.9,0.5c-0.4,0.1-0.7,0.2-1.1,0.3v-0.7c0-2.2-0.6-3.7-1-4.6c0,0,0-0.1,0-0.1c-0.1-0.5-0.2-1.1-0.5-1.5
					c0.5,0.2,1,0.2,1.5,0.2c4.2,0,7.2-3.2,7.9-6.6c1.3-3,1.2-5.5-0.4-8.6c-0.2-0.5-0.6-0.9-0.9-1.3c-0.7-0.7-1.5-1.5-2.6-2.2
					c-0.2-0.4-0.3-0.8-0.5-1c-0.6-1-2.1-2.7-4.9-3.1c-0.8-0.3-1.6-0.5-2.5-0.5c-2.9,0-5.5,1.9-6.5,4.6c-1.1,0.2-2.3,0.7-3.5,1.7
					c-1.8,1.1-3,2.7-3.4,4.6c-0.2,0-0.3-0.1-0.5-0.1l-0.7-0.2c-0.2-0.1-0.4-0.1-0.6-0.1c-2.5-0.4-4.5-0.7-6.4-0.7
					c-3,0-5.4,0.7-8.8,1.9c-5.1,1.3-8,2.4-9.7,3.8c-4.4,0.3-7.3,2.3-10.3,5.3c-5.4,4.5-5.4,4.5-8.9,6.2c-0.4,0.2-0.7,0.4-1,0.7
					c-0.8-0.4-1.6-0.8-2.7-1c0,0,0,0,0,0c-0.9-0.7-1.9-1.5-2.9-2.9c-0.2-0.3-0.4-0.5-0.6-0.8l-1.1-1.1c-0.9-2-2.9-3.1-4.9-3.3
					l-7.5-1.1c0-7.8-7.6-9.1-12.9-9.8c-3.5-1.1-6-1.6-8.4-1.6c-2.3,0-4.5,0.5-6.9,1.5c-1.6,0-3.5-0.1-5.3-0.7
					c-0.3-0.1-0.6-0.2-1-0.2l-8-1c-0.2,0-0.4,0-0.6,0h-1h-3c-0.9,0-3.4,0-6.2,0.6c1.6-1.1,2.5-2.7,2.9-4c0.2-0.5,0.3-1,0.3-1.6
					c0-1.7-0.6-3-1-3.7c0,0,0-0.1,0-0.1c-0.1-0.9-0.5-1.7-1.1-2.4c-0.1-0.1-0.1-0.3-0.2-0.4c-0.6-1.2-1.5-3.1-3.7-5.5
					c-3.3-5.4-4.3-6.4-9.8-7.8c-0.1,0-0.3-0.1-0.4-0.1c-1.2-0.2-2.3-0.3-3.4-0.3c-1.2-2.4-3.4-6.8-8.5-6.8h-1c-0.3,0-0.6,0-0.9,0
					c1.1-0.6,3-1.3,5.2-1.3c0.6,0,1.2,0.1,1.7,0.2c0.3,0.1,0.7,0.1,1,0.1c3.9,0,7.3-0.2,9.5-2.5c0.1-0.1,0.1-0.2,0.2-0.2
					c2.7-1.1,4.3-2.7,4.9-4.9c1.7-0.7,2.9-2,3.9-2.9c0.8-0.8,1.5-1.7,2-2.8c1.6,0.9,3.5,1.6,5.9,2.1l5.4,2.7
					c0.4,0.2,0.8,0.4,1.2,0.6c1.4,0.8,3.3,1.9,6.1,1.9h1c6.3,0,10.5-3.5,13.5-6.5c0.2-0.2,0.4-0.5,0.6-0.8l1.8-2.7
					c2.6-2.9,2.4-6.8-0.4-9.6l-0.1-0.1c-0.1-0.3-0.3-0.5-0.4-0.7c-0.1-1.2-0.6-2.3-1.4-3.1c-2.5-2.5-5.2-2.5-6.5-2.5
					c-1.5,0-3.9,0-6.3,0.6c-0.1-0.1-0.1-0.1-0.2-0.2c-1.7-1.7-3.7-2.6-5.9-2.6c-2,0-3.8,0.7-5.9,1.7c0,0-0.1-0.1-0.1-0.1
					c-1.8-1.8-3.7-2.2-5-2.2c-1,0-2.4,0.2-3.8,1.2c-0.7,0.3-1.3,0.6-1.8,1.1c-1.1-0.7-2.4-1.3-3.7-2c-0.3-0.2-0.7-0.3-1-0.4
					c-0.8-0.2-1.5-0.4-2.2-0.6c-1.8-0.5-3.4-1-5.2-1c-0.9,0-1.8,0.1-2.7,0.4c0,0,0,0-0.1,0c-2.4-2.3-5.6-4.4-7.8-5.5
					C364.5,50.4,363.8,50.2,363,50.2L363,50.2z M329,95.2h-2c-1.3,0-2.6,0.5-3.5,1.5c-2.5,2.5-2.5,5.2-2.5,7.5
					c0,0.5,0.1,1.1,0.3,1.6c0.2,0.7,1.5,4.1,4.3,6.2c0.3,0.5,0.9,1.4,1.9,2.1c1.8,2.7,4,4.1,6.5,4.1c1.3,0,4.1,0,6.5-2.5
					c0.2-0.2,0.4-0.5,0.6-0.8c3.4-5.1,0.8-9.4-0.2-11.2c-0.2-0.3-0.3-0.6-0.4-0.8c-0.2-0.5-0.6-0.9-0.9-1.3
					C336,98.1,332.8,95.2,329,95.2L329,95.2z M315.5,117c-1.5,0-3,0.4-4.3,1.2c-1,0.2-2,0.6-2.8,1.4c-2.5,2.5-2.5,6-2.5,7.5
					c0,0.8,0.2,1.5,0.5,2.2c1.9,3.8,4.4,5.8,7.5,5.8h1c4.1,0,6.3-2.3,7.5-3.5c0.9-0.9,1.5-2.2,1.5-3.5v-2v-3c0-1.9-1.1-3.6-2.8-4.5
					C319.8,118,317.9,117,315.5,117L315.5,117z"/>
			</g>
			<g id="st1">
				<path id="st2" d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,50.7c-2.1,0-6.4,0-8.7,3.9c-0.2,0.3-0.4,0.5-0.6,0.8c-1.6-1.4-3.7-2.5-6.4-2.5
					c-1.5,0-3.2,0.4-5,1.1c-0.1,0-0.2,0.1-0.3,0.2c-4.6,2.3-6.8,5.5-7.8,8.6c-0.9-0.6-1.9-1-3.2-1h-1c-0.5,0-1,0.1-1.4,0.2
					c-1.5,0.5-3.6,1.8-5,5.2c-1.3,3.1-1.9,7.8-1,10.9c0.4,4,3.3,6.6,7.5,6.6h1c1.2,0,2.2-0.2,3.1-0.6c0.7,1.3,1.6,2.5,2.7,3.9
					l0.6,0.8c0,0,0.1,0.1,0.1,0.1c3.3,4.1,5.4,6.7,8.5,7.5c1.3,1.1,2.9,2.2,5.1,2.2c1.7,0,3.3-0.4,4.9-1.2c-0.4,0.7-0.7,1.3-0.9,1.7
					c-0.3,0.6-0.5,1.3-0.5,2c0,1.4,0.1,2.9,1.1,5.1c0.3,2.5,1.6,4.4,3.5,5.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.3-0.5,0.4
					c-1,0.8-3.1,2.5-3.7,5.2c-0.8,1.6-0.8,3.4,0,5.1c0.6,1.2,1.4,2,2.1,2.7c0.8,0.8,2,1.3,3.2,1.3h1c0.8,0,1.8-0.2,2.7-0.5
					c-0.7,0.8-1.1,1.8-1.2,2.8c0,0.1,0,0.1,0,0.2c-0.1,0.5-0.2,1.1-0.2,2c-4.2,1.8-6.7,7.2-7.6,10.8c-0.4,1.1-0.5,2.2-0.6,3.3
					c0,0-0.1,0-0.1-0.1c-0.6-0.3-1.3-0.5-2-0.5c-4.7,0-8,3-8.5,7.5c-0.3,1-0.5,1.9-0.4,2.9c-0.5-0.1-0.9-0.2-1-0.3
					c-1.4-0.4-2.6-0.5-3.8-0.5c-3.2,0-5.2,1.4-7.2,2.7c-0.2,0.1-0.4,0.3-0.7,0.4c-0.2,0.2-0.5,0.4-0.7,0.6l-1.8,1.8
					c-0.7,0.1-1.4,0.3-2.2,0.7c-0.3-0.2-0.6-0.5-1.2-1c-0.5-0.9-1-1.6-1.4-2.3c-0.3-0.4-0.6-0.8-0.8-1.3c-0.5-4.2-3.5-7.2-7.4-7.2
					c-5.2,0-7.7,6-10.3,16.2c-0.7,2.1-1,3.8-1.1,5.3c-0.6-0.2-1.3-0.3-2-0.3c-0.2,0-0.5,0-0.7,0l-0.9-1.8c-0.4-0.7-0.9-1.3-1.5-1.7
					c0-0.1,0-0.2,0-0.3c0-0.7-0.2-1.4-0.5-2c-0.1-0.2-0.3-0.9-0.4-1.3c0,0,0-0.1,0-0.1c0.6-0.5,1.2-1,1.6-1.4c0.8-0.8,1.3-2,1.3-3.2
					c0-0.9-0.2-1.9-0.6-2.9c1.9-0.6,2.8-1.9,3.2-3c2.3-3.3,0.6-6.9-0.1-8.1c-0.6-1.2-1.7-2.1-3-2.4c-0.5-0.3-1.3-0.6-2.3-0.6
					c-0.4,0-0.7,0-1.1,0.1c-0.3-0.3-0.7-0.7-1-0.9c0.4-1.2,0.7-2.4,0.8-3.6c0.2-0.6,0.3-1.1,0.4-1.6c0.7-2.4,1.7-5.6-1.4-8.9
					c0-0.1,0-0.1,0-0.1c0-3.7-2.6-5.8-3.8-6.8c-0.2-0.1-0.4-0.3-0.5-0.4c-0.4-0.4-1-0.8-1.5-1c-0.2-0.1-0.3-0.3-0.5-0.4
					c-2.2-3.1-5.5-4.9-9.5-4.9c-1.9,0-4,0.4-6.2,1.3c-0.3,0.1-0.6,0.2-0.9,0.2c0,0,0,0-0.1,0c-1-1.9-2.4-3.4-3.8-4.5
					c0-1.1-0.4-2.4-1.5-3.9c-0.2-0.6-0.6-1.2-1.1-1.7c-2-2-5.2-3.3-8.2-3.3c-2.3,0-4.9,0-7.2,2.3c-0.3,0.3-0.5,0.5-0.7,0.8
					c-3.2,0.6-5.1,2.8-5.6,5.1c-0.9,2.3-0.5,4.5,1.2,6.5c-0.3,1.2-0.3,2.5-0.3,3.7c0,0.1,0,0.3,0,0.5c-1.7,0.1-3.6,1-5.1,2H240
					c-0.2,0-0.4,0-0.7,0l-0.2-0.5c-0.1-0.2-0.2-0.3-0.3-0.5c-2.4-3.6-5.3-5.5-9.1-5.9c0.3-1.5,0.8-4.5-1.5-6.8
					c-1.6-1.6-3.3-3-8.7-4.1c-1.8-0.9-3.8-1.9-6.6-2.8c-0.9-0.6-1.9-1-2.8-1.5c3.4-0.4,5.7-2.7,6.2-6c1.3-2.8,0.1-6.3-2.2-8.6
					c-0.8-0.8-2-1.3-3.2-1.3h-2c-1.9,0-5.7,1.3-7.3,6.1c-0.2,0.5-0.2,0.9-0.2,1.4c0,2.7,1,5.1,2.7,6.6c-0.8-0.1-1.5-0.2-2.2-0.2
					c-1.8,0-3.5,0.3-5,1h-0.2c-0.1,0-0.1,0-0.2,0c0-0.6,0-1.1,0-1.5c0-0.9-0.2-1.6-0.5-2.3c2.4-1.8,4.2-3.9,4.4-7.7
					c0.7-2.1,0.5-4.3-0.3-6.1c0.2-0.5,0.3-1.1,0.3-1.8c0-0.7-0.2-3-2.3-5.2c-0.5-0.5-2.5-2.3-5.2-2.3c-1.2,0-2.3,0.5-3.2,1.3
					l-0.2,0.2c-0.3,0.1-0.6,0.3-0.8,0.5c-1.6,0-4.1,0.1-6.3,1.5c-0.7-0.3-1.6-0.5-2.5-0.5c-3.5,0-7.5,3.1-7.5,7.5
					c0,2.1,0.6,4.3,1.8,5.9c-0.6,0.3-1.1,0.6-1.7,1.1c-1.7,0.8-2.9,2.4-3.6,4.1c-0.5,0.3-1.1,0.6-1.7,1.1c-2.9,1.7-4.3,4.5-5.4,6.7
					c-0.3,0.6-0.5,1.3-0.5,2c0,1.7,0,3.6,1,5.4v1.6c-1.1,2.7-2,6.6-2,9c0,0.5,0,0.9-0.1,1.4c-0.2,2.1-0.4,5.4,2.8,8.3
					c0.1,0.2,0.3,0.4,0.4,0.6c0.2,1.2,0.6,2.2,1,3.1c-2.7,4.6-3.1,13.5-3.1,13.6c0,0.8,0.2,1.5,0.4,2c-1,0.4-2.1,0.5-3.9,0.5
					c-0.2,0-0.5,0-0.7,0.1c-5.6,0.9-10.2,2.1-13.3,6.7c-3.9,0.1-7.1,2.5-9.2,4.6c-0.8,0.8-1.3,2-1.3,3.2v2.2
					c-0.2-0.1-0.3-0.2-0.5-0.3c-0.6-0.3-1.3-0.5-2-0.5c-3.8,0-7.3,0.1-9.9,1h-3.4c-0.2-0.1-0.4-0.1-0.5-0.2
					c-1.8-0.6-3.5-1.2-5.4-1.2c-1.3,0-2.6,0.3-3.8,0.9c-3.1,1.6-4,4.6-4.5,6.6c-0.2,0.5-0.3,1.2-0.5,1.4c-0.3,0.6-0.5,1.3-0.5,2
					c0,3.2,0,5.7,1.2,9.4c0.1,0.3,0.2,0.6,0.4,0.9c-0.1,0.7-0.3,1.7-0.5,2.8c-0.1,0.3-0.1,0.6-0.1,0.9c0,1.2-0.9,4-1.7,6.3
					c-1,2.1-2.1,5.2-2.3,7.9c-0.3,0.2-0.5,0.4-0.7,0.6c0,0-3.3,3.5-4.1,6.7c-0.1,0.3-0.3,0.6-0.4,0.8c-0.1,0.1-0.1,0.3-0.2,0.4
					c-0.7,0.2-1.3,0.5-1.7,0.7c0,0-0.1,0-0.1,0c-1.8,0.1-3,0.6-3.8,1c-0.1,0-0.1,0-0.2,0.1c-1,0.1-1.9,0.6-2.6,1.3
					c-0.1,0.1-0.3,0.3-0.5,0.4c-1,0.8-3,2.4-3.6,5.1c-0.6,1.1-0.9,2.4-0.9,3.7c0,1.4,0.4,2.7,1.1,3.9c0,0.1,0,0.1,0.1,0.2
					c0,0.1-0.1,0.3-0.1,0.5c-0.2,0.5-0.2,0.9-0.2,1.4v1c-3.5,2.7-4,5.4-4.8,10.3l-0.1,0.8c-1.1,4.5-1.1,9.7-1.1,11.9
					c0,0.2,0,0.5,0.1,0.7c-0.6,0.2-1.3,0.4-2.1,0.8c-4.6,0.5-6.4,3.4-7.5,6.3c-0.5,0.4-1,0.8-1.4,1.2c-2.6,1.3-4.1,4.5-4.1,6.9
					c0,2.5,1.7,5.6,3.3,7.2c0.1,0.1,0.2,0.2,0.3,0.4c0.9,1,2.5,3,5.5,3.7c0.1,0.3,0.2,0.5,0.3,0.8c1.6,3.2,6.1,6.3,8.3,7.6
					c0.6,0.5,1.2,1.1,2.1,1.7c-0.2,0.2-0.4,0.3-0.6,0.5c-0.3,0.3-0.9,0.9-1.4,1.9c-1.7,1.2-2.9,3.3-2.9,6.3c0,3.8,3.4,5.5,6.5,7
					c0.2,0.1,0.4,0.2,0.6,0.2c0.3,0.1,0.4,0.1,0.5,0.6c0.2,0.8,0.5,2.8-0.5,7.2c0,0,0,0.1,0,0.1c-0.3,1.7-0.6,3.1-0.8,3.7
					c-1.4,1.4-4.3,4.3-4.3,8.2c0,3,1.4,5,2.9,6.2l-0.6,0.6c-2.2,2.2-3.8,3.8-5.1,7.8c-0.2,0.5-0.2,0.9-0.2,1.4c0,1.9,0,4,0.8,6
					c-0.7,1.5-1.1,3.3-0.6,5.2c0.3,1.3,1,2.5,1.9,3.4c0.2,1.4,0.7,2.5,1.4,3.4c-0.5,0.5-1,1-1.3,1.6c-5.6,2.9-10.2,10.7-11.4,12.9
					c-1.1,1.3-2.1,3.1-3,5c-1.8,1.9-7.8,8.5-7.8,12.6c0,0.9,0,2.4,0.8,4.1c-0.1,3.7,2.5,7.5,7.2,10.7c0.2,0.1,0.3,0.2,0.5,0.3
					c2,1,4,1.5,5.9,1.5c2.6,0,5-0.9,7.3-2.6c3.2,1.8,7.5,2.5,9.6,2.6c-0.2,0.5-0.3,1-0.3,1.5c0,2.3,0,4.9,2.3,7.2
					c0.3,0.3,0.7,0.6,1.2,0.8c1,0.5,2,0.7,3.1,0.7c3.1,0,4.9-2.2,5.8-3.2c0.1-0.1,0.3-0.3,0.3-0.4c0.1-0.1,0.1-0.2,0.2-0.2
					c0.5-0.2,1.3-0.4,1.7-0.4c4.8-0.1,7.9-3.1,9-4.3c0.2-0.2,0.4-0.4,0.6-0.7c1.1-1.7,3.3-3.5,4.2-4.1c4.3-0.6,9.6-4.8,12.1-7.9
					c4.9,0,7.6-2.2,8.7-4.9c0.1,0,0.1,0,0.2,0c0.3,0.1,1,0.6,1.9,1.5l2.1,2.1c0.5,1.2,1.4,2.3,2.5,3.1v1.6c0,1.2,0.4,2.4,0.7,3.4
					c0.1,0.3,0.2,0.6,0.3,0.8c-0.1,0.4-0.3,0.7-0.4,1c-0.8,1.9-1.8,4.5-0.1,7.8c1.4,2.9,4.6,4.8,8.6,5.3c0.4,0.3,0.7,0.5,1,0.7
					c0.1,0.1,0.2,0.2,0.3,0.2c0.8,0.8,2,1.3,3.2,1.3c0.6,0,1.2-0.1,1.8-0.2c0,0.2-0.1,0.4-0.2,0.6c-0.9,3.6-0.6,5.7-0.1,7.4
					c0,0,0,0,0,0v1c-0.8,2.8-1.2,6.1,1,9.3c-0.1,3.1-0.4,6.3-0.9,9.3c-0.1,0.3-0.2,0.6-0.3,0.9c-0.8,2.4-1.8,5.1-0.8,8.1
					c0,1,0.1,2.2,0.5,3.3c0.3,1,0.9,1.9,1.6,2.6c0.1,1.2,0.2,2.5,0.5,3.8c-0.3,0.7-0.6,1.5-0.6,2.5c0,2.5,0.9,4.4,2.1,5.6
					c0.4,2.8,2,4,3.4,4.5c1.2,0.7,2.6,1.1,4.3,1.1c1.5,0,3.3-0.3,5.7-0.9c0.5-0.1,0.9-0.3,1.3-0.6c0.9,0.1,2.5,0.4,3.4,0.6
					c1.2,0.4,2.2,0.7,3,1c1.1,3.8,4.6,5.5,6.5,6.3c0.2,0.1,0.5,0.2,0.7,0.3c0.3,0.2,0.7,0.3,1.1,0.4c0.9,0.9,1.8,1.3,2.4,1.6
					c0.1,0.1,0.3,0.1,0.4,0.2c0.1,0.1,0.3,0.3,0.4,0.4c1.8,3.3,4,4.9,6.7,4.9c0.8,0,1.6-0.2,2.2-0.4c2.1,1,4.2,2.1,7.4,3.2
					c0.5,0.2,0.9,0.2,1.4,0.2c0.1,0,0.2,0,0.3,0c0.1,0.1,0.2,0.3,0.3,0.4c0.2,2.8,1.7,4.6,3.1,5.7c0.9,4.9,6,5.8,9.4,6.4
					c0.6,0.1,1.3,0.2,2.1,0.4c0.3,0.1,0.6,0.1,0.9,0.1c0.2,0,0.8,0,1.2,0.1c1.3,1.2,3.2,2.9,6.6,2.9c1.3,2,2.8,3.4,5.2,4.5
					c0.6,0.3,1.3,0.5,2,0.5h0.7c0.7,0.3,1.3,0.4,2.1,0.4c1,0,1.7-0.3,2.3-0.5c0.2,0,0.4-0.1,0.6-0.2c0.9,0.3,1.7,0.4,2.6,0.4
					c0.9,0,1.8-0.2,2.6-0.5c0,0,0,0,0,0c0.8,0.8,2,1.3,3.2,1.3h3c1.3,0,2.3-0.4,3-0.9c0.1,0,0.2,0,0.3,0c0.7,0.1,1.5,0.3,2.4,0.3
					c0.5,0,0.9,0,1.3-0.1c1.4,1.3,3.1,2.2,4.6,2.5c1.6,0.8,3.4,1.6,5.6,1.6c1.3,0,2.6-0.3,3.8-0.9c0.4-0.2,0.8-0.5,1.2-0.8
					c0.7-0.7,1.3-1.6,1.8-2.5c1.2-0.1,2.3-0.5,3.4-1.1c2.6,0.9,5.6,0.9,7.6,0.9h5c1.3,0,3.9,0,6.2-2.3c2.3-2.3,2.3-4.9,2.3-6.2
					c0-0.5-0.1-1-0.2-1.5c0.2-1.2,0.9-3.9,1.3-5.5c0.1-0.4,0.2-0.8,0.3-1.1c0,0,0,0,0,0c4.7,0,6.5-4,7.2-5.9c2.4-4,1.9-8.5-1.3-13.4
					c-0.2-0.3-0.4-0.5-0.6-0.7c-0.1-0.2-0.1-0.5-0.2-0.7c1-1.4,2.2-3.3,2.5-5.1c0.1-0.1,0.2-0.2,0.4-0.4c2.5-1.6,3.7-4.1,3.7-7.6
					c0-2.1-0.7-3.5-1.4-4.5c0.7-1,1.1-2.1,1.3-3.2c0.4-0.9,0.7-1.7,0.9-2.3c0.2-0.7,0.4-1.2,0.8-1.9c0.1-0.3,0.2-0.6,0.3-0.9
					c3-2.4,4-7,4.1-9.4c1.1-1,1.8-2.4,2-3.8c0.3-0.6,1-1.6,1.6-2.4c2.8,0.1,7.8,0.5,15,1.9l3.9,1c0.4,0.1,0.7,0.1,1.1,0.1
					c4.2,0,6-2.5,6.4-5.3l0.6-1.2c0.2-0.5,0.4-0.9,0.4-1.4c0-0.1,0-0.1,0.1-0.2c0-0.1,0.1-0.2,0.1-0.3c0.5,0.1,1,0.2,1.5,0.3
					c2.2,0.4,4.1,0.7,5.8,0.7c2.4,0,4.5-0.6,6.7-1.9c0.8-0.2,1.4-0.5,1.9-0.7c0,0,0.1,0,0.1-0.1c0.1,0,0.3-0.1,0.4-0.1
					c1.5-0.4,4.4-1.4,6.1-4.2c0.6,0.2,1.2,0.3,1.8,0.3c3.9,0,5.5-1.8,6.1-3.4c1.9-2.7,2.1-6.4,0.4-10.9v-8.7
					c0.1-0.5,0.2-1.1,0.3-1.5c0.6-2.7,1.5-6.8-1.3-9.4c0.4-1.5,0.8-2.6,1.1-3.6c1.9-0.7,3.5-1.8,4.3-3.4l2.3-2.3
					c1.4-1.4,5.5-5.9,6.2-11.5c0.2-0.3,0.4-0.7,0.6-1.1c1.8-1.8,3.3-4.6,3.1-9.1c0.2-0.1,0.4-0.2,0.6-0.3c0.1,0.3,0.2,0.6,0.3,0.9
					c1.3,2.6,4,5.6,7.5,6.3c0.8,0.4,1.6,0.6,2.5,0.6c0.6,0,1.2-0.1,1.8-0.3c1.3,1.1,3.1,1.9,5.4,1.9c0.5,0,1.7-0.1,3.1-0.8
					c0.1,0,0.2,0,0.3,0c3,0,4.8-1.5,5.8-2.8c0.4,0.2,0.8,0.3,1.2,0.4c1.4,2.9,3.5,4.2,6.5,4.2c1.2,0,2.3-0.5,3.2-1.3
					c0.2-0.2,0.6-0.6,0.8-0.8c0.8-0.2,1.6-0.6,2.2-1.2c1.7-1.7,2.2-3.6,2.3-4.6c0.3-0.9,1.6-3.3,2.7-4.4c0.2-0.2,0.4-0.4,0.6-0.7
					c2.3-3.4,2.9-5.7,2.1-9.1c0.3-0.5,0.6-1.1,0.7-1.8c0,0,0-0.1,0.1-0.1c0.4-0.7,1-2,1-3.5c0-2.4-1.4-4.2-2.1-5
					c-0.1-0.4-0.2-0.7-0.4-1.1c-0.3-0.6-0.5-1.3-0.7-2.1c-0.3-1.1-0.6-2.5-1.3-3.9c-0.1-0.3-0.3-0.5-0.5-0.7c0-0.2,0-0.4,0-0.6
					c1-3.4,1-6.7,1-8.6c0-0.8,0-1.7,0-2.2c0.2-0.2,0.4-0.4,0.7-0.7c1.8-1.8,3-3.6,3.7-5.7c0.1,0,0.1,0,0.2,0c2.1,0,3.7-0.4,4.9-0.9
					c0.4,0.3,0.7,0.6,1,0.9c0.3,0.3,0.8,0.6,0.9,0.8c0.8,0.8,2,1.3,3.2,1.3c3.2,0,5.7-1.7,6.8-4.7c0.5-1.5,0.6-3,0.7-4.1
					c0.4-1.5,0.8-3.1,0.6-4.8c1.2-0.3,2.4-0.7,3.5-1.2c1-0.4,1.9-0.8,2.6-1.3c2.2,2.5,5.4,4,7.8,4c3.9,0,5.5-1.8,6.1-3.4
					c0.7-1,1-1.9,1.2-2.8c0.7-0.2,1.4-0.6,1.9-1.1c0.1-0.1,0.5-0.4,0.7-0.6c0.6-0.5,1.4-1.1,2.1-1.9c0.1-0.1,0.2-0.1,0.3-0.2
					c1.5,0.9,2.9,1.1,3.8,1.1c1.4,0,2.4-0.4,2.8-0.6c2.5-1.2,5-3.8,3.8-9.4c1.7-2.3,1.7-5.1,1.7-6.7c0-1.2-0.5-2.3-1.3-3.2
					c-0.6-0.6-1.4-1.4-2.2-2c-0.1-0.2-0.3-0.4-0.4-0.6c0-0.1,0-0.2,0-0.3v-0.4c0.8-0.7,1.5-1.7,2.2-2.9c2.9-2.9,3.8-4,3.8-6.7
					c0-1.2-0.3-2.3-0.9-3.4c0.5-0.3,1-0.5,1.3-0.9c2.1-1.1,3.6-3.3,3.6-5.8c0-1.2,0-2.3-0.2-3.6c0.5-0.3,0.9-0.7,1.3-1.1
					c3.1-0.2,8.4-1,12.1-3.1c0.2,0.1,0.4,0.1,0.5,0.2c0.7,0.6,2.1,1.5,4.2,1.5c1.2,0,2.5-0.3,3.7-0.9c0.4-0.2,0.8-0.5,1.2-0.8
					c1-1,1.5-2,1.8-2.7c0.1-0.1,0.1-0.3,0.2-0.4c0.4-0.4,0.7-0.9,0.9-1.4c0.2-0.2,0.6-0.5,1.4-1.1l3-2c0.2-0.2,0.5-0.4,0.7-0.6
					c2.3-2.3,2.3-4.9,2.3-6.2c0-0.9-0.3-1.8-0.8-2.5l-1.5-2.2c-0.1-0.5-0.3-1-0.5-1.4c0.7-3.4-0.8-7.3-2.4-11.3
					c-1.6-6-4.6-6.9-9.7-7.9l-0.7-0.1c-0.3-0.1-0.6-0.1-0.9-0.1c-0.1,0-0.3,0-0.5,0c-0.1-0.2-0.3-0.4-0.4-0.6
					c0.9-1.2,1.5-2.9,1.5-4.9c0-2.2-0.7-3.8-1.5-5c0.2-0.4,0.3-0.8,0.4-1.2c1.5-1,3.7-2.7,6.6-5.5c4-0.9,8-3.9,9.7-6.8
					c1.5-0.1,3-0.4,5-1.3c5.8-2.1,5.8-6,5.8-9.2c0-1.7,0-6.1-2.6-9c-0.1-0.8-0.2-1.4-0.3-1.9c0-0.1-0.1-0.2-0.1-0.4
					c-0.1-1.1-0.5-2.1-1.3-2.9c-0.6-0.6-1.4-1.4-2.3-2c-0.1-0.8-0.3-1.6-0.6-2.4c-0.7-1.6-2-3.1-3.6-4c-0.2-0.2-0.4-0.4-0.7-0.5
					c-0.2-0.1-0.4-0.3-0.5-0.5c-0.1-0.5-0.2-0.9-0.4-1.3l-1-2c-0.6-1.2-1.7-2-2.9-2.4c-1.1-0.7-2.5-1.1-4.1-1.1
					c-0.9,0-1.8,0.3-2.5,0.8l-3,2c-0.2,0.2-0.5,0.4-0.7,0.6c-0.2,0.2-0.5,0.5-0.7,0.7c-2.2,0.2-3.7,1.1-4.9,1.8
					c-0.3,0.2-0.6,0.4-0.9,0.5c-0.9,0.3-1.4,0.4-1.8,0.4v-1.3c0-2.1-0.6-3.5-1-4.4c0-0.1,0-0.1-0.1-0.2c-0.1-1-0.6-1.9-1.3-2.6
					c0-0.1-0.1-0.2-0.2-0.4c0,0,0,0,0,0c0.3,0.2,0.6,0.4,1,0.5c0.6,0.3,1.3,0.5,2,0.5c3.9,0,6.8-3.1,7.4-6.2
					c1.3-2.9,1.1-5.3-0.3-8.3c-0.2-0.4-0.5-0.8-0.8-1.2c-0.7-0.7-1.6-1.6-2.6-2.2c-0.2-0.4-0.3-0.8-0.5-1.1c-0.6-1-1.9-2.5-4.6-2.9
					c-0.7-0.3-1.5-0.5-2.4-0.5c-2.8,0-5.2,1.9-6.2,4.6c-1.1,0.2-2.3,0.7-3.5,1.7c-1.8,1.1-3,2.9-3.4,4.8c-0.3-0.1-0.7-0.2-1-0.3
					l-0.7-0.2c-0.2,0-0.3-0.1-0.5-0.1c-2.5-0.4-4.5-0.7-6.3-0.7c-2.7,0-4.9,0.6-8.7,1.8c-5.2,1.3-8,2.4-9.6,3.8
					c-4.3,0.2-7.2,2.2-10.2,5.1c-5.4,4.5-5.5,4.6-9,6.3c-0.4,0.2-0.8,0.5-1.2,0.8c0,0,0,0,0,0c-0.8-0.5-1.8-0.9-3-1.2
					c0,0-0.1-0.1-0.1-0.1c-1-0.7-1.9-1.5-3-3.1c-0.2-0.2-0.4-0.5-0.6-0.7l-1.2-1.2c-0.9-1.8-2.7-3-4.5-3.1l-8-1.1c0-0.1,0-0.3,0-0.4
					c0-7.4-7.1-8.6-12.5-9.4c-3.4-1.1-5.9-1.6-8.3-1.6c-2.3,0-4.4,0.5-6.7,1.5c-1.7,0-3.7-0.1-5.6-0.8c-0.3-0.1-0.6-0.2-0.9-0.2
					l-8-1c-0.2,0-0.4,0-0.6,0h-1h-3c-1.1,0-4.2,0-7.7,1c-0.5,0-0.9,0-1.5-0.1c3.3-0.9,4.7-3.1,5.4-5c0.2-0.5,0.2-0.9,0.2-1.4
					c0-1.6-0.6-2.8-1-3.5c0,0,0-0.1-0.1-0.1c-0.1-0.8-0.5-1.6-1.1-2.3c-0.1-0.1-0.1-0.3-0.2-0.4c-0.5-1.2-1.4-3.1-3.6-5.4
					c-3.2-5.4-4.2-6.3-9.5-7.6c-0.1,0-0.2-0.1-0.4-0.1c-1.2-0.2-2.4-0.3-3.4-0.3c-0.1,0-0.1,0-0.2,0c0-0.1-0.1-0.2-0.1-0.3
					c-1.2-2.4-3.2-6.5-8-6.5h-1c-0.9,0-1.8,0.1-2.5,0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.5-0.8,3.2-2.3,6.5-2.3c0.6,0,1.3,0.1,1.8,0.2
					c0.3,0.1,0.6,0.1,0.9,0.1c3.8,0,7-0.2,9.2-2.3c0.1-0.1,0.2-0.2,0.3-0.3c2.7-1.1,4.2-2.7,4.8-4.8c1.7-0.6,2.9-1.9,3.9-2.9
					c0.9-0.9,1.6-2,2.1-3.2c1.6,1.1,3.7,1.9,6.3,2.4l5.4,2.7c0.4,0.2,0.8,0.4,1.2,0.7c1.4,0.8,3.1,1.8,5.8,1.8h1
					c6.1,0,10.2-3.4,13.2-6.3c0.2-0.2,0.4-0.4,0.6-0.7l1.8-2.7c1.4-1.6,1.7-3.2,1.7-4.3c0-1.2-0.4-3-2.1-4.7l-0.2-0.2
					c-0.1-0.3-0.3-0.6-0.5-0.9c-0.1-1.1-0.5-2.2-1.3-3c-2.3-2.3-4.9-2.3-6.2-2.3c-1.5,0-4,0-6.5,0.7c-0.1-0.1-0.2-0.3-0.4-0.4
					c-1.6-1.6-3.5-2.4-5.6-2.4c-2,0-3.9,0.7-6.1,1.8c-0.1-0.1-0.2-0.3-0.3-0.4c-1.7-1.7-3.5-2.1-4.7-2.1c-0.9,0-2.2,0.2-3.5,1.1
					c-0.8,0.3-1.4,0.7-1.9,1.3c-1.2-0.8-2.6-1.4-4-2.2c-0.3-0.1-0.6-0.3-0.9-0.3c-0.8-0.2-1.5-0.4-2.2-0.6c-1.8-0.5-3.4-0.9-5-0.9
					c-0.9,0-1.8,0.1-2.6,0.4c-0.1,0-0.2,0-0.4,0c-2.4-2.3-5.6-4.4-7.8-5.5C364.4,50.9,363.7,50.7,363,50.7L363,50.7z M329,95.7h-2
					c-1.2,0-2.3,0.5-3.2,1.3c-2.3,2.3-2.3,4.9-2.3,7.2c0,0.5,0.1,1,0.2,1.4c0.2,0.7,1.5,4.1,4.2,6c0,0,0,0,0,0
					c0.3,0.4,0.8,1.3,1.8,2c1.7,2.7,3.8,4,6.2,4c1.3,0,3.9,0,6.2-2.3c0.2-0.2,0.4-0.4,0.6-0.7c3.2-4.8,0.9-8.8-0.3-10.7
					c-0.2-0.3-0.3-0.6-0.5-0.8c-0.2-0.4-0.5-0.8-0.8-1.2C335.7,98.6,332.6,95.7,329,95.7L329,95.7z M315.5,117.5
					c-1.5,0-2.9,0.4-4.1,1.2c-1,0.1-1.9,0.6-2.6,1.3c-2.3,2.3-2.3,5.7-2.3,7.2c0,0.7,0.2,1.4,0.5,2c1.8,3.6,4.2,5.5,7,5.5h1
					c3.9,0,5.9-2.1,7.2-3.3c0.8-0.8,1.3-2,1.3-3.2v-2v-3c0-1.7-1-3.3-2.5-4C319.6,118.4,317.8,117.5,315.5,117.5L315.5,117.5z"/>
			</g>
			<g id="st0">
				<path d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,51.2c-2.2,0-6.1,0-8.3,3.7c-0.3,0.4-0.6,0.9-0.8,1.4c-1.6-1.6-3.7-2.8-6.5-2.8
					c-1.5,0-3.1,0.3-4.8,1.1c-0.1,0-0.2,0.1-0.3,0.1c-4.8,2.4-6.9,5.8-7.8,9c-0.9-0.8-2-1.4-3.4-1.4h-1c-0.4,0-0.9,0.1-1.3,0.2
					c-2,0.7-3.6,2.3-4.7,5c-1.3,3-1.9,7.7-1,10.6c0.3,3.7,3,6.2,7,6.2h1c1.3,0,2.4-0.3,3.3-0.7c0.7,1.4,1.7,2.7,2.9,4.3l0.6,0.8
					c0,0,0.1,0.1,0.1,0.1c3.4,4.2,5.3,6.6,8.3,7.3c1.2,1.1,2.8,2.2,4.8,2.2c3.1,0,6.1-1.4,8.8-4.2c0.2-0.2,0.4-0.4,0.5-0.6
					c0.7-1,3.5-2.5,6.9-2.5c0.7,0,1.3,0.1,1.9,0.2c0.3,0.1,0.5,0.1,0.8,0.1c3.6,0,6.8-0.2,8.8-2.2c0.1-0.1,0.2-0.2,0.3-0.4
					c2.7-1.1,4.2-2.6,4.7-4.8c1.7-0.6,3-1.9,4-2.9c1-1,1.7-2.2,2.2-3.6c1.7,1.3,3.9,2.1,6.7,2.6l5.5,2.7c0.4,0.2,0.8,0.4,1.2,0.7
					c1.4,0.8,3,1.8,5.6,1.8h1c5.9,0,10-3.3,12.8-6.2c0.2-0.2,0.4-0.4,0.5-0.6l1.8-2.7c2.2-2.5,2.1-5.9-0.3-8.3l-0.3-0.3
					c-0.2-0.3-0.3-0.7-0.6-1c0-1-0.4-2-1.2-2.8c-2.2-2.2-4.6-2.2-5.8-2.2c-1.5,0-4.1,0-6.6,0.8c-0.2-0.2-0.3-0.4-0.5-0.6
					c-1.5-1.5-3.3-2.3-5.2-2.3c-2,0-3.9,0.8-6.2,1.9c-0.2-0.2-0.3-0.5-0.5-0.7c-1.6-1.6-3.2-1.9-4.3-1.9c-0.9,0-2.1,0.2-3.3,1.1
					c-0.8,0.3-1.5,0.8-2.1,1.5c-1.3-0.8-2.7-1.6-4.4-2.4c-0.3-0.1-0.5-0.2-0.8-0.3c-0.8-0.2-1.5-0.4-2.3-0.6
					c-1.8-0.5-3.3-0.9-4.9-0.9c-0.9,0-1.7,0.1-2.6,0.4c-0.3,0-0.5,0-0.6,0c-2.4-2.3-5.6-4.4-7.8-5.5C364.2,51.3,363.6,51.2,363,51.2
					L363,51.2z M193,72.2c-1.1,0-2.1,0.4-2.8,1.2l-0.3,0.3c-0.4,0.2-0.7,0.4-1,0.6c-1.6,0-4.3,0-6.4,1.6c-0.7-0.3-1.6-0.6-2.6-0.6
					c-3.3,0-7,2.9-7,7c0,2.3,0.8,4.6,2.2,6.2c-0.9,0.2-1.6,0.7-2.3,1.3c-1.6,0.8-2.8,2.4-3.4,4c-0.5,0.3-1.1,0.6-1.8,1.2
					c-2.8,1.6-4.2,4.3-5.2,6.5c-0.3,0.6-0.4,1.2-0.4,1.8c0,1.8,0,3.6,1,5.3v1.8c-1.1,2.6-2,6.5-2,8.9c0,0.5,0,1-0.1,1.5
					c-0.2,2-0.4,5.2,2.7,7.9c0.1,0.3,0.3,0.5,0.5,0.7c0.2,1.3,0.6,2.4,1.1,3.3c-2.7,4.4-3.2,13.5-3.2,13.6c0,0.9,0.2,1.7,0.5,2.3
					c-0.1,0-0.2,0.1-0.3,0.1c-1.2,0.6-2.5,0.6-4.2,0.6c-0.2,0-0.4,0-0.7,0.1c-5.5,0.9-10.1,2-13.1,6.7c-3.9,0-7.1,2.4-9.1,4.4
					c-0.8,0.8-1.2,1.8-1.2,2.8v3v0.1c-0.4-0.2-0.8-0.4-1.2-0.7c-0.6-0.3-1.2-0.4-1.8-0.4c-3.8,0-7.2,0.1-9.8,1h-3.5
					c-0.2-0.1-0.4-0.1-0.6-0.2c-1.8-0.6-3.4-1.2-5.3-1.2c-1.3,0-2.5,0.3-3.6,0.8c-2.9,1.5-3.7,4.3-4.3,6.3c-0.2,0.6-0.3,1.2-0.5,1.5
					c-0.3,0.6-0.4,1.2-0.4,1.8c0,3.2,0,5.6,1.2,9.3c0.1,0.3,0.3,0.6,0.5,0.9c-0.1,0.7-0.3,1.8-0.6,3c-0.1,0.3-0.1,0.5-0.1,0.8
					c0,1.3-0.9,4.1-1.7,6.5c-1,2.1-2.2,5.3-2.3,8c-0.3,0.2-0.6,0.4-0.8,0.6c0,0-3.2,3.5-4,6.6c-0.1,0.3-0.3,0.6-0.4,0.9
					c-0.1,0.2-0.2,0.5-0.3,0.6c-0.7,0.2-1.3,0.5-1.8,0.7c-0.1,0-0.1,0.1-0.2,0.1c-1.7,0.1-3,0.6-3.7,0.9c-0.1,0-0.1,0.1-0.2,0.1
					c-0.9,0.1-1.7,0.5-2.4,1.1c-0.1,0.1-0.3,0.3-0.5,0.4c-1.1,0.9-2.9,2.4-3.5,4.9c-0.6,1-0.9,2.2-0.9,3.5c0,1.3,0.4,2.6,1,3.7
					c0,0.1,0.1,0.3,0.1,0.4c0,0.1-0.1,0.4-0.2,0.7c-0.1,0.4-0.2,0.8-0.2,1.3v1.2c-3.5,2.6-4,5.1-4.8,10.1l-0.1,0.8
					C76,246.8,76,252,76,254.2c0,0.4,0,0.7,0.1,1.1c-0.1,0-0.3,0.1-0.4,0.1c-0.6,0.2-1.3,0.4-2.1,0.9c-4.5,0.4-6.2,3.2-7.3,6.2
					c-0.6,0.4-1.1,0.9-1.5,1.3c-2.4,1.2-3.8,4.2-3.8,6.5c0,2.3,1.6,5.3,3.2,6.8c0.1,0.1,0.2,0.2,0.3,0.4c0.9,1,2.5,3,5.6,3.6
					c0.1,0.3,0.2,0.7,0.3,1c1.6,3.1,6,6.2,8.2,7.5c0.7,0.7,1.6,1.4,2.8,2c-0.5,0.3-0.9,0.6-1.2,0.9c-0.3,0.3-0.9,0.9-1.4,1.9
					c-1.6,1.1-2.8,3.1-2.8,6c0,3.5,3.3,5.1,6.2,6.6c0.2,0.1,0.3,0.2,0.5,0.2c1.9,0.6,0.8,6.5,0.4,8.3c0,0,0,0.1,0,0.1
					c-0.4,1.8-0.7,3.3-0.8,3.9c0,0-0.1,0.1-0.1,0.1c-1.4,1.4-4.2,4.2-4.2,7.8c0,3.1,1.5,5,3.2,6.2l-1,1c-2.1,2.1-3.7,3.7-5,7.6
					c-0.1,0.4-0.2,0.8-0.2,1.3c0,1.9,0,4.1,0.9,6c-0.7,1.4-1.1,3.2-0.7,5.1c0.3,1.3,1,2.4,1.9,3.2c0.2,1.6,0.8,2.7,1.6,3.7
					c-0.6,0.6-1.2,1.2-1.7,1.9c-5.1,2.6-9.6,9.5-11.3,12.8c-1.1,1.3-2,3.1-3,5c-1.7,1.8-7.7,8.4-7.7,12.3c0,0.9,0,2.4,0.8,4
					c-0.1,2.7,1.2,6.5,7,10.4c0.1,0.1,0.3,0.2,0.4,0.2c1.9,1,3.9,1.5,5.7,1.5c2.5,0,4.9-0.9,7.2-2.7c3.3,2,7.9,2.7,9.9,2.7
					c0.2,0,0.4,0,0.5,0c0,0.1-0.1,0.2-0.1,0.2c-0.3,0.6-0.4,1.2-0.4,1.8c0,2.3,0,4.7,2.2,6.8c0.3,0.3,0.7,0.6,1,0.7
					c0.9,0.5,1.9,0.7,2.8,0.7c2.9,0,4.6-2.1,5.4-3c0.1-0.1,0.3-0.3,0.3-0.4c0.1-0.1,0.2-0.2,0.3-0.3c0.6-0.2,1.5-0.5,2-0.5
					c4.6,0,7.5-2.9,8.7-4.2c0.2-0.2,0.4-0.4,0.5-0.6c1.2-1.8,3.5-3.7,4.5-4.3c4.2-0.5,9.7-4.9,12-7.9c0.1,0,0.1,0,0.2,0
					c4.9,0,7.4-2.2,8.5-4.9c1.1,0.1,1.5,0.6,2.7,1.8l2.2,2.2c0.5,1.3,1.4,2.4,2.6,3.1v1.9c0,1.1,0.4,2.2,0.7,3.3
					c0.1,0.3,0.2,0.7,0.3,0.9v0.1c-0.1,0.4-0.3,0.8-0.4,1.1c-0.8,1.9-1.7,4.2-0.1,7.4c1.4,2.7,4.5,4.6,8.4,5.1
					c0.4,0.3,0.8,0.6,1,0.7c0.2,0.1,0.3,0.2,0.4,0.2c0.8,0.8,1.8,1.2,2.8,1.2c0.8,0,1.6-0.2,2.5-0.5c-0.1,0.4-0.2,0.9-0.4,1.5
					c-1,3.9-0.4,6-0.1,7.1c0,0,0,0.1,0,0.1v1.1c-0.8,2.7-1.2,5.9,1,9.1c-0.1,3.2-0.4,6.5-0.9,9.6c-0.1,0.3-0.2,0.6-0.3,0.9
					c-0.8,2.4-1.8,5-0.8,7.8c0,1,0.1,2.1,0.5,3.2c0.3,1,0.9,1.9,1.6,2.5c0.1,1.3,0.2,2.7,0.6,4c-0.4,0.6-0.6,1.4-0.6,2.4
					c0,2.4,0.9,4.2,2.1,5.4c0.4,2.8,1.9,3.9,3.2,4.3c1.1,0.7,2.5,1,4.1,1c1.4,0,3.2-0.3,5.6-0.9c0.5-0.1,0.9-0.3,1.3-0.6
					c0.8,0.1,2.3,0.3,3.7,0.6c1.3,0.4,2.4,0.8,3.3,1.1c0.9,3.7,4.5,5.4,6.3,6.3c0.2,0.1,0.5,0.2,0.7,0.3c0.3,0.2,0.7,0.3,1.1,0.4
					c0.9,0.9,1.8,1.3,2.4,1.6c0.2,0.1,0.4,0.2,0.5,0.3c0.2,0.2,0.3,0.3,0.5,0.4c1.7,3.2,3.8,4.8,6.3,4.8c0.9,0,1.6-0.2,2.2-0.4
					c0,0,0,0,0,0c2.1,1,4.3,2.1,7.5,3.2c0.4,0.1,0.8,0.2,1.3,0.2c0.2,0,0.4,0,0.5,0c0.1,0.2,0.3,0.5,0.5,0.7
					c0.2,2.8,1.7,4.6,3.1,5.6c0.7,4.7,5.5,5.6,9,6.2c0.6,0.1,1.3,0.2,2.1,0.4c0.3,0.1,0.5,0.1,0.8,0.1c0.3,0,1,0,1.4,0.1
					c1.2,1.2,3.2,2.9,6.6,2.9c0,0,0.1,0,0.1,0c1.3,2.1,2.8,3.4,5.1,4.6c0.6,0.3,1.2,0.4,1.8,0.4h0.8c0.6,0.3,1.3,0.4,2,0.4
					c0.9,0,1.6-0.3,2.1-0.5c0.3,0,0.5-0.1,0.8-0.2c0.9,0.3,1.7,0.4,2.6,0.4c1,0,1.9-0.2,2.7-0.5c0.1,0.1,0.3,0.2,0.3,0.2
					c0.8,0.8,1.8,1.2,2.8,1.2h3c1.2,0,2.2-0.4,2.9-0.9c0.2,0,0.3,0.1,0.5,0.1c0.7,0.1,1.5,0.3,2.3,0.3c0.6,0,1-0.1,1.5-0.2
					c1.4,1.4,3.1,2.3,4.6,2.6c1.5,0.7,3.4,1.5,5.4,1.5c1.2,0,2.4-0.3,3.5-0.8c0.4-0.2,0.7-0.4,1-0.7c0.8-0.8,1.4-1.6,1.8-2.6
					c1.3-0.1,2.5-0.5,3.7-1.2c2.6,1,5.6,1,7.7,1h5c1.3,0,3.7,0,5.8-2.2s2.2-4.6,2.2-5.8c0-0.5-0.1-1-0.3-1.4c0.1-1.2,0.9-4,1.4-5.7
					c0.1-0.5,0.3-1,0.4-1.5c0.1,0,0.3,0,0.4,0c4.5,0,6.1-3.7,6.7-5.6c2.3-3.8,1.9-8.2-1.3-12.9c-0.2-0.3-0.4-0.5-0.7-0.8
					c-0.1-0.3-0.2-0.7-0.4-1c1-1.4,2.3-3.4,2.6-5.3c0.1-0.2,0.3-0.4,0.5-0.6c2.4-1.5,3.6-3.9,3.6-7.2c0-2.2-0.8-3.6-1.6-4.5
					c0.8-1,1.3-2.2,1.5-3.4c0.4-0.9,0.7-1.7,0.9-2.4c0.2-0.7,0.4-1.2,0.8-2c0.1-0.3,0.3-0.6,0.3-0.9c3-2.4,3.9-7.1,4.1-9.3
					c1.1-1,1.8-2.4,2-3.8c0.3-0.6,1.2-1.8,1.9-2.7c2.8,0.1,7.9,0.5,15.3,1.9l3.9,1c0.3,0.1,0.6,0.1,1,0.1c4.5,0,5.6-3,5.9-4.9
					l0.7-1.3c0.2-0.4,0.3-0.9,0.4-1.4c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.2-0.4,0.3-0.7c0.6,0.1,1.2,0.2,1.9,0.4
					c2.2,0.4,4,0.7,5.7,0.7c2.4,0,4.4-0.6,6.5-1.8c0.8-0.2,1.4-0.5,1.8-0.7c0.1,0,0.2-0.1,0.2-0.1c0.2,0,0.3,0,0.5-0.1
					c1.5-0.4,4.4-1.5,6-4.3c0.6,0.3,1.3,0.5,2.1,0.5c3.7,0,5.1-1.7,5.7-3.2c1.8-2.6,1.9-6.1,0.3-10.6v-8.9c0.1-0.6,0.2-1.1,0.3-1.6
					c0.6-2.8,1.5-6.7-1.3-9.1v-0.2c0.4-1.6,0.8-2.9,1.2-3.9c0,0,0-0.1,0-0.1c1.9-0.6,3.5-1.8,4.3-3.3l2.3-2.3
					c1.3-1.3,5.4-5.8,6.1-11.4c0.2-0.3,0.4-0.7,0.6-1.2c1.8-1.7,3.3-4.6,3-9.1c0.4-0.2,0.8-0.4,1.2-0.7c0.1,0.1,0.3,0.2,0.4,0.2
					c0.1,0.4,0.2,0.8,0.4,1.1c1.3,2.5,3.8,5.4,7.2,6.1c0.7,0.4,1.5,0.6,2.3,0.6c0.7,0,1.3-0.1,1.9-0.4c1.2,1.2,2.9,1.9,5.2,1.9
					c0.4,0,1.6-0.1,3-0.8c0.2,0,0.3,0,0.4,0c3,0,4.7-1.6,5.7-3c0.6,0.3,1.2,0.5,1.7,0.6c1.3,2.9,3.3,4.2,6.2,4.2
					c1.1,0,2.1-0.4,2.8-1.2c0.3-0.3,0.7-0.7,0.9-0.9c0.8-0.2,1.5-0.5,2.1-1.1c1.7-1.7,2.1-3.4,2.1-4.4c0.3-0.9,1.6-3.4,2.9-4.6
					c0.2-0.2,0.4-0.4,0.5-0.6c2.3-3.4,2.8-5.5,1.9-8.9c0.4-0.5,0.6-1.2,0.7-1.8c0-0.1,0.1-0.2,0.1-0.2c0.4-0.7,0.9-1.8,0.9-3.3
					c0-2.3-1.5-4.1-2.1-4.7c-0.1-0.4-0.2-0.7-0.4-1.1c-0.3-0.6-0.5-1.3-0.7-2.2c-0.3-1.1-0.6-2.4-1.3-3.8c-0.1-0.3-0.3-0.5-0.5-0.8
					c-0.1-0.2-0.1-0.6-0.1-0.9c1-3.4,1-6.6,1-8.6c0-0.9,0-1.9,0-2.4c0.2-0.2,0.5-0.5,0.8-0.8c1.8-1.8,3-3.7,3.6-5.9
					c0.2,0,0.4,0,0.5,0c2.2,0,3.7-0.4,5-1c0.4,0.4,0.9,0.7,1.3,1.1c0.4,0.3,0.8,0.7,0.9,0.8c0.8,0.8,1.8,1.2,2.8,1.2
					c3,0,5.3-1.6,6.4-4.3c0.5-1.4,0.6-2.9,0.6-4c0.5-1.5,0.8-3.3,0.6-5.1c1.9-0.5,4.6-1.3,6.7-2.7c2.1,2.5,5.3,4.2,7.7,4.2
					c3.7,0,5.1-1.7,5.7-3.2c0.7-1,1-2,1.2-2.9c0.7-0.2,1.4-0.5,2-1.1c0.1-0.1,0.5-0.4,0.8-0.6c0.6-0.5,1.4-1.1,2.1-1.9
					c0.2-0.1,0.4-0.2,0.6-0.4c1.5,1,2.9,1.2,3.8,1.2c1.3,0,2.2-0.4,2.6-0.6c2.3-1.2,4.7-3.7,3.5-9c1.7-2.2,1.7-4.9,1.7-6.6
					c0-1.1-0.4-2.1-1.2-2.8c-0.6-0.6-1.4-1.4-2.3-2c-0.1-0.3-0.3-0.5-0.5-0.8c0-0.1-0.1-0.3-0.1-0.5v-0.6c0.8-0.7,1.6-1.7,2.3-3
					c2.9-2.9,3.7-3.9,3.7-6.4c0-1.2-0.4-2.5-1.1-3.6c0.8-0.3,1.3-0.7,1.8-1.1c2-1,3.4-3.1,3.4-5.3c0-1.3,0-2.5-0.3-3.8
					c0.6-0.3,1.1-0.8,1.6-1.3c3.1-0.2,8.6-0.9,12.3-3.2c0.1,0,0.1,0,0.2,0.1c0.2,0.1,0.5,0.2,0.6,0.2c0.6,0.5,1.9,1.4,4,1.4
					c1.1,0,2.3-0.3,3.5-0.9c0.4-0.2,0.7-0.4,1-0.7c1-1,1.4-1.9,1.7-2.5c0.1-0.2,0.2-0.4,0.3-0.5c0.4-0.4,0.7-0.8,0.9-1.3
					c0.2-0.2,0.6-0.6,1.5-1.2l3-2c0.2-0.1,0.4-0.3,0.6-0.5c2.2-2.2,2.2-4.6,2.2-5.8c0-0.8-0.2-1.6-0.7-2.2l-1.5-2.3
					c-0.2-0.6-0.4-1.1-0.6-1.5c0.7-3.1-0.6-6.6-2.4-11.2c-1.5-5.7-4.4-6.6-9.3-7.5l-0.7-0.1c-0.3-0.1-0.5-0.1-0.8-0.1
					c-0.2,0-0.5,0-0.8,0c-0.2-0.4-0.5-0.8-0.8-1.1c1-1.2,1.6-2.8,1.6-4.9c0-2.2-0.7-3.8-1.6-5c0.3-0.5,0.5-1,0.5-1.6
					c1.5-1,3.8-2.7,6.9-5.7c3.9-0.8,8.1-4,9.6-6.8c1.5-0.1,3-0.3,5.1-1.3c5.5-1.9,5.5-5.5,5.5-8.7c0-1.7,0-6-2.6-8.8
					c-0.1-0.8-0.2-1.5-0.3-2c0-0.1-0.1-0.3-0.1-0.4c0-1-0.5-1.9-1.2-2.6c-0.7-0.7-1.5-1.5-2.4-2.1c0-0.9-0.2-1.7-0.6-2.5
					c-0.7-1.6-1.9-2.9-3.4-3.8c-0.2-0.2-0.5-0.4-0.7-0.6c-0.2-0.2-0.5-0.4-0.7-0.6c-0.1-0.5-0.2-0.9-0.4-1.4l-1-2
					c-0.5-1.1-1.5-1.8-2.6-2.1c-1-0.7-2.4-1.1-4-1.1c-0.8,0-1.6,0.2-2.2,0.7l-3,2c-0.2,0.1-0.4,0.3-0.6,0.5
					c-0.3,0.3-0.6,0.6-0.9,0.9c-2.1,0.2-3.6,1-4.9,1.7c-0.3,0.2-0.6,0.4-1,0.5c-1,0.3-1.6,0.5-2,0.5c-0.1,0-0.3,0-0.5-0.1v-1.7
					c0-2-0.6-3.4-0.9-4.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.9-0.5-1.7-1.1-2.4c0,0-0.2-0.3-0.3-0.5c-0.1-0.3-0.3-0.6-0.5-0.9
					c0.2,0,0.4-0.1,0.6-0.2c0.4,0.3,1,0.7,1.6,1c0.6,0.3,1.2,0.4,1.8,0.4c3.7,0,6.3-2.9,6.9-5.9c1.2-2.8,1.1-5-0.3-7.9
					c-0.2-0.4-0.4-0.7-0.7-1c-0.7-0.7-1.6-1.6-2.7-2.3c-0.2-0.5-0.4-0.9-0.5-1.2c-0.5-0.9-1.8-2.3-4.3-2.7c-0.7-0.3-1.4-0.5-2.2-0.5
					c-2.7,0-5,1.9-5.8,4.5c-1.1,0.2-2.4,0.6-3.6,1.6c-1.8,1.1-2.9,2.8-3.2,4.8c0,0.1,0,0.2,0,0.2c-0.5-0.1-1-0.3-1.5-0.4l-0.7-0.2
					c-0.1,0-0.3-0.1-0.4-0.1c-2.5-0.4-4.4-0.7-6.3-0.7c-2.6,0-4.8,0.6-8.5,1.8c-6,1.5-8.3,2.7-9.5,3.9c-4.3,0.2-7,2.1-10,5
					c-5.5,4.6-5.5,4.6-9.1,6.4c-0.4,0.2-0.7,0.4-1,0.7c-0.1,0.1-0.2,0.2-0.3,0.3c-0.8-0.6-1.9-1.1-3.2-1.3c-0.1-0.1-0.2-0.1-0.2-0.2
					c-0.9-0.7-2-1.6-3.1-3.2c-0.1-0.2-0.3-0.4-0.5-0.6l-1.3-1.3c-0.8-1.7-2.5-2.8-4.2-2.9l-8.5-1.2c0-0.3,0.1-0.5,0.1-0.8
					c0-7-6.9-8.2-12.1-8.9c-3.4-1.1-5.9-1.6-8.2-1.6c-2.3,0-4.3,0.5-6.6,1.5c-1.7,0-3.8-0.1-5.8-0.8c-0.2-0.1-0.5-0.1-0.8-0.2l-8-1
					c-0.2,0-0.3,0-0.5,0h-1h-3c-1.1,0-4.2,0-7.6,1c-1.8,0-4.1-0.3-6.4-0.6c0.4,0,0.7,0,1.1,0c4.6,0,7.5-1.7,8.7-5.1
					c0.1-0.4,0.2-0.8,0.2-1.3c0-1.4-0.6-2.6-0.9-3.3c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.8-0.4-1.5-1-2.2c-0.1-0.1-0.2-0.3-0.2-0.5
					c-0.5-1.1-1.4-3-3.5-5.3c-3.2-5.3-4-6.1-9.2-7.4c-0.1,0-0.2,0-0.3-0.1c-1.2-0.2-2.3-0.3-3.4-0.3c-0.2,0-0.3,0-0.5,0
					c-0.1-0.2-0.1-0.4-0.2-0.6c-1.2-2.3-3.1-6.2-7.6-6.2h-1c-5,0-6.9,3.8-7.6,5.2c-0.3,0.6-0.4,1.2-0.4,1.8c0,1.4,0.1,2.8,1.1,5
					c0.3,2.5,1.6,4.4,3.7,5.3c0.1,0.1,0.1,0.2,0.2,0.2c-0.3,0.2-0.5,0.4-0.8,0.6c-0.1,0.1-0.3,0.3-0.5,0.4c-1.1,0.9-3,2.4-3.5,5
					c-0.7,1.5-0.8,3.2,0,4.7c0.5,1.1,1.3,1.9,2,2.5c0.8,0.8,1.8,1.2,2.8,1.2h1c1.5,0,3.2-0.7,4.7-1.5c0.2,0.1,0.4,0.1,0.6,0.1
					c-0.8,0.4-1.5,0.9-2.1,1.6c-0.7,0.7-1.1,1.7-1.2,2.7c0,0.1,0,0.2,0,0.2c-0.1,0.5-0.2,1.3-0.2,2.3c-4.2,1.6-6.7,7.3-7.6,10.6
					c-0.4,1.4-0.6,2.7-0.5,4c-0.3-0.2-0.6-0.3-0.8-0.5c-0.6-0.3-1.2-0.4-1.8-0.4c-4.4,0-7.6,2.9-8,7.1c-0.4,1.2-0.5,2.4-0.3,3.5
					c-0.9-0.3-1.5-0.4-1.8-0.5c-1.4-0.3-2.6-0.5-3.6-0.5c-3,0-4.9,1.3-6.9,2.6c-0.2,0.1-0.4,0.3-0.7,0.4c-0.2,0.1-0.4,0.3-0.6,0.5
					l-1.9,1.9c-0.7,0.1-1.6,0.3-2.4,0.8c-0.5-0.3-0.8-0.6-1.6-1.3c-0.5-0.9-1-1.7-1.4-2.3c-0.3-0.5-0.6-0.9-0.9-1.4
					c-0.4-4-3.3-6.9-6.9-6.9c-4.3,0-6.9,4.2-9.8,15.9c-0.8,2.3-1,4.2-1.1,5.8c-0.7-0.2-1.6-0.4-2.4-0.4c-0.3,0-0.7,0-1,0.1l-1.1-2.1
					c-0.4-0.7-0.9-1.3-1.6-1.7c0-0.2,0-0.4,0-0.5c0-0.6-0.1-1.2-0.4-1.8c-0.1-0.2-0.3-0.9-0.4-1.4c0-0.1-0.1-0.3-0.1-0.4
					c0.8-0.5,1.3-1.1,1.8-1.6c0.8-0.8,1.2-1.8,1.2-2.8c0-1-0.3-2.1-0.8-3.2c2.1-0.5,3.1-1.8,3.5-3c2.1-3.1,0.5-6.4-0.1-7.6
					c-0.5-1.1-1.5-1.8-2.7-2.1c-0.5-0.3-1.2-0.5-2.1-0.5c-0.4,0-0.8,0.1-1.2,0.2c-0.5-0.5-0.9-1-1.4-1.3c0.5-1.2,0.8-2.5,0.9-3.8
					c0.2-0.6,0.3-1.1,0.5-1.6c0.7-2.4,1.6-5.4-1.4-8.6c0-0.1,0-0.2,0-0.3c0-3.4-2.4-5.4-3.6-6.4c-0.2-0.2-0.4-0.4-0.5-0.4
					c-0.4-0.4-0.9-0.7-1.5-0.9c-0.2-0.1-0.4-0.3-0.6-0.4c-2.1-3.1-5.3-4.8-9.1-4.8c-1.9,0-3.9,0.4-6,1.2c-0.4,0.1-0.7,0.2-1,0.2
					c-0.2,0-0.3,0-0.4,0c-1-2-2.5-3.6-4-4.7c0-1.1-0.3-2.4-1.5-3.9c-0.2-0.6-0.5-1.1-1-1.6c-1.9-1.9-5-3.2-7.8-3.2
					c-2.3,0-4.7,0-6.8,2.2c-0.3,0.3-0.5,0.6-0.8,0.9c-3.1,0.5-4.9,2.6-5.4,4.8c-0.8,2.2-0.4,4.3,1.3,6.3c-0.4,1.4-0.4,2.8-0.4,3.8
					c0,0.3,0,0.6,0,1c0,0,0,0,0,0c-1.7,0-3.8,0.9-5.4,2H240c-0.2,0-0.7,0-1,0l-0.4-0.8c-0.1-0.1-0.2-0.3-0.2-0.4
					c-2.5-3.7-5.4-5.5-9.3-5.7c0-0.1,0-0.2,0.1-0.3c0.3-1.3,1-4.3-1.3-6.6c-1.6-1.6-3.2-2.9-8.5-4c-1.9-0.9-3.8-1.9-6.6-2.8
					c-3.8-2.2-7.5-3.4-10.8-3.4c-1.8,0-3.4,0.3-4.9,1h-0.4c-0.2,0-0.4-0.1-0.6-0.2c0.1-0.8,0.1-1.5,0.1-1.8c0-0.9-0.2-1.8-0.6-2.5
					c2.5-1.8,4.3-3.8,4.5-7.6c0.7-2.1,0.5-4.2-0.3-6c0.2-0.5,0.3-1.1,0.3-1.9c0-0.6-0.2-2.8-2.2-4.8C197.3,73.9,195.5,72.2,193,72.2
					L193,72.2z M210.9,84.2h-2c-1.8,0-5.3,1.2-6.8,5.7c-0.1,0.4-0.2,0.8-0.2,1.3c0,4.6,3,8,7,8c3.7,0,6.4-2.2,6.9-5.7
					c1.2-2.6,0.1-6-2.1-8.1C213,84.6,212,84.2,210.9,84.2L210.9,84.2z M329,96.2h-2c-1.1,0-2.1,0.4-2.8,1.2
					c-2.2,2.2-2.2,4.5-2.2,6.8c0,0.4,0.1,0.9,0.2,1.3c0.2,0.7,1.4,4,4.1,5.8c0,0,0.1,0.1,0.1,0.1c0.2,0.4,0.8,1.3,1.8,2
					c1.6,2.6,3.6,3.9,5.8,3.9c1.3,0,3.7,0,5.8-2.2c0.2-0.2,0.4-0.4,0.5-0.6c3-4.6,0.8-8.3-0.3-10.2c-0.2-0.3-0.4-0.6-0.5-0.8
					c-0.2-0.4-0.4-0.7-0.7-1C335.5,99,332.4,96.2,329,96.2L329,96.2z M315.5,118c-1.4,0-2.7,0.4-3.9,1.2c-0.9,0.1-1.8,0.5-2.4,1.2
					c-2.2,2.2-2.2,5.4-2.2,6.8c0,0.6,0.1,1.2,0.4,1.8c1.7,3.5,3.9,5.2,6.6,5.2h1c3.7,0,5.6-2,6.8-3.2c0.8-0.8,1.2-1.8,1.2-2.8v-2v-3
					c0-1.5-0.9-2.9-2.2-3.6C319.4,118.9,317.6,118,315.5,118L315.5,118z"/>
			</g>
			<g id="st1">
				<path id="st2" d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,51.7c-2.1,0-5.8,0-7.9,3.5c-0.5,0.6-0.8,1.3-1.1,2c-1.6-1.8-3.8-3.3-6.7-3.3
					c-1.4,0-2.9,0.3-4.6,1c-0.1,0-0.2,0.1-0.3,0.1c-5.1,2.6-7.1,6.2-7.7,9.5c-0.9-1-2.1-1.9-3.7-1.9h-1c-0.4,0-0.7,0.1-1.1,0.2
					c-1.9,0.6-3.4,2.2-4.4,4.7c-1.2,3-1.8,7.5-1,10.3c0.3,3.6,2.8,5.8,6.5,5.8h1c1.5,0,2.6-0.4,3.5-1c0.7,1.6,1.7,2.9,3.1,4.7
					l0.6,0.8c0,0,0,0.1,0.1,0.1c3.3,4.2,5.2,6.6,8.2,7.2c1.2,1.1,2.6,2.2,4.6,2.2c3,0,5.8-1.4,8.5-4c0.2-0.2,0.3-0.3,0.4-0.5
					c0.8-1.1,3.7-2.7,7.4-2.7c0.7,0,1.4,0.1,2,0.2c0.2,0,0.5,0.1,0.7,0.1c3.5,0,6.6-0.2,8.5-2c0.1-0.1,0.2-0.3,0.4-0.4
					c2.7-1,4.1-2.5,4.5-4.7c0,0,0.1-0.1,0.1-0.1c1.7-0.5,2.9-1.8,4-2.8c1.1-1.1,1.9-2.5,2.3-4.2c1.7,1.5,4,2.5,7.1,3.1l5.5,2.8
					c0.4,0.2,0.8,0.4,1.2,0.7c1.4,0.8,2.9,1.7,5.3,1.7h1c5.7,0,9.7-3.2,12.5-6c0.2-0.2,0.3-0.3,0.4-0.5l1.8-2.8
					c2.1-2.3,2-5.4-0.3-7.7l-0.3-0.3c-0.2-0.4-0.4-0.7-0.6-1.1v-0.1c0-0.9-0.4-1.8-1-2.5c-2-2-4.3-2-5.5-2c-1.6,0-4.3,0-6.8,0.8
					c-0.2-0.3-0.4-0.6-0.7-0.8c-1.4-1.4-3-2.1-4.9-2.1c-2,0-3.9,0.9-6.4,2.1l0-0.1c-0.2-0.3-0.4-0.6-0.7-0.9c-1.5-1.5-3-1.8-4-1.8
					c-0.8,0-1.9,0.2-3.1,1c-0.9,0.3-1.7,1-2.2,1.7c-1.3-0.9-2.9-1.7-4.7-2.6c-0.2-0.1-0.5-0.2-0.7-0.3c-0.8-0.2-1.6-0.4-2.3-0.6
					c-1.7-0.5-3.2-0.9-4.8-0.9c-0.9,0-1.7,0.1-2.5,0.4c-0.4,0-0.7,0-0.9,0c-2.3-2.3-5.6-4.5-7.8-5.6C364.1,51.8,363.5,51.7,363,51.7
					L363,51.7z M193,72.7c-0.9,0-1.8,0.4-2.5,1l-0.3,0.3c-0.4,0.2-0.8,0.4-1.1,0.6H189c-1.6,0-4.3,0-6.4,1.7
					c-0.7-0.4-1.6-0.7-2.6-0.7c-3.1,0-6.5,2.7-6.5,6.5c0,2.6,1,5.2,2.7,6.5c-1.4,0.1-2.1,0.5-3,1.4c-1.6,0.7-2.7,2.4-3.3,4
					c-0.5,0.3-1.2,0.6-1.9,1.2c-2.7,1.6-4,4.2-5.1,6.3c-0.2,0.5-0.4,1-0.4,1.6c0,1.8,0,3.5,1,5.2v2.1c-1.1,2.6-2,6.5-2,8.8
					c0,0.5,0,1-0.1,1.5c-0.2,2-0.4,5,2.6,7.6c0.2,0.3,0.4,0.6,0.6,0.9c0.2,1.4,0.7,2.6,1.2,3.5c-2.8,4.1-3.3,13.5-3.3,13.6
					c0,1.1,0.4,2,0.8,2.6c0,0,0,0,0,0c-0.3,0.1-0.5,0.2-0.8,0.3c-1.3,0.6-2.7,0.6-4.4,0.6c-0.2,0-0.4,0-0.6,0
					c-5.5,0.9-10,2-12.9,6.7c-0.1,0-0.1,0-0.2,0c-3.8,0-6.9,2.3-8.8,4.3c-0.7,0.7-1,1.5-1,2.5v3v1c-0.6-0.4-1.2-0.8-1.9-1.1
					c-0.5-0.2-1-0.4-1.6-0.4c-3.8,0-7.2,0.1-9.7,1h-3.7c-0.2-0.1-0.5-0.2-0.7-0.2c-1.7-0.6-3.3-1.2-5.1-1.2c-1.2,0-2.3,0.3-3.3,0.8
					c-2.7,1.4-3.5,4.1-4,5.9c-0.2,0.6-0.4,1.2-0.5,1.6c-0.2,0.5-0.4,1-0.4,1.6c0,3.1,0,5.6,1.2,9.1c0.1,0.3,0.3,0.7,0.5,1
					c-0.1,0.7-0.4,1.9-0.6,3.2c0,0.2-0.1,0.5-0.1,0.7c0,1.4-0.9,4.2-1.7,6.7c-1,2.2-2.2,5.5-2.3,8.2c0,0,0,0-0.1,0
					c-0.3,0.2-0.6,0.4-0.9,0.7c0,0-3.2,3.4-3.9,6.4c-0.2,0.3-0.3,0.6-0.4,0.9c-0.1,0.3-0.3,0.8-0.4,0.8l0,0c0,0,0,0,0,0
					c-0.8,0.2-1.4,0.5-1.9,0.7c-0.1,0-0.2,0.1-0.3,0.1c-1.7,0.1-2.9,0.6-3.7,0.9c-0.1,0-0.2,0.1-0.3,0.1c-0.8,0.1-1.6,0.4-2.2,1
					c-0.1,0.1-0.3,0.3-0.6,0.5c-1,0.8-2.8,2.3-3.3,4.7c-1.2,2.1-1.2,4.8,0.1,6.8c0.1,0.2,0.1,0.4,0.2,0.5c0,0.2-0.1,0.4-0.2,0.9
					c-0.1,0.4-0.2,0.7-0.2,1.1v1.5c-3.6,2.6-3.9,4.8-4.8,10l-0.1,0.9c-1.1,4.4-1.1,9.5-1.1,11.7c0,0.4,0.1,0.7,0.2,1.1l0.1,0.2
					c0,0-0.1,0.1-0.1,0.1c-0.2,0.1-0.5,0.2-0.8,0.3c-0.6,0.2-1.3,0.4-2.1,0.9c-4.3,0.4-6,3.1-7,6c-0.6,0.4-1.1,0.9-1.6,1.4
					c-2.4,1.1-3.7,4.3-3.7,6.1c0,2.2,1.5,5,3,6.5c0.1,0.1,0.2,0.3,0.4,0.4c0.9,1,2.5,3,5.6,3.5c0,0.4,0.2,0.8,0.3,1.2
					c1.4,2.8,5.7,6,8,7.3c0.9,0.8,2.1,1.9,3.9,2.4c-1.1,0.3-1.8,0.8-2.3,1.3c-0.3,0.3-0.9,0.9-1.4,1.9c-1.5,1-2.7,2.9-2.7,5.6
					c0,3.2,3,4.7,5.9,6.1c0.1,0.1,0.3,0.1,0.5,0.2c2.1,0.7,1.5,5.2,0.7,8.9c0,0,0,0,0,0.1c-0.4,2-0.7,3.5-0.9,4
					c-0.1,0.1-0.1,0.1-0.2,0.2c-1.3,1.3-4,4-4,7.5c0,3.2,1.7,5,3.5,6.1l-1.5,1.5c-2.1,2.1-3.6,3.6-4.8,7.4c-0.1,0.4-0.2,0.7-0.2,1.1
					c0,2,0,4.2,0.9,6c-0.8,1.4-1.2,3.2-0.8,4.9c0.3,1.2,0.9,2.3,1.9,3.1c0.2,1.7,1,3,1.8,4c-0.8,0.6-1.5,1.4-2,2.3
					c-5,2.4-9.6,9.5-11.3,12.7c-1.1,1.3-2,3.1-3,5c-1.6,1.7-7.6,8.3-7.6,12c0,0.9,0,2.3,0.8,3.9c-0.1,2.6,1,6.3,6.7,10.1
					c0.1,0.1,0.2,0.2,0.4,0.2c1.9,0.9,3.7,1.4,5.5,1.4c2.5,0,4.9-0.9,7.2-2.8c3.2,2.1,7.9,2.8,9.9,2.8c0.5,0,0.9,0,1.3-0.1
					c-0.1,0.4-0.3,0.7-0.4,1c-0.2,0.5-0.4,1-0.4,1.6c0,2.3,0,4.4,2,6.5c0.3,0.3,0.6,0.5,0.9,0.7c0.9,0.4,1.7,0.6,2.6,0.6
					c2.7,0,4.2-1.9,5.1-2.9c0.1-0.2,0.3-0.4,0.4-0.4c0.1-0.1,0.2-0.2,0.3-0.4c0.6-0.3,1.8-0.6,2.3-0.6c4.4,0,7.2-2.8,8.4-4
					c0.2-0.2,0.3-0.3,0.4-0.5c1.3-2,3.8-3.9,4.7-4.5c4.2-0.5,9.8-5,11.9-8c0.1,0,0.3,0,0.5,0c5.4,0,7.4-2.7,8.1-5
					c1.6,0.1,1.9,0.5,3.4,1.9l2.3,2.3c0.5,1.3,1.5,2.4,2.7,3.1v2.1c0,1.1,0.3,2.1,0.7,3.1c0.1,0.3,0.3,0.8,0.3,1v0.3
					c-0.2,0.4-0.3,0.8-0.5,1.2c-0.8,1.9-1.6,4-0.2,6.9c1.5,3,4.9,4.4,8.1,4.8c0.4,0.4,0.8,0.6,1.1,0.8c0.2,0.1,0.4,0.2,0.5,0.3
					c0.7,0.7,1.5,1,2.5,1c0.9,0,2-0.3,3.1-0.8c0,0,0.1,0,0.1,0.1c-0.1,0.6-0.3,1.4-0.6,2.4c-0.9,3.5-0.5,5.4-0.1,6.9
					c0,0,0,0.1,0,0.1v1.3c-0.8,2.6-1.2,5.8,1,8.8c-0.1,3.2-0.4,6.7-0.9,9.9c-0.1,0.3-0.2,0.7-0.3,1c-0.8,2.4-1.7,4.9-0.8,7.6
					c0,1,0.1,2.1,0.4,3.1c0.3,1,0.9,1.8,1.6,2.4c0.1,1.4,0.2,2.9,0.6,4.4c-0.4,0.6-0.6,1.4-0.6,2.4c0,2.3,0.9,4,2.1,5.1
					c0.3,2.7,1.7,3.8,3,4.2c1,0.6,2.3,1,3.8,1c1.4,0,3.1-0.3,5.5-0.9c0.5-0.1,0.9-0.3,1.3-0.6c0.8,0,2.4,0.3,3.9,0.6
					c1.5,0.5,2.7,0.9,3.6,1.2c0.8,3.7,4.2,5.3,6.1,6.2c0.2,0.1,0.5,0.2,0.7,0.3c0.3,0.2,0.7,0.3,1.1,0.3c0,0,0,0,0,0
					c0.9,0.9,1.8,1.4,2.4,1.7c0.4,0.2,0.5,0.3,0.6,0.3c0.2,0.2,0.3,0.3,0.5,0.4c0.7,1.5,2.6,4.6,6,4.6c0.9,0,1.6-0.2,2.2-0.5
					c0.1,0,0.1,0.1,0.2,0.1c2.1,1,4.2,2.1,7.5,3.2c0.4,0.1,0.7,0.2,1.1,0.2c0.3,0,0.6,0,0.8,0c0,0,0,0,0,0.1
					c0.2,0.3,0.4,0.6,0.6,0.9c0.1,2.8,1.7,4.5,3.1,5.6c0.6,4.6,5.2,5.4,8.7,6c0.6,0.1,1.3,0.2,2.1,0.4c0.2,0,0.5,0.1,0.7,0.1
					c0.3,0,1.3,0,1.7,0.1c1.1,1.1,3,2.9,6.3,2.9c0.2,0,0.3,0,0.4,0c1.2,2.1,2.7,3.4,5,4.6c0.5,0.2,1,0.4,1.6,0.4h0.9
					c0.6,0.3,1.2,0.4,1.8,0.4c0.9,0,1.5-0.3,1.9-0.5c0.3,0,0.6-0.1,0.9-0.2c0.9,0.3,1.7,0.5,2.6,0.5c1,0,1.9-0.2,2.7-0.6
					c0.1,0,0.1,0.1,0.2,0.1c0.2,0.1,0.4,0.2,0.5,0.3c0.7,0.7,1.5,1,2.5,1h3c1.2,0,2.1-0.4,2.8-0.9c0.2,0,0.5,0.1,0.7,0.1
					c0.7,0.1,1.4,0.3,2.2,0.3c0.6,0,1.2-0.1,1.7-0.2c1.4,1.5,3.1,2.4,4.6,2.7c1.5,0.8,3.3,1.5,5.3,1.5c1.2,0,2.3-0.3,3.3-0.8
					c0.3-0.2,0.6-0.4,0.9-0.7c0.8-0.8,1.4-1.7,1.8-2.7c1.4,0,2.7-0.5,3.9-1.2h0c2.6,1,5.6,1,7.7,1h5c1.2,0,3.4,0,5.5-2s2-4.3,2-5.5
					c0-0.5-0.1-0.9-0.3-1.3c0.1-1.2,0.9-4.1,1.4-5.9c0.2-0.7,0.4-1.3,0.5-1.9c0.3,0,0.5,0,0.8,0c4.1,0,5.7-3.6,6.3-5.4
					c3.3-5.5-0.1-10.7-1.3-12.5c-0.2-0.3-0.4-0.5-0.7-0.7c-0.1-0.5-0.3-0.9-0.5-1.4c1.1-1.4,2.5-3.6,2.7-5.4
					c0.2-0.2,0.4-0.5,0.7-0.7c2.2-1.4,3.4-3.6,3.4-6.8c0-2.3-0.9-3.6-1.7-4.4c0.9-1.1,1.4-2.4,1.6-3.6c0.4-1,0.7-1.7,0.9-2.4
					c0.2-0.7,0.4-1.3,0.8-2c0.2-0.3,0.3-0.6,0.3-1c3.1-2.3,4-7.2,4-9.3c1.1-0.9,1.8-2.3,2-3.7c0.4-0.7,1.3-2.1,2.1-3.1
					c2.8,0.1,8,0.5,15.7,1.9l3.9,1c0.3,0.1,0.6,0.1,0.8,0.1c4.2,0,5.2-2.7,5.4-4.6l0.7-1.4c0.2-0.4,0.3-0.8,0.4-1.2
					c0-0.1,0.1-0.2,0.1-0.3c0.1-0.3,0.3-0.6,0.4-1c0.7,0.1,1.5,0.3,2.3,0.5c2.2,0.4,4,0.7,5.6,0.7c2.3,0,4.2-0.5,6.3-1.8
					c0.7-0.2,1.3-0.5,1.8-0.7c0.1,0,0.2-0.1,0.3-0.2c0.2,0,0.3,0,0.5-0.1c1.5-0.4,4.5-1.5,5.9-4.5c0.7,0.4,1.4,0.6,2.3,0.6
					c3.5,0,4.8-1.5,5.3-2.9c1.8-2.5,1.8-5.9,0.2-10.2v-9c0.1-0.6,0.2-1.1,0.4-1.6c0.6-2.8,1.4-6.5-1.4-8.8v-0.5
					c0.4-1.7,0.9-2.9,1.2-4c0-0.1,0.1-0.3,0.1-0.4c1.9-0.6,3.5-1.7,4.3-3.2l2.4-2.4c1.3-1.3,5.4-5.8,6-11.2c0.2-0.4,0.5-0.8,0.7-1.3
					c1.8-1.7,3.3-4.5,2.9-9.2c0.6-0.3,1.2-0.6,1.6-1c0.4,0.2,0.7,0.4,0.9,0.6c0,0.4,0.2,0.8,0.3,1.1c1.2,2.4,3.7,5.2,6.9,5.8
					c0.8,0.4,1.6,0.5,2.1,0.5c0.7,0,1.4-0.2,2-0.4c1.1,1.2,2.8,2,5.1,2c0.4,0,1.6-0.1,2.9-0.8c0.2,0,0.4,0,0.6,0
					c3.1,0,4.7-1.8,5.5-3.1c0.8,0.4,1.5,0.7,2.2,0.8c1.2,2.8,3.1,4.1,5.8,4.1c0.9,0,1.8-0.4,2.5-1c0.3-0.3,0.8-0.8,1.1-1
					c0.7-0.1,1.4-0.5,1.9-1c1.6-1.6,1.9-3.3,2-4.1c0.3-0.9,1.7-3.6,3-4.9c0.2-0.2,0.3-0.3,0.4-0.5c2.4-3.6,2.7-5.6,1.8-8.7
					c0.4-0.5,0.7-1.2,0.8-1.8c0-0.1,0.1-0.2,0.2-0.3c0.3-0.7,0.9-1.7,0.9-3.1c0-2.3-1.6-4-2-4.5c0,0,0,0,0,0
					c-0.1-0.4-0.2-0.7-0.3-1.1c-0.3-0.7-0.5-1.4-0.7-2.3c-0.3-1.1-0.6-2.4-1.3-3.7c-0.1-0.3-0.3-0.5-0.5-0.7
					c-0.1-0.3-0.1-0.8-0.1-1.2c1-3.3,1-6.6,1-8.5c0-1.1,0-2.2,0.1-2.6c0.2-0.2,0.6-0.6,0.9-0.9c1.9-1.9,3.1-3.8,3.6-6.1
					c0.3,0.1,0.6,0.1,0.9,0.1c2.3,0,3.8-0.5,5.1-1.1c0.5,0.5,1,0.9,1.5,1.3c0.4,0.3,0.8,0.7,1,0.8c0.7,0.7,1.5,1,2.5,1
					c2.8,0,4.9-1.5,5.9-4c0.5-1.4,0.6-2.8,0.6-3.9c0.5-1.6,0.9-3.5,0.5-5.4c2-0.5,5.1-1.4,7.4-3c2,2.6,5.2,4.4,7.6,4.4
					c3.5,0,4.8-1.6,5.3-2.9c0.8-1.1,1.1-2.2,1.2-3.1c0.8-0.1,1.5-0.4,2-1c0.2-0.2,0.5-0.4,0.8-0.7c0.6-0.5,1.4-1.1,2.1-1.9
					c0.3-0.2,0.6-0.3,0.9-0.5c0,0,0.1,0,0.1,0c1.5,1,2.9,1.3,3.8,1.3c1.2,0,2.1-0.4,2.4-0.5c3.1-1.6,4.2-4.5,3.2-8.7
					c1.7-2.1,1.7-4.8,1.7-6.4c0-0.9-0.4-1.8-1-2.5c-0.7-0.7-1.4-1.4-2.3-2c-0.1-0.3-0.3-0.6-0.6-0.8c-0.1-0.2-0.1-0.5-0.1-0.7l0-0.8
					c1.2-1,1.9-2.2,2.4-3.1c2.9-2.9,3.6-3.7,3.6-6.1c0-1.4-0.5-2.8-1.5-3.8c1-0.3,1.8-0.7,2.3-1.2c1.9-0.9,3.2-2.8,3.2-5
					c0-1.4,0-2.6-0.4-4c0.8-0.4,1.4-0.9,2-1.5c3-0.2,8.8-0.9,12.4-3.2c0.1,0,0.3,0.1,0.4,0.1c0.2,0.1,0.6,0.2,0.8,0.3
					c0.5,0.4,1.7,1.4,3.7,1.4c1.1,0,2.2-0.3,3.2-0.8c0.3-0.2,0.6-0.4,0.9-0.7c0.9-0.9,1.4-1.8,1.7-2.4c0.2-0.4,0.3-0.5,0.3-0.6
					c0.4-0.4,0.6-0.8,0.8-1.2c0.2-0.2,0.7-0.7,1.7-1.3l3-2c0.2-0.1,0.4-0.3,0.5-0.4c2-2,2-4.3,2-5.5c0-0.7-0.2-1.4-0.6-1.9l-1.6-2.4
					c-0.2-0.7-0.4-1.2-0.6-1.6c0.7-3-0.5-6.5-2.4-11.1c-1.4-5.5-4.1-6.2-8.9-7.2l-0.7-0.1c-0.2,0-0.5-0.1-0.7-0.1
					c-0.2,0-0.7,0-1.1,0c-0.3-0.6-0.7-1.1-1.1-1.6c1.1-1.1,1.8-2.7,1.8-4.9c0-2.2-0.8-3.8-1.7-5c0.4-0.5,0.6-1.2,0.7-1.9
					c2-1.2,4.4-3.1,7.1-5.8c3.9-0.7,8.1-4,9.5-6.8c0,0,0,0,0-0.1c1.6,0,3.1-0.2,5.2-1.3c5.2-1.8,5.2-5.1,5.2-8.2c0-1.8,0-6-2.6-8.6
					c0-0.8-0.2-1.6-0.3-2.1c0-0.2-0.1-0.4-0.1-0.5c0-0.9-0.4-1.7-1-2.3c-0.7-0.7-1.6-1.6-2.6-2.2c0-0.9-0.2-1.8-0.6-2.6
					c-0.6-1.5-1.8-2.7-3.3-3.6c-0.2-0.2-0.5-0.4-0.7-0.6c-0.3-0.2-0.7-0.6-0.9-0.8c0-0.5-0.2-0.9-0.4-1.4l-1-2
					c-0.5-1-1.4-1.6-2.4-1.9c-1-0.7-2.2-1.1-3.7-1.1c-0.7,0-1.4,0.2-1.9,0.6l-3,2c-0.2,0.1-0.4,0.3-0.5,0.4c-0.3,0.3-0.8,0.8-1,1
					c-2.1,0.1-3.5,0.9-4.8,1.7c-0.3,0.2-0.7,0.4-1,0.6c-1.1,0.4-1.7,0.5-2.1,0.5c-0.3,0-0.6,0-1-0.2v-2.1c0-1.9-0.6-3.2-0.9-4
					c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.8-0.4-1.6-1-2.2c-0.1-0.1-0.2-0.2-0.3-0.6c-0.2-0.4-0.5-0.9-0.9-1.5c0.5-0.1,1-0.2,1.5-0.4
					c0.5,0.4,1,0.7,1.7,1.1c0.5,0.2,1,0.4,1.6,0.4c3.4,0,5.9-2.8,6.4-5.5c1.2-2.7,1.1-4.7-0.3-7.5c-0.2-0.3-0.4-0.6-0.7-0.9
					c-0.8-0.8-1.7-1.7-2.8-2.3c-0.1-0.5-0.4-0.9-0.5-1.2c-0.5-0.8-1.7-2.1-4-2.4c-0.6-0.3-1.4-0.5-2.1-0.5c-2.6,0-4.8,1.9-5.4,4.4
					c-1.1,0.1-2.4,0.5-3.7,1.6c-2,1.2-3.1,3.1-3.1,5.3c-0.7-0.2-1.4-0.4-2.1-0.6l-0.7-0.2c-0.1,0-0.3-0.1-0.4-0.1
					c-2.5-0.4-4.4-0.7-6.2-0.7c-2.5,0-4.7,0.6-8.4,1.8c-6.2,1.6-8.3,2.7-9.5,3.9c-4.2,0.1-7,2-9.9,4.9c-5.6,4.6-5.6,4.7-9.2,6.5
					c-0.3,0.2-0.6,0.4-0.9,0.7c-0.2,0.2-0.4,0.4-0.7,0.5c-0.9-0.6-2-1.2-3.4-1.4c-0.1-0.1-0.2-0.2-0.3-0.3c-1-0.8-2.1-1.6-3.2-3.3
					c-0.1-0.2-0.3-0.4-0.4-0.5l-1.3-1.3c-0.7-1.6-2.2-2.6-3.8-2.7l-9-1.3c0.1-0.4,0.1-0.8,0.1-1.2c0-6.6-6.4-7.7-11.7-8.4
					c-3.4-1.1-5.8-1.6-8.1-1.6c-2.2,0-4.3,0.5-6.5,1.5c-1.8,0-4-0.1-6.1-0.8c-0.2-0.1-0.4-0.1-0.7-0.2l-8-1c-0.1,0-0.3,0-0.4,0h-1
					h-3c-1.1,0-4.2,0-7.5,1c-3.9-0.1-10.8-1.1-13.9-2.6c0,0,0,0,0,0c3.9,0.7,6.5,1,8.6,1c4.4,0,7.1-1.6,8.2-4.8
					c0.1-0.4,0.2-0.7,0.2-1.1c0-1.3-0.5-2.4-0.9-3.1c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.7-0.4-1.4-0.9-2c-0.1-0.1-0.2-0.4-0.3-0.6
					c-0.6-1.2-1.4-3-3.5-5.2c-3.1-5.2-3.8-6-8.9-7.2c-0.1,0-0.2,0-0.3-0.1c-1.2-0.2-2.3-0.3-3.3-0.3c-0.3,0-0.6,0-0.9,0
					c-0.1-0.3-0.2-0.6-0.3-0.8c-1.2-2.4-3-5.9-7.1-5.9h-1c-4.7,0-6.5,3.6-7.1,4.9c-0.2,0.5-0.4,1-0.4,1.6c0,1.4,0.1,2.7,1,4.9
					c0.2,2.4,1.5,4.2,3.5,5.1c0.1,0.2,0.3,0.4,0.4,0.5c0,0.1,0,0.2,0,0.3c-0.4,0.2-0.7,0.4-1,0.7c-0.1,0.1-0.3,0.3-0.6,0.5
					c-1,0.8-2.9,2.3-3.3,4.7c-0.7,1.3-0.7,2.9,0,4.3c0.5,1,1.3,1.8,1.9,2.4c0.7,0.7,1.5,1,2.5,1h1c1.4,0,3.2-0.7,4.6-1.6
					c0.9,0.2,1.8,0.4,2.6,0.4c-1.4,0.4-2.7,1.1-3.7,2.2c-0.6,0.6-1,1.5-1,2.4c0,0.1,0,0.2,0,0.3c-0.1,0.6-0.3,1.5-0.2,2.5
					c-4.2,1.4-6.8,7.1-7.6,10.3c-0.6,1.7-0.7,3.4-0.3,4.9c-0.5-0.4-1.1-0.7-1.7-1.1c-0.5-0.2-1-0.4-1.6-0.4c-4.2,0-7.1,2.7-7.5,6.7
					c-0.5,1.4-0.5,2.8-0.1,4.2c-1.3-0.4-2.2-0.7-2.6-0.8c-1.4-0.3-2.5-0.5-3.5-0.5c-2.9,0-4.7,1.2-6.6,2.5c-0.2,0.1-0.4,0.3-0.7,0.4
					c-0.2,0.1-0.4,0.3-0.5,0.4l-2.1,2.1c-0.8,0.1-1.7,0.3-2.6,0.9c-0.7-0.4-1-0.6-2-1.6c-0.5-0.9-1-1.7-1.4-2.3
					c-0.3-0.5-0.7-1-1-1.5c-0.4-3.8-3-6.5-6.5-6.5c-4,0-6.5,4.1-9.4,15.5c-0.8,2.6-1.1,4.5-1.1,6.2c0,0-0.1,0.1-0.1,0.1
					c-0.8-0.3-1.8-0.6-2.8-0.6c-0.4,0-0.9,0-1.3,0.1l-1.2-2.5c-0.3-0.7-0.9-1.2-1.6-1.6c0,0,0,0-0.1,0c0-0.2,0-0.6,0-0.8
					c0-0.5-0.1-1.1-0.4-1.6c-0.1-0.3-0.3-0.9-0.5-1.5c-0.1-0.2-0.1-0.5-0.2-0.8c0.9-0.6,1.5-1.2,2-1.7c0.7-0.7,1-1.5,1-2.5
					c0-1.1-0.4-2.4-1-3.6c2.4-0.4,3.4-1.7,3.8-2.8c2-2.9,0.5-5.9-0.1-7.1c-0.5-1-1.4-1.6-2.4-1.9c-0.4-0.2-1.1-0.5-2-0.5
					c-0.5,0-0.9,0.1-1.4,0.2c-0.6-0.7-1.2-1.3-1.9-1.8c0.5-1.2,1-2.6,1-4.1c0.2-0.6,0.3-1.2,0.5-1.7c0.7-2.4,1.6-5.2-1.5-8.3
					c0-0.1,0-0.4,0-0.6c0-3.2-2.3-5-3.5-6c-0.2-0.2-0.5-0.4-0.6-0.5c-0.4-0.4-0.9-0.7-1.4-0.8c-0.2-0.2-0.4-0.4-0.7-0.5
					c-2-3-5-4.7-8.8-4.7c-1.8,0-3.8,0.4-5.8,1.2c-0.4,0.1-0.8,0.2-1.2,0.2c-0.3,0-0.6-0.1-0.7-0.1c-1-2.1-2.6-3.7-4.1-4.9
					c0.1-1.1-0.2-2.4-1.4-3.9c0,0,0,0,0,0c-0.2-0.5-0.5-1-0.9-1.5c-1.4-1.4-4.2-3-7.5-3c-2.3,0-4.4,0-6.5,2
					c-0.3,0.3-0.6,0.7-0.8,1.1c-3,0.4-4.8,2.4-5.2,4.5c-1.1,2.7,0.2,4.8,1.4,6c-0.4,1.4-0.4,2.9-0.4,3.9c0,0.4,0,0.9,0,1.5
					c-0.2,0-0.4,0-0.5,0c-1.7,0-3.7,0.9-5.2,2H240c-0.3,0-0.9,0-1.4-0.1l-0.5-1c-0.1-0.1-0.1-0.3-0.2-0.4c-2.5-3.7-5.4-5.4-9.4-5.6
					c0-0.1,0-0.2,0-0.4c0-0.1,0.1-0.3,0.1-0.5c0.3-1.3,0.9-4.1-1.1-6.1c-1.5-1.5-3.1-2.8-8.3-3.9c-1.9-0.9-3.9-1.9-6.7-2.9
					c-3.7-2.2-7.4-3.3-10.6-3.3c-1.8,0-3.4,0.3-4.8,1h-0.6c-0.3-0.1-0.7-0.2-1.1-0.3c0.1-1.1,0.1-1.9,0.1-2.2c0-1-0.3-1.9-0.7-2.7
					c2.6-1.8,4.5-3.7,4.7-7.6c0.7-2.1,0.5-4.2-0.4-5.9c0.2-0.5,0.4-1.1,0.4-1.9c0-0.6-0.1-2.6-2-4.5C197,74.3,195.3,72.7,193,72.7
					L193,72.7z M358.7,152.9c0-0.4-0.1-0.7-0.2-1c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.3,0.2,0.6,0.4,0.9c0,0,0,0-0.1,0
					C359,152.8,358.9,152.8,358.7,152.9L358.7,152.9z M210.9,84.7h-2c-1.7,0-4.9,1.1-6.3,5.4c-0.1,0.4-0.2,0.7-0.2,1.1
					c0,4.3,2.8,7.5,6.5,7.5c3.6,0,6-2.1,6.4-5.4c0.6-1.2,0.7-2.7,0.1-4.3c-0.4-1.2-1.2-2.4-2.1-3.4
					C212.7,85.1,211.8,84.7,210.9,84.7L210.9,84.7z M329,96.7h-2c-0.9,0-1.8,0.4-2.5,1c-2,2-2,4.2-2,6.5c0,0.4,0.1,0.7,0.2,1.1
					c0.2,0.7,1.4,3.9,4,5.6c0,0.1,0.1,0.1,0.1,0.2c0.3,0.4,0.8,1.2,1.7,1.9c0.9,1.6,2.7,3.7,5.5,3.7c1.2,0,3.4,0,5.5-2
					c0.2-0.2,0.3-0.3,0.4-0.5c2.9-4.3,0.8-7.8-0.3-9.6c-0.2-0.3-0.4-0.6-0.5-0.9c-0.2-0.3-0.4-0.6-0.7-0.9
					C335.2,99.4,332.2,96.7,329,96.7L329,96.7z M315.5,118.5c-1.3,0-2.6,0.4-3.8,1.2c-0.8,0.1-1.6,0.4-2.2,1c-2,2-2,5-2,6.5
					c0,0.5,0.1,1.1,0.4,1.6c0.6,1.2,2.5,4.9,6.1,4.9h1c3.4,0,5.3-1.9,6.5-3c0.7-0.7,1-1.5,1-2.5v-2v-3c0-1.3-0.7-2.5-1.9-3.1
					C319.2,119.4,317.5,118.5,315.5,118.5L315.5,118.5z"/>
			</g>
			<g id="st0">
				<path d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,52.2c-2.1,0-5.6,0-7.5,3.3c-0.6,0.8-1,1.8-1.2,2.8c-1.8-2.2-3.9-3.9-7-3.9
					c-1.3,0-2.8,0.3-4.5,1c-0.1,0-0.2,0.1-0.2,0.1c-4.5,2.3-7.1,5.8-7.6,10.2c-0.9-1.2-2.1-2.5-4.1-2.5h-1c-0.3,0-0.6,0.1-0.9,0.2
					c-1.8,0.6-3.1,2.1-4.1,4.4c-1.2,2.9-1.8,7.4-0.9,10.1c0.2,3.3,2.5,5.4,6,5.4h1c1.6,0,2.8-0.5,3.7-1.2c0.6,1.7,1.7,3.2,3.2,5.2
					l0.6,0.8c0,0,0,0,0.1,0.1c3.4,4.3,5.2,6.5,8,7c1.1,1.1,2.5,2.1,4.3,2.1c2.8,0,5.5-1.3,8.1-3.9c0.1-0.1,0.3-0.3,0.4-0.5
					c0.9-1.4,4.2-2.9,7.8-2.9c0.7,0,1.5,0.1,2.1,0.2c0.2,0,0.4,0.1,0.6,0.1c3.4,0,6.4-0.1,8.1-1.9c0.1-0.1,0.3-0.3,0.4-0.5
					c2.7-1,4.1-2.4,4.4-4.6c0.1-0.1,0.3-0.2,0.4-0.3c1.6-0.5,2.8-1.7,3.8-2.7c1.2-1.2,2.1-3,2.3-4.9c1.6,1.9,4.1,3,7.7,3.6l5.6,2.8
					c0.5,0.2,0.9,0.5,1.3,0.7c1.3,0.8,2.8,1.6,5.1,1.6h1c5.5,0,9.4-3.1,12.1-5.9c0.1-0.1,0.3-0.3,0.4-0.5l1.9-2.8
					c1.9-2.1,1.9-4.9-0.2-7l-0.4-0.4c-0.2-0.4-0.4-0.8-0.7-1.2v-0.3c0-0.8-0.3-1.6-0.9-2.1c-1.9-1.9-3.9-1.9-5.1-1.9
					c-1.6,0-4.5,0-7,0.9c-0.2-0.4-0.5-0.7-0.9-1.1c-1.3-1.3-2.8-2-4.5-2c-2.1,0-4,0.9-6.7,2.3l-0.3-0.5c-0.1-0.3-0.3-0.6-0.6-0.8
					c-1.3-1.3-2.7-1.6-3.6-1.6c-0.7,0-1.8,0.2-2.9,1c-1,0.4-1.8,1.1-2.2,2c-1.3-1-3.1-1.9-5.1-2.9c-0.2-0.1-0.4-0.2-0.6-0.2
					c-0.8-0.2-1.6-0.4-2.3-0.6c-1.8-0.5-3.2-0.9-4.6-0.9c-0.9,0-1.6,0.1-2.4,0.4c-0.6,0-1,0-1.2-0.1c0,0,0,0,0,0
					c-2.3-2.3-5.6-4.5-7.8-5.6C363.9,52.3,363.5,52.2,363,52.2L363,52.2z M193,73.2c-0.8,0-1.6,0.3-2.1,0.9l-0.4,0.4
					c-0.5,0.2-0.9,0.5-1.2,0.7H189c-1.7,0-4.4,0-6.3,1.8c-0.7-0.4-1.6-0.8-2.7-0.8c-2.5,0-6,2.1-6,6c0,3.1,1.5,6.2,4,6.9
					c0.2,0.1,0.3,0.2,0.4,0.2c-0.5-0.1-0.9-0.1-1.5-0.1c-1.7,0-2.5,0.4-3.6,1.3c-1.6,0.7-2.6,2.4-3.1,3.9c0,0-0.1,0.1-0.2,0.1
					c-0.5,0.3-1.2,0.6-1.9,1.2c-2.6,1.5-3.9,4-4.9,6.1c-0.2,0.4-0.3,0.9-0.3,1.3c0,1.8,0,3.5,1,5v2.3c-1.1,2.5-2,6.4-2,8.7
					c0,0.5,0,1-0.1,1.6c-0.2,2.1-0.4,4.8,2.5,7.2c0.2,0.4,0.4,0.7,0.6,1c0.1,1.6,0.7,2.8,1.4,3.7c-2.8,3.8-3.4,13.5-3.4,13.6
					c0,1.2,0.4,2,0.9,2.5c-0.1,0.2-0.2,0.3-0.3,0.5c-0.3,0-0.6,0.1-0.9,0.3c-1.4,0.7-2.9,0.7-4.7,0.7c-0.2,0-0.3,0-0.5,0
					c-5.6,0.9-9.9,2-12.7,6.7c-0.2,0-0.3,0-0.5,0c-3.6,0-6.6,2.3-8.5,4.1c-0.6,0.6-0.9,1.3-0.9,2.1v3v1.8l-0.1,0.1
					c-0.7-0.5-1.5-1-2.5-1.6c-0.4-0.2-0.9-0.3-1.3-0.3c-3.1,0-7,0-9.6,1h-3.9c-0.3-0.1-0.5-0.2-0.8-0.3c-1.7-0.6-3.3-1.1-4.9-1.1
					c-1.1,0-2.2,0.2-3.1,0.7c-2.5,1.3-3.2,3.8-3.8,5.6c-0.2,0.7-0.4,1.3-0.6,1.7c-0.2,0.4-0.3,0.9-0.3,1.3c0,3.1,0,5.5,1.2,8.9
					c0.1,0.4,0.3,0.7,0.6,1c-0.1,0.7-0.3,2-0.7,3.5c0,0.2-0.1,0.4-0.1,0.6c0,1.5-0.9,4.3-1.8,6.9c-1.1,2.3-2.2,5.7-2.2,8.1
					c0,0,0,0.1,0,0.1c-0.1,0.1-0.2,0.1-0.3,0.2c-0.3,0.1-0.6,0.3-0.8,0.6c-0.5,0.5-3.2,3.6-3.8,6.2c-0.2,0.3-0.3,0.7-0.4,0.9
					c-0.3,0.7-0.4,1-0.7,1.1c-0.8,0.2-1.4,0.5-1.9,0.7c-0.1,0.1-0.3,0.1-0.4,0.2c-1.6,0-2.8,0.5-3.6,0.9c-0.1,0.1-0.3,0.1-0.4,0.2
					c-0.7,0.1-1.4,0.4-1.9,0.9c-0.1,0.1-0.4,0.3-0.6,0.5c-1,0.8-2.7,2.2-3.2,4.5c-0.6,0.9-0.9,2-0.9,3.1c0,1.2,0.3,2.3,0.9,3.3
					c0.1,0.3,0.2,0.5,0.3,0.7c0,0.1-0.1,0.5-0.3,1.1c-0.1,0.3-0.2,0.6-0.2,0.9v1.7c-3.6,2.5-3.9,4.6-4.8,9.8l-0.1,0.9
					C77,246.9,77,252,77,254.2c0,0.3,0.1,0.6,0.2,0.9l0.2,0.6c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2,0.1-0.6,0.2-0.9,0.3
					c-0.6,0.2-1.4,0.5-2.1,0.9c-4,0.3-5.6,2.6-6.7,5.8c-0.7,0.4-1.3,0.9-1.8,1.5c-2.2,1-3.4,4-3.4,5.7c0,2,1.5,4.7,2.9,6.1
					c0.1,0.1,0.2,0.3,0.4,0.5c0.9,1.1,2.6,3,5.7,3.4c0,0,0,0,0,0.1c0,0.5,0.1,0.9,0.3,1.3c1.4,2.8,5.6,5.8,7.9,7.1
					c1.1,1,2.6,2.4,5.2,2.5c0,0.2-0.1,0.4-0.1,0.6c0,0,0,0,0,0c-1.7,0-2.8,0.7-3.4,1.3c-0.3,0.3-0.9,0.9-1.3,1.8
					c-1.5,0.9-2.6,2.7-2.6,5.3c0,2.9,2.7,4.2,5.7,5.7c0.1,0.1,0.3,0.1,0.4,0.2c1.9,0.6,2.3,3.8,1,9.5c0,0,0,0,0,0.1
					c-0.4,2.2-0.8,3.8-0.9,4.2c-0.1,0.1-0.2,0.2-0.3,0.3c-1.3,1.3-3.9,3.9-3.9,7.1c0,3.2,1.7,4.9,3.8,5.9l-1.9,1.9
					c-2.1,2.1-3.5,3.5-4.7,7.2c-0.1,0.3-0.2,0.6-0.2,0.9c0,2,0,4.2,1,6v0.1c-0.8,1.3-1.3,3.1-0.8,4.8c0.3,1.2,0.9,2.2,1.9,3
					c0.2,1.9,1,3.3,2.1,4.3c-1,0.7-1.8,1.6-2.4,2.6c-5,2.3-9.5,9.4-11.2,12.6c-1.1,1.3-2,3.1-3,5c-1.5,1.6-7.5,8.2-7.5,11.7
					c0,0.9,0,2.3,0.8,3.7c-0.2,3.3,2,6.8,6.5,9.8c0.1,0.1,0.2,0.1,0.3,0.2c1.8,0.9,3.5,1.4,5.3,1.4c2.5,0,4.9-1,7.2-2.9
					c3.2,2.2,8.2,2.9,9.9,2.9c0.8,0,1.5-0.1,2.1-0.2c-0.1,0.2-0.1,0.3-0.2,0.5c-0.2,0.5-0.3,1-0.6,1.4c-0.2,0.4-0.3,0.9-0.3,1.3
					c0,2.2,0,4.2,1.9,6.1c0.2,0.2,0.5,0.4,0.8,0.6c0.8,0.4,1.6,0.6,2.4,0.6c2.4,0,3.9-1.7,4.7-2.7c0.2-0.2,0.3-0.4,0.4-0.5
					c0.1-0.1,0.3-0.3,0.4-0.4c0.7-0.3,2-0.6,2.6-0.7c4.2,0,6.9-2.7,8.1-3.9c0.1-0.1,0.3-0.3,0.4-0.5c1.4-2.1,4-4.2,5-4.7
					c4.1-0.4,9.8-5.1,11.8-7.9c0.2,0,0.4,0,0.7,0c5.4,0,7.2-2.8,7.7-5c2.1,0.1,2.4,0.4,4.1,2.1l2.4,2.4c0.4,1.3,1.4,2.4,2.6,3
					c0.1,0.1,0.1,0.1,0.2,0.2v2.3c0,1,0.3,2,0.7,2.9c0.1,0.4,0.3,0.9,0.3,1.1v0.4c-0.2,0.5-0.3,0.9-0.5,1.3
					c-0.7,1.8-1.5,3.8-0.2,6.5c1.4,2.9,4.8,4.2,7.9,4.6c0.4,0.4,0.8,0.7,1.1,0.8c0.3,0.2,0.4,0.3,0.5,0.4c0.6,0.6,1.3,0.9,2.1,0.9
					c0.8,0,2-0.3,3.1-0.9c0.2,0.1,0.4,0.3,0.6,0.4c-0.1,0.7-0.3,1.5-0.6,2.7c-0.9,3.4-0.5,5.2-0.1,6.6c0,0.1,0,0.1,0.1,0.2v1.4
					c-0.8,2.7-1.2,5.7,1,8.6c-0.1,3.3-0.4,6.9-0.9,10.2c-0.1,0.4-0.2,0.7-0.4,1c-0.8,2.3-1.7,4.8-0.7,7.3c0,1,0.1,2.1,0.4,3.1
					c0.3,1,0.9,1.8,1.6,2.3c0.1,1.5,0.2,3.1,0.7,4.7c-0.4,0.6-0.7,1.3-0.7,2.3c0,2.3,0.8,3.9,2,4.9c0.2,2.6,1.5,3.6,2.7,3.9
					c1,0.6,2.2,0.9,3.6,0.9c1.4,0,3.1-0.3,5.4-0.8c0.5-0.1,0.9-0.3,1.3-0.7c0,0,0,0,0,0c0.8,0,2.5,0.3,4.2,0.7c1.7,0.6,3,1,3.9,1.3
					c0.6,3.7,4,5.2,5.9,6.1c0.2,0.1,0.5,0.2,0.7,0.3c0.3,0.2,0.7,0.3,1.1,0.3c0,0,0.1,0.1,0.1,0.1c0.8,0.8,1.7,1.3,2.3,1.6
					c0.4,0.2,0.6,0.3,0.7,0.4c0.2,0.2,0.3,0.3,0.5,0.4c0.7,1.3,2.4,4.4,5.6,4.4c0.9,0,1.7-0.3,2.3-0.5c0.1,0.1,0.2,0.2,0.4,0.2
					c2.1,1,4.2,2.1,7.4,3.2c0.3,0.1,0.6,0.2,0.9,0.2c0.4,0,0.8,0,1.1,0c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.3,0.3,0.6,0.6,0.8
					c0.1,0.1,0.1,0.1,0.1,0.2c0.1,2.7,1.5,4.4,3,5.5c0.4,4.4,4.8,5.2,8.3,5.8c0.7,0.1,1.3,0.2,2.1,0.4c0.2,0,0.4,0.1,0.6,0.1
					c1,0,1.7,0.1,2,0.2c1.1,1.1,2.9,2.8,6,2.8c0.3,0,0.6,0.1,0.7,0.1c1.2,2.1,2.6,3.4,4.9,4.6c0.4,0.2,0.9,0.3,1.3,0.3h1
					c0.5,0.2,1,0.4,1.7,0.4c0.8,0,1.4-0.3,1.8-0.5c0.4,0,0.7-0.2,1.1-0.3c0.9,0.3,1.8,0.5,2.6,0.5c1,0,2-0.3,2.7-0.7
					c0.2,0.1,0.3,0.2,0.4,0.3c0.3,0.2,0.4,0.3,0.5,0.4c0.6,0.6,1.3,0.9,2.1,0.9h3c1.2,0,2-0.4,2.6-1c0.3,0,0.6,0.1,0.9,0.2
					c0.7,0.1,1.4,0.2,2.1,0.2c0.7,0,1.3-0.1,1.8-0.3c1.3,1.6,3.1,2.5,4.6,2.8c1.5,0.7,3.2,1.5,5.1,1.5c1.1,0,2.1-0.2,3.1-0.7
					c0.3-0.1,0.6-0.3,0.8-0.6c0.8-0.8,1.5-1.8,1.9-2.9c0,0,0.1,0,0.1,0c1.5,0,2.8-0.4,4-1.3h0.3c2.5,1,5.6,1,7.6,1h5
					c1.2,0,3.2,0,5.1-1.9c1.9-1.9,1.9-3.9,1.9-5.1c0-0.4-0.1-0.9-0.3-1.2c0.1-1.2,0.9-4.3,1.4-6.1c0.2-0.8,0.4-1.6,0.6-2.3
					c0.4,0.1,0.8,0.1,1.1,0.1c3.9,0,5.2-3.3,5.8-5.1c3.2-5.3-0.1-10.3-1.2-12c-0.2-0.3-0.4-0.5-0.7-0.7c-0.1-0.6-0.4-1.2-0.6-1.7
					c1.1-1.4,2.6-3.7,2.8-5.5c0.2-0.2,0.5-0.6,0.8-0.9c3.2-1.9,3.2-5.3,3.2-6.4c0-2.5-1.1-3.7-2-4.4c1-1.1,1.7-2.5,1.9-3.7
					c0.4-1,0.7-1.7,0.9-2.4c0.2-0.7,0.4-1.3,0.8-2.1c0.2-0.3,0.3-0.7,0.3-1c3.2-2.2,4-7.4,4-9.2c1.2-0.9,1.9-2.2,2-3.6
					c0.4-0.7,1.5-2.3,2.4-3.4c2.7,0.1,8,0.5,16,1.9l3.9,1c0.2,0.1,0.5,0.1,0.7,0.1c3.8,0,4.8-2.5,5-4.2l0.7-1.5
					c0.2-0.4,0.3-0.7,0.3-1.1c0-0.1,0.1-0.3,0.2-0.4c0.2-0.4,0.4-0.9,0.5-1.4c0.7,0.1,1.7,0.3,2.7,0.5c2.1,0.4,3.9,0.7,5.5,0.7
					c2.2,0,4.1-0.5,6.1-1.8c0.7-0.2,1.3-0.5,1.8-0.7c0.1-0.1,0.3-0.1,0.4-0.2c0.2,0,0.3,0,0.5-0.1c1.8-0.5,4.6-1.7,5.8-4.8
					c0.7,0.6,1.5,0.9,2.5,0.9c3.2,0,4.4-1.4,4.8-2.7c1.7-2.3,1.8-5.6,0.2-9.9v-9.1c0.1-0.6,0.2-1.2,0.4-1.7c0.6-2.9,1.4-6.4-1.4-8.4
					v-0.8c0.4-1.7,0.9-3,1.2-4.1c0.1-0.2,0.1-0.4,0.2-0.6c1.9-0.5,3.6-1.6,4.2-3.1l2.4-2.4c1.3-1.3,5.3-5.7,5.8-11
					c0.3-0.4,0.5-0.9,0.7-1.4c1.8-1.6,3.3-4.4,2.8-9.2c0.9-0.3,1.6-0.7,2.1-1.2c0.7,0.3,1.3,0.7,1.5,0.9c0,0.4,0.1,0.8,0.3,1.1
					c1.2,2.3,3.5,5,6.6,5.6c0.7,0.4,1.4,0.5,2,0.5c0.8,0,1.5-0.2,2.1-0.6c1,1.3,2.7,2.1,5,2.1c0.7,0,1.8-0.2,2.8-0.8
					c0.2,0,0.5,0,0.7,0c3.1,0,4.6-1.9,5.3-3.2c0.1,0,0.2,0,0.2,0c0.9,0.6,1.8,0.8,2.6,0.9c1.2,2.8,2.9,4.1,5.5,4.1
					c0.8,0,1.6-0.3,2.1-0.9c0.4-0.4,0.9-0.9,1.2-1.1c0.7-0.1,1.3-0.4,1.8-0.9c1.3-1.3,1.8-2.8,1.9-3.9c0.3-0.9,1.7-3.7,3.1-5.1
					c0.1-0.1,0.3-0.3,0.4-0.5c2.3-3.5,2.6-5.5,1.7-8.6c0.5-0.5,0.8-1.1,0.8-1.8c0-0.1,0.1-0.3,0.2-0.4c0.3-0.7,0.8-1.6,0.8-2.8
					c0-2.1-1.4-3.7-1.9-4.1c0,0-0.1-0.1-0.1-0.1c0-0.4-0.1-0.7-0.3-1.1c-0.4-0.7-0.5-1.5-0.8-2.4c-0.3-1.1-0.6-2.3-1.2-3.6
					c-0.1-0.3-0.3-0.5-0.5-0.7c-0.1-0.2-0.2-0.7-0.2-1.5c1-3.3,1-6.4,1-8.4c0-1.3,0-2.5,0.1-2.8c0.1-0.1,0.6-0.6,1-1.1
					c2-2,3.1-4,3.6-6.4c0.4,0.2,0.8,0.3,1.3,0.3c2.4,0,4-0.5,5.1-1.2c0.6,0.6,1.2,1.1,1.8,1.5c0.4,0.3,0.8,0.7,1,0.9
					c0.6,0.6,1.3,0.9,2.1,0.9c2.6,0,4.6-1.3,5.4-3.7c0.5-1.3,0.6-2.7,0.6-3.8c0.6-1.8,0.9-3.7,0.4-5.7c2-0.4,5.7-1.4,8.1-3.3
					c1.9,2.7,5.1,4.5,7.5,4.5c3.2,0,4.4-1.4,4.8-2.7c0.8-1.2,1.1-2.3,1.2-3.3c0,0,0,0,0.1,0c0.8,0,1.5-0.3,2.1-0.9
					c0.2-0.2,0.6-0.5,0.9-0.7c0.7-0.5,1.5-1.1,2.1-1.9c0.4-0.2,0.7-0.4,1-0.6c0.1,0,0.3,0,0.4-0.1c1.4,1.1,2.8,1.3,3.6,1.3
					c1.1,0,1.9-0.3,2.2-0.5c3-1.5,3.9-4.3,2.9-8.4c1.8-1.9,1.8-4.6,1.8-6.3c0-0.8-0.3-1.6-0.9-2.1c-0.7-0.7-1.4-1.4-2.3-2
					c-0.1-0.3-0.3-0.6-0.6-0.9c-0.1-0.2-0.2-0.6-0.2-1l0-1c1.2-0.9,2-2.3,2.5-3.1c2.9-2.9,3.5-3.6,3.5-5.8c0-1.5-0.7-3.1-2-4.1v-0.1
					c1.2-0.2,2.3-0.6,3-1.3c1.7-0.8,3-2.6,3-4.5c0-1.5,0-2.8-0.5-4.3c0.9-0.4,1.7-1,2.3-1.7c3-0.2,9-0.9,12.6-3.3
					c0.2,0.1,0.4,0.1,0.6,0.2c0.3,0.1,0.7,0.2,0.9,0.3c0.4,0.4,1.5,1.3,3.4,1.3c1,0,2-0.3,3-0.8c0.3-0.1,0.6-0.3,0.8-0.6
					c0.8-0.8,1.3-1.7,1.6-2.3c0.2-0.4,0.3-0.6,0.4-0.7c0.3-0.3,0.6-0.7,0.7-1.1c0.2-0.2,0.7-0.7,1.8-1.5l3-2
					c0.2-0.1,0.3-0.2,0.5-0.4c1.9-1.9,1.9-3.9,1.9-5.1c0-0.6-0.2-1.2-0.5-1.7l-1.6-2.4c-0.2-0.7-0.5-1.3-0.7-1.7c0,0,0,0,0-0.1
					c0.8-2.9-0.4-6.4-2.3-11c-1.4-5.2-3.8-5.9-8.6-6.9l-0.7-0.1c-0.2,0-0.4-0.1-0.6-0.1c-0.6,0-1.1,0-1.5-0.1
					c-0.4-0.8-0.9-1.5-1.5-2.1c1.1-1,1.9-2.6,1.9-4.8c0-2.3-0.9-3.9-1.8-5c0.5-0.6,0.8-1.3,0.8-2v-0.1c2-1.2,4.5-3.2,7.4-6
					c3.8-0.6,8-3.8,9.3-6.5c0.1-0.1,0.1-0.2,0.1-0.3c1.7,0,3.1-0.2,5.3-1.2c4.8-1.7,4.8-4.7,4.8-7.8c0-1.8,0-5.9-2.6-8.4
					c0-0.9-0.2-1.6-0.3-2.2c0-0.2-0.1-0.4-0.1-0.5c0-0.8-0.3-1.5-0.9-2c-0.8-0.8-1.7-1.7-2.7-2.2c0-0.9-0.1-1.8-0.5-2.7
					c-0.6-1.4-1.7-2.6-3.1-3.4c-0.2-0.2-0.5-0.4-0.7-0.6c-0.3-0.3-0.9-0.7-1.1-1c0-0.5-0.1-0.9-0.3-1.3l-1-2
					c-0.4-0.9-1.2-1.5-2.2-1.6c-0.9-0.7-2.1-1-3.5-1c-0.6,0-1.2,0.2-1.7,0.5l-3,2c-0.2,0.1-0.3,0.2-0.5,0.4
					c-0.4,0.4-0.9,0.9-1.2,1.1c-2.1,0.1-3.5,0.9-4.7,1.6c-0.3,0.2-0.7,0.4-1.1,0.6c-1.2,0.4-1.8,0.5-2.3,0.5c-0.4,0-0.8-0.1-1.5-0.3
					v-2.5c0-1.8-0.5-3-0.9-3.8c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.7-0.4-1.4-0.9-1.9c-0.1-0.1-0.2-0.3-0.4-0.7c-0.2-0.4-0.5-1-1-1.6
					c-0.1-0.2-0.1-0.4-0.2-0.5c0.9,0,1.7-0.2,2.3-0.5c0.5,0.4,1.1,0.8,1.8,1.2c0.4,0.2,0.9,0.3,1.3,0.3c3.4,0,5.5-2.8,5.9-5.2
					c1.2-2.6,1.1-4.5-0.2-7.2c-0.1-0.3-0.3-0.6-0.6-0.8c-0.8-0.8-1.7-1.7-2.8-2.3c-0.1-0.6-0.3-1-0.5-1.3c-0.5-0.7-1.5-1.9-3.8-2.2
					c-0.6-0.3-1.3-0.5-1.9-0.5c-2.5,0-4.5,1.9-5,4.4c-1.1,0.1-2.5,0.3-3.9,1.5c-1.6,0.9-2.6,2.4-2.8,4.2c-0.1,0.5-0.1,0.9,0,1.4
					c-0.9-0.3-1.8-0.5-2.8-0.8l-0.7-0.2c-0.1,0-0.2-0.1-0.3-0.1c-2.5-0.4-4.3-0.7-6.1-0.7c-2.5,0-4.6,0.5-8.2,1.8
					c-5.2,1.3-7.9,2.4-9.3,3.8c0,0-0.1,0.1-0.1,0.1c-4.2,0.1-6.9,1.9-9.8,4.8c-5.6,4.7-5.7,4.7-9.3,6.5c-0.3,0.1-0.6,0.3-0.8,0.6
					c-0.3,0.3-0.7,0.5-1.1,0.8c-0.9-0.7-2-1.3-3.5-1.6c-0.1-0.1-0.3-0.2-0.4-0.3c-1-0.8-2.1-1.7-3.3-3.4c-0.1-0.2-0.2-0.3-0.4-0.5
					l-1.4-1.4c-0.6-1.5-2-2.4-3.4-2.5l-9.6-1.4c0.2-0.6,0.3-1.1,0.3-1.6c0-6.2-6-7.2-11.3-7.9c-3.3-1.1-5.7-1.6-7.9-1.6
					c-2.2,0-4.2,0.5-6.4,1.5c-1.8,0-4.1-0.1-6.4-0.8c-0.2-0.1-0.4-0.1-0.6-0.1l-8-1c-0.1,0-0.2,0-0.4,0h-1h-3c-1.1,0-4.2,0-7.5,1
					c-4-0.1-11-1.1-14.2-2.7c-1-0.5-2-0.7-3.1-0.7c-1.9,0-4,0.9-5.4,2.3c-0.5,0.5-0.9,1.3-0.9,2.1c0,0.1,0,0.2-0.1,0.3
					c-0.1,0.6-0.3,1.6-0.1,2.8c-4.3,1.1-6.8,6.8-7.7,10.1c-0.8,2.5-0.6,4.8,0.4,6.6c-0.8-1-1.9-1.8-3.2-2.5
					c-0.4-0.2-0.9-0.3-1.3-0.3c-4,0-6.7,2.5-7,6.3c-0.5,1.5-0.5,3,0,4.4c0.1,0.2,0.2,0.4,0.3,0.6c-2-0.8-3.4-1.1-3.6-1.2
					c-1.3-0.3-2.4-0.5-3.4-0.5c-2.7,0-4.4,1.1-6.3,2.4c-0.2,0.1-0.4,0.3-0.7,0.5c-0.2,0.1-0.3,0.2-0.5,0.4l-2.2,2.2
					c-0.8,0.1-1.8,0.3-2.7,0.9h-0.2c-0.9-0.5-1.2-0.7-2.3-1.8c-0.5-0.9-1-1.7-1.5-2.4c-0.4-0.6-0.7-1.1-1-1.6
					c-0.3-3.6-2.7-6.2-6-6.2c-3.7,0-6.1,4-8.9,15.2c-0.9,2.6-1.1,4.6-1.1,6.3c-0.2,0.2-0.3,0.3-0.5,0.5c-1.1-0.5-2-0.7-2.9-0.7
					c-0.5,0-1,0.1-1.5,0.2l-1.4-2.8c-0.3-0.6-0.8-1.1-1.3-1.3c-0.1-0.1-0.2-0.1-0.3-0.2c0-0.3,0-0.7,0-1.1c0-0.5-0.1-0.9-0.3-1.3
					c-0.2-0.3-0.3-1-0.5-1.6c-0.1-0.3-0.2-0.7-0.3-1.1c1-0.6,1.7-1.3,2.3-1.9c0.6-0.6,0.9-1.3,0.9-2.1c0-1.2-0.6-2.7-1.3-4
					c2.7-0.2,3.8-1.5,4.1-2.7c1.9-2.7,0.4-5.5-0.1-6.6c-0.4-0.9-1.2-1.4-2.1-1.6c-0.4-0.2-1-0.5-1.8-0.5c-0.6,0-1.1,0.2-1.5,0.4
					c-0.7-0.9-1.5-1.6-2.4-2.2c0.6-1.2,1.1-2.7,1.2-4.3c0.2-0.7,0.3-1.2,0.5-1.7c0.7-2.4,1.5-5-1.4-7.8c-0.1-0.1-0.1-0.1-0.1-0.1
					c0-0.1,0-0.5,0-0.8c0-3-2.1-4.7-3.3-5.6c-0.2-0.2-0.5-0.4-0.6-0.5c-0.4-0.4-0.8-0.6-1.3-0.8c-0.2-0.2-0.5-0.4-0.8-0.6
					c-1.9-2.9-4.8-4.5-8.4-4.5c-1.8,0-3.7,0.4-5.7,1.2c-0.5,0.2-0.9,0.2-1.4,0.2c-0.5,0-0.9-0.1-1.1-0.3c-1-2.2-2.6-3.9-4.3-5.1
					c0.2-1-0.1-2.3-1.3-3.8c0,0-0.1-0.1-0.1-0.1c-0.1-0.5-0.4-1-0.8-1.3c-1.4-1.4-4.2-2.9-7.1-2.9c-2.2,0-4.2,0-6.1,1.9
					c-0.3,0.3-0.6,0.7-0.9,1.2c-3,0.3-4.7,2.2-5,4.2c-1,2.6,0.4,4.6,1.5,5.7c-0.5,1.4-0.5,2.9-0.5,4.1c0,0.5,0,1.2,0.1,2.1
					c-0.4-0.1-0.7-0.1-1.1-0.1c-1.6,0-3.6,0.9-5.1,2H240c-0.8,0-1.4,0-1.7-0.1l-0.6-1.2c-0.1-0.1-0.1-0.2-0.2-0.3
					c-2.5-3.7-5.3-5.3-9.5-5.3c-0.1,0-0.1,0-0.2,0c0.1-0.3,0.2-0.6,0.2-0.9c0-0.1,0.1-0.4,0.1-0.5c0.3-1.2,0.9-3.8-1-5.7
					c-1.5-1.5-3-2.7-8.1-3.7c-1.9-0.9-3.9-1.9-6.7-2.9c-3.7-2.2-7.3-3.3-10.4-3.3c-1.7,0-3.3,0.3-4.7,1h-0.8c-0.4-0.1-1-0.2-1.6-0.3
					c0.1-1.3,0.1-2.3,0.1-2.7c0-1.1-0.4-2.1-0.9-2.8c2.8-1.9,4.7-3.6,4.8-7.5c0.8-2,0.4-4.1-0.4-5.8c0.2-0.5,0.4-1.1,0.4-1.9
					c0-1.1-0.5-2.7-1.9-4.1C196.7,74.6,195.1,73.2,193,73.2L193,73.2z M358.3,153.4c0-1.2-0.4-2.3-1-3.4c0.5,0.6,1.2,1.2,2,1.6
					c0.2,0.7,0.6,1.3,1,1.8c-0.4-0.1-0.7-0.1-1.1-0.1C358.8,153.3,358.5,153.4,358.3,153.4L358.3,153.4z M210.9,85.2h-2
					c-1.5,0-4.5,1.1-5.8,5.1c-0.1,0.3-0.2,0.6-0.2,0.9c0,4.1,2.5,7,6,7c3.3,0,5.5-1.9,5.9-5c1.1-2.2,0.1-5.2-1.8-7.1
					C212.5,85.5,211.7,85.2,210.9,85.2L210.9,85.2z M364,95.2h-1c-3.9,0-5.6,2.5-6.7,4.7c-0.2,0.4-0.3,0.9-0.3,1.3
					c0,1.3,0.1,2.7,1,4.8c0.2,2.3,1.5,4,3.4,4.8c0.2,0.3,0.4,0.6,0.7,0.8c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.1,0,0.1,0,0.2
					c-0.4,0.1-0.8,0.4-1.1,0.7c-0.1,0.1-0.4,0.3-0.6,0.5c-1,0.8-2.7,2.2-3.2,4.5c-0.7,1.2-0.7,2.6,0,3.9c0.5,0.9,1.2,1.6,1.8,2.3
					c0.6,0.6,1.3,0.9,2.1,0.9h1c1.4,0,3.1-0.7,4.5-1.6c2.1,0.6,4.4,0.6,6.2,0.6c4.9,1,8,1.4,10.4,1.4c4.1,0,6.7-1.5,7.7-4.4
					c0.1-0.3,0.2-0.6,0.2-0.9c0-1.2-0.5-2.2-0.8-2.8c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.7-0.3-1.3-0.8-1.8c-0.1-0.1-0.2-0.4-0.3-0.7
					c-0.5-1.2-1.4-2.9-3.4-5.1c-3.1-5.1-3.7-5.8-8.7-7.1c-0.1,0-0.2,0-0.2,0c-1.2-0.2-2.2-0.3-3.2-0.3c-0.5,0-0.9,0-1.3,0.1
					c0-0.4-0.1-0.8-0.3-1.2C369.6,98.6,367.9,95.2,364,95.2L364,95.2z M329,97.2h-2c-0.8,0-1.6,0.3-2.1,0.9
					c-1.9,1.9-1.9,3.9-1.9,6.1c0,0.3,0.1,0.6,0.2,0.9c0.2,0.6,1.4,3.8,3.9,5.4c0.1,0.1,0.1,0.2,0.2,0.3c0.3,0.4,0.8,1.2,1.6,1.8
					c0.9,1.5,2.5,3.6,5.1,3.6c1.2,0,3.2,0,5.1-1.9c0.1-0.1,0.3-0.3,0.4-0.5c2.7-4,0.7-7.3-0.3-9.1c-0.2-0.3-0.4-0.6-0.5-0.9
					c-0.1-0.3-0.3-0.6-0.6-0.8C334.9,99.9,332,97.2,329,97.2L329,97.2z M315.5,119c-1.3,0-2.5,0.4-3.6,1.2c-0.8,0-1.5,0.3-2,0.9
					c-1.9,1.9-1.9,4.7-1.9,6.1c0,0.5,0.1,0.9,0.3,1.3c0.6,1.2,2.3,4.7,5.7,4.7h1c3.2,0,5-1.7,6.1-2.9c0.6-0.6,0.9-1.3,0.9-2.1v-2v-3
					c0-1.1-0.6-2.2-1.7-2.7C319,119.8,317.4,119,315.5,119L315.5,119z"/>
			</g>
			<g id="st1">
				<path id="st2" d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,52.7c-2.1,0-5.3,0-7.1,3.1c-0.9,1-1.3,2.4-1.2,4c-1.9-2.7-4-4.8-7.4-4.8
					c-1.3,0-2.7,0.3-4.3,0.9c-0.1,0-0.1,0.1-0.2,0.1c-4.8,2.4-7.4,6.3-7.4,11.2c-0.2-0.2-0.3-0.4-0.5-0.7c-0.9-1.2-2.1-2.8-4-2.8h-1
					c-0.3,0-0.5,0-0.8,0.1c-1.6,0.5-2.9,1.9-3.8,4.1c-1.2,2.8-1.7,7.3-0.9,9.8c0.2,3.1,2.3,5,5.5,5h1c1.9,0,3.1-0.7,4-1.6
					c0.6,2,1.7,3.6,3.4,5.8l0.6,0.8c0,0,0,0,0,0.1c3.4,4.2,5.1,6.4,7.8,6.9c1.1,1.1,2.4,2.1,4.1,2.1c2.7,0,5.3-1.3,7.8-3.7
					c0.1-0.1,0.2-0.2,0.3-0.4c1-1.5,4.4-3.1,8.2-3.1c0.8,0,1.5,0.1,2.2,0.2c0.2,0,0.3,0,0.5,0c3.3,0,6.2-0.1,7.8-1.7
					c0.2-0.2,0.3-0.3,0.4-0.5c2.7-1,4-2.3,4.3-4.5c0.2-0.1,0.4-0.3,0.6-0.4c1.5-0.4,2.6-1.5,3.7-2.6c1.5-1.5,2.5-3.8,2-6.2
					c1.4,2.6,4.1,4.1,8.4,4.9l5.7,2.8c0.5,0.2,0.9,0.5,1.3,0.7c1.3,0.8,2.7,1.6,4.8,1.6h1c5.3,0,8.9-2.9,11.8-5.7
					c0.1-0.1,0.2-0.2,0.3-0.4l1.9-2.8c1.8-2,1.7-4.4-0.2-6.3l-0.5-0.5c-0.2-0.5-0.4-0.9-0.8-1.2v-0.5c0-0.7-0.3-1.3-0.7-1.8
					c-1.7-1.7-3.6-1.7-4.8-1.7c-1.7,0-4.7,0-7.2,1.1c-0.2-0.4-0.6-0.9-1-1.3c-1.2-1.2-2.6-1.8-4.2-1.8c-2,0-4,1-6.7,2.4
					c-0.1,0-0.1,0.1-0.2,0.1l-0.5-1c-0.1-0.2-0.3-0.5-0.5-0.6c-1.2-1.2-2.5-1.5-3.3-1.5c-0.7,0-1.6,0.2-2.6,0.9
					c-1.2,0.4-1.9,1.3-2.2,2.4c-1.3-1.2-3.2-2.1-5.5-3.3c-0.2-0.1-0.3-0.1-0.5-0.2c-0.8-0.2-1.6-0.4-2.3-0.6
					c-1.7-0.5-3.1-0.9-4.5-0.9c-0.8,0-1.6,0.1-2.3,0.4c-0.8,0-1.3-0.1-1.6-0.1c0-0.1-0.1-0.1-0.1-0.2c-2.3-2.3-5.5-4.4-7.6-5.5
					C363.8,52.8,363.4,52.7,363,52.7L363,52.7z M193,73.7c-0.7,0-1.3,0.3-1.8,0.7l-0.5,0.5c-0.6,0.2-1,0.5-1.3,0.8H189
					c-1.8,0-4.4,0-6.2,1.9c-0.7-0.5-1.7-0.9-2.8-0.9c-2.2,0-5.5,2-5.5,5.5c0,2.8,1.4,5.8,3.7,6.4c1.4,0.8,2.1,1.5,3,2.4
					c0.2,0.2,0.5,0.4,0.7,0.6c-1.1-0.9-2.6-1.9-4.9-1.9c-1.6,0-2.3,0.3-3.3,1.2c-1.6,0.6-2.6,2.4-3,3.8c-0.1,0.1-0.2,0.1-0.3,0.2
					c-0.5,0.3-1.2,0.6-1.8,1.2c-2.5,1.4-3.7,3.8-4.8,5.9c-0.2,0.3-0.3,0.7-0.3,1.1c0,1.8,0,3.4,1,4.9v2.6c-1.1,2.4-2,6.3-2,8.6
					c0,0.5,0,1.1-0.1,1.6c-0.2,2-0.4,4.6,2.4,6.9c0.2,0.5,0.5,0.8,0.7,1.1c0.1,1.7,0.8,3,1.5,3.9c-2.9,3.3-3.5,13.5-3.5,13.6
					c0,1.3,0.6,2.1,1,2.5c-0.2,0.3-0.4,0.6-0.6,1c-0.4,0-0.7,0.1-1,0.3c-1.5,0.7-3.1,0.7-4.9,0.7c-0.1,0-0.3,0-0.4,0
					c-5.6,0.9-9.8,2-12.5,6.7c-0.2,0-0.5,0-0.7,0c-3.4,0-6.3,2.2-8.1,4c-0.5,0.5-0.7,1.1-0.7,1.8v3v2l-0.6,0.6
					c-0.7-0.6-1.6-1.2-2.8-1.8c-0.3-0.2-0.7-0.3-1.1-0.3c-3.1,0-7,0-9.5,1h-4.1c-0.3-0.1-0.6-0.2-0.9-0.3c-1.7-0.6-3.2-1.1-4.8-1.1
					c-1,0-2,0.2-2.9,0.7c-2.3,1.2-3,3.6-3.5,5.3c-0.2,0.7-0.4,1.3-0.6,1.8c-0.2,0.3-0.3,0.7-0.3,1.1c0,3.1,0,5.4,1.1,8.8
					c0.1,0.4,0.3,0.7,0.6,1c0,0,0,0,0,0c-0.1,0.7-0.3,2-0.7,3.7c0,0.2,0,0.3,0,0.5c0,1.6-1,4.5-1.8,7c-1.1,2.3-2.2,5.5-2.2,8
					c0,0.1,0,0.3,0,0.4c-0.1,0.1-0.3,0.2-0.6,0.4c-0.2,0.1-0.5,0.3-0.6,0.5c-0.8,0.8-1.7,2-2.2,2.9c-0.9,1.5-1.3,2.4-1.4,3.1
					c-0.2,0.3-0.3,0.6-0.4,0.9c-0.4,0.9-0.5,1.1-0.9,1.4c-0.7,0.1-1.4,0.4-1.9,0.7c-0.2,0.1-0.4,0.2-0.5,0.2c-1.6,0-2.8,0.5-3.5,0.8
					c-0.2,0.1-0.4,0.2-0.5,0.2c-0.6,0-1.2,0.3-1.7,0.7c-0.1,0.1-0.4,0.3-0.6,0.5c-1,0.8-2.6,2.1-3,4.3c-1.1,1.8-1.1,4.3,0.1,6
					c0.1,0.3,0.2,0.5,0.3,0.7c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0.4-0.3,1.3c-0.1,0.3-0.1,0.5-0.1,0.8v2
					c-3.6,2.5-3.9,4.4-4.8,9.6l-0.1,0.9c-1,4.3-1,9.3-1,11.5c0,0.3,0,0.5,0.1,0.8l0.3,0.9l-0.1,0c-0.2,0.1-0.5,0.3-0.6,0.5
					c-0.1,0.1-0.7,0.3-1,0.4c-0.6,0.2-1.4,0.5-2.1,0.9c-3.9,0.3-5.4,2.4-6.5,5.7c-0.7,0.4-1.3,1-1.9,1.6c-2.1,0.9-3.2,3.7-3.2,5.2
					c0,1.9,1.4,4.5,2.7,5.8c0.1,0.1,0.3,0.3,0.4,0.5c0.9,1.1,2.6,3,5.8,3.2c0,0.1,0,0.3,0,0.5c0,0.4,0.1,0.8,0.3,1.1
					c1.3,2.6,5.4,5.7,7.7,6.9c1,1,2.5,2.3,5.1,2.5c0.2,0.1,0.3,0.1,0.4,0.1c-0.1,0.4-0.2,0.9-0.3,1.4c-0.1,0-0.3,0-0.4,0
					c-1.5,0-2.5,0.6-3,1.2c-0.3,0.3-0.9,0.9-1.3,1.8c-1.4,0.8-2.5,2.5-2.5,4.9c0,2.5,2.6,3.9,5.4,5.2c0.1,0.1,0.2,0.1,0.3,0.1
					c0.8,0.3,3.3,1.1,1.4,10.1c0,0,0,0,0,0.1c-0.5,2.4-0.8,4.1-1,4.4c0,0-0.2,0.2-0.3,0.3c-1.2,1.2-3.7,3.7-3.7,6.8
					c0,3.2,1.9,4.8,4.2,5.8l-2.4,2.4c-2.1,2.1-3.4,3.4-4.6,7c-0.1,0.3-0.1,0.5-0.1,0.8c0,2,0,4.1,1,5.8v0.3
					c-0.8,1.2-1.3,2.9-0.9,4.5c0.3,1.2,0.9,2.1,1.9,2.8c0.1,2.2,1.2,3.6,2.5,4.6l0,0c-1.2,0.7-2.3,1.8-2.9,2.9
					c-4.9,2.2-9.6,9.5-11.2,12.5c-1.1,1.2-2,3.1-3,5c-1.4,1.5-7.4,8.1-7.4,11.5c0,0.9,0,2.2,0.8,3.6c-0.3,3.2,1.9,6.5,6.3,9.5
					c0.1,0.1,0.2,0.1,0.3,0.2c1.8,0.9,3.4,1.3,5,1.3c2.5,0,4.8-1,7.1-3.1c3.1,2.3,8.2,3,10,3c1.2,0,2.2-0.2,2.9-0.6
					c-0.2,0.5-0.4,1-0.6,1.5c-0.2,0.5-0.4,1-0.6,1.5c-0.2,0.3-0.3,0.7-0.3,1.1c0,2.2,0,4,1.7,5.8c0.2,0.2,0.4,0.3,0.6,0.5
					c0.7,0.4,1.4,0.5,2.2,0.5c2.2,0,3.5-1.6,4.3-2.5c0.2-0.2,0.3-0.4,0.4-0.5c0.1-0.1,0.3-0.3,0.4-0.5c0.7-0.3,2.3-0.8,2.9-0.8
					c4,0,6.6-2.6,7.7-3.7c0.1-0.1,0.2-0.2,0.3-0.4c1.5-2.2,4.3-4.4,5.3-4.9c3.9-0.3,9.9-5.2,11.6-7.9c0.2,0,0.5-0.1,1-0.1
					c5.3,0,6.9-2.8,7.4-5c2.5,0,2.9,0.3,4.9,2.3l2.5,2.5c0.4,1.3,1.3,2.3,2.5,2.9c0.1,0.1,0.2,0.3,0.3,0.4v2.5
					c0,0.9,0.3,1.9,0.6,2.8c0.1,0.4,0.3,1,0.4,1.3v0.5c-0.2,0.5-0.4,1-0.5,1.4c-0.7,1.8-1.4,3.6-0.2,6.1c1.4,2.8,4.6,4,7.7,4.3
					c0.4,0.4,0.9,0.7,1.1,0.9c0.3,0.2,0.5,0.3,0.6,0.5c0.5,0.5,1.1,0.7,1.8,0.7c0.9,0,2.1-0.3,3.2-1c0.3,0.3,0.7,0.5,1.1,0.8
					c-0.1,0.7-0.4,1.7-0.7,3.1c-0.8,3.3-0.5,5-0.1,6.4c0,0.1,0.1,0.2,0.1,0.3v1.5c-0.8,2.6-1.2,5.5,1,8.3c-0.1,3.4-0.4,7.1-0.9,10.5
					c-0.1,0.4-0.2,0.7-0.4,1.1c-0.8,2.4-1.6,4.7-0.7,7.1c0,1.5,0.2,4.1,2,5.3c0,1.6,0.2,3.4,0.7,5c-0.4,0.5-0.7,1.2-0.7,2.2
					c0,2.2,0.8,3.8,2,4.7c0.2,2.5,1.3,3.4,2.5,3.7c0.9,0.6,2,0.9,3.4,0.9c1.3,0,3-0.3,5.2-0.8c0.4-0.1,0.8-0.3,1.2-0.7c0,0,0,0,0,0
					c0,0,0.1,0,0.2,0c0.8,0,2.3,0.3,4.4,0.7c1.9,0.6,3.4,1.1,4.2,1.4c0.4,3.6,3.6,5.1,5.6,6c0.2,0.1,0.5,0.2,0.7,0.3
					c0.3,0.2,0.7,0.3,1.1,0.3c0.1,0.1,0.2,0.2,0.3,0.3c0.8,0.8,1.6,1.2,2.1,1.5c0.4,0.2,0.6,0.3,0.9,0.5c0.2,0.2,0.4,0.3,0.6,0.4
					c0.6,1.2,2.2,4.3,5.2,4.3c0.9,0,1.7-0.3,2.3-0.6c0.2,0.1,0.3,0.3,0.5,0.4c2,1,4.2,2.1,7.3,3.1c0.3,0.1,0.5,0.1,0.8,0.1
					c0.5,0,1.1,0,1.5,0c0.1,0.2,0.2,0.4,0.3,0.6c0.1,0.2,0.3,0.5,0.5,0.6c0.2,0.2,0.2,0.3,0.3,0.4c0.1,2.7,1.5,4.3,3,5.3
					c0.3,4.2,4.3,5,7.9,5.6c0.7,0.1,1.4,0.2,2.1,0.4c0.2,0,0.3,0,0.5,0c1.5,0,2.1,0.2,2.3,0.3c1.1,1.1,2.7,2.7,5.7,2.7
					c0.5,0,0.9,0.1,1.1,0.2c1.2,2.1,2.5,3.4,4.8,4.5c0.3,0.2,0.7,0.3,1.1,0.3h1.2c0.4,0.2,1,0.4,1.6,0.4c0.7,0,1.3-0.3,1.6-0.5
					c0.4,0,0.8-0.2,1.2-0.4c0.9,0.4,1.8,0.5,2.6,0.5c1.1,0,2-0.3,2.7-0.8c0.3,0.2,0.5,0.3,0.7,0.4c0.3,0.2,0.5,0.3,0.6,0.5
					c0.5,0.5,1.1,0.7,1.8,0.7h3c1.1,0,1.9-0.5,2.4-1c0.4,0,0.8,0.1,1.2,0.2c0.7,0.1,1.4,0.2,2.1,0.2c0.7,0,1.4-0.1,2-0.4
					c1.3,1.7,3.1,2.7,4.6,2.9c1.5,0.7,3.1,1.5,5,1.5c1,0,2-0.2,2.9-0.7c0.2-0.1,0.5-0.3,0.6-0.5c0.8-0.8,1.5-1.9,1.9-3
					c0,0,0,0,0-0.1c0.2,0,0.3,0,0.5,0c1.4,0,2.7-0.4,3.8-1.3h0.5c2.4,1,5.5,1,7.5,1h5c1.1,0,3,0,4.8-1.7c1.7-1.7,1.7-3.6,1.7-4.8
					c0-0.4-0.1-0.8-0.3-1.1c0-1.2,0.9-4.4,1.4-6.4c0.2-0.9,0.5-1.8,0.7-2.6c0-0.1,0.1-0.1,0.1-0.2c0.5,0.1,1,0.2,1.4,0.2
					c3.5,0,4.8-3.1,5.4-4.8c3.1-5-0.1-9.9-1.2-11.5c-0.2-0.3-0.4-0.5-0.7-0.7c-0.2-0.7-0.4-1.4-0.8-2.1c0.5-0.7,1-1.4,1.4-2
					c1.2-1.9,1.5-3,1.5-3.6c0.2-0.3,0.6-0.8,1-1.2c3-1.8,3-4.9,3-6c0-2.5-1.1-3.6-2.2-4.4c1.2-1,1.9-2.7,2.2-3.9
					c0.4-1,0.7-1.7,0.9-2.4c0.2-0.7,0.4-1.3,0.9-2.2c0.2-0.3,0.3-0.7,0.3-1.1c3-1.9,4-6.8,4-9V447c1.2-0.8,1.9-2.1,2-3.5
					c0.3-0.8,1.7-2.6,2.6-3.8c2.7,0.1,8.1,0.4,16.4,1.9l3.9,1c0.2,0,0.4,0.1,0.6,0.1c3.3,0,4.3-2,4.5-3.9l0.8-1.5
					c0.2-0.3,0.2-0.7,0.3-1c0-0.1,0.1-0.3,0.2-0.5c0.2-0.5,0.4-1.1,0.6-1.8c0.7,0.1,1.8,0.3,3.2,0.6c2.1,0.4,3.9,0.7,5.4,0.7
					c2.2,0,3.9-0.5,5.9-1.8c0.7-0.1,1.3-0.4,1.7-0.7c0.2-0.1,0.4-0.2,0.5-0.2c0.2,0,0.3,0,0.5-0.1c1.9-0.5,4.8-1.7,5.6-5.2
					c0.7,0.8,1.6,1.3,2.8,1.3c2.9,0,4-1.1,4.4-2.4c1.7-2.2,1.7-5.4,0.1-9.6v-9.3c0.1-0.6,0.3-1.2,0.4-1.7c0.6-2.9,1.4-6.2-1.4-8.1
					v-1.1c0.5-1.8,0.9-3.1,1.2-4.1c0.1-0.3,0.2-0.6,0.3-0.9c1.9-0.4,3.6-1.5,4.2-3l2.5-2.5c1.3-1.3,5.2-5.6,5.7-10.8
					c0.3-0.4,0.6-0.9,0.8-1.5c1.8-1.6,3.3-4.4,2.7-9.3c1.2-0.3,1.9-0.9,2.5-1.4c1.1,0.3,1.9,1,2.1,1.4c0,0.4,0.1,0.7,0.3,1
					c1.2,2.4,3.6,4.8,6.3,5.3c0.7,0.4,1.3,0.5,1.8,0.5c0.9,0,1.6-0.3,2.3-0.7c0.8,1.3,2.5,2.3,4.9,2.3c0.3,0,1.4-0.1,2.7-0.8
					c0.3,0,0.5,0,0.8,0c3.1,0,4.4-2.1,5-3.3c0.2,0,0.4,0.1,0.6,0.1h0.1c1,0.7,1.9,0.9,2.8,1c1.1,2.9,2.6,4,5.1,4
					c0.7,0,1.3-0.3,1.8-0.7c0.4-0.4,1.1-1.1,1.4-1.3c0.6,0,1.2-0.3,1.6-0.7c1.5-1.5,1.7-3,1.7-3.6c0.2-0.9,1.8-3.9,3.3-5.4
					c0.1-0.1,0.2-0.2,0.3-0.4c2.3-3.5,2.5-5.3,1.5-8.5c0.1-0.1,0.1-0.1,0.2-0.2c0.4-0.4,0.7-1,0.7-1.6c0-0.1,0.2-0.4,0.2-0.5
					c0.3-0.6,0.8-1.5,0.8-2.6c0-1.9-1.3-3.4-1.7-3.8c-0.1-0.1-0.2-0.2-0.3-0.3c0-0.4-0.1-0.7-0.3-1.1c-0.4-0.8-0.6-1.6-0.8-2.5
					c-0.3-1.1-0.6-2.3-1.2-3.5c-0.1-0.2-0.3-0.5-0.5-0.6c-0.1-0.1-0.2-0.6-0.3-1.9c1-3.2,1-6.3,1-8.4c0-1.5,0-2.6,0.1-3
					c0.1-0.2,0.5-0.7,1.1-1.3c2.1-2.1,3.2-4.2,3.6-6.8c0.2,0.1,0.4,0.2,0.5,0.2c0.3,0.2,0.7,0.3,1.1,0.3c2.5,0,4-0.6,5.2-1.4
					c0.7,0.6,1.4,1.2,2,1.7c0.4,0.3,0.9,0.7,1,0.9c0.5,0.5,1.1,0.7,1.8,0.7c5.1,0,5.5-4.9,5.5-7.1c0.6-1.9,1-3.9,0.3-6
					c1.9-0.3,6.3-1.4,8.9-3.7c1.7,2.8,5.1,4.8,7.4,4.8c2.9,0,4-1.2,4.4-2.4c0.9-1.2,1.1-2.3,1.1-3.3c0.1-0.2,0.3-0.3,0.5-0.3
					c0.7,0,1.3-0.3,1.8-0.7c0.2-0.2,0.6-0.5,1-0.8c0.7-0.5,1.5-1.1,2-1.9c0.5-0.2,0.9-0.4,1.2-0.7c0.3,0,0.5-0.1,0.7-0.1
					c1.4,1.1,2.7,1.3,3.5,1.3c1,0,1.7-0.3,1.9-0.4c3.8-1.9,3.2-5.9,2.5-8.1c1.8-1.8,1.8-4.3,1.8-6.1c0-0.7-0.3-1.3-0.7-1.8
					c-0.7-0.7-1.5-1.5-2.4-2c-0.1-0.4-0.3-0.7-0.6-1c-0.1-0.2-0.3-0.8-0.3-1.3l0-1.3c1.3-0.9,2.1-2.3,2.5-3.2c3-3,3.5-3.5,3.5-5.5
					c0-1.4-0.7-2.9-2-3.8v-0.8c1.5-0.2,2.6-0.6,3.2-1.3c1.6-0.7,2.8-2.3,2.8-4.1c0-1.6,0-2.9-0.6-4.6c1.1-0.3,2-1,2.7-1.9
					c2.9-0.2,9.3-0.9,12.8-3.4c0.3,0.1,0.6,0.2,0.8,0.3c0.4,0.1,0.9,0.3,1,0.4c0.3,0.3,1.3,1.2,3.1,1.2c0.9,0,1.8-0.2,2.8-0.7
					c0.2-0.1,0.5-0.3,0.6-0.5c0.8-0.8,1.2-1.6,1.5-2.1c0.2-0.4,0.3-0.6,0.5-0.9c0.3-0.3,0.5-0.6,0.6-1c0.2-0.2,0.7-0.8,2-1.6l3-2
					c0.1-0.1,0.3-0.2,0.4-0.3c1.7-1.7,1.7-3.6,1.7-4.8c0-0.5-0.1-1-0.4-1.4l-1.7-2.5c-0.1-0.7-0.4-1.3-0.7-1.7
					c0-0.1-0.1-0.2-0.1-0.2c0.8-2.8-0.3-6.1-2.2-10.9c-1.3-5-3.3-5.6-8.2-6.5l-0.7-0.1c-0.2,0-0.3,0-0.5,0c-0.9,0-1.5-0.1-1.8-0.1
					c-0.4-1-1.1-1.8-1.9-2.6c1.3-0.9,2.2-2.5,2.2-4.8c0-2.4-1-3.9-1.9-4.9c0-0.1,0.1-0.2,0.2-0.3c0.5-0.5,0.7-1.1,0.7-1.8v-0.4
					c2-1.1,4.6-3.2,7.6-6.2c3.7-0.6,7.8-3.7,9.1-6.3c0.1-0.2,0.2-0.4,0.2-0.6c1.8,0,3.3-0.1,5.5-1.2c4.5-1.6,4.5-4.2,4.5-7.3
					c0-1.7,0-5.8-2.6-8.1c0-0.9-0.2-1.7-0.3-2.3c0-0.2-0.1-0.5-0.1-0.6c0-0.6-0.3-1.3-0.7-1.7c-0.9-0.9-1.8-1.8-2.9-2.3
					c0.1-0.9-0.1-1.9-0.4-2.8c-0.6-1.3-1.6-2.4-3-3.2c-0.2-0.2-0.5-0.4-0.7-0.6c-0.6-0.4-1.2-1-1.2-1.3c0-0.4-0.1-0.8-0.3-1.1l-1-2
					c-0.4-0.7-1.1-1.3-1.9-1.4c-0.8-0.6-1.9-1-3.3-1c-0.5,0-1,0.1-1.4,0.4l-3,2c-0.1,0.1-0.3,0.2-0.4,0.3c-0.4,0.4-1.1,1.1-1.4,1.3
					c-2,0-3.4,0.8-4.6,1.5c-0.4,0.2-0.7,0.4-1.1,0.6c-1.3,0.4-1.9,0.6-2.5,0.6c-0.5,0-1.1-0.1-2-0.4v-2.9c0-1.7-0.5-2.9-0.8-3.6
					c-0.1-0.2-0.2-0.4-0.2-0.5c0-0.6-0.3-1.2-0.7-1.7c-0.2-0.2-0.3-0.4-0.5-0.9c-0.2-0.4-0.5-1-1-1.6c-0.1-0.5-0.3-0.9-0.5-1.3h0.3
					c1.1,0,2.1-0.2,2.9-0.6c0.5,0.4,1.1,0.9,2,1.3c0.3,0.2,0.7,0.3,1.1,0.3c3.2,0,5.1-2.6,5.4-4.8c1.1-2.5,1.1-4.2-0.2-6.8
					c-0.1-0.2-0.3-0.5-0.5-0.6c-0.9-0.9-1.8-1.8-2.9-2.3c-0.1-0.6-0.3-1.1-0.5-1.4c-0.4-0.7-1.4-1.8-3.5-2c-0.5-0.3-1.2-0.4-1.8-0.4
					c-2.3,0-4.3,1.9-4.5,4.4c-1.1,0-2.6,0.2-4,1.5c-2.1,1.2-3,3.4-2.6,5.9c-1.1-0.3-2.3-0.7-3.5-1l-0.7-0.2c-0.1,0-0.2,0-0.3-0.1
					c-2.4-0.4-4.3-0.7-6-0.7c-2.4,0-4.5,0.5-8.1,1.8c-5.2,1.3-7.8,2.3-9.1,3.6c-0.1,0.1-0.2,0.2-0.2,0.3c-3.6,0-6.3,1.3-9.7,4.7
					c-5.7,4.7-5.7,4.8-9.4,6.6c-0.2,0.1-0.5,0.3-0.6,0.5c-0.4,0.4-1,0.7-1.5,1c-0.9-0.7-2-1.5-3.7-1.7c-0.2-0.1-0.4-0.3-0.5-0.4
					c-1-0.8-2.2-1.7-3.4-3.5c-0.1-0.1-0.2-0.3-0.3-0.4l-1.5-1.5c-0.5-1.3-1.8-2.2-3.1-2.2l-10.2-1.5c0.3-0.7,0.4-1.4,0.4-2.1
					c0-5.8-5.8-6.7-10.9-7.4c-3.3-1.1-5.7-1.6-7.8-1.6c-2.1,0-4.1,0.5-6.3,1.5c-1.9,0-4.3-0.1-6.6-0.9c-0.2-0.1-0.3-0.1-0.5-0.1
					l-8-1c-0.1,0-0.2,0-0.3,0h-1h-3c-1,0-4.1,0-7.4,1c-3.9-0.1-11.1-1-14.5-2.7c-0.9-0.4-1.9-0.7-2.9-0.7c-1.8,0-3.7,0.8-5,2.1
					c-0.5,0.5-0.7,1.1-0.7,1.8c0,0.1,0,0.2-0.1,0.3c-0.1,0.7-0.3,1.8-0.1,3.1c-4,0.7-6.8,6.1-7.8,9.9c-1.2,3.8-0.1,7.1,3.1,8.8
					c0.3,1.1,1.1,2.1,1.8,2.9v0.2c-0.9-0.5-1.7-0.6-2.4-0.6c-0.5,0-0.9,0.1-1.4,0.2c0.2-2.9-1.5-5.5-4.6-7.1
					c-0.3-0.2-0.7-0.3-1.1-0.3c-3.7,0-6.2,2.4-6.5,5.9c-0.5,1.4-0.5,2.8,0,4.1c0.2,0.6,0.7,1.3,1.3,1.9c-2.7-1.2-5-1.8-5.3-1.9
					c-1.3-0.3-2.3-0.5-3.3-0.5c-2.6,0-4.2,1.1-6,2.4c-0.2,0.1-0.4,0.3-0.7,0.5c-0.1,0.1-0.3,0.2-0.4,0.3l-2.3,2.3
					c-0.8,0.1-1.8,0.3-2.8,1h-0.5c-1.1-0.5-1.3-0.7-2.5-2c-0.5-1-1-1.7-1.5-2.4c-0.4-0.6-0.7-1.1-1.1-1.8c-0.3-3.4-2.5-5.8-5.5-5.8
					c-2.9,0-5.3,2.5-8.4,14.8c-0.9,2.7-1.1,4.7-1.1,6.4c-0.3,0.3-0.6,0.6-0.8,0.9c-1.2-0.6-2.2-0.8-3.1-0.8c-0.6,0-1.2,0.1-1.8,0.4
					l-1.6-3.2c-0.2-0.5-0.6-0.9-1.1-1.1c-0.4-0.2-0.5-0.3-0.6-0.3c-0.1-0.2-0.1-0.9-0.1-1.4c0-0.4-0.1-0.8-0.3-1.1
					c-0.2-0.4-0.4-1-0.5-1.7c-0.1-0.5-0.3-1-0.4-1.5c1.1-0.6,1.9-1.4,2.5-2c0.5-0.5,0.7-1.1,0.7-1.8c0-1.4-0.8-3.1-1.7-4.5h0.2
					c2.9,0,4-1.2,4.4-2.4c1.8-2.4,0.5-5-0.1-6.2c-0.4-0.7-1.1-1.2-1.9-1.4c-0.3-0.2-0.9-0.5-1.6-0.5c-0.7,0-1.2,0.2-1.7,0.5
					c0,0,0,0,0,0c-0.8-1.2-1.9-2-2.9-2.7c0.7-1.3,1.3-2.9,1.3-4.5c0.2-0.7,0.4-1.3,0.5-1.8c0.7-2.4,1.4-4.7-1.2-7.3
					c-0.2-0.2-0.3-0.3-0.3-1.2c0-2.7-1.9-4.3-3.1-5.2c-0.3-0.2-0.5-0.4-0.6-0.5c-0.3-0.3-0.7-0.5-1.2-0.7c-0.2-0.2-0.5-0.5-0.9-0.7
					c-1.8-2.8-4.6-4.4-8.1-4.4c-1.7,0-3.6,0.4-5.5,1.1c-0.5,0.2-1.1,0.3-1.5,0.3c-0.7,0-1.2-0.2-1.5-0.4c-1-2.3-2.7-4-4.4-5.2
					c0.3-0.9,0.1-2.2-1.1-3.7c0-0.1-0.1-0.1-0.2-0.2c-0.1-0.5-0.3-0.9-0.7-1.2c-1.4-1.4-4-2.7-6.8-2.7c-2.2,0-4,0-5.8,1.7
					c-0.4,0.4-0.7,0.8-0.9,1.3c-2.9,0.2-4.6,2-4.9,3.8c-1,2.6,0.5,4.4,1.6,5.5c-0.5,1.4-0.5,3-0.5,4.2c0,0.6,0,1.6,0.2,2.8
					c-0.5-0.2-1.1-0.3-1.7-0.3c-1.5,0-3.5,0.9-4.9,2H240c-1.2,0-1.8-0.1-2.1-0.2l-0.7-1.4c0-0.1-0.1-0.2-0.2-0.3
					c-2.4-3.6-5.1-5.1-9.1-5.1c-0.3,0-0.6,0-1,0c0.1-0.1,0.1-0.3,0.2-0.4c0.2-0.3,0.3-0.7,0.3-1.1c0-0.1,0.1-0.4,0.1-0.6
					c0.3-1.2,0.8-3.5-0.9-5.2c-1.4-1.4-2.9-2.6-7.9-3.6c-1.9-0.9-3.9-1.9-6.8-2.9c-3.6-2.1-7.1-3.3-10.2-3.3c-1.7,0-3.2,0.3-4.5,1
					h-1c-0.5-0.1-1.2-0.3-2.1-0.4c0.2-1.4,0.2-2.7,0.2-3.1c0-1.2-0.5-2.2-1-2.9v-0.1c2.9-1.9,4.8-3.6,5-7.5c0.8-2,0.4-4.1-0.5-5.7
					c0.3-0.5,0.5-1.1,0.5-1.9c0-0.3-0.1-2.1-1.7-3.8C196.4,75,194.9,73.7,193,73.7L193,73.7z M183.4,93.4c-0.1-0.4-0.3-0.7-0.6-1
					c-0.2-0.2-0.5-0.5-0.7-0.7c0.6,0.5,1.1,0.9,1.5,1.2v0.3c0,0.1,0,0.2,0,0.3C183.5,93.5,183.4,93.4,183.4,93.4L183.4,93.4z
					 M210.9,85.7h-2c-1.4,0-4.1,1-5.4,4.7c-0.1,0.3-0.1,0.5-0.1,0.8c0,3.8,2.3,6.5,5.5,6.5c3.1,0,5.1-1.8,5.5-4.7
					c1.1-2,0.1-4.8-1.7-6.6C212.2,86,211.6,85.7,210.9,85.7L210.9,85.7z M364,95.7h-1c-3.6,0-5.2,2.3-6.2,4.4
					c-0.2,0.3-0.3,0.7-0.3,1.1c0,1.3,0.1,2.6,1,4.6c0.2,2.2,1.4,3.8,3.3,4.5c0.2,0.4,0.5,0.7,0.9,1c0,0,0,0.1,0,0.1
					c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.3,0,0.4-0.1,0.6c-0.5,0.1-0.9,0.3-1.2,0.7c-0.1,0.1-0.4,0.3-0.6,0.5c-1,0.8-2.6,2.1-3,4.3
					c-0.6,1.1-0.7,2.3-0.1,3.5c0.4,0.9,1.1,1.5,1.7,2.1c0.5,0.5,1.1,0.7,1.8,0.7h1c1.4,0,3.1-0.7,4.4-1.7c2.1,0.6,4.5,0.7,6.3,0.7
					c4.9,1,8,1.4,10.4,1.4c1.5,0,5.9,0,7.3-4.1c0.1-0.3,0.1-0.5,0.1-0.8c0-1.1-0.4-2-0.8-2.6c-0.1-0.2-0.2-0.4-0.2-0.5
					c0-0.6-0.3-1.2-0.7-1.6c-0.1-0.1-0.2-0.5-0.4-0.8c-0.5-1.2-1.3-2.9-3.4-5c-3.1-5.2-3.6-5.7-8.4-6.9c-0.1,0-0.1,0-0.2,0
					c-1.1-0.2-2.1-0.3-3.1-0.3c-0.6,0-1.2,0-1.8,0.1c0-0.1,0-0.3,0-0.4c0-0.4-0.1-0.8-0.3-1.1C369.1,98.8,367.5,95.7,364,95.7
					L364,95.7z M329,97.7h-2c-0.7,0-1.3,0.3-1.8,0.7c-1.7,1.7-1.7,3.6-1.7,5.8c0,0.3,0,0.5,0.1,0.8c0.2,0.6,1.3,3.7,3.8,5.2
					c0.1,0.1,0.2,0.3,0.3,0.4c0.3,0.4,0.7,1.2,1.6,1.7c0.8,1.4,2.3,3.4,4.7,3.4c1.1,0,3,0,4.8-1.7c0.1-0.1,0.2-0.2,0.3-0.4
					c2.5-3.8,0.7-6.8-0.3-8.6c-0.2-0.3-0.4-0.6-0.5-0.9c-0.1-0.2-0.3-0.5-0.5-0.6C334.6,100.3,331.8,97.7,329,97.7L329,97.7z
					 M315.5,119.5c-1.3,0-2.4,0.4-3.5,1.2H312c-0.7,0-1.3,0.3-1.8,0.7c-1.7,1.7-1.7,4.5-1.7,5.8c0,0.4,0.1,0.8,0.3,1.1
					c0.5,1.1,2.2,4.4,5.2,4.4h1c3,0,4.7-1.6,5.8-2.7c0.5-0.5,0.7-1.1,0.7-1.8v-2v-3c0-0.9-0.5-1.8-1.4-2.2
					C318.8,120.3,317.3,119.5,315.5,119.5L315.5,119.5z"/>
			</g>
			<g id="st0">
				<path d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,53.2c-2,0-5.1,0-6.6,2.8c-1.2,1.5-1.4,3.8-0.5,6.1c-0.1-0.1-0.1-0.1-0.2-0.2
					c-2.2-3.6-4.3-6.5-8.2-6.5c-1.2,0-2.6,0.3-4.1,0.9c-0.1,0-0.1,0-0.2,0.1c-4.6,2.3-7.1,6.1-7.1,10.8v0.2
					c-0.2,0.2-0.3,0.3-0.5,0.5c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.5-0.6-0.8-1c-0.9-1.2-1.9-2.6-3.6-2.6h-1c-0.2,0-0.4,0-0.6,0.1
					c-1.5,0.5-2.6,1.8-3.5,3.8c-1.1,2.7-1.7,7.2-0.9,9.5c0.2,2.8,2,4.6,5,4.6h1c2.2,0,3.4-1.1,4.2-2.3c0.4,2.5,1.6,4,3.6,6.6
					l0.6,0.8c0,0,0,0,0,0c3.7,4.6,5.2,6.4,7.7,6.7c1.1,1,2.3,2.1,3.9,2.1c2.5,0,5-1.2,7.4-3.6c0.1-0.1,0.2-0.2,0.2-0.3
					c1.1-1.7,4.6-3.4,8.6-3.4c0.8,0,1.6,0.1,2.3,0.2c0.1,0,0.3,0,0.4,0c3.2,0,5.9-0.1,7.4-1.6c0.2-0.2,0.3-0.4,0.4-0.6
					c3.2-1.1,4.1-2.6,4.2-4.4c0.3-0.2,0.6-0.4,0.9-0.6c1.4-0.3,2.5-1.5,3.6-2.5c1.6-1.6,2.6-4.2,1.6-6.8c0-0.5,0.1-1,0.4-1.8
					c0.9,4.1,3.6,6.1,9,7.1l5.7,2.9c0.5,0.2,0.9,0.5,1.3,0.7c1.3,0.7,2.6,1.5,4.6,1.5h1c5.1,0,8.7-2.8,11.4-5.6
					c0.1-0.1,0.2-0.2,0.2-0.3l1.9-2.9c1.6-1.7,1.6-4-0.2-5.7l-0.6-0.6c-0.2-0.5-0.5-1-0.9-1.3v-0.7c0-0.5-0.2-1-0.6-1.4
					c-1.6-1.6-3.3-1.6-4.4-1.6c-1.7,0-4.9,0-7.5,1.2c-0.2-0.5-0.6-1.1-1.1-1.6c-1.1-1.1-2.4-1.7-3.8-1.7c-1.9,0-3.8,1-6.5,2.3
					c-0.2,0.1-0.4,0.2-0.5,0.4l0,0l-0.8-1.5c-0.1-0.2-0.2-0.4-0.4-0.5c-0.9-0.9-1.9-1.3-2.9-1.3c-0.8,0-1.6,0.3-2.4,0.9
					c-1.2,0.3-1.9,1.4-2.1,2.5l-0.1,0.4c-1.2-1.4-3.3-2.4-6-3.8c-0.1-0.1-0.3-0.1-0.4-0.2c-0.8-0.2-1.6-0.4-2.3-0.6
					c-1.7-0.5-3-0.9-4.4-0.9c-0.8,0-1.5,0.1-2.2,0.4c-1.1,0-1.6-0.1-1.9-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c-2.2-2.2-5.5-4.3-7.5-5.4
					C363.6,53.3,363.3,53.2,363,53.2L363,53.2z M193,74.2c-0.5,0-1,0.2-1.4,0.6l-0.6,0.6c-0.7,0.2-1.1,0.6-1.4,0.9H189
					c-1.8,0-4.4,0-6.1,2h-0.2c-0.6-0.5-1.6-1-2.7-1c-2,0-5,1.8-5,5c0,2.6,1.3,5.5,3.4,5.9c1.5,0.8,2.3,1.6,3.2,2.5
					c1,1,1.7,1.5,2.2,1.9c0.1,0,0.1,0.1,0.2,0.1v0.6c0,0.3,0,0.6,0,1h0c-0.2,0-0.8-0.3-1.1-0.5c-0.1-0.4-0.3-0.7-0.5-0.9
					c-1.1-1.1-2.6-2.6-5.4-2.6c-1.5,0-2,0.2-3,1.2c-1.5,0.6-2.5,2.3-2.8,3.7c-0.2,0.1-0.3,0.2-0.5,0.3c-0.5,0.3-1.1,0.6-1.8,1.1
					c-2.4,1.3-3.6,3.7-4.6,5.7c-0.1,0.3-0.2,0.6-0.2,0.9c0,1.8,0,3.3,1,4.7v2.8c-1.1,2.4-2,6.3-2,8.4c0,0.6,0,1.1-0.1,1.6
					c-0.2,2-0.3,4.4,2.3,6.5c0.2,0.6,0.6,0.9,0.8,1.2c0.1,1.9,0.9,3.2,1.7,4.1c0,0-0.1,0.1-0.1,0.1c-2.9,2.9-3.6,13.3-3.6,13.4
					c0,1.2,0.6,1.9,1,2.3v0.4c-0.3,0.3-0.6,0.7-0.7,1.3H164c-0.3,0-0.6,0.1-0.9,0.2c-1.6,0.8-3.2,0.8-5.1,0.8c-0.1,0-0.2,0-0.3,0
					c-5.6,0.9-9.8,2-12.3,6.8c-0.3,0-0.7-0.1-1-0.1c-3.2,0-6,2.1-7.8,3.9c-0.4,0.4-0.6,0.9-0.6,1.4v3v2.2l-1,1
					c-0.8-0.7-1.7-1.3-3.1-2c-0.3-0.1-0.6-0.2-0.9-0.2c-3.1,0-7,0-9.4,1h-4.2c-0.3-0.1-0.6-0.2-0.9-0.3c-1.7-0.6-3.1-1.1-4.6-1.1
					c-1,0-1.8,0.2-2.7,0.6c-2.1,1.1-2.7,3.3-3.3,5c-0.2,0.7-0.4,1.4-0.6,1.9c-0.1,0.3-0.2,0.6-0.2,0.9c0,3.1,0,5.3,1.1,8.6
					c0.1,0.3,0.3,0.6,0.5,0.8c0.1,0.1,0.1,0.1,0.2,0.2c0,0.6-0.3,2-0.7,4c0,0.1,0,0.3,0,0.4c0,1.6-0.9,4.5-1.9,7.2
					c-1.1,2.2-2.1,5.4-2.1,7.8c0,0.7,0,0.8-0.9,1.2c-0.2,0.1-0.4,0.2-0.5,0.4c-0.6,0.6-3.1,3.7-3.5,5.8c-0.2,0.4-0.3,0.7-0.4,1
					c-0.4,1-0.6,1.3-1.2,1.7c-0.7,0.1-1.3,0.4-1.8,0.7c-0.2,0.1-0.5,0.3-0.6,0.3c-1.5,0-2.7,0.5-3.4,0.8c-0.2,0.1-0.5,0.2-0.6,0.2
					c-0.5,0-1,0.2-1.4,0.6c-0.2,0.2-0.4,0.4-0.7,0.6c-1,0.8-2.5,2.1-2.8,4.1c-0.5,0.8-0.8,1.8-0.8,2.8c0,1,0.3,2,0.9,2.8
					c0.1,0.3,0.2,0.5,0.3,0.7c0.1,0.2,0.2,0.5-0.3,1.8c-0.1,0.2-0.1,0.4-0.1,0.6v2.3c0,0-0.1,0-0.1,0.1c-3.5,2.4-3.8,4.1-4.7,9.3
					l-0.2,0.9c-1,4.2-1,9.2-1,11.4c0,0.2,0,0.4,0.1,0.6l0.5,1.4l-0.4,0.2c-0.2,0.1-0.4,0.2-0.5,0.4c-0.2,0.2-0.8,0.4-1.2,0.5
					c-0.6,0.2-1.4,0.5-2.1,0.9c-3.7,0.2-5.1,2.1-6.2,5.3c0,0.1,0,0.1,0,0.2c-0.8,0.4-1.4,1-2.1,1.7c-1.9,0.7-3,3.3-3,4.8
					c0,1.7,1.3,4.2,2.6,5.4c0.1,0.1,0.3,0.3,0.5,0.5c0.9,1.1,2.5,3,5.8,3.1c0.1,0.1,0.2,0.5,0.2,1c0,0.3,0.1,0.6,0.2,0.9
					c1.2,2.5,5.4,5.5,7.6,6.7c1,1,2.4,2.3,4.8,2.4c0.3,0.1,0.7,0.2,0.9,0.3c-0.1,0.5-0.3,1.4-0.5,2.2c0,0,0,0.1,0,0.1
					c-0.3,0-0.5-0.1-0.8-0.1c-1.4,0-2.2,0.6-2.7,1c-0.2,0.2-0.8,0.9-1.2,1.8c-1.3,0.7-2.3,2.2-2.3,4.6c0,2.2,2.4,3.4,5.1,4.8
					c0.1,0,0.2,0.1,0.3,0.1c1,0.3,3.8,1.3,1.7,10.7c-0.9,4.4-0.9,4.4-1.5,5c-1.2,1.2-3.6,3.6-3.6,6.4c0,3.2,2,4.6,4.4,5.6
					c0,0.1,0,0.2-0.1,0.3l-2.7,2.7c-2.1,2.1-3.3,3.3-4.5,6.8c-0.1,0.2-0.1,0.4-0.1,0.6c0,2,0,4.1,1,5.7v0.6
					c-0.7,1.1-1.2,2.7-0.9,4.3c0.3,1.1,0.9,2,1.9,2.7c0.1,2.3,1.2,3.7,2.6,4.7l-0.2,0.5c-1.2,0.6-2.4,1.8-3,3
					c-4.9,2.1-9.5,9.4-11.1,12.4c-1.1,1.2-2,3-3,5c-1.3,1.4-7.3,8-7.3,11.2c0,0.9,0,2.2,0.8,3.5c-0.4,3.1,1.7,6.3,6,9.2
					c0.1,0,0.1,0.1,0.2,0.1c1.7,0.8,3.3,1.3,4.8,1.3c2.4,0,4.8-1,7.1-3.2c0,0,0.1,0,0.1,0c2.7,2.2,7.7,3.1,9.9,3.1
					c2.7,0,4.3-1.4,4.4-1.6c0.1-0.1,0.2-0.1,0.2-0.2c0,0,0,0,0,0c-1,1-1.5,2.3-1.8,3.3c-0.2,0.5-0.4,1-0.6,1.5
					c-0.1,0.3-0.2,0.6-0.2,0.9c0,2.1,0,3.8,1.6,5.4c0.2,0.2,0.3,0.3,0.5,0.4c0.6,0.3,1.3,0.5,1.9,0.5c2,0,3.1-1.4,3.9-2.3
					c0.2-0.2,0.3-0.4,0.5-0.5c0.1-0.1,0.3-0.3,0.4-0.5c0.7-0.4,2.6-0.9,3.2-0.9c3.8,0,6.3-2.5,7.4-3.6c0.1-0.1,0.2-0.2,0.2-0.3
					c1.6-2.5,4.7-4.7,5.6-5.1c3.8-0.2,9.9-5.3,11.4-7.9c0.2-0.1,0.6-0.1,1.3-0.1c5.2,0,6.6-2.8,6.9-5c3,0,3.4,0.2,5.6,2.4l2.5,2.5
					c0.3,1.2,1.2,2.2,2.3,2.7c0.1,0.2,0.3,0.4,0.5,0.6v2.7c0,0.8,0.3,1.7,0.6,2.6c0.1,0.4,0.4,1.1,0.4,1.4l0,0.7
					c-0.2,0.6-0.4,1.1-0.6,1.5c-0.7,1.8-1.4,3.4-0.2,5.7c1.3,2.6,4.5,3.8,7.5,4c0.4,0.5,0.8,0.7,1.2,0.9c0.3,0.2,0.5,0.3,0.7,0.5
					c0.4,0.4,0.9,0.6,1.4,0.6c0.9,0,2.2-0.4,3.2-1.1c0.5,0.4,1,0.8,1.6,1.2c-0.1,0.7-0.4,1.8-0.8,3.4c-0.9,3.4-0.4,5.1-0.1,6.1
					c0,0.1,0.1,0.3,0.1,0.4l0,1.6c-0.8,2.6-1.2,5.4,1,8.1c0,3.5-0.4,7.3-0.9,10.7c-0.1,0.4-0.3,0.7-0.4,1.1
					c-0.8,2.3-1.6,4.5-0.7,6.8c0,1.6,0.2,4.1,2,5.1c0,1.7,0.2,3.7,0.8,5.4c-0.4,0.5-0.8,1.1-0.8,2.1c0,2.1,0.9,3.6,2,4.4
					c0.1,2,0.8,3.2,2.2,3.5c0.8,0.6,1.9,0.8,3.2,0.8c1.3,0,2.9-0.3,5.1-0.8c0.4-0.1,0.7-0.3,0.9-0.5c0.1-0.1,0.2-0.2,0.6-0.2
					c0.9,0,2.4,0.3,4.5,0.7c2.2,0.7,3.8,1.3,4.5,1.6c0.3,3.5,3.4,5,5.4,5.9c0.3,0.1,0.5,0.2,0.7,0.3c0.3,0.1,0.6,0.2,0.9,0.2
					c0.1,0,0.1,0,0.2,0c0.1,0.1,0.3,0.3,0.4,0.4c0.7,0.7,1.4,1.1,2,1.4c0.4,0.2,0.7,0.4,1,0.6c0.2,0.2,0.4,0.3,0.6,0.4
					c0,0,0,0,0,0.1c0.5,1,2.1,4.1,4.8,4.1c1,0,1.8-0.4,2.4-0.7c0,0,0,0,0.1,0c0.2,0.2,0.4,0.4,0.7,0.5c2,1,4.1,2.1,7.3,3.1
					c0.2,0.1,0.4,0.1,0.6,0.1c0.7,0,1.4,0,1.8,0c0.1,0.2,0.3,0.5,0.4,0.9c0.1,0.2,0.2,0.4,0.4,0.5c0.3,0.3,0.4,0.6,0.4,0.7
					c0,2.6,1.4,4.1,3,5.1c0.1,4.1,3.7,4.7,7.5,5.4c0.7,0.1,1.4,0.2,2.1,0.4c0.1,0,0.3,0,0.4,0c1.8,0,2.4,0.2,2.6,0.4
					c1.1,1.1,2.6,2.6,5.4,2.6c0.7,0,1.2,0.2,1.4,0.3c1.1,2.1,2.4,3.3,4.7,4.5c0.3,0.1,0.6,0.2,0.9,0.2h1.3c0.4,0.2,0.9,0.4,1.4,0.4
					c0.7,0,1.2-0.3,1.5-0.5c0.5,0,0.9-0.2,1.4-0.4c0.9,0.4,1.8,0.6,2.6,0.6c1.2,0,2.1-0.4,2.8-0.9c0.3,0.3,0.7,0.5,0.9,0.7
					c0.3,0.2,0.5,0.3,0.7,0.5c0.4,0.4,0.9,0.6,1.4,0.6h3c1.1,0,1.8-0.5,2.3-1c0.5,0,1,0.1,1.5,0.2c0.6,0.1,1.3,0.2,2,0.2
					c0.7,0,1.4-0.1,1.9-0.4h0.3c1.2,1.7,3.1,2.7,4.6,3c1.4,0.7,3.1,1.5,4.8,1.5c0.9,0,1.8-0.2,2.7-0.6c0.2-0.1,0.4-0.2,0.5-0.4
					c0.8-0.8,1.4-1.8,1.7-2.8c0-0.1,0.1-0.3,0.1-0.4c0.3,0,0.5,0.1,0.9,0.1c1.3,0,2.6-0.4,3.6-1.3h0.8c2.4,1,5.4,1,7.4,1h5
					c1.1,0,2.8,0,4.4-1.6c1.6-1.6,1.6-3.3,1.6-4.4c0-0.4-0.1-0.7-0.3-1c-0.1-1.1,0.9-4.5,1.5-6.6c0.3-0.9,0.5-1.8,0.7-2.6
					c0.1-0.3,0.3-0.4,0.4-0.6c0.6,0.1,1.2,0.2,1.7,0.2c3.2,0,4.4-2.9,4.9-4.5c3-4.8-0.1-9.4-1.2-11c-0.2-0.3-0.4-0.5-0.7-0.7
					c-0.1-0.8-0.5-1.7-0.9-2.5c1.1-1.3,3-4,3-5.7c0.1-0.3,0.7-1,1.2-1.4c2.8-1.6,2.8-4.6,2.8-5.6c0-2.3-1-3.2-2-4
					c-0.2-0.1-0.4-0.3-0.5-0.4c1.4-0.8,2.3-2.7,2.5-4.1c0.5-1,0.7-1.7,0.9-2.4c0.2-0.7,0.5-1.4,0.9-2.2c0.1-0.3,0.2-0.6,0.2-0.9
					v-0.3c3-1.6,4-6.6,4-8.7v-0.5c1.2-0.7,1.9-2,2-3.3c0.3-0.8,1.9-3,2.9-4.2c2.6,0.1,8.1,0.4,16.7,1.9l3.9,1c0.2,0,0.3,0.1,0.5,0.1
					c2.5,0,3.8-1.2,4-3.5l0.8-1.6c0.1-0.3,0.2-0.6,0.2-0.9c0-0.1,0.1-0.4,0.2-0.6c0.2-0.5,0.5-1.2,0.7-2.2c0.7,0.1,1.9,0.3,3.7,0.7
					c2.1,0.4,3.8,0.7,5.3,0.7c2.1,0,3.8-0.5,5.7-1.7c0.7-0.1,1.2-0.4,1.7-0.6c0.2-0.1,0.5-0.3,0.6-0.3c0.2,0,0.3,0,0.4-0.1
					c2.3-0.6,5.1-2.1,5.5-6c0.7,1.1,1.7,2,3,2c2.2,0,3.5-0.7,3.9-2.1c1.6-2.1,1.7-5.1,0.1-9.2v-9.4c0.1-0.7,0.3-1.3,0.4-1.8
					c0.7-3.1,1.3-6-1.4-7.7v-1.5c0.5-1.8,0.9-3.2,1.3-4.2c0.1-0.4,0.3-0.8,0.4-1.2c1.9-0.4,3.6-1.4,4.2-2.8l2.6-2.6
					c1.2-1.2,5.2-5.6,5.6-10.7c0.3-0.4,0.6-1,0.9-1.6c1.8-1.5,3.3-4.3,2.6-9.4c1.5-0.3,2.3-1,2.9-1.6c1.6,0.4,2.7,1.5,2.7,1.8
					c0,0.3,0.1,0.6,0.2,0.9c1.2,2.3,3.4,4.7,6,5c0.6,0.4,1.2,0.5,1.6,0.5c1,0,1.8-0.4,2.4-1c0.6,1.4,2.2,2.6,4.7,2.6
					c0.2,0,1.3,0,2.5-0.9c0.3,0,0.6,0.1,0.9,0.1c3.1,0,4.2-2.2,4.7-3.3c0,0,0-0.1,0-0.1c0.2,0.1,0.5,0.2,0.8,0.2h0.3
					c1,0.8,2.1,0.9,3,1c1.2,3.3,2.8,4,4.8,4c0.5,0,1-0.2,1.4-0.6c0.5-0.5,1.3-1.3,1.6-1.4c0.5,0,1-0.2,1.4-0.6
					c1.4-1.4,1.6-2.9,1.6-3.3c0.2-0.8,1.8-4.1,3.4-5.7c0.1-0.1,0.2-0.2,0.2-0.3c2.3-3.4,2.4-5.1,1.4-8.3c0.1-0.1,0.2-0.2,0.4-0.4
					c0.4-0.4,0.6-0.9,0.6-1.4c0-0.1,0.2-0.4,0.3-0.6c0.3-0.6,0.7-1.4,0.7-2.4c0-1.7-1.3-3.1-1.6-3.4c-0.2-0.2-0.3-0.3-0.4-0.4
					c0-0.1,0-0.1,0-0.2c0-0.3-0.1-0.6-0.2-0.9c-0.4-0.8-0.6-1.7-0.8-2.6c-0.3-1-0.6-2.2-1.2-3.4c-0.1-0.2-0.2-0.4-0.4-0.5
					c-0.2-0.2-0.4-0.7-0.4-2.3c1-3.2,1-6.3,1-8.3c0-1.6,0-2.7,0.1-3.1c0.1-0.3,0.5-0.7,1.3-1.5c2.2-2.2,3.3-4.3,3.5-7.1
					c0.5,0.2,1,0.3,1.2,0.5c0.3,0.1,0.6,0.2,0.9,0.2c2.6,0,4.2-0.7,5.2-1.5c0.8,0.8,1.6,1.4,2.3,2c0.4,0.4,0.9,0.7,1.1,0.9
					c0.4,0.4,0.9,0.6,1.4,0.6c4.7,0,5-4.6,5-6.7c0.7-2.1,1-4.2,0.1-6.3c1.1-0.1,7-1.3,9.6-4.1c1.5,2.9,5,5.1,7.2,5.1
					c2.2,0,3.5-0.7,3.9-2.1c0.9-1.2,1.1-2.3,1.1-3.3c0.2-0.3,0.6-0.6,1-0.6c0.5,0,1-0.2,1.4-0.6c0.2-0.2,0.6-0.5,1-0.8
					c0.7-0.5,1.5-1.1,2-1.9c0.5-0.2,1-0.5,1.3-0.7c0.4,0,0.7-0.1,1-0.2c1.2,1.1,2.5,1.4,3.4,1.4c0.9,0,1.6-0.3,1.7-0.4
					c3.7-1.9,2.8-5.8,2.2-7.8c1.9-1.7,1.9-4.2,1.9-6c0-0.5-0.2-1-0.6-1.4c-0.7-0.7-1.5-1.5-2.5-2.1c-0.1-0.4-0.3-0.7-0.5-0.9
					c-0.2-0.2-0.4-1-0.4-1.6l0-1.6c1.3-0.8,2.2-2.4,2.6-3.2c3-3,3.4-3.4,3.4-5.2c0-1.4-0.7-2.8-2-3.5v-1.5c1.1-0.1,2.6-0.3,3.4-1.3
					c1.5-0.6,2.6-2.1,2.6-3.7c0-1.8,0-3.1-0.8-5c1.3-0.3,2.4-1,3.1-2.1c2.8-0.2,9.6-0.9,13-3.4c0.4,0.2,0.7,0.3,1.1,0.4
					c0.4,0.1,1.1,0.4,1.2,0.5c0.5,0.5,1.5,1,2.7,1c0.8,0,1.7-0.2,2.6-0.7c0.2-0.1,0.4-0.2,0.5-0.4c0.7-0.7,1.1-1.4,1.4-2
					c0.2-0.4,0.4-0.7,0.6-1c0.3-0.3,0.4-0.6,0.5-0.9c0.1-0.2,0.6-0.8,2.2-1.8l3-2c0.1-0.1,0.2-0.2,0.3-0.2c1.6-1.6,1.6-3.3,1.6-4.4
					c0-0.4-0.1-0.8-0.3-1.1l-1.7-2.6c-0.1-0.7-0.4-1.2-0.6-1.7c-0.1-0.1-0.1-0.3-0.2-0.4c0.9-2.7-0.2-6-2.2-10.8
					c-1.3-4.9-3.3-5.3-7.8-6.2l-0.7-0.1c-0.1,0-0.3,0-0.4,0c-1.2,0-1.8-0.1-2.2-0.2c-0.4-1.2-1.4-2.2-2.3-3.1
					c1.4-0.6,2.5-2.2,2.5-4.7c0-2.3-1-3.7-2-4.8c0-0.4,0.1-0.6,0.4-0.8c0.4-0.4,0.6-0.9,0.6-1.4v-0.7c2.5-1.3,5.5-4,7.9-6.4
					c3.6-0.5,7.6-3.5,8.9-6c0.1-0.3,0.2-0.5,0.2-0.8c0,0,0.1-0.1,0.1-0.1c2,0,3.4,0,5.7-1.1c4.2-1.5,4.2-3.8,4.2-6.9
					c0-1.9,0-5.8-2.6-7.9c0.1-0.9-0.1-1.8-0.3-2.4c-0.1-0.2-0.1-0.6-0.1-0.7c0-0.5-0.2-1-0.6-1.4c-0.9-0.9-1.9-1.9-3.1-2.3
					c0.2-1,0-2-0.4-2.9c-0.5-1.2-1.5-2.3-2.8-3c-0.2-0.2-0.5-0.4-0.8-0.6c-0.9-0.7-1.4-1.2-1.4-1.7c0-0.3-0.1-0.6-0.2-0.9l-1-2
					c-0.3-0.6-1-1.1-1.7-1.1c-0.7-0.6-1.8-1-3.1-1c-0.4,0-0.8,0.1-1.1,0.3l-3,2c-0.1,0.1-0.2,0.2-0.3,0.2c-0.5,0.5-1.3,1.3-1.6,1.4
					c-2,0-3.3,0.8-4.5,1.5c-0.4,0.2-0.8,0.4-1.2,0.7c-1.3,0.4-2.1,0.6-2.7,0.6c-0.6,0-1.3-0.1-2.5-0.5c0-0.1,0-0.1,0-0.2v-3
					c0-1.6-0.5-2.7-0.8-3.5c-0.1-0.2-0.2-0.5-0.2-0.6c0-0.5-0.2-1-0.6-1.4c-0.3-0.3-0.4-0.6-0.6-1c-0.2-0.5-0.5-1-1-1.6
					c-0.2-0.8-0.6-1.4-0.9-2c0.1,0,0.1,0,0.2,0h1c1.2,0,2.2-0.3,3-0.7c0.5,0.5,1.1,1,2.1,1.5c0.3,0.1,0.6,0.2,0.9,0.2
					c2.9,0,4.7-2.5,5-4.5c1.1-2.4,1.1-4-0.2-6.4c-0.1-0.2-0.2-0.4-0.4-0.5c-0.9-0.9-1.9-1.9-3-2.3c-0.1-0.6-0.3-1.1-0.5-1.5
					c-0.5-0.7-1.4-1.6-3.2-1.8c-0.5-0.3-1.1-0.4-1.6-0.4c-1.9,0-4,1.7-4,4.4c-1.1,0-2.7,0-4.2,1.4c-2.2,1.3-3,3.6-2.1,6.2
					c-1.3-0.4-2.8-0.9-4.4-1.3l-0.7-0.2c-0.1,0-0.1,0-0.2,0c-2.4-0.4-4.2-0.7-5.9-0.7c-2.4,0-4.3,0.5-8,1.7c-5,1.3-7.7,2.3-8.9,3.5
					c-0.1,0.1-0.2,0.3-0.3,0.4c-0.1,0-0.2,0-0.3,0c-3.5,0-6,1.2-9.3,4.5c-5.8,4.8-5.8,4.8-9.5,6.7c-0.2,0.1-0.4,0.2-0.5,0.4
					c-0.5,0.5-1.4,1-2,1.2c-0.9-0.8-2.1-1.6-3.8-1.8c-0.2-0.2-0.4-0.3-0.6-0.5c-1-0.8-2.3-1.8-3.5-3.6c-0.1-0.1-0.2-0.2-0.2-0.3
					l-1.6-1.6c-0.4-1.3-1.5-2-2.7-2l-10.8-1.5c0.4-0.9,0.6-1.7,0.6-2.5c0-5-4.4-6.1-10.5-7c-3.3-1.1-5.6-1.5-7.7-1.5
					c-2.1,0-4,0.5-6.2,1.5c-1.8,0-4.4-0.1-6.9-0.9c-0.1,0-0.3-0.1-0.4-0.1l-8-1c-0.1,0-0.2,0-0.2,0h-1h-3c-1,0-4.1,0-7.3,1
					c-4,0-11.3-1-14.8-2.8c-0.8-0.4-1.7-0.6-2.6-0.6c-1.7,0-3.4,0.7-4.7,2c-0.4,0.4-0.6,0.9-0.6,1.4c0,0.1,0,0.3-0.1,0.4
					c-0.1,0.8-0.4,2,0.1,3.4c-4.1,0.2-7,5.8-7.9,9.6c-1.2,3.7-0.1,6.8,3,8.3c0.3,1.2,1.1,2.2,1.9,3v0.8c-0.1,0.2-0.1,0.3-0.2,0.5
					c-0.8-0.6-1.7-0.9-2.7-0.9c-0.7,0-1.4,0.2-2,0.4c0.5-3-1.1-5.7-4.3-7.3c-0.3-0.1-0.6-0.2-0.9-0.2c-3.5,0-5.8,2.2-6,5.5
					c-0.5,1.3-0.5,2.6,0,3.8c0.5,1.1,1.6,2.7,4.4,3.6c0.1,0,0.2,0,0.2,0.1c-0.1,0.3-0.2,0.6-0.3,0.9c-3.8-2.5-8.5-3.7-8.8-3.8
					c-1.2-0.3-2.2-0.5-3.2-0.5c-2.4,0-3.9,1-5.8,2.3c-0.2,0.1-0.4,0.3-0.7,0.5c-0.1,0.1-0.2,0.2-0.3,0.2l-2.4,2.4
					c-0.9,0.1-1.9,0.2-2.9,1h-0.8c-1.2-0.6-1.4-0.8-2.8-2.2c-0.5-1-1-1.7-1.5-2.4c-0.4-0.6-0.8-1.2-1.2-1.9c-0.2-3.2-2.2-5.5-5-5.5
					c-2.7,0-4.9,2.4-7.9,14.4c-0.9,2.7-1,4.7-1.1,6.5c-0.4,0.4-0.8,0.8-1.2,1.2c-1.3-0.6-2.3-0.9-3.2-0.9c-0.7,0-1.4,0.2-2,0.5
					l-1.8-3.7c-0.2-0.4-0.5-0.7-0.9-0.9c-0.9-0.4-0.9-0.4-0.9-2.2c0-0.3-0.1-0.6-0.2-0.9c-0.2-0.4-0.4-1.1-0.6-1.7
					c-0.2-0.6-0.3-1.2-0.6-1.9c1.2-0.5,2.1-1.4,2.8-2.1c0.4-0.4,0.6-0.9,0.6-1.4c0-1.5-1.1-3.7-2.2-5c0.1,0,0.1,0,0.2,0h1
					c2.2,0,3.6-0.7,3.9-2.1c1.5-1.9,0.8-4-0.1-5.8c-0.3-0.6-0.9-1-1.6-1.1c-0.3-0.2-0.8-0.5-1.5-0.5c-0.6,0-1.1,0.2-1.5,0.5
					c-0.2,0-0.3,0-0.4,0.1c-0.9-1.4-2.1-2.3-3.3-3c0.8-1.3,1.5-3,1.5-4.8c0.2-0.7,0.4-1.3,0.5-1.9c0.7-2.4,1.3-4.4-1.1-6.8
					c-0.4-0.4-0.4-0.6-0.4-1.6c0-2.5-1.7-3.9-2.9-4.8c-0.3-0.2-0.5-0.4-0.7-0.6c-0.3-0.3-0.7-0.5-1.1-0.6c-0.2-0.2-0.5-0.5-1-0.7
					c-1.7-2.8-4.4-4.3-7.7-4.3c-1.7,0-3.4,0.4-5.3,1.1c-0.6,0.2-1.2,0.3-1.7,0.3c-0.9,0-1.5-0.3-1.9-0.6c-1-2.4-2.9-4.2-4.6-5.3
					c0.5-1,0.2-2.2-0.9-3.5c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.4-0.3-0.8-0.6-1.1c-1.3-1.3-3.8-2.6-6.4-2.6c-2.1,0-3.8,0-5.4,1.6
					c-0.4,0.4-0.7,0.9-0.9,1.4c-2.8,0.1-4.4,1.7-4.7,3.5c-1,2.5,0.7,4.3,1.7,5.2c-0.6,1.4-0.6,3-0.6,4.3c0,0.8,0,2,0.4,3.5
					c-0.7-0.3-1.5-0.5-2.4-0.5c-1.4,0-3.4,1-4.7,2H240c-1.4,0-2.1-0.1-2.4-0.3l-0.8-1.6c0-0.1-0.1-0.1-0.1-0.2
					c-2.3-3.4-4.9-4.9-8.7-4.9c-0.6,0-1.3,0-2,0.2c0.3-0.4,0.6-0.8,0.8-1.3c0.1-0.3,0.2-0.6,0.2-0.9c0-0.1,0.1-0.4,0.1-0.7
					c0.3-1.2,0.8-3.3-0.7-4.7c-1.4-1.4-2.8-2.5-7.8-3.5c-1.9-1-3.9-1.9-6.8-2.9c-3.6-2.1-7-3.2-10-3.2c-1.7,0-3.1,0.3-4.4,1h-1.1
					c-0.5-0.1-1.5-0.4-2.6-0.4c0.3-1.7,0.3-3.3,0.3-3.6c0-1.1-0.5-2.1-1-2.7v-0.5c0,0,0.1,0,0.1-0.1c2.9-2,4.8-3.4,4.9-7.2
					c0.8-2,0.4-4-0.6-5.6c0.3-0.4,0.6-1,0.6-1.9c0-0.3-0.1-1.9-1.6-3.4C196.1,75.5,194.7,74.2,193,74.2L193,74.2z M192,94.2
					L192,94.2L192,94.2L192,94.2z M210.9,86.2h-2c-1.3,0-3.7,0.9-4.9,4.4c-0.1,0.2-0.1,0.4-0.1,0.6c0,3.5,2.1,6,5,6
					c2.8,0,4.7-1.6,5-4.3c0.5-0.9,0.6-2.1,0.2-3.3c-0.3-1-1-2-1.7-2.8C211.9,86.4,211.4,86.2,210.9,86.2L210.9,86.2z M364,96.2h-1
					c-3.3,0-4.8,2.1-5.8,4.1c-0.1,0.3-0.2,0.6-0.2,0.9c0,1,0,2.3,1,4.5c0.1,2.1,1.3,3.6,3.1,4.2c0.2,0.5,0.6,0.9,1.1,1.1
					c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1,0.2-0.1,0.4-0.1,0.6c0,0.6-0.1,0.9-0.2,1c-0.5,0-0.9,0.2-1.2,0.6c-0.2,0.2-0.4,0.4-0.7,0.6
					c-1,0.8-2.5,2-2.8,4.1c-1.5,2.2,0.7,4.3,1.5,5.2c0.4,0.4,0.9,0.6,1.4,0.6h1c1.3,0,3-0.8,4.4-1.7c2.1,0.7,4.6,0.7,6.4,0.7
					c4.9,1,8,1.4,10.3,1.4c1.4,0,5.5,0,6.8-3.8c0.1-0.2,0.1-0.4,0.1-0.6c0-1-0.4-1.8-0.7-2.4c-0.1-0.2-0.3-0.5-0.3-0.6
					c0-0.5-0.2-1-0.6-1.4c-0.1-0.1-0.3-0.5-0.5-0.9c-0.5-1.1-1.3-2.8-3.3-4.9c-3.1-5.1-3.5-5.5-8.1-6.7c-0.1,0-0.1,0-0.2,0
					c-1.1-0.2-2.1-0.3-3-0.3c-0.8,0-1.6,0.1-2.3,0.2c0-0.3,0-0.7,0-1c0-0.3-0.1-0.6-0.2-0.9C368.7,99.2,367.2,96.2,364,96.2
					L364,96.2z M329,98.2h-2c-0.5,0-1,0.2-1.4,0.6c-1.6,1.6-1.6,3.3-1.6,5.4c0,0.2,0,0.4,0.1,0.6c0.1,0.4,1.2,3.5,3.6,5
					c0.1,0.2,0.2,0.3,0.4,0.6c0.3,0.4,0.7,1.1,1.5,1.6c0.7,1.3,2.2,3.3,4.4,3.3c1.1,0,2.8,0,4.4-1.6c0.1-0.1,0.2-0.2,0.2-0.3
					c2.3-3.5,0.7-6.3-0.3-8c-0.2-0.3-0.4-0.7-0.5-1c-0.1-0.2-0.2-0.4-0.4-0.5C334.4,100.7,331.6,98.2,329,98.2L329,98.2z M315.5,120
					c-1.2,0-2.3,0.4-3.3,1.2H312c-0.5,0-1,0.2-1.4,0.6c-1.6,1.6-1.6,4.2-1.6,5.4c0,0.3,0.1,0.6,0.2,0.9c0.5,1,2.1,4.1,4.8,4.1h1
					c2.8,0,4.3-1.5,5.4-2.6c0.4-0.4,0.6-0.9,0.6-1.4v-2v-3c0-0.8-0.4-1.5-1.1-1.8C318.6,120.8,317.2,120,315.5,120L315.5,120z"/>
			</g>
			<g id="st1">
				<path id="st2" d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,53.7c-2,0-4.8,0-6.2,2.6c-1.3,1.5-1.4,4-0.2,6.4c0.1,0.2,0.2,0.6,0.3,1
					c-0.7-0.5-1.2-1-1.7-1.5c-2.1-3.5-4.2-6.3-7.8-6.3c-1.2,0-2.4,0.3-3.9,0.9c0,0-0.1,0-0.1,0.1c-4.5,2.3-6.8,5.7-6.8,10.3v0.4
					c-0.4,0.4-0.7,0.8-1.1,1.2c-0.1-0.2-0.2-0.5-0.4-0.6c-0.3-0.3-0.6-0.7-0.9-1.1c-0.8-1.1-1.8-2.4-3.2-2.4h-1
					c-0.2,0-0.3,0-0.5,0.1c-1.3,0.4-2.4,1.6-3.2,3.5c-1.2,2.9-1.6,7.2-0.8,9.2c0.1,2.6,1.8,4.2,4.5,4.2h1c2.8,0,3.9-1.9,4.5-3.6
					c0.2,3.5,1.2,5,3.6,8.1l0.6,0.8c3.7,4.6,5.2,6.3,7.5,6.6c1.1,1.1,2.2,2,3.7,2c2.4,0,4.8-1.2,7.1-3.4c0.1-0.1,0.1-0.1,0.2-0.2
					c1.4-2,5.2-3.6,9-3.6c0.8,0,1.7,0.1,2.4,0.2c0.1,0,0.2,0,0.3,0c3.1,0,5.7-0.1,7.1-1.4c0.2-0.2,0.3-0.4,0.4-0.6c0,0,0,0,0,0
					c3.3-1.1,4-2.4,4-4.3c0.5-0.2,0.9-0.5,1.1-0.7c1.3-0.3,2.4-1.3,3.4-2.4c1.5-1.5,2.4-4,1.4-6.4c0-0.7,0.1-1.3,0.5-2.4
					c0.3-0.1,0.5-0.3,0.6-0.4c0.7,4.5,3.2,6.6,8.8,7.6l5.8,2.9c0.5,0.2,0.9,0.5,1.4,0.7c1.3,0.7,2.5,1.4,4.3,1.4h1
					c5,0,8.4-2.7,11.1-5.4c0.1-0.1,0.1-0.1,0.2-0.2l1.9-2.9c0.7-0.8,1.1-1.6,1.1-2.4c0-0.9-0.4-1.8-1.2-2.6l-0.7-0.7
					c-0.2-0.6-0.5-1-0.9-1.3v-1c0-0.4-0.2-0.8-0.4-1.1c-1.4-1.4-3-1.4-4.1-1.4c-1.8,0-5.2,0-7.7,1.4c-0.2-0.6-0.6-1.3-1.2-2
					c-1-1-2.2-1.5-3.5-1.5c-1.8,0-3.7,0.9-6.3,2.2c-0.1,0.1-0.3,0.2-0.4,0.3l-0.5,0.5c0,0,0-0.1-0.1-0.1l-1-2
					c-0.1-0.1-0.2-0.3-0.3-0.4c-0.8-0.8-1.7-1.2-2.6-1.2c-0.8,0-1.5,0.3-2.2,0.8c-1.1,0.3-1.7,1.2-1.8,2.1l-0.5,1.4
					c-0.9-1.7-3.2-2.8-6.4-4.4c-0.1,0-0.2-0.1-0.3-0.1c-0.9-0.2-1.6-0.4-2.3-0.6c-1.7-0.5-3-0.8-4.2-0.8c-0.8,0-1.5,0.1-2.1,0.4
					c-1.4,0-2.1-0.1-2.4-0.2c-0.1-0.1-0.2-0.2-0.3-0.4c-2.2-2.2-5.4-4.3-7.4-5.3C363.5,53.8,363.2,53.7,363,53.7L363,53.7z
					 M193,74.7c-0.4,0-0.8,0.2-1.1,0.4l-0.7,0.7c-0.7,0.2-1.1,0.6-1.3,0.8c0,0,0,0-0.1,0.1H189c-1.9,0-4.3,0-5.8,2h-0.6
					c-0.6-0.4-1.5-1-2.6-1c-1.9,0-4.5,1.7-4.5,4.5c0,2.5,1.2,5.1,3.1,5.5c1.6,0.8,2.4,1.7,3.4,2.6c0.9,0.9,1.7,1.5,2.2,1.8
					c0.1,0.1,0.3,0.2,0.4,0.3v0.8c0,0.4,0,1,0.1,1.5H184c-0.4,0-1.3-0.4-1.5-0.7c0-0.3-0.2-0.6-0.4-0.8c-1.1-1.1-2.4-2.4-5.1-2.4
					c-1.3,0-1.8,0.2-2.7,1.1c-1.4,0.5-2.4,2.2-2.7,3.6c-0.2,0.2-0.5,0.3-0.8,0.4c-0.5,0.2-1.1,0.5-1.7,1.1c-2.3,1.3-3.4,3.5-4.5,5.6
					c-0.1,0.2-0.2,0.4-0.2,0.7c0,1.9,0,3.3,1,4.6v3.1c-1,2.3-2,6.2-2,8.3c0,0.6,0,1.1-0.1,1.7c-0.2,2-0.3,4.2,2.2,6.2
					c0.2,0.6,0.6,1,0.8,1.2c0,0,0,0,0.1,0.1c0,2.2,1,3.5,2,4.5c-0.2,0.1-0.4,0.2-0.5,0.3c-2.7,2.7-3.4,13-3.4,13.1
					c0,1.1,0.6,1.7,0.9,2.1c0,0,0,0,0.1,0.1v0.8c-0.5,0.4-0.7,1-0.9,1.5H164c-0.2,0-0.5,0.1-0.7,0.2c-1.7,0.8-3.5,0.8-5.3,0.8
					c-0.1,0-0.2,0-0.2,0c-5.7,1-9.7,1.9-12.1,6.8c0,0,0,0,0,0c-0.4-0.1-0.9-0.1-1.3-0.1c-3.1,0-5.7,2-7.4,3.7
					c-0.3,0.3-0.4,0.7-0.4,1.1v3v2.4l-1.5,1.5c-0.8-0.7-1.8-1.5-3.3-2.2c-0.2-0.1-0.4-0.2-0.7-0.2c-3.1,0-7,0-9.3,1h-4.4
					c-0.4-0.1-0.7-0.2-1-0.4c-1.6-0.6-3-1.1-4.4-1.1c-0.9,0-1.7,0.2-2.5,0.6c-1.9,1-2.5,3-3,4.7c-0.2,0.7-0.4,1.4-0.7,2
					c-0.1,0.2-0.2,0.4-0.2,0.7c0,3,0,5.2,1.1,8.5c0.1,0.2,0.2,0.4,0.4,0.6c0.1,0.1,0.3,0.3,0.3,0.3c0.1,0.4-0.3,2.1-0.7,4.3
					c0,0.1,0,0.2,0,0.3c0,1.7-1,4.6-1.9,7.4c-1.1,2.1-2.1,5.3-2.1,7.6c0,0.8,0,1.1-1.2,1.7c-0.1,0.1-0.3,0.2-0.4,0.3
					c-0.7,0.7-3,3.7-3.4,5.6c-0.2,0.4-0.3,0.7-0.5,1c-0.5,1.1-0.6,1.5-1.5,2c-0.7,0.1-1.3,0.4-1.7,0.6c-0.3,0.1-0.7,0.3-0.8,0.3
					c-1.5,0-2.5,0.4-3.3,0.7c-0.3,0.1-0.6,0.3-0.7,0.3c-0.4,0-0.8,0.2-1.1,0.4c-0.2,0.2-0.4,0.4-0.7,0.6c-1,0.8-2.4,2-2.7,3.9
					c-1,1.5-1,3.7,0,5.2c0.1,0.3,0.2,0.5,0.2,0.7c0.1,0.3,0.3,0.6-0.2,2.2c-0.1,0.2-0.1,0.3-0.1,0.5v2.6c-0.1,0-0.2,0.1-0.3,0.2
					c-3.4,2.2-3.6,3.8-4.5,9l-0.2,0.9c-1,4.2-1,9.2-1,11.3c0,0.2,0,0.3,0.1,0.5l0.6,1.8l-0.8,0.4c-0.1,0.1-0.3,0.2-0.4,0.3
					c-0.2,0.2-0.8,0.4-1.4,0.6c-0.7,0.2-1.4,0.5-2.1,0.9c-3.6,0.2-4.8,1.9-5.9,5c0,0.1-0.1,0.2-0.1,0.3c-0.8,0.4-1.5,1.1-2.2,1.8
					c-1.7,0.6-2.8,3-2.8,4.4c0,1.5,1.3,3.9,2.4,5.1c0.1,0.1,0.3,0.3,0.5,0.5c0.9,1.1,2.4,2.9,5.6,2.9c0.1,0,0.5,0.4,0.5,1.5
					c0,0.2,0.1,0.5,0.2,0.7c1.2,2.4,5.3,5.4,7.4,6.6c1,1,2.3,2.2,4.6,2.3c0.5,0.2,1.1,0.4,1.4,0.5c0,0.4-0.3,1.5-0.6,2.6
					c0,0.1,0,0.2,0,0.4v0.2c-0.4-0.1-0.8-0.1-1.2-0.1c-1,0-1.7,0.3-2.3,0.9c-0.2,0.2-0.8,0.9-1.2,1.8c-1.2,0.6-2.2,2-2.2,4.2
					c0,1.9,2.2,3,4.8,4.3c0.1,0,0.1,0.1,0.2,0.1c1.1,0.4,4.2,1.4,2,11.3c-0.9,4.6-0.9,4.6-1.6,5.3c-1.1,1.1-3.4,3.4-3.4,6.1
					c0,2.9,1.8,4.2,4.4,5.2c0,0.2,0,0.5-0.2,0.9l-2.8,2.8c-2,2-3.3,3.3-4.4,6.6c-0.1,0.2-0.1,0.3-0.1,0.5c0,2,0,4.1,1,5.5v0.9
					c-0.7,1-1.2,2.6-0.9,4c0.2,1.1,0.9,1.9,1.9,2.5v0c0,2.4,1.2,3.8,2.7,4.7l-0.4,1.1c-1.2,0.6-2.5,1.8-3,3
					c-4.5,1.8-9.2,8.8-11,12.3c-1.1,1.2-2,3-3,5c-1.1,1.2-7.3,7.9-7.3,10.9c0,0.9,0,2.2,0.9,3.4c-0.5,2.9,1.6,6,5.8,8.9
					c0.1,0,0.1,0.1,0.2,0.1c1.6,0.8,3.1,1.2,4.6,1.2c2.4,0,4.6-1,6.9-3.3c0.1,0.1,0.3,0.1,0.5,0.2c2.6,2.1,7.5,3.1,9.7,3.1
					c2.5,0,3.9-1.3,4.1-1.4c0.3-0.3,0.6-0.4,0.8-0.5c0,0,0.1,0.1,0.1,0.1c0.1,0.2,0.2,0.4,0.4,0.6c-1.2,1-1.6,2.3-2,3.4
					c-0.2,0.6-0.4,1.1-0.6,1.6c-0.1,0.2-0.2,0.4-0.2,0.7c0,2.1,0,3.6,1.4,5.1c0.1,0.1,0.2,0.2,0.4,0.3c0.6,0.3,1.1,0.4,1.7,0.4
					c1.7,0,2.8-1.3,3.5-2.1c0.2-0.2,0.4-0.4,0.5-0.6c0.1-0.1,0.3-0.3,0.3-0.5c0.7-0.4,3-1.1,3.6-1.1c3.6,0,5.9-2.3,7.1-3.4
					c0.1-0.1,0.1-0.1,0.2-0.2c1.7-2.5,5-5,5.9-5.3c3.7-0.1,10-5.4,11.2-7.8c0.2-0.1,0.7-0.2,1.7-0.2c5.2,0,6.3-2.9,6.5-5
					c3.4,0,4,0.1,6.5,2.6l2.6,2.6c0.3,1.1,1.2,2.1,2.3,2.6c0.2,0.3,0.4,0.6,0.7,0.8v3c0,0.7,0.3,1.6,0.6,2.5
					c0.2,0.5,0.4,1.3,0.4,1.5v0.8c-0.2,0.6-0.4,1.1-0.6,1.6c-0.7,1.7-1.3,3.2-0.2,5.3c1.3,2.5,4.4,3.6,7.3,3.8
					c0.3,0.5,0.8,0.8,1.2,1c0.3,0.2,0.6,0.3,0.8,0.6c0.3,0.3,0.7,0.4,1.1,0.4c0.9,0,2.2-0.4,3.2-1.2c0.6,0.5,1.3,1,2.2,1.6
					c-0.1,0.7-0.4,1.9-0.8,3.8c-0.8,3.3-0.4,4.9-0.1,5.9c0,0.2,0.1,0.4,0.1,0.5v1.8c-0.8,2.6-1.2,5.3,1,7.8c0,3.6-0.4,7.5-1,11.1
					c-0.1,0.4-0.3,0.8-0.4,1.1c-0.8,2.4-1.5,4.4-0.6,6.5c0,1.6,0.1,4.1,2,4.9c0,1.8,0.2,4,0.9,5.8c-0.5,0.4-0.9,1-0.9,1.9
					c0,2.1,0.9,3.5,2,4.1c0,0.9,0.1,3,1.9,3.3c0.8,0.5,1.7,0.8,3,0.8c1.2,0,2.8-0.3,5-0.8c0.3-0.1,0.5-0.2,0.7-0.4
					c0.2-0.2,0.3-0.3,0.9-0.3c0.9,0,2.4,0.3,4.6,0.7c2.5,0.8,4.2,1.4,4.9,1.7c0.1,3.5,3.1,4.9,5.1,5.8c0.3,0.1,0.5,0.2,0.7,0.4
					c0.2,0.1,0.4,0.2,0.7,0.2c0.1,0,0.3,0,0.4,0c0.1,0.1,0.4,0.3,0.6,0.6c0.7,0.7,1.3,1,1.9,1.3c0.5,0.2,0.8,0.4,1.1,0.7
					c0.2,0.2,0.4,0.3,0.6,0.4c0,0.1,0.1,0.2,0.1,0.2c0.5,0.9,1.9,3.8,4.3,3.8c0.9,0,1.6-0.4,2.2-0.7c0.1-0.1,0.3-0.2,0.5-0.2
					c0.1,0.3,0.4,0.6,0.7,0.7c2,1,4.1,2,7.2,3.1c0.2,0.1,0.3,0.1,0.5,0.1c1,0,1.9,0,2.1,0.1c0.1,0.1,0.3,0.6,0.6,1.1
					c0.1,0.1,0.2,0.3,0.3,0.4c0.5,0.5,0.6,0.9,0.6,1c0,2.6,1.4,3.9,3,4.9v0.1c0,3.8,3.3,4.4,7.1,5.1c0.7,0.1,1.4,0.2,2.1,0.4
					c0.1,0,0.2,0,0.3,0c0.7,0,2.4,0,2.9,0.6c1.1,1.1,2.4,2.4,5.1,2.4c1,0,1.6,0.3,1.8,0.5c1.1,2.1,2.3,3.3,4.5,4.4
					c0.2,0.1,0.4,0.2,0.7,0.2h1.4c0.3,0.2,0.8,0.4,1.3,0.4c0.6,0,1-0.3,1.2-0.4c0,0,0,0,0.1,0c0.5,0,1-0.2,1.5-0.5
					c0.9,0.4,1.8,0.6,2.7,0.6c1.3,0,2.2-0.5,2.8-1.2h0c0.3,0.5,0.8,0.7,1.1,1c0.3,0.2,0.6,0.3,0.8,0.6c0.3,0.3,0.7,0.4,1.1,0.4h3
					c1,0,1.6-0.5,2-1c0.6,0,1.2,0.1,1.8,0.2c0.6,0.1,1.3,0.2,1.9,0.2c0.7,0,1.3-0.1,1.8-0.4h0.7c1.2,1.7,3,2.8,4.4,3
					c1.5,0.7,3,1.4,4.6,1.4c0.9,0,1.7-0.2,2.4-0.6c0.1-0.1,0.3-0.2,0.4-0.3c0.7-0.7,1.3-1.7,1.6-2.6c0.1-0.3,0.2-0.6,0.2-0.8
					c0.4,0.1,0.8,0.1,1.2,0.1c1.3,0,2.4-0.4,3.5-1.3h1.1c2.3,1,5.3,1,7.3,1h5c1.1,0,2.6,0,4.1-1.4s1.4-3,1.4-4.1
					c0-0.3-0.1-0.6-0.3-0.9c-0.2-1,0.9-4.7,1.5-6.9c0.3-0.9,0.5-1.8,0.7-2.7c0.3-0.5,0.5-0.8,0.7-0.9c0.7,0.2,1.3,0.3,1.8,0.3
					c2.9,0,3.9-2.6,4.5-4.2c2.6-4,0.6-7.9-1.1-10.5c-0.2-0.3-0.5-0.5-0.8-0.6c-0.1-0.9-0.5-1.9-1.1-2.9c1-1.2,3.1-4.1,3.1-5.7
					c0-0.3,0.8-1.2,1.4-1.8c2.6-1.5,2.6-4.2,2.6-5.2c0-2.2-1.1-3-1.8-3.6c-0.2-0.2-0.4-0.3-0.6-0.5c-0.2-0.2-0.3-0.3-0.5-0.5
					c1.7-0.4,2.7-2.6,2.9-4c0.5-1,0.7-1.7,0.9-2.4c0.2-0.7,0.5-1.4,0.9-2.3c0.1-0.2,0.2-0.4,0.2-0.7v-0.6c0.1,0,0.1,0,0.2-0.1
					c2.9-1.4,3.8-6.3,3.8-8.3v-0.8c1.4-0.6,2-2,2-3.1c0.2-0.8,2.1-3.3,3.2-4.6c2.5,0,8.1,0.3,17,2l4,1c0.1,0,0.2,0,0.4,0
					c0.8,0,3.3,0,3.5-3.1l0.9-1.7c0.1-0.2,0.2-0.4,0.2-0.7c0-0.1,0.1-0.5,0.3-0.7c0.3-0.6,0.6-1.4,0.7-2.5c0,0,0,0,0,0
					c0.6,0,2,0.3,4.2,0.7c2.1,0.4,3.7,0.7,5.2,0.7c2,0,3.7-0.5,5.6-1.7c0.6-0.1,1.2-0.4,1.7-0.6c0.3-0.1,0.7-0.3,0.8-0.3
					c0.1,0,0.2,0,0.4,0c2.3-0.6,5.1-2.2,5.1-6.5c0,0,0.1-0.2,0.4-0.3c0.5,1.4,1.7,2.8,3.1,2.8c0.8,0,3,0,3.4-1.8
					c1.6-2,1.6-4.9,0.1-8.9v-9.6c0.1-0.7,0.3-1.3,0.4-1.9c0.7-3.1,1.3-5.8-1.4-7.3v-1.8c0.5-1.9,0.9-3.3,1.3-4.3
					c0.2-0.6,0.3-1,0.5-1.5c1.9-0.3,3.6-1.3,4.1-2.7l2.7-2.7c2.5-2.5,5.2-6.5,5.4-10.5c0.3-0.4,0.7-1,0.9-1.7
					c2.4-1.9,3.2-5.1,2.4-9.5c1.8-0.3,2.7-1,3.2-1.8c1.9,0.4,3.5,1.6,3.5,2.4c0,0.2,0.1,0.5,0.2,0.7c1.1,2.2,3.3,4.5,5.8,4.8
					c0.5,0.4,1.1,0.4,1.4,0.4c1,0,1.8-0.6,2.6-1.2c0.1,0,0.1,0.1,0.2,0.1c0.4,1.4,1.9,2.7,4.4,2.7c0.1,0,1.2,0,2.4-0.9
					c0.4,0.1,0.7,0.1,1,0.1c2.7,0,3.7-2,4.2-3c0.1-0.2,0.2-0.4,0.3-0.6c0.3,0.2,0.6,0.4,1,0.4h0.4c1.1,0.9,2.2,1,3.1,1
					c1.1,3.3,2.5,4,4.4,4c0.4,0,0.8-0.2,1.1-0.4c0.5-0.5,1.6-1.6,1.9-1.6c0.4,0,0.8-0.2,1.1-0.4c1.3-1.3,1.4-2.7,1.4-3
					c0.1-0.8,1.8-4.2,3.6-6c0.1-0.1,0.1-0.1,0.2-0.2c2.2-3.3,2.3-4.9,1.3-8.1c0,0,0-0.1,0-0.1c0.1-0.1,0.3-0.4,0.6-0.6
					c0.3-0.3,0.4-0.7,0.4-1.1c0-0.1,0.2-0.6,0.3-0.8c0.3-0.6,0.7-1.3,0.7-2.2c0-1.5-1.2-2.8-1.4-3.1c-0.2-0.2-0.4-0.4-0.6-0.6
					c0-0.1,0-0.2,0-0.4c0-0.2-0.1-0.5-0.2-0.7c-0.4-0.9-0.6-1.7-0.9-2.7c-0.3-1.1-0.5-2.2-1.1-3.3c-0.1-0.1-0.2-0.3-0.3-0.4
					c-0.5-0.5-0.6-1.9-0.6-2.7c1-3.1,1-6.2,1-8.2c0-1.8,0-2.8,0.2-3.3c0.1-0.4,0.5-0.7,1.4-1.7c2.2-2.2,3.3-4.5,3.4-7.4
					c1,0.2,1.8,0.6,1.8,0.6c0.2,0.1,0.4,0.2,0.7,0.2c2.8,0,4.2-0.8,5.3-1.7c0.8,0.9,1.8,1.6,2.6,2.3c0.5,0.4,0.9,0.7,1.1,1
					c0.3,0.3,0.7,0.4,1.1,0.4c4.3,0,4.5-4.2,4.5-6.3c0.7-2.2,1.1-4.5-0.2-6.8c0.3,0,0.5,0,0.7,0c0.6,0,7.7-1.4,9.9-4.6
					c1.2,3.1,4.8,5.6,7.1,5.6c0.8,0,3,0,3.4-1.8c0.9-1.1,1-2.3,1.1-3.2c0.2-0.3,0.8-0.9,1.5-0.9c0.4,0,0.8-0.2,1.1-0.4
					c0.3-0.3,0.7-0.6,1.1-0.9c0.7-0.6,1.5-1.1,2-1.9c0.6-0.2,1.1-0.5,1.5-0.8c0.4,0,0.9-0.1,1.3-0.2c1.2,1.1,2.4,1.4,3.2,1.4
					c0.8,0,1.4-0.2,1.5-0.3c3.5-1.8,2.4-5.7,1.8-7.5c2-1.5,2-4,2-5.8c0-0.4-0.2-0.8-0.4-1.1c-0.8-0.8-1.6-1.6-2.6-2.1
					c0-0.3-0.2-0.7-0.4-0.9c-0.4-0.4-0.6-1.4-0.6-1.9v-1.9c1.4-0.7,2.3-2.4,2.7-3.2c3-3,3.3-3.3,3.3-4.9c0-1.1-0.6-2.6-2-3.2v-2.3
					c1.1,0,2.8-0.2,3.6-1.2c1.4-0.5,2.4-1.8,2.4-3.3c0-2,0-3.2-1-5.4c1.9-0.2,2.9-1.1,3.5-2.2c2.7-0.1,10-0.8,13.2-3.5
					c0.4,0.2,0.9,0.3,1.3,0.5c0.6,0.2,1.2,0.4,1.4,0.6c0.1,0.1,1,0.9,2.4,0.9c0.8,0,1.5-0.2,2.3-0.6c0.1-0.1,0.3-0.2,0.4-0.3
					c0.7-0.7,1-1.3,1.3-1.9c0.2-0.5,0.4-0.8,0.7-1.1c0.2-0.2,0.3-0.5,0.4-0.7c0.1-0.2,0.6-0.9,2.4-2.1l3-2c0.1-0.1,0.2-0.1,0.2-0.2
					c1.4-1.4,1.4-3,1.4-4.1c0-0.3-0.1-0.6-0.3-0.8l-1.8-2.7c-0.1-0.6-0.4-1.2-0.6-1.7c-0.1-0.2-0.2-0.4-0.3-0.6
					c1-2.5-0.2-5.8-2.1-10.7c-1.2-4.6-3-5-7.4-5.9l-0.7-0.1c-0.1,0-0.2,0-0.3,0c-0.6,0-1.9,0-2.6-0.3c-0.3-1.2-1.3-2.2-2.3-3.2
					c-0.3-0.3-0.3-0.4-0.3-0.4c0,0,0,0,0-0.1c1.4-0.4,2.7-1.9,2.7-4.4c0-2.3-1-3.6-2-4.6c0-0.6,0.1-0.9,0.6-1.3
					c0.3-0.3,0.4-0.7,0.4-1.1v-1c2.5-1.3,5.7-4.1,8.2-6.5c3.6-0.4,7.6-3.6,8.7-5.8c0.1-0.2,0.2-0.4,0.2-0.7c0-0.2,0.3-0.5,0.5-0.5
					c2.1,0,3.3,0,5.6-1.1c3.9-1.3,3.9-3.4,3.9-6.4c0-1.8,0-5.8-2.6-7.7c0.1-0.9-0.1-1.8-0.3-2.5c-0.1-0.3-0.2-0.7-0.2-0.8
					c0-0.4-0.2-0.8-0.4-1.1c-1-1-2-2-3.3-2.4c0.3-1,0.2-2.1-0.2-3.1c-0.5-1.1-1.4-2.1-2.6-2.8c-0.2-0.2-0.5-0.4-0.8-0.7
					c-1-0.8-1.6-1.4-1.6-2.1c0-0.2-0.1-0.5-0.2-0.7l-1-2c-0.3-0.5-0.8-0.8-1.3-0.8h-0.1c-0.6-0.6-1.6-1-2.9-1
					c-0.3,0-0.6,0.1-0.8,0.3l-3,2c-0.1,0.1-0.2,0.1-0.2,0.2c-0.5,0.5-1.6,1.6-1.9,1.6c-1.9,0-3.1,0.7-4.3,1.4
					c-0.4,0.2-0.8,0.5-1.3,0.7c-1.4,0.5-2.2,0.6-2.9,0.6c-0.7,0-1.5-0.2-3-0.7c0-0.2,0-0.4,0-0.6v-3c0-1.5-0.4-2.5-0.7-3.3
					c-0.1-0.3-0.3-0.6-0.3-0.7c0-0.4-0.2-0.8-0.4-1.1c-0.3-0.3-0.5-0.7-0.7-1.1c-0.2-0.5-0.5-1-1-1.6c-0.3-1.1-0.9-2.1-1.6-2.9
					c0.4,0.1,0.9,0.1,1.3,0.1h1c1.3,0,2.3-0.3,3.1-0.9c0.5,0.6,1.1,1.2,2.2,1.7c0.2,0.1,0.4,0.2,0.7,0.2c2.6,0,4.3-2.3,4.5-4.1
					c1.1-2.3,1-3.7-0.1-6.1c-0.1-0.1-0.2-0.3-0.3-0.4c-1-1-2-2-3.2-2.3c0-0.7-0.2-1.2-0.4-1.6c-0.4-0.6-1.2-1.4-2.9-1.5
					c-0.4-0.3-1-0.4-1.5-0.4c-1.7,0-3.5,1.5-3.5,3.9c0,0.2,0,0.4,0,0.5c-0.2,0-0.4,0-0.5,0c-1.1,0-2.5,0-3.9,1.3
					c-1.3,0.7-2,1.8-2.2,3.1c-0.2,1.1,0.1,2.3,0.6,3.5c-1.6-0.6-3.4-1.1-5.4-1.6l-0.7-0.2c-0.1,0-0.1,0-0.2,0
					c-2.4-0.4-4.2-0.7-5.8-0.7c-2.3,0-4.2,0.5-7.8,1.7c-4.9,1.2-7.5,2.2-8.6,3.4c-0.2,0.2-0.3,0.3-0.4,0.6c-0.2,0-0.4,0-0.6,0
					c-3.4,0-5.8,1.2-9,4.4c-5.8,4.8-5.8,4.9-9.7,6.8c-0.1,0.1-0.3,0.2-0.4,0.3c-0.6,0.6-1.8,1.2-2.4,1.4c-0.9-0.9-2.1-1.7-3.9-1.9
					c-0.2-0.2-0.5-0.4-0.7-0.6c-1-0.8-2.3-1.8-3.6-3.8c-0.1-0.1-0.1-0.2-0.2-0.2l-1.7-1.7c-0.3-1.1-1.3-1.7-2.3-1.8l-11.5-1.6
					c0.5-1,0.9-2,0.9-2.9c0-4.6-4-5.6-10.2-6.5c-3.2-1.1-5.5-1.5-7.6-1.5c-2,0-3.9,0.5-6.1,1.5c-1.9,0-4.6-0.1-7.2-0.9
					c-0.1,0-0.2-0.1-0.3-0.1l-8-1c-0.1,0-0.1,0-0.2,0h-1h-3c-1,0-4.1,0-7.2,1c-4,0-11.5-1-15.1-2.8c-0.8-0.4-1.6-0.6-2.4-0.6
					c-1.5,0-3.2,0.7-4.3,1.9c-0.3,0.3-0.4,0.7-0.4,1.1c0,0.1,0,0.3-0.1,0.5c-0.2,0.9-0.4,2.3,0.3,3.9c-0.2,0-0.4,0-0.5,0
					c-3.9,0-6.7,5.5-7.7,9.2c-1.1,3.4-0.1,6.3,2.8,7.8c0.1,0,0.1,0.1,0.2,0.1c0.2,1.2,1,2.2,2,3.2v1.1c-0.2,0.4-0.3,0.8-0.4,1.3
					c-0.1-0.1-0.2-0.1-0.3-0.2c-0.8-0.7-1.7-1.1-2.7-1.1c-1,0-1.8,0.4-2.7,0.8c0-0.1,0-0.1,0-0.2c0.9-3.1-0.5-6-3.8-7.6
					c-0.2-0.1-0.4-0.2-0.7-0.2c-3.2,0-5.3,2-5.5,5.2c-0.5,1.2-0.5,2.4-0.1,3.5c0.4,1,1.5,2.4,4.1,3.3c0.2,0.1,0.3,0.1,0.5,0.1
					c0.1,0,0.3,0,0.4,0c-0.4,0.7-0.6,1.4-0.8,2.2c-2.9-2.1-6.8-3.6-9.2-4.2c-1.2-0.3-2.2-0.4-3-0.4c-2.3,0-3.6,0.9-5.5,2.2
					c-0.2,0.1-0.4,0.3-0.7,0.5c-0.1,0.1-0.2,0.1-0.2,0.2l-2.6,2.6c-0.9,0-1.9,0.2-2.9,1h-1.1c-1.4-0.7-1.6-0.8-3.1-2.4
					c-0.5-1-1-1.7-1.5-2.4c-0.4-0.7-0.8-1.3-1.3-2c-0.1-3-2-5.1-4.5-5.1c-2.3,0-4.5,2.4-7.4,14.1c-0.9,2.8-1,4.8-1.1,6.6
					c-0.6,0.5-1.1,1.1-1.5,1.7c-0.1-0.1-0.2-0.1-0.3-0.2c-1.3-0.6-2.2-0.9-3-0.9c-0.8,0-1.5,0.2-2.2,0.7c0,0,0,0,0,0
					c0-0.1,0-0.1-0.1-0.2l-2-4c-0.1-0.3-0.4-0.5-0.7-0.7c-1.2-0.6-1.2-0.7-1.2-2.7c0-0.2-0.1-0.5-0.2-0.7c-0.2-0.4-0.4-1.1-0.6-1.8
					c-0.2-0.7-0.4-1.5-0.7-2.3c1.4-0.5,2.3-1.4,3.1-2.1c0.3-0.3,0.4-0.7,0.4-1.1c0-1.5-1.3-3.9-2.4-5.1c-0.1-0.1-0.3-0.2-0.4-0.3
					c0.1-0.2,0.2-0.4,0.4-0.5c0.3,0.2,0.6,0.4,1,0.4h1c0.8,0,3,0,3.4-1.9c1.4-1.7,0.8-3.6-0.1-5.3c-0.2-0.5-0.7-0.8-1.3-0.8
					c0,0-0.1,0-0.1,0c-0.2-0.1-0.6-0.4-1.2-0.4c-0.5,0-1,0.2-1.3,0.5c-0.3,0-0.6,0-0.9,0.1c-1-1.7-2.4-2.7-3.8-3.4
					c0.9-1.3,1.7-3.1,1.7-5c0.2-0.7,0.4-1.4,0.6-2c0.7-2.4,1.2-4.1-1-6.3c-0.5-0.5-0.6-1-0.6-1.9c0-2.2-1.6-3.6-2.7-4.4
					c-0.3-0.2-0.5-0.4-0.7-0.6c-0.3-0.3-0.6-0.4-0.9-0.4c0,0,0,0-0.1-0.1c-0.2-0.2-0.6-0.6-1.1-0.8c-1.6-2.7-4.2-4.2-7.4-4.2
					c-1.6,0-3.3,0.4-5.1,1.1c-0.7,0.2-1.3,0.3-1.9,0.3c-1.1,0-1.9-0.4-2.3-0.8c-1-2.5-3.1-4.3-4.9-5.5c0,0,0,0,0-0.1
					c0.9-1.4-0.2-2.8-0.6-3.3c-0.1-0.1-0.3-0.3-0.3-0.4c0-0.3-0.2-0.7-0.4-0.9c-1.2-1.2-3.6-2.4-6.1-2.4c-2.1,0-3.6,0-5.1,1.4
					c-0.4,0.4-0.7,1-1,1.6c0,0-0.1,0-0.1,0c-2.7,0-4.3,1.5-4.5,3.1c-1,2.5,0.9,4.1,1.8,4.9c-0.8,1.3-0.8,3-0.8,4.5
					c0,0.9,0,2.7,0.7,4.6c-1-0.7-2.1-1.1-3.2-1.1c-1.4,0-3.3,1-4.6,2H240c-0.6,0-2.1,0-2.8-0.4l-0.9-1.7c0-0.1-0.1-0.1-0.1-0.2
					c-2.2-3.3-4.6-4.7-8.2-4.7c-0.9,0-2.2,0-3.4,0.9c-0.1-0.1-0.3-0.2-0.4-0.3c0.7-0.4,1.5-1.1,2.1-2.4c0.1-0.2,0.2-0.4,0.2-0.7
					c0-0.1,0.1-0.5,0.2-0.8c0.3-1.2,0.7-3-0.6-4.3c-1.3-1.3-2.7-2.4-7.6-3.4c-1.9-1-3.9-2-6.9-2.9c-3.5-2.1-6.9-3.2-9.8-3.2
					c-1.6,0-3.1,0.3-4.3,1h-1.3c-0.5-0.1-1.6-0.4-2.7-0.4c-0.1,0-0.2,0-0.3,0c0.3-1.9,0.4-3.8,0.4-4.1c0-1.1-0.6-2-1-2.6v-1
					c0.1,0,0.2-0.1,0.3-0.2c3.1-2.1,4.6-3.4,4.7-6.9c0.8-2,0.3-4-0.7-5.5c0.3-0.4,0.7-0.9,0.7-1.8c0-0.2,0-1.7-1.4-3.1
					C195.8,75.9,194.5,74.7,193,74.7L193,74.7z M210.9,86.7h-2c-1.1,0-3.4,0.8-4.4,4c-0.1,0.2-0.1,0.3-0.1,0.5
					c0,3.2,1.9,5.5,4.5,5.5c2.6,0,4.3-1.5,4.5-4c1.1-1.8-0.1-4.3-1.4-5.6C211.7,86.9,211.3,86.7,210.9,86.7L210.9,86.7z M364,96.7
					h-1c-3,0-4.3,1.8-5.3,3.8c-0.1,0.2-0.2,0.4-0.2,0.7c0,1.1,0,2.2,1,4.4c0.1,2.1,1.2,3.5,3,4c0.1,0.7,0.7,1.1,1.4,1.2
					c-0.1,0.3-0.2,0.7-0.4,1c-0.1,0.2-0.1,0.3-0.1,0.5c0,1.1-0.4,1.5-0.5,1.5c-0.4,0-0.8,0.2-1.1,0.4c-0.2,0.2-0.4,0.4-0.7,0.6
					c-1,0.8-2.4,2-2.7,3.9c-1.3,1.9,0.6,3.8,1.4,4.6c0.3,0.3,0.7,0.4,1.1,0.4h1c1.2,0,3-0.8,4.3-1.8c2.1,0.7,4.7,0.8,6.6,0.8
					c4.9,1,8,1.4,10.3,1.4c2.2,0,5.3-0.4,6.3-3.4c0.1-0.2,0.1-0.3,0.1-0.5c0-0.9-0.4-1.6-0.7-2.2c-0.1-0.3-0.3-0.7-0.3-0.8
					c0-0.4-0.2-0.8-0.4-1.1c-0.2-0.2-0.4-0.6-0.6-1.1c-0.5-1.1-1.3-2.8-3.3-4.8c-3-5-3.3-5.4-7.8-6.5c0,0-0.1,0-0.1,0
					c-1.1-0.2-2-0.3-2.9-0.3c-1,0-2,0.1-2.8,0.4c0-0.6,0-1.1,0-1.6c0-0.2-0.1-0.5-0.2-0.7C368.3,99.4,366.9,96.7,364,96.7L364,96.7z
					 M329,98.7h-2c-0.4,0-0.8,0.2-1.1,0.4c-1.4,1.4-1.4,3-1.4,5.1c0,0.2,0,0.3,0.1,0.5c0.1,0.4,1.2,3.4,3.5,4.7
					c0.2,0.2,0.3,0.4,0.5,0.7c0.3,0.4,0.7,1.1,1.4,1.4c0.7,1.2,2,3.2,4,3.2c1.1,0,2.6,0,4.1-1.4c0.1-0.1,0.1-0.1,0.2-0.2
					c2.2-3.2,0.7-5.8-0.4-7.5c-0.2-0.3-0.4-0.7-0.6-1c-0.1-0.1-0.2-0.3-0.3-0.4C334.1,101.2,331.4,98.7,329,98.7L329,98.7z
					 M315.5,120.5c-1.1,0-2.2,0.4-3.1,1.2H312c-0.4,0-0.8,0.2-1.1,0.4c-1.4,1.4-1.4,3.9-1.4,5.1c0,0.2,0.1,0.5,0.2,0.7
					c0.5,0.9,1.9,3.8,4.3,3.8h1c2.6,0,4-1.4,5.1-2.4c0.3-0.3,0.4-0.7,0.4-1.1v-2v-3c0-0.6-0.3-1.1-0.8-1.3
					C318.4,121.2,317,120.5,315.5,120.5L315.5,120.5z"/>
			</g>
			<g id="st0">
				<path d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,54.2c-2,0-4.5,0-5.8,2.4c-1.2,1.3-1.2,3.6-0.1,6c0.1,0.3,0.4,1.2,0.6,2.3
					c-1.3-0.8-2.1-1.5-2.9-2.3c-2-3.4-4-6.1-7.5-6.1c-1.1,0-2.3,0.3-3.7,0.8c0,0-0.1,0-0.1,0c-4.3,2.2-6.6,5.5-6.6,9.9v0.6
					c-0.7,0.7-1.3,1.4-1.7,2.1c-0.1-0.1-0.2-0.2-0.3-0.3v-0.5c0-0.3-0.1-0.5-0.3-0.7c-0.3-0.3-0.6-0.7-0.9-1.1
					c-0.8-1.1-1.6-2.2-2.8-2.2h-1c-0.1,0-0.2,0-0.3,0.1c-1.2,0.4-2.1,1.5-2.9,3.3c-1.2,2.8-1.5,7-0.8,8.9c0.1,2.4,1.5,3.8,4,3.8h1
					c3,0,3.8-2.3,4.3-4.1c0.2-0.5,0.4-1.1,0.6-1.5c0,0,0-0.1,0.1-0.1c0.1,0.1,0.2,0.3,0.3,0.4c0.1,0.1,0.2,0.3,0.3,0.4
					c-0.4,0.2-0.6,0.5-0.6,0.9c0,4,1,5.4,3.6,8.8l0.6,0.8c3.8,4.7,5.1,6.2,7.4,6.4c1,1,2.1,2,3.4,2c2.3,0,4.5-1.1,6.7-3.3
					c0,0,0.1-0.1,0.1-0.2c1.4-2.2,5.5-3.8,9.4-3.8c0.9,0,1.7,0.1,2.5,0.2c0.1,0,0.1,0,0.2,0c3,0,5.5-0.1,6.7-1.3
					c0.2-0.2,0.3-0.4,0.3-0.7c0,0,0,0,0,0c0.1,0,0.2,0,0.3-0.1c3.4-1.1,3.7-2.5,3.7-3.9V82c0.6-0.2,1.1-0.6,1.4-0.8
					c1.2-0.2,2.3-1.2,3.3-2.3c1.4-1.4,2.3-3.7,1.3-5.9c0-0.8,0.1-1.5,0.7-2.9c1.3-0.4,1.3-2.1,1.3-2.9c0-2.6-2.3-3.8-6.6-5.9
					c-0.1,0-0.1-0.1-0.2-0.1c-0.9-0.2-1.6-0.4-2.3-0.6c-1.6-0.5-2.9-0.8-4.1-0.8c-0.7,0-1.4,0.1-2,0.4c-1.9,0-2.6-0.2-2.8-0.3
					c0-0.2-0.1-0.3-0.2-0.4c-2.1-2.1-5.3-4.2-7.3-5.2C363.3,54.2,363.2,54.2,363,54.2L363,54.2z M393.5,61.5c-0.7,0-1.3,0.3-1.9,0.8
					c-0.9,0.2-1.5,1-1.6,1.8l-1,2.9c0,0.1-0.1,0.2-0.1,0.3c0,6.3,2.2,8.8,8.7,10l5.9,2.9c0.5,0.3,1,0.5,1.4,0.8
					c1.2,0.7,2.3,1.3,4.1,1.3h1c4.8,0,8.1-2.7,10.7-5.3c0,0,0.1-0.1,0.1-0.2l1.9-2.9c1.3-1.4,1.3-3-0.1-4.3l-0.8-0.8
					c-0.1-0.6-0.5-1-1-1.3v-1.3c0-0.3-0.1-0.5-0.3-0.7c-1.3-1.3-2.6-1.3-3.7-1.3c-1.9,0-5.7,0-8.1,1.7c-0.1-0.8-0.4-1.6-1.2-2.4
					c-0.9-0.9-1.9-1.4-3.1-1.4c-1.7,0-3.4,0.9-6,2.2c-0.1,0-0.2,0.1-0.3,0.2l-1.3,1.3c0,0,0,0,0,0c0-0.1,0-0.3,0-0.6
					c0-0.2,0-0.3-0.1-0.4l-1-2c0-0.1-0.1-0.2-0.2-0.3C395,61.8,394.3,61.5,393.5,61.5L393.5,61.5z M193,75.2c-0.3,0-0.5,0.1-0.7,0.3
					l-0.8,0.8c-0.6,0.1-1,0.5-1.2,0.7c-0.2,0.2-0.2,0.2-0.3,0.2h-1c-1.9,0-4.2,0-5.6,2h-1c-0.5-0.4-1.4-1-2.4-1c-1.4,0-4,1.3-4,4
					c0,2.2,1.1,4.7,2.7,5c1.7,0.9,2.6,1.8,3.6,2.7c0.9,0.9,1.6,1.4,2.1,1.8c0.2,0.2,0.5,0.4,0.6,0.5l0,1c0,0.6,0,1.3,0.2,2H184
					c-0.6,0-1.8-0.7-2-1c0-0.3-0.1-0.5-0.3-0.7c-1-1-2.3-2.3-4.7-2.3c-1.2,0-1.5,0.1-2.5,1.1c-1.3,0.4-2.3,2.2-2.5,3.4
					c-0.3,0.2-0.6,0.4-1,0.6c-0.5,0.3-1.1,0.5-1.6,1.1c-2.2,1.2-3.3,3.3-4.3,5.4c-0.1,0.1-0.1,0.3-0.1,0.4c0,1.9,0,3.2,1,4.4v3.4
					c-1,2.2-2,6.2-2,8.2c0,0.6,0,1.2-0.1,1.7c-0.2,2-0.3,4,2.2,5.9c0.1,0.6,0.5,0.9,0.7,1.1c0.2,0.2,0.2,0.2,0.2,0.3
					c0,2.2,1,3.4,2,4.4v0.6c0,0,0,0,0,0c0,0,0,0,0,0c-0.3,0-0.5,0.1-0.7,0.3c-2.4,2.4-3.3,12-3.3,12.7c0,0.9,0.5,1.4,0.8,1.7
					c0.2,0.2,0.2,0.2,0.2,0.3v1.2c-0.6,0.4-0.9,1.1-1,1.8h-1c-0.2,0-0.3,0-0.4,0.1c-1.8,0.9-3.6,0.9-5.6,0.9c-0.1,0-0.1,0-0.2,0
					c-5.6,0.9-9.4,1.9-11.7,6.5c-0.1,0.1-0.1,0.3-0.1,0.4c-0.5-0.1-1.1-0.2-1.6-0.2c-2.9,0-5.4,1.9-7.1,3.6
					c-0.2,0.2-0.3,0.4-0.3,0.7v3v2.6l-2,2c-0.9-0.8-1.9-1.7-3.5-2.5c-0.1-0.1-0.3-0.1-0.4-0.1c-3.1,0-7,0-9.2,1h-4.6
					c-0.4-0.1-0.8-0.3-1.1-0.4c-1.6-0.6-2.9-1-4.3-1c-0.8,0-1.5,0.2-2.2,0.5c-1.7,0.9-2.3,2.7-2.7,4.4c-0.2,0.8-0.4,1.5-0.7,2
					c-0.1,0.1-0.1,0.3-0.1,0.4c0,3,0,5.2,1.1,8.3c0,0.1,0.1,0.3,0.2,0.4c0.6,0.6,0.6,0.6-0.3,5.1c0,0.1,0,0.1,0,0.2
					c0,1.8-1,4.8-1.9,7.6c-1,2-2.1,5.2-2.1,7.4c0,0.9,0,1.4-1.4,2.1c-0.1,0-0.2,0.1-0.3,0.2c-0.8,0.8-3,3.8-3.3,5.4
					c-0.2,0.4-0.3,0.7-0.5,1.1c-0.5,1.1-0.7,1.7-1.8,2.2c-0.6,0.1-1.2,0.3-1.7,0.6c-0.4,0.2-0.8,0.4-1.1,0.4c-1.4,0-2.4,0.4-3.1,0.7
					c-0.4,0.2-0.7,0.3-0.9,0.3c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.5,0.4-0.8,0.7c-1,0.8-2.3,1.9-2.5,3.7c-1,1.3-1,3.5,0,4.8
					c0.1,0.3,0.2,0.5,0.2,0.7c0.2,0.4,0.4,0.8-0.2,2.5c0,0.1-0.1,0.2-0.1,0.3v3c-0.2,0-0.4,0.1-0.6,0.2c-3.2,2.1-3.4,3.5-4.3,8.7
					l-0.2,1c-1,4.1-1,9.1-1,11.2c0,0.1,0,0.2,0.1,0.3l0.7,2.2l-1.2,0.6c-0.1,0-0.2,0.1-0.3,0.2c-0.3,0.3-1,0.5-1.6,0.8
					c-0.7,0.2-1.4,0.5-2.1,1c-3.4,0.1-4.6,1.7-5.6,4.7c0,0.1-0.1,0.2-0.1,0.3v0.2c-0.9,0.3-1.7,1.1-2.5,1.9
					c-1.5,0.4-2.5,2.7-2.5,3.9c0,1.4,1.2,3.6,2.3,4.7c0.2,0.2,0.3,0.4,0.5,0.6c0.9,1.1,2.3,2.7,5.2,2.7c0.5,0,1,0.7,1,2
					c0,0.2,0,0.3,0.1,0.4c1.1,2.3,5.3,5.3,7.3,6.4c1,1,2.2,2.1,4.4,2.2c0.8,0.3,1.7,0.6,1.7,0.7c0.1,0.3-0.2,1.6-0.5,3
					c0,0.1,0,0.2,0,0.2v0.8c-0.5-0.1-1.1-0.3-1.7-0.3c-0.8,0-1.5,0.2-2,0.7c-0.1,0.1-0.8,0.9-1.2,1.9c-1.2,0.4-2.1,1.7-2.1,3.8
					c0,1.6,2.1,2.7,4.6,3.9c0,0,0.1,0,0.1,0.1c2,0.7,4.4,2.6,2.3,11.8c-0.9,4.7-0.9,4.7-1.7,5.5c-1.1,1.1-3.3,3.3-3.3,5.7
					c0,2.7,1.7,3.9,4.4,4.8c0.1,0.2,0.2,0.7-0.2,1.6l-2.9,2.9c-2,2-3.2,3.2-4.2,6.4c0,0.1-0.1,0.2-0.1,0.3c0,2,0,4,1,5.4v1.3
					c-0.7,0.9-1.2,2.4-0.9,3.7c0.2,0.7,0.7,1.7,1.9,2.3v0.3c0,2.3,1.1,3.5,2.8,4.5l-0.6,1.7c-1.2,0.5-2.6,1.8-3.1,3.1
					c-4.4,1.6-9.2,8.7-11,12.2c-1,1.1-2,3-3,5c-1.3,1.3-7.2,7.9-7.2,10.6c0,1,0,2.1,0.9,3.3c-0.6,2.8,1.4,5.8,5.5,8.6
					c0,0,0.1,0,0.1,0.1c1.5,0.8,3,1.1,4.4,1.1c2.4,0,4.5-1.1,6.8-3.3c0,0,0.1-0.1,0.1-0.1c0.2,0.2,0.5,0.3,0.8,0.3
					c2.5,2.2,7.6,3,9.4,3c2.3,0,3.6-1.2,3.7-1.3c0.7-0.7,1.3-0.7,1.3-0.7c0.1,0,0.2,0.3,0.3,0.4c0.2,0.3,0.4,0.6,0.7,1v0
					c-1.3,0.9-1.8,2.2-2.2,3.5c-0.2,0.5-0.4,1.1-0.7,1.6c-0.1,0.1-0.1,0.3-0.1,0.4c0,2.1,0,3.4,1.3,4.7c0.1,0.1,0.2,0.1,0.3,0.2
					c0.5,0.3,1,0.4,1.5,0.4c1.5,0,2.4-1.1,3.1-2c0.2-0.2,0.4-0.4,0.5-0.6c0.1-0.1,0.2-0.3,0.3-0.5c0.6-0.5,3.2-1.2,4-1.2
					c3.4,0,5.6-2.2,6.7-3.3c0,0,0.1-0.1,0.1-0.2c1.9-2.8,5.4-5.4,6.2-5.6c3.5,0,9.7-5.2,10.9-7.6c0,0,0-0.1,0.1-0.1
					c0.1-0.1,0.7-0.3,2.1-0.3c5,0,6-2.7,6-5c3.9,0,4.6,0,7.3,2.7l2.7,2.7c0.2,1.1,1.1,2.1,2.1,2.4c0.2,0.4,0.4,0.7,0.8,0.9v3.2
					c0,0.7,0.3,1.5,0.6,2.3c0.2,0.6,0.4,1.3,0.4,1.7v0.8c-0.2,0.7-0.4,1.2-0.6,1.7c-0.7,1.7-1.2,3-0.2,4.9c1.2,2.4,4.3,3.4,7.1,3.5
					c0.3,0.5,0.8,0.8,1.1,1.1c0.3,0.2,0.6,0.4,0.9,0.7c0.2,0.2,0.4,0.3,0.7,0.3c0.9,0,2.3-0.5,3.2-1.4c0.7,0.6,1.5,1.3,2.7,2
					c-0.1,0.6-0.3,1.9-0.9,4.2c-0.8,3.2-0.4,4.7-0.2,5.7c0.1,0.3,0.1,0.5,0.1,0.6v1.8c-0.9,2.9-1.1,5.3,1,7.6c0,3.7-0.4,7.7-1,11.4
					c-0.1,0.4-0.3,0.8-0.4,1.2c-0.8,2.3-1.5,4.3-0.6,6.3c0,1.7,0.1,4.1,2,4.6c0,1.9,0.1,4.5,1,6.4c-0.5,0.3-1,0.9-1,1.8
					c0,2,0.9,3.3,2,3.8v0.2c0,0.9,0,2.7,1.6,3c0.7,0.5,1.6,0.8,2.8,0.8c1.2,0,2.7-0.2,4.9-0.8c0.2,0,0.3-0.1,0.5-0.3
					c0.3-0.3,0.5-0.5,1.3-0.5c0.9,0,2.5,0.3,4.7,0.7c3,1,4.8,1.6,5.3,1.9c0,0,0,0.1,0,0.1c0,3.3,2.8,4.6,4.8,5.5
					c0.3,0.1,0.5,0.2,0.7,0.4c0.1,0.1,0.3,0.1,0.4,0.1c0.2,0,0.5,0,0.6,0c0.1,0,0.4,0.4,0.7,0.7c0.6,0.6,1.2,0.9,1.8,1.2
					c0.5,0.2,0.9,0.4,1.2,0.8c0.2,0.2,0.4,0.3,0.7,0.3c0,0,0,0,0,0c0,0.2,0,0.3,0.1,0.4c1.2,2.4,2.5,3.6,3.9,3.6
					c0.7,0,1.4-0.3,1.9-0.6c0.4-0.2,0.8-0.4,1.1-0.4c0,0.4,0.2,0.7,0.6,0.9c2,1,4.1,2,7.1,3.1c0.1,0,0.2,0.1,0.3,0.1
					c1.2,0,2,0,2.3,0.1c0.2,0.1,0.4,0.6,0.8,1.3c0,0.1,0.1,0.2,0.2,0.3c0.7,0.7,0.7,1.3,0.7,1.3c0,2.3,1.2,3.6,3,4.6v0.4
					c0,3.4,2.9,3.9,6.7,4.6c0.7,0.1,1.4,0.2,2.1,0.4c0.1,0,0.1,0,0.2,0c0.8,0,2.6,0,3.3,0.7c1,1,2.3,2.3,4.7,2.3
					c1.2,0,1.9,0.4,2.2,0.6c1.1,2.1,2.2,3.2,4.4,4.3c0.1,0.1,0.3,0.1,0.4,0.1h1.6c0.3,0.2,0.7,0.4,1.1,0.4c0.4,0,0.8-0.2,1-0.3
					c0.2-0.1,0.2-0.1,0.3-0.1c0.5,0,1.1-0.2,1.4-0.6c1,0.5,1.9,0.7,2.8,0.7c1.2,0,2.1-0.5,2.5-1.2h0.6c0.3,0.5,0.8,0.8,1.1,1
					c0.3,0.2,0.6,0.4,0.9,0.7c0.2,0.2,0.4,0.3,0.7,0.3h3c0.9,0,1.4-0.5,1.8-1c0.1,0,0.1,0,0.2,0c0.6,0,1.3,0.1,1.9,0.2
					c0.6,0.1,1.2,0.2,1.8,0.2c0.7,0,1.2-0.1,1.6-0.4h1.1c1.1,1.8,3,2.9,4.3,3c1.4,0.7,2.9,1.4,4.5,1.4c0.8,0,1.5-0.2,2.2-0.5
					c0.1,0,0.2-0.1,0.3-0.2c0.7-0.7,1.2-1.5,1.5-2.4c0.1-0.4,0.2-0.9,0.2-1.3c0.1,0,0.1,0,0.2,0c0.4,0.1,0.9,0.2,1.5,0.2
					c1.2,0,2.3-0.4,3.3-1.3h1.4c2.2,1,5.2,1,7.2,1h5c1.1,0,2.4,0,3.7-1.3c1.3-1.3,1.3-2.6,1.3-3.7c0-0.2-0.1-0.5-0.2-0.6
					c-0.3-0.9,0.8-4.9,1.4-7.3c0.3-1,0.5-1.9,0.7-2.7c0.5-0.9,0.9-1.2,1-1.3c0.7,0.2,1.4,0.3,1.9,0.3c2.4,0,3.3-2,4-3.9
					c2.5-3.8,0.6-7.5-1.1-10c-0.2-0.3-0.5-0.4-0.8-0.4c0,0,0,0,0,0c0-0.9-0.5-2.3-1.2-3.3c0.9-0.9,3.2-4.1,3.2-5.7
					c0-0.6,0.9-1.4,1.6-2.2c2.4-1.3,2.4-3.8,2.4-4.8c0-2-0.9-2.6-1.6-3.2c-0.2-0.2-0.5-0.3-0.7-0.5c-0.4-0.4-0.8-0.8-1.2-1.3
					c0.2,0,0.4,0,0.5,0c1.6,0,2.8-2.3,3-3.7c0.5-1,0.7-1.7,1-2.4c0.2-0.7,0.5-1.4,0.9-2.4c0.1-0.1,0.1-0.3,0.1-0.4v-1c0,0,0,0,0,0
					c0.2,0,0.3,0,0.4-0.1c2.5-1.3,3.6-5.8,3.6-7.9v-1.1c1.4-0.4,2-1.8,2-2.8c0.2-0.7,2.2-3.7,3.4-5c2.4,0,8.1,0.3,17.4,2l4,1
					c0.1,0,0.2,0,0.2,0c0.9,0,2.9,0,3-2.8l0.9-1.8c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2,0.1-0.5,0.3-0.9c0.3-0.6,0.6-1.5,0.7-2.6
					c0.2-0.2,0.3-0.2,0.5-0.2c0.7,0,1.9,0.3,4.3,0.7c2,0.4,3.7,0.7,5.1,0.7c2,0,3.5-0.5,5.4-1.7c0.6-0.1,1.1-0.3,1.6-0.6
					c0.4-0.2,0.8-0.4,1.1-0.4c0.1,0,0.2,0,0.2,0c3.2-0.8,4.8-2.8,4.8-6c0-0.4,0.4-0.8,1.2-0.9c0.4,1.3,1.5,2.9,2.8,2.9
					c0.9,0,2.7,0,3-1.6c1.6-1.8,1.6-4.7,0-8.6v-9.7c0.1-0.7,0.3-1.3,0.4-1.9c0.7-3.2,1.2-5.6-1.4-6.9v-2.2c0.5-1.9,1-3.4,1.3-4.4
					c0.2-0.7,0.4-1.3,0.5-1.7c1.9-0.2,3.7-1.2,4.1-2.5l2.8-2.8c2.5-2.5,5.1-6.4,5.3-10.3c0.3-0.4,0.8-1.1,0.9-1.8
					c2.4-1.9,3.2-5.1,2.3-9.6c2.2-0.2,3.1-1.1,3.5-1.9c2.3,0.3,4.2,1.8,4.2,3c0,0.2,0,0.3,0.1,0.4c1.1,2.1,3.2,4.3,5.5,4.5
					c0.5,0.4,0.9,0.4,1.3,0.4c1,0,1.7-0.6,2.5-1.3c0.2,0.1,0.4,0.2,0.7,0.4c0.3,1.3,1.6,2.5,4,2.5c0.1,0,1.2,0,2.3-0.9
					c0.4,0.1,0.8,0.1,1.2,0.1c2.4,0,3.3-1.7,3.8-2.7c0.2-0.3,0.3-0.6,0.5-0.8c0.2-0.2,0.3-0.4,0.3-0.7c0,0.6,0.4,1,1,1h0.6
					c1.2,1,2.4,1,3.4,1c0,0,0,0,0,0c0,0.1,0,0.2,0.1,0.3c1.1,3.4,2.5,3.7,3.9,3.7c0.3,0,0.5-0.1,0.7-0.3c0.8-0.8,1.7-1.7,2.3-1.7
					c0.3,0,0.5-0.1,0.7-0.3c1.3-1.3,1.3-2.6,1.3-2.7c0-0.7,1.8-4.3,3.7-6.3c0,0,0.1-0.1,0.1-0.2c2.1-3.2,2.2-4.6,1.2-7.7
					c0-0.2,0-0.3,0-0.4c0-0.1,0.4-0.4,0.7-0.7c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3,0.2-0.7,0.4-1.1c0.3-0.6,0.6-1.2,0.6-1.9
					c0-1.4-1.2-2.6-1.3-2.7c-0.3-0.3-0.6-0.6-0.7-0.7c0-0.1,0-0.3,0-0.6c0-0.2,0-0.3-0.1-0.4c-0.5-0.9-0.7-1.8-0.9-2.8
					c-0.3-1-0.5-2.1-1.1-3.2c0-0.1-0.1-0.2-0.2-0.3c-0.7-0.7-0.7-2.3-0.7-3.1c1-3.1,1-6.1,1-8.2c0-3.6,0-3.6,1.7-5.3
					c2.3-2.3,3.3-4.6,3.3-7.6c1.4,0.2,2.5,0.8,2.6,0.8c0.1,0.1,0.3,0.1,0.4,0.1c3,0,4.3-0.9,5.3-1.9c0.9,1,2,1.9,2.8,2.6
					c0.5,0.4,0.9,0.7,1.2,1c0.2,0.2,0.4,0.3,0.7,0.3c3.9,0,4-3.8,4-5.8c0.9-2.8,1.1-5.1-0.8-7.3c0.6,0.2,1.3,0.2,1.8,0.2
					c0.6,0,8.5-1.5,9.8-5h0.3c0.7,3.2,4.6,6,6.9,6c0.9,0,2.7,0,3-1.6c0.9-1.1,1-2.2,1-3.2c0.3-0.4,0.9-1.3,2-1.3
					c0.3,0,0.5-0.1,0.7-0.3c0.3-0.3,0.7-0.6,1.1-0.9c0.8-0.6,1.6-1.2,2-1.9c0.7-0.2,1.3-0.6,1.6-0.9c0.5,0,1.1-0.1,1.7-0.3
					c1.2,1.2,2.3,1.5,3.1,1.5c0.7,0,1.2-0.2,1.3-0.3c3.2-1.6,2.1-5.3,1.6-7.1v-0.3c2-1.4,2-3.7,2-5.6c0-0.3-0.1-0.5-0.3-0.7
					c-0.9-0.9-1.7-1.7-2.7-2.1v-0.2c0-0.3-0.1-0.5-0.3-0.7c-0.6-0.6-0.7-1.9-0.7-2.3V274c1-0.4,1.9-1.5,2.8-3.2c3-3,3.2-3.2,3.2-4.6
					c0-1-0.6-2.4-2-2.9v-3.1c1,0,3.1,0,3.8-1.1c1.2-0.4,2.2-1.6,2.2-2.9c0-2,0-3.2-1.1-5.4c-0.1-0.2-0.2-0.3-0.4-0.4
					c0.1,0,0.2,0,0.4,0c2.1,0,3.2-1.1,3.8-2.2c2.9-0.1,10.5-0.9,13.5-3.6c0.5,0.3,1,0.4,1.5,0.6c0.7,0.2,1.3,0.4,1.6,0.8
					c0.1,0.1,0.8,0.7,2,0.7c0.7,0,1.4-0.2,2.1-0.5c0.1,0,0.2-0.1,0.3-0.2c0.6-0.6,0.9-1.2,1.2-1.8c0.2-0.5,0.4-0.9,0.8-1.2
					c0.2-0.2,0.2-0.3,0.3-0.6c0.1-0.1,0.5-0.9,2.6-2.3l3-2c0.1,0,0.1-0.1,0.2-0.1c1.3-1.3,1.3-2.6,1.3-3.7c0-0.2-0.1-0.4-0.2-0.6
					l-1.9-2.8c-0.1-0.6-0.3-1.1-0.6-1.6c-0.2-0.3-0.3-0.6-0.4-0.9c1-2.4-0.1-5.6-2.1-10.5c-1.1-4.4-2.6-4.7-7-5.5l-0.7-0.1
					c-0.1,0-0.1,0-0.2,0c-0.7,0-2.2,0-3.1-0.5c-0.2-1.2-1.3-2.2-2.2-3.2c-0.2-0.2-0.5-0.5-0.5-0.8c0-0.2,0.1-0.4,0.2-0.5
					c1.3-0.3,2.5-1.6,2.5-4c0-2.2-1-3.4-2-4.4c0-0.7,0.1-1.3,0.7-1.9c0.2-0.2,0.3-0.4,0.3-0.7v-1.3c2.5-1.2,5.9-4.2,8.4-6.7
					c3.4-0.3,7.4-3.4,8.5-5.5c0.1-0.1,0.1-0.3,0.1-0.4c0-0.4,0.6-1,1-1c2,0,3.2,0,5.4-1.1c3.6-1.2,3.6-2.9,3.6-5.9
					c0-2,0-5.8-2.6-7.5c0.2-0.9,0-1.9-0.2-2.6c-0.1-0.4-0.2-0.7-0.2-0.9c0-0.3-0.1-0.5-0.3-0.7c-1-1-2-2-3.3-2.3l-0.3-0.3
					c0.7-2.1-0.3-4.4-2.6-5.6c-0.2-0.2-0.5-0.4-0.8-0.7c-1.1-0.9-1.8-1.5-1.8-2.5c0-0.2,0-0.3-0.1-0.4l-1-2
					c-0.2-0.3-0.5-0.6-0.9-0.6h-0.3c-0.5-0.7-1.7-1-2.7-1c-0.2,0-0.4,0.1-0.6,0.2l-3,2c-0.1,0-0.1,0.1-0.2,0.1
					c-0.8,0.8-1.7,1.7-2.3,1.7c-1.7,0-2.9,0.7-4.1,1.3c-0.4,0.2-0.8,0.5-1.3,0.7c-1.5,0.5-2.3,0.7-3.1,0.7c-0.8,0-1.6-0.2-3.2-0.7
					c-0.1,0-0.2-0.1-0.3-0.1c0-0.3,0-0.6,0-1v-3c0-1.4-0.4-2.4-0.7-3.1c-0.2-0.4-0.3-0.7-0.3-0.9c0-0.3-0.1-0.5-0.3-0.7
					c-0.4-0.4-0.6-0.8-0.8-1.2c-0.2-0.5-0.5-1-1-1.6c-1.2-4.2-5.3-5.4-11.9-7.2l-0.7-0.2c0,0-0.1,0-0.1,0c-2.4-0.4-4.1-0.7-5.8-0.7
					c-2.2,0-4.1,0.5-7.7,1.7c-4.8,1.2-7.4,2.2-8.4,3.3c-0.2,0.2-0.3,0.4-0.3,0.7c-0.2,0.1-0.7,0.1-1,0.1c-3.2,0-5.5,1.1-8.7,4.3
					c-5.9,4.9-5.9,4.9-9.8,6.8c-0.1,0-0.2,0.1-0.3,0.2c-0.7,0.7-2.1,1.5-2.9,1.7c-0.9-0.9-2.1-1.8-4-1.9c-0.3-0.2-0.5-0.4-0.8-0.7
					c-1.1-0.8-2.4-1.9-3.7-3.9c0-0.1-0.1-0.1-0.1-0.2l-1.8-1.8c-0.2-1-1-1.5-1.9-1.5l-12.3-1.8c0.7-1.1,1.2-2.3,1.2-3.3
					c0-3.9-3-5-9.8-6c-3.2-1.1-5.5-1.5-7.5-1.5c-2,0-3.9,0.5-6,1.5c-1.9,0-4.7,0-7.5-0.9c-0.1,0-0.1,0-0.2,0l-8-1c0,0-0.1,0-0.1,0
					h-1h-3c-1,0-4.1,0-7.2,1c-4,0-11.7-1.1-15.4-2.9c-0.7-0.3-1.4-0.5-2.2-0.5c-1.4,0-2.9,0.6-4,1.7c-0.2,0.2-0.3,0.4-0.3,0.7
					c0,0.1,0,0.4-0.1,0.6c-0.2,1-0.5,2.7,0.8,4.4c-0.2,0-0.4,0-0.5,0c-0.3-0.1-0.6-0.1-0.9-0.1c-3.6,0-6.3,5.4-7.2,8.8
					c-1.1,3.2-0.1,5.8,2.5,7.2c0.1,0.1,0.3,0.1,0.4,0.1c0,1.3,1,2.4,2,3.4v1.4c-0.3,0.7-0.4,1.4-0.4,2.1c-0.3-0.3-0.6-0.5-1-0.7
					c-0.7-0.7-1.6-1-2.5-1c-1.2,0-2.4,0.7-3.4,1.3c0.2-0.4,0.3-0.8,0.3-1.3c0.9-2.9-0.4-5.5-3.5-7.1c-0.1-0.1-0.3-0.1-0.4-0.1
					c-2.4,0-4.9,1.3-5,4.8c-0.5,1.1-0.5,2.2-0.1,3.2c0.6,1.3,1.9,2.4,3.8,3c0.1,0,0.2,0.1,0.3,0.1c0.5,0,1-0.1,1.4-0.2
					c-0.8,1.1-1.3,2.4-1.4,3.8c-2.9-2.4-7.2-3.9-9.8-4.6c-1.2-0.3-2.1-0.4-2.9-0.4c-2.1,0-3.4,0.9-5.2,2.1c-0.2,0.1-0.4,0.3-0.7,0.5
					c-0.1,0-0.1,0.1-0.2,0.1l-2.7,2.7c-0.9,0-1.9,0.1-3,1h-1.4c-1.6-0.8-1.7-0.9-3.4-2.6c-0.5-1-1-1.7-1.5-2.5
					c-0.5-0.7-0.9-1.3-1.3-2.2c-0.1-2.4-1.5-4.8-4-4.8c-1.8,0-3.9,1.7-7,13.7c-0.9,2.8-1,4.8-1,6.7c-0.8,0.6-1.5,1.4-1.9,2.2
					c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.1-0.2-0.1-0.3-0.2c-1.2-0.6-2.1-0.9-2.8-0.9c-0.7,0-1.4,0.2-2,0.7c-0.2,0-0.5,0-0.6-0.1
					c0-0.1,0-0.3-0.1-0.4l-2-4c-0.1-0.2-0.3-0.4-0.4-0.4c-1.4-0.7-1.4-1.2-1.4-3.1c0-0.2,0-0.3-0.1-0.4c-0.2-0.5-0.4-1.2-0.6-1.9
					c-0.2-0.9-0.5-1.9-0.9-2.8c1.6-0.4,2.6-1.3,3.4-2.2c0.2-0.2,0.3-0.4,0.3-0.7c0-1.4-1.2-3.6-2.3-4.7c-0.2-0.2-0.4-0.3-0.7-0.3
					h-0.1c0,0,0,0,0-0.1c0.2-0.5,0.4-0.9,0.8-1.2c0.1-0.1,0.2-0.2,0.2-0.4l0.4,0.4c0.2,0.2,0.4,0.3,0.7,0.3h1c0.9,0,2.7,0,3-1.6
					c1.2-1.4,0.9-3-0.1-4.9c-0.2-0.3-0.5-0.6-0.9-0.6c-0.1,0-0.1,0-0.3-0.1c-0.2-0.1-0.5-0.3-1-0.3c-0.5,0-0.8,0.2-1.1,0.4
					c-0.4,0-0.9,0-1.3,0.2c-1.1-2-2.7-3-4.3-3.8c1-1.2,2-3.2,2-5.3c0.2-0.8,0.4-1.4,0.6-2c0.7-2.4,1.1-3.8-0.9-5.8
					c-0.7-0.7-0.7-1.4-0.7-2.3c0-2-1.5-3.2-2.5-4.1c-0.3-0.2-0.6-0.5-0.8-0.7c-0.2-0.2-0.4-0.3-0.7-0.3c-0.1,0-0.1,0-0.3-0.2
					c-0.2-0.2-0.5-0.5-1.1-0.7c-1.5-2.7-3.9-4.1-7-4.1c-1.5,0-3.2,0.4-5,1.1c-0.7,0.2-1.4,0.4-2,0.4c-1.4,0-2.3-0.6-2.8-1
					c-1-2.6-3.2-4.4-5-5.6c0.1-0.1,0.1-0.3,0.1-0.5c0.8-1.1-0.1-2.3-0.5-2.8c-0.1-0.2-0.4-0.5-0.4-0.6c0-0.3-0.1-0.5-0.3-0.7
					c-1.1-1.1-3.4-2.3-5.7-2.3c-2.1,0-3.4,0-4.7,1.3c-0.5,0.5-0.8,1.1-0.9,1.7c-0.1,0-0.3,0-0.4,0c-2.5,0-3.8,1.3-4,2.8
					c-1,2.2,0.7,3.6,1.7,4.4c0.1,0.1,0.2,0.2,0.3,0.3c-0.9,1.2-0.9,2.9-0.9,4.5c0,1,0,3.1,1,5.2v0.7c-1.1-1.1-2.5-1.9-4-1.9
					c-1.3,0-3.2,1-4.4,2H240c-0.7,0-2.4,0-3.2-0.6l-0.9-1.8c0,0,0-0.1-0.1-0.1c-2.1-3.2-4.4-4.4-7.8-4.4c-1,0-2.1,0-3.3,0.9
					c-0.4-0.1-0.8-0.4-1-0.6l-0.4-0.4c0.9-0.2,1.8-0.8,2.6-2.4c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2,0.1-0.6,0.2-0.9
					c0.3-1.1,0.6-2.7-0.5-3.8c-1.1-1.1-2.2-2.2-7.4-3.2c-1.9-1-4-2-6.9-3c-3.4-2-6.7-3.1-9.6-3.1c-1.6,0-3,0.3-4.1,1h-1.6
					c-0.4-0.1-1.6-0.4-2.7-0.4c-0.4,0-0.7,0-1,0.1c0.5-2.1,0.5-4.5,0.5-4.7c0-1-0.6-1.9-1-2.4v-1.6c0,0,0,0,0,0
					c0.2,0,0.4-0.1,0.5-0.2c3-2,4.4-3.3,4.4-6.6c0.9-2,0.3-4.1-0.9-5.5c0,0,0,0,0.1-0.1c0.3-0.3,0.8-0.8,0.8-1.7
					c0-0.1,0-1.5-1.3-2.7C195.6,76.4,194.4,75.2,193,75.2L193,75.2z M210.9,87.2h-2c-0.1,0-2.7,0-3.9,3.7c0,0.1-0.1,0.2-0.1,0.3
					c0,2.5,1.4,5,4,5c2.4,0,3.9-1.3,4-3.6c1.1-1.5,0-3.8-1.3-5.1C211.4,87.3,211.2,87.2,210.9,87.2L210.9,87.2z M364,97.2h-1
					c-2.6,0-3.8,1.4-4.9,3.6c-0.1,0.1-0.1,0.3-0.1,0.4c0,1,0,2.2,1,4.2c0.1,2,1.1,3.3,3,3.7v0.1c0,0.6,0.4,1,1,1
					c0.3,0,0.5,0,0.7-0.1c-0.2,0.5-0.5,1.1-0.7,1.8c0,0.1-0.1,0.2-0.1,0.3c0,1.3-0.5,2-1,2c-0.3,0-0.5,0.1-0.7,0.3
					c-0.2,0.2-0.5,0.4-0.8,0.7c-1,0.8-2.3,1.9-2.5,3.7c-1.2,1.6,0.4,3.2,1.3,4.1c0.2,0.2,0.4,0.3,0.7,0.3h1c1.2,0,3-0.9,4.2-1.8
					c2.1,0.8,4.8,0.8,6.7,0.8c4.9,1,8,1.4,10.2,1.4c2.1,0,4.9-0.3,5.8-3.1c0-0.1,0.1-0.2,0.1-0.3c0-0.7-0.3-1.4-0.6-1.9
					c-0.2-0.4-0.4-0.8-0.4-1.1c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.7-0.7-1.2c-0.5-1.1-1.3-2.7-3.2-4.7c-3.1-5.1-3.3-5.3-7.6-6.4
					c0,0-0.1,0-0.1,0c-1-0.2-2-0.3-2.9-0.3c-1.3,0-2.4,0.2-3.4,0.6c0.1-0.8,0.1-1.6,0.1-2.3c0-0.2,0-0.3-0.1-0.4
					C367.8,99.6,366.6,97.2,364,97.2L364,97.2z M329,99.2h-2c-0.3,0-0.5,0.1-0.7,0.3c-1.3,1.3-1.3,2.7-1.3,4.7c0,0.1,0,0.2,0.1,0.3
					c0,0.1,1.1,3.3,3.3,4.5c0.2,0.3,0.4,0.5,0.6,0.8c0.3,0.5,0.7,1.1,1.4,1.3c1.1,2.1,2.3,3.1,3.7,3.1c1.1,0,2.4,0,3.7-1.3
					c0,0,0.1-0.1,0.1-0.2c2-3,0.6-5.3-0.4-7c-0.2-0.4-0.4-0.7-0.6-1c0-0.1-0.1-0.2-0.2-0.3C333.9,101.7,331.2,99.2,329,99.2
					L329,99.2z M315.5,121c-1.1,0-2,0.4-2.9,1.2H312c-0.3,0-0.5,0.1-0.7,0.3c-1.3,1.3-1.3,3.6-1.3,4.7c0,0.2,0,0.3,0.1,0.4
					c1.2,2.4,2.5,3.6,3.9,3.6h1c2.4,0,3.7-1.3,4.7-2.3c0.2-0.2,0.3-0.4,0.3-0.7v-2v-3c0-0.4-0.2-0.7-0.6-0.9
					C318.2,121.7,316.9,121,315.5,121L315.5,121z M522,123.8c-1.5,0-3,1.3-3,3.4c0,0.4,0.1,0.8,0.3,1.3c-0.4-0.2-0.8-0.3-1.3-0.3
					c-1,0-2.3,0-3.6,1.2c-2.2,1.2-2.7,3.6-1.3,6.3c0,0.1,0.1,0.2,0.2,0.3c1.1,1.1,3.4,2.3,5.7,2.3h1c1.4,0,2.5-0.5,3.2-1.1
					c0.4,0.6,1,1.3,2.4,2c0.1,0.1,0.3,0.1,0.4,0.1c2.5,0,3.8-2.3,4-3.7c1-2.2,1-3.5-0.1-5.7c0-0.1-0.1-0.2-0.2-0.3
					c-1-1-2.1-2.1-3.3-2.3c0.1-0.8-0.1-1.3-0.3-1.7c-0.3-0.6-1.1-1.2-2.7-1.3C523,123.9,522.5,123.8,522,123.8L522,123.8z"/>
			</g>
			<g id="st0">
				<path d="M363,55.2c2,1,5,3,7,5c0,1,3,1,4,1c0.6-0.3,1.2-0.4,1.8-0.4c1.5,0,3.4,0.7,6.2,1.4c4,2,6,3,6,5c0,1,0,2-1,2
					c-1,2-1,3-1,4c1,2,0,4-1,5s-2,2-3,2c0,0-1,1-2,1v1c0,1,0,2-3,3c0,0-1,0-1,1c-1,1-4,1-6,1c-0.9-0.2-1.8-0.3-2.7-0.3
					c-4.3,0-8.6,1.8-10.3,4.3c-2,2-4,3-6,3c-1,0-2-1-3-2c-2,0-3-1-7-6c-3-4-4-5-4-9c1,0,1-1,0-2s-1-3-1-4s1-2,2-3v-1c0-4,2-7,6-9
					c1.3-0.5,2.4-0.8,3.3-0.8c2.8,0,4.4,2.1,6.7,5.8c1,1,2,2,4,3c0,1,1,1,1,1c0-2-1-5-1-5c-1-2-1-4,0-5C359,55.2,361,55.2,363,55.2
					 M370.2,72.6c0.1,0.6,0.4,1.2,0.8,1.6C370.7,73.7,370.5,73.1,370.2,72.6c-0.2-1-0.2-2.2-0.2-3.4c0-1,0-1,0-2c0,0,0,0,0,1
					c-1,0-1,1-1,1C369,69.9,369.5,71.2,370.2,72.6 M393.5,62.5c0.5,0,1,0.3,1.5,0.8l1,2c0,1,0,1,1,2c1-1,1-1,2-2
					c2.5-1.2,4.2-2.1,5.6-2.1c0.9,0,1.6,0.3,2.4,1.1c1,1,1,2,1,3h1c2-2,6-2,8-2c1,0,2,0,3,1c0,1,0,1,0,2c0,0,1,0,1,1l1,1
					c1,1,1,2,0,3l-2,3c-3,3-6,5-10,5c-1,0-1,0-1,0c-2,0-3-1-5-2s-4-2-6-3c-6-1-8-3-8-9l1-3c0,0,0-1,1-1
					C392.5,62.7,393,62.5,393.5,62.5 M331,66.2c1,0,2,2,3,3v1c2,1,1,4,1,5c-1,2-1,5-4,5h-1c-2,0-3-1-3-3c-1-2,0-10,3-11
					C330,66.2,330,66.2,331,66.2 M193,76.2c1,0,2,1,2,1c1,1,1,2,1,2c0,1-1,1-1,2c1,1,2,3,1,5c0,3-1,4-4,6c0,0-1,0-1,1c0,0,0,1,0,2
					c0,0,1,1,1,2c0,0,0,4-1,6c0,0,0,0,1,0c0.3-0.3,0.9-0.4,1.5-0.4c1.2,0,2.5,0.4,2.5,0.4c1,0,1,0,2,0c1.1-0.7,2.4-1,3.9-1
					c2.7,0,5.9,1.1,9.1,3c3,1,5,2,7,3c5,1,6,2,7,3s0,3,0,4c-1,2-2,2-3,2c0,1,0,1,0,1s0,0,1,1c0,0,1,1,2,1c1-1,2-1,3-1c3,0,5,1,7,4
					c1,2,1,2,1,2c1,1,3,1,4,1h2c1-1,3-2,4-2c2,0,4,2,5,4c0-1,0-1,0-1c0-1,0-2,0-3c-1-2-1-4-1-5c0-3,0-5,4-5c0,0,0,0,1,0c0-2,1-3,2-3
					c-2-1-3-1-3-2c-1,0-1-2,0-3s2-1,4-1s4,1,5,2c0,1,2,2,1,3c0,1-1,1-1,1c2,1,5,3,6,6c0.7,0.7,1.9,1.4,3.6,1.4
					c0.7,0,1.5-0.1,2.4-0.4c1.7-0.7,3.2-1,4.6-1c2.8,0,5,1.3,6.4,4c1,0,1,1,2,1c1,1,3,2,3,4c0,1,0,2,1,3c2,2,1,3,0,7c0,2-1,4-2,5v1
					c2,1,4,2,5,5c0-1,1-1,2-1c0.3-0.3,0.6-0.4,0.7-0.4c0.4,0,0.6,0.4,1.3,0.4c1,2,1,3,0,4c0,1-1,1-2,1s-1,0-1,0l-1-1c0,0-1,0-1,1
					c-1,1-1,2-2,3c1,0,1,0,2,0c1,1,2,3,2,4c-1,1-2,2-4,2c-1,0-2-2-2-4c0,0,0-1,1-2c-1,1-1,1-1,1c-1,0-1,0-1,0v1c0,1,0,2,1,3
					c3,1,3,5,4,7c0,2,0,3,2,4c1,2,2,4,2,4c0,1,1,1,2,1c0.5-0.5,1-0.8,1.6-0.8s1.4,0.3,2.4,0.8c1,1,1,2,0,3l1,1v-1c0-1,1-4,3-5
					c0-2,0-4,1-7c3-12,5-13,6-13c2,0,3,2,3,4c1,2,2,3,3,5c2,2,2,2,4,3c1,0,2,0,2,0c1-1,2-1,3-1c1-1,2-2,3-3c2.2-1.5,3.3-2.4,5.3-2.4
					c0.7,0,1.6,0.1,2.7,0.4c4,1,11,4,12,8c0,0,1,1,2,2v-1c0,0,0-1,0-2c0,0,0,0-1,0s-2-1-2-3s1-4,3-5c1.4-0.7,2.8-1.9,4.1-1.9
					c0.6,0,1.2,0.2,1.9,0.9c2,1,2,3,2,4c1,0,1-1,2-1c-1-1-2-3-1-5c0-1,0-1,0-2c-1-1-2-2-2-3c0,0,1-1,2-1c0-1,0-1,0-1s0-1,0-2
					c-1,3-3,3-3,3c-2-1-3-3-2-6c0.9-3.6,3.5-8.1,6.2-8.1c0.3,0,0.5,0,0.8,0.1c1,0,2,0,2,1c1-1,1-1,1-2c0,0,0,0-1,0c-2-2-1-4-1-5
					c0.7-0.7,1.9-1.4,3.3-1.4c0.6,0,1.1,0.1,1.7,0.4c4,2,12,3,16,3c3-1,6-1,7-1h3c0,0,0,0,1,0l8,1c3,1,6,1,8,1c2-1,3.8-1.5,5.8-1.5
					s4.3,0.5,7.3,1.5c7,1,9,2,9,5c0,1-1,3-2,4l14,2c0,0,1,0,1,1l2,2c2,3,4,4,5,5c2,0,3,1,4,2c1,0,3-1,4-2c4-2,4-2,10-7c3-3,5-4,8-4
					c1,0,2,0,2-1c1-1,4-2,8-3c3.5-1.2,5.3-1.7,7.4-1.7c1.5,0,3.1,0.2,5.6,0.7c7,2,11,3,12,7c1,1,1,2,2,3c0,1,1,2,1,4c0,1,0,2,0,3
					c0,2,0,2,1,2c1.5,0.5,2.5,0.8,3.5,0.8s2-0.3,3.5-0.8c2-1,3-2,5-2c1,0,2-1,3-2l3-2c0,0,2,0,2,1c1,0,1,0,1,0l1,2c0,2,2,3,3,4
					c2,1,3,3,2,5c1,1,1,1,1,1c1,0,2,1,3,2c0,1,1,3,0,4c3,1,3,5,3,7c0,3,0,4-3,5c-2,1-3,1-5,1c-1,0-2,1-2,2c-1,2-5,5-8,5
					c-1,1-6,6-9,7c0,1,0,1,0,2c-1,1-1,2-1,3c1,1,2,2,2,4s-1,3-2,3c-1,1-1,2,0,3s2,2,2,3c1,1,3,1,4,1c5,1,6,1,7,5c2,5,3,8,2,10
					c0,1,1,2,1,3l2,3c0,1,0,2-1,3l-3,2c-3,2-3,3-3,3c-1,1-1,2-2,3c-0.7,0.3-1.2,0.4-1.7,0.4c-0.9,0-1.3-0.4-1.3-0.4c-1-1-3-1-4-2
					c-2,3-11,4-14,4c-0.6,1.8-1.9,2.2-3.1,2.2c-0.8,0-1.5-0.2-1.9-0.2c0,1,0,2,1,2c1,2,1,3,1,5c0,1-1,2-2,2c0,1-2,1-3,1c0,0,0,0-1,0
					c0,2,0,3,0,5c2,0,2,2,2,2c0,1,0,1-3,4c-1,2-2,3-3,3c0,1,0,2,0,3c0,0,0,2,1,3c0,1,0,1,0,1c1,0,2,1,3,2c0,2,0,4-2,5c0,1,0,1,0,1
					c1,3,1,5-1,6c0,0-0.3,0.2-0.8,0.2c-0.8,0-2-0.4-3.2-2.2c0,1-1,1-2,1c0,0-1,1-2,1c0,1-2,2-3,3c-2,0-3,2-3,2c0,1,0,2-1,3
					c0,1-1,1-2,1c-2,0-6-3-6-6c-1,0-1,0-2,0c0,3-8,5-9,5s-2,0-3-1c0,1,0,1-1,1v1h1c2,2,2,4,1,7c0,2,0,5-3,5c-1-1-4-3-5-5
					c0,1,0,1,0,1c-1,1-2,2-5,2c0,0-2-1-4-1c0,1,0,1,0,1c0,3-1,5-3,7s-2,2-2,6c0,2,0,5-1,8c0,1,0,3,1,4c1,2,1,4,2,6c0,1,0,1,1,2
					c0,0,1,1,1,2s-1,2-1,3c-1,1-1,1-1,2c1,3,1,4-1,7c-2,2-4,6-4,7c0,0,0,1-1,2c-1,0-2,1-3,2c-1,0-2,0-3-3c0-1-1-1-1-1c-1,0-2,0-3-1
					c0,0,0,0-1,0c0-1,0-1-1-1h-1c0,1,0,1,0,1c-0.9,0.9-1,3.2-3.5,3.2c-0.4,0-0.9-0.1-1.5-0.2c-1,1-2,1-2,1c-2,0-3-1-3-2
					c-1-1-2-1-2-1c-0.7,0.7-1.4,1.4-2.1,1.4c-0.3,0-0.6-0.1-0.9-0.4c-2,0-4-2-5-4c0-2-3-4-6-4c0,1-1,2-4,2c1,4,1,8-2,10c0,1-1,2-1,2
					c0,4-3,8-5,10c-1,1-2,2-3,3c0,1-2,2-4,2c0,1-1,3-2,7c0,1,0,2,0,3c3,1,2,3,1,8c0,5,0,7,0,10c2,5,1,7,0,8c0,1-1,1-2,1s-2-2-2-3
					c-2,0-3,1-3,2s0,4-4,5c-1,0-2,1-3,1c-1.8,1.2-3.2,1.7-5,1.7c-1.3,0-2.9-0.2-5-0.7c-2.5-0.5-3.8-0.8-4.5-0.8s-1,0.3-1.5,0.8
					c0,2-1,3-1,4l-1,2c0,2-1,2-2,2l-4-1c-11-2-17-2-18-2c-1,1-4,5-4,6c0,0,0,2-2,2c0,1,0,1,0,2c0,2-1,6-3,7c0,0-1,0-1,1c0,0,0,0,0,1
					c-1,2-1,3-2,5c0,1-1,3-2,3s-2,0-3-1c1,2,2,3,3,4s2,1,2,3c0,1,0,3-2,4c-1,1-2,2-2,3s-2,4-3,5c-1,0-1,0-1,0c1,1,2,3,2,4s1,1,1,1
					c2,3,3,6,1,9c-0.8,2.3-1.5,3.4-3.1,3.4c-0.5,0-1.2-0.1-1.9-0.4c0,0-1,0-2,2c-1,4-3,10-2,11c0,1,0,2-1,3s-2,1-3,1c-2,0-3,0-5,0
					s-5,0-7-1c-1,0-1,0-2,0c-1,1-2,1.3-2.9,1.3s-1.6-0.3-2.1-0.3h-1c1,1,0,3-1,4c-0.6,0.3-1.2,0.4-1.8,0.4c-1.4,0-2.8-0.7-4.2-1.4
					c-1,0-3-1-4-3c-1,0-1,0-2,0c-0.3,0.3-0.8,0.4-1.3,0.4c-1,0-2.4-0.4-3.7-0.4c0,0,0,0,0-1c-1,1-1,2-2,2s-2,0-3,0c-1-1-2-1-2-2
					c-1,0-1,0-2,0c0,0.6-0.7,1.2-1.8,1.2c-0.8,0-1.9-0.3-3.2-1.2c0,1-1,1-1,1c-0.7,0-0.9,0.4-1.3,0.4c-0.2,0-0.4-0.1-0.7-0.4
					c0,0-1,0-2,0c-2-1-3-2-4-4c0,0-1-1-3-1s-3-1-4-2s-3-1-4-1c-5-1-8-1-8-4c0-1,0-1,0-1c-2-1-3-2-3-4c0,0,0-1-1-2c-1-2-1-2-4-2
					c-3-1-5-2-7-3c0-1-1-1-1-2v1c-1,0-2,1-3,1s-2-1-3-3c0,0,0-1-1-1c-1-1-2-1-3-2s-1-1-2-1c-2-1-5-2-5-5c0-1,0-1-6-3
					c-2.5-0.5-4-0.8-5-0.8s-1.5,0.3-2,0.8c-2,0.5-3.5,0.8-4.6,0.8s-1.9-0.3-2.4-0.8c-1,0-1-1-1-2v-1c-1,0-2-1-2-3c0-1,1-1,2-2
					c-1,0-1,0-1,0c-1-2-1-5-1-7c-2,0-2-2-2-4c-1-2,0-4,1-7c1-6,1-11,1-12c-2-2-2-4-1-7c0,0,0-1,0-2s-1-2,0-6s1-5,1-5c-2-1-3-2-4-3
					c0,1-2,2-3,2c-1-1-2-1-2-2c-3,0-6-1-7-3s0-3,1-6v-1c0-1-1-3-1-4s0-3,0-4c-1,0-1-1-1-1c-1,0-2-1-2-2c-1-1-2-2-3-3c-3-3-4-3-8-3
					c0,0,0,0-1,0v1c0,3-2,4-5,4s-3,1-3,1c-1,2-7,7-10,7c-1,0-5,3-7,6c-1,1-3,3-6,3c-1,0-5,1-5,2c-0.8,0.8-1.6,2.3-3,2.3
					c-0.3,0-0.7-0.1-1-0.3c-1-1-1-2-1-4c1-2,1-4,3-5v-1c-1-1-1-2-2-2c0,0-1,0-2,1c0,0-1,1-3,1s-7-1-9-3c-1,0-1-1-1-1c-1,0-1,1-1,1
					c-1.9,1.9-3.9,3-6.1,3c-1.2,0-2.5-0.3-3.9-1c-3-2-6-5-5-8c-1-1-1-2-1-3c0-2,5-8,7-10c1-2,2-4,3-5c2-4,7-11,11-12c0-1,2-3,3-3
					l1-3c-2-1-3-2-3-4c0,0,0,0,0-1c-3-1-2-4-1-5c0,0,0-1,0-2c-1-1-1-3-1-5c1-3,2-4,4-6c1-1,2-2,3-3c1-2,0-3,0-3c-3-1-4-2-4-4
					s2-4,3-5s1-1,2-6c2-9,0-12-3-13c-2-1-4-2-4-3c0-2,1-3,2-3c0-1,1-2,1-2c0.3-0.3,0.8-0.4,1.3-0.4c1,0,2.1,0.4,2.7,0.4v-2
					c1-4,1-4-2-5c-2,0-3-1-4-2c-2-1-6-4-7-6c0-2-1-3-2-3c-3,0-4-2-5-3s-2-3-2-4s1-3,2-3c1-1,2-2,3-2v-1c1-3,2-4,5-4c1-1,3-1,4-2l2-1
					l-1-3c0-2,0-7,1-11c1-6,1-7,4-9c0,0,0,0,1,0c0-1,0-3,0-4c1-3,0-3,0-4c-1-1-1-3,0-4c0-2,2-3,3-4c1,0,2-1,4-1c1,0,2-1,3-1
					c2-1,2-2,3-4c0-1,2-4,3-5c2-1,2-2,2-3c0-2,1-5,2-7c1-3,2-6,2-8c1-5,1-5,0-6c-1-3-1-5-1-8c1-2,1-5,3-6c0.6-0.3,1.2-0.4,1.8-0.4
					c1.5,0,3.1,0.7,5.2,1.4c3,0,4,0,5,0c2-1,6-1,9-1c2,1,3,2,4,3c1-1,2-2,3-3c0-1,0-2,0-3s0-2,0-3c1.6-1.6,3.9-3.3,6.4-3.3
					c0.5,0,1.1,0.1,1.6,0.3h1c0-1,0-1,0-1c2-4,5-5,11-6c2,0,4,0,6-1c1,0,1,0,2,0c0-1,0-2,1-2c0-1,0-1,0-2s-1-1-1-2s1-10,3-12
					c1,0,1-1,1-1c0-1,0-1,0-1c-1-1-2-2-2-4c0-1-1-1-1-2c-3-2-2-4-2-7c0-2,1-6,2-8c0-2,0-3,0-4c-1-1-1-2-1-4c1-2,2-4,4-5c1-1,2-1,3-2
					c0-1,1-3,2-3c1-1,1-1,2-1c2,0,3,1,4,2c0,1,2,2,3,2s2,0,2,0h1c-1-1-1-2-1-3s0-1,0-1c0-1-1-1-3-3c-1-1-2-2-4-3c-1,0-2-2-2-4
					s2-3,3-3s2,1,2,1c1,0,2,0,2,0c1-2,3-2,5-2c1,0,1,0,1,0c1,0,1-1,2-1C193,76.2,193,76.2,193,76.2 M210.9,88.2c1,1,2,3,1,4
					c0,2-1,3-3,3s-3-2-3-4c1-3,3-3,3-3C209.9,88.2,209.9,88.2,210.9,88.2 M207.9,92.2c0-1,0-1,0-1S207.9,91.2,207.9,92.2 M364,98.2
					c2,0,3,2,4,4c0,1.5,0,3-0.9,4.5c-0.5,0.4-0.9,0.9-1.3,1.5c-1,0.1-1.9,1-2.9,1v-1c-2,0-3-1-3-3c-1-2-1-3-1-4c1-2,2-3,4-3H364
					 M329,100.2c2,0,5,3,7,5c1,2,3,4,1,7c-1,1-2,1-3,1s-2-1-3-3c-1,0-1-1-2-2c-2-1-3-4-3-4c0-2,0-3,1-4
					C328,100.2,328,100.2,329,100.2 M372.3,105c0.8,0,1.7,0.1,2.7,0.2c4,1,4,1,7,6c3,3,3,5,4,6c0,1,1,2,1,3c-0.6,1.7-2,2.4-4.9,2.4
					c-2.3,0-5.6-0.5-10.1-1.4c-2,0-5,0-7-1c-1,1-3,2-4,2s-1,0-1,0c-1-1-2-2-1-3c0-2,2-3,3-4c1,0,2-1,2-3c0.5-1.6,1.1-2.9,1.9-4
					c0,0,0.1,0,0.1,0c0.5-0.5,0.9-1,1.1-1.5C368.5,105.5,370.2,105,372.3,105 M251.9,108.2c1,0,2,1,2,2c1,1,1,2,0,3c-1,0-2,1-3,1
					c-1-1-3-2-2-4C248.9,109.2,249.9,108.2,251.9,108.2 M315.5,122c1.2,0,2.3,0.6,3.5,1.2c0,1,0,2,0,3c0,0,0,1,0,2c-1,1-2,2-4,2
					c0,0,0,0-1,0s-2-1-3-3c0-1,0-3,1-4h1C313.8,122.4,314.7,122,315.5,122 M522,124.8c0.4,0,0.7,0.1,1,0.4c2,0,3,1,2,3h1
					c1,0,2,1,3,2c1,2,1,3,0,5c0,1-1,3-3,3c-2-1-2-2-3-3c0,1-1,2-3,2h-1c-2,0-4-1-5-2c-1-2-1-4,1-5c1-1,2-1,3-1s2,1,2,1c1,1,1,1,2,2
					c0-1,0-1,0-2c-1,0-2-2-2-3C520,125.7,521.1,124.8,522,124.8 M352,149.2c2,1,4,3,3,6c0,2-2,3-4,3c-3-1-4-3-3-5
					C348,150.2,350,149.2,352,149.2 M363,54.7c-2,0-4.3,0-5.4,2.2c-1.1,1.2-1.1,3.3-0.1,5.5c0.1,0.3,0.6,1.9,0.9,3.5
					c0-0.1-0.1-0.1-0.2-0.1c-1.9-0.9-2.8-1.9-3.8-2.9c-2.4-4-4.1-6-7.1-6c-1,0-2.2,0.3-3.5,0.8c-4.2,2.1-6.3,5.3-6.3,9.5V68
					c-1,1-2,2-2,3.2c-0.2-0.5-0.5-0.9-1-1.2v-0.7c0-0.1-0.1-0.3-0.1-0.4c-0.3-0.3-0.6-0.7-1-1.2c-0.8-1-1.5-2-2.4-2h-1
					c-0.1,0-0.1,0-0.2,0c-1,0.3-1.9,1.3-2.6,3c-1.1,2.7-1.5,6.9-0.8,8.6c0,2.2,1.2,3.4,3.5,3.4h1c2.6,0,3.3-2,3.8-3.7
					c0.2-0.6,0.4-1.1,0.6-1.6c0-0.1,0.1-0.1,0.1-0.2c0-0.2,0-0.4,0.1-0.8c0-0.2,0.1-0.5,0.1-0.8c0.2,0.7,0.4,1.4,0.9,1.9
					c0.6,0.6,0.6,1,0.6,1.1c0,0-0.1,0.1-0.2,0.1c-0.3,0-0.5,0.2-0.5,0.5c0,3.9,0.9,5.1,3.5,8.4l0.6,0.9c4,4.9,5.1,6.1,7.2,6.2
					c1,1,2.1,2,3.2,2c2.1,0,4.3-1.1,6.4-3.1c0,0,0,0,0.1-0.1c1.5-2.3,5.8-4,9.9-4c0.9,0,1.8,0.1,2.6,0.3c0,0,0.1,0,0.1,0
					c2.2,0,5.2,0,6.4-1.1c0.1-0.1,0.1-0.2,0.1-0.4c0-0.4,0.3-0.5,0.5-0.5c0,0,0.1,0,0.2,0c3.1-1,3.3-2.1,3.3-3.5v-0.6
					c0.8-0.2,1.4-0.7,1.7-1c1.1-0.1,2.2-1.1,3.1-2.1c1.3-1.3,2.1-3.4,1.1-5.5c0-0.9,0-1.8,0.8-3.4c1.2-0.2,1.2-1.6,1.2-2.5
					c0-2.3-2.1-3.4-6.3-5.4c0,0-0.1,0-0.1,0c-0.9-0.2-1.7-0.4-2.3-0.6c-1.6-0.4-2.8-0.8-4-0.8c-0.7,0-1.3,0.1-1.9,0.4
					c-3,0-3.4-0.5-3.4-0.5c0-0.1,0-0.2-0.1-0.3c-2.1-2.1-5.2-4.1-7.1-5.1C363.2,54.7,363.1,54.7,363,54.7L363,54.7z M393.5,62
					c-0.6,0-1.2,0.3-1.7,0.8c-0.9,0.1-1.2,0.8-1.3,1.4l-1,2.9c0,0.1,0,0.1,0,0.2c0,6.1,2,8.4,8.3,9.5l5.9,3c0.5,0.3,1,0.5,1.4,0.8
					c1.2,0.7,2.2,1.3,3.8,1.3h1c4.6,0,7.8-2.6,10.4-5.1c0,0,0,0,0.1-0.1l2-3c1.2-1.2,1.1-2.5,0-3.7l-0.9-0.9c-0.1-0.7-0.5-1-1-1.2
					v-1.6c0-0.1-0.1-0.3-0.1-0.4c-1.1-1.1-2.3-1.1-3.4-1.1c-2,0-6,0-8.2,2h-0.3c0-0.9-0.2-1.9-1.1-2.9c-0.8-0.8-1.7-1.2-2.8-1.2
					c-1.5,0-3.2,0.9-5.8,2.1c0,0-0.1,0.1-0.1,0.1l-1.6,1.6c-0.5-0.5-0.5-0.6-0.5-1.3c0-0.1,0-0.2-0.1-0.2l-1-2c0,0-0.1-0.1-0.1-0.1
					C394.8,62.3,394.1,62,393.5,62L393.5,62z M193,75.7c-0.1,0-0.3,0.1-0.4,0.1l-0.9,0.9c-0.6,0.1-0.9,0.4-1.1,0.6
					c-0.2,0.2-0.4,0.4-0.6,0.4h-1c-1.9,0-4.1,0-5.3,2h-1.5c-0.4-0.3-1.2-1-2.2-1c-1.3,0-3.5,1.2-3.5,3.5c0,2.1,1,4.4,2.4,4.5
					c1.9,0.9,2.8,1.9,3.8,2.9c0.9,0.9,1.6,1.4,2.1,1.7c0.7,0.5,0.8,0.6,0.8,0.9v1c0,0.8,0,1.6,0.5,2.5h-2c-0.9,0-2.5-0.9-2.5-1.5
					c0-0.1-0.1-0.3-0.1-0.4c-1-1-2.1-2.1-4.4-2.1c-1.1,0-1.3,0.1-2.2,1c-1.2,0.2-2.1,2.1-2.3,3.3c-0.4,0.4-0.8,0.6-1.2,0.8
					c-0.5,0.3-1,0.5-1.6,1c-2.1,1.1-3.1,3.2-4.2,5.2c0,0.1-0.1,0.1-0.1,0.2c0,2,0,3.1,1,4.2v3.7c-1,2.1-2,6.1-2,8.1
					c0,0.6,0,1.2-0.1,1.7c-0.2,2.1-0.3,3.8,2.1,5.5c0.1,0.5,0.4,0.8,0.6,1.1c0.2,0.2,0.4,0.4,0.4,0.6c0,2.1,1,3.2,2,4.2v0.8
					c0,0.2-0.1,0.5-0.5,0.5c-0.1,0-0.3,0.1-0.4,0.1c-2.2,2.2-3.1,11.8-3.1,12.4c0,0.7,0.4,1.1,0.6,1.4c0.2,0.2,0.4,0.4,0.4,0.6v1.6
					c-0.8,0.2-1,1.1-1,1.9H164c-0.1,0-0.2,0-0.2,0.1c-1.9,0.9-3.8,0.9-5.8,0.9c0,0-0.1,0-0.1,0c-6.3,1.1-9.3,2.2-11.4,6.3
					c0,0.1-0.1,0.1-0.1,0.2v0.5h-0.4c-0.6-0.2-1.1-0.3-1.7-0.3c-2.7,0-5.2,1.9-6.7,3.4c-0.1,0.1-0.1,0.2-0.1,0.4v3v2.8l-2.5,2.5
					c-0.9-0.9-1.9-1.8-3.8-2.7c-0.1,0-0.1-0.1-0.2-0.1c-3.1,0-7,0-9.1,1h-4.8c-0.4-0.1-0.8-0.3-1.2-0.4c-1.5-0.5-2.9-1-4.1-1
					c-0.7,0-1.4,0.2-2,0.5c-1.5,0.8-2,2.5-2.5,4.1c-0.2,0.8-0.4,1.5-0.7,2.1c0,0.1-0.1,0.1-0.1,0.2c0,3.1,0,5.1,1,8.2
					c0,0.1,0.1,0.1,0.1,0.2c0.8,0.8,0.8,0.8-0.1,5.5c0,0,0,0.1,0,0.1c0,1.9-1,4.9-2,7.8c-1,2-2,5.1-2,7.2c0,0.9,0,1.7-1.7,2.6
					c0,0-0.1,0.1-0.1,0.1c-0.9,0.9-3,3.9-3.1,5.2c-0.2,0.4-0.4,0.8-0.5,1.1c-0.5,1.2-0.8,1.8-2.1,2.5c-0.6,0-1.1,0.3-1.6,0.5
					c-0.5,0.2-0.9,0.4-1.3,0.4c-1.3,0-2.2,0.4-2.9,0.7c-0.5,0.2-0.8,0.3-1.1,0.3c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0.2-0.5,0.4-0.8,0.7
					c-1,0.8-2.2,1.8-2.3,3.5c-1,1.2-1,3.2,0,4.4c0,0.3,0.1,0.5,0.2,0.7c0.2,0.5,0.4,1-0.2,2.9c0,0.1,0,0.1,0,0.2v3.5H85
					c-0.1,0-0.2,0-0.3,0.1c-3,2-3.2,3.2-4.1,8.3l-0.2,1c-1,4-1,9.2-1,11.1c0,0.1,0,0.1,0,0.2l0.9,2.6l-1.6,0.8c0,0-0.1,0.1-0.1,0.1
					c-0.4,0.4-1.1,0.6-1.8,0.9c-0.7,0.2-1.5,0.5-2,1c-3.3,0.1-4.3,1.5-5.3,4.3c0,0.1,0,0.1,0,0.2v0.6c-1,0.2-1.9,1.1-2.7,2
					c-1.3,0.2-2.3,2.4-2.3,3.5c0,1.1,1,3.3,2.1,4.4c0.2,0.2,0.4,0.4,0.5,0.6c0.9,1.1,2.1,2.5,4.8,2.5c0.7,0,1.5,0.9,1.5,2.5
					c0,0.1,0,0.2,0.1,0.2c1.1,2.2,5.3,5.2,7.2,6.2c1,1,2.1,2.1,4.2,2.1c1.4,0.5,2,0.7,2.1,1.1c0.2,0.5-0.1,1.5-0.5,3.3
					c0,0,0,0.1,0,0.1v1.4c-0.2,0-0.3-0.1-0.5-0.1c-0.5-0.1-1.1-0.3-1.7-0.3c-0.7,0-1.2,0.2-1.6,0.6c-0.1,0.1-0.9,0.9-1.1,1.9
					c-1.1,0.2-2,1.3-2,3.4c0,1.3,2,2.3,4.3,3.4c0,0,0,0,0.1,0c2.2,0.7,4.8,2.8,2.7,12.4c-1,4.9-1,4.9-1.9,5.8c-1,1-3.1,3.1-3.1,5.4
					c0,2.5,1.5,3.5,4.2,4.4c0.2,0.2,0.5,1-0.1,2.3l-2.9,2.9c-2,2-3.1,3.1-4.1,6.2c0,0.1,0,0.1,0,0.2c0,2,0,4,1,5.2v1.6
					c-0.7,0.8-1.2,2.2-0.9,3.4c0.2,0.7,0.6,1.6,1.9,2.1v0.7c0,2.2,1.1,3.3,2.9,4.2l-0.8,2.3c-1.2,0.3-2.7,1.9-3.1,3.1
					c-4,1.3-8.8,7.9-11,12.1c-1,1.1-2,3-3,5c-1.6,1.6-7.1,7.9-7.1,10.3c0,1,0,2.1,0.9,3.1c-0.9,3.5,2.9,6.7,5.3,8.3c0,0,0,0,0.1,0
					c1.5,0.7,2.8,1.1,4.1,1.1c2.2,0,4.3-1,6.4-3.2c0.1-0.1,0.1-0.2,0.1-0.4c0-0.1,0-0.3,0.1-0.4c0.2,0.4,0.5,0.8,1.2,0.9
					c2.3,2.1,7.4,3,9.2,3c2.2,0,3.3-1.1,3.4-1.1c0.8-0.8,1.6-0.9,1.7-0.9c0.3,0,0.4,0.1,0.7,0.7c0.2,0.3,0.4,0.7,0.7,1v0.5
					c-1.4,0.8-1.8,2.1-2.3,3.4c-0.2,0.6-0.4,1.1-0.7,1.7c0,0.1-0.1,0.1-0.1,0.2c0,2.1,0,3.2,1.1,4.4c0,0,0.1,0.1,0.1,0.1
					c0.4,0.2,0.9,0.3,1.3,0.3c1.3,0,2.1-1,2.8-1.8c0.2-0.2,0.4-0.5,0.6-0.6c0.1-0.1,0.1-0.2,0.1-0.4c0.2-0.6,3.4-1.5,4.5-1.5
					c3.2,0,5.3-2.1,6.4-3.1c0,0,0,0,0.1-0.1c2.1-3.1,5.9-5.8,6.6-5.8c3.2,0,9.4-5.1,10.4-7.3c0,0,0-0.1,0-0.2c0-0.1,0.4-0.6,2.5-0.6
					c3.6,0,5.5-1.5,5.5-4.5v-0.5h0.5c4,0,4.8,0,7.6,2.9l2.9,2.9c0.1,1.1,1,2,2.1,2.2c0.1,0.4,0.4,0.8,0.9,1v3.6
					c0,0.6,0.3,1.3,0.5,2.2c0.2,0.7,0.5,1.4,0.5,1.8v0.9c-0.2,0.7-0.5,1.3-0.7,1.8c-0.7,1.7-1.1,2.8-0.3,4.5c1,1.9,3.7,3.2,7,3.3
					c0.2,0.6,0.6,0.8,1.1,1.1c0.3,0.2,0.7,0.4,1,0.7c0.1,0.1,0.2,0.1,0.4,0.1c0.9,0,2.5-0.7,3.2-1.6c0.8,0.8,1.8,1.6,3.3,2.4
					c-0.1,0.5-0.3,1.8-1,4.6c-0.8,3.1-0.4,4.5-0.2,5.4c0.1,0.3,0.1,0.5,0.1,0.7v1.9c-0.9,2.9-1.1,5.1,1,7.3c0,3.8-0.4,7.9-1,11.7
					c-0.1,0.4-0.3,0.8-0.4,1.2c-0.8,2.3-1.5,4.1-0.6,6c0,1.8,0,4,2,4.3c0,2,0.1,4.8,1.1,6.8c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0
					c-0.6,0.3-1.1,0.7-1.1,1.6c0,2.1,1,3.2,2,3.4v0.6c0,0.9,0,2.4,1.3,2.5c0.6,0.5,1.4,0.8,2.6,0.8c1.2,0,2.7-0.2,4.7-0.8
					c0.1,0,0.2-0.1,0.2-0.1c0.4-0.4,0.8-0.6,1.6-0.6c1,0,2.6,0.3,4.9,0.7c5.6,1.9,5.6,1.9,5.6,2.5c0,3,2.6,4.2,4.5,5.1
					c0.3,0.1,0.5,0.2,0.8,0.4c0.1,0,0.1,0.1,0.2,0.1c0.8,0,0.8,0,1.6,0.9c0.6,0.6,1.1,0.8,1.6,1.1c0.5,0.2,0.9,0.5,1.4,0.9
					c0.1,0.1,0.2,0.1,0.4,0.1c0.4,0,0.5,0.3,0.5,0.5c0,0.1,0,0.2,0.1,0.2c1.1,2.2,2.2,3.3,3.4,3.3c0.6,0,1.2-0.3,1.7-0.6
					c0.5-0.2,0.9-0.4,1.3-0.4c0.1,0,0.2,0,0.2-0.1c0.2,0.2,0.3,0.3,0.3,0.6c0,0.2,0.1,0.4,0.3,0.4c2,1,4,2,7.1,3c0.1,0,0.1,0,0.2,0
					c2.7,0,2.7,0,3.6,1.7c0,0,0.1,0.1,0.1,0.1c0.8,0.8,0.9,1.6,0.9,1.7c0,2.2,1.2,3.3,3,4.3v0.7c0,3,2.5,3.4,6.3,4.1
					c0.7,0.1,1.4,0.2,2.1,0.4c0,0,0.1,0,0.1,0c0.9,0,2.8,0,3.6,0.9c1,1,2.1,2.1,4.4,2.1c1.5,0,2.4,0.6,2.6,0.8c1,2,2.1,3.1,4.2,4.1
					c0.1,0,0.1,0.1,0.2,0.1h1.8c0.3,0.3,0.6,0.4,0.9,0.4c0.3,0,0.5-0.1,0.7-0.2c0.2-0.1,0.3-0.2,0.6-0.2c0.4,0,1-0.2,1.3-0.7
					c1.3,0.7,2.2,0.9,2.9,0.9c1.2,0,1.9-0.5,2.2-1.2h1.2c0.2,0.5,0.6,0.8,1.1,1.1c0.3,0.2,0.7,0.4,1,0.7c0.1,0.1,0.2,0.1,0.4,0.1h3
					c0.9,0,1.3-0.6,1.6-1.1c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.4,0.2c0.7,0,1.3,0.1,2,0.2c0.6,0.1,1.2,0.2,1.7,0.2
					c0.6,0,1.1-0.1,1.5-0.4h1.5c1.1,2,3.1,2.9,4.2,3c1.4,0.7,2.9,1.4,4.4,1.4c0.7,0,1.4-0.2,2-0.5c0,0,0.1-0.1,0.1-0.1
					c0.6-0.6,1.1-1.4,1.4-2.2c0.2-0.6,0.2-1.2,0.1-1.7h0.2c0.2,0,0.4,0,0.7,0.1c0.4,0.1,0.9,0.2,1.4,0.2c1.2,0,2.2-0.4,3.1-1.3h1.7
					c2.1,1,5.1,1,7.1,1h5c1,0,2.2,0,3.4-1.1c1.1-1.1,1.1-2.3,1.1-3.4c0-0.1-0.1-0.3-0.1-0.4c-0.6-0.6,0.7-5.2,1.4-7.7
					c0.3-1,0.5-1.9,0.7-2.8c0.7-1.3,1.3-1.6,1.5-1.7c0.8,0.2,1.4,0.4,1.9,0.4c1.9,0,2.8-1.4,3.5-3.7c2.4-3.6,0.5-7.1-1-9.5
					c-0.1-0.1-0.2-0.2-0.4-0.2c-0.2,0-0.5-0.1-0.5-0.5c0-0.9-0.6-2.4-1.4-3.5c0.1,0,0.2-0.1,0.3-0.1c0.9-0.9,3.1-4.1,3.1-5.4
					c0-0.8,0.9-1.7,1.8-2.6c2.2-1.2,2.2-3.4,2.2-4.4c0-1.7-0.7-2.2-1.4-2.8c-0.2-0.2-0.5-0.4-0.7-0.6c-0.7-0.7-1.3-1.3-2-2.3
					c0.6,0.1,1.1,0.1,1.6,0.1c1.3,0,2.4-2.2,2.5-3.4c0.5-1,0.7-1.7,1-2.5c0.3-0.8,0.5-1.5,1-2.4c0-0.1,0.1-0.1,0.1-0.2v-1
					c0-0.4,0.3-0.5,0.5-0.5c0.1,0,0.2,0,0.2-0.1c2.3-1.1,3.3-5.5,3.3-7.4v-1.5c1.6-0.3,2-1.7,2-2.5c0-0.7,2.5-4.2,3.7-5.5
					c2.3,0,8.1,0.3,17.7,2l4,1c0,0,0.1,0,0.1,0c1,0,2.5,0,2.5-2.4l0.9-1.9c0-0.1,0.1-0.1,0.1-0.2c0-0.3,0.1-0.7,0.3-1.1
					c0.3-0.7,0.6-1.5,0.7-2.7c0.4-0.4,0.5-0.5,1-0.5c0.7,0,1.9,0.2,4.4,0.7c2,0.4,3.6,0.7,5.1,0.7c1.9,0,3.4-0.5,5.2-1.7
					c0.6,0,1.1-0.3,1.6-0.5c0.5-0.2,0.9-0.4,1.3-0.4c0,0,0.1,0,0.1,0c2.9-0.7,4.4-2.6,4.4-5.5c0-0.7,0.7-1.4,2.1-1.5
					c0.2,1.2,1.3,3,2.4,3c0.9,0,2.4,0,2.5-1.3c1.5-1.7,1.6-4.4,0-8.3v-9.9c0.1-0.7,0.3-1.4,0.4-2c0.8-3.4,1.2-5.4-1.4-6.4v-2.6
					c0.5-2,1-3.4,1.3-4.5c0.3-0.9,0.5-1.5,0.6-2c1.9-0.1,3.8-1,4-2.3l2.9-2.9c2.4-2.4,5.1-6.4,5.1-10.2c0.3-0.3,0.9-1.1,1-1.9
					c2.5-1.8,3.2-5.1,2.1-9.8c2.8-0.1,3.6-1.2,3.8-2c2.5,0.2,5.1,1.9,5.1,3.5c0,0.1,0,0.2,0.1,0.2c1,2,3,4.1,5.2,4.3
					c0.3,0.3,0.7,0.4,1.1,0.4c0.8,0,1.6-0.7,2.3-1.4c0.3,0,0.8,0.2,1.3,0.7c0.1,1.1,1.2,2.3,3.5,2.3c0,0,1.1,0,2.1-1
					c0.5,0.1,0.9,0.2,1.3,0.2c2.1,0,2.8-1.4,3.4-2.4c0.2-0.4,0.4-0.7,0.5-0.9c0.1-0.1,0.1-0.2,0.1-0.4v-0.5h0.5c0.2,0,0.4,0,0.5,0
					c0,0.1,0,0.3,0,0.5c0,0.3,0.2,0.5,0.5,0.5h0.8c1.1,1,2.2,1,3.2,1c0.2,0,0.5,0.1,0.5,0.5c0,0.1,0,0.1,0,0.2
					c1,3.1,2.1,3.3,3.5,3.3c0.1,0,0.3-0.1,0.4-0.1c0.9-0.9,1.9-1.9,2.6-1.9c0.1,0,0.3-0.1,0.4-0.1c1.1-1.1,1.1-2.3,1.1-2.4
					c0-0.8,1.9-4.7,3.9-6.6c0,0,0,0,0.1-0.1c2.1-3.2,2.1-4.3,1.1-7.4c0-0.7,0-0.7,0.9-1.6c0.1-0.1,0.1-0.2,0.1-0.4
					c0-0.4,0.2-0.8,0.4-1.3c0.3-0.5,0.6-1.1,0.6-1.7c0-1.2-1-2.2-1.1-2.4c-0.9-0.9-0.9-0.9-0.9-1.6c0-0.1,0-0.2-0.1-0.2
					c-0.5-1-0.7-1.9-1-2.9c-0.3-1-0.5-2.1-1-3.1c0,0-0.1-0.1-0.1-0.1c-0.8-0.8-0.9-2.6-0.9-3.6c1-3.1,1-6.1,1-8.1
					c0-3.8,0-3.8,1.9-5.6c2.2-2.2,3.1-4.4,3.1-7.4v-0.5c1.7,0.1,3.3,0.9,3.3,0.9c0.1,0,0.1,0.1,0.2,0.1c3.2,0,4.3-1.1,5.3-2.1
					c0.9,1.1,2.1,2.1,3.1,2.9c0.5,0.4,0.9,0.8,1.2,1c0.1,0.1,0.2,0.1,0.4,0.1c3.5,0,3.5-3.5,3.5-5.4c1-2.9,1.1-5.2-1.1-7.4
					c-0.1-0.1-0.2-0.1-0.4-0.1h-0.5v0c0.5,0,0.8-0.2,0.9-0.5c0.9,0.5,1.8,0.5,2.6,0.5c0.9,0,8.7-1.8,9.4-5h1.1c0.4,3.1,4.3,6,6.5,6
					c0.9,0,2.4,0,2.5-1.3c1-1.1,1-2.1,1-3.1c0.2-0.4,1.1-1.6,2.5-1.6c0.1,0,0.3-0.1,0.4-0.1c0.3-0.3,0.7-0.6,1.2-1
					c0.8-0.6,1.6-1.2,1.9-1.9c0.8-0.2,1.5-0.7,1.8-1c0.7,0,1.5,0,1.9-0.5c1.1,1.3,2.3,1.7,3,1.7c0.6,0,1-0.2,1.1-0.2
					c2.6-1.3,2.1-4,1.3-6.5v-0.6c2-1.2,2-3.4,2-5.3c0-0.1-0.1-0.3-0.1-0.4c-0.9-0.9-1.8-1.8-2.9-2.1v-0.6c0-0.1-0.1-0.3-0.1-0.4
					c-0.8-0.8-0.9-2.6-0.9-2.6v-2.6c1-0.3,2-1.3,2.9-3.1c3-3,3.1-3.1,3.1-4.3c0-0.8-0.4-2.2-2-2.5v-4h0.5c1.1,0,3,0,3.4-1
					c1.1-0.2,2.1-1.3,2.1-2.5c0-2,0-3.1-1.1-5.2c-0.1-0.2-0.3-0.3-0.4-0.3c-0.3,0-0.5-0.1-0.5-1c0,0,0.1,0,0.1,0
					c0.4,0,0.8,0.1,1.3,0.1c1.7,0,2.8-0.7,3.4-2.2c3.2-0.1,11.2-1,13.7-3.8c0.5,0.4,1.2,0.6,1.8,0.8c0.7,0.2,1.4,0.5,1.8,0.9
					c0.1,0.1,0.6,0.6,1.7,0.6c0.6,0,1.2-0.2,1.9-0.5c0,0,0.1-0.1,0.1-0.1c0.6-0.6,0.8-1.1,1.1-1.6c0.2-0.5,0.5-0.9,0.9-1.4
					c0.1-0.1,0.1-0.2,0.1-0.4c0,0,0,0,0,0c0,0,0.2-0.9,2.8-2.6l3-2c0,0,0.1,0,0.1-0.1c1.1-1.1,1.1-2.3,1.1-3.4c0-0.1,0-0.2-0.1-0.3
					l-1.9-2.9c0-0.6-0.3-1.1-0.5-1.6c-0.2-0.4-0.4-0.8-0.4-1.2c1-2.2-0.1-5.5-2-10.3c-1-4.1-2.2-4.3-6.7-5.2l-0.7-0.1
					c0,0-0.1,0-0.1,0c-0.9,0-2.6,0-3.5-0.7c-0.1-1.1-1.1-2.1-2.1-3.1c-0.8-0.8-0.8-1.4-0.1-2.2c1.1-0.1,2.3-1.2,2.3-3.5
					c0-2.1-1-3.2-2-4.2c0-0.9,0.1-1.6,0.9-2.4c0.1-0.1,0.1-0.2,0.1-0.4v-1.7c2.7-1.1,6.5-4.7,8.7-6.9c3.3-0.1,7.2-3.2,8.2-5.3
					c0-0.1,0.1-0.1,0.1-0.2c0-0.7,0.8-1.5,1.5-1.5c2,0,3.1,0,5.2-1c3.3-1.1,3.3-2.5,3.3-5.5c0-1.9,0-5.8-2.7-7.2
					c0.4-0.8,0.1-1.9-0.1-2.8c-0.1-0.4-0.2-0.8-0.2-1c0-0.1-0.1-0.3-0.1-0.4c-1-1-2-2-3.1-2.1l-0.6-0.6c0.4-1,0.4-2,0-3
					c-0.4-1-1.2-1.8-2.3-2.3c-0.2-0.2-0.5-0.5-0.8-0.7c-1-0.8-2-1.6-2-2.9c0-0.1,0-0.2-0.1-0.2l-1-2c-0.1-0.2-0.3-0.3-0.4-0.3h-0.6
					c-0.3-0.7-1.4-1-2.4-1c-0.1,0-0.2,0-0.3,0.1l-3,2c0,0-0.1,0-0.1,0.1c-0.9,0.9-1.9,1.9-2.6,1.9c-1.6,0-2.6,0.6-3.8,1.3
					c-0.4,0.2-0.9,0.5-1.4,0.8c-1.4,0.5-2.3,0.7-3.3,0.7c-1,0-2-0.3-3.3-0.7c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0-0.4,0
					c-0.1-0.2-0.1-0.8-0.1-1.5v-3c0-1.3-0.4-2.2-0.7-2.9c-0.2-0.5-0.3-0.8-0.3-1.1c0-0.1-0.1-0.3-0.1-0.4c-0.4-0.4-0.7-0.9-0.9-1.4
					c-0.2-0.5-0.5-1-1-1.5c-1.1-4-5-5.2-11.6-7l-0.7-0.2c0,0,0,0-0.1,0c-2.3-0.4-4.1-0.7-5.7-0.7c-2.2,0-4,0.5-7.6,1.7
					c-4.6,1.1-7.2,2.1-8.2,3.1c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.4-0.2,0.5-1.5,0.5c-3.1,0-5.3,1-8.4,4.1c-5.9,4.9-5.9,4.9-9.9,6.9
					c0,0-0.1,0.1-0.1,0.1c-0.9,0.9-2.6,1.7-3.5,1.8c-0.9-0.9-2-1.9-4-2c-0.3-0.3-0.6-0.5-1-0.8c-1-0.8-2.4-1.9-3.8-4
					c0,0,0-0.1-0.1-0.1l-1.9-1.9c-0.1-0.9-0.9-1.3-1.4-1.3l-13.1-1.9c0.8-1.1,1.5-2.7,1.5-3.6c0-3.6-2.8-4.5-9.4-5.5
					c-3.2-1.1-5.4-1.5-7.4-1.5c-2,0-3.8,0.5-5.9,1.5c-2,0-4.9,0-7.7-1c0,0-0.1,0-0.1,0l-8-1c0,0,0,0-0.1,0h-1h-3c-1,0-4,0-7.1,1
					c-4.2,0-11.9-1-15.7-2.9c-0.6-0.3-1.3-0.5-2-0.5c-1.9,0-3.3,1.2-3.6,1.6c-0.1,0.1-0.1,0.2-0.1,0.4c0,0.2,0,0.4-0.1,0.7
					c-0.2,1.1-0.5,2.9,1.2,4.7c0.1,0.1,0.2,0.1,0.4,0.1h0.5c0,0.2-0.1,0.4-0.3,0.6c-0.5-0.6-1.4-0.6-2.1-0.6
					c-0.3-0.1-0.6-0.1-0.9-0.1c-3.2,0-5.8,5-6.7,8.5c-1,2.9-0.2,5.4,2.3,6.6c0.1,0,0.1,0.1,0.2,0.1c0.1,0,1.4,0,2.5-1.5v0.5
					c-1,0.2-1.8,1-1.9,1.1c-0.1,0.1-0.1,0.2-0.1,0.4c0,1.2,1,2.2,2,3.2v1.7c-0.8,1.7-0.3,3.6,0.6,4.9c-0.2,0.1-0.3,0.3-0.5,0.4
					c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1-1.1-0.4-2.8-2.2-3.7c-0.7-0.6-1.4-0.9-2.2-0.9c-1.1,0-2.2,0.6-3.2,1.2c-0.4,0.2-0.8,0.5-1.2,0.7
					c-2,1-3.3,3.1-3.3,5.4c0,0.3,0,0.6,0.1,0.8c-2.6-2.6-7.2-4.5-10.4-5.3c-1.1-0.3-2-0.4-2.8-0.4c-1.9,0-3.1,0.8-4.9,2
					c-0.2,0.1-0.4,0.3-0.7,0.5c0,0-0.1,0-0.1,0.1l-2.9,2.9c-0.9,0-2,0.1-3,1h-1.7c-1.8-0.9-1.9-0.9-3.7-2.8c-0.5-1-1-1.7-1.5-2.5
					c-0.5-0.7-0.9-1.4-1.4-2.3c0-2.1-1.1-4.4-3.5-4.4c-1.5,0-3.5,1.5-6.5,13.4c-1,2.9-1,4.9-1,6.8c-1.1,0.7-1.9,1.9-2.4,3
					c-0.1-0.4-0.4-0.7-0.7-1.1c0,0-0.1-0.1-0.1-0.1c-1.1-0.6-1.9-0.8-2.6-0.8c-0.7,0-1.3,0.2-1.8,0.7c-1.1,0-1.3-0.1-1.3-0.5
					c0-0.1,0-0.2-0.1-0.2l-2-4c0-0.1-0.1-0.2-0.2-0.2c-1.7-0.9-1.7-1.6-1.7-3.6c0-0.1,0-0.2-0.1-0.2c-0.3-0.5-0.5-1.3-0.7-2
					c-0.3-1.1-0.6-2.3-1.2-3.3c1.9-0.2,2.9-1.2,3.8-2.1c0.1-0.1,0.1-0.2,0.1-0.4c0-1.1-1-3.3-2.1-4.4c-0.1-0.1-0.2-0.1-0.4-0.1h-1
					c0.2-0.3,0.3-0.5,0.4-0.8c0.2-0.5,0.5-0.9,0.9-1.4c0.1-0.1,0.1-0.2,0.1-0.4c0-0.3,0.1-0.4,0.3-0.5l0.8,0.8
					c0.1,0.1,0.2,0.1,0.4,0.1h1c0.9,0,2.4,0,2.5-1.3c1.2-1.3,0.9-2.6,0-4.4c-0.1-0.2-0.3-0.3-0.4-0.3c-0.2,0-0.4-0.1-0.6-0.2
					c-0.2-0.1-0.4-0.2-0.7-0.2c-0.3,0-0.6,0.1-0.9,0.4c-0.6,0-1.3,0-1.7,0.3c-1.1-2.3-2.8-3.3-4.6-4.1v-0.5c1-1.1,2-3.1,2-5.1
					c0.2-0.8,0.4-1.5,0.6-2.1c0.7-2.3,1-3.5-0.7-5.3c-0.9-0.9-0.9-1.7-0.9-2.6c0-1.8-1.3-2.8-2.3-3.7c-0.3-0.2-0.6-0.5-0.8-0.7
					c-0.1-0.1-0.2-0.1-0.4-0.1c-0.3,0-0.4-0.1-0.6-0.4c-0.2-0.2-0.5-0.5-1-0.6c-1.4-2.6-3.7-4-6.7-4c-1.5,0-3.1,0.3-4.8,1
					c-0.7,0.2-1.5,0.4-2.2,0.4c-1.6,0-2.7-0.7-3.2-1.2c-1-2.8-3.5-4.6-5.3-5.7c0.2-0.2,0.3-0.5,0.4-0.8c0.7-0.9,0-1.8-0.5-2.3
					c-0.2-0.3-0.5-0.7-0.5-0.9c0-0.1-0.1-0.3-0.1-0.4c-1-1-3.2-2.1-5.4-2.1c-2.1,0-3.2,0-4.4,1.1c-0.5,0.5-0.8,1.3-0.9,2
					c-0.3-0.1-0.6-0.2-0.9-0.2c-2.3,0-3.4,1.2-3.5,2.4c-0.9,1.9,0.6,3.1,1.5,3.9c0.2,0.2,0.5,0.4,0.6,0.6c0,0,0.1,0.1,0.1,0.1
					c-1.1,1-1.1,2.7-1.1,4.6c0,1,0,3,1,5.1v2.1c-1.1-1.5-2.8-2.8-4.5-2.8c-1.1,0-3.1,1-4.2,2H240c-0.9,0-2.7,0-3.6-0.8l-1-1.9
					c0,0,0,0,0-0.1c-2-3-4.1-4.2-7.4-4.2c-1,0-2.1,0-3.2,1c-0.6-0.1-1.2-0.6-1.5-0.8l-0.9-0.9v-0.3c0.9,0,2-0.3,2.9-2.3
					c0-0.1,0.1-0.1,0.1-0.2c0-0.2,0.1-0.6,0.2-1c0.3-1.1,0.6-2.4-0.3-3.3c-1-1-2.1-2.1-7.2-3.1c-2-1-4-2-7-3c-3.3-2-6.7-3.1-9.4-3.1
					c-1.6,0-2.9,0.3-4,1h-1.8c-0.3-0.1-1.5-0.4-2.6-0.4c-0.7,0-1.3,0.1-1.7,0.4h-0.1c0.7-2.2,0.8-5.3,0.8-5.5c0-1-0.7-1.8-1-2.2
					v-1.8c0-0.4,0.3-0.5,0.5-0.5c0.1,0,0.2,0,0.3-0.1c3-2,4.2-3.1,4.2-6.3c0.9-1.9,0.2-4-1-5.3c0-0.2,0.1-0.3,0.3-0.5
					c0.3-0.3,0.6-0.6,0.6-1.4c0,0,0-1.2-1.1-2.4C195.2,76.7,194.2,75.7,193,75.7L193,75.7z M255.1,111.7
					C255.1,111.7,255.1,111.7,255.1,111.7c0-0.3-0.1-0.6-0.2-0.9c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0.3,0.1,0.4,0.2
					C255.5,111.3,255.3,111.5,255.1,111.7L255.1,111.7z M253.9,113.7C253.9,113.7,253.9,113.7,253.9,113.7c0.2,0,0.3-0.1,0.4-0.1
					c0.1-0.1,0.2-0.2,0.3-0.4c0,0.2-0.1,0.3-0.1,0.5H254C254,113.7,253.9,113.7,253.9,113.7L253.9,113.7z M289.5,155.7L289.5,155.7
					c0,0.3,0,0.5,0,0.5c0,0.6,0.1,1.2,0.2,1.7c-0.2-0.5-0.2-1.1-0.2-1.7V155.7L289.5,155.7z M353.4,166.9c0-0.1-0.1-0.2-0.1-0.4
					c0.2,0.1,0.4,0.1,0.7,0.1h0.5v1.3L353.4,166.9L353.4,166.9z M210.9,87.7h-2c-0.1,0-2.4,0-3.5,3.3c0,0.1,0,0.1,0,0.2
					c0,2.2,1.1,4.5,3.5,4.5c2.2,0,3.4-1.1,3.5-3.3c0.5-0.6,0.5-1.4,0.2-2.4c-0.3-0.8-0.8-1.6-1.4-2.2
					C211.2,87.8,211,87.7,210.9,87.7L210.9,87.7z M364,97.7h-1c-2.3,0-3.5,1.3-4.4,3.3c0,0.1-0.1,0.1-0.1,0.2c0,1,0,2.1,1,4.1
					c0,2,1.1,3.2,3,3.4v0.5c0,0.3,0.2,0.5,0.5,0.5c0.6,0,1.2-0.3,1.7-0.6c0,0,0,0,0,0c-0.4,0.8-0.8,1.8-1.2,2.9c0,0.1,0,0.1,0,0.2
					c0,1.6-0.8,2.5-1.5,2.5c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0.2-0.5,0.4-0.8,0.7c-1,0.8-2.2,1.8-2.3,3.5c-0.5,0.6-0.5,1.2-0.2,1.9
					c0.3,0.6,0.8,1.1,1.3,1.6c0.1,0.1,0.2,0.1,0.4,0.1h1c1.1,0,2.9-0.9,4.1-1.9c2.1,0.9,4.9,0.9,6.9,0.9c4.8,1,8,1.4,10.2,1.4
					c3.1,0,4.7-0.8,5.4-2.7c0-0.1,0-0.1,0-0.2c0-0.6-0.3-1.2-0.6-1.7c-0.2-0.5-0.4-0.9-0.4-1.3c0-0.1-0.1-0.3-0.1-0.4
					c-0.3-0.3-0.5-0.8-0.8-1.4c-0.5-1.1-1.2-2.7-3.2-4.6c-3-5-3.1-5.1-7.3-6.2c-1.1-0.2-2-0.3-2.8-0.3c-1.6,0-2.9,0.3-4.1,0.9
					c0.3-1.1,0.3-2.1,0.3-3.2c0-0.1,0-0.2-0.1-0.2C367.4,99.9,366.3,97.7,364,97.7L364,97.7z M329,99.7h-2c-0.1,0-0.3,0.1-0.4,0.1
					c-1.1,1.1-1.1,2.3-1.1,4.4c0,0.1,0,0.1,0,0.2c0,0.1,1.1,3.1,3.2,4.2c0.3,0.3,0.5,0.7,0.7,0.9c0.3,0.5,0.6,1,1.3,1.1
					c1.1,2,2.1,3,3.3,3c1,0,2.2,0,3.4-1.1c0,0,0,0,0.1-0.1c1.8-2.7,0.6-4.8-0.4-6.5c-0.2-0.4-0.4-0.7-0.6-1c0,0-0.1-0.1-0.1-0.1
					C334.2,102.7,331.2,99.7,329,99.7L329,99.7z M315.5,121.5c-1,0-1.9,0.4-2.7,1.2H312c-0.1,0-0.3,0.1-0.4,0.1
					c-1.1,1.1-1.1,3.3-1.1,4.4c0,0.1,0,0.2,0.1,0.2c1.1,2.2,2.2,3.3,3.4,3.3h1c2.2,0,3.3-1.1,4.4-2.1c0.1-0.1,0.1-0.2,0.1-0.4v-2v-3
					c0-0.2-0.1-0.4-0.3-0.4C318,122.2,316.8,121.5,315.5,121.5L315.5,121.5z M522,124.3c-1.2,0-2.5,1.1-2.5,2.9c0,1,0.9,2.9,2,3.4
					v0.4l-1.1-1.1c-0.1-0.1-1.2-1.1-2.4-1.1c-1,0-2.2,0-3.3,1.1c-2,1-2.4,3.1-1.2,5.6c0,0,0.1,0.1,0.1,0.1c1,1,3.2,2.1,5.4,2.1h1
					c1.7,0,2.7-0.6,3.2-1.4c0.1,0.1,0.1,0.1,0.2,0.2c0.5,0.7,1,1.4,2.4,2.2c0.1,0,0.1,0.1,0.2,0.1c2.3,0,3.4-2.1,3.5-3.4
					c1-2.1,1-3.2,0-5.3c0,0-0.1-0.1-0.1-0.1c-1.1-1.1-2.1-2.1-3.4-2.1h-0.3c0.3-0.9,0.1-1.5-0.1-1.9c-0.3-0.5-1-1-2.4-1.1
					C522.9,124.4,522.5,124.3,522,124.3L522,124.3z M352,148.7c-2.8,0-4.5,1.6-4.5,4.4c-0.5,1-0.5,2-0.1,2.8
					c0.5,1.2,1.7,2.2,3.5,2.7c0.1,0,0.1,0,0.2,0c2.1,0,4.4-1.1,4.5-3.4c0.8-2.6-0.4-5.1-3.3-6.5C352.2,148.7,352.1,148.7,352,148.7
					L352,148.7z"/>
			</g>
		</g>
	</g>
</g>
<g id="Foreground">
<a  href="./branches/11" >
	   <title>Оймяконский район электрических сетей</title>
		<path id="Oymyakonsky" d="M447,348.2c0-1-1-2-1-3c0,0,0,0,0-1l0,0l-1-1v-1c0,0,0-1-1-2v-1l0,0c-1-2-1-4-1-6c0,0,0-2,1-3
			c0-1,0-1,0-2v-1c0-1,0-1,0-2s0-2,0-3s0-2,1-2c0-1,1-1,1-2c0,0,1,0,1-1l1-1l0,0c0-1,0-1,0-1c0-1,1-1,1-2l-1-1l0,0v-1h-1l0,0
			c-1,0-3-1-4-2c-2-1-4-1-6-2c-4,0-5-1-7-5c-2-3-3-3-5-3c-1,0-2,0-4,0c0,0-1,0-2,0s-2-1-4-4c-1-1-2-3-3-3c0,0-1,0-2,0c-1,1-2,1-3,1
			s-2,0-2,0c-3-1-4-2-4-2h-1c-2,1-7,0-9,0c-1,0-1,0-2,0l0,0h-3h-1c0,1-1,2-2,2c-2,1-2,4-2,9c1,2,1,3,1,3l0,0c2,1,3,2,4,4
			c1,1,2,2,3,2s2,0,2,1c1,1,0,2,0,3v1l0,0c1,1,2,1,3,1c2,1,2,2,2,4v5c0,1,0,4,0,5s0,3-1,3c0,1-1,1-2,1h-1c-1,0-2,2-2,6c-1,3-1,6,0,7
			c0,1,0,2,0,3s1,2,1,3c1,0,1,1,1,1s0,0,1,0c0,0,0,0,1,0s2,1,3,3c0,0,0,1,1,1l0,0l0,0h1c1,1,2,0,2-1s1-1,3-1c0,1,0,1,1,1h1
			c1,0,1,1,2,1c0,1,1,1,2,2c0,1,1,1,1,2l0,0l0,0c0,0,0,1,1,1l0,0l0,0l0,0c0,1,0,1,0,1l0,0c1,0,1,0,1,0l0,0c0,0,0,0,0,1l0,0l0,0h1
			l0,0l0,0l0,0l0,0l0,0h1l0,0l0,0l0,0c0-1,0-1,0-1h1l0,0l0,0l0,0c1,0,1,0,1,0l0,0l0,0h1l0,0l0,0l0,0c1,0,1,1,1,1l0,0h1v1l0,0l0,0h1
			l0,0l0,0l0,0v1l0,0c0,0,0,0,0-1h1l0,0l0,0l0,0l0,0c1-1,2-1,2-1c1,0,1,0,1,1l0,0c1,0,1-1,1-2c1-1,1-2,2-2l0,0c0-1,1-1,1-1
			c1,0,1,1,1,1c1,0,1,0,1,0c1,0,1,0,2,1l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0c1,0,1,0,1,0l0,0l0,0l0,0h1l0,0l0,0h1l0,0l0,0v1l0,0l0,0
			c1,0,1,0,1,0l0,0l0,0l0,0c0,0,0,0,0,1l0,0l0,0c0,0,0,0,0,1c1,0,1,0,1,0l0,0l0,0l0,0l0,0v1l0,0l0,0l0,0l0,0l0,0l0,0l0,0h1l0,0l0,0
			l0,0l0,0l0,0l0,0l0,0c0-1,0-1,0-1l1-1h1c0-1,2-6,4-8c0-1,1-1,1-2v-1l0,0l0,0l0,0c0-1,0-2,0-2c0-1,0-2,0-3c0,0,0-1,1-1c0-1,1-1,1-1
			c0-1,0-1,0-1C448,348.2,448,348.2,447,348.2z"/>
	</a>
  <a  href="./branches/9" >
	   <title>Момский район электрических сетей</title>
		<path id="Momsky" d="M466,311.2c0-1,0-1,0-2l0,0c1,0,1,0,1,0c0-1,0-1,0-1c0-2-1-2-2-4l0,0c-1-1-1-1-1-2c0,0,0,0,0-1l0,0l0,0h1l0,0
			l0,0l0,0l0,0l0,0l0,0v-1l0,0l0,0c0,0,0,0,0-1l0,0l0,0l0,0c-1-2-2-5-1-9c0-3,0-6,0-7c-1-3-4-6-7-8c-8-4-12-11-11-16c0-3,2-4,4-5
			s3-2,3-3s-1-2-1-3c-1,0-2-2-2-4c-1-1-1-2-2-3c0-1-1-1-1-2l0,0l0,0l0,0l0,0c-2,1-3,1-6,0h-2v2l-1,4c0,1-1,1-1,1h-4c-3,0-4,0-5,1
			c-2,2-2,2-3,5c0,1-1,4-1,5c-1,1-2,1-3,1s-3-1-6-3c-5-2-12-6-13-8c-1,0-1-1-2-1s-3-1-4-3c-2-2-3-3-5-3h-1l0,0l0,0c0,0,0,0-1,0l0,0
			l0,0c0,1,0,2-1,2c-1,1-1,2-2,2c-1,1-2,3-3,3c-1,1-2,1-3,2c-1,0-2,1-3,1s-2-1-2-1c-2-1-3-1-4-1l-3-1c0,1,0,2,0,2c0,1,1,3,1,4
			s3,5,4,5c3,1,7,4,8,6h1c2,1,3,2,4,3c2,2,1,3-1,5c-2,1-2,1-3,4c0,0,0,1,0,2c0,0,0,0,1,0c1,1,2,2,2,4v1c0,0,0,0,0,1l0,0l0,0
			c1,1,2,2,3,3l2,2l0,0c1,0,3,1,4,1c3,0,5,0,5,0c1-1,2-1,3-1l1,1c0,0,0,0,1,0c0,0,2,1,2,2c1,0,2,0,3-1c1,0,2-1,3-1c0,0,0,0,1,0
			s2,2,4,4c1,3,2,3,2,3c1,0,1,0,1,0c3,0,5,0,6,0c3,1,4,2,5,4c2,4,2,4,5,4h1c3,1,7,3,11,5c0,0,1,0,2,0s2,0,3,0l1,1h2c1,0,2,0,2-1l0,0
			c0-1,1-1,1-2l0,0c0,0,0,0,1,0l0,0l0,0l0,0h1l0,0l0,0l0,0l0,0l0,0l0,0h1l0,0l0,0l0,0v1l0,0l0,0l0,0l0,0l0,0l0,0c0,1,2,3,3,3
			S466,312.2,466,311.2z"/>
	</a>
	<a  href="./branches/7" >
	   <title>Зырянский район электрических сетей</title>
		<path id="Verkhnekolymsky" d="M503,292.2L503,292.2L503,292.2L503,292.2L503,292.2L503,292.2c0-1,1-1,1-1l0,0l0,0l0,0c0-1,0-1,0-1
			l0,0l0,0v-1h-1l0,0v-1v-1l0,0c0-1,0-1,1-2l1-1c0-1,0-1,0-1c0-1-1-1-2-1c0,0-1-1-1-2s-1-2-1-3v-1v-1c0-2,1-4,2-4c0,0,1-1,2-3
			c1-1,2-1,2-2c0,0,0-1-1-1l0,0h-1l0,0c0-1,0-1,0-1l0,0v-1c0,0,0,0,0-1s0-2,0-3v-1l0,0l0,0v-1l0,0l0,0l0,0c0,0,0,0-1-1
			c-4-2-8-4-11-5l0,0c-3,0-4-1-6-3c-2-3-6-6-8-6c-1,0-4-2-5-3c-2-3-3-3-11-5c-4-1-9-3-11-3c0,0-1,0-2-1l0,0l-1,3c0,2-1,3-1,4l0,0
			l0,0l0,0l0,0l0,0v1l0,0l0,0c1,1,1,1,2,2c0,0,2,3,2,5c0,0,0,1,1,2c0,1,2,3,2,4c-1,2-3,3-4,4c-3,2-3,2-3,4c-1,5,2,10,10,14
			c4,2,7,7,8,10c0,2,0,5-1,7c0,4,0,5,1,6c0,1,0,1,0,2c0,0,0,0,1,0l0,0l0,0l0,0c0,0,0,0,1,0c0,1,0,1,0,1h1v1l0,0h1l0,0l0,0l0,0h1l0,0
			c0,0,0,0,0-1l0,0h1l0,0l0,0h1l0,0l0,0h1v-1h1l0,0l0,0l0,0h1l0,0v-1l0,0h1l0,0l0,0l0,0l0,0l0,0c0-1,0-1,0-1l0,0c0,0,0,0,1-1l0,0
			l0,0l0,0l0,0l0,0l1-1l0,0l0,0l0,0c1,0,1,0,1,0l0,0l0,0h1l0,0l0,0c0,0,1,0,1,1c0,0,0,0,1,0c0,0,0,0,0,1l0,0c1,0,1,0,1,1
			c0,0,0,1,1,1c0,1,1,2,3,2l0,0h1v-1c-1-2,1-3,3-4l1-1c0,0,1,0,2-1l0,0c0-1,0-1,1-1c1-1,2-2,3-2h1l0,0l0,0c0-1,0-1,0-1l0,0l0,0l0,0
			l0,0l0,0c1,0,1,0,1,0l0,0c0,1,0,1,0,1l0,0c1,0,1,0,1,0l0,0c0,0,0,0,0,1l0,0c0,0,0,0,1,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0
			C503,292.2,503,292.2,503,292.2z"/>
	</a>
	<a  href="./branches/18" >
	   <title>Белогорский район электрических сетей</title>
		<path id="Abyysky" d="M390,204.2L390,204.2c-1,1-2,3-2,4c-1,2-1,2,1,8c1,0,1,1,1,2l0,0l0,0l0,0c-1,1-1,2-1,3c-3,5-3,7-3,12
			c0,3,1,4,1,4c1,1,1,1,2,1l0,0c0,0,1-1,2,0c2,0,4,2,6,4c1,1,2,2,3,2s3,1,3,2c1,2,14,9,19,10l0,0c0-1,1-2,1-4c1-3,1-4,3-6
			c3-2,4-2,8-2h2l1-2l1-4c0-1,0-1,1-1l3,1c1,0,2,0,3,0c0-1,1-1,1-1s0,0,1,0c0-1,0-3,0-4c1-3,2-3,2-4l0,0l0,0c-1-1-2-2-2-3
			c-2-2-3-4-5-4c0-1-3-2-2-4c0-1-1-2-1-2c-2-3-2-4-1-5c0-1,1-2,3-1c1,0,5-2,5-3c1-1,1-1,0-2c0,0,0-1-1-1h-2c0,0,0,0-1-1c0,0,0,0,0-1
			l2-5c1-4,0-5,0-5v-1h-1c-1,1-4,3-6,4c-4,2-5,3-5,4c-1,1-2,2-4,3c0,0-1,0-2,0s-2-1-3-2c0-1-2-1-4-2c-2,0-3,0-4,0c-1,1-3,2-4,3
			c-2,0-2,0-3,0s-2-1-2-1c-1-1-2-2-5-2c-4-1-4-1-6,1c0,1-1,1-2,2l0,0h-1C392,202.2,391,203.2,390,204.2z"/>
	</a>
	<a  href="./branches/14" >
	   <title>Среднеколымский район электрических сетей</title>
		<path id="Srednekolymsky" d="M540,237.2l4-2l-2-3c0-1-1-2-1-3s0-1,0-1c0-1,0-2,0-3s-1-3-1-4s-1-1-1-2c0,0,0-1-1-1c0-2-1-2-4-2
			c-3-1-5-1-6-2l0,0c-1-1-1-1-1-1c1,0,1-1,0-1l0,0l0,0l0,0c-1,0-1-1-1-1s0,0-1-1l0,0c0-2,0-3,0-4c1-1,1-1,2-2c0,0,1,0,1-1
			c0,0,0,0,0-1c-1,0-1,0-2-1c0,0-1,0-1-1l0,0c0-1,0-1,0-1c1-1,1-2,2-4l0,0l0,0c0,0,0,0,0-1l0,0l0,0l0,0l0,0c-1-1-2-2-2-3
			c-1-2-1-2-5-3h-1c-2,0-4,0-5,0c-2,1-3,1-5,1c-1,0-1,0-2,0c0,0-1,1-2,1c-1-1-1-1-2-1c0,0,0-1-1-1c0,0,0,0-1,0h-1c-1,0-2,0-2-1h-1
			c-3-1-6-1-7,0s-4,0-5,0s-4-1-5-2c0-1,0-1-1-1l0,0c-2,1-8,0-8,0c-3,0-3-1-4-1c0-1-1-1-2-2c0,0-1-1-2-1c0,0,0,0-1,0l-1,1
			c-5,3-5,3-7,4c-1,1-5,4-8,5l0,0l0,0l0,0c1,2,1,4,0,8l-1,4l0,0c2,0,3,2,4,3c0,2-2,5-5,6c-1,0-3,1-4,1s-1-1-1,0c0,0,0,1,1,2s2,3,2,5
			l0,0c0,0,0,0,1,0c2,1,4,4,5,5l2,4l0,0c1,0,1,1,1,1s0,0,1,0s4,1,11,3c8,2,9,2,13,5c1,1,2,2,3,2c3,1,8,4,10,7c1,2,1,2,4,3l0,0
			c3,0,6,2,12,5c0,0,1,1,1,2h1l0,0l0,0h1l0,0l0,0c0,0,0,0,1,0v1l0,0h1l0,0l0,0v-1l0,0l0,0l0,0h1l0,0l0,0l0,0v-1l0,0c0,0,0,0,0-1
			s-1-1-1-2c-1-1-1-2-1-4c1-2,1-2,3-1c1,0,2,0,2-1l1-1l0,0h1c3,0,12-2,12-3c0,0,0,0,1-1l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0h1l0,0l0,0
			l0,0l0,0l0,0h1l0,0l0,0l0,0h1l0,0l0,0l0,0l1,1l0,0l0,0h1l0,0l1,1l0,0l0,0c0,0,0,0,1,0l0,0l0,0l0,0l0,0l0,0l0,0v-1l0,0l0,0l0,0v-1
			l0,0c0,0,0,0,1,0C537,240.2,538,239.2,540,237.2z"/>
	</a>
	<a  href="./branches/15" >
	   <title>Чокурдахский район электрических сетей</title>
		<path id="Allaikhovsky" d="M399,133.2L399,133.2v2c-1,2-2,4-4,5c-1,1-2,2-3,2c0,1-1,2-2,5c-1,5-1,7-3,8c-1,1-2,6-1,7c3,4,2,5,0,9
			c-2,2-3,4-4,7c0,2-1,6-2,9c0,1,0,2-1,3l0,0c1,1,2,1,3,2c1,0,2,1,3,1c3,1,4,2,3,6h1l0,0c1,0,2,0,2,0l0,0h1c0,0,1-1,2-1c3-3,3-3,7-2
			c4,0,5,1,6,2c0,1,0,1,1,1s5-1,6-2s4-1,5-1s4,1,6,3c0,1,1,1,1,1c2,0,3,0,4-2c0-1,3-3,6-4c2-1,3-2,5-3c0-1,1-2,2-2s1,0,1,0h1
			c1-1,2-1,3-2c2-1,3-2,5-3c1-1,2-2,6-4c1-1,2-1,2-1l0,0c1-1,1-1,2-2c0,0,0-1,0-2s0-3,0-4c-1-1-1-2-1-3s0-2-1-2c-1-2-1-4-1-6
			c0-1,0-2,0-3s0-1,0-1l0,0v-1l0,0c0,0,0,0-1,0l0,0v-1l0,0l0,0l0,0h-1v-1l0,0l0,0h-1l0,0l0,0c-1,0-2-1-2-1h-1v-1c-1,0-1-1-2-1
			c0,0-1-1-1-2c0,0-1,0-1-1l-2-2l-8-1l-8-1v-2c0-1,1-2,1-2c1-1,2-1,2-2c0-2-1-2-7-3h-1l-3-1l0,0h-1l0,0h-1l0,0l0,0h-1l0,0h-1l0,0
			l0,0l0,0h-1l0,0l0,0h-1l0,0l0,0h-1l0,0l0,0l0,0h-1l0,0v1c-1,0-5,0-10-1H399z"/>
	</a>
  <a  href="./branches/10" >
	   <title>Нижнеколымский район электрических сетей</title>
		<path id="Nizhnekolymsky" d="M462,155.2h1l0,0v-1h1l0,0l0,0c1,0,1-1,1-1c5-2,5-2,11-7c1,0,1-1,2-1c0,0,0-1,1-1l1-1c1,0,1,0,2-1
			l0,0c1,0,1,0,1,0c2,0,3-1,4-1c0,0,3-2,6-3h1l0,0h1l0,0l1-1l0,0l0,0h1l0,0l0,0h1l0,0l0,0h1l0,0l0,0h1l0,0l0,0h1l0,0l0,0h1l0,0l0,0
			h1l2,1c8,1,11,2,11,5v1l1,1c0,1,0,1,1,2c0,0,1,1,1,2c-1,1,0,2,0,4c0,0,0,0,0,1s1,1,1,2h1h1l0,0l1,1l0,0l0,0l0,0h1l0,0h1l0,0l0,0h1
			l0,0l0,0h1l0,0c0,0,0,0,1,0l0,0l0,0h1v-1l0,0c1,0,1,0,1,0h1c1-1,2-1,3-1l0,0h1v-1h1c1-1,1-1,2-1l3-3l1,2c0,1,1,3,2,3c0,1,1,2,2,2
			l0,0c1,1,1,1,1,3c0,1,1,3,3,4c1,0,2,1,1,3c-1,1-1,1,0,2l0,0c2,0,3,1,3,5c0,2,0,3-2,4c-1,0-2,0-4,0c-1,0-4,2-4,3c0,2-4,5-6,4
			c-1,0-1,1-2,1v1l0,0l0,0l0,0l0,0l-1,1l0,0l0,0h-1v1l0,0h-1l0,0l-1,1l0,0c-2,1-3,3-4,3l0,0c0-1-1-2-1-3c-2-2-3-3-6-4h-1
			c-3,0-5,0-7,0c-1,1-2,1-3,1s-2,0-4,1l0,0c0,0-1,0-1-1c-1,0-2,0-3-1h-1c-1,0-1,0-1,0c0-1-1-1-3-1c-2-1-7-1-8,0c0,0-1,1-3,0
			c-3,0-4-1-4-1c-1-1-2-2-2-2c-1,0-2,0-2,1c-1,0-4,0-7-1c0,0-1,0-2,0c-1-1-2-2-3-3c-1,0-1,0-2,0l0,0c0-1,0-1,0-1l0,0l0,0l0,0
			c1-1,1-1,1-2c1-2,1-4,0-5c0-1,0-1,0-2s-2-4-2-4c-1-2-1-3,0-4c0-2,0-4-1-5C462,155.2,462,155.2,462,155.2L462,155.2L462,155.2
			L462,155.2L462,155.2z M523,128.2c0,0,0,0,0-1l0,0c-1,0-1,0-1,0C522,128.2,522,128.2,523,128.2L523,128.2z M516,134.2L516,134.2
			c1,0,1,0,1,0l0,0l0,0l0,0l0,0v1h1l0,0l0,0l0,0l0,0h1l0,0l0,0h1l0,0l0,0l0,0l0,0l0,0l0,0c1,0,1,0,1,0l0,0l0,0l0,0c0-1,0-1,0-1
			s-1-1-2-2l-1-1l0,0h-1l0,0l0,0l0,0l0,0l0,0c0,0,0,0-1,0c0,1,0,2,0,2C516,134.2,516,134.2,516,134.2L516,134.2z M525,130.2
			L525,130.2L525,130.2L525,130.2h-1l0,0l0,0c0,1,0,2,1,3v1l0,0l0,0l0,0v1l0,0l0,0h1l0,0l0,0l0,0l0,0v1l0,0l0,0l0,0l0,0l0,0h1v-1
			l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0c0-1,0-1,0-1c1-1,1-1,0-2v-1c0,0,0,0-1-1l0,0C526,130.2,526,130.2,525,130.2L525,130.2
			L525,130.2z"/>
	</a>
	<path id="Ust-Maysky" d="M397,367.2c0-1,0-1,1-1l0,0c0-1,0-1,0-2l0,0c0,0,0-1-1-1l0,0v-1l0,0l0,0c0,0,0,0,0-1l0,0l0,0v-1l0,0
		c-1,0-2-1-2-2l-1-1c-1,0-1,0-1,0c0,1,0,1,0,2c0,2-5,3-8,3c-1-1-2-2-2-2c-1,0-2,1-3,3c-1,0-3,4-5,4c-1,0-3,4-3,7c0,1,0,3-2,4
		c-2,2-5,1-11,1l-6-1l-2,1c-1,2-1,2-2,2c-1,1-2,1-3,1s-2,1-3,2l-1,1l0,0c0,0,0,0-1,0l0,0c0,1,0,3-1,4c-1,2-3,3-6,2c-1,0-3,1-5,1
		c-1,0-2,1-3,1c0,1,0,2,0,3c-1,2-1,3,0,5c0,4-2,6-5,7s-3,1-3,3c-1,1-1,3-4,4l0,0c0,0,0,1,0,2c-1,2-1,3-2,4c-1,0-2,0-3,0c0,1,0,1,0,2
		c0,2,0,3,0,5c0,1,0,2,1,3c0,0,1,3,1,4c1,1,3,3,4,4c2,0,3,2,4,2c0,1,0,1,1,2c0,0,0,0,0,1h1l0,0l0,0c0-1,0-1,0-1l0,0l0,0l0,0
		c0,0,0,0,0-1s4-6,5-7h1l0,0l0,0l0,0c0,0,0,0,1,0l0,0l0,0h1l0,0l0,0h1l0,0h1l0,0h2l0,0l1,1l0,0h1h1l0,0h1h1h1l1,1h4l1,1h4l1-2
		c0-2,1-3,2-5c1-1,2-2,7-1s6,1,9-1c1,0,3-1,4-1s2-1,2-2c-1-3,1-4,4-5h1l0,0l0,0h1l0,0l0,0v1l0,0l0,0l0,0c1,0,1,0,1,0l0,0
		c0,0,0,0,0,1v1c1,0,1-2,0-7c0-3,0-5,0-10c1-1,1-1,1-2v-1c0-1,0-1,0-2v-1l0,0c-1,0-1,0-1,0c-1-1-1-2-1-5c2-6,3-9,4-8c1,0,2-1,2-2
		c1-1,3-2,4-3c2-2,4-6,4-9c1-1,1-2,2-3c0,0,0,0,0-1l0,0h1l0,0c0,0,0,0,0-1l0,0l0,0l0,0c0-1,0-1,0-1c1,0,0,0,0,0c1,0,1,0,1,0
		c0-1,0-1,0-1H397z"/>
	<path id="Tomponsky" d="M383,284.2L383,284.2c-2,0-4,0-6-1c-6-1-6-1-9,1c-2,1-4,2-5,1c-2,0-3-1-4-2s-4-2-6-2c-1,0-2,0-2,0
		c-1,1-2,1-3,3c-2,2-5,4-7,5c-5,3-7,5-7,8c0,2-1,4-2,5c-2,0-3,0-6-1c-3,0-4,0-5,0c0,1-1,2-1,3c0,3,0,3,1,4c1,2,2,4,1,5c0,1-1,1-1,1
		c0,1,0,1,0,1l0,0l0,0c1,1,1,3,1,8c-1,5-1,6-3,9c-2,2-4,4-6,5c3,1,4,1,6,3c0,1,1,3,1,4l0,0l0,0l0,0l0,0c1,0,3,0,6,0h1c1,0,2,0,3,0
		l0,0c1,0,1,1,2,1l0,0l0,0c0,1,1,2,0,2c0,1,1,1,1,1c1,1,1,2,0,5c-1,5,1,7,7,8c2,1,5,1,5,4l0,0c0,1,1,2,2,3c1,2,1,4,1,6s0,3,0,3
		c1,0,1,1,2,2c0,0,0,0,0-1l2-2c0,0,0,0,1,0l7,1c6,1,7,1,8,0c1,0,2,0,2-3s3-7,5-9c1-1,2-2,3-3c2-3,3-4,5-4c0,0,1,0,1,1c1,0,1,0,2,1
		c1,0,3,0,4,0c1-1,1-1,1-2l1-1l0,0c0,0,0,0-1-1l0,0c0,0-1-1-1-2c-1-1-2-3-2-4c0,0,0-1,0-2c-1-3,0-11,2-14c2-1,3-1,4-1c0-3,1-5,1-8
		c0-1,0-3,0-4s0-1,0-1c-1,0-1,0-1,0c-1-1-2-1-3-1c-2-2-2-3-1-5v-1l0,0c-2,0-4-1-5-3c0-1-1-2-2-2c-3-1-3-3-3-6c-1-3-1-8,2-10
		c1,0,1-1,1-1c1-2,3-2,4-2h1c-1,0-1-1-2-2c-1,0-1-1-2-2c0,0,0,0-1,0l0,0C383,284.2,383,284.2,383,284.2z	">

	</path>
	<a  href="./branches/5" >
	   <title>Верхоянские электрические сети</title>
		<path id="Verkhoyansky" d="M385,269.2c0-1-1-2-2-3c-2,0-3-1-4-2c0,0-3-3-6-4s-6-6-6-8c0-1,0-1,0-1c-1-1-1-2-1-3s0-2,0-2v-1l1-3
			c1-2,0-5-2-8c-1-1-2-3-2-5c0-1-1-3-2-4c-2-2-3-4-4-6c0-1,0-1-4-2c-2,0-8-1-7-7c0-1,0-2-1-3c0-1,0-3,0-4c0-2,0-2-2-2c0,0-1,0-2-1
			c-3,0-4,1-4,1l-2,1c0,1,0,1-1,1l0,0l0,0l0,0l-1-1l-1-2c-1-2-2-2-3-2c0,0,0-1-1,0c0,1-4,4-5,4c-1,1-2,1-2,1c-2,1-3,1-3,2
			c-2,2-2,3-4,9c0,2-1,5-2,7c1-1,1-1,2-1l1,1c6,5,8,6,7,8c0,1,0,2-1,3c0,1-1,2-1,3c0,2-1,4-3,5c-4,2-6,6-7,7c0,2-2,4-6,6
			c-5,3-5,3-6,5c0,3-1,4-2,5s-3,6-2,9c0,2,1,3,1,4s-1,1-1,2l0,0c0,0,0,0,1,1s3,3,4,4s2,2,3,3c3,1,7,9,6,13c1,1,1,2,2,3c1,2,2,4,2,6
			s0,3,1,4s1,2,2,2c0,0,1-1,3-1c0,1,1,1,1,1l0,0c1-1,1-1,1-1c0-2,0-2-1-3c-1-2-2-3-1-6c1-5,4-5,8-4c2,0,4,0,5,0c0,0,0-1,1-2
			c0-5,2-7,8-10c2-2,4-3,6-5c0-1,3-3,4-4c1,0,2,0,3,0c2,0,5,1,6,3c1,1,2,1,4,1c1,1,2,0,4-1c3-1,5-1,9-1c1,0,2,0,2,0c2,1,3,1,5,2l0,0
			c0-1,0-1,0-1c0-2,0-2-1-2s-2-2-2-3c0-3,1-5,3-6C384,270.2,385,269.2,385,269.2z"/>
	</a>
	<a  href="./branches/17" >
	   <title>Янские электрические сети</title>
		<path id="Ust-Yansky" d="M351,156.2c-1-1-1-1-1-3c0-1,0-2,2-2c1,1,1,2,1,4C353,156.2,352,156.2,351,156.2z M312,181.2c0,2,0,3,0,5
			c0,1,1,2,3,2c4,1,5,2,5,7c0,4,1,4,1,4c1,0,2,0,2,1c2,0,3-1,4-3c0,0,1-2,3-1c1,0,2,1,4,3l1,2v-1c2-1,4-2,6-2c1,0,2,1,2,1
			c4,0,4,3,4,5c0,1,0,2,0,2c1,2,1,3,1,5c-1,1-1,3,5,4c4,1,5,1,6,3s2,4,3,6c2,1,3,4,3,6c0,1,0,1,0,2c1,1,3,4,3,4c2,4,2,5,1,8v1l0,0
			c0,0,1,0,2,1c2,0,4,1,6,2c0,0,0,0,1,0c0,0,0,0,1-1s2-1,4-2l1-1c1-2,2-3,4-4l0,0l0,0l0,0c-3-1-3-4-4-7c0-5,0-7,3-12l1-3l-1-3
			c-2-4-2-5-1-7c1-3,2-5,4-7l0,0c-1,0-4,0-3-3c0-2,0-2-2-2c-1-1-3-1-4-2c-1,0-1-1-2-1c-2-1-2-3-1-7c1-2,2-6,3-8c0-3,1-5,3-8
			c3-3,3-3,1-5c-2-4-2-8,1-11c1-1,1-2,2-6c2-6,3-7,4-7c1-1,2-1,2-2c2-1,3-2,3-4v-2h-2c-1,0-4,0-7,0h-1l0,0h-1l0,0h-1l0,0h-1l0,0h-1
			l0,0h-1h-1l0,0h-2l-1-1l0,0h-1l0,0h-1h-1l0,0l-1-1h-1l0,0c-1,0-1,0-2-1l0,0l0,0h-1l0,0l0,0l0,0l0,0h-1l0,0l0,0l0,0v1l0,0l0,0l0,0
			l0,0l0,0l0,0l0,0v1l0,0l0,0l0,0l0,0l0,0v1l0,0l0,0l0,0l0,0c2,2,2,2,1,4s-1,3-2,2c-1,0-2,0-2,0c1-1,0-1-1-1c-1-1-4,3-4,7
			c-1,0-1,0-1,0l0,0v1l0,0l0,0l0,0c0,0,0,0,0,1l0,0l0,0l0,0l0,0l0,0h1l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0
			c0,0,0-1,1-1c0-1,1-1,3-1c2,1,3,2,1,2c0,1-1,1-1,2s0,2-1,2s0,1,0,2c1,1,2,2,1,3c0,2,0,3,1,4s1,1,1,2s-1,1-2,1c0,0-1,0-1,1l0,0l0,0
			l0,0l0,0c0,0,0,0,0,1l0,0c-1,0-1,0-1,0l0,0l0,0l0,0l0,0l0,0h-1l0,0c0,0,0,0,0-1l0,0l0,0l0,0c0,0,0,0-1,0l0,0l0,0v-1l0,0l0,0l0,0
			l0,0v-1c0-1-1-2-1-2h-1l0,0v-1l0,0l0,0l0,0l0,0l0,0c0,0,0,0-1,0l0,0l0,0l0,0l0,0l0,0v1h-1l0,0l0,0l0,0l0,0h-1v1l0,0c-3,2-3,3-1,4
			c1,1,1,2,1,4s0,3-1,4l0,0l0,0l0,0l0,0l0,0h-1v-1l0,0l0,0l0,0l0,0l0,0h-1l0,0v-1l0,0h-1l0,0l0,0l0,0v-1l0,0c0,0,0,0-1,0l0,0v-1l0,0
			l0,0l0,0l0,0c0,0,0,0,0-1h-1c0-2-6-5-10-6h-2l0,0l0,0l0,0l0,0h-1l0,0l0,0l0,0l0,0l0,0l0,0h-1l0,0l0,0l0,0l0,0l0,0l0,0l0,0
			c-1,0-1,0-1,0v1l0,0l0,0l0,0l0,0l-1,1c-1,1-3,2-4,2c-1,1-2,1-2,2c0,0-1,0-4,0c-3-1-3-1-5-5c-2-2-3-4-3-5l0,0v-1l0,0l0,0l0,0l0,0
			l0,0h-1v-1l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0h-1c0,1,0,1,0,1l0,0l0,0l0,0l0,0l0,0v1c0,0,0,0-1,0l0,0l0,0c0,1,0,1,0,1l0,0v1
			l0,0c0,0,0,1-1,1l0,0c0,0,0,0,0,1l0,0c0,0,0,0,0,1l0,0c0,0,0,0,0,1l0,0l-1,1l0,0c0,1,0,1,0,1s0,0,0,1c0,0,0,0,0,1l0,0c0,1,0,1,0,1
			l0,0l0,0c-1,1-1,1-1,1l0,0c0,0,0,0,0,1v1l0,0l0,0l0,0c0,1,0,1,0,1l0,0v1l0,0c0,0,0,0,0,1l0,0C312,174.2,313,177.2,312,181.2
			L312,181.2z"/>
	</a>
	<path id="Novosibirskiye_Ostrova" d="M345,60.2c1,0,1,0,1,0l0,0c0,0,0-1,1-1l0,0l0,0l0,0h1l0,0c0,0,0,0,0,1l0,0c1,0,1,0,1,0l0,0
		l0,0l0,0c1,0,1,0,1,1l0,0l0,0h1v1l0,0l1,1l0,0l1,1c1,1,2,3,3,4c1,0,1,0,1,0l0,0c0,1,1,1,1,1l0,0h1l0,0l0,0h1l0,0l0,0l0,0l0,0
		c0,1,0,1,1,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0c0-2,0-7-1-8c-2-2,0-4,2-4c3,1,5,2,6,4v1l0,0c1,0,1,0,1,0l0,0l0,0l0,0h1v1l0,0l0,0h1
		l0,0l0,0h1h1l0,0l0,0l0,0h1l0,0l0,0h1l0,0l0,0l0,0l0,0h1l0,0l0,0l0,0l0,0l0,0c1,0,1,0,1,0l0,0l0,0h1l0,0l0,0l0,0l0,0l0,0h1l0,0l0,0
		l0,0h1v1l0,0h1l0,0c6,3,6,3,5,4s-2,4-1,6c0,2-1,4-2,4s-1,0-2,1l0,0l0,0h-1l0,0l0,0l0,0c-1,0-1,0-1,0l0,0l0,0h-1l0,0l0,0h-1v-1l0,0
		l0,0c-1,0-1,0-1,0v-1l0,0h-1l0,0v-1c0,0,0,0-1,0c-2-3-2-4-1-6c0-3,0-4-1-4h-1l0,0l0,0l0,0l0,0h-1l0,0l0,0l0,0l0,0h-1l0,0l0,0l0,0
		l0,0l0,0h-1v1l0,0l0,0l0,0l0,0l0,0v1l0,0h-1l0,0l0,0l0,0c0,1,0,1,0,1c0,2,2,7,5,9c1,1,2,2,3,2c4,1,4,2,2,3s-3,1-3,2c0,0-2,0-4,0
		c-5-1-12,1-15,4c0,1,0,1,0,1l0,0h-1v1l0,0l0,0c-1,0-1,0-1,0l0,0c0,0,0,0,0,1l0,0c-1,0-1,0-1,0l0,0l0,0l0,0h-1l0,0l0,0l0,0l0,0l0,0
		c0-1,0-1-1-1l0,0l0,0c0,0-1-1-2-1s-3-2-5-5c-3-4-4-4-3-7c0-2,0-3-1-4s-1-4,1-4c0,0,1-2,1-3C340,64.2,342,62.2,345,60.2z M393,67.2
		c-1,5,0,6,5,7c3,1,5,2,7,3l1,1l0,0l0,0h1l0,0l0,0l1,1l0,0l0,0l0,0h1l0,0l0,0c4,0,7-2,9-5l3-2l-2-2c0-1-1-2-1-2l0,0l0,0v-1l0,0l0,0
		l0,0l0,0l0,0l0,0l0,0h-1l0,0l0,0l0,0c-2,0-5,0-7,1c-1,1-2,1-3,1c-2,0-2,0-1-2l0,0c0,0,0,0,0-1l0,0l0,0l0,0l0,0l0,0l0,0c0-1,0-1,0-1
		s0,0-1,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0h-1l0,0v1l0,0h-1l0,0l0,0h-1v1l0,0h-1h-1v1h-1l0,0l0,0h-1l0,0v1l0,0h-1l0,0l0,0l0,0l0,0h-1
		l0,0l0,0l0,0l0,0l0,0l0,0l0,0l-1-1l0,0l0,0l0,0l0,0l0,0v-1l0,0h-1l0,0l-1-3V67.2z M329,77.2L329,77.2L329,77.2L329,77.2L329,77.2
		c0,1,1,1,1,1l0,0l0,0l0,0h1c1,0,1-1,2-3c0-2,0-3,0-3c-1,0-1-1-1-2l-1-1l0,0l0,0l0,0l0,0l0,0l0,0l0,0v-1l0,0l0,0l0,0l0,0l0,0l0,0
		c0,0,0,0-1,0C329,69.2,328,75.2,329,77.2L329,77.2z M374,107.2c-4-1-6,1-8,5c0,3-1,4-3,5c-1,1-2,2-2,2l0,0l0,0v1l0,0l0,0l0,0l0,0
		l0,0l0,0h1l0,0l0,0l0,0l1-1l0,0l0,0l0,0l0,0l0,0h1l0,0l0,0c0-1,4-1,8,0c9,2,12,2,13,1c0-1-1-1-1-1c0-1-1-1-1-1c1-1-1-3-2-6
		C378,108.2,377,108.2,374,107.2L374,107.2z M330,106.2c0,0,1,2,2,3l1,1l0,0l0,0l0,0l0,0l0,0l1,1l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0
		l0,0h1l0,0l0,0l0,0l0,0l0,0l0,0c1-1,1-2-1-5c0,0-1,0-1-1l0,0l0,0l-1-1l0,0l0,0l-1-1l0,0l0,0h-1v-1l0,0h-1l0,0l0,0h-1
		C327,103.2,329,106.2,330,106.2L330,106.2z M361,104.2l1,1l0,0l0,0l0,0l0,0v1l0,0l0,0h1l0,0l0,0l0,0l0,0l0,0l0,0l0,0c0,0,0,0,1,0
		l0,0l0,0l0,0l0,0l0,0c1,0,1,0,1,0s0,0,0-1l0,0l0,0h1l0,0l0,0c0-1,0-1,0-1l0,0l0,0l0,0c0-1,0-1,0-1l0,0l0,0l0,0c0-1,0-1,0-1l0,0l0,0
		l0,0c0,0,0,0,0-1c0,0,0,0-1,0l0,0l0,0l0,0l0,0c0-1,0-1,0-1l0,0l0,0h-1l0,0l0,0l0,0l0,0C361,101.2,360,102.2,361,104.2L361,104.2z"
		/>
	<path id="Nerungri" d="M308,489.2c0-1-1-2-1-3c0,0,0-1-1-2h-1l0,0v-1l0,0l0,0l-1,1c-1,1-3,2-5,4l-2,1c-1,0-1,0-2,0l-2-2
		c-1-1-2-1-3-1s-2-1-4-1c-4,0-6,0-7,0c-1,1-2,1-2,1c-1,0-2-1-2-2c0,0-1,0-2,0l0,0c-1,0-2,1-6,1s-7-1-10-1c-1,0-2,0-3-1c-2-1-2-3-2-5
		c0-1,0-1,0-2c-1-1-1-1-2-1c-1-1-3-1-4-2c-3,0-4-1-6-4c-1-1-1-2-2-2s-2,0-4,1c-3,1-4,1-6,1c-1-1-2-1-3-2c-3-2-4-6-1-10c0-1,1-2,1-3
		c1-2,2-3,3-3c0-1,1-1,1-2c-1,0-1,0-1,0c-2,0-3,0-7,2l-4,3c-1,1-1,1-1,1l-3-1c0,2-1,3-4,5c-3,3-3,3-3,5c0,3-1,5-2,6c-1,0-1,2-2,4
		c0,3-1,4-3,5c-1,1-3,2-4,1c-2,0-4-1-6-4s-3-4-5-5c-3-1-5-2-7-2c-5-1-9,0-10,0c0,1,0,1-1,1l0,0c0,1,0,1-1,2c-1,0-1,0,0,1s1,2,1,2
		s0,0,0,1l0,0l0,0l0,0c0,0,0,0,1,0l0,0l0,0l0,0l0,0l0,0h1l0,0l0,0l0,0h1l0,0l0,0l0,0h1l0,0l0,0l0,0h1v-1h1l0,0l0,0c0,0,0,0,1,0l0,0
		l0,0l0,0h1l0,0l0,0h1l0,0l0,0h1h1v1h1l0,0h1h1c6,1,7,2,7,5c0,1,1,2,3,2c2,1,4,1,4,2c1,1,1,1,2,1c0,1,1,2,2,3l0,0l0,0c1,0,1,0,1,1
		l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0h1l0,0l0,0l0,0l0,0l0,0l0,0c0,0,0,0,1-1l0,0l0,0l0,0c0,0,0,0,1,0l0,0l0,0l0,0
		h1l0,0v1h1l0,0l0,0l1,1c1,1,2,2,5,2c4,1,5,1,6,4c1,1,1,3,1,3c0,1,1,2,2,2c0,0,1,1,1,2s1,2,6,3c5,0,6,1,6,2l0,0l0,0h1l0,0l0,0l0,0
		l0,0v1h1l0,0l0,0c1,0,1,0,1,0l0,0h1l0,0c0,0,0,0,1,0l0,0l0,0l0,0h1l0,0l0,0v1c0,0,0,0,1,0l0,0l0,0l0,0v1l0,0l0,0c1,1,1,2,2,2
		c1,1,2,1,3,1l0,0l0,0l0,0l0,0l0,0l0,0l0,0c0-1,0-1,0-1l0,0l0,0h1l0,0c0-1,0-1,0-1l0,0c0,0,0,0,1,0l0,0l0,0l0,0l0,0c0,1,0,1,0,1h1
		l0,0l0,0h1v1l0,0c0,0,0,0,1,0l0,0l0,0l0,0l0,0h1l0,0l0,0c0-1,0-1,0-1c1,0,1,0,1,0l0,0l0,0l0,0h1l0,0v-1l0,0c0,0,0,0,1,0l0,0l0,0
		l0,0l0,0h1l0,0l0,0l0,0l0,0l1,1l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0c0,1,1,1,1,1l1,1l0,0l0,0l0,0l0,0l0,0l0,0l0,0c1,0,1,0,1,0l0,0
		c0-1,0-1,0-1l0,0l0,0l0,0l0,0v-1l0,0l0,0l0,0h1l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0h1l0,0l0,0l0,0h1l0,0l0,0h1l0,0h1v1l0,0l0,0h1
		l0,0l0,0l0,0l0,0l0,0v-1c0,0,0,0,1,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0h1l0,0l0,0l0,0h1l0,0l0,0l0,0l0,0l0,0l1,1l0,0l0,0l0,0l0,0
		l0,0h1l0,0c0,0,0,0,0,1c0,0,1,1,3,2h1l0,0l0,0l0,0l1,1l0,0l0,0l0,0l0,0h1l0,0l0,0v-1l0,0h1c1-1,1-1,0-2c-1-2,1-2,3-2c2,1,3,0,4,0
		l0,0v-1l0,0l0,0l0,0l0,0l0,0l0,0l0,0h1l0,0l0,0l0,0h1h1l0,0l0,0l0,0l1,1c0,0,0,0,1,0l0,0h1l0,0c0,0,0,0,1,0l0,0h1l0,0h1l0,0
		c1,0,1,0,1,0c2,0,4,0,5,0c2,0,2,0,2-1s0-4,2-12c1-3,3-4,5-4c1,1,2,0,3-1C310,493.2,310,491.2,308,489.2z	">

	</path>
	<a  href="./branches/12" >
	   <title>Олекминский район электрических сетей</title>
		<path id="Olekminsky" d="M161.9,460.2L161.9,460.2c0,0,1-1,2,0c1,0,1,0,0,2c0,1,0,1,0,1l0,0c0,0,0,0,0,1l0,0l0,0l0,0v1l0,0
			c0,0,0,0,0,1l0,0l0,0l0,0l0,0v1l0,0l0,0l0,0l0,0c0,1,1,1,1,1l0,0l0,0l0,0l0,0l0,0l0,0l0,0h1c1-2,6-2,11-1c2,0,4,1,6,2c4,1,5,2,7,6
			c3,3,3,3,5,3c3,1,4,0,5-4c0-2,1-4,2-5s1-2,2-4c0-4,1-5,4-8c1,0,2-1,3-3l0,0l-5-1c-1,0-1-1-1-2l0,0l1-5c1-5,1-6,0-7c-2-4-1-7,4-11
			c1-1,3-3,4-3c2-2,3-2,7-2c0,0,1,0,2,0c0,0,0,0,1-1s1-2,3-2c0-1,0-1,0-2c1-1,1-2,2-3c1,0,2,0,4,0c1,1,4,1,4-1c1-3,3-4,7-4
			c0,0,0,0,1,0c0,0,0,0,0-1c0-2,2-2,3-2s2,1,3,2c1-1,3-1,4-1c2,1,4,0,5-2c0-2,4-3,5-3s2-1,2-1c1,0,1-1,1-2l1-4l0,0l0,0l0,0h-1
			c-1,1-3,1-4,1s-2,0-3,0s-3,1-5,1c-4,0-6,1-9,2c-2,1-2,2-2,2c-1,3-3,3-3,3c-1,0-2-1-2-2c-1-1-2-2-3-3c-4-2-6-6-6-10c0-1,0-1,1-2
			l0,0c-1,0-1,0-1-1l0,0l0,0c0-1-1-2-3-2c-3-2-4-5-4-8l0,0c-1,0-2,0-3-1c0,0,0,0-1,0s-2,0-3,0h-1c-1,1-1,1-2,1l0,0l-6-1
			c-5-1-7-1-9-3c-2-1-4-2-8-3c-2,0-3,0-4-1c-1,0-1,0-2,0l0,0l0,0c-1,1-3,2-4,2c-2,1-3,2-4,2c-2,2-4,3-6,3c-1,0-3,1-4,3c0,3-4,6-6,6
			l0,0c-1,1-2,1-3,1h-1l0,0h-1l0,0l0,0c-1,2-2,2-3,3c-1,2-3,3-4,5c-1,1-1,1-2,2l0,0l0,0l0,0c0,1,0,1,0,1l0,0l0,0c0,0,0,0,1,1l0,0
			c0,1,0,3,0,4c0,2,1,3,1,3c1,1,0,2-1,4s-1,3,0,3c0,0,0,0,0,1l0,0c0,0,0,0,1,0l0,0l0,0l0,0l1,1l0,0l0,0l0,0h1l0,0l0,0l0,0h1l0,0l0,0
			l0,0h1l0,0l0,0l0,0l0,0c0,0,0,0,1,0c0,0,0,0,0,1l1,1c1,0,1,0,1-1c0,0,0,0,0-1l0,0c0,0,0,0,1,0l0,0l0,0l0,0l0,0v-1l0,0l0,0l0,0l0,0
			h1l0,0l0,0l0,0l0,0l0,0h1l0,0v1l0,0l0,0l0,0l0,0h1c0,0,0,0,0,1l0,0l0,0c1,1,2,2,2,2c2,0,2,3,1,7c0,3,0,5,0,5s0,2,0,4c-1,3,0,4,0,5
			c2,2,2,8,0,14c-1,3-1,5-1,7l0,0l0,0l0,0C161.9,459.2,161.9,459.2,161.9,460.2L161.9,460.2L161.9,460.2L161.9,460.2L161.9,460.2
			L161.9,460.2L161.9,460.2L161.9,460.2L161.9,460.2L161.9,460.2C162.9,460.2,161.9,460.2,161.9,460.2z"/>
	</a>
	{/* <a  href="./branches/19" > */}
	   {/* <title>Алданский район электрических сетей</title> */}
		<path id="Aldan" d="M319,446.2L319,446.2c0-1,0-1,0-1l0,0v-1c-1-1-1-1-2-2c0,0-1-1-1-2c-2,0-3-1-4-2c-3-2-3-4-3-4c0-1,0-2-1-3
			c-1-2-1-4-1-7c0-1,0-2,1-3c-1,0-1,0-2-1c-2,0-3,0-5,2c-1,0-2,1-2,2c-1,1-3,1-3,1c-3,0-5,0-7-1l-7-1c-1,0-1,0-1-1l-2-2c0-1-2-4-1-5
			l0,0c-1-1-1-2-1-4c0-1,0-3,1-5c0-3,1-6,1-7h-1c-3,0-5-1-7-2c0,1,0,2-1,2c-1,4-1,5-5,6c-1,0-2,1-3,1c-1,3-4,5-8,4c-1,0-1,0-2,0
			c0,1,0,1-1,1v1l-1-1l0,0c-1,0-1-1-1-2c0,0,0,0-1,0h-1l0,0c0,3-2,3-4,3c-1,0-3,0-4,2c0,3-3,4-8,3h-1c0,0,0,0,0,1c-1,1-1,2-3,3
			c0,1-1,1-1,2c-2,3-3,3-6,2s-3,0-4,1s-3,2-5,4c-3,2-3,2-3,4c0,1,0,3,0,4c1,3,1,4,0,9l-1,4h2c1,1,3,1,4,1c0,0,1,0,2,0s4-1,5-2
			c4-2,6-4,8-3c1,0,3,0,3,2c0,1-1,2-2,3c-1,0-1,1-1,1c-1,2-2,3-2,4c-2,3-2,7,0,8c0,1,1,1,3,2c1,0,2,0,5-1c1-1,4-1,5-1s2,1,3,3
			s2,3,3,3c2,0,4,1,6,2c3,1,3,2,3,6c0,2,0,2,1,2c1,1,2,1,3,1c5,1,12,1,14,0c0,0,1-1,2,0c1,0,3,1,3,2c1,0,1,0,1,0c3-1,7-1,11,0
			c3,0,4,1,5,2c1,0,2,1,2,1l0,0c1,0,5-2,6-4c1,0,2-1,3-1l0,0v-1l0,0h1l0,0l0,0v-1l0,0h1l0,0l0,0c1,0,2-2,2-3c1-2,2-3,3-5
			c2-1,2-2,0-3c-1,0-4-5-3-6c0,0-1-1-2-1s-1,0-1-1c1,0,1-1,1-1s0,0,0-1l0,0l0,0l0,0h1l0,0l0,0l0,0v1l0,0h1l0,0l0,0l0,0h1l0,0l0,0
			l0,0c0,0,0,0,1,0v1l0,0l0,0l0,0h1l0,0v1l0,0l0,0l0,0l0,0h1l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0c0,0,0,0,0-1l0,0l0,0l0,0l0,0l0,0v-1
			c1-1,1-3,2-5c0-2,1-3,1-3C317,453.2,318,450.2,319,446.2L319,446.2z"/>
	{/* </a> */}
	<path id="Lensky" d="M145.9,395.2L145.9,395.2L145.9,395.2L145.9,395.2L145.9,395.2c1-2,3-4,6-7c1-1,2-2,3-3l0,0l0,0h1l0,0l0,0
		c-1-1-1-1-1-2s0-2,0-3c-1-2-2-4-1-6c0-1-3-6-5-7c-1,0-2-1-3-2c-1-2-1-2-5-3c-1,0-3,0-4,0c-1,1-1,1-2,1c-1-1-3-2-3-6l0,0
		c-1,1-2,1-3,2s-2,2-3,2s-2-1-2-2c-1,0-1,0-2-1h-1c0,1-1,1-2,1c-1,1-3,2-4,4c-1,1-4,4-6,4l0,0l0,0c-1,1-2,2-3,3c-2,0-5,0-6-1
		c-2,0-4,0-5-1c-2-1-3-2-5-2l-5-1l0,0c0,0,0,0-1,0l0,0l0,0c-1,0-2,1-2,2s-1,1-2,2c-2,0-7,5-9,10c-1,2-2,3-3,5c-3,3-7,8-7,9s0,1,1,1
		c0,0,0,1,0,2c0,2,2,5,5,7l0,0l0,0h1l0,0v1l0,0l0,0h1l0,0l0,0h1l0,0l0,0h1v-1l0,0c0,0,0,0,1,0l0,0l0,0l0,0c0,0,0-1,1-1l0,0l0,0v-1
		l0,0h1l0,0l0,0l0,0v-1h1l0,0l0,0l0,0l0,0c1,0,1,0,1,0l0,0l0,0l0,0c1,0,1,0,1,0l0,0l0,0l0,0c1,1,1,1,1,1l0,0l0,0h1v1l0,0l0,0h1l0,0
		h1l0,0v1h1l0,0l0,0h1l0,0h1h1l0,0h1v1l0,0l1-1l0,0l0,0l0,0h1l0,0l0,0l0,0c1-1,2-1,3-2l3-1l-1,1c0,1,1,2,1,3c2,2,2,2,0,3
		c-1,2-1,3-2,5l0,0l0,0l0,0v1l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0h1l0,0v-1c1-1,5-3,7-3c1,0,3,0,4-2
		c2-3,8-7,9-7s7-3,8-6c1-1,2-1,4-2c3,0,3,0,4-2c0-3,0-3,3-3h1l0,0h1l0,0l0,0h1l0,0l0,0c1,0,1,1,1,1l0,0h1l0,0l0,0h1l0,0v1h1l0,0l0,0
		l1,1l0,0l0,0l1,1c1,1,2,2,4,4l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0H145.9z"/>
	<path id="Amginsky" d="M310,419.2c1,0,1,0,1,0c0-1,0-1,1-2c0-2,0-4,3-4c0,0,0-1,0,0l0,0c0,0,0-1,0-2c1-3,4-6,7-5c0,0,0,0,0-1
		c1,0,2-1,2-3c0-1,0-4,0-6v-3c0,0-2-1-1-3c-1,0-2-1-1-2c0-1,0-2,0-2c-1-1-3-1-3-1c-3-1-4,0-4,0c-1,0-2,1-3,1l0,0c-1,1-2,1-3,1
		c-4-1-7,0-8,1s-1,3-2,7c-1,6-1,7-4,7h-1l0,0c0,0,0,0-1,1l0,0c0,0-1,0-1,1c-1,0-2,0-3,0c-2,0-3-1-4-2c0-1-1-1-4-2l-1,7c-1,2-1,4-1,6
		c1,1,1,2,1,3s0,2,1,3l1,2l8,1c1,1,5,1,7,1c0-1,2-2,3-3c2-2,4-2,6-2c1,1,2,1,3,1H310z"/>
	<path id="Khangalassky" d="M237.9,399.2c2,1,4,3,4,4l0,0l0,0c0,0,0,0,1,0c0-4,5-6,14-7c1,0,4-1,4-1c2,0,3,0,4,0s1,0,1,0
		c1,0,2-1,3,0c1,0,1,0,1,0l0,0l3,1c2,0,3,1,5,1l2,1l0,0c1,0,2,0,2,0h1c1,0,3,1,4,2c0,1,1,2,2,2s2,0,3-1l0,0l0,0c0-1,0-2,1-5l1-3
		l-2-1c-5-3-8-5-7-9c0-1,0-1,1-2l0,0l0,0c0-1,0-1,0-1c-2,0-3-1-4-1h-5c0-1-1-1-1-2l1-1c-3,0-5,0-7,0c-4,0-4,0-5,3v3c-1,0-1,1-2,1
		l-3-1c-2,0-4,1-5,1c-2,1-5,2-8,1c-1,0-2,0-3,0v1c-1,3-6,6-9,5c-1,0-2,0-2-1v1C232.9,394.2,234.9,396.2,237.9,399.2z"/>
	<path id="Suntarsky" d="M188.9,364.2L188.9,364.2c1,0,2-1,3-1l1-1c0,0,0-2-2-5c-2-4-2-5-4-5c-2-1-3-2-4-4l0,0h-1l0,0c-2-1-3-2-4-3
		s-3-2-4-2c-2-1-5-1-5-4c1-1-1-2-3-2c-3-1-5-3-10-8c-4-4-5-6-6-10c-3-9-5-11-7-11c-1,0-1,0-1,0l0,0c-1,0-1,0-1,0h-4l-1,4
		c0,2-1,5-1,7c0,1,0,1,0,1c1,0,1,0,2,0s1,2,1,3v3l0,0l0,0l0,0l0,0l0,0l0,0l0,0c0,1-1,1-1,1c0,1-1,1-1,1l0,0l-3-1c-1,0-1,0-2,0
		c0,0,0-1-1-1c0,1,0,1,0,1l0,0l0,0c3,1,2,3,2,6c-1,3,0,4,0,4l0,0c2,0,3,1,3,3s0,5,0,12c0,2-1,3-1,4l0,0c0,1,1,2,0,3c0,1,1,1,2,1
		c0,1,0,0,0,0c1,0,5,0,6,0c2,1,4,1,5,3c1,1,1,2,2,2c2,0,3,3,4,4s3,4,2,6c0,1,1,3,1,4c1,2,2,4,1,5c1,0,1,0,1,0c1,0,1,0,1,0
		c1-1,2-1,3-1s3-2,3-4c1-3,4-6,7-5c1,0,2-1,4-2s4-3,5-3h1c0-1,1-2,2-2l0,0C183.9,365.2,186.9,364.2,188.9,364.2z"/>
	<path id="Mirninsky" d="M75.9,279.2c1,0,1,1,1,1l0,0c1,2,3,4,5,4l0,0c0,0,0,0,1,0v1l0,0c1,1,1,1,2,1s2,1,3,1c0,0,0,0,1,1l0,0
		c0,1,0,2,0,2c0,1,0,2,0,3l-1,5l-2-1l0,0l0,0l0,0h-1l0,0l0,0l0,0c0,0,0,0-1,0l0,0l0,0l0,0l0,0l0,0c0,1,0,1-1,2l0,0c-1,0-1,0-1,1l0,0
		h1c0,0,0,0,0,1c1,0,1,0,2,0l1,1c1,0,1,0,1,1c3,2,4,6,2,14c-1,4-1,5-3,7c-3,2-3,4,0,5c2,1,3,3,2,6c0,1-1,2-3,4c-3,2-4,3-4,5
		c0,1,0,3,0,3c1,1,1,2,1,4c-1,2-1,2,0,3c0,1,1,1,1,2s1,2,2,2c1,1,1,1,1,3l-1,2c1,0,1,0,2,0s3,1,4,1c4,2,7,3,11,4c1,0,4,0,4-1
		c1-1,2-2,3-2s3-1,5-4c3-3,5-3,5-3c1-1,2-2,3-2c1,1,3,1,4,3l0,0c0,0,1,0,2-1c2-2,3-2,4-2l0,0c1-2,1-5,1-7s0-4,0-6s0-4-1-4
		c-3,0-2-4-2-7c0-1,0-2,0-2v-1c-1,0-1-1-2-1c0-1,0-1,0-2c1-1,3-1,4-1s1,0,2,0c0,0,0,0,1,1c0,0,0,0,1,0c0-1,0-2,0-3c-1,0-1,0-1,0
		c-2,0-2-2-2-4c1-2,1-4,1-7l1-5c0-1,1-1,1-1h4l0,0c0-1,0-2,0-3c1-2,1-4,1-6c0-1,0-2,0-3c0,0-1-1-1-2c-2-1-3-2-4-4c-2-2-3-4-5-5
		c-1-1-1-1-1-2s1-2,1-2c1-1,1-2,2-2c0-1-1-2-1-2l-1-2c-1,0-1-1,0-2h1h1c0,0,0,0,1,0l0,0c0,0,0,0,0-1c1-3,3-4,7-4h1v-2c0-3,1-5,2-5
		c0,0,0-1-1-2c-1-2-2-4-2-6v-1c-1,0-2-1-2-1c-2,0-4-1-5-3c-1,0-2-1-2-1s0,0-1,1c-2,1-3,1-6,1c-3-1-5,0-11,4c-1,1-3,2-5,2
		c-1,0-1,0-2-1c0-1,0-2,1-3s2-7,2-9c-1,0-1-1-1-3c1-1,1-1,1-1c-1,0-1,0-1,0h-1c-2,0-2,0-3,1s-3,3-5,3c0,0,0,0,0-1c-1,1-1,1-2,1
		s-1,0-2-1c0,0-1,0-1-1c-2,0-3-1-3-2v-1l0,0c0,0-1,0-1,1c-1,0-1,1-2,1c-1-1-1-1-2-1h-1c-1,1-2,2-2,3s-1,2-1,3c0,0,0,1,0,2s0,2-1,3
		c0,1,0,1,0,2l0,0c0,2,0,3,0,5l1,4l0,0l-3,2c-2,1-4,2-5,2c-2,0-2,0-3,2c0,2,0,3-1,3h-1l-1,1l0,0c0,1-1,1-1,1l0,0l0,0c-1,0-1,0-1,0v1
		l0,0c0,0,0,0,0,1l0,0l0,0l1,1l0,0c1,0,1,0,1,0v1l0,0c0,1,1,1,1,1l1,1h1h1C74.9,276.2,75.9,277.2,75.9,279.2z"/>
	<path id="Megino-Kangalassky" d="M291,378.2c0,2-2,3-4,3l0,0c0,1,0,2,0,3s0,3,5,6l3,2c1,0,1,1,1,1l-1,5c0,1,0,2-1,3c1,0,1-1,2-1v1
		c0-1,0-4,1-6c1-5,1-7,3-8c2-2,6-3,10-2l0,0c-1-1-2-2-3-3c-2-2-2-3-1-7c0-2,0-3-1-4l-1-2l0,0c0,0-1,0-2,0c-1-1-3-1-4-2l-1-1
		c0,0,0,0-1,1c0,0-1,2-2,2c1,2,0,5-2,7C292,376.2,291,377.2,291,378.2z"/>
	<path id="Gorny" d="M233.9,387.2l1,1l0,0c3,0,6-1,7-3c0-3,2-3,6-3c3,1,4,0,6,0c2-1,4-2,7-2l2,1v-2c1-4,3-5,8-5c2,0,4,0,5,0h1l0,0
		l0,0c0-1,0-3-2-4c-2,0-4-1-5-2c0,0,0-1,1-1h-1l0,0c0,0,0,0,0-1c-1-1-1-2,0-3c0-1,1-3,3-3c0,0,1-1,2-1l0,0c-1-1-1-1-1-2
		c-1-1-2-3-2-5c0-1,0-1,0-2l0,0l0,0l0,0c0,0,0-1-1-1c0-1,0-2,0-3c0,0,0,0-1-1c0,0,0,0-1,0l0,0c-1,0-1,0-1,0c-1,1-2,1-4,1v1
		c0,3-2,3-5,3c0-1-1-1-2-1c-1,1-4,1-5,1h-1l0,0l0,0c-1,1-2,1-4,1l0,0c1,2,1,3,1,4c-1,1-2,1-3,1s-1-1-2-1h-1c-1,0-2,0-5,3
		c0,0-1,1-2,1s-2-1-3-1c-1-1-2-2-3-2c-3,0-9,0-11,1c0,1-2,1-3,1h-1l0,0c0,1-1,1-1,2l0,0c0,0,0,0,0,1l0,0c1,0,1,0,1,0l0,0
		c0,1,1,2,2,3s2,4,2,6c0,1-1,1-2,2v1v2l0,0c2-1,3-1,5-1c1,1,1,1,1,1c2,0,4,1,4,3s1,4,3,5s3,2,4,4l0,0l0,0l0,0
		C232.9,387.2,232.9,387.2,233.9,387.2z"/>
	<path id="Verkhnevilyusky" d="M211.9,362.2L211.9,362.2c0-1,0-1,0-1c-1,0-1,0-1,0c-3,0-2-3-1-7c0-2,0-4,0-6c0-1,0-2,0-5
		c1-2,1-3-2-7c-2-3-3-6-2-8c0-1,1-2,2-2h1c0-1,0-3,0-4c0-2,0-3,0-4c-1,0-2,0-2,1c-1,0-3,0-4,0s-3-1-3-5c0-1,0-2-1-3c-2-3-3-7-2-10
		c1-2,1-2-1-4c-4-5-3-12,2-17c1-1,1-2,2-3l1-3h-1c-3-1-4-2-4-5c0,0,0-1-1-2c-1,0-1,0-1-1c-1,0-2,0-2,0c-2,0-3,0-5,7c-1,1-1,2-1,3
		l0,0v1l1,2c1,1,1,3,1,4s0,2,0,3c1,2,1,4,1,5c0,2,1,4,1,5c1,3,1,5,0,12c-1,10,0,12,0,13c2,0,2,2,2,2c0,2-1,3-1,4c-1,4-1,6-3,7
		c-1,1-1,1-2,7c-1,3-1,4-1,5s0,1,0,1l0,0c0,1,1,1,1,2l1,1c3,1,6,6,7,10c1,2,2,3-1,4c-1,1-1,1-2,1c-1,1-2,1-3,2c-1,0-1,0-1,0l-1,1
		c1,0,3,0,5,0c5,1,7,2,8,3s5,2,10,3c1,1,3,1,5,1c0-1,0-1,0-2s1-2,2-3c0,0,0,0,0-1s0-2-2-4c0-1-1-2-1-3l0,0H211.9z"/>
	<path id="Churapchinsky" d="M315,365.2l-3,2c-2,0-4,1-6,2c2,1,2,4,1,7c0,3,0,4,4,8l0,0c1,0,1,0,2,0c0,0,0,0,1,0c1-2,3-1,5-1
		c4,1,5,2,5,6l0,0c0,0,1,1,1,2l0,0l0,0l0,0l4-1c2,0,5-1,7-1c1,0,3-1,3-2c1-1,1-2,1-2l0,0l0,0c0-1-1-1-1-1c-1-2-2-3-3-4c-2-1-2-1-4-2
		c-1,0-2,0-3,0s-2,0-3,0c-4-1-5-3-6-7c0-2-1-3-2-4s-1-1-2-2l0,0l0,0l0,0C315,365.2,315,365.2,315,365.2z"/>
	<path id="Yakutsk" d="M293,368.2c-1,0-1,0-1,0s0,0,0-1l0,0c-1,0-2,1-3,1c-2,0-4,0-6-1c-1,0-2,0-3-1c-1,0-2,0-3,0s-1,0-1,0
		c-1,1-3,1-4,1l0,0l0,0l0,0c0,1,0,1,1,1c4,1,7,3,6,6c0,0,0,1-1,1c1,1,1,1,1,1c1,1,2,1,3,1c2,1,4,1,5,2c0,0,0,0,0,1l1-1c0,0,1,0,1-1
		s1-2,2-3s1-2,1-4C292,370.2,292,369.2,293,368.2L293,368.2z"/>
	<path id="Tattinsky" d="M318,353.2l-2,10l0,0c0,0,1,0,1,1c0,0,1,0,1,1c2,1,3,3,4,5c1,5,1,5,4,6c1,0,2,0,2,0c2,0,3,0,4,0
		c2,1,3,1,5,3c1,1,3,3,4,4l0,0c2-1,4-2,6-3h1c0,0,0,0,0-1c-3,0-2-4-2-6s0-3-1-4c0-1-1-2-1-3c-1,0-1-1-1-2c-1,0-2-1-3-1
		c-7-1-10-4-9-11c1-1,1-2,1-2c-1-1-1-3-1-3l-1-1h-4c-3,0-5-1-6-1l0,0c0,0,0,0,0,1l0,0l0,0l0,0c-1,0-1,0-1,0l0,0
		C319,348.2,319,350.2,318,353.2z"/>
	<path id="Vilyusky" d="M198.9,260.2c0,1-1,2-1,2c-1,2-1,3-2,3c1,1,1,3,2,4c0,2,1,2,2,3c1,0,1,1,2,1l0,0l0,0v1c0,0,0,2,0,3
		c-1,2-2,4-3,5c-2,1-3,4-4,7c0,3,1,5,2,7c2,2,3,3,2,6c-1,2,0,6,2,8c1,2,2,4,1,5c0,1,0,1,0,2c1,0,2,0,2,1c1-1,2-1,2-1c1,0,2-1,2-1
		c1,0,1,0,1,0c2,1,2,2,2,5c0,4,0,5-2,6c-1,1-1,1-1,3c-1,1-1,2,2,5c2,5,3,5,2,9c0,1,0,2,0,3s1,2,0,8c-1,1-1,3-1,4c0,0,0,0,1,0
		c-1,0,0-1,0-2s1-2,3-1c1,0,2,0,3-1s4-1,7-1c2,0,3,0,5,0c1,0,2,1,3,2c0,0,1,1,2,1c0,0,1,0,2-1c2-2,3-3,6-3c0,0,1,0,2,1h1
		c0-1,0-1,0-2s0-1,0-1l-1-1l0,0l0,0l0,0c-1-1-1-1-2-2c-1-2-3-3-8-5c-2-1-5-1-5-4c0,0,0-1,0-2c-2-1-2-3-1-5c1-4,3-7,7-8c1-1,3-3,4-4
		c1-2,3-4,5-4l0,0c0-1-1-1-1-2l-3-2v-1l1-4c-1,1-3,1-4,2c-2,1-4,2-6,1c-2,0-3-1-4-2c-1,0-2-1-4-1c-2-1-2-3-2-4h-1c-1,0-3-1-4-2
		c0,0,0-1-1-1s-2-1-2-2c-1,0-1-1-1-1c-2-1-3-3-3-6c0-2-1-3-2-4l-3-2v-1l1-8c0-7,0-7-1-9c-1-1-2-3-1-4c0-1,0-2-3-4
		C200.9,259.2,199.9,260.2,198.9,260.2z"/>
	<path id="Namsky" d="M273,365.2c2-1,3-1,6-1c0,0,1,0,2,1c1,0,2,0,3,0c1,1,3,1,4,1c1-1,2-1,3,0l0,0h1l0,0l0,0c0-1,0-2,0-3
		c1-3,1-3,0-5c-2-3-2-4-1-8c1-2,1-3,1-3c-1-1-1-2-1-3h-1c-1,0-2-1-3-2s-2-1-3-1h-1c-1,1-3,3-4,4s-2,2-2,3c-1,3-3,3-3,2h-1
		c1,1,1,2,1,3s0,2,1,3s1,2,1,3s0,1-1,2s-2,1-3,1c0,1,0,3,0,4C272,365.2,273,365.2,273,365.2z"/>
	<path id="Ust-Aldanski" d="M314,339.2h-3v-1c-1,0-2,0-3,0c0,0-1,0-1,1c-2,0-4,0-6,0c-6-1-7,0-7,0c0,2-1,4-2,4v1c0,0,0,0,0,1
		c1,0,1,0,1,1c0,0,1,1,1,2c0,0,0,1-1,1c0,1,0,2-1,3c0,3,0,4,1,5c2,2,2,4,1,7c0,0,0,1-1,2l0,0c0,0,0,0,0,1l0,0l0,0l0,0h1c0-1,0-1,0-1
		c1-1,2-2,3-2l0,0c1,0,2,1,2,1c1,1,2,1,3,2c1,0,1,0,2,0h1l2-1c2,0,3,0,5-1c1-1,2-1,3-1v-1c-1-3,0-6,2-13v-1c0-1,0-2,1-3l0,0v-1l0,0
		c0,0,0,0,0-1l0,0v-1C318,341.2,316,340.2,314,339.2z"/>
	<path id="Nyurbinsky" d="M184.9,278.2c-1,1-2,2-3,2c-1,1-2,3-4,4c-1,1-2,2-4,3c-1,0-2,1-3,2c0,1,0,2-1,2c0,1-2,2-4,1c-1,0-2,0-4-1
		c0-1,0-1,0-1c-2,0-3,0-5,0c-4,1-6,1-7,1c0,1,0,3-3,2c-1,0-1,0-2,0c0,1-1,1-2,1c0,3,0,5,0,7l-1,5c5,1,7,5,10,13c1,4,2,6,8,11
		c3,3,4,4,6,4c3,1,5,3,5,6c1,0,2,1,2,1c3,0,5,1,6,3c1,1,2,1,3,2c1,0,1,0,1,0l0,0c0-2,0-4,1-6c0-5,0-6,3-7c1-1,1-2,2-7c0-1,1-2,1-4
		c-3-2-2-11-2-15c1-6,1-8,0-10c0-2-1-4-1-6s0-3-1-4c0-2-1-3,0-5c0-1,0-2-1-3C184.9,278.2,184.9,278.2,184.9,278.2z	">

	</path>
	<a  href="./branches/8" >
	   <title>Кобяйские электрические сети</title>
		<path id="Kobyaysky" d="M319,315.2h-1c-1,0-1,0-1,0c-1,1-2,1-2,1c-1,0-2-1-3-3c-1-1-2-3-2-5s-1-4-2-5c0-2-2-4-2-6c1-2-3-9-4-9
			s-4-3-4-4c-1-1-3-3-4-4c0,0-1-1-1-2h-1l0,0l-1-1c-2-1-4-1-5-2c-2,0-6-1-7-2c-1,0-2,0-3-1h-1c-1,0-2,0-2,0c-1,0-2,0-2-1
			c-1,0-1,0-1,0c0,1-1,1-1,1c-1,1-2,2-2,4c0,1-1,2,2,5c2,2,3,5,2,7c0,2-2,3-3,3l-1,1c-1,4-2,5-6,4c0,0-1,0-2,0c-1,2-5,3-7,3
			c-5,2-7,3-7,5c0,1-1,2-1,2c-1,0-1,1-1,1l0,0c0,1,0,2,0,3s0,1,2,3c2,1,2,3,2,5c-1,0-1,1-3,1l0,0c0,0-1,0-3,2c-1,2-3,3-5,5
			c-3,1-4,2-5,6c-1,3-1,3,0,4s1,3,1,4c1,1,2,1,3,1c5,2,7,3,10,8h1l0,0c1,0,2,0,3,0l0,0c0,0,0,0,1-1l0,0l0,0l0,0c0,0,0,0,0-1l0,0l0,0
			l0,0l0,0l0,0l0,0l0,0l0,0c0,0,0,0,0,1l0,0l0,0l0,0h1l0,0c1,0,1,0,2,0s2,0,3,0c1-1,3-1,4-1h1h1c0-1,0-4,3-3c0,0,0,0,1,0
			c1-1,2-2,3-1h1c2,1,2,2,2,4v1h1h1l0,0c0-1,3-4,4-5s2-2,2-3c1-1,2-2,4-1c2,0,3,1,4,2c0,0,1,1,2,1h1v-1c2-5,4-5,10-4c2,0,3,0,5,0
			c1-1,3-1,4-1s1,0,1,0l2-1c1-1,3-3,4-4c2-3,2-3,3-9c0-3,0-5,0-6C320,316.2,319,316.2,319,315.2L319,315.2L319,315.2z"/>
	</a>
	<a  href="./branches/6" >
	   <title>Жиганский район электрических сетей</title>
		<path id="Zhigansky" d="M264,226.2c-1,0-3-1-4-1c-2-1-5-4-6-6l0,0c-1-1-3,0-4,1c-1,0-4,1-5,1c-2,0-5-4-5-4c-2-3-3-4-4-4s-1,0-2,0
			c0,1-1,1-2,1s-2-1-2-2s-1-2-2-3c-2-3-2-3-5-3l0,0c-1-1-2-1-3-1c0,1-1,1-2,2c-1,0-2,0-3,1c-1,0-2,1-3,1h-1l0,0c0,1,1,1,1,1
			c1,2,2,3,2,5c0,1,0,1,1,2s1,2,1,4c-1,3-1,4-4,4c-2-1-3,0-4,2c-1,4-2,4-6,3c0,0-1,0-2,0c0,1-1,2-2,2l0,0c0,3,0,4-1,5c-2,1-3,1-5,0
			c-1,0-2,0-3,0l0,0c0,1,0,2,1,2c1,1,1,2,1,3c0,2-1,4-3,5l-1,1h1c1,0,2,0,2,1c2,0,2,0,2,1c1,0,2,0,2,0c1,0,3,1,3,2l0,0h1l0,0l0,0
			l0,0l0,0c0,1,0,1,0,1c0,1,1,1,1,1c1,1,2,2,3,2c0,1,0,1,0,1l0,0c2,1,4,5,4,7c0,1,0,2,1,2c2,3,2,4,1,11l-1,7l2,1c2,2,3,4,3,7
			c0,2,1,3,2,4c1,0,1,1,2,2l0,0c1,0,2,1,3,2c0,1,1,1,2,1s3,1,3,4l0,0c1,0,3,1,5,2c1,1,2,1,3,2c2,0,4-1,6-2s3-1,4-1l0,0c0,0,0-1,1-1
			c0-1,1-3,2-4c1-2,5-3,7-4c2,0,4-1,6-2c0-1,2-1,4-1c2,1,2,1,3-1c0-2,1-3,3-3c0-1,1-1,1-3s0-2-2-5c-3-4-2-7-2-8c1-1,2-3,4-4v-1
			c0-1,0-2,0-2c-1-2-1-4,0-5s1-3,1-4c1-3,1-3-2-6c-1-2-3-4-4-7c-1-4,0-9,3-10l1-1c0-2,0-5,0-6c-1,0-1-1-1-1l0,0c0,0,0,0,0-1l0,0
			c-1,0-1,0-1,0l0,0C265,226.2,265,226.2,264,226.2z"/>
	</a>
	<a  href="./branches/13" >
	   <title>Оленекский район электрических сетей</title>
		<path id="Oleneksky" d="M86.9,224.2c0,1,1,1,1,1c0,1,0,2,0,3s0,2,0,2l0,0c0,1,0,1,0,1l0,0v1l0,0l0,0v1l0,0l0,0l0,0c0,1,0,1,0,1
			l0,0h1l0,0c0,1,0,0,1,0c1-1,2-1,2-1c1,0,2,1,2,3l0,0c1,1,2,1,4,2l0,0c1-1,2-1,3-1c0,1,1,0,2-1c1-2,4-3,6-3c1,0,1,1,2,1
			c1,1,0,3,0,4c0,0,0,0,0,1c0,0,0,0,1,0c1,2-2,9-3,11v1l0,0c1,0,2-1,4-2c6-4,9-5,13-4c2,0,3,0,4-1c1,0,2-1,3-1s1,1,2,2s2,2,4,2
			c3,1,4,1,4,4c0,2,0,3,1,4c2,3,2,4,2,5s-1,1-1,1c-1,1-1,2-1,2l-1,4c0,1-1,1-1,1h-3c-1,0-2,0-3,2c0,1-1,3-3,3c0,0,0,0,0,1
			c1,0,2,2,2,3c-1,1-1,2-2,3c-1,0-1,0-1,1c1,0,2,2,5,6c1,1,2,2,4,3c0,1,1,2,2,2l0,0c1,0,1,0,1,0c0-1,2-1,4-1l0,0c1-2,4-2,9-3
			c2,0,3,0,5,0c1,0,1,1,2,1c1,1,2,1,2,1c1,0,2,0,3,0c0,0,0-1,1-2c0-1,2-3,4-3c1-1,2-1,3-2c1-2,3-3,4-5l3-2c0,0,0,0,0-1s1-3,1-4
			c1-4,3-8,7-7l0,0c1,0,2,0,2,0h1c0-1,0-1,0-2c1-1,3-4,5-5h-1c-1-1-3-3-3-4s-1-1-1-1c-1,0-4,0-4-2l0,0c-1,0-1,0-2,0s-1,0-2,0
			s-2-1-2-2s1-2,2-3s2-2,2-3s0-1,0-2c-1-1-2-2-2-3c1-3,3-2,6-2c1,0,2,0,2,0s1,0,1-1v-1c0-1,0-1,0-2c1,0,1-1,2-1l0,0c1-2,4-2,5-2
			s2,0,3,0v-1c2-4,4-5,7-5l1,1c0-1,0-2,0-2c0-1,0-2,0-2c-1-1-2-3-2-4s0-2-1-3l-1-2c-1,0-1-1,0-2l0,0l0,0l0,0l0,0c0-1,1-2,2-3
			s1-1,2-2c0-2,1-3,2-4c0,0,1-5,2-7c1-8,2-12,5-12h1h3c1,0,2,0,3,0c0,0,0-1,1-1c1-2,2-9,2-12l2-12l-2-4c-1-2-2-5-2-7c0-1,0-1-1-2
			l0,0h-1l0,0c-2,1-6,1-15-1c-6-1-10-1-11-1c-2,0-6,3-6,4v1c1,0,1,1,0,2c0,1-1,1-1,1c-1,0-2-1-2-1h-1h-1c-1,0-3,1-4,2s-2,1-3,2
			c1,1,1,1,2,2c3,4,2,5,2,6c-1,0-3,1-4,1c-1,1-3,1-4,2c-2,1-4,1-5,1s-2-1-3-1c-2-3-1-5,1-7c2-3,3-9,2-11s0-4,0-6c1-1,1-3,1-4
			s0-1,0-1c-1-1-2-1-3-1c0,0-1,0-2,0c0,0,0,0,0,1l0,0l0,0c0,1-1,2-1,2c-1,1-3,11-2,12c0,0,0,1,0,3v1l-1,1v1c-1,0-1,1-3,1
			c-2,1-3,1-5,1c-1,0-1,0-1,0c-2,0-4,1-6,1c-1,0-2,1-3,2c0,0,0,1-1,1c0,1,0,2-1,2h-1c0,0,0,0-1,0c-2,0-6,3-6,4c1,0,1,0,1,1s0,2-1,3
			l0,0c0,1-1,2-1,2c-1,0-1,1-1,2l-1,1l0,0l0,0l0,0l0,0l0,0v1l0,0l0,0l0,0l0,0l0,0c-1,0-1,0-1,0l0,0l0,0l0,0l0,0l0,0c0,0-1,0-1-1l0,0
			l0,0l0,0c0,0-1,0-1-1l0,0l0,0l0,0l0,0l0,0h-1v-1l0,0l0,0l0,0h-1l0,0l0,0l0,0h-1l0,0v-1c-1,0-1,0-2,0c0,0-1,0-2,0h-1h-1l0,0
			c-1,1-2,1-2,1l0,0l0,0l0,0l0,0h-1l0,0l0,0l0,0l0,0l0,0h-1l0,0l0,0h-2l0,0l0,0h-1l0,0l-1-1h-1l0,0h-1l0,0h-1l0,0c0,0,0,0-1,0l0,0
			l0,0l0,0h-1l0,0l0,0l0,0c0,0,0,0-1,0l0,0l0,0l0,0c0,0,0,0,0,1l0,0l0,0l0,0c-1,0-1,0-1,1l0,0l0,0l0,0v1l0,0c0,0,0,0,0,1l0,0
			c0,0,0,0,0,1c0,0,0,1-1,2c0,0,0,1,1,2v1c0,0,0,0,0,1c1,1,1,2,1,4v1c-1,1-1,1-1,1v1l0,0c0,1,0,2,0,3h-1c0,1,0,1,0,2c0,0,0,0,0,1
			c-1,1-1,2-1,3c-1,0-1,1-1,2c-1,1-1,3-1,4v1c0,1-1,2-1,2c-1,1-1,1-1,2c-2,1-3,2-3,3c0,2-2,5-3,6h-1c-1,0-2,0-3,1h-1c-1,0-2,0-3,1
			l0,0h-1c-1,1-1,2-1,3h-1C87.9,224.2,86.9,224.2,86.9,224.2z"/>
	</a>
	<a  href="./branches/16" >
	   <title>Эвено-Бытантайский район электрических сетей</title>
		<path id="Eveno-Bytantaysky" d="M309,225.2c-1,0-1,0-1,0h-1c0,0,0,0-1-1v-3c-1-1-1-1-1-2c-1,0-2,1-6,0c-6-1-6,0-7,2c-2,2-5,3-8,3
			c-1,0-2,0-3,0c-2,1-5,1-7,1c-3,0-5,0-5,0s0,1-1,1l0,0l0,0l0,0c1,1,1,1,1,1c1,2,1,4,1,7c-1,1-1,2-3,3c0,0-1,0-1,2c-1,4,0,7,4,12
			c3,3,3,4,2,8c0,1,0,3-2,4c0,0,0,2,1,4c0,1,0,2,0,3c2,0,3,0,5,0c3,1,4,1,5,2c1,0,3,1,4,1c5,1,6,1,8,3l0,0l0,0c0-1,0-2-1-4
			c-1-3,0-9,3-11c0,0,1-1,1-3c1-3,2-4,7-7c4-2,5-3,5-4c1-3,4-7,8-9c1-1,2-1,2-3c0,0,1-3,2-4c0-1,0-2,0-2c0-2,0-2-2-3c-1-1-2-2-3-3
			s-1-1-2-1l0,0h-1l0,0l0,0l0,0h-1c0,1,0,1-1,1L309,225.2z"/>
	</a>
	<a  href="./branches/4" >
	   <title>Булунские электрические сети</title>
		<path id="Bulynsky" d="M258,107.2L258,107.2L258,107.2h1l0,0l0,0l0,0l1,1l0,0l0,0l0,0l0,0h1l0,0l0,0v1c1,1,1,1-2,0l0,0h-1l0,0l0,0
			h-1l0,0l0,0l0,0h-1l0,0l0,0l0,0l0,0l0,0C254.9,107.2,255.9,107.2,258,107.2z M297,149.2L297,149.2L297,149.2L297,149.2L297,149.2
			L297,149.2L297,149.2L297,149.2L297,149.2L297,149.2L297,149.2L297,149.2v-1l0,0l0,0C297,148.2,297,148.2,297,149.2L297,149.2
			L297,149.2z M293,156.2L293,156.2h-1c0,0,0,0,0,1c0,0,0,1,1,1S294,157.2,293,156.2L293,156.2z M218.9,118.2c0-1,0-1,0-1
			c-2,0-5,1-7,2c0,1-1,1-1,2l0,0c1,0,1,0,2,0c4,1,6,2,7,6c0,0,0,1,3,2c0,0,1,0,2,0l0,0c3,0,3,2,4,5s2,5,1,6l0,0v1c1,0,3,0,3,4
			c0,2,1,4,2,5l2,5l0,0l-2,13c-2,11-2,12-3,13c0,0,0,0-1,0c0,2-3,2-4,2l-5-1c-1,1-2,8-2,10c-1,7-2,8-3,9s-1,1-1,2c-1,1-1,3-3,4l0,0
			c2-1,3-2,5-2l1-1c1-1,2-1,4,0h1c3,0,4,1,6,4c1,2,2,3,2,4c1,0,1,0,2,0c1-1,2-1,2-1c2,0,3,2,6,5c1,2,2,3,3,3s2,0,4-1s4-2,6-1
			c1,0,2,1,1,2l0,0c0,1,2,3,7,5c1,0,2,0,3,1l0,0c1,0,1,0,1,0l0,0c0,0,0,0,1,0c0-1,0-1,0-1c2-1,7-1,7-1c2,0,5,0,5,0c2-1,3-1,5-1
			s4,0,5-2c2-3,5-3,10-2c1,0,2,0,4,0h1c1,0,2,1,3,3c0,1,0,1,0,2c1,0,2-1,2-1h1c0-1,0-1,0-1s0,0,0-1l1-6c2-6,2-7,5-10c1-1,3-2,4-3
			c0,0,0,0,1,0l0,0h-1c-3-1-3-5-3-7c0-3,0-3-3-4c-3,0-4-1-5-4c0-1,0-4,0-6c1-3,0-4,0-4c-1,0-1,0-1-1l0,0l0,0c0,0,0,1-1,1
			c0,0-1,1-1,2v1c0,0,0,1-1,2l0,0c-1,0-1,0-1,0c-2,0-3-1-3-3c0,0,0,0,0-1l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0h-1l0,0l0,0l0,0h-1
			l0,0l0,0h-1l0,0l0,0l0,0h-1l0,0l0,0l0,0h-1l0,0l0,0l0,0l-1-1l0,0l0,0l0,0l0,0h-1l0,0l0,0c0-1,0-1,0-1s0-2-2-3c-1-1-2-3-2-5
			c0-4-1-5-2-5c-2-1-3-4-3-6s1-2,3-3c1,0,2-1,2-2s1-2,1-2c1,0,1,0,1-1s-2-3-4-4c-2,0-2-1-2-3c0-1,1-2,1-2c1,0,1-2,2-4c1-3,1-4,0-5
			s-1-2-2-3c0-2,0-3-1-3s-2-1-3-2v-1l0,0l0,0h-1l0,0v-1l0,0h-1l0,0l0,0l0,0h-1l0,0v-1l0,0h-1l0,0l0,0h-1l0,0l0,0h-1l0,0l0,0l-1,1
			l0,0l0,0h-1l0,0h-1l0,0h-1l0,0l0,0l0,0l-1,1l0,0l0,0h-1l0,0l0,0c-1,0-1,0-1,0v-1l0,0h-1l0,0l0,0l0,0h-1l0,0l0,0h-1v-1l0,0l0,0l0,0
			l-1-1l0,0l0,0v-1l0,0h-1l0,0v-1l0,0l0,0h-1v-1l0,0l0,0l0,0h-1c0,0,0,0,0-1l0,0l0,0h-1l0,0l0,0l0,0v-1h-1l0,0l0,0l0,0h-1l0,0l0,0
			l0,0h-1h-1c-1,0-2,0-2,1c-1,1-2,2-3,2s-2,0-2,3c0,2,1,3,1,4c1,0,1,2,0,4c-1,4-1,4-2,4c0,0,0,0-1,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0
			l-1-1l0,0l0,0l0,0l0,0c1-1-2-4-3-4s-2,0-2,1c0,2-1,2-5,1l0,0l0,0h-1l0,0h-1l0,0h-1l0,0l0,0l0,0l0,0l0,0l-1-1l0,0l0,0l0,0l0,0l0,0
			l0,0h-1v-1l0,0l0,0l0,0l0,0l0,0l-1-1l0,0l0,0c0-1,0-1,0-1l0,0c-1,0-1-1-1-1l0,0l0,0v-1c-1,0-1,0-1,0l0,0c0,0,0,0-1,0l0,0v-1l0,0
			h-1l0,0l0,0l0,0c-1,0-1,0-1,0l0,0l0,0l0,0c-1,0-1,0-1,0l0,0c0,0,0,0,0,1c0,0,0,0-1,0l0,0l0,0l0,0c0,0,0,0-1,0l0,0l0,0l0,0h-1l0,0
			c0,0,0,0-1,0v-1h-1l0,0c0,0,0,0-1-1C219.9,120.2,218.9,118.2,218.9,118.2z M250.9,111.2c0,0,0,0,1,1c0,0,1-1,0-1c0,0,0-1-1-1
			C250.9,110.2,250.9,110.2,250.9,111.2L250.9,111.2z M313,127.2c0,0,0,0,1,0l0,0c0,1,0,1,0,1l0,0l0,0l0,0h1l0,0l0,0l0,0l0,0l0,0h1
			l0,0l0,0l0,0l0,0l0,0l0,0l0,0c0,0,0,0,0-1l0,0h1c0-1,0-2,0-2c0-1,0-1-1-1C314,124.2,313,125.2,313,127.2z"/>
	</a>
	<a  href="./branches/20" >
	   <title>Анабарский район электрических сетей</title>
		<path id="Anabarsky" d="M208.9,90.2L208.9,90.2L208.9,90.2c1,0,1,0,1,0s1,1,0,2c0,0,0,1-1,1c0,0-1-1,0-2
			C208.9,90.2,208.9,90.2,208.9,90.2z M188.9,94.2c0-1-1-1-1-1l0,0c0,1,0,1,0,1l0,0C188.9,94.2,188.9,94.2,188.9,94.2z M192.9,80.2
			l1-1l0,0l0,0l0,0l0,0l0,0v-1l0,0c-1,0-1,0-1,0s0,0,0,1h-1L192.9,80.2L192.9,80.2z M179.9,82.2L179.9,82.2L179.9,82.2h-1l0,0l0,0
			l0,0c0,1,0,1,0,1c0,1,0,1,1,1c0,0,3,2,5,4s4,3,4,3l0,0l0,0l0,0l0,0l0,0l0,0l0,0h1l0,0l0,0l0,0l0,0l1-1l0,0l0,0c2-1,3-2,3-4
			c1-2,0-3-1-4s-2-1-4-2c-2,0-2,0-3,2l0,0l0,0v1l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0h-1l0,0l0,0c0,0,0,0-1,0l0,0l0,0l0,0l0,0h-1
			l0,0l0,0l0,0h-1l0,0c0,0,0,0-1,0l0,0c0,0,0,0,0,1l0,0l0,0l0,0h-1l0,0l0,0c0-1,0-1,0-1c1,0,1,0,1,0c0-1,0-1,0-1l0,0
			C180.9,82.2,180.9,82.2,179.9,82.2L179.9,82.2L179.9,82.2L179.9,82.2L179.9,82.2L179.9,82.2z M167.9,104.2c0,1,0,2,1,2
			c0,1,0,3,0,5c-1,3-2,6-2,8c-1,4,0,5,1,5c1,1,2,3,2,4l0,0v1l0,0l0,0c1,0,1,1,1,1l0,0l0,0l0,0h1l0,0c1,0,2,0,3,0c2,1,4,1,4,4
			c0,2-1,4-1,6c-1,2-1,3,0,4c1,3,0,9-2,11c-3,4-3,5-2,5c0,1,1,1,2,1s3,0,5-1c1-1,3-1,4-2c2,0,2,0,2,0s0-1-2-3l-2-4c0,0,0,0,0-1
			c0,0,0,0,1-1l3-1c1-1,3-2,3-2c1-1,3-1,4-1c0,0,0,1,1,1l0,0l0,0l0,0c0-1,0-1,0-2c1-2,5-5,7-6c2,0,8,1,13,1c7,2,10,2,12,2
			c0-1,1-1,1-1l0,0c0,0,0-1,0-2s0-3-1-4c-1-3-1-3-1-3l0,0c-2,0-2,0-3-1c-3,0-5-1-5-3c-1-3-1-3-4-4c-3,0-5-1-5-3c0-1,2-3,7-4
			c2-1,3-1,3-1c1,0,1,0,1,0l0,0h1l0,0l0,0c1,0,2,0,2-1c1-2,0-2-5-4c-3-1-5-2-8-3c0,0,0-1-1-1l0,0h-1l0,0v-1h-1l0,0h-1l0,0l0,0h-1v-1
			h-1h-1l0,0l0,0h-1l0,0l0,0h-1l0,0l0,0h-1l0,0l0,0v1h-1l0,0l0,0h-1l0,0l0,0c0,0,0,0-1,0l0,0l0,0l0,0h-1l0,0l0,0h-1l0,0l0,0h-1l0,0
			l0,0l0,0l0,0c0,0,0,0-1,0l0,0l0,0c0,0-1,0-2,0s-1,0-1,2c0,1,0,2,0,3c0,0,0,0-1,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0h-1
			l0,0v-1c-1-1-1-6,0-6s2-6,1-7c0,0-1,1-1,2l0,0h-1l0,0l0,0l0,0h-1l0,0l0,0v1h-1l0,0v-1h-1l0,0l0,0h-1l0,0l0,0h-1l0,0h-1l0,0v-1h-1
			l0,0l0,0l0,0c0-1-1-1-1-1l0,0l0,0c0-1,0-1,0-1h-1l0,0l0,0l0,0l0,0v-1l0,0l0,0l0,0l0,0l0,0h-1l0,0l0,0h-1c-1,1-1,2-2,3c0,0-1,2-3,3
			S167.9,102.2,167.9,104.2z"/>
	</a>
</g>
<circle cx="315.9" cy="375.9" r="2"/>
<circle cx="326.6" cy="368.9" r="2"/>
<circle cx="311.6" cy="401.1" r="2"/>
<circle cx="350.2" cy="360.3" r="1.1"/>
<circle cx="364.5" cy="361" r="1.1"/>
<circle cx="354.7" cy="377" r="1.4"/>
<circle cx="354.4" cy="329.1" r="2"/>
<circle cx="418.6" cy="333.1" r="2"/>
<circle cx="414.3" cy="335.9" r="1.1"/>
<circle cx="421.1" cy="336.7" r="1.1"/>
<circle cx="423.9" cy="336.4" r="1.1"/>
<circle cx="433.3" cy="337.6" r="1.1"/>
<circle cx="439.6" cy="361.8" r="2"/>
<circle cx="411.6" cy="290.2" r="1.1"/>
<circle cx="453.2" cy="290.1" r="1.1"/>
<circle cx="412" cy="266.4" r="2"/>
<circle cx="406.8" cy="262.3" r="1.1"/>
<circle cx="482.1" cy="269" r="2"/>
<circle cx="470.5" cy="273.5" r="2"/>
<circle cx="475" cy="256.3" r="1.4"/>
<circle cx="488" cy="272.7" r="1.4"/>
<circle cx="494.8" cy="222.3" r="2"/>
<circle cx="488.7" cy="222.9" r="1.1"/>
<circle cx="519.4" cy="222" r="1.1"/>
<circle cx="500" cy="213.4" r="1.1"/>
<circle cx="478.5" cy="216.3" r="1.1"/>
<circle cx="498.2" cy="203.7" r="1.1"/>
<circle cx="486.5" cy="202.1" r="1.1"/>
<circle cx="479" cy="209.6" r="1.1"/>
<circle cx="471.7" cy="203.5" r="1.1"/>
<circle cx="464.8" cy="211.7" r="1.1"/>
<circle cx="489" cy="186" r="1.1"/>
<circle cx="524.2" cy="180.3" r="1.1"/>
<circle cx="539.4" cy="167.9" r="1.1"/>
<circle cx="546.5" cy="170.5" r="2"/>
<circle cx="429.1" cy="216.2" r="1.4"/>
<circle cx="429.3" cy="219.8" r="2.2"/>
<circle cx="419" cy="221" r="1.4"/>
<circle cx="414.4" cy="226.4" r="1.4"/>
<circle cx="429.2" cy="227.8" r="1.4"/>
<circle cx="417.8" cy="236.1" r="1.4"/>
<circle cx="432.9" cy="188.1" r="1.1"/>
<circle cx="420.9" cy="186.2" r="1.1"/>
<circle cx="403.9" cy="177" r="1.1"/>
<circle cx="430.2" cy="168.2" r="1.1"/>
<circle cx="437" cy="156" r="1.1"/>
<circle cx="368.7" cy="210.5" r="2.3"/>
<circle cx="329.6" cy="219.7" r="1.4"/>
<circle cx="331.5" cy="228.4" r="1.4"/>
<circle cx="328" cy="233.2" r="1.4"/>
<circle cx="339.2" cy="237.9" r="1.4"/>
<circle cx="336" cy="250.5" r="1.4"/>
<circle cx="341" cy="250.5" r="1.4"/>
<circle cx="308.3" cy="238.8" r="1.1"/>
<circle cx="336.8" cy="244.6" r="1.1"/>
<circle cx="317.4" cy="263.2" r="1.1"/>
<circle cx="320.5" cy="253.2" r="1.4"/>
<circle cx="326.6" cy="255.7" r="1.4"/>
<circle cx="331.7" cy="254.1" r="1.4"/>
<circle cx="301.3" cy="247.7" r="1.9"/>
<circle cx="313.4" cy="209.9" r="1.9"/>
<circle cx="287.3" cy="162.6" r="2.3"/>
<circle cx="363.8" cy="216.2" r="1.1"/>
<circle cx="337.9" cy="194.3" r="1.1"/>
<circle cx="342.8" cy="182.2" r="1.1"/>
<circle cx="345" cy="175.8" r="1.1"/>
<circle cx="367.4" cy="180.7" r="1.1"/>
<circle cx="340.4" cy="164.7" r="1.1"/>
<circle cx="367.8" cy="149.2" r="1.9"/>
<circle cx="371.6" cy="353.5" r="1.7"/>
<circle cx="289.9" cy="150.6" r="1.4"/>
<circle cx="302.4" cy="182.5" r="1.4"/>
<circle cx="324.2" cy="182.1" r="1.4"/>
<circle cx="257.2" cy="197.1" r="2"/>
<circle cx="277.9" cy="184.5" r="2"/>
<circle cx="239" cy="136" r="2"/>
<circle cx="229.2" cy="126.6" r="2"/>
<circle cx="185.2" cy="119.4" r="2"/>
<circle cx="189.5" cy="143.5" r="2"/>
<circle cx="171.6" cy="177.7" r="1.1"/>
<circle cx="152.5" cy="212" r="2.3"/>
<circle cx="180.9" cy="274.8" r="1.4"/>
<circle cx="317" cy="290.8" r="1.9"/>
<circle cx="238.6" cy="255.2" r="1.1"/>
<circle cx="236.6" cy="269.7" r="1.4"/>
<circle cx="237.6" cy="283.9" r="1.4"/>
<circle cx="239.3" cy="301" r="1.4"/>
<circle cx="291" cy="304" r="1.4"/>
<circle cx="296" cy="329.9" r="1.1"/>
<circle cx="253.8" cy="371.5" r="2"/>
<circle cx="176.5" cy="333.7" r="2"/>
<circle cx="128.3" cy="341.4" r="2"/>
<circle cx="165.1" cy="359.9" r="2"/>
<circle cx="133.3" cy="383.1" r="2"/>
<circle cx="269.5" cy="331.6" r="2.3"/>
<circle cx="263.9" cy="330.6" r="1.1"/>
<circle cx="259.5" cy="331.7" r="1.1"/>
<circle cx="262.5" cy="337.2" r="1.1"/>
<circle cx="283" cy="339.8" r="1.1"/>
<circle cx="246.5" cy="340.8" r="1.1"/>
<circle cx="249.2" cy="343.1" r="1.1"/>
<circle cx="241.5" cy="335" r="1.1"/>
<circle cx="251.3" cy="335.1" r="1.1"/>
<circle cx="272.5" cy="343" r="1.1"/>
<circle cx="230.4" cy="351.3" r="1.1"/>
<circle cx="206.8" cy="370.1" r="1.1"/>
<circle cx="207.8" cy="328.6" r="2"/>
<circle cx="195.5" cy="335" r="2"/>
<circle cx="288.8" cy="374.4" r="3.8"/>
<circle cx="268.8" cy="391.3" r="1.1"/>
<circle cx="279.2" cy="396.8" r="1.1"/>
<circle cx="257.6" cy="395.5" r="1.1"/>
<circle cx="253.1" cy="393.2" r="1.1"/>
<circle cx="279.1" cy="388.7" r="2"/>
<circle cx="263.4" cy="413.2" r="1.1"/>
<circle cx="243.4" cy="397.1" r="1.1"/>
<circle cx="235.9" cy="400.7" r="1.1"/>
<circle cx="226.8" cy="402.2" r="1.1"/>
<circle cx="218.9" cy="401" r="1.1"/>
<circle cx="210.6" cy="400.9" r="1.1"/>
<circle cx="263.6" cy="429.4" r="1.1"/>
<circle cx="257.9" cy="428.2" r="1.1"/>
<circle cx="236.7" cy="434.2" r="1.1"/>
<circle cx="170.4" cy="388.9" r="1.1"/>
<circle cx="178" cy="394" r="1.1"/>
<circle cx="187.6" cy="404.7" r="1.1"/>
<circle cx="173" cy="404.9" r="1.1"/>
<circle cx="181.6" cy="409.6" r="1.1"/>
<circle cx="180.4" cy="412.8" r="1.1"/>
<circle cx="193.8" cy="423.3" r="1.1"/>
<circle cx="170.9" cy="418.7" r="1.1"/>
<circle cx="163.2" cy="413.9" r="1.1"/>
<circle cx="163.4" cy="410.1" r="1.1"/>
<circle cx="152.7" cy="408" r="1.1"/>
<circle cx="151.8" cy="400" r="1.1"/>
<circle cx="150.9" cy="396.2" r="1.1"/>
<circle cx="146.9" cy="390.8" r="1.1"/>
<circle cx="141.3" cy="386.1" r="1.1"/>
<circle cx="120" cy="388.4" r="1.1"/>
<circle cx="80.1" cy="397.7" r="1.1"/>
<circle cx="85.2" cy="398.7" r="1.1"/>
<circle cx="342.8" cy="408" r="2"/>
<circle cx="341.3" cy="412.1" r="1.1"/>
<circle cx="325.8" cy="427.2" r="1.1"/>
<circle cx="314.6" cy="438.3" r="1.1"/>
<circle cx="310.8" cy="442" r="1.1"/>
<circle cx="296.9" cy="449.6" r="1.1"/>
<circle cx="280.8" cy="456.5" r="1.1"/>
<circle cx="193.1" cy="398.2" r="2"/>
<circle cx="351.4" cy="250.8" r="1.4"/>
<circle cx="345" cy="258.5" r="1.9"/>
<circle cx="333.3" cy="263" r="1.4"/>
<circle cx="330.3" cy="264.3" r="1.4"/>
<circle cx="313.7" cy="272.6" r="1.4"/>
<circle cx="320.4" cy="275.5" r="1.4"/>
<circle cx="315.3" cy="267.8" r="1.4"/>
<circle cx="310.9" cy="274.9" r="1.4"/>
<circle cx="298.8" cy="261" r="1.1"/>
</svg>




</div>
		</div>
		);
	}
}
