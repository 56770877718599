import React from 'react';
import { withRouter } from 'react-router-dom';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import store from 'root/site/store';
import { HtmlValue } from '@smartplatform/ui';
import { processText } from 'root/tools/processText';
import { Loader } from '@smartplatform/ui';
import { Attachments } from '../attachments';
import './styles.scss';

@withRouter
@observer
export default class Record extends React.Component {
	@observable noRecords;
	@observable records;
	@observable categorySort = null;
	@observable sortCategory = null;
	@observable category;
	@observable allowedToShow = false;

	async componentDidMount() {
		this.init();
	}

	init = async () => {
		const { categoryId } = this.props;

		this.category = await store.model[this.props.model].findById(categoryId, {
			include: ['categorySort'],
		});
		await this.fetchRecords();
	};

	fetchRecords = async (sort) => {
		const { page, categoryId, limit, order, showOnMain } = this.props;
		this.allowedToShow = false;

		this.categorySort = this.sortCategory ? this.sortCategory : this.category.categorySort;
		this.records = await store.model.CategoryRecord.find({
			order: order ? order : this.categorySort ? this.categorySort.value : 'createdAt desc',
			where:
				page === 'disconnection'
					? {
							[`${page}Id`]: categoryId,
							showOnMain: !!showOnMain,
					  }
					: {
							[`${page}Id`]: categoryId,
					  },
			limit: limit,
		});

		// console.log('==== records', this.records);
		if (this.records.totalCount > 0) {
			this.getRecordsData(this.records);
		} else {
			this.noRecords = true;
		}
	};

	getRecordsData = async (records) => {
		const recordsData = [];
		for (const record of records) {
			let { content, name, id, title, createdAt } = record;
			createdAt = moment(createdAt).format('DD.MM.YYYY');
			let files;
			let attachments;
			files = await store.model.CategoryRecordFile.find({
				where: { categoryRecordId: id },
				include: [{ relation: 'attachments' }],
			});

			if (files.totalCount > 0) {
				files = files[0];
				attachments = files.attachments();
			}

			recordsData.push({ id, content, name, attachments, title, createdAt });
		}
		this.records = recordsData;
		this.allowedToShow = true;
	};

	@action onCategorySortChange = async (sortCategory) => {
		this.sortCategory = sortCategory;
		await this.fetchRecords();
	};

	render() {
		const { title } = this.props;
		if (this.noRecords) {
			return (
				<div>
					<h2 className='ClassNameSection client'>{title}</h2>
					<div>Записей не найдено</div>
				</div>
			);
		}
		if (!this.records) {
			return <Loader />;
		}

		const records = this.records.map((record) => {
			const { title, content, attachments, createdAt, id } = record;
			return (
				<div key={`record-${id}`}>
					<div className='BlockButtonList'>
						{title && <h2> {title} </h2>}
						<p> {createdAt} </p>
						{content && (
							<div className='article-content__wrapper'>
								<HtmlValue value={processText(content)} className='article-content' />
							</div>
						)}
						{attachments && <Attachments attachments={attachments} />}
					</div>
				</div>
			);
		});
		return (
			<div className='BlockButton'>
				<h2 className='ClassNameSection client'>{title}</h2>
				{/*<Row className={'SortSelector'}>*/}
				{/*	<span>Сортировать записи по</span>*/}
				{/*	<RecordSelect*/}
				{/*		model={store.model.CategorySort}*/}
				{/*		value={this.categorySort}*/}
				{/*		onChange={this.onCategorySortChange}*/}
				{/*		property={'name'}*/}
				{/*	/>*/}
				{/*</Row>*/}
				{this.allowedToShow && records}
			</div>
		);
	}
}
