import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { Button, DateValue, HtmlValue } from '@smartplatform/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { processText } from 'root/tools/processText';
import { ResponsePopup } from './ResponsePopup';

@observer
export class List extends React.Component {
	@observable expandedVacancies = [];
	@observable responseVacancy = null;

	onExpand = (id) => {
		const index = this.expandedVacancies.findIndex((item) => item === id);
		if (~index) {
			this.expandedVacancies.splice(index, 1);
		} else {
			this.expandedVacancies.push(id);
		}
	};

	onClose = () => (this.responseVacancy = null);
	render() {
		const { records } = this.props;
		return (
			<div className='vacancies-list'>
				{this.responseVacancy && <ResponsePopup vacancy={this.responseVacancy} onClose={this.onClose} />}
				{records.map((vacancy) => {
					const { id, title, salary, date, workPlace, requirements, responsibility, conditions, description } = vacancy;
					const isExpanded = !!this.expandedVacancies.find((item) => item === id);
					return (
						<div key={id} className='vacancy'>
							<div className='main-info' onClick={() => this.onExpand(id)}>
								<div className='left'>
									{!!date && <DateValue value={date} format='DD.MM.YYYY' />}
									<div className='title'>{title}</div>
									<div>
										{!!workPlace && (
											<div className='workPlace'>
												<FontAwesomeIcon icon={faMapMarkerAlt} />
												{workPlace}
											</div>
										)}
									</div>
								</div>
								<div className='salary'>з/п: {!!salary ? salary : 'по результатам собеседования'}</div>
								<div className={isExpanded ? 'expand expanded' : 'expand'}>
									<FontAwesomeIcon icon={faChevronUp} />
								</div>
							</div>
							{isExpanded && (
								<div className='secondary-info'>
									{!!requirements && (
										<div className='requirements'>
											<div className='title'>Требования:</div>
											{<HtmlValue value={processText(requirements)} />}
										</div>
									)}
									{!!responsibility && (
										<div className='responsibility'>
											<div className='title'>Обязанности:</div>
											{<HtmlValue value={processText(responsibility)} />}
										</div>
									)}
									{!!conditions && (
										<div className='conditions'>
											<div className='title'>Условия:</div>
											{<HtmlValue value={processText(conditions)} />}
										</div>
									)}
									{!!description && (
										<div className='description'>
											<div className='title'>Описание:</div>
											{<HtmlValue value={processText(description)} />}
										</div>
									)}
									<Button className='btn-primary mb-3' onClick={() => (this.responseVacancy = vacancy)}>
										Откликнуться
									</Button>
								</div>
							)}
						</div>
					);
				})}
			</div>
		);
	}
}
