import React from 'react';
import { observable } from 'mobx';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import moment from 'moment';
import store from 'root/site/store';
import { processText } from 'root/tools/processText';
import './styles.scss';
import { Sidebar } from '../../components/sidebar';
import { HtmlValue } from '@smartplatform/ui';

@withRouter
@observer
export default class OnePage extends React.Component {
	@observable article;

	componentDidMount() {
		console.log(this.props);
		store.ui.setPage('/news');
		console.log(store.ui.page);
		this.init();
	}

	componentDidUpdate() {
		store.ui.setPage('/news');
		this.init();
	}

	init = async () => {
		const { id } = this.props.match.params;
		const article = await store.model.Article.findById(id);
		this.article = article;
	};

	render() {
		if (!this.article) {
			return <div />;
		}
		const { content, name, createdAt } = this.article;
		const img = this.article.downloadFile('thumbnail');

		return (
			<div className='container'>
				<div className='row'>
					<div className='col-8'>
						<h3 className='ClassNameSection client'>{name}</h3>

						<div className='PostImage'>
							<a href={img} target='_blank'>
								<img src={img} />
							</a>
						</div>
						<div className='PostText'>
							<h6>Дата: {moment(createdAt).format('DD.MM.YYYY')}</h6>
							<HtmlValue value={processText(content)} />
						</div>
					</div>
					<div className='col-4'>
						<Sidebar />
					</div>
				</div>
			</div>
		);
	}
}
